import React from "react";
import {connect} from "react-redux";
// Components
import RightModal from "shared/components/RightModal";
import OrderDescriptionModal from "./OrderDescriptionModal";
import AcceptNewOrderModal from "./AcceptNewOrderModal";

const modalVariants = {
  orderDetails: "orderDetails",
  acceptNewOrder: "acceptNewOrder",
};

const OrderDetailsModals = ({modalData}) => {
  if (modalData === null) {
    modalData = {
      title: "none",
      name: "none",
      sub: "none",
      buttonType: "",
    };
  }

  return (
    <>
      {modalData.title && (
        <RightModal
          title={modalData && modalData.title ? modalData.title : null}
          subTitle={modalData && modalData.subTitle ? modalData.subTitle : null}
          buttonType={modalData && modalData.buttonType ? modalData.buttonType : null}
        >
          <div>
            {modalData.name === modalVariants.orderDetails && <OrderDescriptionModal />}
            {modalData.name === modalVariants.acceptNewOrder && <AcceptNewOrderModal />}
          </div>
        </RightModal>
      )}
    </>
  );
};

const mapStateToProps = store => ({
  modalData: store.rightModalData.variant,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailsModals);
