import {
  LOGIN_PENDING,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  DEVICE_FAILED,
  DEVICE_SUCCESS,
  SIGN_UP_PENDING,
  SIGN_UP_FAILED,
  LOGOUT,
  USER_INFO_PENDING,
  USER_INFO_SUCCESS,
  USER_INFO_FAILED,
  USER_AVATAR_SUCCESS,
  USER_AVATAR_FAILED,
  POPULATE_TOKEN_DATA,
  REFRESH_TOKEN,
  FETCH_USER_PROFILE_PENDING,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_FAILED,
  UPDATE_USER_PROFILE_PENDING,
  UPDATE_USER_PROFILE_FAILED,
  UPDATE_USER_PROFILE_SUCCESS,
  SIGN_UP_SUCCESS,
  GENERATE_PASSPHRASE_SUCCESS,
  GENERATE_PASSPHRASE_FAILED,
  COUNTRIES_DOC_SUCCESS,
  COUNTRIES_DOC_FAILED,
} from "./actions/types";
import config from "../config";

const initState = {
  isLoading: true,
  tokenData: null,
  userData: null,
  userAvatar: null,
  userProfile: null,
  userDevices: null,
  generatedPassPhrase: "",
  countriesWithDoc: null,
};

const authData = (state = initState, action) => {
  switch (action.type) {
    case LOGIN_PENDING:
    case SIGN_UP_PENDING:
    case USER_INFO_PENDING:
    case REFRESH_TOKEN:
    case FETCH_USER_PROFILE_PENDING:
    case UPDATE_USER_PROFILE_PENDING:
      return {...state, isLoading: true};
    case LOGIN_SUCCESS: {
      localStorage.setItem(config.localStorageTokenKey, JSON.stringify(action.payload));
      return {...state, isLoading: false, tokenData: action.payload};
    }
    case LOGIN_FAILED:
    case DEVICE_FAILED:
    case SIGN_UP_FAILED:
    case USER_INFO_FAILED:
    case UPDATE_USER_PROFILE_FAILED:
      return {...state, isLoading: false};
    case SIGN_UP_SUCCESS:
      return {...state, generatedPassPhrase: ""};
    case DEVICE_SUCCESS:
      return {...state, userDevices: action.payload};
    case USER_AVATAR_SUCCESS:
      return {...state, userAvatar: action.payload};
    case USER_AVATAR_FAILED:
      return {...state, userAvatar: null};
    case USER_INFO_SUCCESS:
      return {...state, isLoading: false, userData: action.payload};
    case POPULATE_TOKEN_DATA: {
      const tokenJSON = localStorage.getItem(config.localStorageTokenKey);
      if (tokenJSON) {
        return {...state, tokenData: JSON.parse(tokenJSON)};
      }
      return state;
    }
    case LOGOUT: {
      localStorage.removeItem(config.localStorageTokenKey);
      return {...initState, isLoading: false};
    }
    case FETCH_USER_PROFILE_SUCCESS:
      return {...state, isLoading: false, userProfile: action.payload};
    case FETCH_USER_PROFILE_FAILED:
      return {...state, isLoading: false, userProfile: null};
    case UPDATE_USER_PROFILE_SUCCESS:
      return {...state, isLoading: false};
    case GENERATE_PASSPHRASE_SUCCESS:
      return {...state, generatedPassPhrase: action.payload};
    case GENERATE_PASSPHRASE_FAILED:
      return {...state, generatedPassPhrase: ""};
    case COUNTRIES_DOC_SUCCESS:
      return {...state, countriesWithDoc: action.payload};
    case COUNTRIES_DOC_FAILED:
      return {...state, countriesWithDoc: null};
    default:
      return state;
  }
};

export {authData};
