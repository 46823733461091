import React from "react";
import {FormattedMessage} from "react-intl";
import messages from "../../../messages";

import {Field} from "formik";
import Input from "shared/components/Input";
import Button from "shared/components/Button";
import {countries} from "shared/commonDataService/countries";
import Select from "shared/components/Select";
import FileInputText from "shared/components/FileInputText";
import config from "shared/config";

const deleteIcon = config.prefixImgUrl + "delete-icon.svg";

const RegisterTypes = {
  person: "person",
  company: "company",
};

const SecondRegisterStep = ({
  step,
  type,
  setFieldValue,
  errors,
  values,
  setStep,
  validateForm,
  setErrors,
}) => {
  const isDisabled = () => {
    if (type === RegisterTypes.company) {
      return !values.certificate || !values.memorandum || !values.taxID;
    }
    return !values.bill;
  };
  const isNotValid = errors => {
    if (type === RegisterTypes.company) {
      return (
        !values.country ||
        errors.country ||
        !values.companyNumber ||
        errors.companyNumber ||
        !values.certificate ||
        errors.certificate ||
        !values.memorandum ||
        errors.memorandum ||
        !values.taxID ||
        errors.taxID
      );
    }
    return (
      !values.country ||
      errors.country ||
      !values.zip ||
      errors.zip ||
      !values.street ||
      errors.street ||
      !values.apartment ||
      errors.apartment ||
      !values.region ||
      errors.region ||
      !values.city ||
      errors.city ||
      !values.bill ||
      errors.bill
    );
  };
  return (
    <div className="secondStep">
      {step > 2 && (
        <div className="readyStep">
          <div className="stepTitle">
            <div className="title">
              {type === RegisterTypes.person ? (
                <FormattedMessage {...messages.legalAddress} />
              ) : (
                <FormattedMessage {...messages.legalDocuments} />
              )}
            </div>
            <div className="separator" />
            <button className="titleEdit" onClick={() => setStep(2)}>
              <FormattedMessage {...messages.edit} />
            </button>
          </div>
          <div className="stepInfo">
            {values.type === RegisterTypes.company && (
              <>
                {values.certificate.name}
                {", "}
                {values.memorandum.name}
                {", "}
                {values.taxID.name}
              </>
            )}
            {values.type === RegisterTypes.person && (
              <>
                {values.street}
                {", "}
                {values.apartment}
                {", "}
                {values.city}
                {", "}
                {values.country}
                {", "}
                {values.zip}
              </>
            )}
          </div>
        </div>
      )}
      {step === 2 && (
        <>
          {values.type === RegisterTypes.person && (
            <LegalAddress errors={errors} values={values} setFieldValue={setFieldValue} />
          )}
          {values.type === RegisterTypes.company && (
            <LegalDocuments
              errors={errors}
              values={values}
              setFieldValue={setFieldValue}
            />
          )}
          <Button
            disabled={isDisabled()}
            type="button"
            className="nextButton"
            onClick={() => {
              validateForm().then(errors => {
                if (!isNotValid(errors)) {
                  setErrors({});
                  setStep(3);
                }
              });
            }}
            text={messages.next}
          />
        </>
      )}
    </div>
  );
};

export const LegalAddress = ({errors, values, setFieldValue}) => {
  return (
    <>
      <div className="secondStepTitle">
        <FormattedMessage {...messages.legalAddress} />
      </div>
      <div className="countryInputWrapper">
        <Field name="country">
          {({field}) => (
            <div className="inputContainer country">
              <label className="label">
                <FormattedMessage {...messages.country} />
              </label>
              <Select
                error={errors.country}
                {...field}
                options={countries.map(country => country.name)}
              />
            </div>
          )}
        </Field>
        <Field name="zip">
          {({field}) => (
            <div className="inputContainer zip">
              <label className="label">
                <FormattedMessage {...messages.zip} />
              </label>
              <Input error={errors.zip} {...field} />
            </div>
          )}
        </Field>
      </div>
      <Field name="street">
        {({field}) => (
          <div className="inputContainer">
            <label className="label">
              <FormattedMessage {...messages.street} />
            </label>
            <Input error={errors.street} {...field} />
          </div>
        )}
      </Field>
      <Field name="apartment">
        {({field}) => (
          <div className="inputContainer">
            <label className="label">
              <FormattedMessage {...messages.apartment} />
            </label>
            <Input error={errors.apartment} {...field} />
          </div>
        )}
      </Field>
      <Field name="region">
        {({field}) => (
          <div className="inputContainer">
            <label className="label">
              <FormattedMessage {...messages.region} />
            </label>
            <Input error={errors.region} {...field} />
          </div>
        )}
      </Field>
      <Field name="city">
        {({field}) => (
          <div className="inputContainer">
            <label className="label">
              <FormattedMessage {...messages.city} />
            </label>
            <Input error={errors.city} {...field} />
          </div>
        )}
      </Field>
      <div className="billInfo">
        <FormattedMessage {...messages.toConfirmAddress} />
      </div>
      <div className="billInput">
        {!values.bill && (
          <>
            <FileInputText
              accept=".jpg, .jpeg, .png, .pdf"
              error={errors.bill}
              onChange={event => {
                setFieldValue("bill", event.target.files[0].name);
                let _billFile = [];
                _billFile.push(event.target.files[0]);
                setFieldValue("billFile", _billFile);
              }}
            >
              <FormattedMessage {...messages.attachBill} />
            </FileInputText>
          </>
        )}
        {values.bill && (
          <>
            <div className="name">{values.bill}</div>
            <img
              src={deleteIcon}
              className="deleteIcon"
              alt="delete"
              onClick={() => {
                setFieldValue("bill", "");
                setFieldValue("billFile", "");
              }}
            />
          </>
        )}
      </div>
    </>
  );
};

export const LegalDocuments = ({errors, setFieldValue, values}) => {
  return (
    <>
      <div className="secondStepTitle">
        <FormattedMessage {...messages.legalDocuments} />
      </div>
      <Field name="country">
        {({field}) => (
          <div className="inputContainer">
            <label className="label">
              <FormattedMessage {...messages.country} />
            </label>
            <Select
              error={errors.country}
              {...field}
              options={countries.map(country => country.name)}
              placeholder="Country of your residence"
            />
          </div>
        )}
      </Field>
      {values.country && (
        <>
          <Field name="companyNumber">
            {({field}) => (
              <div className="inputContainer">
                <label className="label">
                  <FormattedMessage {...messages.companyNumber} />
                </label>
                <Input error={errors.companyNumber} {...field} />
              </div>
            )}
          </Field>
          <CompanyFileInput
            file={values.certificate ? values.certificate.name : ""}
            error={errors.certificate}
            clear={() => setFieldValue("certificate", "")}
            onChange={e => setFieldValue("certificate", e.target.files[0])}
          >
            <FormattedMessage {...messages.companyRegistrationCertificate} />
          </CompanyFileInput>
          <CompanyFileInput
            file={values.memorandum ? values.memorandum.name : ""}
            error={errors.memorandum}
            clear={() => setFieldValue("memorandum", "")}
            onChange={e => setFieldValue("memorandum", e.target.files[0])}
          >
            <FormattedMessage {...messages.memorandumOfAssociation} />
          </CompanyFileInput>
          <CompanyFileInput
            file={values.taxID ? values.taxID.name : ""}
            error={errors.taxID}
            clear={() => setFieldValue("taxID", "")}
            onChange={e => setFieldValue("taxID", e.target.files[0])}
          >
            <FormattedMessage {...messages.taxID} />
          </CompanyFileInput>
        </>
      )}
    </>
  );
};

export const CompanyFileInput = ({file, error, onChange, clear, children}) => {
  return (
    <div className="companyFileWrapper">
      <div className="companyFileContainer">
        <div className="title">
          <div className="titleText">{children}</div>
          {!file && (
            <FileInputText accept=".jpg, .jpeg, .png, .pdf" onChange={onChange}>
              <FormattedMessage {...messages.add} />
            </FileInputText>
          )}
          {file && (
            <img src={deleteIcon} className="deleteIcon" alt="delete" onClick={clear} />
          )}
        </div>
        <div className="name">{file}</div>
      </div>
      {error && <div className="errorMessage">{error}</div>}
    </div>
  );
};

export default SecondRegisterStep;
