import React from "react";
import {FormattedMessage} from "react-intl";
import messages from "../../../messages";
// Styles
import "../index.scss";

const CustomersTableHeader = () => {
  return (
    <div className="customers-table-header">
      <div className="customers-table-header__item">
        <FormattedMessage {...messages.customerId} />
      </div>
      <div className="customers-table-header__item">
        <FormattedMessage {...messages.firstName} />
      </div>
      <div className="customers-table-header__item">
        <FormattedMessage {...messages.lastName} />
      </div>
      <div className="customers-table-header__item">
        <FormattedMessage {...messages.companyName} />
      </div>
      <div className="customers-table-header__item">
        <FormattedMessage {...messages.status} />
      </div>
    </div>
  );
};

export default CustomersTableHeader;
