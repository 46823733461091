/**
 * @author: Gnapovska and Bazyliuk
 */

import React, {useState, useEffect} from "react";
import {connect} from "react-redux";

import Header from "shared/components/Header";
import {openRightModal} from "shared/components/RightModal/actions";
import {getVoucherList} from "shared/shoppingService/actions";

import {FormattedMessage} from "react-intl";
import Button from "shared/components/Button";
import InputWithIcon from "shared/components/InputWithIcon";

import ShoppingCompanyCards from "../CompanyCards";
import ShoppingCompanyVouchers from "../CompanyVouchers";
import SendToCustomer from "../SendToCustomer";

import {ReactComponent as ShpIconAddCard} from "images/add-card-shopping.svg";
import {ReactComponent as ShpIconAddVoucher} from "images/add-voucher-shopping.svg";

import CompanyDetailsModals from "./components/CompanyDetailsModals";
import messages from "./messages";
import "./index.scss";

import config from "shared/config";

const ActionHistoryIcon = config.prefixImgUrl + "shopping-header.svg";
const searchIcon = config.prefixImgUrl + "search-shopping.svg";

const ShoppingCompany = ({openRightModal, getVoucherList}) => {
  const [isLoading, setIsLoading] = useState(false);

  // Get voucher list
  useEffect(() => {
    if (!isLoading) {
      getVoucherList();
      setIsLoading(false);
    }
  }, [getVoucherList, isLoading]);

  return (
    <>
      <Header
        headerTitle={<FormattedMessage {...messages.header} />}
        navLinks={[]}
        logoImage={ActionHistoryIcon}
      >
        <div className="messages__header__actionBlock">
          <Button
            type="button"
            text={messages.addcard}
            className="btn-ui btn-bold-black btn-with-icon"
            renderIcon={className => <ShpIconAddCard className={className} />}
            onClick={() =>
              openRightModal({
                name: "shoppingCompanyCard",
                title: "New Shopping Card",
              })
            }
          />
          <Button
            type="button"
            text={messages.addvoucher}
            className="btn-ui btn-bold-black btn-with-icon mr-2"
            renderIcon={className => <ShpIconAddVoucher className={className} />}
            onClick={() =>
              openRightModal({name: "shoppingCompanyVoucher", title: "New Voucher"})
            }
          />
          <InputWithIcon
            placeholder={messages.searchcustomer.defaultMessage}
            icon={searchIcon}
          />
        </div>
      </Header>

      <CompanyDetailsModals />
      <div className="shopping-corporate-page">
        <div className="shopping-corporate-page__card">
          <ShoppingCompanyCards />
        </div>
        <div className="shopping-corporate-page__vouchers">
          <ShoppingCompanyVouchers />
        </div>
        <div className="shopping-corporate-page__sendtocustomer">
          <SendToCustomer />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = store => ({});
const dispatchToProps = {
  openRightModal,
  getVoucherList,
};

export default connect(mapStateToProps, dispatchToProps)(ShoppingCompany);
