import {defineMessages} from "react-intl";

const scope = "AccountSettings";

const messages = defineMessages({
  loginSetting: {
    id: `${scope}.loginSetting`,
    defaultMessage: "Login Settings",
  },
  logout: {
    id: `${scope}.logout`,
    defaultMessage: "Log out",
  },
  header: {
    id: `${scope}.header`,
    defaultMessage: "Account Settings",
  },
  login_auth: {
    id: `${scope}.login_auth`,
    defaultMessage: "Login Authentication",
  },
  reset_password: {
    id: `${scope}.reset_password`,
    defaultMessage: "reset password",
  },
  change: {
    id: `${scope}.change`,
    defaultMessage: "change",
  },
  two_factor_auth: {
    id: `${scope}.two_factor_auth`,
    defaultMessage: "Two Factor Authentication",
  },
  cell_phone_number: {
    id: `${scope}.cell_phone_number`,
    defaultMessage: "Cell phone number",
  },
  password: {
    id: `${scope}.password`,
    defaultMessage: "Password",
  },
  trusted_devices: {
    id: `${scope}.trusted_devices`,
    defaultMessage: "Trusted Devices",
  },
  decription: {
    id: `${scope}.decription`,
    defaultMessage:
      "If you see here unknown device - remove it from the list of trusted and change your password.",
  },
  changePassword: {
    id: `${scope}.changePassword`,
    defaultMessage: "Password Recovery",
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: "Cancel",
  },
  saveNewPassword: {
    id: `${scope}.saveNewPassword`,
    defaultMessage: "Save new Password",
  },
  confirmNewPassword: {
    id: `${scope}.confirmNewPassword`,
    defaultMessage: "Confirm New Password",
  },
  useBothUppercase: {
    id: `${scope}.useBothUppercase`,
    defaultMessage:
      " Use both upper-case and lower-case letters, one or more numerical digits",
  },
  newPassword: {
    id: `${scope}.newPassword`,
    defaultMessage: "New Password",
  },
  enterNewPassword: {
    id: `${scope}.enterNewPassword`,
    defaultMessage: "Enter and save new password",
  },
  currentPassword: {
    id: `${scope}.currentPassword`,
    defaultMessage: "Current Password",
  },
});

export default messages;
