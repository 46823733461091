import React from "react";
import {connect} from "react-redux";
// Components
import MiniChart from "components/Dashboard/Components/MiniChart";
// Selectors
import {getCryptoIconBySymbol} from "shared/walletService/selectors";
// Styles
import "./index.scss";

// TODO: Delete after integration with back-end
import weekly from "components/Dashboard/Components/Chart/mockData/1week.js";

const DehoStats = ({getCryptoIconBySymbol}) => {
  return (
    <>
      <div className="deho-stats">
        <div className="deho-stats__title">Deho stats</div>

        <div className="deho-stats__block">
          {/* First line */}
          <div className="deho-stats__block__items">
            <div className="deho-stats__block__items__current-price deho-stats__block__items__item">
              Current price
            </div>
            <div className="deho-stats__block__items__item deho-stats__block__items__deho">
              <img src={getCryptoIconBySymbol("DHG")} alt="dhg" />
              <div className="deho-stats__block__items__deho-info">
                <div className="deho-stats__block__items__deho-info__price">
                  14.45 USD
                </div>
                <div className="deho-stats__block__items__deho-info__name">DHG</div>
              </div>
            </div>
            <div className="deho-stats__block__items__item deho-stats__block__items__deho">
              <img src={getCryptoIconBySymbol("DHS")} alt="dhs" />
              <div className="deho-stats__block__items__deho-info">
                <div className="deho-stats__block__items__deho-info__price">
                  14.45 USD
                </div>
                <div className="deho-stats__block__items__deho-info__name">DHS</div>
              </div>
            </div>
            <div className="deho-stats__block__items__item deho-stats__block__items__deho">
              <img src={getCryptoIconBySymbol("DHP")} alt="dhp" />
              <div className="deho-stats__block__items__deho-info">
                <div className="deho-stats__block__items__deho-info__price">
                  14.45 USD
                </div>
                <div className="deho-stats__block__items__deho-info__name">DHP</div>
              </div>
            </div>
            <div className="deho-stats__block__items__total deho-stats__block__items__item">
              Total
            </div>
          </div>

          {/* Second line */}
          <div className="deho-stats__block__items">
            <div className="deho-stats__block__items__item">Price change (7d)</div>
            <div className="deho-stats__block__items__deho-graph deho-stats__block__items__item">
              <MiniChart chartData={weekly} strokeColor="#FFB300" width="65%" />
            </div>
            <div className="deho-stats__block__items__deho-graph deho-stats__block__items__item">
              <MiniChart chartData={weekly} strokeColor="#CCCCCC" width="65%" />
            </div>
            <div className="deho-stats__block__items__deho-graph deho-stats__block__items__item">
              <MiniChart chartData={weekly} strokeColor="#ADB5CC" width="65%" />
            </div>
            <div className="deho-stats__block__items__item" />
          </div>

          {/* Three line */}
          <div className="deho-stats__block__items">
            <div className="deho-stats__block__items__item">Current Emission</div>
            <div className="deho-stats__block__items__deho-graph deho-stats__block__items__item">
              100,000 DHG
            </div>
            <div className="deho-stats__block__items__deho-graph deho-stats__block__items__item">
              100,000 DHG
            </div>
            <div className="deho-stats__block__items__deho-graph deho-stats__block__items__item">
              100,000 DHG
            </div>
            <div className="deho-stats__block__items__item" />
          </div>

          {/* Four line */}
          <div className="deho-stats__block__items">
            <div className="deho-stats__block__items__item">Sold Coins</div>
            <div className="deho-stats__block__items__deho-graph deho-stats__block__items__item">
              43,500 DHG
            </div>
            <div className="deho-stats__block__items__deho-graph deho-stats__block__items__item">
              2,400 DHS
            </div>
            <div className="deho-stats__block__items__deho-graph deho-stats__block__items__item">
              6,200 DHS
            </div>
            <div className="deho-stats__block__items__item" />
          </div>

          {/* Five line */}
          <div className="deho-stats__block__items">
            <div className="deho-stats__block__items__item">Coins In Stock</div>
            <div className="deho-stats__block__items__deho-graph deho-stats__block__items__item">
              56,500 DHG
            </div>
            <div className="deho-stats__block__items__deho-graph deho-stats__block__items__item">
              7,600 DHS
            </div>
            <div className="deho-stats__block__items__deho-graph deho-stats__block__items__item">
              3,800 DHS
            </div>
            <div className="deho-stats__block__items__item" />
          </div>

          {/* Six line */}
          <div className="deho-stats__block__items">
            <div className="deho-stats__block__items__item">Covered by Metal</div>
            <div className="deho-stats__block__items__deho-graph deho-stats__block__items__item">
              <div className="deho-stats__block__items__item-usd">435,328.00 USD</div>
              <div className="deho-stats__block__items__item-metal">10 Kg Gold</div>
            </div>
            <div className="deho-stats__block__items__deho-graph deho-stats__block__items__item">
              <div className="deho-stats__block__items__item-usd">24,470.00 USD</div>
              <div className="deho-stats__block__items__item-metal">20 Kg Silver</div>
            </div>
            <div className="deho-stats__block__items__deho-graph deho-stats__block__items__item">
              <div className="deho-stats__block__items__item-usd">76,460.00 USD</div>
              <div className="deho-stats__block__items__item-metal">5 Kg Platinum</div>
            </div>
            <div className="deho-stats__block__items__item">536,258.00 USD</div>
          </div>

          {/* Seven line */}
          <div className="deho-stats__block__items">
            <div className="deho-stats__block__items__item">Not Covered by Metal</div>
            <div className="deho-stats__block__items__deho-graph deho-stats__block__items__item">
              35,450.00 USD
            </div>
            <div className="deho-stats__block__items__deho-graph deho-stats__block__items__item">
              6,250.00 USD
            </div>
            <div className="deho-stats__block__items__deho-graph deho-stats__block__items__item">
              18,450.00 USD
            </div>
            <div className="deho-stats__block__items__item">60,150.00 USD</div>
          </div>

          {/* Eight line */}
          <div className="deho-stats__block__items">
            <div className="deho-stats__block__items__item">
              Reserved for Metal Purchase
            </div>
            <div className="deho-stats__block__items__deho-graph deho-stats__block__items__item">
              35,450.00 USD
            </div>
            <div className="deho-stats__block__items__deho-graph deho-stats__block__items__item">
              6,250.00 USD
            </div>
            <div className="deho-stats__block__items__deho-graph deho-stats__block__items__item">
              18,450.00 USD
            </div>
            <div className="deho-stats__block__items__item">60,150.00 USD</div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  getCryptoIconBySymbol,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DehoStats);
