import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import "./index.scss";
import {FormattedMessage} from "react-intl";
import messages from "../../messages";

import {openRightModal} from "shared/components/RightModal/actions";
import CustomCheckbox from "shared/components/Checkbox";
import {useClickOutside} from "shared/hooks";
import {setDefaultCard} from "shared/cardService/actions";
import config from "shared/config";

const clockIcon = config.prefixImgUrl + "clock-icon.svg";
const unlinkIcon = config.prefixImgUrl + "unlink-icon.svg";
const depositOnwalletIcon = config.prefixImgUrl + "deposit-onwallet-icon.svg";

export const mapItems = (items, option = {}) => {
  return items.map((item, index) => (
    <div
      key={index}
      className={`RowMenuItem ${option.other ? "other" : ""}`}
      onClick={() => {
        if (item.onClick) item.onClick();
      }}
    >
      <div className="wrapper-icon">
        <img src={item.icon} className="icon" alt="icon" />
      </div>
      <div className="text">
        <h1 className="title">{item.title}</h1>
        {!!item.subTitle && <h2 className="subTitle">{item.subTitle}</h2>}
      </div>
    </div>
  ));
};

const TableRowMenu = ({
  card,
  wallet,
  bankAccount,
  thirdWallet,
  openRightModal,
  onClick,
}) => {
  const Items = [
    {
      title: <FormattedMessage {...messages.depositOnwalletAccount} />,
      subTitle: <FormattedMessage {...messages.easyTransferToYourAccount} />,
      icon: depositOnwalletIcon,
    },
  ];

  const BankAccountOtherItems = [
    {
      title: <FormattedMessage {...messages.transactionHistory} />,
      icon: clockIcon,
    },
    {
      title: <FormattedMessage {...messages.unlinkAccount} />,
      icon: unlinkIcon,
    },
  ];

  const ThirdWalletOtherItems = [
    {
      title: <FormattedMessage {...messages.transactionHistory} />,
      icon: clockIcon,
    },
    {
      title: <FormattedMessage {...messages.unlinkWallet} />,
      icon: unlinkIcon,
    },
  ];

  const [active, setActive] = useState(false);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);
  const [position, setPosition] = useState("bottom");
  useClickOutside(menuRef, () => setActive(false));

  const handleClick = event => {
    if (card) {
      openRightModal("modalMenuCard");
    }
    if (wallet) {
      openRightModal("modalMenuWallet");
    }
    if (onClick) onClick(event);
    setActive(true);
  };

  const checkPosition = () => {
    if (!menuRef.current || !buttonRef.current) return;
    const bottom = buttonRef.current.getBoundingClientRect().bottom;
    const menuMargin = 30;
    const windowHeight = window.innerHeight;
    const menuHeight = menuRef.current.clientHeight;
    if (windowHeight - (bottom + menuMargin + menuHeight) < 0) {
      setPosition("top");
    } else {
      setPosition("bottom");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkPosition);
    return () => {
      window.removeEventListener("scroll", checkPosition);
    };
  }, []);

  useEffect(() => {
    checkPosition();
  }, [menuRef, buttonRef]);

  return (
    <div className={`RowMenu ${card || wallet ? "no-menu" : ""}`} onClick={handleClick}>
      <span ref={buttonRef} className="RowMenuIcon" />
      {bankAccount && (
        <div
          ref={menuRef}
          className={`RowMenuContainer bankAccount ${
            active ? "active" : "closed"
          } ${position}`}
        >
          {mapItems(Items)}
          {mapItems(BankAccountOtherItems, {other: true})}
          <div className="currentByDefault">
            <CustomCheckbox onChange={() => {}} />
            <p className="text">
              <FormattedMessage {...messages.useThisCurrentAccountByDefault} />
            </p>
          </div>
        </div>
      )}
      {thirdWallet && (
        <div
          ref={menuRef}
          className={`RowMenuContainer ${active ? "active" : "closed"} ${position}`}
        >
          {mapItems(Items)}
          {mapItems(ThirdWalletOtherItems, {other: true})}
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  openRightModal,
  setDefaultCard,
};

export default connect(null, mapDispatchToProps)(TableRowMenu);
