/**
 * @author: Gnapovska
 */

import React, {Component} from "react";
import Checkbox from "shared/components/Checkbox";

import "./index.scss";

class OpenOrderItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      price: 0,
      amount: 0,
      total: 0,
      progres: 0,
      date: "",
      side: "",
      pair: "",
      filled: 0,
      selected: false,
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  prepareData() {
    const {item} = this.props;

    this.setState({
      id: item.id,
      price: item.Price,
      amount: this.formatAmount(item.amount),
      exactAmount: item.Amount,
      progres: this.calcProgress(),
      date: item.Date,
      side: item.Side,
      pair: item.Pair,
      filled: item.Filled,
      total: item.Total,
      avarage: item.Avarage,
      selected: item.selected,
    });
  }

  calcProgress() {
    let max = 100;
    let min = 1;
    return Math.random() * (max - min) + min;
  }

  formatAmount(amount) {
    let _val = (Math.round(amount * 10) / 10).toFixed(1);
    return this.formatNumber(_val);
  }

  formatNumber(value) {
    if (value > 0) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return 0;
  }

  handleSelect(event) {
    this.setState({
      selected: event.target.checked,
    });

    this.props.callChecked([this.state.id, event.target.checked]);
  }

  render() {
    return (
      <div
        className={"OrderItemWrap " + (this.state.side === "0" ? "data-sel" : "data-buy")}
      >
        <div className="item-row-open-orders-checkbox">
          <Checkbox
            value={this.props.selected}
            onChange={event => {
              this.handleSelect(event);
            }}
          ></Checkbox>
        </div>
        <div className="item-row-open-orders" style={{display: "flex"}}>
          <div className="item-row-open-orders">
            <div className="Date">{this.state.date}</div>
            {this.state.side === "0" ? (
              <div className="Side" style={{color: "#FF3333"}}>
                {" "}
                {"SELL "}
                {this.state.exactAmount}
              </div>
            ) : (
              <div className="Side" style={{color: "#2FA100"}}>
                {" "}
                {"BUY "}
                {this.state.exactAmount}
              </div>
            )}
            <div className="Pair">{this.state.pair}</div>
          </div>
          <div className="item-row-open-orders">
            <div className="item-value Filled">
              {"Filled: "}
              {this.state.filled}
              {"%"}
            </div>
            <div className="item-value Total">{this.state.total}</div>
            <div className="item-value">
              {"Price: "}
              {this.state.price}
            </div>
          </div>
          <div
            className={"item-value progress-total-open-orders"}
            style={{width: this.state.filled + "%"}}
          ></div>
        </div>
      </div>
    );
  }
}

export default OpenOrderItem;
