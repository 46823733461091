import React from "react";
import {connect} from "react-redux";
import {markAsRead} from "shared/messagesService/actions";
import MessageElement from "shared/components/MessageElement";
import moment from "moment";
import messages from "../../messages";
import {FormattedMessage} from "react-intl";

const MessagesContainer = ({
  handleClick,
  date,
  items,
  selected,
  selectedUnread,
  selectedSearch,
  markAsRead,
}) => {
  let filteredList = items;

  if (selectedUnread || selectedSearch) {
    filteredList = items.filter(item => {
      return (
        (selectedUnread && !item.isRead) ||
        (selectedSearch &&
          item.content.toLowerCase().includes(selectedSearch.toLowerCase())) ||
        item.title.toLowerCase().includes(selectedSearch.toLowerCase())
      );
    });
  }

  // Error handler
  if (filteredList && filteredList.length === 0) return null;

  return (
    <div className="messages__list__message message__row">
      <div className="message__date">
        <div className="message__date_current">
          {moment(date).isSame(moment(), "d") ? (
            <FormattedMessage {...messages.today} />
          ) : (
            moment(date).format("DD MMMM, YYYY")
          )}
        </div>
      </div>
      {filteredList &&
        filteredList
          .map(message => (
            <MessageElement
              key={message.id}
              onClick={e => {
                handleClick(message, e);
                markAsRead(message.id);
              }}
              selected={selected && selected.id === message.id}
              status={!message.isRead}
              time={moment(message.time).format("H:mm")}
              heading={message.title}
              messageBody={message.content}
            />
          ))
          .reverse()}
    </div>
  );
};

const mapStateToProps = store => ({});

function mapDispatchToProps(dispatch) {
  return {
    markAsRead: data => {
      dispatch(markAsRead(data));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(MessagesContainer);

// export default MessagesContainer;
