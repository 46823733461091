import React from "react";
import {connect} from "react-redux";
import "./index.scss";
import {selectUserProfile} from "shared/auth/selectors";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import messages from "../../messages";
import {FormattedMessage} from "react-intl";
import Button from "shared/components/Button";
import Input from "shared/components/Input";
import {closeRightModal} from "shared/components/RightModal/actions";
import Select from "shared/components/Select";
import {countries} from "shared/commonDataService/countries";
// import {regions} from "shared/commonDataService/regions";
import FileInputText from "shared/components/FileInputText";

import config from "shared/config";
import {updateUserProfile} from "../../../../shared/auth/actions";
const deleteIcon = config.prefixImgUrl + "delete-icon.svg";

const initialValues = {
  country: "",
  zip: "",
  street: "",
  apartment: "",
  region: "",
  city: "",
  bill: "",
};

const ChangeAddressSchema = Yup.object().shape({
  country: Yup.string().required("Country is required"),
  zip: Yup.string().when("type", {
    is: "person",
    then: Yup.string().required("Zip Code is required"),
  }),
  street: Yup.string().when("type", {
    is: "person",
    then: Yup.string().required("Street is required"),
  }),
  apartment: Yup.string().when("type", {
    is: "person",
    then: Yup.string().required("Apartment is required"),
  }),
  region: Yup.string().when("type", {
    is: "person",
    then: Yup.string().required("Region is required"),
  }),
  city: Yup.string().when("type", {
    is: "person",
    then: Yup.string().required("City is required"),
  }),
  bill: Yup.string().when("type", {
    is: "person",
    then: Yup.string().required("Bill is required"),
  }),
});

const ChangeAddressForm = ({
  userProfile,
  closeRightModal,
  setChangeAddress,
  updateUserProfile,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ChangeAddressSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, {resetForm}) => {
        updateUserProfile({
          apartment: values.apartment,
          city: values.city,
          country: values.country,
          region: values.region,
          street: values.street,
          zip: values.zip,
        });
        setChangeAddress(true);
        closeRightModal();
        resetForm();
      }}
    >
      {({errors, touched, values, setFieldValue}) => (
        <Form className="ChangeDetailsForm">
          <div className="currentName">
            <div className="nameLabel">
              <FormattedMessage {...messages.currentAddress} />:
            </div>
            <div className="name">
              {userProfile && userProfile.apartment} {userProfile && userProfile.street},{" "}
              {userProfile && userProfile.city}, {userProfile && userProfile.region},{" "}
              {userProfile && userProfile.country}, {userProfile && userProfile.zip}
            </div>
          </div>
          <FormSectionSeparator sectionName={messages.newAddress} />
          <div className="countryInputWrapper marginTop">
            <Field name="country">
              {({field}) => (
                <div className="inputContainer country">
                  <label className="label">
                    <FormattedMessage {...messages.country} />
                  </label>
                  <Select
                    {...field}
                    options={countries.map(country => country.name)}
                    placeholder="Country of your residence"
                  />
                </div>
              )}
            </Field>
            <Field name="zip">
              {({field}) => (
                <div className="inputContainer zip">
                  <label className="label">
                    <FormattedMessage {...messages.zip} />
                  </label>
                  <Input error={touched.zip && errors.zip} {...field} />
                </div>
              )}
            </Field>
          </div>
          <Field name="street">
            {({field}) => (
              <div className="inputContainer">
                <label className="label">
                  <FormattedMessage {...messages.street} />
                </label>
                <Input error={touched.street && errors.street} {...field} />
              </div>
            )}
          </Field>
          <Field name="apartment">
            {({field}) => (
              <div className="inputContainer">
                <label className="label">
                  <FormattedMessage {...messages.apartment} />
                </label>
                <Input error={touched.apartment && errors.apartment} {...field} />
              </div>
            )}
          </Field>

          {/*<Field name="region">
            {({field}) => (
              <div className="inputContainer">
                <label className="label">
                  <FormattedMessage {...messages.region} />
                </label>
                <Select
                  {...field}
                  options={countries.map(countrie => countrie.name)}
                  placeholder="Select"
                />
              </div>
            )}
          </Field>*/}

          <Field name="region">
            {({field}) => (
              <div className="inputContainer">
                <label className="label">
                  <FormattedMessage {...messages.region} />
                </label>
                <Input error={touched.region && errors.region} {...field} />
              </div>
            )}
          </Field>

          <Field name="city">
            {({field}) => (
              <div className="inputContainer">
                <label className="label">
                  <FormattedMessage {...messages.city} />
                </label>
                <Input error={errors.city} {...field} />
              </div>
            )}
          </Field>
          <div className="billInfo">
            <FormattedMessage {...messages.toConfirmAddress} />
          </div>
          <div className="billInput">
            {!values.bill && (
              <>
                <FileInputText
                  accept=".jpg, .jpeg, .png, .pdf"
                  error={errors.bill}
                  onChange={event => setFieldValue("bill", event.target.files[0].name)}
                >
                  <FormattedMessage {...messages.attachBill} />
                </FileInputText>
              </>
            )}
            {values.bill && (
              <>
                <div className="name">{values.bill}</div>
                <img
                  src={deleteIcon}
                  className="deleteIcon"
                  alt="delete"
                  onClick={() => setFieldValue("bill", "")}
                />
              </>
            )}
          </div>
          <div className="buttonsContainer">
            <Button
              type="button"
              text={messages.cancel}
              isSecondary="true"
              onClick={() => {
                closeRightModal();
              }}
            />
            {/*<Button disabled={!values.bill} type="sumbit" text={messages.applyToChange} />*/}
            <Button type="sumbit" text={messages.applyToChange} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = store => ({
  userProfile: selectUserProfile(store),
});

const mapDispatchToProps = {
  closeRightModal,
  updateUserProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeAddressForm);
