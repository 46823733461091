import React, {Fragment} from "react";
import {FormattedMessage} from "react-intl";
// Components
import ChartComposition from "../CustomersChart";
import CustomersTable from "../CustomersTable";
// Utils
import messages from "../../messages";
// Styles
import "./index.scss";

const CustomersPageBody = () => {
  return (
    <Fragment>
      <div className="customers content">
        {/* Customers Info */}
        <div className="customers-info">
          <div className="customers-info__item">
            <div className="customers-info__item-number">380</div>
            <div className="customers-info__item-description">
              <FormattedMessage {...messages.totalCustomers} />
            </div>
          </div>
          <div className="customers-info__item">
            <div className="customers-info__item-number">+19</div>
            <div className="customers-info__item-description">
              <FormattedMessage {...messages.last24hours} />
            </div>
          </div>
          <div className="customers-info__item">
            <div className="customers-info__item-number">328</div>
            <div className="customers-info__item-description">
              <FormattedMessage {...messages.privateIndividuals} />
            </div>
          </div>
          <div className="customers-info__item">
            <div className="customers-info__item-number">52</div>
            <div className="customers-info__item-description">
              <FormattedMessage {...messages.companies} />
            </div>
          </div>
        </div>

        {/* Customers Chart */}
        <div className="customers-chart">
          <ChartComposition />
        </div>

        {/* Customers Table*/}
        <div className="customers-table">
          <CustomersTable />
        </div>
      </div>
    </Fragment>
  );
};

export default CustomersPageBody;
