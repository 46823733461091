import React from "react";
// Styles
import "./index.scss";

const mockData = [
  {name: "Stripe", status: "ok"},
  {name: "Dwolla", status: "ok"},
  {name: "Razorpay", status: "ok"},
  {name: "Binance", status: "ok"},
];

const DownHeader = () => {
  return (
    <>
      <div className="info down-header content">
        <div className="info-title">External:</div>
        <div className="internal">
          {mockData.map((item, index) => (
            <div
              className={`internal__item ${
                item.status === "error" ? "internal-error" : ""
              }`}
              key={index}
            >
              <div
                className={`internal__item-name ${
                  item.status === "error" ? "internal-error" : ""
                } `}
              >
                {item.name}
              </div>
              <div
                className={`internal__item-status ${
                  item.status === "error" ? "internal-error" : ""
                }`}
              >
                {item.status}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DownHeader;
