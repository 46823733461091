import {defineMessages} from "react-intl";

const scope = "DashboardAdminPage";

const messages = defineMessages({
  headerDashboardTitle: {
    id: `${scope}.headerDashboardTitle`,
    defaultMessage: "Dashboard",
  },
  headerCart: {
    id: `${scope}.headerCard`,
    defaultMessage: "Cart",
  },
  onwalletAccount: {
    id: `${scope}.onwalletAccount`,
    defaultMessage: "OnWallet Account",
  },
  bitcoinAccount: {
    id: `${scope}.bitcoinAccount`,
    defaultMessage: "Bitcoin Account",
  },
  bitcoinWallet: {
    id: `${scope}.bitcoinWallet`,
    defaultMessage: "Bitcoin Wallet",
  },
  dehoWalletAccount: {
    id: `${scope}.dehoWalletAccount`,
    defaultMessage: "Deho Wallet",
  },
  showAll: {
    id: `${scope}.showAll`,
    defaultMessage: "Show All",
  },
  completed: {
    id: `${scope}.completed`,
    defaultMessage: "Completed",
  },
  pendingTransactions: {
    id: `${scope}.pendingTransactions`,
    defaultMessage: "Pending Transactions",
  },
  lastTransactions: {
    id: `${scope}.lastTransactions`,
    defaultMessage: "Last Transactions",
  },
  allCustomers: {
    id: `${scope}.allCustomers`,
    defaultMessage: "All customers",
  },
  brokerCommission: {
    id: `${scope}.brokerCommission`,
    defaultMessage: "Broker Commission",
  },
  saveChanges: {
    id: `${scope}.saveChanges`,
    defaultMessage: "Save Changes",
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: "Cancel",
  },
});

export default messages;
