import React from "react";
import {connect} from "react-redux";
import RightModal from "shared/components/RightModal";
import {FormattedMessage} from "react-intl";
import messages from "../../messages";
import ChangeNameForm from "../ChangeDetailsForm/ChangeNameForm";
import ChangeAddressForm from "../ChangeDetailsForm/ChangeAddressForm";
import ChangePhoneForm from "../ChangeDetailsForm/ChangePhoneForm";
import ResetPasswordForm from "../ResetPasswordForm";
import ChangeCompanyName from "../ChangeDetailsForm/ChangeCompanyNameForm";

const modalVariants = {
  changeName: "changeName",
  changePhone: "changePhone",
  changeAddress: "changeAddress",
  changePassword: "changePassword",
  changeCompanyName: "changeCompanyName",
};

const getModalDataByVariant = variant => {
  switch (variant) {
    case modalVariants.changeName:
      return {
        title: <FormattedMessage {...messages.changeName} />,
      };
    case modalVariants.changePhone:
      return {
        title: <FormattedMessage {...messages.changeCellPhone} />,
      };
    case modalVariants.changeAddress:
      return {
        title: <FormattedMessage {...messages.changeAddress} />,
      };
    case modalVariants.changePassword:
      return {
        title: <FormattedMessage {...messages.resetPassword} />,
      };
    case modalVariants.changeCompanyName:
      return {
        title: <FormattedMessage {...messages.changeCompanyName} />,
      };
    default:
      return null;
  }
};

const PersonalDetailsModals = ({variant, setChangeName, setChangeAddress}) => {
  const modalData = getModalDataByVariant(variant);
  return (
    <>
      {modalData && (
        <RightModal title={modalData && modalData.title ? modalData.title : null}>
          {variant === modalVariants.changeName && (
            <ChangeNameForm setChangeName={setChangeName} />
          )}
          {variant === modalVariants.changePhone && <ChangePhoneForm />}
          {variant === modalVariants.changeAddress && (
            <ChangeAddressForm setChangeAddress={setChangeAddress} />
          )}
          {variant === modalVariants.changePassword && <ResetPasswordForm />}
          {variant === modalVariants.changeCompanyName && <ChangeCompanyName />}
        </RightModal>
      )}
    </>
  );
};

const mapStateToProps = store => ({
  variant: store.rightModalData.variant,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetailsModals);
