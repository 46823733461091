import React from "react";
import {FormattedMessage} from "react-intl";
import {Field} from "formik";
import Tooltip from "shared/components/Tooltip";
import SimpleCardInput from "../SimpleCardInput";

import "./index.scss";
import messages from "./messages";

import config from "shared/config";
const payPassIcon = config.prefixImgUrl + "pay-pass-icon.svg";
const visaGrayIcon = config.prefixImgUrl + "visa-icon-gray.svg";

const convertExpiryData = value => {
  const v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
  const matches = v.match(/\d{1,4}/g);
  const match = (matches && matches[0]) || "";
  const parts = [];
  for (let i = 0, len = match.length; i < len; i += 2) {
    parts.push(match.substring(i, i + 2));
  }
  if (parts.length) {
    return parts.join("/");
  } else {
    return v;
  }
};

const convertCVVCode = value => {
  const v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
  const matches = v.match(/\d{1,3}/g);
  const match = (matches && matches[0]) || "";
  if (match) {
    return match;
  } else {
    return v;
  }
};

const CardInput = ({form}) => {
  const {touched, errors} = form;
  return (
    <>
      <div className="CardInputContainer">
        <div className="CardInputFront">
          <div className="FrontHeader">
            <img src={payPassIcon} alt="icon" />
            <img src={visaGrayIcon} alt="visa" />
          </div>
          <Field
            name="number"
            render={({field}) => (
              <div className="inputContainer">
                <label className="label">
                  <FormattedMessage {...messages.cardNumber} />
                </label>
                <SimpleCardInput
                  {...field}
                  type="text"
                  withoutError={true}
                  className="numberInput"
                  error={touched.number && errors.number}
                  placeholder="0000 0000 0000 0000"
                />
              </div>
            )}
          />
          <div className="FrontFooter">
            <Field
              name="holder"
              render={({field}) => (
                <div className="inputContainer holder">
                  <label className="label">
                    <FormattedMessage {...messages.cardHolder} />
                  </label>
                  <input
                    type="text"
                    className={`holderInput ${
                      touched.holder && errors.holder ? "error" : ""
                    }`}
                    placeholder="Name here"
                    {...field}
                  />
                </div>
              )}
            />
            <Field
              name="expiry"
              render={({field}) => (
                <div className="inputContainer expire">
                  <label className="label">
                    <FormattedMessage {...messages.expires} />
                  </label>
                  <input
                    {...field}
                    type="text"
                    className={`expireInput ${
                      touched.expiry && errors.expiry ? "error" : ""
                    }`}
                    placeholder="MM/YY"
                    onChange={e => {
                      e.target.value = convertExpiryData(e.target.value);
                      field.onChange(e);
                    }}
                  />
                </div>
              )}
            />
          </div>
        </div>
        <div className="CardInputBack">
          <div className="BackHeader">
            <label className="side">
              <FormattedMessage {...messages.backSide} />
            </label>
            <div className="line" />
          </div>
          <div className="BackFooter">
            <Field
              name="cvc"
              render={({field}) => (
                <div className="inputContainer holder">
                  <label className="label">
                    CVV{" "}
                    <Tooltip label={<span className="info" data-tip="info-tooltip" />}>
                      <FormattedMessage {...messages.threeDigitsNumber} />
                    </Tooltip>
                  </label>
                  <input
                    {...field}
                    type="text"
                    className={`cvcInput ${touched.cvc && errors.cvc ? "error" : ""}`}
                    placeholder="000"
                    onChange={e => {
                      e.target.value = convertCVVCode(e.target.value);
                      field.onChange(e);
                    }}
                  />
                </div>
              )}
            />
          </div>
        </div>
      </div>

      {errors[Object.keys(errors)[0]] && touched[Object.keys(errors)[0]] && (
        <div className="CardInputError">{errors[Object.keys(errors)[0]]}</div>
      )}
    </>
  );
};

export default CardInput;
