import React, {Fragment} from "react";
import config from "shared/config";
import {REFUND_POLICY} from "./constance";
// Styles
import "../../index.scss";

const tablePng = config.prefixImgUrl + "refund_policy_table.png";

const RefundPolicy = () => {
  return (
    <Fragment>
      <div className="document-wrapper text__container_article">
        <div className="text__container_title">Definitions: </div>

        <div>
          <span>Blocked Transactions - </span> Transactions for which processing cannot
          continue until specific resources are available.
          <br />
          <span>Chargeback - </span>This is a charge that is returned to a payment card
          after a customer disputes an item on their account statement or transaction
          report.
          <br />
          <span>Commercial Transaction - </span>It refers to buying and selling of goods
          or services using internet, and the transfer of money and data to execute these
          transactions.
          <br />
          <span>Credit Card - </span>It is a card issued by a financial institution that
          lets the user borrow money to make a purchase.
          <br />
          <span>Debit Card - </span>It is a Payment card that deducts money directly from
          a users check in account to pay for a purchase.
          <br />
          <span>Loading Transactions -</span> Transactions that are deemed to be pending
          due to technical/server issues either on the issuing bank side or OnWallet.
          <br />
          <span>Refund - </span>An amount of money that is returned as repayment.
          <br />
          <span>Seller - </span>An individual/entity who exchanges any goods or services
          in return for payment.
          <br />
          <span>User - </span>For the purpose of this policy, User or You means any person
          who as an access to our website and who is not either i) employed by OnWallet or
          acting in the course of their employment or ii) engaged in providing services to
          OnWallet and accessing the website in connection with the provisions of such
          services.
          <br />
          <span>Applicable law:</span> means all laws, statutes, by-laws, rules,
          regulations, orders, ordinances, protocols, codes, guidelines, policies,
          notices, directions, judgments, decrees, arbitral awards or other requirements
          or official directive of any Governmental Authority or person acting under the
          authority of any Governmental Authority of that respective Country. The singular
          shall include the plural and vice versa, and the masculine shall include the
          feminine and vice versa.
          <br />
          <span>Governing Law:</span> the rules and laws that govern the agreement in case
          of any legal issues/disputes that arise out of this agreement.
          <div className="mt-4">
            A reference to any legislation shall include any statutory modification or
            re-enactment thereof or any statutory provisions substituted therefore.
          </div>
        </div>

        <div className="mt-6 text__container_title">Commercial Transactions</div>
        <div>
          All the transactions, processed through our App are non-refundable and
          non-reversible/cancellable as OnWallet does not assume any liability,
          responsibility, or ownership for;
        </div>
        <div className="document-wrapper-restrictions__items">
          {REFUND_POLICY.map((item, index) => (
            <div className="document-wrapper-restrictions__items__item" key={index}>
              <div>{item.id}</div>
              <div>{item.text}</div>
            </div>
          ))}
        </div>

        <div className="mt-6 text__container_title">Revocation of a Transaction</div>

        <div>
          All transactions transmitted through OnWallet are irrevocable unless expressly
          mentioned in accordance with the terms of this policy. If the user revokes any
          incomplete, pending or loading transactions due to any technical or server
          issue, and if the money has been deducted from the users account, the same shall
          be refunded back to the user in accordance with the terms of this policy.
        </div>

        <div className="mt-6 text__container_title">Refunds</div>

        <div>In the event of any refund, the User</div>

        <ul>
          <li>
            agrees and acknowledges that they are solely responsible for the transaction
            and OnWallet does not assume any responsibility or liability for any improper
            transaction made by the user,
          </li>
          <li>
            shall make a request to OnWallet within 3-4 working days. OnWallet shall
            refund the amount as requested,
          </li>
          <li>
            •have not received any information about the amount deducted through our
            website/application within 24 hours of the completion of the transaction, the
            user shall inform OnWallet by sending an email at info@onwallet.com,
          </li>
        </ul>

        <div>
          In case of any dispute, our team will investigate the matter and if it is found
          that money has been deducted from the users account and the transaction has not
          been completed, OnWallet shall refund the money once the refund has been
          processed by the merchant/seller/vendor in accordance with the timeline
          mentioned below.
        </div>

        <div className="mt-6 image">
          <img src={tablePng} alt="table" />
        </div>

        <div className="mt-4">
          The maximum time taken by our App to refund back the transaction amount to the
          user is 21 working days from the receipt of the complaint through email
          info@onwallet.com.
        </div>

        <div className="mt-6 text__container_title">Revocation of a Transaction</div>

        <div>
          All transactions transmitted through OnWallet are irrevocable unless expressly
          mentioned in accordance with the terms of this policy. If the user revokes any
          incomplete, pending or loading transactions due to any technical or server
          issue, and if the money has been deducted from the users account, the same shall
          be refunded back to the user in accordance with the terms of this policy.
        </div>

        <div className="mt-6 text__container_title">
          OnWallet's right to block amounts
        </div>

        <div>
          OnWallet reserves all the rights to block any amount that is credited/available
          in their OnWallet account in the following scenarios;
        </div>

        <div className="mt-4">
          <ul>
            <li>any breach of terms of this agreement,</li>
            <li>
              the transaction is suspected to be illegal in accordance with the applicable
              laws of the respective country,
            </li>
            <li>
              because of non completion of users identification or verification with
              OnWallet.
            </li>
            <li>
              if the transaction has been found to be faulty or invalid by OnWallet;
            </li>
          </ul>
        </div>

        <div className="mt-6 text__container_title">Users Liability</div>

        <div>
          The users shall agree and acknowledge to assume liability to pay compensation
          relating to any charges, fees, fines, refunds or charge backs against OnWallet
          or any third party payments due to the user's failure to comply with the terms
          of this policy.
          <br />
          The users shall agree and acknowledge that they will be solely responsible for
          any fraud or illegal or unauthorized use or access of their information through
          our App. In case you detect or notice any use of your OnWallet account in any
          unauthorized/illegal/fraudulent manner, the users shall inform the same in
          writing to our email info@onwallet.com.
          <br />
          OnWallet does not assume any responsibility, liability, guarantee and obligation
          towards the users or any third party to the extent that the successful transfer
          of any data in the framework of any transaction depends on the good and/or
          uninterrupted operations of any electronic communications networks and/or
          facilities and/or applications which are the property or are managed by
          electronic communication service providers and/or network operators.
        </div>

        <div className="mt-6 text__container_title">
          Dispute Resolution and Governing law
        </div>

        <div>
          <div className="mt-4">
            In case of any disputes arising between the user and the seller/merchant or
            vendor, our App is not obligated to act as a mediator in resolving the
            dispute. However, our App is responsible for assisting in case of any
            grievances/complaints relating to transactions.
          </div>
          <div className="mt-4">
            Please note that our App is an intermediary and only acts as a facilitator
            between the seller and purchaser. Neither our App nor its affiliates of
            officers, directors, or employees shall have any liability or responsibility
            neither towards the user nor any third party for any indirect, incidental,
            specific, consequential damages or any loss of revenue arising out of any
            transactions made using our App.
          </div>
          <div className="mt-4">
            In case of any dispute resolution relating to the rights and obligations
            arising out of this policy, the user may email regarding the same to the
            following email address info@onwallet.com. Onwallet tries to resolve the
            complaint or grievance amicably by and in the event the dispute stands
            unresolved, both the parties (OnWallet and User) shall mutually agree and
            consent to appoint a Single Arbitrator for resolving the issue at the
            International Arbitration Centre, London located at 190 Fleet St, Holborn,
            London EC4A 2AG, United Kingdom.
          </div>
          <div className="mt-4">
            The validity, construction and performance of this agreement shall be governed
            by English Courts.
          </div>
        </div>

        <div className="mt-6 text__container_title">Suspension of an Account</div>

        <div>
          OnWallet reserves absolute right to suspend the operations of any Users in the
          following events:
        </div>

        <div className="mt-4">
          <ul>
            <li>
              the OnWallet account is used for any illegal or fraudulent activities
              according to the laws of their respective Country;
            </li>
            <li>any illegal, fraudulent, unauthorized or suspected transactions;</li>
            <li>
              the user fails to comply with the terms and conditions mentioned in the
              website and this policy;
            </li>
            <li>if the users opts for deletion or removal of their OnWallet account;</li>
            <li>
              if the account of the user is required to be suspended according to the
              provisions of their respective Country's law or legislation;
            </li>
            <li>
              in case of any non compliance of any due diligence with the instructions
              imposed by International Card Schemes;
            </li>
          </ul>
        </div>
        <div>
          OnWallet shall inform or notify the user through the mobile application or email
          regarding the suspension or removal of their OnWallet account with immediate
          effect. In case, of suspension of any User's account by OnWallet, the User
          cannot access or avail the services through their OnWallet account for a limited
          period of time which may extend for a period not extending for more than 3
          months or until the compliance has been remedied.
        </div>

        <div className="mt-6 text__container_title">
          Deletion of your OnWallet Account
        </div>
        <div>
          Upon deleting your account, all your pending transactions will be cancelled by
          OnWallet and if any fund is held by us at the time of deleting your account, we
          will return the same according to our Opt-Out policy after deducting the
          applicable fees.
        </div>
      </div>
    </Fragment>
  );
};

export default RefundPolicy;
