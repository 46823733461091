import React, {useEffect} from "react";
import {FormattedMessage} from "react-intl";
import messages from "../../../messages";

import Button from "shared/components/Button";
import {Field} from "formik";
import {getDialCodeByCountry, getDialCodes} from "shared/commonDataService/countries";
import PhoneNumberInput from "shared/components/PhoneNumberInput";

const FourthRegisterStep = ({
  step,
  setFieldValue,
  errors,
  values,
  setStep,
  validateForm,
  handleSubmit,
}) => {
  // Hooks
  useEffect(() => {
    setFieldValue("dialCode", getDialCodeByCountry(values.country || "US"));
  }, [setFieldValue, values.country]);

  const isDisabled = () => {
    return (
      !values.dialCode || !values.phoneNumber || errors.dialCode || errors.phoneNumber
    );
  };

  const isNotValid = errors => {
    return (
      !values.dialCode || errors.dialCode || !values.phoneNumber || errors.phoneNumber
    );
  };

  return (
    <div className="fourthStep">
      {step > 4 && (
        <div className="readyStep">
          <div className="stepTitle">
            <div className="title">
              <FormattedMessage {...messages.mobileNumber} />
            </div>
            <div className="separator" />
            <button className="titleEdit" onClick={() => setStep(4)}>
              <FormattedMessage {...messages.edit} />
            </button>
          </div>
          <div className="stepInfo">
            {values.dialCode}
            {values.phoneNumber}
          </div>
        </div>
      )}
      {step === 4 && (
        <>
          <div className="fourthStepTitle">
            <FormattedMessage {...messages.mobileNumberValidation} />
          </div>
          <Field name="phoneNumber">
            {({field}) => (
              <div className="inputContainer">
                <label className="label">
                  <FormattedMessage {...messages.mobileNumber} />
                </label>
                <PhoneNumberInput
                  error={errors.phoneNumber}
                  {...field}
                  dialValue={values.dialCode}
                  dialOptions={getDialCodes()}
                  dialOnChange={e => setFieldValue("dialCode", e.target.value)}
                />
              </div>
            )}
          </Field>

          <div>
            <Button
              type="submit"
              className="nextButton"
              disabled={isDisabled()}
              onClick={() => {
                validateForm().then(errors => {
                  if (!isNotValid(errors)) {
                    handleSubmit();
                    setStep(5);
                  }
                });
              }}
              text={messages.finishRegistration}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default FourthRegisterStep;
