import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import messages from "../../messages";

import Button from "shared/components/Button";
import {Field} from "formik";
import {getDialCodeByCountry, getDialCodes} from "shared/commonDataService/countries";
import PhoneNumberInput from "shared/components/PhoneNumberInput";
import CodeInput from "shared/components/CodeInput";

const FourthRegisterStep = ({
  step,
  setFieldValue,
  errors,
  values,
  setStep,
  validateForm,
  setErrors,
}) => {
  const [send, setSend] = useState(false);
  useEffect(() => {
    setFieldValue("dialCode", getDialCodeByCountry(values.country || "US"));
  }, [setFieldValue, values.country]);
  const isNotValid = errors => {
    return (
      !values.dialCode ||
      errors.dialCode ||
      !values.phoneNumber ||
      errors.phoneNumber ||
      !values.phoneCode ||
      errors.phoneCode
    );
  };
  return (
    <div className="fourthStep">
      {step > 4 && (
        <div className="readyStep">
          <div className="stepTitle">
            <div className="title">
              <FormattedMessage {...messages.mobileNumber} />
            </div>
            <div className="separator" />
            <button className="titleEdit" onClick={() => setStep(4)}>
              <FormattedMessage {...messages.edit} />
            </button>
          </div>
          <div className="stepInfo">
            {values.dialCode}
            {values.phoneNumber}
          </div>
        </div>
      )}
      {step === 4 && (
        <>
          <div className="fourthStepTitle">
            <FormattedMessage {...messages.mobileNumberValidation} />
          </div>
          <Field name="phoneNumber">
            {({field}) => (
              <div className="inputContainer">
                <label className="label">
                  <FormattedMessage {...messages.mobileNumber} />
                </label>
                <PhoneNumberInput
                  disabled={send}
                  error={errors.phoneNumber}
                  {...field}
                  dialValue={values.dialCode}
                  dialOptions={getDialCodes()}
                  dialOnChange={e => setFieldValue("dialCode", e.target.value)}
                />
              </div>
            )}
          </Field>
          <div className="smsInfo">
            {!send && <FormattedMessage {...messages.weWillSendOnThisNumberSMS} />}
            {send && <FormattedMessage {...messages.weSentOnThisPhoneSMS} />}
          </div>
          {send && (
            <div className="codeInputWrapper">
              <CodeInput
                error={errors.phoneCode}
                onChange={value => setFieldValue("phoneCode", value)}
              />
            </div>
          )}
          {!send && (
            <div>
              <Button
                type="button"
                className="nextButton"
                onClick={() => {
                  validateForm().then(errors => {
                    if (!errors.phoneNumber) {
                      setErrors({});
                      setSend(true);
                    }
                  });
                }}
                text={messages.sendCode}
              />
              <div className="copyright">{messages.copyRight.defaultMessage}</div>
            </div>
          )}
          {send && (
            <div>
              <Button
                type="button"
                className="nextButton"
                onClick={() => {
                  validateForm().then(errors => {
                    if (!isNotValid(errors)) {
                      setStep(5);
                    }
                  });
                }}
                text={messages.next}
              />

              <div className="copyright">{messages.copyRight.defaultMessage}</div>
            </div>
          )}
          {send && (
            <div className="issueInfo">
              <div className="text">
                <FormattedMessage {...messages.iHaveIssue} />
              </div>
              <div className="button">
                <FormattedMessage {...messages.sendNewCode} />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default FourthRegisterStep;
