/**
 * @author: Gnapovska
 */

import React, {Component} from "react";
import {connect} from "react-redux";

import {Link} from "react-router-dom";

import {getCurrentOrdersItems} from "shared/ordersService/selectors";
import Checkbox from "shared/components/Checkbox";

import OpenOrderItem from "./OpenOrderItem";
import "./index.scss";
import {routes} from "shared/appUrls";

class Orders extends Component {
  constructor(props) {
    super(props);

    this.onChangeTypeView = this.onChangeTypeView.bind(this);

    this.state = {
      typeView: "mix",
      dataListOpenOrders: this.props.currentOrders,
      selected: [],
      isAllSelected: "",
    };
  }

  countShowBuy() {
    return this.state.typeView === "mix" ? 10 : this.state.typeView === "buy" ? 20 : 0;
  }
  countShowSel() {
    return this.state.typeView === "mix" ? 10 : this.state.typeView === "sel" ? 20 : 0;
  }

  getRandom(min, max) {
    return Math.random() * (max - min) + min;
  }

  onChangeTypeView(e) {
    this.setState({
      typeView: e.target.value,
    });
  }

  handleClick = name => {
    const selectedIndex = this.state.selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selected.slice(1));
    } else if (selectedIndex === this.state.selected.length - 1) {
      newSelected = newSelected.concat(this.state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.selected.slice(0, selectedIndex),
        this.state.selected.slice(selectedIndex + 1)
      );
    }

    this.setState({
      selected: newSelected,
    });
  };

  isSelected = name => {
    return this.state.selected.indexOf(`${name}`) !== -1;
  };

  childChecked = childData => {
    this.handleClick(childData[0]);
    if (!childData[1]) {
      this.setState({isAllSelected: false});
    }
  };

  render() {
    return (
      <div className="open-orders widget">
        <div className="widget__wrapper">
          <div className="widget__header">
            <div className="widget__header__title">
              <h2>Open Orders</h2>
            </div>
            <div className="widget__header__tools">
              <label
                className="Action"
                onClick={() => {
                  const newList = this.state.dataListOpenOrders.filter(
                    (item, key) => !this.isSelected(item.id)
                  );
                  this.setState({dataListOpenOrders: newList, selected: []});
                }}
              >
                {"Cancel Selected"}
              </label>
              <Link className="Action" to={routes.orderlist}>
                {"Details"}
              </Link>
            </div>
          </div>

          <div className="widget__body">
            <div className="widget__body__head">
              <div className="title-item">
                <Checkbox
                  value={
                    this.state.isAllSelected ||
                    this.state.selected.length === this.state.dataListOpenOrders.length
                  }
                  onChange={event => {
                    this.setState(
                      {
                        isAllSelected: event.target.checked,
                      },
                      () => {
                        const newList = [];
                        if (this.state.isAllSelected) {
                          this.state.dataListOpenOrders.map((item, key) => {
                            newList.push(item.id);
                            return true;
                          });
                        }
                        this.setState({selected: newList});
                      }
                    );
                  }}
                ></Checkbox>
              </div>
              <div className="title-item" style={{marginLeft: "4px"}}>
                Side {"/"} Amount {"/"} Pair
              </div>
              <div className="title-item">Total</div>
            </div>

            <div className="widget__body__content">
              <div className="data-items">
                {this.state.dataListOpenOrders.map((item, key) => {
                  return (
                    <OpenOrderItem
                      key={item.id}
                      item={item}
                      selected={this.isSelected(item.id)}
                      callChecked={this.childChecked}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  currentOrders: getCurrentOrdersItems(store),
});

function mapDispatchToProps(dispatch) {}
export default connect(mapStateToProps, mapDispatchToProps)(Orders);
