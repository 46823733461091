/**
 * @author: Ihor Bazyliuk
 */
import React, {Component} from "react";
import {connect} from "react-redux";
import {openRightModal} from "shared/components/RightModal/actions";
import messages from "../../messages";
import {FormattedMessage} from "react-intl";
import config from "shared/config";

class OrderRequestsItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: null,
    };
  }

  componentDidMount() {
    // this.loadImage(this.props.item.img);
    this.setState({src: config.prefixImgUrl + this.props.item.img});
  }
  //
  // loadImage(name) {
  //   import(config.prefixImgUrl + name).then(image => {
  //     this.setState({src: image});
  //   });
  // }

  render() {
    let item = this.props.item;
    return (
      <div className="order-requests__metal-item">
        <div className="metal-item__img-wrapper">
          <img src={this.state.src} alt="" />
        </div>
        <div className="metal-item__txt-metal-wrapper">
          <span className="txt-metal__name">{item.metal}</span>{" "}
          <span className="txt-metal__val">
            {item.metal_value > 0 ? item.metal_value : ""}
          </span>
        </div>
        <div className="metal-item__txt-currency1-wrapper">
          <span className="txt-currency__val">{item.kg}</span>{" "}
          <span className="txt-currency__name">Kg</span>
        </div>
        <div
          className="metal-item__price"
          //TODO: Implement redirect to coin registry
        >
          {`${item.sum_currency} USD`}
        </div>
        <div className="metal-item__txt-currency2-wrapper">
          <span className="txt-currency__note">
            <FormattedMessage {...messages.linked} />
          </span>{" "}
          <span className="txt-currency__val">{item.sum_currency2}</span>{" "}
          <span className="txt-currency__name">{item.currency2}</span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  openRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderRequestsItem);
