import React from "react";
import {connect} from "react-redux";
import {closeRightModal} from "./actions";
import config from "shared/config";
import "./index.scss";
import {ReactComponent as CloseIcon} from "images/close-icon.svg";

// Images
const printIcon = config.prefixImgUrl + "print_icon.svg";

const RightModal = ({
  children,
  title,
  subTitle,
  show,
  icon,
  image,
  button,
  buttonAction,
  buttonType,
  close,
  closeRightModal,
  className,
}) => {
  return (
    <div className={`RightModalWrapper ${show ? "show" : "hide"}`}>
      <div className="background" />
      <div className={`RightModalContainer ${className ? className : ""}`}>
        {icon && <img className="HeaderIcon" src={icon} alt="header-icon" />}
        <div className="RightModalHeader">
          <CloseIcon
            className="closeButton"
            onClick={!!close ? close : closeRightModal}
          />
          <div className="titles">
            {image && (
              <div className="image">
                <img src={image} alt="header-img" />
              </div>
            )}
            <div className="text">
              <h1 className="main">{title ? title : ""}</h1>
              <h2 className="sub">{subTitle ? subTitle : ""}</h2>
            </div>
          </div>
          {button && (
            <button className="HeaderButton" onClick={buttonAction}>
              {button}
            </button>
          )}
          {buttonType === "print" && (
            <div className="HeaderPrint">
              <img src={printIcon} alt="Print icon" />
              <div className="HeaderPrint__text">print</div>
            </div>
          )}
        </div>
        <div className="RightModalContent">{children}</div>
      </div>
    </div>
  );
};

const mapStateToProps = store => ({
  show: store.rightModalData.show,
});

const mapDispatchToProps = {
  closeRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(RightModal);
