/**
 * @author: Seleznyov
 */

import React, {Component} from "react";
import {connect} from "react-redux";

import "../../index.scss";
import messages from "../../messages";
import walletsMessage from "components/WalletsPage/messages";
import {getShortAddress} from "shared/walletService/selectors";
import ToggleMenu from "shared/components/ToggleMenu";
import SearchInput from "shared/components/SearchInput";
import {
  openContactsModal,
  updateSearchValueShared,
} from "../ContactsDetailsModal/actions";

import config from "shared/config";
import {FormattedMessage} from "react-intl";
import {openRightModal} from "shared/components/RightModal/actions";
import {Scrollbars} from "react-custom-scrollbars";
import {ReactComponent as ReceiveIcon} from "images/receive-icon.svg";
import {ReactComponent as SettingIcon} from "images/settings-icon-gray.svg";
import {ReactComponent as TransferIcon} from "images/transfer-icon.svg";

const receiveOnWalletIcon = config.prefixImgUrl + "recieve-onwallet-icon.svg";
const ownCardIconSecond = config.prefixImgUrl + "own-card-icon-second.svg";
const walletIconSecond = config.prefixImgUrl + "wallet-icon-second.svg";
const shareIcon = config.prefixImgUrl + "share-icon.svg";
const editIcon = config.prefixImgUrl + "edit-icon-short.svg";
const defaultIcon = config.prefixImgUrl + "userdefault-icon.svg";

class ContactView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
    };

    this.handlerChange = this.handlerChange.bind(this);
  }

  handlerChange = e => {
    this.setState({search: e.target.value});
    this.props.updateSearchValueShared(e.target.value);
  };

  handlerDefineDeho = selected => {
    const dehoWallet = !!selected
      ? selected.info.cryptoWallets.find(item => item.currency === "dhg")
      : "";

    return dehoWallet;
  };

  render() {
    const {selected, openRightModal, openContactsModal} = this.props;

    return (
      <div className="contacts__block view">
        <div className="contact__container_view">
          {selected && (
            <>
              <ToggleMenu
                className="contact_menu_container receive"
                label={() => (
                  <div className="contact_menu_label">
                    <ReceiveIcon className="menuIcon" />
                  </div>
                )}
              >
                <div
                  className="header_menu_item receive"
                  onClick={() => openRightModal("receiveOnWallet")}
                >
                  <img src={receiveOnWalletIcon} className="icon" alt="icon" />
                  <div className="text">
                    <h1 className="title">
                      <FormattedMessage {...walletsMessage.toOwnOnwalletAccount} />
                    </h1>
                    <h2 className="subTitle">
                      <FormattedMessage
                        {...walletsMessage.createInvoiceWithYourAccount}
                      />
                    </h2>
                  </div>
                </div>
                <div
                  className="header_menu_item receive"
                  onClick={() => openRightModal("receiveCard")}
                >
                  <img src={ownCardIconSecond} className="icon" alt="icon" />
                  <div className="text">
                    <h1 className="title">
                      <FormattedMessage {...walletsMessage.toOwnCard} />
                    </h1>
                    <h2 className="subTitle">
                      <FormattedMessage
                        {...walletsMessage.transferCreateInvoiceWithCard}
                      />
                    </h2>
                  </div>
                </div>
                <div
                  className="header_menu_item receive"
                  onClick={() => openRightModal("receiveWallet")}
                >
                  <img src={walletIconSecond} className="icon" alt="icon" />
                  <div className="text">
                    <h1 className="title">
                      <FormattedMessage {...walletsMessage.toOwnCryptoWallet} />
                    </h1>
                    <h2 className="subTitle">
                      <FormattedMessage
                        {...walletsMessage.transferCreateInvoiceWithCrypto}
                      />
                    </h2>
                  </div>
                </div>
              </ToggleMenu>
              <div className="contact__head">
                <div className="contact__head_container">
                  <div className="contact__head__img">
                    <img
                      src={!!selected.picture ? selected.picture.medium : defaultIcon}
                      alt="user icon"
                    />
                  </div>
                </div>
                <div className="contact__head__nameblock">
                  <h2 className="contact__head__name">
                    {selected.info.firstName} {selected.info.lastName}
                  </h2>
                  <ToggleMenu
                    className="contact_menu_container settings"
                    label={() => (
                      <div className="contact_menu_label settings">
                        <SettingIcon className="settingsIcon" />
                      </div>
                    )}
                  >
                    <div
                      className="header_menu_item settings"
                      onClick={() =>
                        openContactsModal(
                          "shareContact",
                          `Share ${selected.info.firstName} ${selected.info.lastName}`,
                          <SearchInput
                            value={this.state.search}
                            onChange={this.handlerChange}
                            onClick={() => {
                              this.setState({search: ""});
                              updateSearchValueShared("");
                            }}
                            placeholder={messages.input.defaultMessage}
                          />,
                          selected,
                          false,
                          "RightModalContainer-small-width",
                          this.state.search
                        )
                      }
                    >
                      <img src={shareIcon} className="icon" alt="icon" />
                      <div className="text">
                        <FormattedMessage {...messages.shareContact} />
                      </div>
                    </div>
                    <div
                      className="header_menu_item settings"
                      onClick={() =>
                        openContactsModal(
                          "editContact",
                          `${selected.info.firstName} ${selected.info.lastName}`,
                          <FormattedMessage {...messages.editContact} />,
                          selected,
                          defaultIcon
                        )
                      }
                    >
                      <img src={editIcon} className="icon" alt="icon" />
                      <div className="text">
                        <FormattedMessage {...messages.edit} />
                      </div>
                    </div>
                  </ToggleMenu>
                </div>
              </div>
              <Scrollbars
                style={{zIndex: "-1"}}
                renderTrackVertical={props => (
                  <div {...props} className="track-vertical " />
                )}
                renderThumbVertical={props => (
                  <div {...props} className="thumb-vertical " />
                )}
              >
                <div className="contact__main table">
                  <div className="contact__main__row">
                    <div className="contact__atribut company_name">
                      <FormattedMessage {...messages.companyName} />
                    </div>
                    <div className="contact__atribut_value company_name">
                      {selected.info.companyName ? selected.info.companyName : ""}
                    </div>
                  </div>
                  <div className="contact__main__row">
                    <div className="contact__atribut OnWalletAccount">
                      <FormattedMessage {...messages.onWalletAccount} />
                    </div>
                    <div className="contact__atribut_value OnWalletAccount">
                      {selected.info.onWalletAccount}
                    </div>
                    <div
                      className="contact_transfer_button"
                      onClick={() => openRightModal("transferToSomeoneOnWallet")}
                    >
                      <TransferIcon className="icon" />
                    </div>
                  </div>
                  <div className="contact__main__row">
                    <div className="contact__atribut Phone">
                      <FormattedMessage {...messages.phone} />
                    </div>
                    <div className="contact__atribut_value Phone">
                      {selected.info.phone}
                    </div>
                  </div>
                  <div className="contact__main__row">
                    <div className="contact__atribut E-mail">
                      <FormattedMessage {...messages.email} />
                    </div>
                    <div className="contact__atribut_value E-mail">
                      {selected.info.email}
                    </div>
                  </div>
                  <div className="contact__main__row">
                    <div className="contact__atribut Address">
                      <FormattedMessage {...messages.address} />
                    </div>
                    <div className="contact__atribut_value Address">
                      {selected.info.address ? selected.info.address : ""}
                    </div>
                  </div>
                  <div className="contact__main__row">
                    <div className="contact__atribut Deho Wallet">
                      <FormattedMessage {...messages.dehoWallet} />
                    </div>
                    <div className="contact__atribut_value Deho Wallet">
                      {getShortAddress(
                        this.handlerDefineDeho(selected)
                          ? this.handlerDefineDeho(selected).account
                          : ""
                      )}
                    </div>
                    <div
                      className="contact_transfer_button"
                      onClick={() => openRightModal("transferOwnWallet")}
                    >
                      <TransferIcon className="icon" />
                    </div>
                  </div>
                  <div className="contact__main__row">
                    <div className="contact__atribut Bank Account">
                      <FormattedMessage {...messages.bankAccount} />
                    </div>
                    <div className="contact__atribut_value Bank Account">
                      {selected.info.bankAccounts.length
                        ? `* ${selected.info.bankAccounts[0].slice(-5)}`
                        : ""}
                    </div>
                    <div
                      className="contact_transfer_button"
                      onClick={() => openRightModal("transferToBankDetails")}
                    >
                      <TransferIcon className="icon" />
                    </div>
                  </div>
                  <div className="contact__main__row">
                    <div className="contact__atribut Card Number">
                      <FormattedMessage {...messages.cardNumber} />
                    </div>
                    <div className="contact__atribut_value Card Number">
                      {!!selected.info.cards[0]
                        ? `**** ${selected.info.cards[0].slice(-4)}`
                        : ""}
                    </div>
                    <div
                      className="contact_transfer_button"
                      onClick={() => openRightModal("transferSomeoneCard")}
                    >
                      <TransferIcon className="icon" />
                    </div>
                  </div>
                </div>
              </Scrollbars>
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({});

const mapDispatchToProps = {
  openRightModal,
  openContactsModal,
  updateSearchValueShared,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactView);
