import moment from "moment";

export const getCurrencyRates = store => {
  if (!store.currencyRatesData.rates) return {};
  return store.currencyRatesData.rates;
};

export const getCurrencyRate = store => {
  return !!store.currencyRatesData.currencyRate
    ? store.currencyRatesData.currencyRate.chartData.map(item => {
        return {name: moment(item.name).format("YYYY-MM-DD"), value: item.value};
      })
    : null;
};

export const getCurrencyRateWithTime = store => {
  return !!store.currencyRatesData.currencyRate
    ? store.currencyRatesData.currencyRate.chartData.map(item => {
        return {name: moment(item.name).format("YYYY-MM-DD hh:mm"), value: item.value};
      })
    : null;
};

export const getCurrencyRatesTable = store => {
  return !!store.currencyRatesData.currencyRatesTable.currency
    ? store.currencyRatesData.currencyRatesTable
    : null;
};

export const getLoading = store => {
  return store.currencyRatesData.isLoading;
};
