import React, {useRef, useState} from "react";
import {Link} from "react-router-dom";
import "./index.scss";
import PropTypes from "prop-types";
import {useClickOutside} from "shared/hooks";

const Header = ({
  headerTitle,
  logoImage,
  navLinks,
  children,
  link,
  headerStyle,
  middleStyle,
  navBarStyle,
  logoStyle,
}) => {
  return (
    <nav className={`HeaderContainer ${headerStyle}`}>
      <div className={`HeaderLogo ${logoStyle || ""}`}>
        {link ? (
          <Link className="HeaderBrandLink" to={link}>
            {logoImage && (
              <img src={logoImage} className="HeaderBrand active" alt="logo" />
            )}
          </Link>
        ) : (
          logoImage && <img src={logoImage} className="HeaderBrand active" alt="logo" />
        )}
        {headerTitle}
      </div>
      <div className={`HeaderMiddle ${middleStyle || ""}`}>{children}</div>
      <ul className={`HeaderNavLinksContainer ${navBarStyle || ""}`}>
        {navLinks.map((navItem, index) => (
          <HeaderLink key={index} navItem={navItem} />
        ))}
      </ul>
    </nav>
  );
};

const HeaderLink = ({navItem}) => {
  const [active, setActive] = useState();
  const linkRef = useRef(null);
  useClickOutside(linkRef, () => setActive(false));
  return (
    <>
      {navItem.leftBorder && <span className="HeaderNavbarBorder" />}
      <li
        ref={linkRef}
        className={`HeaderNavbarItem ${navItem.subList ? "subNavContainer" : ""} ${
          active ? "active" : "closed"
        } ${navItem.styles ? navItem.styles : ""}`}
        onClick={() => {
          if (navItem.rightModal) navItem.rightModal();
          setActive(true);
        }}
      >
        {navItem.link && (
          <Link className="NavbarItemLink" to={navItem.link}>
            {!!navItem.notifications && navItem.notifications !== 0 && (
              <span className="notifications">{navItem.notifications}</span>
            )}
            {navItem.icon && navItem.icon("HeaderNavbarIcon")}
            {navItem.text}
          </Link>
        )}
        {!navItem.link && navItem.rightModal && (
          <div className="NavbarItemLink">
            {!!navItem.notifications && navItem.notifications !== 0 && (
              <span className="notifications">{navItem.notifications}</span>
            )}
            {navItem.icon && navItem.icon("HeaderNavbarIcon")}
            {navItem.text}
          </div>
        )}
        {navItem.subList && <SubNav subNavList={navItem.subList} />}
      </li>
    </>
  );
};

const SubNav = ({subNavList}) => {
  return (
    <ul className="subNavList">
      {subNavList.map((item, index) => (
        <SubNavItem key={index} item={item} />
      ))}
    </ul>
  );
};

const SubNavItem = ({item}) => {
  return (
    <li className="subNavItem">
      {item.link && (
        <Link className="subNavItemLink" to={item.link}>
          <span className="wrapper-subnav-icon">
            <img className="subnav-icon" src={item.icon} alt="icon" />
          </span>
          <div className="subNavTitleText">
            <h1 className="subNavTitle">{item.title}</h1>
            <h2 className="subNavSubTitle">{item.subTitle}</h2>
          </div>
        </Link>
      )}
      {!item.link && item.rightModal && (
        <div className="subNavItemLink" onClick={() => item.rightModal()}>
          <span className="wrapper-subnav-icon">
            <img className="subnav-icon" src={item.icon} alt="icon" />
          </span>
          <div className="subNavTitleText">
            <h1 className="subNavTitle">{item.title}</h1>
            <h2 className="subNavSubTitle">{item.subTitle}</h2>
          </div>
        </div>
      )}
    </li>
  );
};

Header.propTypes = {
  headerTitle: PropTypes.object,
  logoImage: PropTypes.string,
  navLinks: PropTypes.array,
};

export default Header;
