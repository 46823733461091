export const getCurrentOrdersItems = store => {
  return [
    {
      id: "1",
      Date: "4 Mar 10:25:43",
      Pair: "BTO/USDT",
      Side: "0",
      Price: "5316.83 USDT",
      Amount: "0.150000 BTC",
      Filled: "0.00",
      Total: "797.523 USDT",
    },
    {
      id: "2",
      Date: "4 Mar 10:25:43",
      Pair: "BTO/USDT",
      Side: "0",
      Price: "5316.83 USDT",
      Amount: "0.150000 BTC",
      Filled: "0.00",
      Total: "797.523 USDT",
    },
    {
      id: "3",
      Date: "4 Mar 10:25:43",
      Pair: "DHO/USD",
      Side: "1",
      Price: "65.00 USD",
      Amount: "25.00 DHO",
      Filled: "50.00",
      Total: "1625.00 USD",
    },
    {
      id: "4",
      Date: "4 Mar 10:25:43",
      Pair: "DHO/USD",
      Side: "1",
      Price: "65.00 USD",
      Amount: "25.00 DHO",
      Filled: "50.00",
      Total: "1625.00 USD",
    },
  ];
};
