import React, {useRef, useEffect} from "react";
import "./index.scss";

const Loader = ({simple, local, width, height, small, middle, large}) => {
  const containerRef = useRef(null);
  useEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current;
      container.style.width = width ? `${width}px` : "100%";
      container.style.height = height ? `${height}px` : "100%";
    }
  }, [containerRef, width, height]);
  if (simple) {
    return (
      <div className="simple-circle-loader">
        <div className="circle-loader">
          <div></div>
        </div>
      </div>
    );
  }
  return local ? (
    <div ref={containerRef} className="LoaderContainer">
      <span
        className={`loader 
          ${small ? "small" : ""} 
          ${middle ? "middle" : ""} 
          ${large ? "large" : ""}`}
      />
    </div>
  ) : (
    <div className="LoaderWrapper">
      <div ref={containerRef} className="LoaderContainer">
        <span
          className={`loader 
          ${small ? "small" : ""} 
          ${middle ? "middle" : ""} 
          ${large ? "large" : ""}`}
        />
      </div>
    </div>
  );
};

export default Loader;
