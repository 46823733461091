import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import {useEffect} from "react";
import {ReactComponent as CloseIcon} from "images/close-icon.svg";
import {ReactComponent as SettingIcon} from "images/settings-icon-gray.svg";
import "./index.scss";

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

const SimplePopover = ({
  children,
  content,
  element,
  elementId,
  title,
  date,
  messageKey,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(element);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const isShowButtonShare =
    messageKey === "contact.share" || messageKey === "profile.share";

  useEffect(() => {
    if (!!element && elementId === element.id) {
      setAnchorEl(element.value);
    }
  }, [element, elementId]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {children}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Typography className={classes.typography}>
          <div className="header-popover">
            <div className="header-popover-left">
              <CloseIcon className="closeButton" onClick={() => handleClose()} />
              <div className="header-popover-left__titles">
                <div className="header-popover-left__titles__date">
                  {date ? date : ""}
                </div>
                <div className="header-popover-left__titles__title">
                  {title ? title : ""}
                </div>
              </div>
            </div>
            <div className="header-popover-right">
              <SettingIcon className="settingsIcon" />
            </div>
          </div>
          <div dangerouslySetInnerHTML={{__html: content}} className="content" />
          <div className="actions">
            {/* Get function for action from props */}
            {!isShowButtonShare && (
              <div>
                <button onClick={() => handleClose()}>Transfer</button>
                <button onClick={() => handleClose()}>Receive</button>
              </div>
            )}
            {isShowButtonShare && (
              <div>
                <button onClick={() => handleClose()}>SAVE CONTACT</button>
              </div>
            )}
          </div>
        </Typography>
      </Popover>
    </div>
  );
};

export default SimplePopover;
