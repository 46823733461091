/**
 * @author: Seleznyov
 */
import React, {Component} from "react";
import "./index.scss";

class Paginator extends Component {
  render() {
    return (
      <div className="ui-paginator">
        <div className="ui-paginator__btn btn-prev">Previous Page</div>

        <div className="ui-paginator__pages">
          <ol>
            <li>1</li>
            <li className="disabled">...</li>
            <li>3</li>
            <li>4</li>
            <li className="active">5</li>
            <li>6</li>
            <li>7</li>
            <li className="disabled">...</li>
            <li>15</li>
          </ol>
        </div>

        <div className="ui-paginator__btn btn-next">Next Page</div>
      </div>
    );
  }
}
export default Paginator;
