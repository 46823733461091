import React from "react";
import {connect} from "react-redux";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import {FormattedMessage} from "react-intl";

import "./index.scss";
import messages from "../../messages";
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import Select from "shared/components/Select";
// import Input from "shared/components/Input";
import Button from "shared/components/Button";
import AddressBookInput from "shared/components/AddressBook";

import {closeRightModal} from "shared/components/RightModal/actions";
import {addCartItem} from "shared/cartService/actions";
import {getAllRegularCurrency, getBankCards} from "shared/cardService/selectors";
import {CardOption} from "shared/components/CardWalletOptions";
import SimpleCardInput from "shared/components/SimpleCardInput";
import Textarea from "shared/components/Input/Textarea";
import {transferFromCardToCard} from "shared/cardService/actions";
import {
  CardAmountWithCurrency,
  DetectAmount,
  DetectDescription,
  DetectRecipient,
  DetectRegularCard,
  DetectRegularCurrency,
  FeeRate,
  SendRecipient,
} from "./components";

const initialValues = {
  sourceCard: null,
  recipientCardNumber: null,
  recipientCardHolder: null,
  amount: "0",
  currency: "USD",
  description: "",
  sendReceipt: false,
  email: "",
};

const onSubmit = values => ({
  amount: Number.parseFloat(values.amount),
  recipientNumber: values.recipientCardNumber
    ? values.recipientCardNumber.replace(/ /g, "")
    : "",
  sourceNumber: values.sourceCard ? values.sourceCard.number : "",
  receiptEmail: values.email || null,
});

const CardToSomeCardTransferSchema = Yup.object().shape({
  sourceCard: Yup.object()
    .nullable()
    .default(null)
    .required("Source card is required"),
  recipientCardNumber: Yup.string()
    .min(18, "Card Number is too short")
    .max(23, "Card Number is too long")
    .required("Recipient Card Number is required"),
  amount: Yup.string()
    .required("Amount is required")
    .matches(
      "([1-9]\\d*(\\.\\d*[1-9])?|0\\.\\d*[1-9]+)|\\d+(\\.\\d*[1-9])?",
      "Amount must be a number"
    ),
  currency: Yup.string().required("Currency is required"),
  email: Yup.string().email("Email must be valid"),
});

const SomeoneCardFromCardForm = ({
  isSending,
  bankCards,
  allCurrency,
  addCartItem,
  formTabs,
  transferFromCardToCard,
  cardNumber,
  recipientCardNumber,
  currency,
  amount,
  description,
  closeRightModal,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CardToSomeCardTransferSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, {setSubmitting}) => {
        transferFromCardToCard(onSubmit(values));
      }}
    >
      {({errors, touched, values}) => (
        <div className="CardToCardTransferForm">
          <DetectRegularCard
            field="sourceCard"
            cardNumber={cardNumber}
            allCards={bankCards}
          />
          <DetectRecipient field="recipientCardNumber" recipient={recipientCardNumber} />
          <DetectRegularCurrency
            allCurrency={allCurrency}
            field="currency"
            currency={currency}
          />
          <DetectAmount field="amount" amount={amount} />
          <DetectDescription field="description" description={description} />
          <Form className="form">
            <FormSectionSeparator sectionName={messages.source} />
            {formTabs && formTabs()}
            <Field name="sourceCard">
              {({field}) => (
                <div className="cardInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.sourceOfTransfer} />
                  </label>
                  <Select
                    {...field}
                    renderValue={value =>
                      !!value ? <CardOption className="short" card={value} /> : ""
                    }
                  >
                    {bankCards.map((card, index) => (
                      <CardOption
                        key={index}
                        className="currency-m"
                        value={card}
                        card={card}
                      />
                    ))}
                  </Select>
                </div>
              )}
            </Field>
            <FormSectionSeparator sectionName={messages.recipient} />
            <Field name="recipientCardNumber">
              {({field}) => (
                <div className="cardInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.recipientCardNumber} />
                  </label>
                  <SimpleCardInput
                    error={touched.recipientCardNumber && errors.recipientCardNumber}
                    {...field}
                  />
                </div>
              )}
            </Field>
            <div className="cardInputContainer">
              <div className="cardInputContainer-block">
                <div className="cardInputContainer-block__item">
                  <Field name="recipientCardHolder">
                    {({field, form}) => (
                      <AddressBookInput
                        type="text"
                        label={<FormattedMessage {...messages.recipientCardholder} />}
                        types="cards"
                        placeholder="Name as marked on card"
                        name="recipientCardHolder"
                        error={touched.recipientCardHolder && errors.recipientCardHolder}
                        customer={{
                          cardNumber: values.recipientCardNumber,
                        }}
                        otherfield={{
                          label: <FormattedMessage {...messages.recipientCardNumber} />,
                          value: values.recipientCardNumber,
                          name: "recipientCardNumber",
                        }}
                        form={form}
                        {...field}
                      />
                    )}
                  </Field>
                </div>
              </div>
            </div>
            <FormSectionSeparator sectionName={messages.details} />
            <CardAmountWithCurrency
              touched={touched}
              errors={errors}
              allCurrency={allCurrency}
            />
            <Field name="description">
              {({field}) => (
                <div className="descriptionInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.description} />
                  </label>
                  <Textarea
                    type="text"
                    placeholder={messages.transferFromCryptoWallet.defaultMessage}
                    {...field}
                  />
                </div>
              )}
            </Field>
            <SendRecipient touched={touched} errors={errors} values={values} />
            <FeeRate
              card={values.sourceCard}
              amount={values.amount}
              currency={values.sourceCard ? values.sourceCard.currency : null}
            />
            <div className="buttonsContainer">
              <Button
                type="button"
                text={messages.addToCart}
                isSecondary="true"
                onClick={() => {
                  closeRightModal();
                  addCartItem({
                    type: "transfer",
                    data: {
                      description: values.description,
                      sourceCard: values.sourceCard,
                      sourceAmount: values.amount,
                      sourceCurrency: values.sourceCard
                        ? values.sourceCard.currency
                        : "USD",
                    },
                    action: option => transferFromCardToCard(onSubmit(values), option),
                  });
                }}
              />

              <Button type="sumbit" text={messages.sendNow} isLoading={isSending} />
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

const mapStateToProps = store => ({
  isSending: store.transferCardData.isLoading,
  bankCards: getBankCards(store),
  allCurrency: getAllRegularCurrency(store),
});

const mapDispatchToProps = {
  addCartItem,
  transferFromCardToCard,
  closeRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(SomeoneCardFromCardForm);
