import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Formik, Form, Field, useFormikContext} from "formik";
import * as Yup from "yup";
import {FormattedMessage} from "react-intl";

import "./index.scss";
import messages from "../../messages";
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import Select from "shared/components/Select";
import Input from "shared/components/Input";
import Button from "shared/components/Button";
import Checkbox from "shared/components/Checkbox";
import AddressBookInput from "../../../../shared/components/AddressBook";

import {addCartItem} from "shared/cartService/actions";
import {getOnwalletAccount} from "shared/cardService/selectors";
import {
  OnWalletOption,
  WalletAddOption,
  WalletOption,
  WalletOptionSelected,
} from "shared/components/CardWalletOptions";
import Textarea from "shared/components/Input/Textarea";
import {
  getCoinAmountInUSD,
  getCryptoWallets,
  getWalletIconByName,
  getCoinAmountFromOtherCurrency,
  getCoinNameByCurrency,
  getAllCryptoCurrency,
} from "shared/walletService/selectors";
import {getCurrencyRates} from "shared/ratesService/selectors";
import {transferOnWalletToCrypto} from "shared/walletService/actions";
import {
  DetectAmount,
  DetectCryptoCurrency,
  DetectDescription,
  DetectRecipient,
  DetectSecondCryptoWallet,
  SendRecipient,
} from "./components";

import config from "shared/config";
const arrowIcon = config.prefixImgUrl + "left-arrow-icon.svg";

const ownInitialValues = {
  sourceOnWallet: null,
  recipientWallet: null,
  amount: "0",
  description: "",
  sendReceipt: false,
  email: "",
};

const someoneInitialValues = {
  sourceOnWallet: null,
  walletCurrency: "",
  recipientAddress: "",
  amount: "0",
  description: "",
  sendReceipt: false,
  email: "",
};

const OwnWalletFromOnWalletSchema = Yup.object().shape({
  sourceOnWallet: Yup.object()
    .nullable()
    .default(null)
    .required("Source account is required"),
  recipientWallet: Yup.object()
    .nullable()
    .default(null)
    .required("Recipient wallet is required"),
  amount: Yup.string()
    .required("Amount is required")
    .matches(
      "([1-9]\\d*(\\.\\d*[1-9])?|0\\.\\d*[1-9]+)|\\d+(\\.\\d*[1-9])?",
      "Amount must be a number"
    ),
  email: Yup.string().email("Email must be valid"),
});

const SomeoneWalletFromOnWalletSchema = Yup.object().shape({
  sourceOnWallet: Yup.object()
    .nullable()
    .default(null)
    .required("Source account is required"),
  walletCurrency: Yup.string().required("Recipient currency is required"),
  recipientAddress: Yup.string().required("Recipient address is required"),
  amount: Yup.string()
    .required("Amount is required")
    .matches(
      "([1-9]\\d*(\\.\\d*[1-9])?|0\\.\\d*[1-9]+)|\\d+(\\.\\d*[1-9])?",
      "Amount must be a number"
    ),
  email: Yup.string().email("Email must be valid"),
});

const onSubmit = (values, own, currencyRates) => {
  if (own) {
    return {
      cryptoAmount: getCoinAmountFromOtherCurrency(
        values.recipientWallet,
        values.amount,
        currencyRates,
        values.sourceOnWallet.currency
      ),
      sourceCurrency: values.sourceOnWallet
        ? values.sourceOnWallet.currency.toLowerCase()
        : "",
      recipientAddress: values.recipientWallet ? values.recipientWallet.address : "",
      recipientCurrency: values.recipientWallet
        ? values.recipientWallet.currency.toLowerCase()
        : "",
      receiptEmail: values.email || null,
    };
  } else {
    return {
      cryptoAmount: getCoinAmountFromOtherCurrency(
        values.recipientWallet,
        values.amount,
        currencyRates,
        values.sourceOnWallet.currency
      ),
      sourceCurrency: values.sourceOnWallet
        ? values.sourceOnWallet.currency.toLowerCase()
        : "",
      recipientAddress: values.recipientAddress,
      recipientCurrency: values.walletCurrency ? values.walletCurrency.toLowerCase() : "",
      receiptEmail: values.email || null,
    };
  }
};

const ToWalletFromOnWalletForm = ({
  isSending,
  addCartItem,
  allWallets,
  formTabs,
  currencyRates,
  availableWallets,
  transferOnWalletToCrypto,
  onWalletAccount,
  recipientAddress,
  currency,
  amount,
  description,
}) => {
  const [own, setOwn] = useState(false);
  return (
    <Formik
      initialValues={own ? ownInitialValues : someoneInitialValues}
      validationSchema={
        own ? OwnWalletFromOnWalletSchema : SomeoneWalletFromOnWalletSchema
      }
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, {setSubmitting}) => {
        transferOnWalletToCrypto(onSubmit(values, own, currencyRates));
      }}
    >
      {({errors, touched, values, resetForm}) => (
        <div className="CardToCardTransferForm">
          <DetectSecondCryptoWallet field="recipientWallet" allWallets={allWallets} />
          <DetectOnWalletAccount onWalletAccount={onWalletAccount} />
          <DetectRecipient field="recipientAddress" recipient={recipientAddress} />
          <DetectDescription field="description" description={description} />
          <DetectCryptoCurrency
            currency={currency}
            field="walletCurrency"
            availableWallets={availableWallets}
          />
          <DetectAmount field="amount" amount={amount} />
          <Form className="form">
            <FormSectionSeparator sectionName={messages.source} />
            {formTabs && formTabs()}
            <Field name="sourceOnWallet">
              {({field}) => (
                <div className="cardInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.myOnWalletAccount} />
                  </label>
                  <OnWalletOption account={field.value} />
                </div>
              )}
            </Field>
            <FormSectionSeparator sectionName={messages.recipient} />
            <div className="switchReceiptContainer">
              <Checkbox
                className="checkbox"
                value={own}
                onChange={e => {
                  setOwn(e.target.checked);
                }}
              />
              <div className="text">
                <FormattedMessage {...messages.ownCryptoWallet} />
              </div>
            </div>
            {own && (
              <Field name="recipientWallet">
                {({field}) => (
                  <div className="cardInputContainer">
                    <label className="label">
                      <FormattedMessage {...messages.recipientWallet} />
                    </label>
                    <Select
                      {...field}
                      height={64}
                      renderValue={value =>
                        !!value ? (
                          <WalletOptionSelected className="no-padding" wallet={value} />
                        ) : (
                          ""
                        )
                      }
                    >
                      {allWallets.map((wallet, index) => (
                        <WalletOption key={index} value={wallet} wallet={wallet} />
                      ))}
                    </Select>
                  </div>
                )}
              </Field>
            )}
            {!own && (
              <SomeoneCryptoWallet
                touched={touched}
                errors={errors}
                availableWallets={availableWallets}
                values={values}
              />
            )}
            <FormSectionSeparator sectionName={messages.details} />
            <div className="AmountWalletCurrency">
              <Field name="amount">
                {({field}) => (
                  <div className="amountInputContainer">
                    <label className="label">
                      <FormattedMessage {...messages.amountInSourceCurrency} />
                    </label>
                    <Input
                      type="text"
                      error={touched.amount && errors.amount}
                      {...field}
                    />
                  </div>
                )}
              </Field>

              <div className="amountInputContainer">
                <label className="label">
                  <FormattedMessage {...messages.amountInRecipientCurrency} />
                </label>
                <div className="amountWrapper">
                  <span className="equal">≈</span>
                  <div className="amountWrapper">
                    <Input
                      type="text"
                      error={touched.amount && errors.amount}
                      leftIcon={
                        own && !!values.recipientWallet
                          ? getWalletIconByName(values.recipientWallet.walletName)
                          : !own && !!values.walletCurrency
                          ? getWalletIconByName(
                              getCoinNameByCurrency(values.walletCurrency)
                            )
                          : null
                      }
                      iconStyles="walletIcon"
                      value={getCoinAmountFromOtherCurrency(
                        !own && values.walletCurrency
                          ? {currency: values.walletCurrency}
                          : values.recipientWallet,
                        values.amount,
                        currencyRates,
                        values.sourceOnWallet ? values.sourceOnWallet.currency : null
                      )}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
            {own && !!values.recipientWallet && (
              <div className="exchangeRate">
                <div className="rate">
                  <FormattedMessage {...messages.exchangeRate} />: 1{" "}
                  {values.recipientWallet.currency}
                  <img className="arrow" src={arrowIcon} alt="arrow" />
                  {getCoinAmountInUSD(values.recipientWallet, 1, currencyRates)} USD
                </div>
                <div className="info">
                  <FormattedMessage {...messages.finalExchangeRate} />
                </div>
              </div>
            )}
            {!own && !!values.walletCurrency && (
              <div className="exchangeRate">
                <div className="rate">
                  <FormattedMessage {...messages.exchangeRate} />: 1{" "}
                  {values.walletCurrency.toUpperCase()}
                  <img className="arrow" src={arrowIcon} alt="arrow" />
                  {getCoinAmountInUSD(
                    {currency: values.walletCurrency},
                    1,
                    currencyRates
                  )}{" "}
                  USD
                </div>
                <div className="info">
                  <FormattedMessage {...messages.finalExchangeRate} />
                </div>
              </div>
            )}
            <Field name="description">
              {({field}) => (
                <div className="descriptionInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.description} />
                  </label>
                  <Textarea
                    type="text"
                    placeholder="Write an optional message"
                    {...field}
                  />
                </div>
              )}
            </Field>
            <SendRecipient touched={touched} errors={errors} values={values} />
            {!!values.sourceOnWallet && (
              <div className="feeRate">
                <div className="rate">
                  <FormattedMessage {...messages.transactionFee} />: 1.5% -{" "}
                  {values.amount * 0.015} {values.sourceOnWallet.currency}
                </div>
              </div>
            )}
            <div className="buttonsContainer">
              <Button
                type="button"
                text={messages.addToCart}
                isSecondary="true"
                onClick={() => {
                  addCartItem({
                    type: "transfer",
                    data: {
                      description: values.description,
                      sourceOnWallet: values.sourceOnWallet,
                      sourceAmount: values.amount,
                      sourceCurrency: values.sourceOnWallet
                        ? values.sourceOnWallet.currency
                        : "USD",
                    },
                    action: option =>
                      transferOnWalletToCrypto(
                        onSubmit(values, own, currencyRates),
                        option
                      ),
                  });
                }}
              />

              <Button type="sumbit" text={messages.sendNow} isLoading={isSending} />
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

const DetectOnWalletAccount = ({onWalletAccount}) => {
  const {setFieldValue} = useFormikContext();
  useEffect(() => {
    if (onWalletAccount) {
      setFieldValue("sourceOnWallet", onWalletAccount);
    }
  }, [onWalletAccount, setFieldValue]);
  return null;
};

const SomeoneCryptoWallet = ({touched, errors, availableWallets, values}) => {
  return (
    <>
      <Field name="walletCurrency">
        {({field}) => (
          <div className="currencyInputContainer">
            <label className="label">
              <FormattedMessage {...messages.currency} />
            </label>
            <Select
              error={
                touched.walletCurrency && errors.walletCurrency
                  ? errors.walletCurrency
                  : ""
              }
              className="select"
              {...field}
              renderValue={value =>
                !!value ? (
                  <WalletAddOption
                    className="no-padding"
                    currency={value}
                    title={getCoinNameByCurrency(value)}
                  />
                ) : (
                  ""
                )
              }
            >
              {availableWallets.map((availableWalletCurrency, index) => (
                <WalletAddOption
                  key={index}
                  value={availableWalletCurrency}
                  currency={availableWalletCurrency}
                  title={getCoinNameByCurrency(availableWalletCurrency)}
                />
              ))}
            </Select>
          </div>
        )}
      </Field>
      <Field name="recipientAddress">
        {({field, form}) => (
          <div className="recipientAddressInputContainer">
            <AddressBookInput
              type="text"
              label={<FormattedMessage {...messages.recipientAddress} />}
              types="cryptoWallets"
              placeholder="Paste an address"
              name="recipientAddress"
              error={touched.recipientAddress && errors.recipientAddress}
              customer={{
                recipientAddress: values.recipientAddress,
              }}
              valfilter={{
                currency: values.walletCurrency ? values.walletCurrency : null,
              }}
              form={form}
              {...field}
            />
          </div>
        )}
      </Field>
    </>
  );
};

const mapStateToProps = store => ({
  isSending: store.transferCardData.isLoading,
  allWallets: getCryptoWallets(store),
  currencyRates: getCurrencyRates(store),
  availableWallets: getAllCryptoCurrency(store),
  onWalletAccount: getOnwalletAccount(store),
});

const mapDispatchToProps = {
  transferOnWalletToCrypto,
  addCartItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(ToWalletFromOnWalletForm);
