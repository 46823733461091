/**
 * @author: Seleznyov
 */

import {tradingEndpoints} from "../../api/apiEndpoints";
import axios from "axios";
import {getTokenData} from "shared/auth/selectors";
import {cryptoCurrency, currency, typeView, dataSelBuyLimitGet} from "../selectors";
import {
  tradingAPIGraphGetData,
  tradingAPIGetChartData,
  tradingAPIGetChartDataHeader,
} from "../../api/trading";

import {
  SelectMarket,
  BUY,
  MIX,
  SELL,
  SET_DATA_BUY,
  ADD_DATA_BUY,
  REMOVE_DATA_BUY,
  UPDATE_DATA_BUY,
  SET_DATA_SEL,
  ADD_DATA_SEL,
  REMOVE_DATA_SEL,
  UPDATE_DATA_SEL,
  SET_TYPEVIEW,
  SET_LIMITVIEW,
  SET_DATA_LASTORDER,
  SET_DATA_TRADEHISTORY,
  ADD_DATA_TRADEHISTORY,
  ORDERS_HISTORY_WIDGET,
  GRAPH_LOAD_DATA,
  GRAPH_HEADER_DATA,
  TRADING_SELECT_PAIR,
  CHART_PENDING_DATA,
  CHART_SUCCESS_DATA,
  CHART_FAIL_DATA,
  CHART_HEADER_SUCCESS_DATA,
  CHART_HEADER_FAIL_DATA,
} from "./types";

const getOption = getState => {
  // token
  const TOKEN = getTokenData(getState());
  // Error handler
  if (!TOKEN) return;

  const OPTION = {
    headers: {
      Authorization: `${TOKEN.token_type} ${TOKEN.access_token}`,
    },
  };

  return OPTION;
};

/*
|--------------------------------------------------------------
| ORDERS
|--------------------------------------------------------------
|
*/
/**
 *
 * @returns {function(...[*]=)}
 */
export const getDataSelBuy = () => (dispatch, getState) => {
  // token
  const tokenData = getTokenData(getState());
  if (!tokenData) return;
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };

  const varCryptoCurrency = cryptoCurrency(getState()),
    varCurrency = currency(getState()),
    varTypeView = typeView(getState()),
    varDataSelBuyLimit = dataSelBuyLimitGet(getState());

  let apiUrl = tradingEndpoints.ordersByType;
  let type = "";
  let dataPost = {
    SearchByUser: true,
    CryptoCurrency: varCryptoCurrency,
    Currency: varCurrency,
    Size: varDataSelBuyLimit,
  };

  switch (parseInt(varTypeView)) {
    case MIX:
      getDataTradeOrdersAPi(apiUrl, dataPost, SELL, option)
        .then(res => {
          const _data: dataBS[] = res.data;
          dispatch({
            type: SET_DATA_SEL,
            payload: _data,
            limit: varDataSelBuyLimit,
          });
        })
        .catch(error => {
          console.error("error", error);
          return false;
        });

      getDataTradeOrdersAPi(apiUrl, dataPost, BUY, option)
        .then(res => {
          const _data: dataBS[] = res.data;
          dispatch({
            type: SET_DATA_BUY,
            payload: _data,
            limit: varDataSelBuyLimit,
          });
        })
        .catch(error => {
          console.error("error", error);
          return false;
        });
      break;

    case BUY:
    case SELL:
      type = varTypeView === SELL ? SET_DATA_SEL : SET_DATA_BUY;
      let etype = varTypeView !== SELL ? SET_DATA_SEL : SET_DATA_BUY;

      getDataTradeOrdersAPi(apiUrl, dataPost, varTypeView, option)
        .then(res => {
          const _data: dataBS[] = res.data;
          dispatch({
            type: type,
            payload: _data,
            limit: varDataSelBuyLimit,
          });
          dispatch({
            type: etype,
            payload: [],
            limit: varDataSelBuyLimit,
          });
        })
        .catch(error => {
          console.error("error", error);
          return false;
        });
      break;

    default:
      return;
  }
};

function getDataTradeOrdersAPi(apiUrl, data, type, option) {
  let postData = {
    CryptoCurrency: data.CryptoCurrency,
    Currency: data.Currency,
    SearchByUser: data.SearchByUser,
    Size: data.Size,
    Type: type,
  };
  console.log("axios.post ", postData, type);
  return axios.post(apiUrl, postData, option);
}

/**
 *
 * @param data
 * @returns {function(...[*]=)}
 */
export const onCreateTradeUnit = data => (dispatch, getState) => {
  try {
    console.log("onCreateTradeUnit", data);
    const dataT: dataBS = data;
    const dataType = parseInt(dataT.type);

    const varTypeView = typeView(getState()),
      varDataSelBuyLimit = dataSelBuyLimitGet(getState());
    // varCryptoCurrency = cryptoCurrency(getState()),
    // varCurrency = currency(getState()),
    if (varTypeView !== MIX && data.type !== varTypeView) return false;

    let _type = "";

    if (dataType === SELL) {
      _type = ADD_DATA_SEL;
    } else if (dataType === BUY) {
      _type = ADD_DATA_BUY;
    } else {
      return false;
    }

    // _type = ADD_DATA_BUY || ADD_DATA_SELL
    dispatch({
      type: _type,
      payload: dataT,
      limit: varDataSelBuyLimit,
    });

    dispatch({
      type: SET_DATA_LASTORDER,
      payload: dataT,
    });
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const onHandleTradeUnit = data => (dispatch, getState) => {
  try {
    console.log("onHandleTradeUnit", data);
    const _data: dataBS = data;
    const currentType = parseInt(_data.type);

    let _type;
    if (currentType === SELL) {
      _type = UPDATE_DATA_SEL;
    } else if (currentType === BUY) {
      _type = UPDATE_DATA_BUY;
    } else {
      return false;
    }

    dispatch({
      type: _type,
      payload: _data,
    });
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const onRemoveTradeUnit = data => (dispatch, getState) => {
  try {
    const _data: dataBS = data;
    const currentType = parseInt(_data.type);

    let _type;
    if (currentType === SELL) {
      _type = REMOVE_DATA_SEL;
    } else if (currentType === BUY) {
      _type = REMOVE_DATA_BUY;
    } else {
      return false;
    }

    dispatch({
      type: _type,
      payload: _data.tradeUnitId,
    });
  } catch (e) {
    console.log(e);
    return false;
  }
};

/**
 *
 * @param type
 * @returns {function(*, *): boolean}
 */
export const setTypeView = type => (dispatch, getState) => {
  dispatch({
    type: SET_TYPEVIEW,
    payload: type,
  });
  return true;
};

export const setLimitView = limit => (dispatch, getState) => {
  dispatch({
    type: SET_LIMITVIEW,
    payload: limit,
  });
  return true;
};

/*
|--------------------------------------------------------------
| Trade History
|--------------------------------------------------------------
|
*/

/**
 *
 * @returns {function(...[*]=)}
 */
export const getDataTradeHistory = () => (dispatch, getState) => {
  // token
  const tokenData = getTokenData(getState());
  if (!tokenData) return;
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };

  const {dataTradingWidgets} = getState();
  const {dataTradingWidgetsTradeHistory} = getState();

  const apiUrl = tradingEndpoints.tradeHistoryFilterByLimit;
  const dataPost = {
    SearchByUser: true,
    CryptoCurrency: dataTradingWidgets.filterVars.cryptoCurrency,
    Currency: dataTradingWidgets.filterVars.currency,
    Type: null,
    Size: dataTradingWidgetsTradeHistory.limit,
  };

  axios
    .post(apiUrl, dataPost, option)
    .then(res => {
      const _data: dataTS[] = res.data;
      dispatch({
        type: SET_DATA_TRADEHISTORY,
        payload: _data,
        limit: dataTradingWidgetsTradeHistory.limit,
      });
    })
    .catch(error => {
      console.error("error", error);
    });
};

/**
 *
 * @param data
 */
export const onHandleTradeHistory = data => async (dispatch, getState) => {
  const {dataTradingWidgetsTradeHistory} = getState();
  // const limitTradeHistory = dataTradeHistory(getState());
  // varCryptoCurrency = cryptoCurrency(getState()),
  // varCurrency = currency(getState()),

  const _data: dataTH = data;

  dispatch({
    type: ADD_DATA_TRADEHISTORY,
    payload: _data,
    limit: dataTradingWidgetsTradeHistory.limit,
  });

  return true;
};

interface dataTH {
  average: number;
  completedCryptoAmount: number;
  cryptoCurrency: string;
  currency: string;
  date: string;
  price: number;
  remainedCryptoAmount: number;
  status: number;
  tradeHistoryId: string;
  type: number;
}

interface dataBS {
  baseCryptoAmount: number;
  createDate: string;
  cryptoAmount: number;
  cryptoCurrency: string;
  currency: string;
  price: number;
  tradeUnitId: string;
  type: number;
  updateDate?: string;
  direction?: string;
}

interface dataGraphHeader {
  hange: number;
  changePercent: number;
  high: number;
  last: number;
  low: number;
  volume: number;
}

export const graphCandle = data => (dispatch, getState) => {
  const tokenData = getTokenData(getState());

  if (!tokenData) return;
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };
  tradingAPIGraphGetData(option, data.time, data.limit)
    .then(res => {
      dispatch({type: GRAPH_LOAD_DATA, payload: res.data || []});
    })
    .catch(error => {
      console.log("ERROR TRADING GRAPH GET DATA", error);
    });
};

export const onHandleTradeStats = data => async dispatch => {
  const _data: dataGraphHeader = data.tradeStats;

  dispatch({
    type: GRAPH_HEADER_DATA,
    payload: _data,
  });

  return true;
};

export const selectMarket = market => {
  return {
    type: SelectMarket,
    payload: market,
  };
};

//Trade Widgets - Orders
export const createNewOrder = data => (dispatch, getState) => {
  // token
  const tokenData = getTokenData(getState());
  if (!tokenData) return;
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };

  const apiUrl = tradingEndpoints.newOrder();

  axios
    .post(
      apiUrl,
      {
        price: data.price,
        currency: data.currency,
        cryptoAmount: data.amount,
        cryptoCurrency: data.cryptoCurrency,
        type: data.type,
      },
      option
    )
    .then(response => {
      console.log(response);
    })
    .catch(error => {
      console.error("error", error);
    });
};

export const getOrdersHistory = data => (dispatch, getState) => {
  // token
  const tokenData = getTokenData(getState());
  if (!tokenData) return;
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };

  const apiUrl = tradingEndpoints.ordersHistory();

  axios
    .post(
      apiUrl,
      {
        searchByUser: true,
        cryptoCurrency: data.cryptoCurrency,
        currency: data.currency,
        //size: data.size,
        //startDate: data.startDate,
        //endDate: data.endDate,
      },
      option
    )
    .then(res => {
      const _data: dataTS[] = res.data;
      dispatch({
        type: ORDERS_HISTORY_WIDGET,
        payload: _data,
      });
    })
    .catch(error => {
      console.error("error", error);
    });
};
//Trade Widgets - Orders

export const SelectedPair = pair => {
  // pair.code = pair.code.split("/")[1];
  return {
    type: TRADING_SELECT_PAIR,
    payload: {id: pair.id, code: pair.code.split("/")[1]},
  };
};

/*
|--------------------------------------------------------------
| Trade Chart Binance (Graph)
|--------------------------------------------------------------
|
*/

export const getChartData = data => async (dispatch, getState) => {
  dispatch({type: CHART_PENDING_DATA});

  try {
    await tradingAPIGetChartData(getOption(getState), data).then(res => {
      dispatch({type: CHART_SUCCESS_DATA, payload: res.data});
    });
  } catch (error) {
    console.warn(error);
    dispatch({type: CHART_FAIL_DATA});
  }
};

export const getChartDataHeader = data => async (dispatch, getState) => {
  try {
    await tradingAPIGetChartDataHeader(getOption(getState), data).then(res => {
      dispatch({type: CHART_HEADER_SUCCESS_DATA, payload: res.data});
    });
  } catch (error) {
    console.warn(error);
    dispatch({type: CHART_HEADER_FAIL_DATA});
  }
};
