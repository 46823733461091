import React from "react";
import "./index.scss";
import {DatePicker} from "@material-ui/pickers";

const CustomDatePicker = ({className, ...props}) => {
  return (
    <DatePicker
      variant="inline"
      className={`CustomDatePicker ${className} `}
      TextFieldComponent={propsField => (
        <CustomTextField
          {...propsField}
          className={`CustomDatePicker ${className ? className : ""} `}
        />
      )}
      inputVariant="outlined"
      ToolbarComponent={() => <></>}
      keyboard="true"
      clearable="true"
      autoOk="true"
      PopoverProps={{
        anchorOrigin: {horizontal: "center", vertical: "bottom"},
        transformOrigin: {horizontal: "center", vertical: "bottom"},
      }}
      format="DD MMM YYYY"
      {...props}
    />
  );
};

const CustomTextField = ({InputProps, inputRef, ...props}) => {
  return <input ref={inputRef} {...props} {...InputProps} />;
};

export default CustomDatePicker;
