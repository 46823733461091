import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import "./index.scss";
import Loader from "shared/components/Loader";
import CustomCheckbox from "shared/components/Checkbox";
import Input from "shared/components/Input";
import {FormattedMessage} from "react-intl";
import messages from "../../messages";
import {
  getAllRegularCurrency,
  isDefaultCardLoading,
  isRegularCurrenciesFetched,
} from "shared/cardService/selectors";
import {
  setDefaultCard,
  fetchAllRegularCurrency,
  updateCard,
  deleteCard,
} from "shared/cardService/actions";
import Select from "shared/components/Select";

import config from "shared/config";
const onwalletIconLight = config.prefixImgUrl + "onwallet-icon-light_v1.svg";
const visaIconLight = config.prefixImgUrl + "visa-icon-light.svg";

const ManageCardModal = ({
  card,
  isDefaultCardLoading,
  setDefaultCard,
  isRegularCurrenciesFetched,
  allCurrency,
  fetchAllRegularCurrency,
  updateCard,
  deleteCard,
}) => {
  const [localCard, setLocalCard] = useState(card);
  const [isShowDelete, setShowDelete] = useState(false);
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (allCurrency.length === 0 && !isRegularCurrenciesFetched) {
      fetchAllRegularCurrency();
    }
  }, [allCurrency, fetchAllRegularCurrency, isRegularCurrenciesFetched]);

  if (!card) return;
  const currentCurrency = allCurrency.find(
    currency => currency.currency === localCard.currency
  );

  return (
    <div className="ManageCardModal">
      <div className="currentByDefault">
        {isDefaultCardLoading && <Loader simple />}
        {!isDefaultCardLoading && (
          <CustomCheckbox
            value={localCard.isDefault}
            onChange={() => {
              if (!localCard.isDefault) {
                setDefaultCard(localCard.id);
                setLocalCard({...localCard, isDefault: true});
              }
            }}
          />
        )}
        <p className="text">
          <FormattedMessage {...messages.useThisCardByDefault} />
        </p>
      </div>
      <div className="BankCard">
        <div className="content">
          <img src={onwalletIconLight} className="icon" alt="icon" />
          <p className="number">
            <span className="dots" />
            <span className="dots" />
            <span className="dots" />
            <span>{localCard["last4"] ? localCard["last4"] : 0}</span>
          </p>
          <img src={visaIconLight} className="visa" alt="visa" />
        </div>
      </div>
      <div className="currencyInputContainer">
        <label className="label">
          <FormattedMessage {...messages.cardCurrency} />
        </label>
        <Select
          value={currentCurrency ? currentCurrency.currency.toUpperCase() : ""}
          onChange={e => {
            updateCard(localCard.id, {currency: e.target.value.toLowerCase()});
          }}
          options={allCurrency.map(currency => currency.currency.toUpperCase())}
        />
      </div>
      {isShowDelete ? (
        <div className="deleteText">
          <div className="deleteText__text">
            <div className="deleteText__text__item">
              <FormattedMessage {...messages.deleteCardInfo} />
            </div>
            <div className="deleteText__text__item">
              <FormattedMessage {...messages.cardAgain} />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="label">
              <FormattedMessage {...messages.password} />
            </div>
            <Input
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
          <div className="deleteText__actions">
            <div
              className="deleteText__actions__item blue-text"
              onClick={() => setShowDelete(false)}
            >
              <FormattedMessage {...messages.cancel} />
            </div>
            <div
              className="deleteText__actions__item"
              onClick={() => {
                if (!!password) {
                  deleteCard(card.id);
                }
              }}
            >
              <FormattedMessage {...messages.deleteCard} />
            </div>
          </div>
        </div>
      ) : (
        <div className="deleteText" onClick={() => setShowDelete(true)}>
          <FormattedMessage {...messages.deleteCard} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = store => ({
  isDefaultCardLoading: isDefaultCardLoading(store),
  isRegularCurrenciesFetched: isRegularCurrenciesFetched(store),
  allCurrency: getAllRegularCurrency(store),
});

const mapDispatchToProps = {
  setDefaultCard,
  fetchAllRegularCurrency,
  updateCard,
  deleteCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageCardModal);
