import React, {useState} from "react";
import {connect} from "react-redux";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import {FormattedMessage} from "react-intl";

import "./index.scss";
import messages from "../../messages";
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import Select from "shared/components/Select";
import Input from "shared/components/Input";
import Button from "shared/components/Button";
import Checkbox from "shared/components/Checkbox";

import {addCartItem} from "shared/cartService/actions";
import {WalletOption, WalletOptionSelected} from "shared/components/CardWalletOptions";
import Textarea from "shared/components/Input/Textarea";
import {
  getCryptoWallets,
  getWalletIconByName,
  getCoinNameByCurrency,
  getAllCryptoCurrency,
  getPerOtherCoin,
  getAmountInOtherCoin,
} from "shared/walletService/selectors";
import {getCurrencyRates} from "shared/ratesService/selectors";
import {transferCryptoToCrypto} from "shared/walletService/actions";
import {
  DetectAmount,
  DetectCryptoCurrency,
  DetectCryptoWallet,
  DetectDescription,
  DetectRecipient,
  DetectSecondCryptoWallet,
  FeeRate,
  SendRecipient,
  SomeoneCryptoWallet,
} from "./components";

import config from "shared/config";
const arrowIcon = config.prefixImgUrl + "left-arrow-icon.svg";

const ownInitialValues = {
  sourceWallet: null,
  recipientWallet: null,
  amount: "0",
  description: "",
  sendReceipt: false,
  email: "",
};

const someoneInitialValues = {
  sourceWallet: null,
  walletCurrency: "",
  recipientAddress: "",
  amount: "0",
  description: "",
  sendReceipt: false,
  email: "",
};

const OwnWalletFromWalletSchema = Yup.object().shape({
  sourceWallet: Yup.object()
    .nullable()
    .default(null)
    .required("Source wallet is required"),
  recipientWallet: Yup.object()
    .nullable()
    .default(null)
    .required("Recipient wallet is required"),
  amount: Yup.string()
    .required("Amount is required")
    .matches(
      "([1-9]\\d*(\\.\\d*[1-9])?|0\\.\\d*[1-9]+)|\\d+(\\.\\d*[1-9])?",
      "Amount must be a number"
    ),
  email: Yup.string().email("Email must be valid"),
});

const SomeoneWalletFromWalletSchema = Yup.object().shape({
  sourceWallet: Yup.object()
    .nullable()
    .default(null)
    .required("Source wallet is required"),
  walletCurrency: Yup.string().required("Recipient currency is required"),
  recipientAddress: Yup.string().required("Recipient address is required"),
  amount: Yup.string()
    .required("Amount is required")
    .matches(
      "([1-9]\\d*(\\.\\d*[1-9])?|0\\.\\d*[1-9]+)|\\d+(\\.\\d*[1-9])?",
      "Amount must be a number"
    ),
  email: Yup.string().email("Email must be valid"),
});

const onSubmit = (values, own) => {
  if (own) {
    return {
      cryptoAmount: Number.parseFloat(values.amount),
      sourceCurrency: values.sourceWallet
        ? values.sourceWallet.currency.toLowerCase()
        : "",
      recipientCurrency: values.recipientWallet
        ? values.recipientWallet.currency.toLowerCase()
        : "",
      recipientAddress: values.recipientWallet ? values.recipientWallet.address : "",
      receiptEmail: values.email || null,
    };
  } else {
    return {
      cryptoAmount: Number.parseFloat(values.amount),
      sourceCurrency: values.sourceWallet
        ? values.sourceWallet.currency.toLowerCase()
        : "",
      recipientCurrency: values.walletCurrency ? values.walletCurrency.toLowerCase() : "",
      recipientAddress: values.recipientAddress,
      receiptEmail: values.email || null,
    };
  }
};

const ToWalletFromWalletForm = ({
  isSending,
  addCartItem,
  allWallets,
  formTabs,
  currencyRates,
  availableWallets,
  transferCryptoToCrypto,
  walletAddress,
  recipientAddress,
  currency,
  amount,
  description,
}) => {
  const [own, setOwn] = useState(false);

  return (
    <Formik
      initialValues={own ? ownInitialValues : someoneInitialValues}
      validationSchema={own ? OwnWalletFromWalletSchema : SomeoneWalletFromWalletSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, {setSubmitting}) => {
        transferCryptoToCrypto(values, own);
      }}
    >
      {({errors, touched, values}) => (
        <div className="CardToCardTransferForm">
          <DetectSecondCryptoWallet
            field="recipientWallet"
            allWallets={allWallets}
            firstWallet={values.sourceWallet}
          />
          <DetectCryptoWallet
            field="sourceWallet"
            allWallets={allWallets}
            walletAddress={walletAddress}
          />
          <DetectRecipient field="recipientAddress" recipient={recipientAddress} />
          <DetectDescription field="description" description={description} />
          <DetectCryptoCurrency
            currency={currency}
            field="walletCurrency"
            availableWallets={availableWallets}
          />
          <DetectAmount field="amount" amount={amount} />
          <Form className="form">
            <FormSectionSeparator sectionName={messages.source} />
            {formTabs && formTabs()}
            <Field name="sourceWallet">
              {({field}) => (
                <div className="cardInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.transferSource} />
                  </label>
                  <Select
                    {...field}
                    height={64}
                    renderValue={value =>
                      !!value ? (
                        <WalletOptionSelected className="no-padding" wallet={value} />
                      ) : (
                        ""
                      )
                    }
                  >
                    {allWallets.map((wallet, index) => (
                      <WalletOption key={index} value={wallet} wallet={wallet} />
                    ))}
                  </Select>
                </div>
              )}
            </Field>
            <FormSectionSeparator sectionName={messages.recipient} />
            <div className="switchReceiptContainer">
              <Checkbox
                className="checkbox"
                value={own}
                onChange={e => {
                  setOwn(e.target.checked);
                }}
              />
              <div className="text">
                <FormattedMessage {...messages.ownCryptoWallet} />
              </div>
            </div>
            {own && (
              <Field name="recipientWallet">
                {({field}) => (
                  <div className="cardInputContainer">
                    <label className="label">
                      <FormattedMessage {...messages.sourceOfTransfer} />
                    </label>
                    <Select
                      {...field}
                      height={64}
                      renderValue={value =>
                        !!value ? (
                          <WalletOptionSelected className="no-padding" wallet={value} />
                        ) : (
                          ""
                        )
                      }
                    >
                      {allWallets
                        .filter(function(item) {
                          return values.sourceWallet.currency !== item.currency;
                        })
                        .map((wallet, index) => (
                          <WalletOption key={index} value={wallet} wallet={wallet} />
                        ))}
                    </Select>
                  </div>
                )}
              </Field>
            )}
            {!own && (
              <SomeoneCryptoWallet
                touched={touched}
                errors={errors}
                availableWallets={availableWallets}
                values={values}
              />
            )}
            <FormSectionSeparator sectionName={messages.details} />
            <div className="AmountWalletCurrency">
              <Field name="amount">
                {({field}) => (
                  <div className="amountInputContainer">
                    <label className="label">
                      <FormattedMessage {...messages.amountInSourceCurrency} />
                    </label>
                    <div className="amountWrapper">
                      {!!values.sourceWallet && (
                        <Input
                          type={
                            values.sourceWallet.walletName === "Deho" ? "number" : "text"
                          }
                          leftIcon={
                            !!values.sourceWallet
                              ? getWalletIconByName(values.sourceWallet.walletName)
                              : null
                          }
                          iconStyles="walletIcon"
                          error={touched.amount && errors.amount}
                          {...field}
                        />
                      )}
                    </div>
                  </div>
                )}
              </Field>

              <div className="amountInputContainer">
                <label className="label">
                  <FormattedMessage {...messages.amountInRecipientCurrency} />
                </label>
                <div className="amountWrapper">
                  <span className="equal">≈</span>
                  <div className="amountWrapper">
                    <Input
                      type="text"
                      leftIcon={
                        own && !!values.recipientWallet
                          ? getWalletIconByName(values.recipientWallet.walletName)
                          : !own && !!values.walletCurrency
                          ? getWalletIconByName(
                              getCoinNameByCurrency(values.walletCurrency)
                            )
                          : null
                      }
                      iconStyles="walletIcon"
                      error={touched.amount && errors.amount}
                      value={getAmountInOtherCoin(
                        values.sourceWallet ? values.sourceWallet.currency : null,
                        !own && values.walletCurrency
                          ? values.walletCurrency.currency
                          : values.recipientWallet
                          ? values.recipientWallet.currency
                          : null,
                        values.amount,
                        currencyRates
                      )}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
            {own && !!values.sourceWallet && !!values.recipientWallet && (
              <div className="exchangeRate">
                <div className="rate">
                  <FormattedMessage {...messages.exchangeRate} />: 1{" "}
                  {values.sourceWallet.currency}
                  <img className="arrow" src={arrowIcon} alt="arrow" />
                  {getPerOtherCoin(
                    values.sourceWallet,
                    values.recipientWallet,
                    currencyRates
                  )}{" "}
                  {values.recipientWallet.currency}
                </div>
                <div className="info">
                  <FormattedMessage {...messages.finalExchangeRate} />
                </div>
              </div>
            )}
            {!own && !!values.sourceWallet && !!values.walletCurrency && (
              <div className="exchangeRate">
                <div className="rate">
                  <FormattedMessage {...messages.exchangeRate} />: 1{" "}
                  {values.sourceWallet.currency}
                  <img className="arrow" src={arrowIcon} alt="arrow" />
                  {getPerOtherCoin(
                    values.sourceWallet,
                    {currency: values.walletCurrency},
                    currencyRates
                  )}{" "}
                  {values.walletCurrency.toUpperCase()}
                </div>
                <div className="info">
                  <FormattedMessage {...messages.finalExchangeRate} />
                </div>
              </div>
            )}
            <Field name="description">
              {({field}) => (
                <div className="descriptionInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.description} />
                  </label>
                  <Textarea
                    type="text"
                    placeholder="Write an optional message"
                    {...field}
                  />
                </div>
              )}
            </Field>
            <SendRecipient touched={touched} errors={errors} values={values} />
            <FeeRate
              wallet={values.sourceWallet}
              amount={values.amount}
              currency={values.sourceWallet ? values.sourceWallet.currency : null}
              currencyRates={currencyRates}
            />
            <div className="buttonsContainer">
              <Button
                type="button"
                text={messages.addToCart}
                isSecondary="true"
                onClick={() => {
                  addCartItem({
                    type: "transfer",
                    data: {
                      description: values.description,
                      sourceWallet: values.sourceWallet,
                      sourceAmount: values.amount,
                      sourceCurrency: values.sourceWallet
                        ? values.sourceWallet.currency
                        : "",
                    },
                    action: option =>
                      transferCryptoToCrypto(onSubmit(values, own), option),
                  });
                }}
              />

              <Button type="sumbit" text={messages.sendNow} isLoading={isSending} />
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

const mapStateToProps = store => ({
  isSending: store.transferCardData.isLoading,
  allWallets: getCryptoWallets(store),
  currencyRates: getCurrencyRates(store),
  availableWallets: getAllCryptoCurrency(store),
});

const mapDispatchToProps = {
  transferCryptoToCrypto,
  addCartItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(ToWalletFromWalletForm);
