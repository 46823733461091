import React from "react";
import PropTypes from "prop-types";
// Components
import CoinRegistryTableHeader from "./CoinRegistryTableHeader";
import CoinRegistryTableBody from "./CoinRegistryTableBody";
// Styles
import "./index.scss";

const CoinRegistryTable = ({searchValue, coinCurrency}) => {
  return (
    <div className="coin-table">
      <CoinRegistryTableHeader />
      <CoinRegistryTableBody searchValue={searchValue} coinCurrency={coinCurrency} />
    </div>
  );
};

// Types
CoinRegistryTable.propTypes = {
  searchValue: PropTypes.string,
  coinCurrency: PropTypes.object,
};

export default CoinRegistryTable;
