import {Field, useFormikContext} from "formik";
import React, {useEffect} from "react";
import "./index.scss";
import {FormattedMessage} from "react-intl";
import messages from "../../messages";
import {getCoinAmountInUSD, getCoinNameByCurrency} from "shared/walletService/selectors";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import {WalletAddOption} from "shared/components/CardWalletOptions";
import Checkbox from "shared/components/Checkbox";
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import Textarea from "shared/components/Input/Textarea";
import CustomDatePicker from "shared/components/DatePicker";
import {countries} from "shared/commonDataService/countries";
import moment from "moment";

import config from "shared/config";
import AddressBookInput from "../../../../shared/components/AddressBook";
const arrowIcon = config.prefixImgUrl + "left-arrow-icon.svg";
const onWalletIcon = config.prefixImgUrl + "onwallet-icon-short_v1.svg";
const eyeIcon = config.prefixImgUrl + "onwallet-eye.svg";
const deleteIcon = config.prefixImgUrl + "delete-icon.svg";

export const DetectRegularCard = ({field, allCards, cardNumber}) => {
  const {setFieldValue} = useFormikContext();
  useEffect(() => {
    if (allCards && cardNumber) {
      setFieldValue(field, allCards.filter(card => card.number === cardNumber)[0]);
    } else {
      const filteredCard = allCards.filter(card => card.isDefault)[0];
      setFieldValue(field, filteredCard || (allCards && allCards[0]));
    }
  }, [allCards, setFieldValue, cardNumber, field]);
  return null;
};

export const DetectSecondRegularCard = ({field, allCards, firstCard}) => {
  const {setFieldValue} = useFormikContext();
  useEffect(() => {
    if (allCards && firstCard) {
      const filteredList = allCards.filter(card => card.number !== firstCard.number);
      setFieldValue(field, filteredList && filteredList[0]);
    }
  }, [allCards, setFieldValue, firstCard, field]);
  return null;
};

export const DetectCryptoWallet = ({field, allWallets, walletAddress}) => {
  const {setFieldValue} = useFormikContext();
  useEffect(() => {
    if (allWallets && walletAddress) {
      setFieldValue(
        field,
        allWallets.filter(wallet => wallet.address === walletAddress)[0]
      );
    } else if (allWallets) {
      setFieldValue(field, allWallets[0]);
    }
  }, [allWallets, setFieldValue, walletAddress, field]);
  return null;
};

export const DetectSecondCryptoWallet = ({field, allWallets, firstWallet}) => {
  const {setFieldValue} = useFormikContext();
  useEffect(() => {
    if (allWallets && firstWallet) {
      const filteredList = allWallets.filter(
        wallet => wallet.address !== firstWallet.address
      );
      setFieldValue(field, filteredList && filteredList[0]);
    } else if (allWallets && allWallets[0]) {
      setFieldValue(field, allWallets[0]);
    }
  }, [allWallets, setFieldValue, firstWallet, field]);
  return null;
};

export const DetectOnWalletAccount = ({field, onWalletAccount}) => {
  const {setFieldValue} = useFormikContext();
  useEffect(() => {
    if (onWalletAccount) {
      setFieldValue(field, onWalletAccount);
    }
  }, [onWalletAccount, setFieldValue, field]);
  return null;
};

export const FeeRate = ({card, wallet, amount, currency, currencyRates}) => {
  if (!!card) {
    return (
      <div className="feeRate">
        <div className="rate">
          <FormattedMessage {...messages.transactionFee} />: 1.5% - {amount * 0.015}{" "}
          {currency}
        </div>
        <div className="description">
          <FormattedMessage {...messages.transactionFeeDescription} />
        </div>
      </div>
    );
  }
  if (!!wallet) {
    return (
      <div className="feeRate">
        <div className="rate">
          <FormattedMessage {...messages.transactionFee} />: 1.5% - {amount * 0.015}{" "}
          {currency} ~ {getCoinAmountInUSD(wallet, 0.015 * amount, currencyRates)} USD
        </div>
        <div className="description">
          <FormattedMessage {...messages.transactionFeeDescription} />
        </div>
      </div>
    );
  }
  return null;
};

export const SomeoneOnWallet = ({touched, errors, values}) => {
  let {firstName = "", lastName = ""} = values || {};

  return (
    <Field name="recipientAddress">
      {({field, form}) => (
        <div className="onWalletAccountInputContainer">
          <AddressBookInput
            type="text"
            label={<FormattedMessage {...messages.onWalletAccount} />}
            types="onWalletAccount"
            placeholder="Paste or scan an address"
            name="recipientAddress"
            leftIcon={onWalletIcon}
            error={touched.recipientAddress && errors.recipientAddress}
            customer={{
              firstName: firstName,
              lastName: lastName,
            }}
            form={form}
            {...field}
            setfield={[
              {
                label: "",
                value: "",
                name: "firstName",
              },
              {
                label: "",
                value: "",
                name: "lastName",
              },
            ]}
          />
        </div>
      )}
    </Field>
  );
};

export const SomeoneOnWalletWithName = ({touched, errors, values}) => {
  return (
    <>
      <SomeoneOnWallet touched={touched} errors={errors} values={values} />
      <div className="NameInputContainer">
        <Field name="firstName">
          {({field}) => (
            <div className="nameInputContainer">
              <label className="label">
                <FormattedMessage {...messages.firstName} />
              </label>
              <div className="nameWrapper">
                <Input
                  type="text"
                  placeholder="John"
                  error={touched.firstName && errors.firstName}
                  {...field}
                />
              </div>
            </div>
          )}
        </Field>
        <Field name="lastName">
          {({field}) => (
            <div className="nameInputContainer">
              <label className="label">
                <FormattedMessage {...messages.lastName} />
              </label>
              <div className="nameWrapper">
                <Input
                  type="text"
                  placeholder="Smith"
                  error={touched.lastName && errors.lastName}
                  {...field}
                />
              </div>
            </div>
          )}
        </Field>
      </div>
    </>
  );
};

export const BankDetailsForm = ({touched, errors, values}) => {
  let _recipientAccountNumber = values ? values.recipientAccountNumber : "";
  return (
    <>
      <Field name="recipientName">
        {({field, form}) => (
          <div className="NameFullContainer">
            <AddressBookInput
              type="text"
              label={<FormattedMessage {...messages.recipientName} />}
              types="bankAccounts"
              placeholder=""
              name="recipientName"
              error={touched.recipientName && errors.recipientName}
              customer={{
                bankAccounts: _recipientAccountNumber,
              }}
              otherfield={{
                label: <FormattedMessage {...messages.recipientAccountNumber} />,
                value: _recipientAccountNumber,
                name: "recipientAccountNumber",
              }}
              form={form}
              {...field}
            />
          </div>
        )}
      </Field>

      <Field name="recipientAccountNumber">
        {({field}) => (
          <div className="InputContainer">
            <label className="label">
              <FormattedMessage {...messages.recipientAccountNumber} />
            </label>
            <div className="accountInput">
              <Input
                type="text"
                error={touched.recipientAccountNumber && errors.recipientAccountNumber}
                {...field}
              />
            </div>
          </div>
        )}
      </Field>
    </>
  );
};

export const CardAmountWithCurrency = ({touched, errors, allCurrency}) => {
  return (
    <div className="AmountCurrency">
      <Field name="amount">
        {({field}) => (
          <div className="amountInputContainer">
            <label className="label">
              <FormattedMessage {...messages.transactionAmount} />
            </label>
            <Input error={touched.amount && errors.amount} {...field} />
          </div>
        )}
      </Field>
      <Field name="currency">
        {({field}) => (
          <div className="currencyInputContainer">
            <label className="label">
              <FormattedMessage {...messages.currency} />
            </label>
            <Select
              className="select"
              {...field}
              options={allCurrency.map(currency => currency.currency.toUpperCase())}
            />
          </div>
        )}
      </Field>
    </div>
  );
};

export const ExchangeRate = ({wallet, currency, currencyRates}) => {
  if (wallet) {
    return (
      <div className="exchangeRate">
        <div className="rate">
          <FormattedMessage {...messages.exchangeRate} />: 1 {currency}
          <img className="arrow" src={arrowIcon} alt="arrow" />
          {getCoinAmountInUSD(wallet, 1, currencyRates)} USD
        </div>
        <div className="info">
          <FormattedMessage {...messages.finalExchangeRate} />
        </div>
      </div>
    );
  }
  return null;
};

export const SomeoneCryptoWallet = ({touched, errors, availableWallets, values}) => {
  return (
    <>
      <Field name="walletCurrency">
        {({field}) => (
          <div className="currencyInputContainer">
            <label className="label">
              <FormattedMessage {...messages.currency} />
            </label>
            <Select
              error={
                touched.walletCurrency && errors.walletCurrency
                  ? errors.walletCurrency
                  : ""
              }
              className="select"
              {...field}
              renderValue={value =>
                !!value ? (
                  <WalletAddOption
                    className="no-padding"
                    currency={value}
                    title={getCoinNameByCurrency(value)}
                  />
                ) : (
                  ""
                )
              }
            >
              {availableWallets.map((availableWalletCurrency, index) => (
                <WalletAddOption
                  key={index}
                  value={availableWalletCurrency}
                  currency={availableWalletCurrency}
                  title={getCoinNameByCurrency(availableWalletCurrency)}
                />
              ))}
            </Select>
          </div>
        )}
      </Field>
      <Field name="recipientAddress">
        {({field, form}) => (
          <div className="recipientAddressInputContainer">
            <AddressBookInput
              type="text"
              label={<FormattedMessage {...messages.recipientAddress} />}
              types="cryptoWallets"
              placeholder="Paste an address"
              name="recipientAddress"
              error={touched.recipientAddress && errors.recipientAddress}
              customer={{
                recipientAddress: values.recipientAddress,
              }}
              // otherfield={{
              //   label: <FormattedMessage {...messages.recipientAddress} />,
              //   value: values.recipientAddress,
              //   name: "recipientAddress",
              // }}
              valfilter={{
                currency: values.walletCurrency ? values.walletCurrency : null,
              }}
              form={form}
              {...field}
            />
          </div>
        )}
      </Field>
    </>
  );
};

export const SendRecipient = ({touched, errors, values}) => {
  return (
    <>
      <Field name="addRecipientAddress">
        {({field}) => (
          <div className="sendReceiptContainer">
            <Checkbox className="checkbox" {...field} />
            <div className="text">
              <FormattedMessage {...messages.sendTransactionReceipt} />
            </div>
          </div>
        )}
      </Field>
      {values.sendReceipt && (
        <Field name="email">
          {({field}) => (
            <div className="InputContainer">
              <label className="label">
                <FormattedMessage {...messages.email} />
              </label>
              <div className="accountInput">
                <Input type="text" error={touched.email && errors.email} {...field} />
              </div>
            </div>
          )}
        </Field>
      )}
    </>
  );
};

export const DetectRecipient = ({field, recipient}) => {
  const {setFieldValue} = useFormikContext();
  useEffect(() => {
    if (recipient) {
      setFieldValue(field, recipient);
    }
  }, [setFieldValue, field, recipient]);
  return null;
};

export const DetectRegularCurrency = ({field, allCurrency, currency}) => {
  const {setFieldValue} = useFormikContext();
  useEffect(() => {
    if (
      allCurrency &&
      currency &&
      allCurrency.some(item => item.currency.toUpperCase() === currency)
    ) {
      setFieldValue(field, currency);
    }
  }, [allCurrency, setFieldValue, currency, field]);
  return null;
};

export const DetectCryptoCurrency = ({field, availableWallets, currency}) => {
  const {setFieldValue} = useFormikContext();
  useEffect(() => {
    if (
      availableWallets &&
      currency &&
      availableWallets.some(item => item && item.toUpperCase() === currency)
    ) {
      setFieldValue(field, currency);
    } else if (availableWallets && availableWallets[0]) {
      setFieldValue(field, availableWallets[0]);
    }
  }, [availableWallets, setFieldValue, currency, field]);
  return null;
};

export const DetectAmount = ({field, amount}) => {
  const {setFieldValue} = useFormikContext();
  useEffect(() => {
    if (amount) {
      setFieldValue(field, amount);
    }
  }, [setFieldValue, field, amount]);
  return null;
};

export const DetectDescription = ({field, description}) => {
  const {setFieldValue} = useFormikContext();
  useEffect(() => {
    if (description) {
      setFieldValue(field, description);
    }
  }, [setFieldValue, field, description]);
  return null;
};

export const SwiftBankForm = ({
  touched,
  errors,
  allCurrency,
  setFieldValue,
  values,
  openFileViewer,
}) => {
  return (
    <>
      <FormSectionSeparator sectionName={messages.recipientBank} />
      <Field name="country">
        {({field}) => (
          <div className="cardInputContainer second">
            <label className="label">
              <FormattedMessage {...messages.country} />
            </label>
            <Select
              error={errors.country}
              {...field}
              options={countries.map(country => country.name)}
            />
          </div>
        )}
      </Field>
      <Field name="currency">
        {({field}) => (
          <div className="currencyInputContainer second">
            <label className="label">
              <FormattedMessage {...messages.currency} />
            </label>
            <Select
              className="select"
              error={touched.currency && errors.currency ? errors.currency : ""}
              {...field}
              options={allCurrency.map(currency => currency.currency.toUpperCase())}
            />
          </div>
        )}
      </Field>
      <Field name="amount">
        {({field}) => (
          <div className="amountInputContainer second">
            <label className="label">
              <FormattedMessage {...messages.amountOfTransfer} />
            </label>
            <Input type="text" error={touched.amount && errors.amount} {...field} />
          </div>
        )}
      </Field>
      <FormSectionSeparator sectionName={messages.recipient} />
      <Field name="recipientName">
        {({field, form}) => (
          <div className="recipientAddressInputContainer">
            <AddressBookInput
              type="text"
              label={<FormattedMessage {...messages.recipientName} />}
              types="bankAccounts"
              placeholder=""
              name="recipientName"
              error={touched.recipientName && errors.recipientName}
              customer={{
                bankAccounts: values.recipientAccountNumber,
              }}
              otherfield={{
                label: <FormattedMessage {...messages.recipientAccountNumber} />,
                value: values.recipientAccountNumber,
                name: "recipientAccountNumber",
              }}
              form={form}
              {...field}
            />
          </div>
        )}
      </Field>
      <Field name="recipientAccountNumber">
        {({field}) => (
          <div className="InputContainer second">
            <label className="label">
              <FormattedMessage {...messages.recipientAccountNumber} />
            </label>
            <div className="accountInput">
              <Input
                type="text"
                error={touched.recipientAccountNumber && errors.recipientAccountNumber}
                {...field}
              />
            </div>
          </div>
        )}
      </Field>
      <FormSectionSeparator sectionName={messages.recipientAddress} />
      <Field name="recipientCountry">
        {({field}) => (
          <div className="cardInputContainer second">
            <label className="label">
              <FormattedMessage {...messages.country} />
            </label>
            <Input
              type="text"
              error={touched.recipientCountry && errors.recipientCountry}
              {...field}
              placeholder="Latin letters only"
            />
          </div>
        )}
      </Field>
      <Field name="recipientCity">
        {({field}) => (
          <div className="cardInputContainer second">
            <label className="label">
              <FormattedMessage {...messages.city} />
            </label>
            <Input
              type="text"
              error={touched.recipientCity && errors.recipientCity}
              {...field}
              placeholder="Latin letters only"
            />
          </div>
        )}
      </Field>
      <Field name="recipientAddress">
        {({field}) => (
          <div className="cardInputContainer second">
            <label className="label">
              <FormattedMessage {...messages.address} />
            </label>
            <Input
              type="text"
              error={touched.recipientAddress && errors.recipientAddress}
              {...field}
              placeholder="Latin letters only"
            />
          </div>
        )}
      </Field>
      <FormSectionSeparator sectionName={messages.details} />
      <Field name="valueDate">
        {({field}) => (
          <div className="datePicketInput second">
            <label className="label">
              <FormattedMessage {...messages.valueDate} />
            </label>
            <CustomDatePicker
              error={touched.valueDate && errors.valueDate}
              value={field.value ? moment(field.value) : moment()}
              onChange={date => setFieldValue("valueDate", date.format())}
              onBlur={() => {}}
            />
          </div>
        )}
      </Field>
      <Field name="purposeFirst">
        {({field}) => (
          <div className="cardInputContainer second">
            <label className="label">
              <FormattedMessage {...messages.purposeOfPayment} />
            </label>
            <Input
              type="text"
              error={touched.purposeFirst && errors.purposeFirst}
              {...field}
            />
          </div>
        )}
      </Field>
      <Field name="purposeSecond">
        {({field}) => (
          <div className="cardInputContainer second">
            <Input
              type="text"
              error={touched.purposeSecond && errors.purposeSecond}
              {...field}
            />
          </div>
        )}
      </Field>
      <Field name="description">
        {({field}) => (
          <div className="descriptionInputContainer">
            <label className="label">
              <FormattedMessage {...messages.description} />
            </label>
            <Textarea
              type="text"
              {...field}
              placeholder="Country (from), invoice, VIN, Lot (Latin only)"
            />
          </div>
        )}
      </Field>
      <FormSectionSeparator sectionName={messages.confirmationDocuments} />
      {values.documents &&
        Object.entries(values.documents).map(([index, file]) => (
          <DocumentComponent
            key={index}
            index={index}
            file={file}
            open={openFileViewer}
            onDelete={index => {
              let newObj = {};
              const filteredList = Object.entries(values.documents).filter(
                ([idx, file]) => idx !== index
              );
              filteredList.forEach(([i, file], idx) => {
                newObj[idx] = file;
              });
              setFieldValue("documents", newObj);
            }}
          />
        ))}
      <Field name="documents">
        {({field}) => (
          <div className="documentsContainer">
            <div className="add">
              <input
                id="file"
                name="documents"
                type="file"
                multiple
                onChange={event => {
                  setFieldValue("documents", event.currentTarget.files);
                }}
                className="inputFile"
              />
              <FormattedMessage {...messages.addDocument} />
            </div>
          </div>
        )}
      </Field>
      <div className="documentDescription">
        <FormattedMessage {...messages.ifNecessaryAttachScannedCopies} />
      </div>
    </>
  );
};

const DocumentComponent = ({file, index, onDelete, open}) => {
  return (
    <div className="DocumentContainer">
      <span className="name">
        {index}. {file.name}
      </span>
      <span className="button" onClick={() => open(file)}>
        <img src={eyeIcon} alt="view" />
      </span>
      <span className="button" onClick={() => onDelete(index)}>
        <img src={deleteIcon} alt="delete" />
      </span>
    </div>
  );
};
