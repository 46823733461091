import {defineMessages} from "react-intl";

const scope = "componentContactList";

const messages = defineMessages({
  saveAsNewContact: {
    id: `${scope}.saveAsNewContact`,
    defaultMessage: "Save as new contact",
  },
  addToExistContact: {
    id: `${scope}.addToExistContact`,
    defaultMessage: "add to existing contact",
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: "Search",
  },
  newContact: {
    id: `${scope}.newContact`,
    defaultMessage: "New Contact",
  },
  firstName: {
    id: `${scope}.firstName`,
    defaultMessage: "First Name",
  },
  lastName: {
    id: `${scope}.lastName`,
    defaultMessage: "Last Name",
  },
  saveNewContact: {
    id: `${scope}.saveNewContact`,
    defaultMessage: "Save new Contact",
  },
  saveToContact: {
    id: `${scope}.saveToContact`,
    defaultMessage: "Save to Contact",
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: "Cancel",
  },
});

export default messages;
