import React from "react";
// Components
import Button from "shared/components/Button";
// Styles
import "./index.scss";
// Utils
import config from "../../config";
// Images
const closeIcon = config.prefixImgUrl + "close-icon.svg";

const SearchInputWithButton = ({
  className,
  value,
  onClick,
  onClickSearch,
  onChange,
  ...props
}) => {
  // For button text
  const messages = {
    search: {
      id: "CustomersPage.search",
      defaultMessage: "Search",
    },
  };

  return (
    <div className={`search-input-with-button ${className ? className : ""}`}>
      <button className="search-input-with-button__search-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="input__block_searchIcon"
          width="16"
          height="16"
          viewBox="0 0 16 16"
        >
          <path
            className="a"
            d="M19.78,18.72l-3.331-3.331a7.008,7.008,0,1,0-1.06,1.06L18.72,19.78a.75.75,0,0,0,1.06-1.06ZM5.5,11A5.5,5.5,0,1,1,11,16.5,5.506,5.506,0,0,1,5.5,11Z"
            transform="translate(-4 -4)"
          />
        </svg>
      </button>
      <input
        className="search-input-with-button__search"
        type="text"
        value={value}
        onChange={onChange}
        {...props}
      />
      {value && (
        <img
          className="search-input-with-button__close-icon"
          src={closeIcon}
          alt="close"
          onClick={onClick}
        />
      )}
      <Button
        className="search-input-with-button__button"
        text={messages.search}
        type="button"
        onClick={onClickSearch}
      />
    </div>
  );
};

export default SearchInputWithButton;
