import React from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import Header from "shared/components/Header";
import {openQRCodeModal} from "shared/components/QRCodeModal/actions";

import WalletBalance from "./../WalletBalance";

import {getDehoWallet, getBitcoinWallet} from "shared/walletService/selectors";
import {getOnwalletAccount} from "shared/cardService/selectors";

import config from "shared/config";
import {routes} from "shared/appUrls";
import messages from "./../../messages";

import "./index.scss";
import {getCartItemsCount} from "shared/cartService/selectors";
import {ReactComponent as CartIcon} from "images/cart-icon.svg";

const logoPage = config.prefixImgUrl + "dashboard-header-icon.svg";

const header = ({
  dehoWallet,
  bitcoinWallet,
  onWalletAccount,
  openQRCodeModal,
  cartItemsCount,
}) => {
  const navLinks = [
    {
      text: <FormattedMessage {...messages.headerCart} />,
      icon: className => <CartIcon className={className} />,
      leftBorder: true,
      link: routes.cart,
      notifications: cartItemsCount,
    },
  ];

  const walletsBalance = [
    {
      type: onWalletAccount ? onWalletAccount.currency.toUpperCase() : "USD",
      balance: onWalletAccount ? `$${onWalletAccount.balance}` : "$0",
      walletName: <FormattedMessage {...messages.onwalletAccount} />,
      onClick: event => {
        event.stopPropagation();
        openQRCodeModal(
          onWalletAccount.account,
          <FormattedMessage {...messages.onwalletAccount} />
        );
      },
    },
    {
      type: dehoWallet ? dehoWallet.currency : "DHO",
      balance: dehoWallet ? `${dehoWallet.balance} ${dehoWallet.currency}` : 0,
      walletName: <FormattedMessage {...messages.dehoWalletAccount} />,
      onClick: event => {
        event.stopPropagation();
        openQRCodeModal(
          dehoWallet.address,
          <FormattedMessage {...messages.dehoWalletAccount} />
        );
      },
    },
  ];

  if (bitcoinWallet) {
    const addBitcoinWallet = {
      type: bitcoinWallet ? bitcoinWallet.currency : "BTC",
      balance: bitcoinWallet
        ? `${bitcoinWallet.balance} ${bitcoinWallet.currency}`
        : `0 BTC`,
      walletName: <FormattedMessage {...messages.bitcoinWallet} />,
      onClick: event => {
        event.stopPropagation();
        openQRCodeModal(
          bitcoinWallet.address,
          <FormattedMessage {...messages.bitcoinAccount} />
        );
      },
    };

    walletsBalance.push(addBitcoinWallet);
  }

  return (
    <>
      <div className="dashboard-header">
        <Header
          headerTitle={<FormattedMessage {...messages.headerDashboardTitle} />}
          navLinks={navLinks}
          logoImage={logoPage}
        >
          <div className="wallets-header-conteiner">
            {walletsBalance.map(item => {
              return <WalletBalance key={item.type} {...item} />;
            })}
          </div>
        </Header>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  onWalletAccount: getOnwalletAccount(state),
  dehoWallet: getDehoWallet(state),
  bitcoinWallet: getBitcoinWallet(state),
  cartItemsCount: getCartItemsCount(state),
});

const mapDispatchToProps = {
  openQRCodeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(header);
