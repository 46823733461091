import {OPEN_FILE_VIEWER, CLOSE_FILE_VIEWER} from "./actions";

const initState = {
  show: false,
  file: null,
  meta: null,
};

const fileViewerData = (state = initState, action) => {
  switch (action.type) {
    case OPEN_FILE_VIEWER:
      return {
        ...state,
        show: true,
        file: action.payload.file,
        meta: action.payload.meta,
      };
    case CLOSE_FILE_VIEWER:
      return {...state, show: false, file: null, meta: null};
    default:
      return state;
  }
};

export {fileViewerData};
