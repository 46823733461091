import {
  OPEN_CONTACTS_MODAL,
  CLOSE_CONTACTS_MODAL,
  UPDATE_SEARCH_VALUE_SHARED,
} from "./actions";

const initState = {
  show: false,
  title: "",
  name: "",
  sub: "",
  data: "",
  image: "",
  className: "",
  search: "",
};

const contactsModalData = (state = initState, action) => {
  switch (action.type) {
    case OPEN_CONTACTS_MODAL:
      return {
        ...state,
        show: true,
        title: action.payload.title,
        name: action.payload.name,
        sub: action.payload.sub,
        data: action.payload.data,
        image: action.payload.image,
        className: action.payload.className,
      };
    case UPDATE_SEARCH_VALUE_SHARED:
      return {
        ...state,
        search: action.search,
      };
    case CLOSE_CONTACTS_MODAL:
      return {
        ...state,
        show: false,
        title: "",
        name: "",
        sub: "",
        data: "",
        image: "",
        className: "",
        search: "",
      };
    default:
      return state;
  }
};

export {contactsModalData};
