import {defineMessages} from "react-intl";

const scope = "metalSupply";

const messages = defineMessages({
  metalSupplyTitle: {
    id: `${scope}.metalSupplyTitle`,
    defaultMessage: "Metals Supply",
  },
  allocatedMetals: {
    id: `${scope}.allocatedMetals`,
    defaultMessage: "Allocated Metals",
  },
  supplier: {
    id: `${scope}.supplier`,
    defaultMessage: "Supplier",
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: "Status",
  },
  orders: {
    id: `${scope}.orders`,
    defaultMessage: "Orders",
  },
  linked: {
    id: `${scope}.linked`,
    defaultMessage: "Linked",
  },
  acceptOrder: {
    id: `${scope}.acceptOrder`,
    defaultMessage: "Accept Order",
  },
  metalSupplied: {
    id: `${scope}.metalSupplied`,
    defaultMessage: "Metal to be Supplied",
  },
  metal: {
    id: `${scope}.metal`,
    defaultMessage: "Metal",
  },
  dateTime: {
    id: `${scope}.dateTime`,
    defaultMessage: "Date & Time",
  },
  orderedMetal: {
    id: `${scope}.orderedMetal`,
    defaultMessage: "Ordered Metal",
  },
  bullionWeight: {
    id: `${scope}.bullionWeight`,
    defaultMessage: "Bullion Weight",
  },
  weight: {
    id: `${scope}.weight`,
    defaultMessage: "Weight",
  },
  weightKg: {
    id: `${scope}.weightKg`,
    defaultMessage: "Weight Kg",
  },
  priceKgUSD: {
    id: `${scope}.priceKgUSD`,
    defaultMessage: "Price per Kg, USD",
  },
  priceKg: {
    id: `${scope}.priceKg`,
    defaultMessage: "Price, 1Kg",
  },
  period: {
    id: `${scope}.period`,
    defaultMessage: "Period",
  },
  refiner: {
    id: `${scope}.refiner`,
    defaultMessage: "Refiner",
  },
  orderAmount: {
    id: `${scope}.orderAmount`,
    defaultMessage: "Order Amount",
  },
  confirmDelivery: {
    id: `${scope}.confirmDelivery`,
    defaultMessage: "Confirm Delivery",
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: "Cancel",
  },
  details: {
    id: `${scope}.details`,
    defaultMessage: "Details",
  },
  bullionDetails: {
    id: `${scope}.bullionDetails`,
    defaultMessage: "Bullion Details",
  },
  bullionId: {
    id: `${scope}.bullionId`,
    defaultMessage: "Bullion ID",
  },
  linkedCoins: {
    id: `${scope}.linkedCoins`,
    defaultMessage: "Linked Coins",
  },
  orderDetails: {
    id: `${scope}.orderDetails`,
    defaultMessage: "Order Details",
  },
  deliveryDate: {
    id: `${scope}.deliveryDate`,
    defaultMessage: "Delivery Date",
  },
  linkedToCoins: {
    id: `${scope}.linkedToCoins`,
    defaultMessage: "Linked To coins",
  },
});

export default messages;
