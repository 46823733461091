import React from "react";
import {MenuItem} from "@material-ui/core";
import {getCoinNameByCurrency, getWalletIconByName} from "shared/tradeService/selectors";

import "./index.scss";

export const CurrencyOption = React.forwardRef(
  ({currency, className, value, ...props}, ref) => {
    return (
      <MenuItem value={value} className={`walletOption ${className}`} {...props}>
        <div className="title">
          <div className="name">
            <img
              className="icon"
              src={getWalletIconByName(getCoinNameByCurrency(currency.code))}
              alt="coin"
            />{" "}
            {getCoinNameByCurrency(currency.code)}
          </div>
        </div>
      </MenuItem>
    );
  }
);

export const CurrencyOptionSelected = React.forwardRef(
  ({currency, className, value, ...props}, ref) => {
    return (
      <MenuItem value={value} className={`walletOption ${className}`} {...props}>
        <div className="itemsWrap">
          <div className="imageAlign">
            <img
              className="icon"
              src={getWalletIconByName(getCoinNameByCurrency(currency.code))}
              alt="coin"
            />
          </div>
          <div className="textAlign">
            <div className="title">
              <div className="name">
                {getCoinNameByCurrency(currency.code)}
                {" ("}
                {currency.code}
                {")"}
              </div>
            </div>
            <div className="optionDescription">{"Market"}</div>
          </div>
        </div>
      </MenuItem>
    );
  }
);
