import React from "react";
import "./index.scss";

const HistoryItem = ({time, amount, currency, purpose, status, description}) => {
  return (
    <div className="HistoryItem">
      <div className="HistoryItemHeader">
        <span className="HistoryItemTime">{time}</span>
        <span className="HistoryItemAmount">
          {amount} {currency}
        </span>
      </div>
      <div className="HistoryItemDetails">
        <div className="HistoryItemPurposeDescription">
          <div className="HistoryItemPurpose">{purpose}</div>
          <div className="HistoryItemDescription">{description}</div>
        </div>
        <div className={`HistoryItemStatus ${status}`}>{status}</div>
      </div>
      <div className="HistoryItemSeparator"></div>
    </div>
  );
};

export default HistoryItem;
