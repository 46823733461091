import React from "react";
import "./index.scss";
import PropTypes from "prop-types";
import HistoryItem from "../HistoryItem";

const HistoryDayWrapper = ({date, totalAmount, items}) => {
  return (
    <div className="HistoryDayWrapper">
      <div className="HistoryDayWrapperTitle">
        <span className="HistoryDayDate">{date}</span>
        <span className="HistoryDayBalance">{totalAmount} USD</span>
      </div>
      <div className="HistoryDayContainer">
        {items.map((item, index) => (
          <HistoryItem
            key={index}
            time={item.time}
            amount={item.amount}
            currency={item.currency}
            purpose={item.purpose}
            status={item.status}
            description={item.description}
          />
        ))}
      </div>
    </div>
  );
};

HistoryDayWrapper.propTypes = {
  date: PropTypes.string,
  balance: PropTypes.string,
  items: PropTypes.array,
};

export default HistoryDayWrapper;
