import React from "react";
import {connect} from "react-redux";
import RightModal from "shared/components/RightModal";
import ManageForm from "./ManageForm";

const modalVariants = {
  shoppingVoucher: "shoppingModalVoucher",
  shoppingCard: "shoppingModalCard",
};

const ShoppingDetailsModals = ({modalData, setChangeName, setChangeAddress}) => {
  if (modalData === null) {
    modalData = {
      title: "none",
      name: "none",
      sub: "none",
    };
  }

  return (
    <>
      {modalData.title && (
        <RightModal
          title={modalData && modalData.title ? modalData.title : null}
          subTitle={modalData && modalData.subTitle ? modalData.subTitle : null}
        >
          {modalData.name === modalVariants.shoppingCard && <ManageForm type="card" />}
          {modalData.name === modalVariants.shoppingVoucher && (
            <ManageForm type="voucher" />
          )}
        </RightModal>
      )}
    </>
  );
};

const mapStateToProps = store => ({
  modalData: store.rightModalData.variant,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingDetailsModals);
