// Here we store all API endpoints urls

import {APILinks} from "shared/appUrls";

export const AuthEndpoints = {
  login: `${APILinks.baseIDPUrl}/connect/token`,
  signUp: `${APILinks.baseIDPUrl}/account/new`,
  getUserInfo: `${APILinks.baseIDPUrl}/connect/userinfo`,
  userProfile: `${APILinks.baseAPIUrl}/v1/user/profile`,
  passPhrase: `${APILinks.baseIDPUrl}/account/passphrase`,
  passReset: `${APILinks.baseIDPUrl}/account/password/reset`,
  uploadDrivingLicence: `${APILinks.baseIDPUrl}/account/doc`,
  shareMyContact: `${APILinks.baseAPIUrl}/user/share`,
  getDevices: `${APILinks.baseIDPUrl}/device`,
  updateDevice: id => `${APILinks.baseIDPUrl}/device/${id}`,
  getAvatarUser: id => `${APILinks.baseAPIUrl}/user/profile/photo/${id}`,
  uploadAvatar: `${APILinks.baseAPIUrl}/user/profile/photo`,
  accountCheck: `${APILinks.baseIDPUrl}/account/check`,
  sendEmailConfirmCode: `${APILinks.baseIDPUrl}/account/email/confirmation`,
  countriesWithDocs: `${APILinks.baseIDPUrl}/account/onfido/countries`,
};

export const ContactsEndpoints = {
  contacts: `${APILinks.baseAPIUrl}/contact`,
  contactsUpdateAndDelete: id => `${APILinks.baseAPIUrl}/contact/${id}`,
  contactsShared: id => `${APILinks.baseAPIUrl}/contact/share/${id}`,
};

export const CardEndpoints = {
  cardOnWallet: `${APILinks.baseAPIUrl}/wallet`,
  createRegular: `${APILinks.baseAPIUrl}/card/regular`,
  getRegularCards: `${APILinks.baseAPIUrl}/card/regular`,
  currencyRegularAll: `${APILinks.baseAPIUrl}/currency/regular/all`,
  cardRegularQRCode: `${APILinks.baseAPIUrl}/card/regular/qr`,
  cardOnWalletQRCode: `${APILinks.baseAPIUrl}/card/onwallet/qr`,
  transferRegularToRegular: `${APILinks.baseAPIUrl}/card/regular/regular`,
  cardRegularDefault: id => `${APILinks.baseAPIUrl}/card/regular/${id}/default`,
  cardRegularUpdate: id => `${APILinks.baseAPIUrl}/card/regular/${id}`,
  cardRegularDelete: id => `${APILinks.baseAPIUrl}/card/regular/${id}/remove`,
  transferOnWalletToCard: `${APILinks.baseAPIUrl}/card/onwallet/regular`,
  transferCardToOnWallet: `${APILinks.baseAPIUrl}/card/regular/onwallet`,
  transferOnWalletToOnWallet: `${APILinks.baseAPIUrl}/card/onwallet/onwallet`,
};

export const CryptoEndpoints = {
  cryptoWalletAvailable: `${APILinks.baseAPIUrl}/wallet/crypto/available/currency`,
  cryptoCurrencyAll: `${APILinks.baseAPIUrl}/currency/crypto/available`,
  cryptoWalletAll: `${APILinks.baseAPIUrl}/wallet/crypto/all`,
  cryptoWallet: `${APILinks.baseAPIUrl}/wallet/crypto`,
  cryptoWalletTransfer: `${APILinks.baseAPIUrl}/crypto/transfer`,
  transferCryptoToCard: `${APILinks.baseAPIUrl}/crypto/sell/regular`,
  transferCardToCrypto: `${APILinks.baseAPIUrl}/crypto/buy/regular`,
  transferOnWalletToCrypto: `${APILinks.baseAPIUrl}/crypto/buy`,
  transferCryptoToOnWallet: `${APILinks.baseAPIUrl}/crypto/sell`,
  walletDelete: currency => `${APILinks.baseAPIUrl}/wallet/crypto/${currency}/remove`,
};

export const RatesEndpoints = {
  currencyRates: `${APILinks.baseAPIUrl}/currency/rates`,
  getCurrencyRate: `${APILinks.baseAPIUrl}/binance/dashboardgraph`,
  getCurrencyRatesTable: `${APILinks.baseAPIUrl}/binance/dashboardtable`,
};

export const HistoryEndpoints = {
  history: `${APILinks.baseAPIUrl}/wallet/history`,
  cryptoHistory: `${APILinks.baseAPIUrl}/wallet/balance/history/crypto`,
  regularHistory: `${APILinks.baseAPIUrl}/wallet/balance/history/regular`,
};

export const tradingEndpoints = {
  orders: (cryptoCurrency, currency, limit) =>
    `${APILinks.baseAPIUrl}/v1/trade/unit/pair/${cryptoCurrency}/${currency}/${limit}`,
  tradeHistoryByLimit: limit => `${APILinks.baseAPIUrl}/v1/trade/history/set/${limit}`,
  newOrder: () => `${APILinks.baseAPIUrl}/v1/trade/unit`,
  ordersHistory: `${APILinks.baseAPIUrl}/v1/trade/history/search`,
  // orders: (cryptoCurrency, currency, limit) => `${APILinks.baseAPIUrl}/v1/trade/unit/pair/${cryptoCurrency}/${currency}/${limit}`,
  ordersByType: `${APILinks.baseAPIUrl}/v1/trade/unit/search`,
  // tradeHistoryByLimit: (limit) => `${APILinks.baseAPIUrl}/v1/trade/history/set/${limit}`,
  tradeHistoryFilterByLimit: `${APILinks.baseAPIUrl}/v1/trade/history/search`,
  graph: () => `${APILinks.baseAPIUrl}/v1/trade/report/search`,
  getTradeGraph: `${APILinks.baseAPIUrl}/binance/tradinggraph`,
  getTradeGraphHeader: `${APILinks.baseAPIUrl}/binance/tradinggraphheader`,
};

export const messagesEndpoints = {
  messages: `${APILinks.baseAPIUrl}/notify`,
  messageUpdate: id => `${APILinks.baseAPIUrl}/notify/${id}`,
};

export const shoppingEndpoints = {
  voucher: `${APILinks.baseAPIUrl}/voucher`,
  sendVoucher: `${APILinks.baseAPIUrl}/voucher/send`,
  voucherTransferOnWallet: `${APILinks.baseAPIUrl}/voucher/transfer/onwallet`,
  voucherTransferEmail: `${APILinks.baseAPIUrl}/voucher/transfer/email`,
  voucherTransferAccept: `${APILinks.baseAPIUrl}/voucher/transfer/accept`,
  voucherTransferReject: `${APILinks.baseAPIUrl}/voucher/transfer/reject`,
  voucherGetQRCode: (voucherId, merchantId) =>
    `${APILinks.baseAPIUrl}/voucher/${voucherId}/merchant/${merchantId}/qr`,
  removeUserVoucherTransfer: (voucherId, merchantId) =>
    `${APILinks.baseAPIUrl}/voucher/${voucherId}/merchant/${merchantId}/remove`,
};
