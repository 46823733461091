import React, {Fragment} from "react";
// import {FormattedMessage} from "react-intl";
// import messages from "../../messages";
import moment from "moment";
import Barcode from "react-barcode";
import {connect} from "react-redux";
import {openRightModal} from "shared/components/RightModal/actions";

// const infoIcon = config.prefixImgUrl + "info-icon-blue.svg";

const CardsInfo = ({cardData, openRightModal}) => {
  // Error handler
  if (cardData === undefined) return false;

  return (
    <Fragment>
      {!!cardData && !!cardData.title && (
        <div className="main_block">
          <div className={"top_block"}>
            <div className={"short_info"}>
              <div className={"data_card"}>
                <div className={"imageLogo"}>
                  <img
                    src="http://www.alluserpics.com/data/media/29/01911.jpg"
                    alt="alt_image"
                  />
                </div>
                <div className={"info"}>
                  <h2>{cardData.title}</h2>
                  <span className={"links"}> {cardData.cardName} | Apparel</span>
                  <div className={"card_id"}>Card# 1234567890</div>
                </div>
              </div>
              <div className={"bar_code"}>
                <Barcode
                  value="1234567890"
                  format="CODE128"
                  displayValue={false}
                  width={5}
                  height={34}
                />
              </div>
            </div>

            <div className={"action_block"}>
              <button
                className="button"
                type="button"
                onClick={() =>
                  openRightModal({
                    name: "shoppingModalCard",
                    title: `${cardData.title}`,
                    subTitle: "Voucher # 1234567890",
                  })
                }
              >
                Manage
                {/* <FormattedMessage {...messages.reset_password} /> */}
              </button>
            </div>
          </div>

          <div className={"bottom_block"}>
            <div className={"bottom_block_header"}>
              <div className={"block"}>
                <div className="block_value">
                  {cardData.percent ? `${cardData.percent}%` : ""}
                </div>
                <div className="block_text">discount</div>
              </div>
              <div className={"block"}>
                <div className="block_value">
                  {moment(cardData.expirationDate).format("DD.MM.YYYY")}
                </div>
                <div className="block_text">valid till</div>
              </div>
            </div>
            <div className={"description"}>
              The North Face® fundamental mission remains unchanged since 1966: Provide
              the best gear for our athletes and the modern day explorer, support the
              preservation of the outdoors, and inspire a global movement of exploration.
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = store => ({
  cardData: store.shoppingReducer.shoppingCardData,
});
function dispatchToProps(dispatch) {
  return {
    openRightModal: data => {
      dispatch(openRightModal(data));
    },
  };
}

export default connect(mapStateToProps, dispatchToProps)(CardsInfo);
