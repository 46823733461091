/**
 * @author: Seleznyov
 */

import React from "react";
import {FormattedMessage} from "react-intl";
import Header from "shared/components/Header";
// import Button from "shared/components/Button";
import {routes} from "shared/appUrls";
import {Link} from "react-router-dom";

import messages from "../../messages";
import "../../index.scss";

import config from "shared/config";
const logoPage = config.prefixImgUrl + "left-arrow-icon.svg";

const ApprovalHistory = () => {
  return (
    <>
      <Header
        headerTitle={<FormattedMessage {...messages.approvalHistoryTitle} />}
        navLinks={[]}
        logoImage={logoPage}
      >
        <div className="messages__header__actionBlock">
          <Link
            to={routes.actionsApproval}
            className="btn-ui btn-bold-black btn-with-icon"
          >
            <FormattedMessage {...messages.actionsApprovalPageTitle} />
          </Link>
        </div>
      </Header>

      <div className="actions-approval-page"></div>
    </>
  );
};

export default ApprovalHistory;
