import React, {Component} from "react";
import ToggleMenu from "shared/components/ToggleMenu";
import {DeviceUUID} from "device-uuid";
import moment from "moment";
import {ReactComponent as SettingIcon} from "images/settings-icon-gray.svg";

import config from "shared/config";

import "../../index.scss";

class TrustedOneDevice extends Component {
  render() {
    const data = this.props.data;
    let icon = config.prefixImgUrl + "account_settings_pc_icon.svg";

    if (data && data.info.isDesktop) data.type = "computer";
    if (data && data.info.isTablet) data.type = "tablet";
    if (data && data.info.isMobile) data.type = "smartphone";

    data.info.active = data.id === new DeviceUUID().get();

    switch (data.type) {
      case "smartphone":
        icon = config.prefixImgUrl + "account_settings_smartphone_icon.svg";
        break;
      case "tablet":
        icon = config.prefixImgUrl + "account_settings_tablet_icon.svg";
        break;
      case "computer":
        icon = config.prefixImgUrl + "account_settings_pc_icon.svg";
        break;
      default:
        icon = config.prefixImgUrl + "account_settings_pc_icon.svg";
        break;
    }

    return (
      <div className={"trunstedOne"}>
        <img src={icon} alt="icon" />
        <div className={"content_block"}>
          <div className={"font-16-medium"}>{data.info.name_device}</div>
          <div className={"os"}>{data.info.os}</div>
          <div className="region">{`${data.geo.city}, ${data.geo.region_name}, ${data.geo.country_name}`}</div>
          <div className={"timing " + (data.info.active ? "active" : "")}>
            {data.info.active
              ? !this.props.forAdmin
                ? "This Device"
                : "Using Now"
              : moment()
                  .startOf("hour")
                  .fromNow(data.lastAccess)}
          </div>
        </div>
        {data.info.active ? (
          ""
        ) : (
          <ToggleMenu
            className="popup"
            contentStyle="changeBackground"
            label={() => (
              <div className="settings">
                <SettingIcon className="settingsIcon" />
              </div>
            )}
            children={<div>{"Logout & remove from trusted"}</div>}
          />
        )}
      </div>
    );
  }
}

export default TrustedOneDevice;
