import React, {Fragment} from "react";
import {connect} from "react-redux";
import {Formik, Form, Field} from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
// Actions
import {closeRightModal} from "shared/components/RightModal/actions";
// Selectors
import {dataRightModal} from "shared/components/RightModal/selectors";
// Components
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import Input from "shared/components/Input";
import Button from "shared/components/Button";
import DropZone from "shared/components/DropZone";
import RegisterFileList from "shared/components/RegisterFileList";
// Utils
import {FormattedMessage} from "react-intl";
import messages from "../../messages";
import config from "shared/config";
// Styles
import "./index.scss";
// Images
const eyeIcon = config.prefixImgUrl + "onwallet-eye.svg";

const mockData = ["File name 1.jpg", "File name 2.jpg"];

const ChangeNameCustomerModal = ({customer, closeRightModal}) => {
  const initFormik = {
    firstName: "",
    lastName: "",
    documents: mockData,
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("First Name is required")
      .matches(/^[a-zA-Z]+$/, "First Name is not valid"),
    lastName: Yup.string()
      .required("Last Name is required")
      .matches(/^[a-zA-Z]+$/, "Last Name is not valid"),
    documents: Yup.array().required("Documents is required"),
  });

  return (
    <Fragment>
      <Formik
        initialValues={initFormik}
        validationSchema={validationSchema}
        onSubmit={() => closeRightModal()}
      >
        {({values, errors, touched, setFieldValue, isValid}) => (
          <Form>
            {/* First Section */}
            <div className="customer-change-name">
              <div className="customer-change-name__current-name">
                <div className="customer-change-name__description">
                  Current Legal Name:
                </div>
                <div className="customer-change-name__info">
                  {`${customer.name.first} ${customer.name.last}`}
                </div>
              </div>
              <div className="customer-change-name__documents">
                <div className="customer-change-name__documents__description">
                  Documents:
                </div>
                <div className="customer-change-name__info">
                  <div className="customer-change-name__info__item">
                    <div className="customer-change-name__info__item-name">
                      File name 1.jpg
                    </div>
                    <div className="customer-change-name__info__item-image">
                      <img src={eyeIcon} alt="Eye icon" />
                    </div>
                  </div>
                  <div className="customer-change-name__info__item">
                    <div className="customer-change-name__info__item-name">
                      File name 2.jpg
                    </div>
                    <div className="customer-change-name__info__item-image">
                      <img src={eyeIcon} alt="Eye icon" />
                    </div>
                  </div>
                </div>
              </div>

              {/* Second Sections */}
              <FormSectionSeparator sectionName={messages.newName} />

              <div className="customer-change-name__form">
                <Field name="firstName">
                  {({field}) => (
                    <div className="customer-change-name__form-input">
                      <div className="label">
                        <FormattedMessage {...messages.firstName} />
                      </div>
                      <div className="linkInput">
                        <Input
                          type="text"
                          error={touched.firstName && errors.firstName}
                          value={values.firstName}
                          name="firstName"
                          {...field}
                        />
                      </div>
                    </div>
                  )}
                </Field>

                <Field name="lastName">
                  {({field}) => (
                    <div className="customer-change-name__form-input">
                      <div className="label">
                        <FormattedMessage {...messages.lastName} />
                      </div>
                      <div className="linkInput">
                        <Input
                          type="text"
                          error={touched.lastName && errors.lastName}
                          value={values.lastName}
                          name="lastName"
                          {...field}
                        />
                      </div>
                    </div>
                  )}
                </Field>
              </div>

              <div className="customer-change-name__hint">
                In order to confirm change of legal name, please attach copies of
                documents confirming it.
              </div>

              <div className="customer-change-name__drop">
                <DropZone
                  onChange={files => {
                    const list = values.documents;
                    files.forEach(file => list.push(file.name));
                    setFieldValue("documents", list);
                  }}
                  accept=".jpg, .jpeg, .png, .pdf"
                />
              </div>

              <div className="customer-change-name__files">
                <RegisterFileList
                  list={values.documents || []}
                  onDelete={index => {
                    const list = values.documents.filter((file, idx) => idx !== index);
                    setFieldValue("documents", list);
                  }}
                />
              </div>

              <div className="customer-change-name__actions">
                <Button
                  type="button"
                  text={messages.cancel}
                  isSecondary="true"
                  onClick={closeRightModal}
                />
                <Button type="submit" disabled={!isValid} text={messages.applyToChange} />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

// Types
ChangeNameCustomerModal.propTypes = {
  customer: PropTypes.object.isRequired,
  closeRightModal: PropTypes.func.isRequired,
};

// Redux
const mapStateToProps = state => ({
  customer: dataRightModal(state),
});

const mapDispatchToProps = {
  closeRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeNameCustomerModal);
