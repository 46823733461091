import {
  FETCH_TRANSACTIONS_PENDING,
  FETCH_TRANSACTIONS_SUCCESS,
  FETCH_TRANSACTIONS_FAILED,
  FETCH_DEHO_HISTORY_PENDING,
  FETCH_ONWALLET_HISTORY_PENDING,
  FETCH_DEHO_HISTORY_SUCCESS,
  FETCH_ONWALLET_HISTORY_SUCCESS,
  FETCH_DEHO_HISTORY_FAILED,
  FETCH_ONWALLET_HISTORY_FAILED,
} from "./actions/types";

const initHistoryState = {
  isLoading: false,
  transactions: [],
  filters: [],
};

const transactionsHistoryData = (state = initHistoryState, action) => {
  switch (action.type) {
    case FETCH_TRANSACTIONS_PENDING:
      return {...state, isLoading: true};
    case FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: action.payload,
        isLoading: false,
      };
    case FETCH_TRANSACTIONS_FAILED:
      return {...state, isLoading: false};
    default:
      return state;
  }
};

const initBalanceHistoryState = {
  isDehoLoading: false,
  isOnWalletLoading: false,
  dehoHistory: [],
  onwalletHistory: [],
};

const balanceHistoryData = (state = initBalanceHistoryState, action) => {
  switch (action.type) {
    case FETCH_DEHO_HISTORY_PENDING:
      return {...state, isDehoLoading: true};
    case FETCH_ONWALLET_HISTORY_PENDING:
      return {...state, isOnWalletLoading: true};
    case FETCH_DEHO_HISTORY_SUCCESS:
      return {...state, isDehoLoading: false, dehoHistory: action.payload};
    case FETCH_ONWALLET_HISTORY_SUCCESS:
      return {...state, isOnWalletLoading: false, onwalletHistory: action.payload};
    case FETCH_DEHO_HISTORY_FAILED:
      return {...state, isDehoLoading: false};
    case FETCH_ONWALLET_HISTORY_FAILED:
      return {...state, isOnWalletLoading: false};
    default:
      return state;
  }
};

export {transactionsHistoryData, balanceHistoryData};
