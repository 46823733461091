import React from "react";
// import PropTypes from "prop-types";
// Components
import CustomersTableHeader from "./CustomersTableHeader";
import CustomersTableBody from "./CustomersTableBody";
// Styles
import "./index.scss";

const CustomersTable = () => {
  return (
    <>
      <div className="customers-table-title">New Customers</div>
      <div className="customers-table">
        <CustomersTableHeader />
        <CustomersTableBody />
      </div>
    </>
  );
};

// Types
CustomersTable.propTypes = {
  // TODO
};

export default CustomersTable;
