export const FETCH_TRANSACTIONS_PENDING = "FETCH_TRANSACTIONS_PENDING";
export const FETCH_TRANSACTIONS_SUCCESS = "FETCH_TRANSACTIONS_SUCCESS";
export const FETCH_TRANSACTIONS_FAILED = "FETCH_TRANSACTIONS_FAILED";

export const FETCH_ONWALLET_HISTORY_PENDING = "FETCH_CURRENT_CHART_PENDING";
export const FETCH_ONWALLET_HISTORY_SUCCESS = "FETCH_ONWALLET_HISTORY_SUCCESS";
export const FETCH_ONWALLET_HISTORY_FAILED = "FETCH_ONWALLET_HISTORY_FAILED";

export const FETCH_DEHO_HISTORY_PENDING = "FETCH_DEHO_HISTORY_PENDING";
export const FETCH_DEHO_HISTORY_SUCCESS = "FETCH_DEHO_HISTORY_SUCCESS";
export const FETCH_DEHO_HISTORY_FAILED = "FETCH_DEHO_HISTORY_FAILED";
