import faker from "faker";
import moment from "moment";

const empty = new Array(50).fill(null);

const hour = empty.map(() => {
  return {
    name: moment(faker.date.past()).format("MM-DD-YYYY h:mm"),
    value: faker.random.number({min: 1000, max: 1100}),
    secondValue: faker.random.number({min: 100, max: 1000}),
  };
});

export default hour;
