import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

import {FormattedMessage} from "react-intl";

import {getCompanyVouchers, getCompanyCards} from "shared/shoppingService/selectors";
import SendOption from "./SendOption";

import messages from "../Company/messages";

import "./index.scss";

const fromStates = {
  card: "card",
  voucher: "voucher",
};

const FormTabs = ({from, setFrom}) => {
  return (
    <div className="OptionTabsContainer">
      <div
        className={`SourceTab ${from === fromStates.card ? "ActiveTab" : ""}`}
        onClick={() => setFrom(fromStates.card)}
      >
        <FormattedMessage {...messages.sendcardoption} />
      </div>
      <div
        className={`SourceTab ${from === fromStates.voucher ? "ActiveTab" : ""}`}
        onClick={() => setFrom(fromStates.voucher)}
      >
        <FormattedMessage {...messages.sendvoucheroption} />
      </div>
    </div>
  );
};

const SendToCustomer = ({meta, cards, vouchers}) => {
  const [from, setFrom] = useState(fromStates.card);

  useEffect(() => {
    if (meta && meta.from) {
      setFrom(meta.from);
    }
  }, [setFrom, meta]);

  return (
    <>
      <div className="corporate-send-to-customer-layout">
        <div className="corporate-send-to-customer-header">
          <FormattedMessage {...messages.corporatesendtocustomer} />
        </div>
        <div className="tabs">
          {from === fromStates.card && (
            <SendOption
              formTabs={() => <FormTabs from={from} setFrom={setFrom} />}
              formTabsType={fromStates.card}
              cards={cards}
            />
          )}
          {from === fromStates.voucher && (
            <SendOption
              formTabs={() => <FormTabs from={from} setFrom={setFrom} />}
              formTabsType={fromStates.voucher}
              vouchers={vouchers}
            />
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = store => ({
  meta: fromStates.card,
  cards: getCompanyCards(store),
  vouchers: getCompanyVouchers(store),
});
const dispatchToProps = {};

export default connect(mapStateToProps, dispatchToProps)(SendToCustomer);
