import React from "react";
import config from "shared/config";
import "./index.scss";

const expandIcon = config.prefixImgUrl + "dropdown-arrow.svg";

const MoreLessButton = ({moreLabel, lessLabel, isExpanded, onClick}) => {
  return (
    <div className="more-less-button" onClick={onClick}>
      <span>{isExpanded ? lessLabel : moreLabel}</span>
      <img className={isExpanded ? "up" : ""} alt="" src={expandIcon} />
    </div>
  );
};

export default MoreLessButton;
