import React from "react";
import {withStyles} from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const AntSwitch = withStyles(theme => ({
  root: {
    width: 36,
    height: 18,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.common.white,
    "&$checked": {
      transform: "translateX(18px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#0080FF",
        borderColor: "#0080FF",
      },
    },
  },
  thumb: {
    width: 14,
    height: 14,
    boxShadow: "none",
  },
  track: {
    border: `1px solid #0080ff40`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "#0080ff40",
  },
  checked: {},
}))(Switch);

export default function CustomSwitch({value, onChange}) {
  return (
    <Typography component="div">
      <Grid component="label" container alignItems="center" spacing={1}>
        <AntSwitch checked={value} onChange={onChange} name="checkedC" />
      </Grid>
    </Typography>
  );
}
