import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {createStore, applyMiddleware, compose} from "redux";
import thunk from "redux-thunk";
import reducers from "store";
import axios from "axios";

import "./index.scss";
import App from "./components/App";
import TranslationProvider from "shared/i18n/index";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {createMuiTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import * as serviceWorker from "./serviceWorker";

// Configure redux
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

const materialTheme = createMuiTheme({
  palette: {
    primary: {main: "#0080ff"},
    secondary: {main: "#1f333d"},
  },
  status: {
    danger: {main: "#ff3333"},
  },
});

axios.interceptors.request.use(
  request => request,
  error => Promise.reject(error)
);

axios.interceptors.response.use(
  response => response,
  error => {
    if (!!error && !!error.response && !!error.response.status) {
      if (error.response.status === 403) {
        error.response.description =
          "Limited Access. Your documents are under verification procedure. Until successful verification you do not have access to financial features. You will be informed on result of verification.";
      }
    }

    return Promise.reject(error);
  }
);

ReactDOM.render(
  <Provider store={store}>
    <TranslationProvider>
      <ThemeProvider theme={materialTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <App />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </TranslationProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
