export const getCurrentCartItems = store => {
  return store.cartData.currentItems;
};

export const isExecuting = store => {
  return store.cartData.isExecuting;
};

export const getProcessingItems = store => {
  return store.cartData.processItems;
};

export const getFailedItems = store => {
  return store.cartData.failedItems;
};

export const hasExecuted = store => {
  return store.cartData.hasExecuted;
};

export const isLoading = store => {
  return store.cartData.isLoading;
};

export const isEmpty = store => {
  return (
    store.cartData.currentItems.length === 0 &&
    store.cartData.processItems.length === 0 &&
    store.cartData.failedItems.length === 0
  );
};

export const getCartItemsCount = store => {
  return store.cartData.currentItems.length;
};
