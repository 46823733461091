/**
 * @author: Seleznyov
 */
import React, {Component} from "react";
import messages from "../../messages";
import {FormattedMessage} from "react-intl";
// Components
import AcceptedOrdersItem from "../AcceptedOrders/item";
import CustomSelect from "shared/components/Select";
import {MetalOption} from "shared/components/CardWalletOptions";
import CustomDateRangePicker from "shared/components/DateRangePicker";

class AcceptedOrders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemsOrder: [],
      dateStart: "",
      dateEnd: "",
    };
  }

  componentDidMount() {
    this.getDataList();
  }

  getDataList() {
    let _data = [];
    for (let i = 1; i < 16; i++) {
      _data[i] = {
        id: i,
        dateTime:
          parseInt(this.getRandom(1, 30), 10) +
          " " +
          parseInt(this.getRandom(1, 9), 10) +
          " 2020 | " +
          parseInt(this.getRandom(1, 24), 10) +
          ":" +
          parseInt(this.getRandom(0, 59), 10),
        orderedMetal: parseInt(this.getRandom(1, 4), 10),
        weight: 5,
        price: this.getRandom(900, 70000),
        orderAmount: this.getRandom(4000, 400000),
        status: parseInt(this.getRandom(1, 4), 10),
      };
    }
    this.setState({itemsOrder: _data});
  }

  getRandom(min, max) {
    return Math.random() * (max - min) + min;
  }

  render() {
    const mockSelect = {
      // img: "metal-au.svg",
      metal: "All",
      // metal_value: 999.9,
    };

    return (
      <div className="accepted-orders-section">
        {/* Title */}
        <div className="card">
          <div className="card__header">
            <div className="accepted-orders-section__title">
              <FormattedMessage {...messages.orders} />
            </div>
          </div>
          {/* Filter Section */}
          <div className="accepted-orders-section__filter">
            <div className="accepted-orders-section__filter__item">
              <CustomDateRangePicker
                style={{left: 100}}
                onChange={value => {
                  this.setState({dataStart: value.start});
                  this.setState({dataEnd: value.end});
                }}
                title={messages.period}
              />
            </div>
            {/* Example how use selectors in accept new order modal */}
            <div className="accepted-orders-section__filter__item">
              <div>
                <label className="label">
                  <FormattedMessage {...messages.metal} />
                </label>
                <CustomSelect
                  name="select"
                  className="acceptNewOrder__select__item"
                  renderValue={value =>
                    !!value ? <MetalOption className="no-padding" metal={value} /> : ""
                  }
                  value={mockSelect}
                  // onChange={e => setFieldValue("select", e.target.value)}
                >
                  {/* {METALS.map((metal, index) => {
                    return <MetalOption key={index} value={metal} metal={metal} />;
                  })} */}
                </CustomSelect>
              </div>
            </div>
            <div className="accepted-orders-section__filter__item">
              <div>
                <label className="label">
                  <FormattedMessage {...messages.supplier} />
                </label>
                <CustomSelect
                  name="select"
                  className="acceptNewOrder__select__item"
                  renderValue={value =>
                    !!value ? <MetalOption className="no-padding" metal={value} /> : ""
                  }
                  value={mockSelect}
                  // onChange={e => setFieldValue("select", e.target.value)}
                >
                  {/* {METALS.map((metal, index) => {
                    return <MetalOption key={index} value={metal} metal={metal} />;
                  })} */}
                </CustomSelect>
              </div>
            </div>
            <div className="accepted-orders-section__filter__item">
              <div>
                <label className="label">
                  <FormattedMessage {...messages.supplier} />
                </label>
                <CustomSelect
                  name="select"
                  className="acceptNewOrder__select__item"
                  renderValue={value =>
                    !!value ? <MetalOption className="no-padding" metal={value} /> : ""
                  }
                  value={mockSelect}
                  // onChange={e => setFieldValue("select", e.target.value)}
                >
                  {/* {METALS.map((metal, index) => {
                    return <MetalOption key={index} value={metal} metal={metal} />;
                  })} */}
                </CustomSelect>
              </div>
            </div>
            <div className="accepted-orders-section__filter__item">
              <div>
                <label className="label">
                  <FormattedMessage {...messages.status} />
                </label>
                <CustomSelect
                  name="select"
                  className="acceptNewOrder__select__item"
                  renderValue={value =>
                    !!value ? <MetalOption className="no-padding" metal={value} /> : ""
                  }
                  value={mockSelect}
                  // onChange={e => setFieldValue("select", e.target.value)}
                >
                  {/* {METALS.map((metal, index) => {
                    return <MetalOption key={index} value={metal} metal={metal} />;
                  })} */}
                </CustomSelect>
              </div>
            </div>
          </div>
          {/* Table Section */}
          <div className="accepted-orders-section__table">
            <div className="card__body">
              <div className="table-ui">
                <div className="table-ui__header">
                  <div className="table-ui__row">
                    <div className="table-ui__item item-left">#</div>
                    <div className="table-ui__item item-left">
                      <FormattedMessage {...messages.dateTime} />
                    </div>
                    <div className="table-ui__item item-left">
                      <FormattedMessage {...messages.supplier} />
                    </div>
                    <div className="table-ui__item item-left">
                      <FormattedMessage {...messages.refiner} />
                    </div>
                    <div className="table-ui__item item-left">
                      <FormattedMessage {...messages.orderedMetal} />
                    </div>
                    <div className="table-ui__item item-right">
                      <FormattedMessage {...messages.weight} />
                    </div>
                    <div className="table-ui__item item-right">
                      <FormattedMessage {...messages.orderAmount} />
                    </div>
                    <div className="table-ui__item item-left">
                      <FormattedMessage {...messages.status} />
                    </div>
                    <div className="table-ui__item item-right"></div>
                  </div>
                </div>
                <div className="table-ui__body">
                  {this.state.itemsOrder.map((item, key) => {
                    return <AcceptedOrdersItem key={key} item={item} />;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AcceptedOrders;
