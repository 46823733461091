import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import NewOrderItem from "./NewOrderItem";
import {routes} from "shared/appUrls";

const fromStates = {
  sell: "sell",
  buy: "buy",
};

const FormTabs = ({from, setFrom, market}) => {
  return (
    <div className="SourceTabsContainer">
      <div
        className={`SourceTab ${from === fromStates.buy ? "ActiveTab" : ""}`}
        onClick={() => setFrom(fromStates.buy)}
      >
        {"BUY " + market}
      </div>
      <div
        className={`SourceTab ${from === fromStates.sell ? "ActiveTab" : ""}`}
        onClick={() => setFrom(fromStates.sell)}
      >
        {"SELL " + market}
      </div>
    </div>
  );
};

const NewOrder = ({market, meta}) => {
  const [from, setFrom] = React.useState(fromStates.buy);
  React.useEffect(() => {
    if (meta && meta.from) {
      setFrom(meta.from);
    }
  }, [setFrom, meta]);
  return (
    <>
      <div className="widget__header">
        <div className="widget__header__title">
          <h2>New Orders</h2>
        </div>
        <div className="widget__header__tools">
          <Link className="Action" to={routes.textpage}>
            {"Trading Rules"}
          </Link>
        </div>
      </div>

      <div className="widget__body__content_new_order">
        {from === fromStates.buy && (
          <NewOrderItem
            formTabs={() => (
              <FormTabs from={from} setFrom={setFrom} market={market.code} />
            )}
            market={market}
            mode={1}
            isBuy={true}
          />
        )}

        {from === fromStates.sell && (
          <NewOrderItem
            formTabs={() => (
              <FormTabs from={from} setFrom={setFrom} market={market.code} />
            )}
            market={market}
            mode={0}
            isBuy={false}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = store => ({
  market: store.marketData.market,
  meta: fromStates.buy,
});

const mapDispatchToProps = {
  execute: "NEW_ORDER_EXECUTE_SUCCESS",
};

export default connect(mapStateToProps, mapDispatchToProps)(NewOrder);
