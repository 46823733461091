import axios from "axios";
import {shoppingEndpoints} from "./apiEndpoints";

export const getVoucherAPI = option => {
  return axios.get(shoppingEndpoints.voucher, {
    ...option,
  });
};

export const voucherGetQRCodeAPI = (option, voucherId, merchantId) => {
  return axios.get(shoppingEndpoints.voucherGetQRCode(voucherId, merchantId), {
    ...option,
  });
};

export const createVoucherAPI = (option, data) => {
  return axios.post(shoppingEndpoints.voucher, data, {
    ...option,
  });
};

export const sendVoucherOnWalletAccountAPI = (option, data) => {
  return axios.post(shoppingEndpoints.sendVoucher, data, {
    ...option,
  });
};

export const voucherTransferOnWalletAPI = (option, data) => {
  return axios.post(shoppingEndpoints.voucherTransferOnWallet, data, {
    ...option,
  });
};

export const voucherTransferEmailAPI = (option, data) => {
  return axios.post(shoppingEndpoints.voucherTransferEmail, data, {
    ...option,
  });
};

export const voucherTransferAcceptAPI = (option, data) => {
  return axios.post(shoppingEndpoints.voucherTransferAccept, data, {
    ...option,
  });
};

export const voucherTransferRejectAPI = (option, data) => {
  return axios.post(shoppingEndpoints.voucherTransferReject, data, {
    ...option,
  });
};

export const removeUserVoucherTransferAPI = (option, voucherId, merchantId) => {
  return axios.delete(
    shoppingEndpoints.removeUserVoucherTransfer(voucherId, merchantId),
    {
      ...option,
    }
  );
};
