import React, {useState} from "react";
import {connect} from "react-redux";
import {injectIntl, FormattedMessage} from "react-intl";
import messages from "./messages";
import "./index.scss";
import {Formik, Form} from "formik";
import * as Yup from "yup";
import {isAuthLoading, isUserAuth} from "../../shared/auth/selectors";
import {signUp} from "shared/auth/actions";
import SaveRedirect from "shared/components/SaveRedirect";

import {Link} from "react-router-dom";
import {routes} from "../../shared/appUrls";
import FirstRegisterStep from "./components/FirstRegisterStep";
import {Scrollbars} from "react-custom-scrollbars";
import SecondRegisterStep from "./components/SecondRegisterStep";
import ThirdRegisterStep from "./components/ThirdRegistrationStep";
import FourthRegisterStep from "./components/FourthRegistrationStep";
import FifthRegisterStep from "./components/FifthRegisterStep";

import {ReactComponent as CloseIcon} from "images/close-icon.svg";

import config from "shared/config";
// onwallet-icon-short
const onwalletLogo = config.prefixImgUrl + "logo-registration.svg";
const onwalletIconShort = config.prefixImgUrl + "onwallet-icon-short.svg";
const registerBGOne = config.prefixImgUrl + "register-bg-one.svg";
const registerBGTwo = config.prefixImgUrl + "register-bg-two.svg";
const registerBGThree = config.prefixImgUrl + "register-bg-three.svg";
const registerBGFour = config.prefixImgUrl + "register-bg-four.svg";
const registerBGFive = config.prefixImgUrl + "register-bg-five.svg";

let initialValues = {
  type: "person",
  email: "",
  emailConfirmationCode: "999999",
  firstName: "",
  lastName: "",
  companyName: "",
  companySite: "",
  password: "",
  confirmPassword: "",
  country: "",
  zip: "",
  street: "",
  apartment: "",
  region: "",
  city: "",
  bill: "",
  billFile: "",
  companyNumber: "",
  certificate: "",
  memorandum: "",
  taxID: "",
  validFiles: [],
  dialCode: "",
  phoneCode: "",
  phoneNumber: "",
  copyPhrase: false,
  passPhrase: "",
  specialization: "",
};

const RegisterPageSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  companyName: Yup.string().when("type", {
    is: "company",
    then: Yup.string().required("Company name is required"),
  }),
  email: Yup.string()
    .email("Email must be valid")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(
      "^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{8,}$",
      "Password must contain uppercase and lowercase chars and digits"
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Password not equals")
    .required("Confirm password is required"),
  country: Yup.string().required("Country is required"),
  zip: Yup.string().when("type", {
    is: "person",
    then: Yup.string().required("Zip Code is required"),
  }),
  street: Yup.string().when("type", {
    is: "person",
    then: Yup.string().required("Street is required"),
  }),
  apartment: Yup.string().when("type", {
    is: "person",
    then: Yup.string().required("Apartment is required"),
  }),
  region: Yup.string().when("type", {
    is: "person",
    then: Yup.string().required("Region is required"),
  }),
  city: Yup.string().when("type", {
    is: "person",
    then: Yup.string().required("City is required"),
  }),
  bill: Yup.string().when("type", {
    is: "person",
    then: Yup.string().required("Bill is required"),
  }),
  companyNumber: Yup.string().when("type", {
    is: "company",
    then: Yup.string()
      .matches("^[0-9]*$", "Company Number must be a number")
      .required("Company Number is required"),
  }),
  certificate: Yup.string().when("type", {
    is: "company",
    then: Yup.string().required("Certificate is required"),
  }),
  memorandum: Yup.string().when("type", {
    is: "company",
    then: Yup.string().required("Memorandum is required"),
  }),
  taxID: Yup.string().when("type", {
    is: "company",
    then: Yup.string().required("Tax ID is required"),
  }),
  phoneNumber: Yup.string().required("Phone number is required"),
  phoneCode: Yup.string().required("Code from SMS is required"),
  passPhrase: Yup.string().required("Pass phrase is required"),
});

const getBG = step => {
  switch (step) {
    case 5:
      return registerBGFive;
    case 4:
      return registerBGFour;
    case 3:
      return registerBGThree;
    case 2:
      return registerBGTwo;
    default:
      return registerBGOne;
  }
};

const RegisterPage = ({intl, isAuthLoading, isUserAuth, signUp}) => {
  const [step, setStep] = useState(1);
  if (!isAuthLoading && isUserAuth) {
    return <SaveRedirect to="/" />;
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={RegisterPageSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={values => {
        initialValues = {...initialValues, ...values};
        initialValues.copyPhrase = false;

        signUp({
          type: values.type,
          specialization: values.specialization,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          emailConfirmationCode: +values.emailConfirmationCode,
          password: values.password,
          companyName: values.companyName,
          confirmPassword: values.confirmPassword,
          country: values.country,
          zip: values.zip,
          street: values.street,
          apartment: values.apartment,
          region: values.region,
          city: values.city,
          phone: `${values.dialCode}${values.phoneNumber}`,
          bill: values.bill,
          billFile: values.billFile,
          passPhrase: values.passPhrase,

          searchAllowed: true,
          termsApproved: true,
          cryptoCurrency: "btc",
          regularCurrency: "usd",
        });
      }}
    >
      {({errors, touched, values, setFieldValue, validateForm, setErrors}) => (
        <div className="RegisterPageWrapper">
          <a href="https://onwallet.com/">
            <img src={onwalletLogo} alt="onwallet-logo" className="onwallet-logo" />
          </a>
          <img className="background" src={getBG(step)} alt="bg" />
          <div className="RegisterContainer">
            <img src={onwalletIconShort} className="upper-logo" alt="logo" />
            <div className="registerHeader">
              {step < 5 && (
                <Link to={routes.login} className="closeButton">
                  <CloseIcon />
                </Link>
              )}
              <div className="title">
                {step < 5 ? (
                  <FormattedMessage {...messages.onWalletRegistration} />
                ) : (
                  <FormattedMessage {...messages.onWalletRegistrationFinished} />
                )}
              </div>
            </div>
            {step < 5 && <StepLine step={step} setStep={setStep} />}
            <Scrollbars
              renderTrackVertical={props => (
                <div {...props} className="track-vertical " />
              )}
              renderThumbVertical={props => (
                <div {...props} className="thumb-vertical " />
              )}
            >
              <Form className={"RegisterForm"}>
                <FirstRegisterStep
                  type={values.type}
                  setFieldValue={setFieldValue}
                  setStep={setStep}
                  step={step}
                  errors={errors}
                  values={values}
                  validateForm={validateForm}
                  setErrors={setErrors}
                />
                <SecondRegisterStep
                  type={values.type}
                  setFieldValue={setFieldValue}
                  setStep={setStep}
                  step={step}
                  touched={touched}
                  errors={errors}
                  values={values}
                  validateForm={validateForm}
                  setErrors={setErrors}
                />
                <ThirdRegisterStep
                  setFieldValue={setFieldValue}
                  setStep={setStep}
                  step={step}
                  errors={errors}
                  values={values}
                  validateForm={validateForm}
                  setErrors={setErrors}
                />
                <FourthRegisterStep
                  setFieldValue={setFieldValue}
                  setStep={setStep}
                  step={step}
                  errors={errors}
                  values={values}
                  validateForm={validateForm}
                  setErrors={setErrors}
                />
                <FifthRegisterStep
                  step={step}
                  setFieldValue={setFieldValue}
                  values={values}
                />
              </Form>
            </Scrollbars>
          </div>
        </div>
      )}
    </Formik>
  );
};

const StepLine = ({step, setStep}) => {
  return (
    <div className="stepLineContainer">
      <div className="stepLine" />
      {step > 0 && (
        <div className="stepButton" onClick={() => setStep(1)}>
          <div className={`circle ${step === 1 ? "active" : ""}`}>1</div>
        </div>
      )}
      {step > 1 && (
        <div className="stepButton" onClick={() => setStep(2)}>
          <div className={`circle ${step === 2 ? "active" : ""}`}>2</div>
        </div>
      )}
      {step > 2 && (
        <div className="stepButton" onClick={() => setStep(3)}>
          <div className={`circle ${step === 3 ? "active" : ""}`}>3</div>
        </div>
      )}
      {step > 3 && (
        <div className="stepButton" onClick={() => setStep(4)}>
          <div className={`circle ${step === 4 ? "active" : ""}`}>4</div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = store => ({
  isAuthLoading: isAuthLoading(store),
  isUserAuth: isUserAuth(store),
});
const mapDispatchToProps = {
  signUp,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RegisterPage));
