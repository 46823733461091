import React from "react";
import "./index.scss";

const Input = ({
  options,
  className,
  error,
  disabled,
  leftIcon,
  rightIcon,
  iconStyles,
  children,
  ...props
}) => {
  return (
    <>
      <div
        className={`CustomInputWrapper ${leftIcon ? "withLeftIcon" : ""} ${
          rightIcon ? "withRightIcon" : ""
        }`}
      >
        {leftIcon && (
          <img src={leftIcon} className={`leftIcon ${iconStyles}`} alt="icon" />
        )}
        <input
          type="text"
          className={`input ${error ? "errorInput" : ""} ${disabled ? "disabled" : ""} ${
            className ? className : ""
          }`}
          disabled={disabled}
          {...props}
        />
        {rightIcon && (
          <img src={rightIcon} className={`rightIcon ${iconStyles}`} alt="icon" />
        )}
      </div>
      {error && <div className="inputErrorText">{error}</div>}
    </>
  );
};

export default Input;
