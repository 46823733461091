import React from "react";
import {connect} from "react-redux";
import {shoppingVoucherModal} from "shared/shoppingService/actions/index";
import {daysLeftDay} from "shared/tools";
import "./index.scss";
import config from "shared/config";

const brandIcon = config.prefixImgUrl + "brand-image.svg";

const VoucherItem = ({item, shoppingModal}) => {
  return (
    <>
      <div
        className="voucher-container"
        onClick={() => {
          shoppingModal(item);
        }}
      >
        <div className="voucher-item">
          <img
            alt=""
            className="voucher-brand-logo"
            style={{
              background: item.expired
                ? "rgba(0, 43, 64, 0.05) 0% 0% no-repeat padding-box"
                : "",
            }}
            src={brandIcon}
          ></img>
          <div
            className="voucher-name"
            style={{
              background: item.expired
                ? "rgba(0, 43, 64, 0.05) 0% 0% no-repeat padding-box"
                : "",
            }}
          >
            {item.title}
          </div>
          <div className="voucher-due-date">
            {"till " + daysLeftDay(item.expirationDate)}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = store => ({});

function mapDispatchToProps(dispatch) {
  return {
    shoppingModal: data => {
      return dispatch(shoppingVoucherModal(data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VoucherItem);
