import React, {useEffect} from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import {addNewRegularCard} from "shared/cardService/actions";
import {
  getAllRegularCurrency,
  isRegularCurrenciesFetched,
} from "shared/cardService/selectors";
import {fetchAllRegularCurrency} from "shared/cardService/actions";
import "./index.scss";
import CardInput from "shared/components/CardInput";
import FormSectionSeparator from "shared/components/FormSectionSeparator";

import messages from "../../messages";
import Select from "shared/components/Select";
import Button from "shared/components/Button";

const initialValues = {
  expiry: "",
  number: "",
  holder: "",
  cvc: "",
  currency: "USD",
};

const AddNewCardSchema = Yup.object().shape({
  number: Yup.string()
    .min(18, "Card Number is too short")
    .max(23, "Card Number is too long")
    .required("Card Number is required"),
  expiry: Yup.string()
    .required("Expiry Date is required")
    .matches(
      "^(0[1-9]|1[0-2]|[1-9])\\/(1[4-9]|[2-9][0-9]|20[1-9][1-9])$",
      "Expiry Date must be in MM/YY format"
    ),
  cvc: Yup.string()
    .length(3, "CVV must contain 3 digit")
    .required("CVV is required"),
});

const AddNewCard = ({
  addNewRegularCard,
  allCurrency,
  fetchAllRegularCurrency,
  isRegularCurrenciesFetched,
}) => {
  useEffect(() => {
    if (allCurrency.length === 0 && !isRegularCurrenciesFetched) {
      fetchAllRegularCurrency();
    }
  }, [allCurrency, fetchAllRegularCurrency, isRegularCurrenciesFetched]);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddNewCardSchema}
      onSubmit={(values, actions) => {
        const {expiry, number, cvc, holder, currency} = values;
        const expiryDate = expiry.split("/");
        const expMonth = Number.parseInt(expiryDate[0]);
        const expYear = Number.parseInt(expiryDate[1]);

        addNewRegularCard({
          cvc,
          number: number.replace(/ /g, ""),
          expMonth,
          expYear,
          holder,
          currency: currency.toLowerCase(),
        });
      }}
    >
      {form => (
        <Form className="AddCardForm">
          <FormSectionSeparator sectionName={messages.cardDetails} />
          <CardInput form={form} />
          <Field
            name="currency"
            render={({field}) => (
              <div className="currencyInputContainer">
                <label className="label">
                  <FormattedMessage {...messages.cardCurrency} />
                </label>
                <Select
                  className="select"
                  {...field}
                  options={allCurrency.map(currency => currency.currency.toUpperCase())}
                />
              </div>
            )}
          />
          <div className="text">
            <FormattedMessage {...messages.weWillCharge} />
          </div>
          <Button text={messages.addCard} />
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = store => ({
  allCurrency: getAllRegularCurrency(store),
  isRegularCurrenciesFetched: isRegularCurrenciesFetched(store),
});

const mapDispatchToProps = {
  addNewRegularCard,
  fetchAllRegularCurrency,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewCard);
