/**
 * @author: Seleznyov
 */

import React from "react";
import {FormattedMessage} from "react-intl";
import Header from "shared/components/Header";

import messages from "./messages";
import "./index.scss";

import config from "shared/config";
import FilterTools from "./components/FilterTools";
import ActionsLogList from "./components/ActionsLogList";
const logoPage = config.prefixImgUrl + "actions-log-icon-toptitle.svg";

const ActionsLogPage = () => {
  return (
    <>
      <Header
        headerTitle={<FormattedMessage {...messages.actionsLogPageTitle} />}
        navLinks={[]}
        logoImage={logoPage}
      />

      <div className="actions-log-page">
        <FilterTools />
        <ActionsLogList />
      </div>
    </>
  );
};

export default ActionsLogPage;
