import React from "react";
import {connect} from "react-redux";

import Checkbox from "shared/components/Checkbox";

import {getCurrentOrdersItems} from "shared/ordersService/selectors";

import "./index.scss";
import {routes} from "shared/appUrls";
import messages from "./messages";

import Header from "shared/components/Header";
import {FormattedMessage} from "react-intl";

import OrdersTable from "./OrderTable";
import {ReactComponent as OrderHistoryIcon} from "images/order-history.svg";

import config from "shared/config";
const LeftArrowIcon = config.prefixImgUrl + "left-arrow-icon.svg";

const OrdersList = ({currentItems}) => {
  const [selected, setSelected] = React.useState([]);
  const [items, setItems] = React.useState(currentItems);
  const isSelected = name => selected.indexOf(name) !== -1;

  const navLinks = [
    {
      text: <FormattedMessage {...messages.orderHistory} />,
      icon: className => <OrderHistoryIcon className={className} />,
      link: routes.orderHistory,
    },
  ];

  const handleSelectAll = event => {
    if (event.target.checked) {
      const newSelecteds = items.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected([...newSelected]);
  };

  const mapTableRows = rows => {
    return rows.map((row, index) => {
      const isItemSelected = isSelected(row.id);
      return {
        cols: [
          <Checkbox
            style={{"max-width": "20px"}}
            value={isItemSelected}
            onChange={event => {
              handleClick(event, row.id);
            }}
          />,
          <div style={{"min-width": "130px"}}>
            {row.Date && (
              <>
                <span className="source">
                  <span className="dots" />
                  {row.Date ? row.Date : ""}
                </span>
              </>
            )}
          </div>,
          <div>
            {row.Pair && (
              <>
                <span className="source">
                  <span className="dots" />
                  {row.Pair ? row.Pair : ""}
                </span>
              </>
            )}
          </div>,
          <div style={{"font-weight": "500"}}>
            {row.Side === "0" ? (
              <>
                <span className="source">
                  <span className="dots" />
                  <div style={{color: "#FF3333"}}>{"Sell"}</div>
                </span>
              </>
            ) : (
              <>
                <span className="source">
                  <span className="dots" />
                  <div style={{color: "#2FA100"}}>{"Buy"}</div>
                </span>
              </>
            )}
          </div>,
          <div style={{"min-width": "130px"}}>
            {row.Price && (
              <>
                <span className="source">
                  <span className="dots" />
                  {row.Price ? row.Price : ""}
                </span>
              </>
            )}
          </div>,
          <div style={{"font-weight": "500", "min-width": "130px"}}>
            {row.Amount && (
              <>
                <span className="source">
                  <span className="dots" />
                  {row.Amount ? row.Amount : ""}
                </span>
              </>
            )}
          </div>,
          <div>
            {row.Filled && (
              <>
                <span className="source">
                  <span className="dots" />
                  {row.Filled ? `${row.Filled}%` : ""}
                </span>
              </>
            )}
          </div>,
          <div style={{"min-width": "130px"}}>
            {row.Total && (
              <>
                <span className="source">
                  <span className="dots" />
                  {row.Total ? row.Total : ""}
                </span>
              </>
            )}
          </div>,
          <div className="Action">
            {
              <>
                <span className="source">
                  <span className="dots" />
                  {<FormattedMessage {...messages.tableCellActions} />}
                </span>
              </>
            }
          </div>,
        ],
      };
    });
  };

  const transactionsTableHeader = [
    <Checkbox
      style={{"max-width": "20px"}}
      value={selected.length > 0 && selected.length === items.length}
      onChange={event => {
        handleSelectAll(event);
      }}
    />,
    <FormattedMessage {...messages.tableHeaderDate} />,
    <FormattedMessage {...messages.tableHeaderPair} />,
    <FormattedMessage {...messages.tableHeaderSide} />,
    <FormattedMessage {...messages.tableHeaderPrice} />,
    <FormattedMessage {...messages.tableHeaderAmount} />,
    <FormattedMessage {...messages.tableHeaderFilled} />,
    <FormattedMessage {...messages.tableHeaderTotal} />,
    <FormattedMessage {...messages.tableHeaderActions} />,
  ];
  return (
    <>
      <Header
        headerTitle={<FormattedMessage {...messages.orderList} />}
        navLinks={navLinks}
        logoImage={LeftArrowIcon}
        link={routes.trading}
      />
      <div className="main-content-orderslist">
        <div className="Options">
          <div className="OpenedOrders">
            <FormattedMessage {...messages.openedOrder} />
          </div>
          <div
            className="CancelSelected"
            onClick={() => {
              const newList = items.filter((item, key) => !isSelected(item.id));
              setItems(newList);
              setSelected([]);
            }}
          >
            <FormattedMessage {...messages.cancelSelected} />
          </div>
        </div>
        <div>
          <OrdersTable headers={transactionsTableHeader} rows={mapTableRows(items)} />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = store => ({
  currentItems: getCurrentOrdersItems(store),
});

const dispatchToProps = {
  execute: "ORDERS_LIST_EXECUTE_SUCCESS",
};
export default connect(mapStateToProps, dispatchToProps)(OrdersList);
