import React from "react";
import {FormattedMessage} from "react-intl";
// Components
import TransactionMonitorTableItem from "./item";
import CustomPaginator from "shared/components/CustomPaginator";
// Utils
import messages from "../../messages";
// Styles
import "./index.scss";

const TransactionMonitorTable = ({items, page, setPage}) => {
  return (
    <>
      <div className="transaction-monitor-table">
        <div className="transaction-monitor-table__header">
          <div className="transaction-monitor-table__header__item">
            <FormattedMessage {...messages.dateAndTime} />
          </div>
          <div className="transaction-monitor-table__header__item">
            <FormattedMessage {...messages.source} />
          </div>
          <div className="transaction-monitor-table__header__item">
            <FormattedMessage {...messages.recipient} />
          </div>
          <div className="transaction-monitor-table__header__item">
            <FormattedMessage {...messages.amount} />
          </div>
          <div className="transaction-monitor-table__header__item">
            <FormattedMessage {...messages.currency} />
          </div>
          <div className="transaction-monitor-table__header__item">
            <FormattedMessage {...messages.status} />
          </div>
          <div className="transaction-monitor-table__header__item"></div>
        </div>
        <div className="transaction-monitor-table__body">
          {items.map((item, index) => (
            <TransactionMonitorTableItem item={item} key={index} />
          ))}
        </div>

        {/* Paginator */}
        <CustomPaginator count={300} getPage={setPage} />
      </div>
    </>
  );
};

export default TransactionMonitorTable;
