import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

import VoucherItem from "./VoucherItem";
import {shoppingVoucherModal} from "shared/shoppingService/actions/index";

import "./index.scss";

const ShoppingVouchers = ({formTabs, personalVouchers, shoppingModal}) => {
  const active = personalVouchers.filter(item => item.status === 1);
  const expired = personalVouchers.filter(item => item.status !== 1);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (isLoading && personalVouchers.length >= 1) {
      shoppingModal(active[0] || personalVouchers[0]);
      setLoading(false);
    }
  }, [active, isLoading, personalVouchers, shoppingModal]);

  return (
    <>
      {formTabs && formTabs()}
      <div className="vouchers-layout">
        {active.map((item, index) => (
          <VoucherItem item={item} key={index} />
        ))}
        {!!expired.length && (
          <div className="vouchers-separator-wrapper">
            <div className="vouchers-separator-text">{"Used & Expired"}</div>
            <div className="vouchers-separator"></div>
          </div>
        )}
        {expired.map((item, index) => (
          <VoucherItem item={item} key={index} />
        ))}
      </div>
    </>
  );
};

const mapStateToProps = store => ({});

function mapDispatchToProps(dispatch) {
  return {
    shoppingModal: data => {
      return dispatch(shoppingVoucherModal(data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingVouchers);
