import React from "react";
import {connect} from "react-redux";
import RightModal from "shared/components/RightModal";
import {closeContactsModal} from "./actions";
import ContactAddModal from "./ContactAddModal";
import ContactEditModal from "./ContactEditModal";
import ShareContactModal from "./ShareContactModal";
import SendMyContact from "./SendMyContactModal";
import {addContact, deleteContact, updateContact} from "shared/contacts/actions";

const modalVariants = {
  addContact: "addContact",
  sendMyContact: "sendMyContact",
  editContact: "editContact",
  shareContact: "shareContact",
};

const ContactsDetailsModal = ({
  show,
  title,
  sub,
  name,
  data,
  image,
  closeContactsModal,
  className,
}) => {
  return (
    <div className={`RightModalWrapper ${show ? "show" : "hide"}`}>
      {title && (
        <RightModal
          title={title ? title : null}
          subTitle={sub ? sub : null}
          show={show}
          image={image}
          close={closeContactsModal}
          className={className ? className : ""}
        >
          {name === modalVariants.addContact && (
            <ContactAddModal close={closeContactsModal} addContact={addContact} />
          )}
          {name === modalVariants.editContact && (
            <ContactEditModal
              close={closeContactsModal}
              updateContact={updateContact}
              deleteContact={deleteContact}
              data={data}
            />
          )}
          {name === modalVariants.shareContact && (
            <ShareContactModal data={data} close={closeContactsModal} />
          )}
          {name === modalVariants.sendMyContact && (
            <SendMyContact close={closeContactsModal} />
          )}
        </RightModal>
      )}
    </div>
  );
};

const mapStateToProps = store => ({
  name: store.contactsModalData.name,
  show: store.contactsModalData.show,
  sub: store.contactsModalData.sub,
  title: store.contactsModalData.title,
  data: store.contactsModalData.data,
  image: store.contactsModalData.image,
  className: store.contactsModalData.className,
  search: store.contactsModalData.search,
});

const mapDispatchToProps = {
  closeContactsModal,
  addContact,
  deleteContact,
  updateContact,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactsDetailsModal);
