import React, {useEffect} from "react";
import PropTypes from "prop-types";
import "./index.scss";
import {Route, Switch, Router} from "react-router-dom";
import {connect} from "react-redux";
import {routes, routesACL} from "shared/appUrls";
import {populateToken, getUserInfo, getUserProfile} from "shared/auth/actions";
import {fetchAllRegularCurrency} from "shared/cardService/actions";
import {
  fetchAllCryptoCurrency,
  fetchCryptoWallets,
  fetchThirdWallets,
} from "shared/walletService/actions";
import {fetchBankAccounts, fetchBankCards} from "shared/cardService/actions";
import {fetchCurrenciesRates} from "shared/ratesService/actions";
import {fetchAllAvailableWallets} from "shared/walletService/actions";
import {isAuthLoading, isUserAuth, userProfileRoles} from "shared/auth/selectors";

import ErrorModal from "shared/components/ErrorModal";
import Layout from "./components/Layout";
import WalletsPage from "components/WalletsPage";
import LoginPage from "components/LoginPage";
import CustomersPage from "components/CustomersPage";
import LandingPage from "components/LandingPage";
import RegisterPage from "../RegisterPage";
import Documents from "components/Documents";
import PageWithDocument from "components/Documents/components/PageWithDocument";
import CartPage from "components/CartPage";
import WebsocketTestPage from "components/WebsocketTestPage";
import Dashboard from "components/Dashboard";
import QRCodeModal from "shared/components/QRCodeModal";
import ContactModal from "components/ContactsPage/components/ContactsDetailsModal";

import OrdersList from "components/OrdersList";
import OrdersHistory from "components/OrdersHistory";
import DashboardAdmin from "components/DashboardAdmin";
import Trading from "components/Trading";
import Messages from "components/Messages";
import TextPage from "components/TextPage";
import ContactsPage from "components/ContactsPage";
import RecoveryPage from "../RecoveryPage";
import WalletsModals from "../WalletsPage/components/WalletsModals";
import PersonalDetails from "../PersonalDetailsPage";
import NotFoundPage from "../NotFoundPage";
import {Redirect} from "react-router";
import LoadingPage from "../LoadingPage";
import AccountSettings from "components/AccountSettings";
import ActionsHistory from "../ActionsHistoryPage";
import Shopping from "components/Shopping";
import ScrollToTop from "../../shared/components/ScrollToTop";
import CoinRegistryPage from "components/CoinRegistryPage";
import OrdersPage from "components/OrdersPage";
import MetalSupplyPage from "components/MetalSupplyPage";
import StaffPage from "../StaffPage";
import RolesPage from "../RolesPage";
import ActionsApprovalPage from "../ActionsApprovalPage";
import ApprovalHistory from "../ActionsApprovalPage/components/ApprovalHistory";
import ActionsLogPage from "../ActionsLogPage";
import TransactionMonitorPage from "components/TransactionMonitorPage";
import LastTransactionPage from "components/TransactionMonitorPage/components/LastTransactionPage";
// import SidebarDetailsModals from "components/App/components/Sidebar/components/SidebarDetailsModals"

const App = ({
  history,
  populateToken,
  getUserInfo,
  isUserAuth,
  isAuthLoading,
  fetchAllRegularCurrency,
  fetchAllAvailableWallets,
  getUserProfile,
  fetchCryptoWallets,
  fetchBankCards,
  fetchBankAccounts,
  fetchThirdWallets,
  fetchCurrenciesRates,
  fetchAllCryptoCurrency,
  profileRoles,
}) => {
  useEffect(() => {
    populateToken();
    getUserInfo();
    fetchAllRegularCurrency();
    fetchAllAvailableWallets();
    getUserProfile();
  }, [
    populateToken,
    getUserInfo,
    fetchAllRegularCurrency,
    fetchAllAvailableWallets,
    getUserProfile,
  ]);
  useEffect(() => {
    if (!isUserAuth) return;
    fetchCryptoWallets();
    fetchBankCards();
    fetchBankAccounts();
    fetchThirdWallets();
    fetchCurrenciesRates();
    fetchAllCryptoCurrency();
  }, [
    isUserAuth,
    fetchCryptoWallets,
    fetchBankCards,
    fetchBankAccounts,
    fetchThirdWallets,
    fetchCurrenciesRates,
    fetchAllCryptoCurrency,
  ]);

  return (
    <Router history={history}>
      <ErrorModal />
      <QRCodeModal />
      <ContactModal />
      <WalletsModals />
      {/* <SidebarDetailsModals />       */}

      {isAuthLoading && <LoadingPage />}
      {!isAuthLoading && (
        <>
          <ScrollToTop />
          <Switch handler={App}>
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route exact path={routes.landing} component={LandingPage} />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route exact path={routes.login} component={LoginPage} />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route exact path={routes.register} component={RegisterPage} />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route exact path={routes.recovery} component={RecoveryPage} />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route
              exact
              path={routes.legalDocs}
              render={() => (
                <Layout>
                  <Documents />
                </Layout>
              )}
            />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route
              exact
              path={routes.legalDocsWithId}
              render={() => (
                <Layout>
                  <PageWithDocument />
                </Layout>
              )}
            />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route
              exact
              path={routes.wallets}
              render={() =>
                checkRole(profileRoles, routesACL.wallets) ? (
                  <Layout>
                    <WalletsPage />
                  </Layout>
                ) : (
                  <Layout>
                    <PersonalDetails />
                  </Layout>
                )
              }
            />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route
              exact
              path="/"
              render={() =>
                checkRole(profileRoles, routesACL.dashboard) ? (
                  <Layout>
                    <Dashboard />
                  </Layout>
                ) : (
                  <Layout>
                    <PersonalDetails />
                  </Layout>
                )
              }
            />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route
              exact
              path={routes.transactionMonitor}
              render={() =>
                checkRole(profileRoles, routesACL.transactionMonitor) ? (
                  <Layout>
                    <TransactionMonitorPage />
                  </Layout>
                ) : (
                  <Layout>
                    <PersonalDetails />
                  </Layout>
                )
              }
            />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route
              exact
              path={routes.lastTransactions}
              render={() =>
                checkRole(profileRoles, routesACL.lastTransactions) ? (
                  <Layout>
                    <LastTransactionPage />
                  </Layout>
                ) : (
                  <Layout>
                    <PersonalDetails />
                  </Layout>
                )
              }
            />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route exact path={routes.cart} render={withLayout(CartPage)} />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route exact path={routes.websocket} render={withLayout(WebsocketTestPage)} />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route exact path={routes.dashboard} render={withLayout(Dashboard)} />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route exact path={routes.orderlist} render={withLayout(OrdersList)} />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route exact path={routes.orderHistory} render={withLayout(OrdersHistory)} />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route
              exact
              path={routes.dashboardAdmin}
              render={() =>
                checkRole(routesACL.dashboardAdmin) ? (
                  <Layout>
                    <DashboardAdmin />
                  </Layout>
                ) : (
                  <Layout>
                    <PersonalDetails />
                  </Layout>
                )
              }
            />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route
              exact
              path={routes.trading}
              render={() =>
                checkRole(profileRoles, routesACL.trading) ? (
                  <Layout>
                    <Trading />
                  </Layout>
                ) : (
                  <Layout>
                    <PersonalDetails />
                  </Layout>
                )
              }
            />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route
              exact
              path={routes.actionsHistory}
              render={() =>
                checkRole(profileRoles, routesACL.actionsHistory) ? (
                  <Layout>
                    <ActionsHistory />
                  </Layout>
                ) : (
                  <Layout>
                    <PersonalDetails />
                  </Layout>
                )
              }
            />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route exact path={routes.customers} render={withLayout(CustomersPage)} />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route exact path={routes.messages} render={withLayout(Messages)} />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route exact path={routes.contacts} render={withLayout(ContactsPage)} />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route exact path={routes.textpage} render={withLayout(TextPage)} />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route exact path={routes.details} render={withLayout(PersonalDetails)} />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route
              exact
              path={routes.accountSettings}
              render={withLayout(AccountSettings)}
            />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route path={routes.notfound} component={NotFoundPage} />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route
              exact
              path={routes.shopping}
              render={() =>
                checkRole(profileRoles, routesACL.shopping) ? (
                  <Layout>
                    <Shopping />
                  </Layout>
                ) : (
                  <Layout>
                    <PersonalDetails />
                  </Layout>
                )
              }
            />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route
              exact
              path={routes.orders}
              render={() =>
                checkRole(profileRoles, routesACL.orders) ? (
                  <Layout>
                    <OrdersPage />
                  </Layout>
                ) : (
                  <Layout>
                    <PersonalDetails />
                  </Layout>
                )
              }
            />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route
              exact
              path={routes.coinRegistry}
              render={() =>
                checkRole(profileRoles, routesACL.coinRegistry) ? (
                  <Layout>
                    <CoinRegistryPage />
                  </Layout>
                ) : (
                  <Layout>
                    <PersonalDetails />
                  </Layout>
                )
              }
            />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route
              exact
              path={routes.metalSupply}
              render={() =>
                checkRole(profileRoles, routesACL.metalSupply) ? (
                  <Layout>
                    <MetalSupplyPage />
                  </Layout>
                ) : (
                  <Layout>
                    <PersonalDetails />
                  </Layout>
                )
              }
            />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route
              exact
              path={routes.staff}
              render={() =>
                checkRole(profileRoles, routesACL.staff) ? (
                  <Layout>
                    <StaffPage />
                  </Layout>
                ) : (
                  <Layout>
                    <PersonalDetails />
                  </Layout>
                )
              }
            />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route
              exact
              path={routes.roles}
              render={() =>
                checkRole(profileRoles, routesACL.roles) ? (
                  <Layout>
                    <RolesPage />
                  </Layout>
                ) : (
                  <Layout>
                    <PersonalDetails />
                  </Layout>
                )
              }
            />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route
              exact
              path={routes.actionsApproval}
              render={() =>
                checkRole(profileRoles, routesACL.actionsApproval) ? (
                  <Layout>
                    <ActionsApprovalPage />
                  </Layout>
                ) : (
                  <Layout>
                    <PersonalDetails />
                  </Layout>
                )
              }
            />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route
              exact
              path={routes.approvalHistory}
              render={() =>
                checkRole(profileRoles, routesACL.approvalHistory) ? (
                  <Layout>
                    <ApprovalHistory />
                  </Layout>
                ) : (
                  <Layout>
                    <PersonalDetails />
                  </Layout>
                )
              }
            />
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Route
              exact
              path={routes.actionsLog}
              render={() =>
                checkRole(profileRoles, routesACL.actionsLog) ? (
                  <Layout>
                    <ActionsLogPage />
                  </Layout>
                ) : (
                  <Layout>
                    <PersonalDetails />
                  </Layout>
                )
              }
            />
            {/* ------------------------------------------------------------------------------------------------------- */}
            {/* ------------------------------------------------------------------------------------------------------- */}
            <Redirect from="*" to={routes.notfound} />
          </Switch>
        </>
      )}
    </Router>
  );
};

const withLayout = Component => {
  return routerProps => (
    <Layout>
      <Component {...routerProps} />
    </Layout>
  );
};

export const checkRole = (profileRoles, roles) => {
  if (!!roles && !!roles.length && !!profileRoles && !!profileRoles.length) {
    let counter = 0;

    roles.forEach(role => {
      if (profileRoles.includes(role)) counter++;
    });

    return !!counter;
  }

  return true;
};

App.propTypes = {
  history: PropTypes.object,
  isAuthLoading: PropTypes.bool,
};

// Func which map State to props
const mapStateToProps = state => ({
  history: state.historyData.history,
  isAuthLoading: isAuthLoading(state),
  isUserAuth: isUserAuth(state),
  profileRoles: userProfileRoles(state),
});

// Func which map actionCreators to props
const mapDispatchToProps = {
  populateToken,
  getUserInfo,
  fetchAllRegularCurrency,
  fetchAllAvailableWallets,
  getUserProfile,
  fetchCryptoWallets,
  fetchBankCards,
  fetchBankAccounts,
  fetchThirdWallets,
  fetchCurrenciesRates,
  fetchAllCryptoCurrency,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
