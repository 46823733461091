import {
  CART_EXECUTE_PENDING,
  CART_EXECUTE_SUCCESS,
  CART_ADD_ITEM,
  CART_FAILED_ITEM,
  CART_CONFIRM_START,
  CART_SUCCESS_ITEM,
  CART_CLEAR,
  CART_REMOVE_ITEM,
  CART_EXECUTE_AGAIN,
  CART_REMOVE_FAILED_ITEM,
} from "./actions/types";

const itemStatuses = {
  pending: "pending",
  failed: "failed",
  confirmed: "confirmed",
};

const initState = {
  isExecuting: false,
  hasExecuted: false,
  isLoading: false,
  currentItems: [],
  failedItems: [],
  processItems: [],
};

const mapProcessItems = items => {
  return items.map(item => {
    item.status = itemStatuses.pending;
    return item;
  });
};

const changeStatus = (items, successItemId, status) => {
  return items.map(item => {
    if (item.id === successItemId) {
      item.status = status;
    }
    return item;
  });
};

const addFailedItem = (failedList, processList, failedItemId) => {
  const item = processList.filter(item => item.id === failedItemId)[0];
  if (!item) return [...failedList];
  item.status = itemStatuses.failed;
  return [...failedList, item];
};

const cartData = (state = initState, action) => {
  switch (action.type) {
    case CART_EXECUTE_PENDING:
      return {...state, isExecuting: true, hasExecuted: false};
    case CART_EXECUTE_SUCCESS:
      return {...state, hasExecuted: true, isExecuting: false};
    case CART_CONFIRM_START:
      return {
        ...state,
        currentItems: [],
        isLoading: true,
        processItems: mapProcessItems(state.currentItems),
      };
    case CART_CLEAR:
      return {
        ...state,
        failedItems: [],
        currentItems: [],
        processItems: [],
        isExecuting: false,
        hasExecuted: false,
        isLoading: false,
      };
    case CART_SUCCESS_ITEM:
      return {
        ...state,
        isLoading: false,
        processItems: changeStatus(
          state.processItems,
          action.payload,
          itemStatuses.confirmed
        ),
      };
    case CART_ADD_ITEM:
      return {
        ...state,
        currentItems: [...state.currentItems, action.payload],
      };
    case CART_REMOVE_ITEM:
      return {
        ...state,
        currentItems: state.currentItems.filter(item => item.id !== action.payload),
      };
    case CART_REMOVE_FAILED_ITEM:
      return {
        ...state,
        failedItems: state.failedItems.filter(item => item.id !== action.payload),
      };
    case CART_FAILED_ITEM:
      return {
        ...state,
        isLoading: false,
        failedItems: addFailedItem(state.failedItems, state.processItems, action.payload),
        processItems: state.processItems.filter(item => item.id !== action.payload),
      };
    case CART_EXECUTE_AGAIN:
      return {...state, currentItems: state.failedItems, failedItems: []};
    default:
      return state;
  }
};

export {cartData};
