import React from "react";
import {connect} from "react-redux";
import Button from "shared/components/Button";
import MiniChart from "../MiniChart";
import perDay from "../Chart/mockData/1day.js";
import {openRightModal} from "shared/components/RightModal/actions";
import "./index.scss";

import messages from "./../../messages";

import {getWalletIconByName} from "shared/walletService/selectors";
import {getAmountInOtherCoin} from "shared/walletService/selectors";
import {getCurrencyRates} from "shared/ratesService/selectors";

const MarketCap = ({marketCap}) => {
  return (
    <div className="deho-info-bar__market-cap">
      <div>Deho Gold (DHG)</div>
      <div>Market Cap: {marketCap}</div>
    </div>
  );
};

const Price = ({price}) => {
  return (
    <div className="deho-info-bar__price">
      <div>{`$ ${price}`}</div>
      <div>Price</div>
    </div>
  );
};

const Change = ({change}) => {
  return (
    <div className="deho-info-bar__change">
      <div>{change}</div>
      <div>Change (24h)</div>
    </div>
  );
};

const Chart = ({chartData}) => {
  return (
    <div className="deho-info-bar__chart">
      <MiniChart strokeColor="#FFB300" chartData={chartData} />
    </div>
  );
};

const DehoInfoBar = ({openRightModal, currencyRates}) => {
  return (
    <div className="deho-info-bar">
      <img
        src={getWalletIconByName("Deho Gold")}
        alt="DEHO GOLD"
        className="deho-info-bar__deho-icon"
      />
      <div className="container-space-between">
        <MarketCap marketCap={"$10M"} />
        <Price price={`${getAmountInOtherCoin("dho", "usd", 1, currencyRates)}`} />
        <Change change={"2.35%"} />
      </div>
      <Chart chartData={perDay} />
      <Button
        type="button"
        className="deho-info-bar__button-buy"
        text={messages.dehoBuy}
        onClick={() => openRightModal("exchangeDeho", "Deho Gold")}
      />
    </div>
  );
};

const mapStateToProps = store => ({
  currencyRates: getCurrencyRates(store),
});
const dispatchToProps = {
  openRightModal,
};

export default connect(mapStateToProps, dispatchToProps)(DehoInfoBar);
