import React, {Fragment, useState} from "react";
import {connect} from "react-redux";
import {Formik, Form, Field} from "formik";
// Components
import Input from "shared/components/Input";
import Button from "shared/components/Button";
import CustomSelect from "shared/components/Select";
// Actions
import {closeRightModal} from "shared/components/RightModal/actions";
// Selectors
import {dataRightModal} from "shared/components/RightModal/selectors";
// Utils
import {FormattedMessage} from "react-intl";
import messages from "../../messages";
import config from "shared/config";
// Styles
import "./index.scss";

// Images
const avatarDefault = config.prefixImgUrl + "avatar-default-light.svg";
const imageIcon = config.prefixImgUrl + "image-icon.svg";

const StaffNewAndEditMemberModal = ({staffMember, closeRightModal}) => {
  const [editFlag] = useState(!!staffMember);
  const [avatar, setAvatar] = useState(
    editFlag ? staffMember.picture.large : avatarDefault
  );
  const listRoles = ["Manager Lvl 1", "Manager Lvl 2"];

  const initFormik = {
    photo: editFlag ? staffMember.picture.large : "",
    firstName: editFlag ? staffMember.name.first : "",
    lastName: editFlag ? staffMember.name.last : "",
    role: editFlag ? listRoles[0] : "",
  };

  const handlerUploadImage = ({files}) => {
    if (files && files.length > 0) {
      const avatar = window.URL.createObjectURL(files[0]);
      // TODO: Implement request
      setAvatar(avatar);
    }
  };

  return (
    <Fragment>
      <Formik initialValues={initFormik} onSubmit={values => console.log(values)}>
        {({values, setFieldValue}) => (
          <Form className="staff-new-member">
            <div className="staff-new-member__upload-img staff-new-member__item">
              <img src={avatar} alt="avatar" className=" staff-new-member__item-avatar" />
              <div className="changeAvatar">
                <img src={imageIcon} alt="change" />
                <input
                  type="file"
                  className="avatarInput"
                  onChange={e => handlerUploadImage(e.target)}
                />
              </div>
            </div>

            {editFlag && (
              <div className="staff-edit-member__actions">
                <div className="staff-edit-member__actions__item blue-text">
                  <FormattedMessage {...messages.resetPassword} />
                </div>
                <div className="staff-edit-member__actions__item">
                  <FormattedMessage {...messages.deleteAccount} />
                </div>
              </div>
            )}

            {/* First Name */}
            <div className="staff-new-member__item">
              <Field name="firstName">
                {({field}) => (
                  <div className="fields-wrapper">
                    <label className="label">
                      <FormattedMessage {...messages.firstName} />
                    </label>
                    <Input {...field} />
                  </div>
                )}
              </Field>
            </div>

            {/* Last Name */}
            <div className="staff-new-member__item">
              <Field name="lastName">
                {({field}) => (
                  <div className="fields-wrapper">
                    <label className="label">
                      <FormattedMessage {...messages.lastName} />
                    </label>
                    <Input {...field} />
                  </div>
                )}
              </Field>
            </div>

            {/* Select role */}
            <div className="staff-new-member__item">
              <Field name="role">
                {({field}) => (
                  <div>
                    <label className="label">
                      <FormattedMessage {...messages.role} />
                    </label>
                    <CustomSelect
                      className="staff-new-member__item-select"
                      onChange={e => setFieldValue(values.role, e.target.value)}
                      options={listRoles}
                      {...field}
                    ></CustomSelect>
                  </div>
                )}
              </Field>
            </div>

            {editFlag ? (
              <div className="staff-new-member__action">
                <Button
                  text={messages.cancel}
                  type="button"
                  isSecondary="true"
                  onClick={() => {
                    closeRightModal();
                  }}
                />
                <Button text={messages.saveChanges} type="submit" />
              </div>
            ) : (
              <div className="staff-new-member__action">
                <Button text={messages.register} type="submit" />
              </div>
            )}
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

// Redux
const mapStateToProps = state => ({
  staffMember: dataRightModal(state),
});

const mapDispatchToProps = {
  closeRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffNewAndEditMemberModal);
