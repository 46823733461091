import React from "react";
import {connect} from "react-redux";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import {FormattedMessage} from "react-intl";

import "./index.scss";
import messages from "../../messages";
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import Button from "shared/components/Button";

import {addCartItem} from "shared/cartService/actions";
import {getAllRegularCurrency, getOnwalletAccount} from "shared/cardService/selectors";
import {OnWalletOption} from "shared/components/CardWalletOptions";
import Textarea from "shared/components/Input/Textarea";
import {
  BankDetailsForm,
  CardAmountWithCurrency,
  DetectOnWalletAccount,
  FeeRate,
  SendRecipient,
} from "./components";

const initialValues = {
  sourceOnWallet: null,
  recipientName: null,
  recipientAccountNumber: null,
  email: "",
  amount: "0",
  currency: "USD",
  description: "",
  sendReceipt: false,
};

const BankDetailsFromOnWalletSchema = Yup.object().shape({
  sourceOnWallet: Yup.object()
    .nullable()
    .default(null)
    .required("Source account is required"),
  recipientCard: Yup.object()
    .nullable()
    .default(null)
    .required("Recipient card is required"),
  amount: Yup.string()
    .required("Amount is required")
    .matches(
      "([1-9]\\d*(\\.\\d*[1-9])?|0\\.\\d*[1-9]+)|\\d+(\\.\\d*[1-9])?",
      "Amount must be a number"
    ),
  currency: Yup.string().required("Currency is required"),
});

const BankDetailsFromOnWalletForm = ({
  isSending,
  allCurrency,
  addCartItem,
  formTabs,
  onWalletAccount,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={BankDetailsFromOnWalletSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, {setSubmitting}) => {}}
    >
      {({errors, touched, values}) => (
        <div className="CardToCardTransferForm">
          <DetectOnWalletAccount
            field="sourceOnWallet"
            onWalletAccount={onWalletAccount}
          />
          <Form className="form">
            <FormSectionSeparator sectionName={messages.source} />
            {formTabs && formTabs()}
            <Field name="sourceOnWallet">
              {({field}) => (
                <div className="cardInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.myOnWalletAccount} />
                  </label>
                  <OnWalletOption account={field.value} />
                </div>
              )}
            </Field>
            <FormSectionSeparator sectionName={messages.recipient} />
            <BankDetailsForm touched={touched} errors={errors} values={values} />
            <SendRecipient touched={touched} errors={errors} values={values} />
            <FormSectionSeparator sectionName={messages.details} />
            <CardAmountWithCurrency
              touched={touched}
              errors={errors}
              allCurrency={allCurrency}
            />
            <Field name="description">
              {({field}) => (
                <div className="descriptionInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.description} />
                  </label>
                  <Textarea type="text" {...field} />
                </div>
              )}
            </Field>
            <SendRecipient touched={touched} errors={errors} values={values} />
            <FeeRate
              card={values.sourceOnWallet}
              amount={values.amount}
              currency={values.sourceOnWallet ? values.sourceOnWallet.currency : null}
            />
            <div className="buttonsContainer">
              <Button
                type="button"
                text={messages.addToCart}
                isSecondary="true"
                onClick={() => {
                  console.log("Add to Cart");
                  // addCartItem({variant: "cardToCard", type: "transfer", data: values})
                }}
              />

              <Button type="sumbit" text={messages.sendNow} isLoading={isSending} />
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

const mapStateToProps = store => ({
  isSending: store.transferCardData.isLoading,
  allCurrency: getAllRegularCurrency(store),
  onWalletAccount: getOnwalletAccount(store),
});

const mapDispatchToProps = {
  addCartItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(BankDetailsFromOnWalletForm);
