/**
 * @author: Gnapovska
 */

import React, {Component} from "react";
import {connect} from "react-redux";

import {Link} from "react-router-dom";

import {getOrdersHistory} from "shared/ordersHistoryService/selectors";
//import {getOrdersHistory} from "shared/tradeService/actions";
//import {dataOrderHistory} from "shared/tradeService/selectors"

import OrderHistoryItem from "./OrderHistoryItem";
import "./index.scss";
import {routes} from "shared/appUrls";
// import moment from "moment";

class Orders extends Component {
  constructor(props) {
    super(props);

    this.onChangeTypeView = this.onChangeTypeView.bind(this);

    this.state = {
      typeView: "mix",
      dataListOrders: this.props.currentOrders,
      selected: [],
      isAllSelected: "",
    };
  }

  countShowBuy() {
    return this.state.typeView === "mix" ? 10 : this.state.typeView === "buy" ? 20 : 0;
  }
  countShowSel() {
    return this.state.typeView === "mix" ? 10 : this.state.typeView === "sel" ? 20 : 0;
  }

  getRandom(min, max) {
    return Math.random() * (max - min) + min;
  }

  onChangeTypeView(e) {
    this.setState({
      typeView: e.target.value,
    });
  }

  handleClick = name => {
    const selectedIndex = this.state.selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selected.slice(1));
    } else if (selectedIndex === this.state.selected.length - 1) {
      newSelected = newSelected.concat(this.state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.selected.slice(0, selectedIndex),
        this.state.selected.slice(selectedIndex + 1)
      );
    }

    this.setState({
      selected: newSelected,
    });
  };

  isSelected = name => {
    return this.state.selected.indexOf(`${name}`) !== -1;
  };

  childChecked = childData => {
    this.handleClick(childData[0]);
    if (!childData[1]) {
      this.setState({isAllSelected: false});
    }
  };

  componentDidMount() {
    // let data = {
    //   cryptoCurrency: "dho",
    //   currency: "usd",
    //   size: 10,
    //   startDate: moment(),
    //   endDate: moment().subtract(1, "days"),
    // };
    //this.setState({dataListOrders: this.props.getOrdersHistory(data)});
    //this.setState({dataListOrders: this.props.currentOrders});
  }

  render() {
    return (
      <div className="orders-history widget">
        <div className="widget__wrapper">
          <div className="widget__header">
            <div className="widget__header__title">
              <h2>My 24h Order History</h2>
            </div>
            <div className="widget__header__tools">
              <Link className="Action" to={routes.orderHistory}>
                {"Details"}
              </Link>
            </div>
          </div>

          <div className="widget__body">
            <div className="widget__body__head">
              <div className="title-item">
                Side {"/"} Amount {"/"} Pair
              </div>
              <div className="title-item">Total</div>
            </div>

            <div className="widget__body__content">
              <div className="data-items">
                {this.state.dataListOrders.map((item, key) => {
                  return (
                    <OrderHistoryItem
                      key={item.id}
                      item={item}
                      selected={this.isSelected(item.id)}
                      callChecked={this.childChecked}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  //currentOrders: dataOrderHistory(store),
  currentOrders: getOrdersHistory(store),
});

function mapDispatchToProps(dispatch) {
  return {
    getOrdersHistory: data => {
      dispatch(getOrdersHistory(data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
