import React from "react";
import {connect} from "react-redux";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import {FormattedMessage} from "react-intl";

import "./index.scss";
import messages from "../../messages";
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import Select from "shared/components/Select";
import Button from "shared/components/Button";
import AddressBookInput from "shared/components/AddressBook";
import {addCartItem} from "shared/cartService/actions";
import {
  getCoinAmountInUSD,
  getCryptoWallets,
  getWalletIconByName,
} from "shared/walletService/selectors";
import {WalletOption, WalletOptionSelected} from "shared/components/CardWalletOptions";
import Input from "shared/components/Input";
import Textarea from "shared/components/Input/Textarea";
import SimpleCardInput from "shared/components/SimpleCardInput";
import {getCurrencyRates} from "shared/ratesService/selectors";
import {transferCryptoToCard} from "shared/walletService/actions";
import {
  DetectCryptoWallet,
  DetectDescription,
  DetectRecipient,
  FeeRate,
  SendRecipient,
} from "./components";

const initialValues = {
  source: null,
  recipientCard: null,
  amount: "0",
  description: "",
  sendReceipt: false,
  email: "",
  recipientCardNumber: "",
};

const WalletToSomeCardTransferSchema = Yup.object().shape({
  source: Yup.object()
    .nullable()
    .default(null)
    .required("Source  is required"),
  recipientCardNumber: Yup.string()
    .min(18, "Card Number is too short")
    .max(23, "Card Number is too long")
    .required("Recipient Card Number is required"),
  amount: Yup.string()
    .required("Amount is required")
    .matches(
      "([1-9]\\d*(\\.\\d*[1-9])?|0\\.\\d*[1-9]+)|\\d+(\\.\\d*[1-9])?",
      "Amount must be a number"
    ),
  email: Yup.string().email("Email must be valid"),
});

const onSubmit = values => ({
  cryptoAmount: Number.parseFloat(values.amount),
  sourceCurrency: values.source ? values.source.currency.toLowerCase() : "",
  recipientNumber: values.recipientCardNumber
    ? values.recipientCardNumber.replace(/ /g, "")
    : "",
  receiptEmail: values.email || null,
});

const SomeoneCardFromWalletForm = ({
  isSending,
  addCartItem,
  formTabs,
  allWallets,
  currencyRates,
  transferCryptoToCard,
  walletAddress,
  recipientCardNumber,
  description,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={WalletToSomeCardTransferSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, {setSubmitting}) => {
        transferCryptoToCard(onSubmit(values));
      }}
    >
      {({errors, touched, values}) => (
        <div className="CardToCardTransferForm">
          <DetectCryptoWallet
            field="source"
            allWallets={allWallets}
            walletAddress={walletAddress}
          />
          <DetectRecipient field="recipientCardNumber" recipient={recipientCardNumber} />
          <DetectDescription field="description" description={description} />
          <Form className="form">
            <FormSectionSeparator sectionName={messages.source} />
            {formTabs && formTabs()}
            <Field name="source">
              {({field}) => (
                <div className="cardInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.wallet} />
                  </label>
                  <Select
                    {...field}
                    height={64}
                    renderValue={value =>
                      !!value ? (
                        <WalletOptionSelected className="no-padding" wallet={value} />
                      ) : (
                        ""
                      )
                    }
                  >
                    {allWallets.map((wallet, index) => (
                      <WalletOption key={index} value={wallet} wallet={wallet} />
                    ))}
                  </Select>
                </div>
              )}
            </Field>
            <FormSectionSeparator sectionName={messages.recipient} />
            <Field name="recipientCardNumber">
              {({field}) => (
                <div className="cardInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.recipientCardNumber} />
                  </label>
                  <SimpleCardInput
                    error={touched.recipientCardNumber && errors.recipientCardNumber}
                    {...field}
                  />
                </div>
              )}
            </Field>
            <div className="cardInputContainer">
              <div className="cardInputContainer-block">
                <div className="cardInputContainer-block__item">
                  <Field name="recipientCardHolder">
                    {({field, form}) => (
                      <AddressBookInput
                        type="text"
                        label={<FormattedMessage {...messages.recipientCardholder} />}
                        types="cards"
                        placeholder="Name as marked on card"
                        name="recipientCardHolder"
                        error={touched.recipientCardHolder && errors.recipientCardHolder}
                        customer={{
                          cardNumber: values.recipientCardNumber,
                        }}
                        otherfield={{
                          label: <FormattedMessage {...messages.recipientCardNumber} />,
                          value: values.recipientCardNumber,
                          name: "recipientCardNumber",
                        }}
                        form={form}
                        {...field}
                      />
                    )}
                  </Field>
                </div>
              </div>
            </div>
            <FormSectionSeparator sectionName={messages.details} />
            <div className="AmountWalletCurrency">
              <Field name="amount">
                {({field}) => (
                  <div className="amountInputContainer">
                    <label className="label">
                      <FormattedMessage {...messages.amountInSourceCurrency} />
                    </label>
                    <div className="amountWrapper">
                      {!!values.source && (
                        <Input
                          type={values.source.walletName === "Deho" ? "number" : "text"}
                          leftIcon={
                            !!values.source
                              ? getWalletIconByName(values.source.walletName)
                              : null
                          }
                          iconStyles="walletIcon"
                          error={touched.amount && errors.amount}
                          {...field}
                        />
                      )}
                    </div>
                  </div>
                )}
              </Field>

              <div className="amountInUSDContainer">
                <label className="label">
                  <FormattedMessage {...messages.amountInRecipientCurrency} />
                </label>
                <div className="amountWrapper">
                  <span className="equal">≈</span>
                  <Input
                    type="text"
                    value={getCoinAmountInUSD(
                      values.source,
                      values.amount,
                      currencyRates
                    )}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <FeeRate
              wallet={values.source}
              amount={values.amount}
              currency={values.source ? values.source.currency : null}
              currencyRates={currencyRates}
            />
            <Field name="description">
              {({field}) => (
                <div className="descriptionInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.description} />
                  </label>
                  <Textarea
                    type="text"
                    placeholder={messages.transferFromCryptoWallet.defaultMessage}
                    {...field}
                  />
                </div>
              )}
            </Field>
            <SendRecipient touched={touched} errors={errors} values={values} />
            <FeeRate
              wallet={values.source}
              amount={values.amount}
              currency={values.source ? values.source.currency : null}
              currencyRates={currencyRates}
            />

            <div className="buttonsContainer">
              <Button
                type="button"
                text={messages.addToCart}
                isSecondary="true"
                onClick={() => {
                  addCartItem({
                    type: "transfer",
                    data: {
                      description: values.description,
                      sourceWallet: values.source,
                      sourceAmount: values.amount,
                      sourceCurrency: values.source ? values.source.currency : "",
                    },
                    action: option => transferCryptoToCard(onSubmit(values), option),
                  });
                }}
              />

              <Button type="sumbit" text={messages.sendNow} isLoading={isSending} />
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

const mapStateToProps = store => ({
  isSending: store.transferCardData.isLoading,
  allWallets: getCryptoWallets(store),
  currencyRates: getCurrencyRates(store),
});

const mapDispatchToProps = {
  addCartItem,
  transferCryptoToCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(SomeoneCardFromWalletForm);
