import {defineMessages} from "react-intl";

const scope = "TransactionsHistory";

const messages = defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: "Transaction History",
  },
  transactions: {
    id: `${scope}.transactions`,
    defaultMessage: "Transactions",
  },
  incomming: {
    id: `${scope}.incomming`,
    defaultMessage: "Incomming",
  },
  outgoing: {
    id: `${scope}.outgoing`,
    defaultMessage: "Outgoing",
  },
  date: {
    id: `${scope}.date`,
    defaultMessage: "Date",
  },
  cardwalet: {
    id: `${scope}.cardwalet`,
    defaultMessage: "Card/Wallet",
  },
  purposeofpayment: {
    id: `${scope}.purposeofpayment`,
    defaultMessage: "Purpose of Payment",
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: "Status",
  },
  amount: {
    id: `${scope}.amount`,
    defaultMessage: "Amount",
  },
  sendByEmail: {
    id: `${scope}.sendByEmail`,
    defaultMessage: "send By Email",
  },
  repeatTransaction: {
    id: `${scope}.repeatTransaction`,
    defaultMessage: "repeat transaction",
  },
  sourceWallet: {
    id: `${scope}.sourceWallet`,
    defaultMessage: "Source Wallet",
  },
  recipientWallet: {
    id: `${scope}.recipientWallet`,
    defaultMessage: "Recipient Wallet",
  },
  filters: {
    id: `${scope}.filters`,
    defaultMessage: "Filters",
  },
  autoReport: {
    id: `${scope}.autoReport`,
    defaultMessage: "Auto report",
  },
  sendByEMail: {
    id: `${scope}.sendByEMail`,
    defaultMessage: "Send by e-mail",
  },
  saveToFile: {
    id: `${scope}.saveToFile`,
    defaultMessage: "Save to file",
  },
  headerTransactionHistory: {
    id: `${scope}.headerTransactionHistory`,
    defaultMessage: "Transaction History",
  },
  headerCart: {
    id: `${scope}.headerCart`,
    defaultMessage: "Cart",
  },
  reset: {
    id: `${scope}.reset`,
    defaultMessage: "Reset",
  },
  applyFilters: {
    id: `${scope}.applyFilters`,
    defaultMessage: "Apply Filters",
  },
});

export default messages;
