import React from "react";
import {useParams} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
// Components
import Header from "shared/components/Header";
import Eula from "../Eula";
import Agreement from "../Agreement";
import PrivacyPolicy from "../PrivacyPolicy";
import TermsAndConditions from "../TermsAndConditions";
import CookiesPolicy from "../CookiesPolicy";
import RefundPolicy from "../RefundPolicy";
// Utils
import config from "shared/config";
import messages from "../../messages";
// Styles
import "./index.scss";
// Images
const closeIcon = config.prefixImgUrl + "close-icon.svg";
const arrowIcon = config.prefixImgUrl + "left-arrow-icon.svg";

const TYPES_DOCS = {
  eula: "eula",
  agreement: "agreement",
  privacy: "privacy",
  terms: "terms",
  refund: "refund",
  cookies: "cookies",
};

const PageWithDocument = () => {
  const {id} = useParams();
  const TYPE_DOCUMENT = id;

  return (
    <>
      <Header
        headerTitle={<FormattedMessage {...messages[`${TYPE_DOCUMENT}`]} />}
        navLinks={[]}
        logoImage={arrowIcon}
        link={"/legal-documents"}
      />

      <div className="document-wrapper-page">
        <div className="document-paper">
          <div className="document-paper__title">
            <Link to="/legal-documents">
              <img src={closeIcon} alt="close icon" />
            </Link>
            <div className="document-paper__title-text">
              {<FormattedMessage {...messages[`${TYPE_DOCUMENT}`]} />}
            </div>
          </div>

          <div className="document-paper__body">
            {TYPE_DOCUMENT === TYPES_DOCS.eula && <Eula />}
            {TYPE_DOCUMENT === TYPES_DOCS.agreement && <Agreement />}
            {TYPE_DOCUMENT === TYPES_DOCS.privacy && <PrivacyPolicy />}
            {TYPE_DOCUMENT === TYPES_DOCS.terms && <TermsAndConditions />}
            {TYPE_DOCUMENT === TYPES_DOCS.refund && <RefundPolicy />}
            {TYPE_DOCUMENT === TYPES_DOCS.cookies && <CookiesPolicy />}
          </div>
        </div>
      </div>
    </>
  );
};

export default PageWithDocument;
