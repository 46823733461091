// Utils
import config from "shared/config";
// Images
const onwalletLogo = config.prefixImgUrl + "logo-registration.svg";

const loadScript = src => {
  return new Promise(resolve => {
    const script = document.createElement("script");
    script.src = src;

    script.onload = () => {
      resolve(true);
    };

    script.onerror = () => {
      resolve(false);
    };

    // Add script in body site
    document.body.appendChild(script);
  });
};

export const displayRazorpay = async (user, data, description, order, testMode) => {
  const res = await loadScript(config.REACT_APP_RAZOR_SCRIPT);

  // Error handler
  if (!res) {
    console.warn("Razorpay SDK failed to load. Are you online?");
    return;
  }

  data = {
    currency: "INR",
    amount: 500,
    id: 312313,
  };

  // Generation option for payment
  const options = {
    key: config.REACT_APP_RAZOR_PAY_KEY,
    currency: data.currency,
    amount: data.amount.toString(),
    // Add order_id in production (for test mode without order_id)
    order_id: order.id,
    name: "Transfer",
    description,
    image: onwalletLogo,
    handler: function(response) {
      // alert(response.razorpay_payment_id);
      // alert(response.razorpay_order_id);
      // alert(response.razorpay_signature);
      // TODO: Write web hook for back-end
    },
    prefill: {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      // "card[name]": card.name,
      // "card[number]": card.number,
      // "card[expiry]": card.expiry,
      // "card[cvv]": card.cvv,
    },
  };

  if (testMode) {
    delete options.order_id;
  }

  const paymentObject = new window.Razorpay(options);

  // Open modal razorpay
  paymentObject.open();
};
