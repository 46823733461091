import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import ChartFilter from "./Components/ChartFilter";
import moment from "moment";
import "./index.scss";

import {
  getCurrencyRateWithTime,
  getCurrencyRate,
  getLoading,
} from "shared/ratesService/selectors";
import {getCurrencyRateForChart} from "shared/ratesService/actions";
import Loader from "shared/components/Loader";
// TODO: Delete after integration with back-end
import daily from "./mockData/1day.js";
import weekly from "./mockData/1week.js";
import month from "./mockData/1month.js";
import perMinute from "./mockData/1minute.js";
import perHour from "./mockData/1hour.js";

import {
  LineChart,
  Line,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  // ReferenceLine,
} from "recharts";

const CustomTooltip = ({active, payload, label, children, coin}) => {
  if (active && !!payload) {
    return (
      <div className="custom-chart-tooltip">
        <span className="custom-chart-tooltip__label">{`${coin} price, ${label}`}</span>
        <span className="custom-chart-tooltip__value">{`$${payload[0].value}`}</span>
        {children}
      </div>
    );
  }

  return null;
};

// const tooltipContainerStyles = {
//   boxShadow: "0px 3px 8px #004D993F",
//   borderRadius: "6px",
//   border: "none",
//   opacity: ".85",
// };

const addDolarSigh = decimal => {
  let k = decimal / 1000;
  if (k > 1) {
    return `$${k}K`;
  } else {
    return `$${decimal}`;
  }
};

const handlerInsertData = (
  filter,
  setChartData,
  currencyRate,
  setRealTimeIntervalId,
  currencyRateWithTime
) => {
  switch (filter.interval) {
    case "1MIN":
    case "1HOUR":
      setChartData(currencyRateWithTime);
      break;
    case "1DAY":
    case "1WEEK":
    case "1MONTH":
      setChartData(currencyRate);
      break;
    case "TIME":
      // Connect web socket
      setChartData(perMinute.slice(0, 100));
      const intervalId = setInterval(() => {
        setChartData(currentValue => {
          const rest = currentValue.slice(1);
          const date = moment(rest[rest.length - 1].name)
            .add(1, "minute")
            .format("YYYY-MM-DD hh:mm");
          rest.push({
            name: date,
            value: Math.floor(Math.random() * 3000) + 6500,
          });
          return rest;
        });
      }, 50);
      setRealTimeIntervalId(intervalId);
      break;
    default:
  }
};

console.log(`Chart init global`);
const Chart = ({chartData, coin}) => {
  return (
    <ResponsiveContainer width="99%">
      <LineChart
        data={chartData}
        margin={{top: 17, right: 0, bottom: 0, left: -20}}
        //     onMouseMove={(payload,event)=>{
        //     console.log(payload)
        //     if(payload && payload.activePayload)
        //     setSelectedValue(payload.activePayload[0].value);
        // }}
      >
        <XAxis dataKey="name" tick={{fontSize: 11}} />
        <YAxis
          axisLine={false}
          tickLine={false}
          tick={{fontSize: 11}}
          tickFormatter={addDolarSigh}
        />
        <CartesianGrid vertical={false} />
        <Tooltip content={<CustomTooltip coin={coin} />} />
        {/* <ReferenceLine y={selectedValue} stroke="red" strokeDasharray="3 3" /> */}
        {/* 'basis' | 'basisClosed' | 'basisOpen' | 'linear' | 'linearClosed' | 'natural' | 'monotoneX' | 'monotoneY' | 'monotone' | 'step' | 'stepBefore' | 'stepAfter'  */}
        <Line
          isAnimationActive={false}
          type="monotone"
          dataKey="value"
          stroke="#0080FF"
          strokeWidth="2"
          activeDot={{stroke: "#0080FF", strokeWidth: 2, r: 5, fill: "#FFF"}}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const ChartComposition = ({
  getCurrencyRateForChart,
  currencyRate,
  isLoading,
  currencyRateWithTime,
}) => {
  const [realTimeIntervalId, setRealTimeIntervalId] = useState();
  const [coin, setCoin] = useState("DEHO");
  const [filterCoin, setFilterCoin] = useState({});
  const [chartData, setChartData] = useState(weekly);

  useEffect(() => {
    if (!isLoading && !!currencyRateWithTime && coin !== "DEHO")
      handlerInsertData(
        filterCoin,
        setChartData,
        currencyRate,
        setRealTimeIntervalId,
        currencyRateWithTime
      );
  }, [coin, currencyRate, currencyRateWithTime, filterCoin, isLoading]);

  return (
    <div className="chart-wrapper">
      <div className="nav-bar-chart">
        <ChartFilter
          onChangeFilter={filter => {
            clearInterval(realTimeIntervalId);
            filter.limit = 100;
            setCoin(filter.coin);
            setFilterCoin(filter);

            if (filter.coin !== "DEHO") {
              getCurrencyRateForChart(filter);
            } else {
              // Delete after integration with Deho
              switch (filter.interval) {
                case "1MIN":
                  setChartData(perMinute);
                  break;
                case "1HOUR":
                  setChartData(perHour);
                  break;
                case "1DAY":
                  setChartData(daily);
                  break;
                case "1WEEK":
                  setChartData(weekly);
                  break;
                case "1MONTH":
                  setChartData(month);
                  break;
                case "TIME":
                  setChartData(perMinute.slice(0, 100));
                  const intervalId = setInterval(() => {
                    setChartData(currentValue => {
                      const rest = currentValue.slice(1);
                      const date = moment(rest[rest.length - 1].name)
                        .add(1, "minute")
                        .format("YYYY-MM-DD hh:mm");
                      rest.push({
                        name: date,
                        value: Math.floor(Math.random() * 3000) + 6500,
                      });
                      return rest;
                    });
                  }, 50);
                  setRealTimeIntervalId(intervalId);
                  break;
                default:
              }
            }
          }}
        />

        <div className="full-screen"></div>
      </div>
      <div className="chart">
        {!isLoading ? <Chart chartData={chartData} coin={coin} /> : <Loader />}
      </div>
    </div>
  );
};

const mapStateToProps = store => ({
  currencyRateWithTime: getCurrencyRateWithTime(store),
  currencyRate: getCurrencyRate(store),
  isLoading: getLoading(store),
});

const mapDispatchToProps = {
  getCurrencyRateForChart: data => getCurrencyRateForChart(data),
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartComposition);
