import React, {Fragment} from "react";
import {connect} from "react-redux";
// Actions
import {closeRightModal} from "shared/components/RightModal/actions";
// Components
import TrustedDevices from "components/AccountSettings/components/TrustedDevices";
import Button from "shared/components/Button";
// Utils
import messages from "../../messages";
// Styles
import "./index.scss";

const TrustedDevicesCustomerModal = ({closeRightModal}) => {
  return (
    <Fragment>
      <TrustedDevices forAdmin={true} className={"settings__block__admin"} />

      <div className="customer-trusted-devices__actions">
        <Button
          type="button"
          text={messages.cancel}
          isSecondary="true"
          onClick={() => closeRightModal()}
        />

        <Button type="button" text={messages.save} onClick={() => closeRightModal()} />
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  closeRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrustedDevicesCustomerModal);
