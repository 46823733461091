/**
 * @author: Seleznyov and Bazyliuk
 */

export const contactList = store => {
  // Sort alphabetically
  const sortedContacts = store.dataContacts.contacts.sort((a, b) => {
    const nameA = `${a.info.firstName} ${a.info.lastName}`.toLowerCase();
    const nameB = `${b.info.firstName} ${b.info.lastName}`.toLowerCase();

    return nameA < nameB ? -1 : 1;
  });

  return sortedContacts;
};

export const contactListCount = store => {
  return store.dataContacts.contacts ? store.dataContacts.contacts.length : [];
};
