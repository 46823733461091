export const FETCH_CRYPTO_WALLETS_PENDING = "FETCH_CRYPTO_WALLETS_PENDING";
export const FETCH_CRYPTO_WALLETS_SUCCESS = "FETCH_CRYPTO_WALLETS_SUCCESS";
export const FETCH_CRYPTO_WALLETS_FAILED = "FETCH_CRYPTO_WALLETS_FAILED";

export const SET_ACTIVE_CRYPTO_WALLET = "SET_ACTIVE_CRYPTO_WALLET";

export const GET_AVAILABLE_WALLET_PENDING = "GET_AVAILABLE_WALLET_PENDING";
export const GET_AVAILABLE_WALLET_SUCCESS = "GET_AVAILABLE_WALLET_SUCCESS";
export const GET_AVAILABLE_WALLET_FAILED = "GET_AVAILABLE_WALLET_FAILED";

export const GET_ALL_CRYPTO_CURRENCY_PENDING = "GET_ALL_CRYPTO_CURRENCY_PENDING";
export const GET_ALL_CRYPTO_CURRENCY_SUCCESS = "GET_ALL_CRYPTO_CURRENCY_SUCCESS";
export const GET_ALL_CRYPTO_CURRENCY_FAILED = "GET_ALL_CRYPTO_CURRENCY_FAILED";

export const CREATE_CRYPTO_WALLET_PENDING = "CREATE_CRYPTO_WALLET_PENDING";
export const CREATE_CRYPTO_WALLET_SUCCESS = "CREATE_CRYPTO_WALLET_SUCCESS";
export const CREATE_CRYPTO_WALLET_FAILED = "CREATE_CRYPTO_WALLET_FAILED";

export const FETCH_THIRD_WALLETS_PENDING = "FETCH_THIRD_WALLETS_PENDING";
export const FETCH_THIRD_WALLETS_SUCCESS = "FETCH_THIRD_WALLETS_SUCCESS";
export const FETCH_THIRD_WALLETS_FAILED = "FETCH_THIRD_WALLETS_FAILED";

export const SET_ACTIVE_THIRD_WALLET = "SET_ACTIVE_CRYPTO_WALLET";

export const TRANSFER_CRYPTO_TO_CRYPTO_PENDING = "TRANSFER_CRYPTO_TO_CRYPTO_PENDING";
export const TRANSFER_CRYPTO_TO_CRYPTO_SUCCESS = "TRANSFER_CRYPTO_TO_CRYPTO_SUCCESS";
export const TRANSFER_CRYPTO_TO_CRYPTO_FAILED = "TRANSFER_CRYPTO_TO_CRYPTO_FAILED";

export const TRANSFER_CRYPTO_TO_CARD_PENDING = "TRANSFER_CRYPTO_TO_CARD_PENDING";
export const TRANSFER_CRYPTO_TO_CARD_SUCCESS = "TRANSFER_CRYPTO_TO_CARD_SUCCESS";
export const TRANSFER_CRYPTO_TO_CARD_FAILED = "TRANSFER_CRYPTO_TO_CARD_FAILED";

export const TRANSFER_CARD_TO_CRYPTO_PENDING = "TRANSFER_CARD_TO_CRYPTO_PENDING";
export const TRANSFER_CARD_TO_CRYPTO_SUCCESS = "TRANSFER_CARD_TO_CRYPTO_SUCCESS";
export const TRANSFER_CARD_TO_CRYPTO_FAILED = "TRANSFER_CARD_TO_CRYPTO_FAILED";

export const TRANSFER_ONWALLET_TO_CRYPTO_PENDING = "TRANSFER_ONWALLET_TO_CRYPTO_PENDING";
export const TRANSFER_ONWALLET_TO_CRYPTO_SUCCESS = "TRANSFER_ONWALLET_TO_CRYPTO_SUCCESS";
export const TRANSFER_ONWALLET_TO_CRYPTO_FAILED = "TRANSFER_ONWALLET_TO_CRYPTO_FAILED";

export const TRANSFER_CRYPTO_TO_ONWALLET_PENDING = "TRANSFER_CRYPTO_TO_ONWALLET_PENDING";
export const TRANSFER_CRYPTO_TO_ONWALLET_SUCCESS = "TRANSFER_CRYPTO_TO_ONWALLET_SUCCESS";
export const TRANSFER_CRYPTO_TO_ONWALLET_FAILED = "TRANSFER_CRYPTO_TO_ONWALLET_FAILED";

export const CRYPTO_WALLET_DELETE_PENDING = "CRYPTO_WALLET_DELETE_PENDING";
export const CRYPTO_WALLET_DELETE_SUCCESS = "CRYPTO_WALLET_DELETE_SUCCESS";
export const CRYPTO_WALLET_DELETE_FAILED = "CRYPTO_WALLET_DELETE_FAILED";
