import React from "react";
import {MenuItem} from "@material-ui/core";

import "./index.scss";

export const PairOption = React.forwardRef(({className, pair, value, ...props}, ref) => {
  return (
    <MenuItem value={value} className={`walletOption ${className}`} {...props}>
      <div className="title">
        <div className="name">{pair && pair.code}</div>
      </div>
    </MenuItem>
  );
});

export const PairOptionSelected = React.forwardRef(
  ({className, pair, value, ...props}, ref) => {
    return (
      <MenuItem value={value} className={`walletOption ${className}`} {...props}>
        <div className="title">
          <div className="name">{pair && pair.code}</div>
        </div>
        <div>{"Trade Pair"}</div>
      </MenuItem>
    );
  }
);
