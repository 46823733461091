import {
  FETCH_BANK_CARDS_PENDING,
  FETCH_BANK_CARDS_SUCCESS,
  FETCH_BANK_CARDS_FAILED,
  SET_ACTIVE_BANK_CARD,
  GET_ONWALLET_PENDING,
  GET_ONWALLET_SUCCESS,
  GET_ONWALLET_FAILED,
  GET_REGULAR_CURRENCY_PENDING,
  GET_REGULAR_CURRENCY_SUCCESS,
  GET_REGULAR_CURRENCY_FAILED,
  ADD_REGULAR_CARD_FAILED,
  ADD_REGULAR_CARD_PENDING,
  ADD_REGULAR_CARD_SUCCESS,
  RECEIVE_CARD_QR_FAILED,
  RECEIVE_CARD_QR_PENDING,
  RECEIVE_CARD_QR_SUCCESS,
  RESET_RECEIVE_CARD_QR,
  TRANSFER_CARD_TO_CARD_FAILED,
  TRANSFER_CARD_TO_CARD_PENDING,
  TRANSFER_CARD_TO_CARD_SUCCESS,
  FETCH_BANK_ACCOUNT_PENDING,
  FETCH_BANK_ACCOUNT_SUCCESS,
  SET_ACTIVE_BANK_ACCOUNT,
  SET_CARD_DEFAULT_PENDING,
  SET_CARD_DEFAULT_SUCCESS,
  SET_CARD_DEFAULT_FAILED,
  UPDATE_CARD_DEFAULT_PENDING,
  UPDATE_CARD_DEFAULT_SUCCESS,
  UPDATE_CARD_DEFAULT_FAILED,
  DELETE_CARD_DEFAULT_PENDING,
  DELETE_CARD_DEFAULT_SUCCESS,
  DELETE_CARD_DEFAULT_FAILED,
  TRANSFER_ONWALLET_TO_CARD_PENDING,
  TRANSFER_ONWALLET_TO_CARD_SUCCESS,
  TRANSFER_ONWALLET_TO_CARD_FAILED,
  TRANSFER_CARD_TO_ONWALLET_PENDING,
  TRANSFER_CARD_TO_ONWALLET_SUCCESS,
  TRANSFER_CARD_TO_ONWALLET_FAILED,
  TRANSFER_ONWALLET_TO_ONWALLET_PENDING,
  TRANSFER_ONWALLET_TO_ONWALLET_SUCCESS,
  TRANSFER_ONWALLET_TO_ONWALLET_FAILED,
} from "./types";
import {closeRightModal} from "shared/components/RightModal/actions";
import {
  cardAPIGetRegular,
  cardAPIGetOnWallet,
  walletAPIGetAllRegularCurrency,
  cardAPICreateRegular,
  getCardRegularQRCode,
  getOnWalletQRCode,
  cardAPITransferRegularToRegular,
  cardAPISetRegularCardDefault,
  cardAPICardRegularUpdate,
  cardAPICardRegularDelete,
  cardAPITransferOnWalletToCard,
  cardAPITransferCardToOnWallet,
  cardAPITransferOnWalletToOnWallet,
} from "shared/api/cardApi";
import {handleUnAuthorizationError} from "shared/auth/actions";
import {getTokenData} from "shared/auth/selectors";
import {selectUserProfile} from "shared/auth/selectors";
import {fetchTransactions} from "../../historyService/actions";
import {fetchCryptoWallets} from "../../walletService/actions";
import {displayRazorpay} from "razorpay";

const updateInfo = dispatch => {
  setTimeout(() => {
    // TODO: update card and wallet information
    dispatch(fetchTransactions());
    dispatch(fetchOnwalletCard());
    dispatch(fetchBankCards());
    dispatch(fetchCryptoWallets());
  }, 1000);
};

export const fetchBankCards = () => (dispatch, getState) => {
  const tokenData = getTokenData(getState());
  if (!tokenData) return;
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };
  dispatch({
    type: FETCH_BANK_CARDS_PENDING,
  });
  cardAPIGetRegular(option)
    .then(res => {
      dispatch({type: FETCH_BANK_CARDS_SUCCESS, payload: res.data});
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error, fetchBankCards));
      dispatch({type: FETCH_BANK_CARDS_FAILED});
    });
};

export const setActiveBankCard = (number, name) => ({
  type: SET_ACTIVE_BANK_CARD,
  payload: name ? name : number,
});

export const fetchOnwalletCard = () => (dispatch, getState) => {
  const tokenData = getTokenData(getState());
  if (!tokenData) return;
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };
  dispatch({type: GET_ONWALLET_PENDING});
  cardAPIGetOnWallet(option)
    .then(res => {
      dispatch({type: GET_ONWALLET_SUCCESS, payload: res.data});
    })
    .catch(error => {
      dispatch({type: GET_ONWALLET_FAILED});
      dispatch(handleUnAuthorizationError(error, fetchOnwalletCard));
    });
};

export const fetchAllRegularCurrency = () => (dispatch, getState) => {
  const tokenData = getTokenData(getState());
  if (!tokenData) return;
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };
  dispatch({type: GET_REGULAR_CURRENCY_PENDING});
  walletAPIGetAllRegularCurrency(option)
    .then(res => {
      dispatch({type: GET_REGULAR_CURRENCY_SUCCESS, payload: res.data});
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error, fetchAllRegularCurrency));
      dispatch({type: GET_REGULAR_CURRENCY_FAILED});
    });
};

export const addNewRegularCard = data => (dispatch, getState) => {
  const tokenData = getTokenData(getState());
  if (!tokenData) return;
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };
  dispatch({type: ADD_REGULAR_CARD_PENDING});
  cardAPICreateRegular(data, option)
    .then(() => {
      dispatch({type: ADD_REGULAR_CARD_SUCCESS});
      dispatch(fetchBankCards());
      dispatch(closeRightModal());
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error, addNewRegularCard, data));
      dispatch(closeRightModal());
      dispatch({type: ADD_REGULAR_CARD_FAILED});
    });
};

export const resetReceiveCard = () => ({
  type: RESET_RECEIVE_CARD_QR,
});

export const getRegularCardReceive = data => (dispatch, getState) => {
  const tokenData = getTokenData(getState());
  if (!tokenData) return;
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };
  dispatch({type: RECEIVE_CARD_QR_PENDING});
  getCardRegularQRCode(data.sourceId, data.amount, option)
    .then(res => {
      let reader = new FileReader();
      reader.readAsDataURL(res.data); // converts the blob to base64 and calls onload
      reader.onload = function() {
        dispatch({
          type: RECEIVE_CARD_QR_SUCCESS,
          payload: reader.result,
        });
      };
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error, getRegularCardReceive, data));
      dispatch({type: RECEIVE_CARD_QR_FAILED});
    });
};

export const getOnWalletCardReceive = data => (dispatch, getState) => {
  const tokenData = getTokenData(getState());
  if (!tokenData) return;
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };
  dispatch({type: RECEIVE_CARD_QR_PENDING});
  getOnWalletQRCode(data.amount, option)
    .then(res => {
      let reader = new FileReader();
      reader.readAsDataURL(res.data); // converts the blob to base64 and calls onload
      reader.onload = function() {
        dispatch({
          type: RECEIVE_CARD_QR_SUCCESS,
          payload: reader.result,
        });
      };
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error, getOnWalletCardReceive, data));
      dispatch({type: RECEIVE_CARD_QR_FAILED});
    });
};

export const transferFromCardToCard = (data, opt) => (dispatch, getState) => {
  const tokenData = getTokenData(getState());
  const user = selectUserProfile(getState());
  // Error handler
  if (!tokenData) return;

  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };

  dispatch({type: TRANSFER_CARD_TO_CARD_PENDING});

  cardAPITransferRegularToRegular(data, option)
    .then(res => {
      // Razorpay window
      displayRazorpay(
        user,
        "Card to card",
        "Transfer card to card",
        res.data.order || {id: 11111},
        true
      );
      dispatch({type: TRANSFER_CARD_TO_CARD_SUCCESS});
      dispatch(closeRightModal());
      updateInfo(dispatch);
      if (opt && opt.success) dispatch(opt.success());
    })
    .catch(error => {
      dispatch(closeRightModal());
      updateInfo(dispatch);
      dispatch(handleUnAuthorizationError(error, transferFromCardToCard, data, opt));
      dispatch({type: TRANSFER_CARD_TO_CARD_FAILED});
      if (opt && opt.error) dispatch(opt.error());
    });
};

const mockBankAccounts = [
  {
    name: "BankOfAmerica",
    last5: "45879",
  },
  {
    name: "BankAustria",
    last5: "23456",
  },
];

export const fetchBankAccounts = () => dispatch => {
  dispatch({type: FETCH_BANK_ACCOUNT_PENDING});
  setTimeout(() => {
    dispatch({type: FETCH_BANK_ACCOUNT_SUCCESS, payload: mockBankAccounts});
  }, 1500);
};

export const setActiveBankAccount = number => ({
  type: SET_ACTIVE_BANK_ACCOUNT,
  payload: number,
});

export const setDefaultCard = id => (dispatch, getState) => {
  const tokenData = getTokenData(getState());
  if (!tokenData) return;
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };
  dispatch({type: SET_CARD_DEFAULT_PENDING});
  cardAPISetRegularCardDefault(id, option)
    .then(() => {
      dispatch({type: SET_CARD_DEFAULT_SUCCESS, payload: id});
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error, setDefaultCard, id));
      dispatch({type: SET_CARD_DEFAULT_FAILED});
    });
};

export const updateCard = (id, data) => (dispatch, getState) => {
  const tokenData = getTokenData(getState());
  if (!tokenData) return;
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };
  dispatch({type: UPDATE_CARD_DEFAULT_PENDING});
  cardAPICardRegularUpdate(id, data, option)
    .then(() => {
      dispatch({type: UPDATE_CARD_DEFAULT_SUCCESS, payload: {id, data}});
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error, updateCard, id, data));
      dispatch({type: UPDATE_CARD_DEFAULT_FAILED});
    });
};

export const deleteCard = id => (dispatch, getState) => {
  const tokenData = getTokenData(getState());
  if (!tokenData) return;
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };
  dispatch({type: DELETE_CARD_DEFAULT_PENDING});
  cardAPICardRegularDelete(id, option)
    .then(() => {
      dispatch(closeRightModal());
      dispatch({type: DELETE_CARD_DEFAULT_SUCCESS, payload: id});
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error, deleteCard, id));
      dispatch({type: DELETE_CARD_DEFAULT_FAILED});
    });
};

export const transferOnWalletToCard = (data, opt) => (dispatch, getState) => {
  const tokenData = getTokenData(getState());
  if (!tokenData) return;
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };
  dispatch({type: TRANSFER_ONWALLET_TO_CARD_PENDING});
  cardAPITransferOnWalletToCard(data, option)
    .then(() => {
      dispatch({type: TRANSFER_ONWALLET_TO_CARD_SUCCESS});
      dispatch(closeRightModal());
      updateInfo(dispatch);
      if (opt && opt.success) dispatch(opt.success());
    })
    .catch(error => {
      dispatch(closeRightModal());
      updateInfo(dispatch);
      dispatch(handleUnAuthorizationError(error, transferOnWalletToCard, data, opt));
      dispatch({type: TRANSFER_ONWALLET_TO_CARD_FAILED});
      if (opt && opt.error) dispatch(opt.error());
    });
};

export const transferCardToOnWallet = (data, opt) => (dispatch, getState) => {
  const tokenData = getTokenData(getState());
  const user = selectUserProfile(getState());

  // Error handler
  if (!tokenData) return;

  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };

  dispatch({type: TRANSFER_CARD_TO_ONWALLET_PENDING});

  cardAPITransferCardToOnWallet(data, option)
    .then(res => {
      displayRazorpay(
        user,
        "Card to OnWallet",
        "Transfer card to OnWallet",
        res.data.order
      );
      dispatch({type: TRANSFER_CARD_TO_ONWALLET_SUCCESS});
      dispatch(closeRightModal());
      updateInfo(dispatch);
      if (opt && opt.success) dispatch(opt.success());
    })
    .catch(error => {
      dispatch(closeRightModal());
      updateInfo(dispatch);
      dispatch(handleUnAuthorizationError(error, transferCardToOnWallet, data, opt));
      dispatch({type: TRANSFER_CARD_TO_ONWALLET_FAILED});
      if (opt && opt.error) dispatch(opt.error());
    });
};

export const transferOnWalletToOnWallet = (data, opt) => (dispatch, getState) => {
  const tokenData = getTokenData(getState());
  if (!tokenData) return;
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };
  dispatch({type: TRANSFER_ONWALLET_TO_ONWALLET_PENDING});
  cardAPITransferOnWalletToOnWallet(data, option)
    .then(() => {
      dispatch({type: TRANSFER_ONWALLET_TO_ONWALLET_SUCCESS});
      dispatch(closeRightModal());
      updateInfo(dispatch);
      if (opt && opt.success) dispatch(opt.success());
    })
    .catch(error => {
      dispatch(closeRightModal());
      updateInfo(dispatch);
      dispatch(handleUnAuthorizationError(error, transferOnWalletToOnWallet, data, opt));
      dispatch({type: TRANSFER_ONWALLET_TO_ONWALLET_FAILED});
      if (opt && opt.error) dispatch(opt.error());
    });
};
