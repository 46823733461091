import {
  FETCH_TRANSACTIONS_PENDING,
  FETCH_TRANSACTIONS_SUCCESS,
  FETCH_TRANSACTIONS_FAILED,
  FETCH_DEHO_HISTORY_PENDING,
  FETCH_DEHO_HISTORY_SUCCESS,
  FETCH_DEHO_HISTORY_FAILED,
  FETCH_ONWALLET_HISTORY_PENDING,
  FETCH_ONWALLET_HISTORY_SUCCESS,
  FETCH_ONWALLET_HISTORY_FAILED,
} from "./types";
import {
  historyAPIGetCryptoBalance,
  historyAPIGetRegularBalance,
  historyAPIGetTransactions,
} from "shared/api/historyApi";
import {handleUnAuthorizationError} from "shared/auth/actions";
import {getTokenData} from "shared/auth/selectors";
import {closeRightModal} from "shared/components/RightModal/actions";
import moment from "moment";

export const fetchTransactions = (data = {}) => (dispatch, getState) => {
  const tokenData = getTokenData(getState());
  if (!tokenData) return;
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };
  dispatch({
    type: FETCH_TRANSACTIONS_PENDING,
  });

  if (!data.startTime) {
    const today = moment();
    data.startTime = [today.clone().subtract(30, "days"), today.clone()];
  }

  historyAPIGetTransactions(data, option)
    .then(res => {
      dispatch({type: FETCH_TRANSACTIONS_SUCCESS, payload: res.data});
      dispatch(closeRightModal());
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error, fetchTransactions));
      dispatch({type: FETCH_TRANSACTIONS_FAILED});
    });
};

export const fetchDehoBalanceHistory = () => (dispatch, getState) => {
  const tokenData = getTokenData(getState());
  if (!tokenData) return;
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };
  dispatch({
    type: FETCH_DEHO_HISTORY_PENDING,
  });
  historyAPIGetCryptoBalance(option)
    .then(res => {
      dispatch({type: FETCH_DEHO_HISTORY_SUCCESS, payload: res.data});
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error, fetchDehoBalanceHistory));
      dispatch({type: FETCH_DEHO_HISTORY_FAILED});
    });
};

export const fetchOnWalletBalanceHistory = () => (dispatch, getState) => {
  const tokenData = getTokenData(getState());
  if (!tokenData) return;
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };
  dispatch({
    type: FETCH_ONWALLET_HISTORY_PENDING,
  });
  historyAPIGetRegularBalance(option)
    .then(res => {
      dispatch({type: FETCH_ONWALLET_HISTORY_SUCCESS, payload: res.data});
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error, fetchOnWalletBalanceHistory));
      dispatch({type: FETCH_ONWALLET_HISTORY_FAILED});
    });
};
