import {messagesEndpoints} from "../../api/apiEndpoints";
import axios from "axios";
import {getTokenData} from "shared/auth/selectors";
import {getShortAddress} from "shared/walletService/selectors";

import {MESSAGES_MARK_ALL_READ, MESSAGES_MARK_AS_READ, GET_MESSAGES} from "./types";

export const markAsRead = id => ({
  type: MESSAGES_MARK_AS_READ,
  payload: id,
});

export const markAllRead = () => ({
  type: MESSAGES_MARK_ALL_READ,
});

const getOption = getState => {
  const tokenData = getTokenData(getState());

  // Err handler
  if (!tokenData) return;

  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };

  return option;
};

const remapResponse = data =>
  data.map(item => {
    switch (item.key) {
      case "contact.share":
        const info = JSON.parse(item.content);

        // Define title message
        item.title = "Share contact";
        // Define body message
        item.content = `${info.SenderFirstName} ${info.SenderLastName} shared contact of ${info.ContactFirstName} ${info.ContactLastName} with you. Would you like to save this contact?`;

        return item;

      case "profile.share":
        const profileInfo = JSON.parse(item.content);

        // Define title message
        item.title = "Share profile";
        // Define body message
        item.content = `${profileInfo.FirstName} ${profileInfo.LastName} shared own contact with you. Would you like to save it?`;

        return item;

      case "transaction.success":
        // TODO talk with back-end about change structure obj
        const transactionInfo = JSON.parse(item.content);
        const date = new Date(item.time);
        const transactionDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;

        // Define title message
        item.title = `${transactionInfo.Info.TargetCurrency.toUpperCase()} ${
          transactionInfo.Info.TargetAmount
        }`;

        // Define body message
        item.content = `You received ${transactionInfo.Info.TargetCurrency.toUpperCase()} ${
          transactionInfo.Info.TargetAmount
        } on your ${getShortAddress(transactionInfo.Info.TargetAccount)}. <br> ${
          transactionInfo.Info.Purpose
        }, ${transactionDate}.`;

        return item;

      case "transaction.fail":
        const transactionFail = JSON.parse(item.content);
        const dateFail = new Date(item.time);
        const transactionFailDate = `${dateFail.getDate()}/${dateFail.getMonth()}/${dateFail.getFullYear()}`;

        // Define title message
        item.title = "Transaction fail";

        // Define body message
        item.content = `Transaction fail ${transactionFail.Info.TargetCurrency.toUpperCase()} ${
          transactionFail.Info.Data.TokenAmount
        } on your ${getShortAddress(transactionFail.Info.TargetAccount)}. <br> ${
          transactionFail.Info.Purpose
        }, ${transactionFailDate}.`;

        return item;
      default:
        return item;
    }
  });

export const getMessages = () => (dispatch, getState) => {
  axios
    .get(messagesEndpoints.messages, getOption(getState))
    .then(res => {
      const _data = remapResponse(res.data);

      dispatch({
        type: GET_MESSAGES,
        payload: _data,
      });
    })
    .catch(error => {
      console.error("error", error);
    });
};

export const updateMessage = message => (dispatch, getState) => {
  axios
    .put(messagesEndpoints.messageUpdate(message.id), message, getOption)
    .then(res => {
      dispatch({
        type: GET_MESSAGES,
        payload: res.data,
      });
      dispatch({type: MESSAGES_MARK_AS_READ});
    })
    .catch(error => {
      console.error("error", error);
    });
};
