import React, {useState, Fragment} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
// Components
import ToggleMenu from "shared/components/ToggleMenu";
import DialogModal from "shared/components/DialogModal";
// Actions
import {openRightModal} from "shared/components/RightModal/actions";
// Selectors
import {getWalletIconByName} from "shared/walletService/selectors";
import {getCardIconBank} from "shared/cardService/selectors";
// Utils
import messages from "../../../messages";
import config from "shared/config";
// Styles
import "./index.scss";
// Images
import {ReactComponent as SettingIcon} from "images/settings-icon-gray.svg";
const selectUp = config.prefixImgUrl + "arrow-transaction.svg";
const selectDown = config.prefixImgUrl + "arrow-down-transaction.svg";
const imageIcon = config.prefixImgUrl + "image-icon.svg";
const avatarDefault = config.prefixImgUrl + "avatar-default-light.svg";
const documentIcon = config.prefixImgUrl + "document_icon.svg";
const editIcon = config.prefixImgUrl + "edit-icon-short.svg";
const exchangeIcon = config.prefixImgUrl + "buy-icon.svg";
const InvoiceIcon = config.prefixImgUrl + "icon-receive.svg";
const transferIcon = config.prefixImgUrl + "transfer-icon.svg";
const transferHistory = config.prefixImgUrl + "clock-icon.svg";

const CustomersTableItem = ({
  item,
  wallets,
  cards,
  getWalletIconByName,
  getCardIconBank,
  openRightModal,
}) => {
  const [isShow, setShow] = useState(false);
  const [avatar, setAvatar] = useState(
    item.picture.large ? item.picture.large : avatarDefault
  );

  const handlerUploadImage = ({files}) => {
    if (files && files.length > 0) {
      const avatar = window.URL.createObjectURL(files[0]);
      // TODO: Request on back-end
      // uploadUserAvatar(files[0], onWalletAccount);
      setAvatar(avatar);
    }
  };

  const listItems = (items, getIcon, customer) => {
    return (
      <div>
        {items.map((item, index) => (
          <div className="details-customer__wallets__table-body__items" key={index}>
            <div className="details-customer__wallets__table-body__items__item">
              <img src={getIcon(item.name)} alt="logo" />
              {item.name}
            </div>
            <div className="details-customer__wallets__table-body__items__item">
              {item.id}
            </div>
            <div className="details-customer__wallets__table-body__items__item">
              {item.type}
            </div>
            <div className="details-customer__wallets__table-body__items__item">
              {item.currency}
            </div>
            <div className="details-customer__wallets__table-body__items__item">
              {item.balance}
            </div>
            <div className="details-customer__wallets__table-body__items__item">
              {item.status}
            </div>
            <div className="details-customer__wallets__table-body__items__item">
              <ToggleMenu label={() => <SettingIcon />}>
                <div className="details-customer__wallets__table-body__items__item-action-title">
                  <div>Operations</div>
                  <div className="line" />
                </div>
                <div
                  className="details-customer__wallets__table-body__items__item-action"
                  onClick={() =>
                    openRightModal(
                      {
                        name: "transfer",
                        title: "New Transfer",
                        subTitle: `${customer.name.first} ${customer.name.last}`,
                        image: customer.picture.medium,
                      },
                      null,
                      customer
                    )
                  }
                >
                  <img src={transferIcon} className="icon" alt="icon" />
                  <div className="text">
                    <FormattedMessage {...messages.transfer} />
                  </div>
                </div>
                <div
                  className="details-customer__wallets__table-body__items__item-action"
                  onClick={() =>
                    openRightModal(
                      {
                        name: "invoice",
                        title: "New Invoice",
                        subTitle: `${customer.name.first} ${customer.name.last}`,
                        image: customer.picture.medium,
                      },
                      null,
                      customer
                    )
                  }
                >
                  <img src={InvoiceIcon} className="icon" alt="icon" />
                  <div className="text">
                    <FormattedMessage {...messages.invoice} />
                  </div>
                </div>
                <div
                  className="details-customer__wallets__table-body__items__item-action"
                  onClick={() =>
                    openRightModal(
                      {
                        name: "exchange",
                        title: "New Exchange",
                        subTitle: `${customer.name.first} ${customer.name.last}`,
                        image: customer.picture.medium,
                      },
                      null,
                      customer
                    )
                  }
                >
                  <img src={exchangeIcon} className="icon" alt="icon" />
                  <div className="text">
                    <FormattedMessage {...messages.exchange} />
                  </div>
                </div>
                <div className="details-customer__wallets__table-body__items__item-action-title">
                  <div>OTHER</div>
                  <div className="line" />
                </div>
                <div className="details-customer__wallets__table-body__items__item-action">
                  <img src={transferHistory} className="icon" alt="icon" />
                  <div className="text">
                    <Link to="/action_history">
                      <FormattedMessage {...messages.transactionHistory} />
                    </Link>
                  </div>
                </div>
                <div
                  className="details-customer__wallets__table-body__items__item-action"
                  onClick={() =>
                    openRightModal(
                      {
                        name: "changeFinancialAccountStatus",
                        title: "Change Financial Account Status",
                        subTitle: `${customer.name.first} ${customer.name.last}`,
                        image: customer.picture.medium,
                      },
                      null,
                      customer
                    )
                  }
                >
                  <img src={editIcon} className="icon" alt="icon" />
                  <div className="text">
                    <FormattedMessage {...messages.changeStatus} />
                  </div>
                </div>
              </ToggleMenu>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Fragment>
      <div
        className={`customers-table-body__item ${isShow ? "active-item" : ""}`}
        onClick={() => setShow(!isShow)}
      >
        <div className="customers-table-body__item-id">{item.location.postcode}</div>
        <div className="customers-table-body__item-firstName">{item.name.first}</div>
        <div className="customers-table-body__item-lastName">{item.name.last}</div>
        <div className="customers-table-body__item-companyName">{item.location.city}</div>
        <div className="customers-table-body__item-status">
          <div>Active</div>
          <img src={isShow ? selectUp : selectDown} alt="select" />
        </div>
      </div>

      {/* Details Customer */}
      {isShow && (
        <div className="details-customer">
          <div className="details-customer__personal-details">
            {/* Card Title Customer */}
            <div className="details-customer__personal-details-title">
              <FormattedMessage {...messages.personalDetails} />
            </div>

            {/* Card Customer */}
            <div className="details-customer__personal-details-card">
              <div className="details-customer__personal-details-card__image">
                <div className="avatar-container">
                  <div className="avatar-container__image">
                    <img src={avatar} alt="avatar" />
                  </div>
                  <div className="changeAvatar">
                    <img src={imageIcon} alt="change" />
                    <input
                      type="file"
                      className="avatarInput"
                      onChange={e => handlerUploadImage(e.target)}
                    />
                  </div>
                </div>
              </div>
              <div className="details-customer__personal-details-card__info">
                {/* Name Customer */}
                <div className="details-customer__personal-details-card__info__item">
                  <div className="details-customer__personal-details-card__info__item-text">
                    <div className="details-customer__personal-details-card__info__item-title">
                      {`${item.name.first} ${item.name.last}`}
                    </div>
                    <div className="details-customer__personal-details-card__info__item-sub-title">
                      Name
                    </div>
                  </div>
                  <div
                    className="details-customer__personal-details-card__info__item-change details-customer__personal-details-actions__item"
                    onClick={() =>
                      openRightModal(
                        {
                          name: "changeName",
                          title: "Change Name",
                          subTitle: `${item.name.first} ${item.name.last}`,
                          image: item.picture.medium,
                        },
                        null,
                        item
                      )
                    }
                  >
                    <FormattedMessage {...messages.change} />
                    <img src={documentIcon} alt="document icon" />
                  </div>
                </div>

                {/* Phone Customer */}
                <div className="details-customer__personal-details-card__info__item">
                  <div className="details-customer__personal-details-card__info__item-text">
                    <div className="details-customer__personal-details-card__info__item-title">
                      {item.cell}
                    </div>
                    <div className="details-customer__personal-details-card__info__item-sub-title">
                      Cell phone number
                    </div>
                  </div>
                </div>

                {/* Email Customer */}
                <div className="details-customer__personal-details-card__info__item">
                  <div className="details-customer__personal-details-card__info__item-text">
                    <div className="details-customer__personal-details-card__info__item-title">
                      {item.email}
                    </div>
                    <div className="details-customer__personal-details-card__info__item-sub-title">
                      E-mail
                    </div>
                  </div>
                </div>

                {/* Address Customer */}
                <div className="details-customer__personal-details-card__info__item">
                  <div className="details-customer__personal-details-card__info__item-text">
                    <div className="details-customer__personal-details-card__info__item-title">
                      {`${item.location.country} ${item.location.city} ${item.location.street.name}`}
                    </div>
                    <div className="details-customer__personal-details-card__info__item-sub-title">
                      Legal address
                    </div>
                  </div>
                  <div
                    className="details-customer__personal-details-card__info__item-change details-customer__personal-details-actions__item"
                    onClick={() =>
                      openRightModal(
                        {
                          name: "changeAddress",
                          title: "Change Address",
                          subTitle: `${item.name.first} ${item.name.last}`,
                          image: item.picture.medium,
                        },
                        null,
                        item
                      )
                    }
                  >
                    <FormattedMessage {...messages.change} />
                    <img src={documentIcon} alt="document icon" />
                  </div>
                </div>
              </div>
            </div>

            {/* Actions Customer */}
            <div className="details-customer__personal-details-actions">
              <div className="details-customer__personal-details-actions__item">
                <DialogModal
                  textCallIn={<FormattedMessage {...messages.resetPassword} />}
                  dialogtitle={
                    <FormattedMessage {...messages.passwordResetConfirmation} />
                  }
                  btntext={<FormattedMessage {...messages.resetPassword} />}
                  // Add btnAction request for reset password customer
                  secondBtnText={<FormattedMessage {...messages.cancel} />}
                  contentText={<FormattedMessage {...messages.resetPasswordInfo} />}
                />
              </div>
              <div
                className="details-customer__personal-details-actions__item"
                onClick={() => {
                  openRightModal({
                    name: "trustedDevices",
                    title: "List of Trusted Devices",
                    subTitle: `${item.name.first} ${item.name.last}`,
                    image: item.picture.medium,
                  });
                }}
              >
                <FormattedMessage {...messages.trustedDevices} />
              </div>
              <div
                className="details-customer__personal-details-actions__item"
                onClick={() => {
                  openRightModal({
                    name: "changeStatus",
                    title: "Change User Account Status",
                    subTitle: `${item.name.first} ${item.name.last}`,
                    image: item.picture.medium,
                  });
                }}
              >
                <FormattedMessage {...messages.changeStatus} />
              </div>
              <div className="details-customer__personal-details-actions__item">
                <FormattedMessage {...messages.validationResult} />
              </div>
            </div>
          </div>

          {/* Wallets & Cards Customer */}
          <div className="details-customer__wallets">
            {/* Wallets & Cards Title Customer */}
            <div className="details-customer__wallets-title">
              <FormattedMessage {...messages.walletsAndCards} />
              <div className="details-customer__wallets-title-actions">
                <div className="details-customer__wallets-title-actions__item">
                  <FormattedMessage {...messages.ratesAndFees} />
                </div>
                <div className="details-customer__wallets-title-actions__item">
                  <FormattedMessage {...messages.transactionHistory} />
                </div>
              </div>
            </div>

            {/* Wallets & Cards Table */}
            <div className="details-customer__wallets__table">
              {/* Table Header */}
              <div className="details-customer__wallets__table-header">
                <div className="details-customer__wallets__table-header__item">
                  <FormattedMessage {...messages.name} />
                </div>
                <div className="details-customer__wallets__table-header__item">
                  <FormattedMessage {...messages.id} />
                </div>
                <div className="details-customer__wallets__table-header__item">
                  <FormattedMessage {...messages.type} />
                </div>
                <div className="details-customer__wallets__table-header__item">
                  <FormattedMessage {...messages.currency} />
                </div>
                <div className="details-customer__wallets__table-header__item">
                  <FormattedMessage {...messages.balance} />
                </div>
                <div className="details-customer__wallets__table-header__item">
                  <FormattedMessage {...messages.status} />
                </div>
                <div className="details-customer__wallets__table-header__item" />
              </div>

              {/* Table Body */}
              <div className="details-customer__wallets__table-body">
                {listItems(cards, getCardIconBank, item)}
                {listItems(wallets, getWalletIconByName, item)}
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

// Types
CustomersTableItem.propTypes = {
  item: PropTypes.object.isRequired,
};

// Redux
const mapStateToProps = state => ({
  getWalletIconByName: name => getWalletIconByName(name),
  getCardIconBank: name => getCardIconBank(name),
});

const mapDispatchToProps = {
  openRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomersTableItem);
