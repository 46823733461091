import React from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import {addNewRegularCard} from "shared/cardService/actions";

import {fetchAllRegularCurrency} from "shared/cardService/actions";
import "./index.scss";
import FormSectionSeparator from "shared/components/FormSectionSeparator";

import messages from "../../messages";
import Select from "shared/components/Select";
import Button from "shared/components/Button";
import {CountryOption, BankAccountOption} from "shared/components/CardWalletOptions";

import config from "shared/config";
const defaultBankIcon = config.prefixImgUrl + "default-bank-icon.svg";

const Countries = [{title: "United States of America", value: "USA"}];
const BankAccounts = [
  {
    name: "Bank Name 1",
    icon: defaultBankIcon,
  },
  {
    name: "Bank Name 2",
    icon: defaultBankIcon,
  },
  {
    name: "Bank Name 3",
    icon: defaultBankIcon,
  },
];
const initialValues = {
  country: null,
  bankAccount: null,
};

const AddBankAccountSchema = Yup.object().shape({
  country: Yup.object()
    .nullable()
    .default(null)
    .required("Country is required"),
  bankAccount: Yup.object()
    .nullable()
    .default(null)
    .required("Bank Account is required"),
});

const AddBankAccount = () => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddBankAccountSchema}
      onSubmit={(values, actions) => {}}
    >
      {({errors, touched}) => (
        <Form className="AddBankAccount">
          <FormSectionSeparator sectionName={messages.accountDetails} />
          <Field
            name="country"
            render={({field}) => (
              <div className="countryInputContainer">
                <label className="label">
                  <FormattedMessage {...messages.country} />
                </label>
                <Select
                  className="select"
                  error={touched.country && errors.country ? errors.country : ""}
                  {...field}
                  renderValue={value =>
                    !!value ? <CountryOption className="short" country={value} /> : ""
                  }
                >
                  {Countries.map((country, index) => (
                    <CountryOption key={index} value={country} country={country} />
                  ))}
                </Select>
              </div>
            )}
          />
          <Field
            name="bankAccount"
            render={({field}) => (
              <div className="countryInputContainer">
                <label className="label">
                  <FormattedMessage {...messages.bank} />
                </label>
                <Select
                  className="select"
                  error={
                    touched.bankAccount && errors.bankAccount ? errors.bankAccount : ""
                  }
                  {...field}
                  renderValue={value =>
                    !!value ? <BankAccountOption className="short" bank={value} /> : ""
                  }
                >
                  {BankAccounts.map((bank, index) => (
                    <BankAccountOption key={index} value={bank} bank={bank} />
                  ))}
                </Select>
              </div>
            )}
          />
          <Button text={messages.linkAccount} />
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = store => ({});

const mapDispatchToProps = {
  addNewRegularCard,
  fetchAllRegularCurrency,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddBankAccount);
