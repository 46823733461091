import React from "react";
import {connect} from "react-redux";
// Components
import RightModal from "shared/components/RightModal";
import RatesAndFeesModal from "./RatesAndFeesModal";

// Types modals
const modalVariants = {
  ratesAndFees: "ratesAndFees",
};

const DashboardAdminDetailsModals = ({modalData}) => {
  if (modalData === null) {
    modalData = {
      title: "none",
      name: "none",
      sub: "none",
    };
  }

  return (
    <>
      {modalData.title && (
        <RightModal
          title={modalData && modalData.title ? modalData.title : null}
          subTitle={modalData && modalData.subTitle ? modalData.subTitle : null}
          image={modalData && modalData.image ? modalData.image : null}
        >
          {/* Define of the child by type */}
          <div>
            {modalData.name === modalVariants.ratesAndFees && <RatesAndFeesModal />}
          </div>
        </RightModal>
      )}
    </>
  );
};

const mapStateToProps = store => ({
  modalData: store.rightModalData.variant,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardAdminDetailsModals);
