/**
 * @author: Seleznyov
 */

import React from "react";
import {FormattedMessage} from "react-intl";
import OrderDetailsModals from "./components/OrderDetailsModal";
import Header from "shared/components/Header";

import messages from "./messages";
import "./index.scss";

import config from "shared/config";
import OrderRequests from "./components/OrderRequests";
import AcceptedOrders from "./components/AcceptedOrders";
const logoPage = config.prefixImgUrl + "icon-orders-metalsupply-top.svg";

const OrdersPage = () => {
  return (
    <>
      <Header
        headerTitle={<FormattedMessage {...messages.ordersTitle} />}
        navLinks={[]}
        logoImage={logoPage}
      />

      <div className="orders-page">
        <OrderDetailsModals />
        <OrderRequests />
        <AcceptedOrders />
      </div>
    </>
  );
};

export default OrdersPage;
