import {defineMessages} from "react-intl";

const scope = "OrdersPage";

const messages = defineMessages({
  orderHistory: {
    id: `${scope}.orderHistory`,
    defaultMessage: "ORDER HISTORY",
  },
  orderList: {
    id: `${scope}.orderList`,
    defaultMessage: "My Orders",
  },
  openedOrder: {
    id: `${scope}.openedOrders`,
    defaultMessage: "Opened Orders",
  },
  cancelSelected: {
    id: `${scope}.cancelSelected`,
    defaultMessage: "CANCEL SELECTED",
  },
  tableHeaderDate: {
    id: `${scope}.tableHeaderDate`,
    defaultMessage: "Date",
  },
  tableHeaderPair: {
    id: `${scope}.tableHeaderPair`,
    defaultMessage: "Pair",
  },
  tableHeaderSide: {
    id: `${scope}.tableHeaderSide`,
    defaultMessage: "Side",
  },
  tableHeaderPrice: {
    id: `${scope}.tableHeaderPrice`,
    defaultMessage: "Price",
  },
  tableHeaderAmount: {
    id: `${scope}.tableHeaderAmount`,
    defaultMessage: "Amount",
  },
  tableHeaderFilled: {
    id: `${scope}.tableHeaderFilled`,
    defaultMessage: "Filled",
  },
  tableHeaderTotal: {
    id: `${scope}.tableHeaderTotal`,
    defaultMessage: "Total",
  },
  tableHeaderActions: {
    id: `${scope}.tableHeaderActions`,
    defaultMessage: "Actions",
  },
  tableCellActions: {
    id: `${scope}.tableCellActions`,
    defaultMessage: "CANCEL",
  },
  handleSelectAll: {
    id: `${scope}.handleSelectAll`,
    defaultMessage: "Handle Select All",
  },
});

export default messages;
