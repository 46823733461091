export const OPEN_ERROR_MODAL = "OPEN_ERROR_MODAL";
export const CLOSE_ERROR_MODAL = "CLOSE_ERROR_MODAL";

export const closeError = () => ({
  type: CLOSE_ERROR_MODAL,
});

export const handleError = (error, option) => dispatch => {
  dispatch({
    type: OPEN_ERROR_MODAL,
    payload: {
      error,
      mainAction: option && option.mainAction ? option.mainAction : null,
      secondAction: option && option.secondAction ? option.secondAction : null,
    },
  });
};
