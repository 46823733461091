import {combineReducers} from "redux";

import {historyData} from "store/historyData";
import {localeData} from "shared/i18n/reducer";
import {cryptoWalletsData, thirdWalletsData} from "shared/walletService/reducer";
import {
  bankCardsData,
  receiveCardData,
  transferCardData,
  bankAccountData,
} from "shared/cardService/reducer";
import {authData} from "shared/auth/reducer";
import {errorModalData} from "shared/components/ErrorModal/reducer";
import {cartData} from "shared/cartService/reducer";
import {transactionsHistoryData, balanceHistoryData} from "shared/historyService/reducer";
import {rightModalData} from "shared/components/RightModal/reducer";
import {currencyRatesData} from "shared/ratesService/reducer";
import {fileViewerData} from "shared/components/FileViewer/reducer";
import {qrCodeModalData} from "../shared/components/QRCodeModal/reducer";
import {contactsModalData} from "components/ContactsPage/components/ContactsDetailsModal/reducer";
import {
  dataTradingWidgetsTradeHistory,
  dataTradingWidgetsOrders,
  dataTradingWidgets,
  marketData,
  dataTradingWidgetsOrdersHistory,
  dataTradingGraph,
  tradingChartData,
} from "../shared/tradeService/reducer";
import {dataActionsHistory} from "../shared/actionsHistory/reducer";
import {messagesData} from "shared/messagesService/reducer";
import {shoppingReducer, shoppingAddCard} from "shared/shoppingService/reducer";
import {dataContacts} from "shared/contacts/reducer";

export default combineReducers({
  historyData,
  authData,
  localeData,
  cryptoWalletsData,
  bankCardsData,
  errorModalData,
  cartData,
  receiveCardData,
  transactionsHistoryData,
  balanceHistoryData,
  rightModalData,
  transferCardData,
  bankAccountData,
  thirdWalletsData,
  currencyRatesData,
  fileViewerData,
  qrCodeModalData,
  contactsModalData,
  dataTradingWidgets,
  dataTradingWidgetsOrders,
  dataTradingWidgetsTradeHistory,
  dataTradingGraph,
  marketData,
  dataTradingWidgetsOrdersHistory,
  tradingChartData,
  dataActionsHistory,
  messagesData,
  shoppingReducer,
  shoppingAddCard,
  dataContacts,
});
