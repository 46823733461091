import {forIn} from "lodash";

export const getTokenData = store => {
  if (!store.authData.tokenData) return null;
  return store.authData.tokenData;
};

export const storeUserInfo = store => {
  if (!store.authData.userData) return null;
  return store.authData.userData;
};

export const isAuthLoading = store => {
  return store.authData.isLoading;
};

export const isUserAuth = store => {
  if (!store.authData.tokenData) return false;
  return true;
};

export const selectUserProfile = store => {
  return !!store.authData.userProfile ? store.authData.userProfile : null;
};

export const getUserDevices = store => {
  return store.authData.userDevices ? store.authData.userDevices : [];
};

export const getUserMainAvatar = store => {
  return store.authData.userAvatar ? store.authData.userAvatar : null;
};

export const getCountriesWithDocument = store => {
  return !!store.authData.countriesWithDoc
    ? forIn(store.authData.countriesWithDoc, value => {
        value.forEach(item => (item.documentType = item.documentType.replace(/_/g, " ")));
      })
    : null;
};

/**
 *
 * @param store
 * @returns {null|string} company || private
 * @author: Seleznyov
 */
export const userType = store => {
  if (!store.authData.userProfile) return null;
  let _roles = store.authData.userProfile.roles;
  if (_roles.length && _roles.indexOf("Advanced") !== -1) return "company";
  else return "private";
};

/**
 * @author: Seleznyov
 */
export const userProfileRoles = store => {
  if (!store.authData.userProfile) return null;
  return store.authData.userProfile.roles;
};

export const getGeneratedPassPhrase = store => {
  if (!store.authData.generatedPassPhrase) return null;
  return store.authData.generatedPassPhrase;
};
