/**
 * @author: Seleznyov
 */
import React, {Component} from "react";
import SearchInput from "shared/components/SearchInput";
import CustomDateRangePicker from "../../../../shared/components/DateRangePicker";
import CustomSelect from "shared/components/Select";
import messages from "../../messages";
import {FormattedMessage} from "react-intl";

class FilterTools extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      dataStart: null,
      dataEnd: null,
      originator: null,
      originatorList: {},
    };
  }
  render() {
    return (
      <div className="actions-log-filter-section">
        <div className="table-ui">
          <div className="table-ui__row">
            <div className="table-ui__item item-left">
              <label className="label">
                <FormattedMessage {...messages.searchById} />
              </label>
              <SearchInput
                value={this.state.search}
                onChange={e => this.setState({search: e.target.value})}
                placeholder="Search By ID"
              />
            </div>
            <div className="table-ui__item item-left">
              <CustomDateRangePicker
                onChange={value => {
                  this.setState({dataStart: value.start});
                  this.setState({dataEnd: value.end});
                }}
                title={messages.period}
              />
            </div>
            <div className="table-ui__item item-left">
              <label className="label">
                <FormattedMessage {...messages.originator} />
              </label>
              <CustomSelect
                className="coin-registry-body__select__item"
                value={this.state.originator}
                onChange={e => this.setState({originator: e.target.value})}
                renderValue={this.state.originatorList}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FilterTools;
