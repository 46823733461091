import React, {useState} from "react";
import {FormattedMessage} from "react-intl";
import moment from "moment";
// Components
import TransactionMonitorFilters from "../TransactionMonitorFilters";
import TransactionMonitorTable from "../TransactionMonitorTable";
// Utils
import messages from "../../messages";
import config from "shared/config";
// Styles
import "./index.scss";
// Images
const arrowLeftIcon = config.prefixImgUrl + "left-arrow-icon-active.svg";

const mockData = [
  {
    source: {
      main: "OnWallet Account #1",
      sub: "Michael Hansen, ID 12345",
    },
    recipient: {
      main: "OnWallet Account #1",
      sub: "Jane Swanson, ID 12345",
    },
    status: {
      main: "Pending status description lorem ipsum",
      sub: "Optional transaction description goes here",
    },
    date: "20/12/2020",
    amount: 500.0321,
    currency: "USD",
  },
  {
    source: {
      main: "OnWallet Account #1",
      sub: "Michael Hansen, ID 12345",
    },
    recipient: {
      main: "OnWallet Account #1",
      sub: "Jane Swanson, ID 12345",
    },
    status: {
      main: "Pending status description lorem ipsum",
      sub: "Optional transaction description goes here",
    },
    date: "20/12/2020",
    amount: 500.321,
    currency: "USD",
  },
  {
    source: {
      main: "OnWallet Account #1",
      sub: "Michael Hansen, ID 12345",
    },
    recipient: {
      main: "OnWallet Account #1",
      sub: "Jane Swanson, ID 12345",
    },
    status: {
      main: "Pending status description lorem ipsum",
      sub: "Optional transaction description goes here",
    },
    date: "20/12/2020",
    amount: 500.3213,
    currency: "USD",
  },
];

const TransactionMonitorBody = ({lastTransactionPage = false}) => {
  const [search, setSearch] = useState("");
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const [currency, setCurrency] = useState("All");
  const [amountFrom, setAmountFrom] = useState("");
  const [till, setTill] = useState("");
  const [page, setPage] = useState(1);

  const inc = () => setPage(page + 1);

  return (
    <>
      <div className="content transaction-monitor-body">
        <div className="transaction-monitor-body__titles">
          <div className="transaction-monitor-body__titles-main">
            {lastTransactionPage ? (
              `Transactions as of ${moment().format("Do MMMM, YYYY hh:mm")}`
            ) : (
              <FormattedMessage {...messages.pendingTransactions} />
            )}
          </div>
          <div className="transaction-monitor-body__titles-sub blue-text">
            <FormattedMessage {...messages.resetFilters} />
          </div>
        </div>

        <div className="transaction-monitor-body__items">
          <TransactionMonitorFilters
            lastTransactionPage={lastTransactionPage}
            search={search}
            currency={currency}
            amountFrom={amountFrom}
            till={till}
            setAmountFrom={setAmountFrom}
            setTill={setTill}
            setCurrency={setCurrency}
            setSearch={setSearch}
            setDateStart={setDateStart}
            setDateEnd={setDateEnd}
          />

          {lastTransactionPage && (
            <div className="transaction-monitor-body__paginator">
              <div className="transaction-monitor-body__paginator__item">
                <img src={arrowLeftIcon} alt="back" onClick={() => setPage(page + 1)} />
                <div className="transaction-monitor-body__paginator__item-text">{`Page ${page}`}</div>
                <img src={arrowLeftIcon} className="next" alt="next" onClick={inc} />
              </div>
              <div className="transaction-monitor-body__paginator__item">
                Items on page:
                <div className="blue-text">{mockData.length}</div>
              </div>
            </div>
          )}

          <TransactionMonitorTable
            page={page}
            setPage={setPage}
            dateStart={dateStart}
            dateEnd={dateEnd}
            items={mockData}
          />
        </div>
      </div>
    </>
  );
};

export default TransactionMonitorBody;
