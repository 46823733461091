import React, {useState, useEffect} from "react";
import {connect} from "react-redux";

import Header from "shared/components/Header";
import ContactView from "./components/ContactView";
import {openContactsModal} from "./components/ContactsDetailsModal/actions";
import {getDataContacts} from "shared/contacts/actions";

import messages from "./messages";

import "./index.scss";
// import {routes} from "shared/appUrls";
import {contactListCount, contactList} from "shared/contacts/selectors";

import {FormattedMessage, useIntl} from "react-intl";
import Button from "shared/components/Button";

import config from "shared/config";
import ContactsList from "./components/ContactsList";
import SearchInput from "shared/components/SearchInput";

import {ReactComponent as ContactsSendIcon} from "images/icon-contacts-send.svg";
import {ReactComponent as ContactsAddIcon} from "images/icon-contacts-add.svg";

const ContactsIconHeader = config.prefixImgUrl + "icon-contacts.svg";
const NoContacts = config.prefixImgUrl + "no_contacts.svg";

const ContactsPage = ({
  openContactsModal,
  contactListCount,
  getDataContacts,
  contactList,
}) => {
  const intl = useIntl(); // intl extracted from useIntl hook
  const titleMsg = intl.formatMessage({...messages.input});

  const [selectedContact, setSelectedContact] = useState(null);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading) {
      getDataContacts();
      // Change after demo
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }

    if (selectedContact) {
      const contact = contactList.find(item => item.id === selectedContact.id);
      setSelectedContact(contact);
    }
  }, [isLoading, getDataContacts, contactListCount, selectedContact, contactList]);

  return (
    <>
      <Header
        headerTitle={<FormattedMessage {...messages.header} />}
        navLinks={[]}
        logoImage={ContactsIconHeader}
      >
        <div className="messages__header__count">{contactListCount}</div>
        <div className="messages__header__actionBlock">
          <Button
            type="button"
            text={messages.sendMyContact}
            className="btn-ui btn-bold-black btn-with-icon"
            renderIcon={className => <ContactsSendIcon className={className} />}
            onClick={() => openContactsModal("sendMyContact", "Send My Contact")}
          />
          <Button
            type="button"
            text={messages.addContact}
            className="btn-ui btn-bold-black btn-with-icon"
            renderIcon={className => <ContactsAddIcon className={className} />}
            onClick={() => openContactsModal("addContact", "New Contact")}
          />
          <div className="contact__header__searchWrapper">
            <SearchInput
              value={search}
              onChange={e => setSearch(e.target.value)}
              onClick={() => setSearch("")}
              placeholder={titleMsg}
            />
          </div>
        </div>
      </Header>

      {!isLoading &&
        (contactListCount ? (
          <div className="main-Contacts-page mainContent">
            <div className="contacts__block list">
              <ContactsList
                search={search}
                selectedId={selectedContact ? selectedContact.id : null}
                onSelect={contact => setSelectedContact(contact)}
              />
            </div>
            <ContactView selected={selectedContact} />
          </div>
        ) : (
          <div className="contacts-empty">
            <div className="contacts-empty__img">
              <img src={NoContacts} alt="No contacts" />
            </div>
            <p>{messages.noContactsNow.defaultMessage}</p>
            <Button
              className="contacts-empty__btn"
              type="button"
              text={messages.addContact}
              onClick={() => openContactsModal("addContact", "New Contact")}
            />
          </div>
        ))}
    </>
  );
};

const mapStateToProps = store => ({
  contactListCount: contactListCount(store),
  contactList: contactList(store),
});

const mapDispatchToProps = {
  getDataContacts,
  openContactsModal,
  execute: "MESSAGES_EXECUTE_SUCCESS",
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactsPage);
