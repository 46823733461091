import {defineMessages} from "react-intl";

const scope = "ShoppingCorporate";

const messages = defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: "Shopping",
  },
  corporatecardsheader: {
    id: `${scope}.corporatecardsheader`,
    defaultMessage: "Cards",
  },
  corporatevoucherssheader: {
    id: `${scope}.corporatevoucherssheader`,
    defaultMessage: "Vouchers",
  },
  corporatesendtocustomer: {
    id: `${scope}.corporatesendtocustomer`,
    defaultMessage: "Send to Customer",
  },
  addcard: {
    id: `${scope}.addcard`,
    defaultMessage: "add card",
  },
  addvoucher: {
    id: `${scope}.addvoucher`,
    defaultMessage: "add voucher",
  },
  searchcustomer: {
    id: `${scope}.searchcustomer`,
    defaultMessage: "Search customer",
  },
  cardsoption: {
    id: `${scope}.cardsoption`,
    defaultMessage: "Cards",
  },
  vouchersoption: {
    id: `${scope}.vouchersoption`,
    defaultMessage: "Vouchers",
  },
  sendcardoption: {
    id: `${scope}.sendcardoption`,
    defaultMessage: "Card",
  },
  sendvoucheroption: {
    id: `${scope}.sendvoucheroption`,
    defaultMessage: "Voucher",
  },
  sendtocustomerbutton: {
    id: `${scope}.sendtocustomerbutton`,
    defaultMessage: "send",
  },
  sendtolabel: {
    id: `${scope}.sendtolabel`,
    defaultMessage: "Send to",
  },
  onwalletnumber: {
    id: `${scope}.onwalletnumber`,
    defaultMessage: "OnWallet Account #",
  },
  phonenumber: {
    id: `${scope}.phonenumber`,
    defaultMessage: "Phone #",
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: "E-mail",
  },
  voucherName: {
    id: `${scope}.voucherName`,
    defaultMessage: "Voucher Name",
  },
  ShCompNewShoppingCard: {
    id: `${scope}.ShCompNewShoppingCard`,
    defaultMessage: "New Shopping Card",
  },
  fieldDiscountProc: {
    id: `${scope}.fieldDiscountProc`,
    defaultMessage: "Discount, %",
  },
  fieldIncludedPoints: {
    id: `${scope}.fieldIncludedPoints`,
    defaultMessage: "Included Points",
  },
  fieldCardName: {
    id: `${scope}.fieldCardName`,
    defaultMessage: "Card Name",
  },
  fieldValidityPeriod: {
    id: `${scope}.fieldValidityPeriod`,
    defaultMessage: "Validity Period",
  },

  fieldValidityPeriodSpecifiedDate: {
    id: `${scope}.fieldValidityPeriodSpecifiedDate`,
    defaultMessage: "Specified date",
  },
  fieldValidityPeriodYears: {
    id: `${scope}.fieldValidityPeriodYears`,
    defaultMessage: "Years",
  },
  fieldValidityPeriodMonths: {
    id: `${scope}.fieldValidityPeriodMonths`,
    defaultMessage: "Months",
  },
  fieldValidityPeriodDays: {
    id: `${scope}.fieldValidityPeriodDays`,
    defaultMessage: "Days",
  },
  fieldValidityPeriodUnlimited: {
    id: `${scope}.fieldValidityPeriodUnlimited`,
    defaultMessage: "Unlimited",
  },
  fieldDescription: {
    id: `${scope}.fieldDescription`,
    defaultMessage: "Description (optional)",
  },
  ShCompOptionalDescription: {
    id: `${scope}.ShCompOptionalDescription`,
    defaultMessage: "Write an optional description",
  },
  ShCompCreateCard: {
    id: `${scope}.ShCompCreateCard`,
    defaultMessage: "Create Card",
  },
  saveChanges: {
    id: `${scope}.saveChanges`,
    defaultMessage: "Save Changes",
  },
  createVoucher: {
    id: `${scope}.createVoucher`,
    defaultMessage: "Create Voucher",
  },
  ShCompCancel: {
    id: `${scope}.ShCompCancel`,
    defaultMessage: "Cancel",
  },
  ShCompExpiryDate: {
    id: `${scope}.ShCompExpiryDate`,
    defaultMessage: "Expiry Date",
  },
  ShCompNumberOfYears: {
    id: `${scope}.ShCompNumberOfYears`,
    defaultMessage: "Number of Years",
  },
  ShCompNumberOfMonths: {
    id: `${scope}.ShCompNumberOfMonths`,
    defaultMessage: "Number of Months",
  },
  ShCompNumberOfDays: {
    id: `${scope}.ShCompNumberOfDays`,
    defaultMessage: "Number of Days",
  },
  ShCompBonuses: {
    id: `${scope}.ShCompBonuses`,
    defaultMessage: "Bonuses",
  },
  ShCompDetails: {
    id: `${scope}.ShCompDetails`,
    defaultMessage: "Details",
  },
  ShCompAddCardImage: {
    id: `${scope}.ShCompAddCardImage`,
    defaultMessage: "Add Card Image",
  },
  ShCompOptional: {
    id: `${scope}.ShCompOptional`,
    defaultMessage: "(optional)",
  },
});

export default messages;
