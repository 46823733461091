import {defineMessages} from "react-intl";

const scope = "CardInput";

const messages = defineMessages({
  cardNumber: {
    id: `${scope}.cardNumber`,
    defaultMessage: "Card Number",
  },
  cardHolder: {
    id: `${scope}.cardHolder`,
    defaultMessage: "Card Holder",
  },
  nameHere: {
    id: `${scope}.nameHere`,
    defaultMessage: "Name here",
  },
  expires: {
    id: `${scope}.expires`,
    defaultMessage: "Expires",
  },
  backSide: {
    id: `${scope}.backSide`,
    defaultMessage: "Back side",
  },
  threeDigitsNumber: {
    id: `${scope}.threeDigitsNumber`,
    defaultMessage:
      "3 digit number on VISA, MasterCard and Discover branded cards. On American Express card it is a 4 digit numeric code.",
  },
});

export default messages;
