/**
 * @author: Seleznyov
 */

import React, {Fragment} from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
// Actions
import {openRightModal} from "shared/components/RightModal/actions";
// Components
import Button from "shared/components/Button";
import Header from "shared/components/Header";
import RolesDetailsModals from "./RolesDetailsModals";
import {ReactComponent as RoleAddIcon} from "images/icon-contacts-add.svg";

import messages from "./messages";
import "./index.scss";

import config from "shared/config";
import RolesList from "./components/RolesList";
const logoPage = config.prefixImgUrl + "roles-icon-toptitle.svg";

const RolesPage = ({openRightModal}) => {
  return (
    <Fragment>
      <RolesDetailsModals />
      <Header
        headerTitle={<FormattedMessage {...messages.rolesTitle} />}
        navLinks={[]}
        logoImage={logoPage}
      >
        <div className="messages__header__actionBlock">
          <Button
            type="button"
            text={messages.newRole}
            className="btn-ui btn-bold-black btn-with-icon"
            renderIcon={className => <RoleAddIcon className={className} />}
            onClick={() =>
              openRightModal({
                name: "newRole",
                title: "New Role",
              })
            }
          />
        </div>
      </Header>

      <div className="roles-page">
        <RolesList />
      </div>
    </Fragment>
  );
};

// Redux
const mapStateToProps = state => ({});

const mapDispatchToProps = {
  openRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(RolesPage);
