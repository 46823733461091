export const getPersonalCards = store => {
  return store.shoppingReducer.voucherList
    ? store.shoppingReducer.voucherList.filter(item => item.type === 4)
    : [];
};

export const getPersonalVouchers = store => {
  return store.shoppingReducer.voucherList
    ? store.shoppingReducer.voucherList.filter(item => item.type === 2)
    : [];
};

export const getPersonalPromos = store => {
  return [
    {
      id: "1",
      name: "Star Chemist",
    },
    {
      id: "2",
      name: "The Store",
    },
    {
      id: "3",
      name: "Giant Savers",
    },
    {
      id: "4",
      name: "Makro",
    },
    {
      id: "5",
      name: "Medical",
    },
    {
      id: "6",
      name: "One more Brand",
    },
    {
      id: "7",
      name: "Tesco",
    },
    {
      id: "8",
      name: "JB Hi-Fi",
    },
    {
      id: "9",
      name: "Discount Decor",
    },
  ];
};

export const getVoucherManage = store => {
  return !!store.shoppingReducer.shoppingVoucherData
    ? store.shoppingReducer.shoppingVoucherData
    : null;
};

export const getVoucherQRCode = store => {
  return store.shoppingReducer.voucherQRCode;
};

export const getCardManage = store => {
  return store.shoppingReducer.shoppingCardData;
};

export const getCompanyCards = store => {
  return store.shoppingReducer.voucherList
    ? store.shoppingReducer.voucherList.filter(item => item.type === 4)
    : [];
};

export const getCompanyVouchers = store => {
  return store.shoppingReducer.voucherList
    ? store.shoppingReducer.voucherList.filter(item => item.type === 2)
    : [];
};

/**
 * @author: Seleznyov
 */
export const fieldsAddCard = store => {
  return store.shoppingAddCard;
};
