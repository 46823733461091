import React from "react";

import "./index.scss";
import {ReactComponent as LoadIcon} from "images/logo-loader.svg";

const LoadingPage = () => {
  return (
    <div className="LoadingPageWrapper">
      <div className="LoadingPageLoader">
        <LoadIcon className="LoadIcon" />
      </div>
    </div>
  );
};

export default LoadingPage;
