import React, {useRef, useState} from "react";
import "./index.scss";
import {useClickOutside} from "../../hooks";

const ToggleMenu = ({
  className,
  label,
  children,
  onClick,
  containerStyle,
  labelStyle,
  contentStyle,
}) => {
  const [active, setActive] = useState(false);
  const menuRef = useRef(null);
  useClickOutside(menuRef, () => setActive(false));

  return (
    <div className={`ToggleMenuWrapper ${className ? className : ""}`}>
      <div
        className={`ToggleMenuContainer ${active ? "active" : "closed"} ${
          containerStyle ? containerStyle : ""
        }`}
      >
        <div
          className={`ToggleMenuLabel ${labelStyle ? labelStyle : ""}`}
          onClick={e => {
            if (onClick) onClick(e);
            setActive(true);
          }}
        >
          {label ? label() : ""}
        </div>
        <div
          ref={menuRef}
          className={`ToggleMenuContent ToggleMenuLabel ${
            contentStyle ? contentStyle : ""
          }`}
          // HOT-FIX: Delete after full fix
          onClick={() => setActive(false)}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ToggleMenu;
