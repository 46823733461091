import React, {Component} from "react";
import {connect} from "react-redux";
import messages from "../../messages";
import {FormattedMessage} from "react-intl";
import config from "shared/config";
import {selectUserProfile} from "shared/auth/selectors";
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import {openRightModal} from "shared/components/RightModal/actions";
import PersonalDetailsModals from "components/PersonalDetailsPage/components/PersonalDetailsModals";
import {logout} from "shared/auth/actions";

class LoginSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      changeName: false,
      changeAddress: false,
    };
  }

  componentDidMount() {}

  render() {
    const IconLogOut = config.prefixImgUrl + "account_settings_logOut.svg";
    const userProfile = this.props.userProfile;
    let maskEmail = "Unknown email";
    if (userProfile && userProfile.email) {
      const email = userProfile.email;
      maskEmail =
        email[0] + email[1] + "***@***" + email.slice(email.lastIndexOf(".") - 1);
    }
    return (
      <div className="settings__block left">
        <PersonalDetailsModals
          setChangeName={this.state.changeName}
          setChangeAddress={this.state.changeAddress}
        />

        <div className={"wrapperMain"}>
          <div className="wrapper_header">
            <div className={"header"}>
              <FormattedMessage {...messages.loginSetting} />
            </div>
            <div
              className={"setting_logOut"}
              onClick={() => {
                this.props.logout();
              }}
            >
              <img src={IconLogOut} alt="icon_log_out" />
              <span>
                <FormattedMessage {...messages.logout} />
              </span>
            </div>
          </div>
          <FormSectionSeparator sectionName={messages.login_auth} />
          <div className={"block_settings padd_bottom"}>
            <div className={"email font-16-medium"}>{maskEmail}</div>
            <div className={"text_name"}>E-mail</div>
            <div className={"reset_password"}>
              <div className={"field_pasw"}>
                <div className={"password font-16-medium"}>********</div>
                <button
                  className="button"
                  type="button"
                  onClick={() => this.props.openRightModal("changePassword")}
                >
                  <FormattedMessage {...messages.reset_password} />
                </button>
              </div>
              <div className={"text_name"}>
                <FormattedMessage {...messages.password} />
              </div>
            </div>
          </div>
          <FormSectionSeparator sectionName={messages.two_factor_auth} />
          <div className={"block_settings"}>
            <div className={"reset_password second-block"}>
              <div className={"field_pasw"}>
                <div className={"password font-16-medium"}>
                  {(userProfile && userProfile.phone) || "Unknown phone"}
                </div>
                <button
                  className="button"
                  type="button"
                  onClick={() => this.props.openRightModal("changePhone")}
                >
                  <FormattedMessage {...messages.change} />
                </button>
              </div>
              <div className={"text_name"}>
                <FormattedMessage {...messages.cell_phone_number} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = store => ({
  userProfile: selectUserProfile(store),
});

function mapDispatchToProps(dispatch) {
  return {
    logout: () => {
      dispatch(logout());
    },
    openRightModal: data => {
      dispatch(openRightModal(data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginSettings);
