/* eslint-disable no-implied-eval */
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import moment from "moment";
// Actions
import {getChartData, graphCandle, getChartDataHeader} from "shared/tradeService/actions";
// Selectors
import {
  dataGraph,
  getChartDataTrading,
  getChartDataLoading,
  getChartHeaderData,
} from "shared/tradeService/selectors";
// Components
import Chart from "./Chart";
import Loader from "shared/components/Loader";
// Styles
import "./index.scss";

export const ChartComponent = ({
  market,
  pair,
  chartData,
  getChartData,
  getChartDataHeader,
  chartHeaderData,
  isLoadingChart,
}) => {
  const [initData, setInitData] = useState(true);
  const [request, setRequest] = useState(true);
  const [data, setData] = useState([]);
  const [interval, setInterval] = useState("1MIN");

  // Hook on change market
  useEffect(() => {
    setRequest(true);
  }, [market.code]);

  // Init Chart
  useEffect(() => {
    if (initData) {
      generator();
      setInitData(false);
    }

    if (!handlerCheckerDeho(market.code) && request) {
      // Pair change on pair.code
      getChartDataHeader({coin: market.code, pair: "USDT"});
      getChartData({coin: market.code, limit: 60, interval});
      setRequest(false);
    }
  }, [
    request,
    getChartData,
    initData,
    interval,
    market.code,
    market.coin,
    getChartDataHeader,
    pair,
  ]);

  // Hook on change interval
  useEffect(() => {
    if (!handlerCheckerDeho(market.code) && !request) {
      setData(chartData);
    }
  }, [chartData, interval, market, request]);

  const handlerCheckerDeho = str => {
    return str.includes("DHG") || str.includes("DHS") || str.includes("DHP");
  };

  // Create array random data for chart
  const generator = () => {
    const startDate = moment([2011, 9, 31, 1, 0]).format("YYYY-MM-DD hh:mm");
    let _data = [
      {
        date: new Date(startDate),
        open: 2095.09009,
        high: 2100.98999,
        low: 2085.66992,
        close: 2092.83008,
        volume: 2500,
      },
    ];

    for (let i = 1; i <= 60; i++) {
      const date = moment(_data[i - 1].date)
        .add(1, "minute")
        .format("YYYY-MM-DD hh:mm");
      const _round = Math.floor(Math.random() * 300);

      _data.push({
        date: new Date(date),
        open: _data[i - 1].close,
        high: _round + _data[i - 1].high,
        low: _round + _data[i - 1].low,
        close: _round + _data[i - 1].close + (Math.random() < 0.5 ? -1 : 1) * 200,
        volume: _round + _data[i - 1].volume,
      });
    }

    setData(_data);
  };

  return (
    <div className="graph__chart widget">
      <div className="widget__wrapper">
        <div className="widget__body">
          {/* HEADER CHART */}
          {!!chartHeaderData && (
            <div className="widget__body__head">
              <div className="title-item">
                <div className={"value_item"}>
                  {(chartHeaderData.last && chartHeaderData.last.toFixed(2)) || "00.00"}
                </div>
                <div className={"text_item"}>Last Price</div>
              </div>
              <div className="title-item">
                <div className={"value_item green"}>
                  <span className={chartHeaderData.change < 0 ? "red" : ""}>
                    {chartHeaderData.change.toFixed(2) || "00.00"}
                  </span>
                  {!!chartHeaderData.changePercent && chartHeaderData.changePercent > 0
                    ? "+"
                    : ""}
                  <span className={chartHeaderData.changePercent < 0 ? "red" : ""}>
                    {(!!chartHeaderData.changePercent &&
                      chartHeaderData.changePercent.toFixed(2)) ||
                      "00.00"}
                    %
                  </span>
                </div>
                <div className={"text_item"}>24h Change</div>
              </div>
              <div className="title-item">
                <div className={"value_item"}>
                  {chartHeaderData.high.toFixed(2) || "00.00"}
                </div>
                <div className={"text_item"}>24h High</div>
              </div>
              <div className="title-item">
                <div className={"value_item"}>
                  {chartHeaderData.low.toFixed(2) || "00.00"}
                </div>
                <div className={"text_item"}>24h Low</div>
              </div>
              <div className="title-item">
                <div className={"value_item"}>
                  {chartHeaderData.volume.toFixed(2) || "00.00"} USD
                </div>
                <div className={"text_item"}>24h Volume</div>
              </div>
            </div>
          )}

          {/* BODY HEADER CHART */}
          <div className="widget__body__content">
            <div className={"header_graph"}>
              <div className={"name_graph"}>{market.code} / USDT</div>
              <div className={"actions_graph"}>
                <div className={"timer_switcher_graph"}>
                  <ul>
                    <li>Time</li>
                    <li
                      className={interval === "1MIN" ? "active" : ""}
                      onClick={() => {
                        setInterval("1MIN");
                        if (!handlerCheckerDeho(market.code)) {
                          setRequest(true);
                        } else {
                          generator();
                        }
                      }}
                    >
                      <span>1M</span>
                    </li>
                    <li
                      className={interval === "1DAY" ? "active" : ""}
                      onClick={() => {
                        setInterval("1DAY");
                        if (!handlerCheckerDeho(market.code)) {
                          setRequest(true);
                        } else {
                          generator();
                        }
                      }}
                    >
                      <span>1D</span>
                    </li>
                    <li
                      className={interval === "1WEEK" ? "active" : ""}
                      onClick={() => {
                        setInterval("1WEEK");
                        if (!handlerCheckerDeho(market.code)) {
                          setRequest(true);
                        } else {
                          generator();
                        }
                      }}
                    >
                      <span>1W</span>
                    </li>
                    <li
                      className={interval === "1MONTH" ? "active" : ""}
                      onClick={() => {
                        setInterval("1MONTH");
                        if (!handlerCheckerDeho(market.code)) {
                          setRequest(true);
                        } else {
                          generator();
                        }
                      }}
                    >
                      <span>1M</span>
                    </li>
                  </ul>
                </div>
                <div className={"button_graph_fullscreen"}></div>
              </div>
            </div>

            {/* BODY CHART */}
            {!isLoadingChart ? (
              !!data.length && (
                <div className={"content_graph"}>
                  <Chart data={data} />
                  <div className="volume-label">{"Volume"}</div>
                </div>
              )
            ) : (
              <div className="loader-trading-chart">
                <Loader />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// Types
ChartComponent.propTypes = {
  pair: PropTypes.object,
  market: PropTypes.object.isRequired,
  chartHeaderData: PropTypes.object.isRequired,
  chartData: PropTypes.array.isRequired,
};

// Redux
const mapStateToProps = store => ({
  dataGraph: dataGraph(store),
  chartData: getChartDataTrading(store),
  isLoadingChart: getChartDataLoading(store),
  chartHeaderData: getChartHeaderData(store),
  market: store.marketData.market,
  pair: store.marketData.pair,
});

function mapDispatchToProps(dispatch) {
  return {
    getDataGraph: data => {
      dispatch(graphCandle(data));
    },
    getChartData: data => {
      dispatch(getChartData(data));
    },
    getChartDataHeader: data => {
      dispatch(getChartDataHeader(data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartComponent);
