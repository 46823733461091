import {getTokenData} from "shared/auth/selectors";
import {handleUnAuthorizationError} from "shared/auth/actions";
import {
  createVoucherAPI,
  getVoucherAPI,
  sendVoucherOnWalletAccountAPI,
  voucherTransferOnWalletAPI,
  voucherTransferEmailAPI,
  voucherGetQRCodeAPI,
  // voucherTransferAcceptAPI,
  // voucherTransferRejectAPI,
  removeUserVoucherTransferAPI,
} from "../../api/shoppingApi";
import {
  SET_DATA_CARD_SHOPPING,
  SET_DATA_VOUCHER_SHOPPING,
  GET_VOUCHER_LIST_SHOPPING_SUCCESS,
  GET_VOUCHER_LIST_SHOPPING_FAIL,
  CREATE_VOUCHER_LIST_SHOPPING_SUCCESS,
  CREATE_VOUCHER_LIST_SHOPPING_FAIL,
  SEND_VOUCHER_ONWALLET_ACCOUNT,
  TRANSFER_VOUCHER_SUCCESS,
  TRANSFER_VOUCHER_FAIL,
  GET_VOUCHER_QR_SUCCESS,
  GET_VOUCHER_QR_FAIL,
  DELETE_VOUCHER_USER_SUCCESS,
  DELETE_VOUCHER_USER_FAIL,
} from "./types";

export const shoppingCardModal = data => ({
  type: SET_DATA_CARD_SHOPPING,
  payload: data,
});

export const shoppingVoucherModal = data => ({
  type: SET_DATA_VOUCHER_SHOPPING,
  payload: data,
});

const getOption = getState => {
  const tokenData = getTokenData(getState());

  // Err handler
  if (!tokenData) return;

  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };

  return option;
};

export const getVoucherList = () => (dispatch, getState) => {
  getVoucherAPI(getOption(getState))
    .then(res => {
      dispatch({
        type: GET_VOUCHER_LIST_SHOPPING_SUCCESS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch(handleUnAuthorizationError(err));
      dispatch({
        type: GET_VOUCHER_LIST_SHOPPING_FAIL,
      });
    });
};

export const createVoucher = data => (dispatch, getState) => {
  createVoucherAPI(getOption(getState), data)
    .then(res => {
      dispatch({
        type: CREATE_VOUCHER_LIST_SHOPPING_SUCCESS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch(handleUnAuthorizationError(err));
      dispatch({
        type: CREATE_VOUCHER_LIST_SHOPPING_FAIL,
      });
    });
};

export const sendVoucherOnWalletAccount = data => (dispatch, getState) => {
  sendVoucherOnWalletAccountAPI(getOption(getState), data)
    .then(() => {
      dispatch({
        type: SEND_VOUCHER_ONWALLET_ACCOUNT,
      });
    })
    .catch(err => {
      dispatch(handleUnAuthorizationError(err));
      dispatch({
        type: GET_VOUCHER_LIST_SHOPPING_FAIL,
      });
    });
};

export const voucherTransferOnWallet = data => (dispatch, getState) => {
  voucherTransferOnWalletAPI(getOption(getState), data)
    .then(() => {
      dispatch({type: TRANSFER_VOUCHER_SUCCESS});
    })
    .catch(err => {
      dispatch({type: TRANSFER_VOUCHER_FAIL});
      console.warn(err);
    });
};

export const voucherTransferEmail = data => (dispatch, getState) => {
  voucherTransferEmailAPI(getOption(getState), data)
    .then(() => {
      dispatch({type: TRANSFER_VOUCHER_SUCCESS});
    })
    .catch(err => {
      dispatch({type: TRANSFER_VOUCHER_FAIL});
      console.warn(err);
    });
};

export const voucherGetQRCode = (voucherId, merchantId) => (dispatch, getState) => {
  voucherGetQRCodeAPI(getOption(getState), voucherId, merchantId)
    .then(res => {
      dispatch({type: GET_VOUCHER_QR_SUCCESS, payload: res.data});
    })
    .catch(err => {
      dispatch(handleUnAuthorizationError(err));
      dispatch({type: GET_VOUCHER_QR_FAIL});
    });
};

export const voucherUserDelete = (voucherId, merchantId) => (dispatch, getState) => {
  removeUserVoucherTransferAPI(getOption(getState), voucherId, merchantId)
    .then(() => {
      dispatch({type: DELETE_VOUCHER_USER_SUCCESS, payload: voucherId});
    })
    .catch(err => {
      dispatch(handleUnAuthorizationError(err));
      dispatch({type: DELETE_VOUCHER_USER_FAIL});
    });
};
