import React from "react";
import {connect} from "react-redux";

import moment from "moment";

import config from "shared/config";

import MessageElement from "shared/components/MessageElement";
import {Link} from "react-router-dom";

import {getMessageList, getUnReadNumber} from "shared/messagesService/selectors";
import {markAsRead, markAllRead, getMessages} from "shared/messagesService/actions";
import SimplePopover from "shared/components/Popover";

import "./index.scss";
import {routes} from "shared/appUrls";

const expandIcon = config.prefixImgUrl + "right-arrow.svg";

const LatestEvents = ({
  messagesList,
  markAsRead,
  markAllRead,
  unReadNumber,
  getMessages,
}) => {
  React.useEffect(() => {
    getMessages();
  }, [getMessages]);

  const [selected, setSelected] = React.useState(false);
  const [selectedMessage, setSelectedMessage] = React.useState(null);

  const handleClick = () => {
    setSelected(!selected);
  };

  return (
    <>
      <div className="latest-events widget">
        <div className="widget__header">
          <div className="widget__header__title">
            <h2>Latest Events {unReadNumber}</h2>
          </div>
          <div className="widget__header__tools">
            <div className="Action" onClick={() => markAllRead()}>
              {"MARK ALL AS READ"}
            </div>
          </div>
        </div>
        <div className="widget__body">
          {messagesList &&
            messagesList
              .map(
                message =>
                  message && (
                    <SimplePopover
                      element={selectedMessage}
                      elementId={message.id}
                      key={message.id}
                      children={
                        <MessageElement
                          key={message.id}
                          onClick={e => {
                            setSelectedMessage({
                              value: e.currentTarget,
                              id: message.id,
                            });
                            handleClick(message, e);
                          }}
                          selected={selected && selected.id === message.id}
                          status={!message.isRead}
                          time={moment(message.time).format("HH:MM")}
                          heading={message.title}
                          messageBody={message.content}
                        />
                      }
                      title={message.title}
                      date={moment(message.time).format("DD MMMM, YYYY h:mm")}
                      content={message.content}
                      messageKey={message.key}
                    />
                  )
              )
              .reverse()
              .slice(0, 4)}
        </div>
      </div>
      <Link className="more-latest-events-button" to={routes.messages}>
        <span>{"all messages"}</span>
        <img alt="" src={expandIcon} />
      </Link>
      <Link className="more-latest-events-button" to={routes.actionsHistory}>
        <span>{"transactions history"}</span>
        <img alt="" src={expandIcon} />
      </Link>
    </>
  );
};

const mapStateToProps = store => ({
  messagesList: getMessageList(store),
  unReadNumber: getUnReadNumber(store),
});

function mapDispatchToProps(dispatch) {
  return {
    getMessages: data => {
      dispatch(getMessages(data));
    },
    markAsRead: data => {
      dispatch(markAsRead(data));
    },
    markAllRead: data => {
      dispatch(markAllRead(data));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(LatestEvents);
