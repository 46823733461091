import React, {Fragment} from "react";
import {connect} from "react-redux";
import {Formik, Form, Field} from "formik";
// import FormattedMessage from "react-intl";
// Actions
import {closeRightModal} from "shared/components/RightModal/actions";
// Components
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import Input from "shared/components/Input";
import Button from "shared/components/Button";
// Utils
import messages from "../../messages";
// Styles
import "./index.scss";

const RatesAndFeesModal = ({closeRightModal}) => {
  const initFormik = {
    monthly: "0.00 USD",
    onwalletTransaction: "0.00%",
    dehoTransaction: "0.00%",
    onlineShopping: "0.00%",
    dehoTrading: "1.75%",
    cryptoTrading: "1.75%",
    bankTransaction: "3.00%",
    cryptoCurrencyConversions: "3.00%",
    currencyConversions: "3.00%",
  };

  const inputsName = [
    "Monthly fee",
    "OnWallet transaction fee",
    "Deho transaction fee",
    "Online shopping fee",
    "Flat charge on Deho trading",
    "Flat charge on other crypto trading",
    "Charge on global card & bank transactions",
    "Flat fee on crypto currency conversions",
    "Flat fee on fiat currency conversions",
  ];

  const brokerCommission = [
    {name: "1000 - 10,000 coins", value: "1.75%"},
    {name: "10,000 - 100,000 coins", value: "2.50%"},
    {name: "100,000 - 500,000 coins", value: "3.50%"},
    {name: "500,000 - 1000,000 coins", value: "5.00%"},
    {name: "1000,000 - 10,000,000 coins", value: "7.00%"},
    {name: "more than 10,000,000 coins", value: "10.00%"},
  ];

  return (
    <Fragment>
      <Formik initialValues={initFormik} onSubmit={values => console.log(values)}>
        {({values, setFieldValue, errors}) => (
          <Form>
            <div className="rates-and-fees">
              <div className="rates-and-fees__info mt-5">
                The following rates apply to all customers by default. custom rates can be
                applied at customer's page.
              </div>

              {/* First Section */}
              <FormSectionSeparator sectionName={messages.allCustomers} />

              {Object.keys(initFormik).map((item, index) => (
                <Field name={item} key={index}>
                  {({field}) => (
                    <div className="rates-and-fees__inputs__item">
                      <Input
                        error={errors[item]}
                        className="rates-and-fees__inputs__item-input"
                        {...field}
                      />
                      <div className="rates-and-fees__inputs__item-name">
                        {inputsName[index]}
                      </div>
                    </div>
                  )}
                </Field>
              ))}

              {/* Second section */}
              <FormSectionSeparator sectionName={messages.brokerCommission} />

              <div className="rates-and-fees__info mt-4">
                For purchase of coins the customer receives Commission Fee:
              </div>

              {brokerCommission.map((item, index) => (
                <div className="rates-and-fees__inputs__item">
                  <Input
                    className="rates-and-fees__inputs__item-input"
                    value={item.value}
                  />
                  <div className="rates-and-fees__inputs__item-name">{item.name}</div>
                </div>
              ))}

              <div className="rates-and-fees__actions mt-5">
                <Button
                  type="button"
                  text={messages.cancel}
                  isSecondary="true"
                  onClick={closeRightModal}
                />

                <Button type="sumbit" text={messages.saveChanges} />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

// Redux
const mapDispatchToProps = {
  closeRightModal,
};

export default connect(null, mapDispatchToProps)(RatesAndFeesModal);
