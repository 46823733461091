import React from "react";
import {connect} from "react-redux";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import {FormattedMessage} from "react-intl";
import {closeRightModal} from "shared/components/RightModal/actions";

import "./index.scss";
import messages from "../../messages";
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import Select from "shared/components/Select";
import Button from "shared/components/Button";
import {addCartItem} from "shared/cartService/actions";
import {getAllRegularCurrency, getBankCards} from "shared/cardService/selectors";
import {CardOption} from "shared/components/CardWalletOptions";
import Textarea from "shared/components/Input/Textarea";
import {
  BankDetailsForm,
  CardAmountWithCurrency,
  DetectRegularCard,
  FeeRate,
  SendRecipient,
} from "./components";

const initialValues = {
  sourceCard: null,
  recipientName: null,
  recipientAccountNumber: "",
  email: "",
  amount: "0",
  currency: "USD",
  description: "",
  sendReceipt: false,
};

const BankDetailsFromCardSchema = Yup.object().shape({
  sourceCard: Yup.object()
    .nullable()
    .default(null)
    .required("Source card is required"),
  recipientName: Yup.string(),
  recipientAccountNumber: Yup.string(),
  email: Yup.string().email("Email must be valid"),
  amount: Yup.string()
    .required("Amount is required")
    .matches(
      "([1-9]\\d*(\\.\\d*[1-9])?|0\\.\\d*[1-9]+)|\\d+(\\.\\d*[1-9])?",
      "Amount must be a number"
    ),
  currency: Yup.string().required("Currency is required"),
});

const BankDetailsFromCardForm = ({
  isSending,
  bankCards,
  allCurrency,
  addCartItem,
  formTabs,
  cardNumber,
  closeRightModal,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={BankDetailsFromCardSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, {setSubmitting}) => {}}
    >
      {({errors, touched, values}) => (
        <div className="CardToCardTransferForm">
          <DetectRegularCard
            field="sourceCard"
            cardNumber={cardNumber}
            allCards={bankCards}
          />
          <Form className="form">
            <FormSectionSeparator sectionName={messages.source} />
            {formTabs && formTabs()}
            <Field name="sourceCard">
              {({field}) => (
                <div className="cardInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.sourceOfTransfer} />
                  </label>
                  <Select
                    {...field}
                    renderValue={value =>
                      !!value ? <CardOption className="short" card={value} /> : ""
                    }
                  >
                    {bankCards.map((card, index) => (
                      <CardOption
                        key={index}
                        className="currency-m"
                        value={card}
                        card={card}
                      />
                    ))}
                  </Select>
                </div>
              )}
            </Field>
            <FormSectionSeparator sectionName={messages.recipient} />
            <BankDetailsForm touched={touched} errors={errors} values={values} />
            <SendRecipient touched={touched} errors={errors} values={values} />
            <FormSectionSeparator sectionName={messages.details} />
            <CardAmountWithCurrency
              touched={touched}
              errors={errors}
              allCurrency={allCurrency}
            />
            <Field name="description">
              {({field}) => (
                <div className="descriptionInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.description} />
                  </label>
                  <Textarea type="text" {...field} />
                </div>
              )}
            </Field>
            <SendRecipient touched={touched} errors={errors} values={values} />
            <FeeRate
              card={values.sourceCard}
              amount={values.amount}
              currency={values.sourceCard ? values.sourceCard.currency : null}
            />
            <div className="buttonsContainer">
              <Button
                type="button"
                text={messages.addToCart}
                isSecondary="true"
                onClick={() => {
                  closeRightModal();
                  addCartItem({variant: "cardToCard", type: "transfer", data: values});
                }}
              />
              <Button type="sumbit" text={messages.sendNow} isLoading={isSending} />
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

const mapStateToProps = store => ({
  isSending: store.transferCardData.isLoading,
  bankCards: getBankCards(store),
  allCurrency: getAllRegularCurrency(store),
});

const mapDispatchToProps = {
  addCartItem,
  closeRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(BankDetailsFromCardForm);
