/**
 * @author: Seleznyov
 */
import axios from "axios";
import React, {Component} from "react";
import messages from "../../messages";
import {FormattedMessage} from "react-intl";
import StaffListItem from "./item";

class StaffList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemsStaff: [],
    };
  }

  componentDidMount() {
    this.mockupContacts();
  }

  mockupContacts() {
    axios
      .get("https://randomuser.me/api/?results=10")
      .then(res => {
        this.setState({itemsStaff: res.data.results});
      })
      .catch(e => {
        console.log(e);
      });
  }

  render() {
    return (
      <div className="staff-list-section">
        <div className="card">
          <div className="card__body">
            <div>
              <div className="table-ui">
                <div className="table-ui__header">
                  <div className="table-ui__row">
                    <div className="table-ui__item item-left" />
                    <div className="table-ui__item item-left">
                      <FormattedMessage {...messages.cellName} />
                    </div>
                    <div className="table-ui__item item-left">
                      <FormattedMessage {...messages.cellEmail} />
                    </div>
                    <div className="table-ui__item item-left">
                      <FormattedMessage {...messages.cellRole} />
                    </div>
                    <div className="table-ui__item item-left">
                      <FormattedMessage {...messages.cellId} />
                    </div>
                    <div className="table-ui__item item-left">
                      <FormattedMessage {...messages.cellStatus} />
                    </div>
                    <div className="table-ui__item item-right"></div>
                  </div>
                </div>
                <div className="table-ui__body">
                  {this.state.itemsStaff.map((item, key) => {
                    return <StaffListItem key={key} item={item} />;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StaffList;
