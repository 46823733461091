import faker from "faker";
import moment from "moment";

const empty = new Array(70).fill(null);

const day = empty.map(() => {
  return {
    name: moment(faker.date.past()).format("MM-DD-YYYY h:mm"),
    value: faker.random.number({min: 900, max: 1500}),
    secondValue: faker.random.number({min: 100, max: 1000}),
  };
});

export default day;
