import React, {Fragment} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Formik, Form} from "formik";
import * as Yup from "yup";
// Actions
import {closeRightModal} from "shared/components/RightModal/actions";
// Selectors
import {dataRightModal} from "shared/components/RightModal/selectors";
// Components
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import Button from "shared/components/Button";
import {LegalAddress} from "components/RegisterPage/components/SecondRegisterStep";
// Utils
import {FormattedMessage} from "react-intl";
import messages from "../../messages";
import config from "shared/config";
// Styles
import "./index.scss";
// Images
const eyeIcon = config.prefixImgUrl + "onwallet-eye.svg";

const mockData = ["File name of the bill.jpg"];

const ChangeAddressCustomerModal = ({customer, closeRightModal}) => {
  // Define location customer
  const {country, city, street, postcode} = customer.location;

  const initFormik = {
    documents: mockData,
    country: "",
    zip: "",
    street: "",
    apartment: "",
    region: "",
    city: "",
    bill: "",
  };

  const validationSchema = Yup.object().shape({
    country: Yup.string().required("Country is required"),
    zip: Yup.number()
      .min(5)
      .required("ZIP is required"),
    street: Yup.string().required("Street is required"),
    apartment: Yup.string().required("Apartment is required"),
    region: Yup.string().required("Region is required"),
    city: Yup.string().required("City is required"),
    billFile: Yup.array()
      .nullable()
      .min(1, "Document is required")
      .required(),
    bill: Yup.string().required("Document is required"),
  });

  return (
    <Fragment>
      <Formik
        initialValues={initFormik}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={values => {
          console.log(values);
          closeRightModal();
        }}
      >
        {({values, errors, setFieldValue, isValid}) => (
          <Form>
            <div className="customer-change-address">
              {/* First Section */}
              <div className="customer-change-address__current-address">
                <div className="customer-change-address__current-address__title">
                  <FormattedMessage {...messages.currentAddress} />
                </div>
                <div className="customer-change-address__current-address__info">
                  {`${postcode} ${country}, ${city}, ${street.name}`}
                </div>
              </div>
              <div className="customer-change-address__current-doc">
                <div className="customer-change-address__current-doc__title">
                  <FormattedMessage {...messages.documents} />
                </div>
                <div className="customer-change-address__current-doc__info">
                  {values.documents.map((item, index) => (
                    <div
                      className="customer-change-address__current-doc__info__item"
                      key={index}
                    >
                      <div className="customer-change-address__current-doc__info__item-text">
                        {item}
                      </div>
                      <div className="customer-change-address__current-doc__info__item-image">
                        <img src={eyeIcon} alt="Eye icon" />
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Second Section */}
              <FormSectionSeparator sectionName={messages.newAddress} />

              <div className="customer-change-address__form">
                <LegalAddress
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  hideTitle={true}
                />
              </div>

              <div className="customer-change-address__actions">
                <Button
                  type="button"
                  text={messages.cancel}
                  isSecondary="true"
                  onClick={closeRightModal}
                />
                <Button type="submit" text={messages.applyToChange} />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

// Types
ChangeAddressCustomerModal.propTypes = {
  customer: PropTypes.object.isRequired,
  closeRightModal: PropTypes.func.isRequired,
};

// Redux
const mapStateToProps = state => ({
  customer: dataRightModal(state),
});

const mapDispatchToProps = {
  closeRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeAddressCustomerModal);
