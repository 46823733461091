/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author: Gnapovska
 */

import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

import Header from "shared/components/Header";

import {FormattedMessage} from "react-intl";
import Button from "shared/components/Button";

import ShoppingCards from "../Cards";
import ShoppingVouchers from "../Vouchers";
import ShoppingPromos from "../Promos";

import {ReactComponent as AHPiconManage} from "images/manage-shopping.svg";

import {
  getPersonalCards,
  getPersonalVouchers,
  getPersonalPromos,
} from "shared/shoppingService/selectors";
import {getVoucherList} from "shared/shoppingService/actions";

import messages from "../../messages";

import config from "shared/config";

import CardsInfo from "../CardsInfo";
import VouchersInfo from "../VouchersInfo";
import ShoppingDetailsModals from "../ShoppingDetailsModals";

const ShoppingIcon = config.prefixImgUrl + "shopping-header.svg";
const fromStates = {
  cards: "cards",
  vouchers: "vouchers",
  promo: "promo",
};

const FormTabs = ({from, setFrom}) => {
  return (
    <div className="OptionTabsContainer">
      <div
        className={`SourceTab ${from === fromStates.cards ? "ActiveTab" : ""}`}
        onClick={() => setFrom(fromStates.cards)}
      >
        <FormattedMessage {...messages.cardsoption} />
      </div>
      <div
        className={`SourceTab ${from === fromStates.vouchers ? "ActiveTab" : ""}`}
        onClick={() => setFrom(fromStates.vouchers)}
      >
        <FormattedMessage {...messages.vouchersoption} />
      </div>
      <div
        className={`SourceTab ${from === fromStates.promo ? "ActiveTab" : ""}`}
        onClick={() => setFrom(fromStates.promo)}
      >
        <FormattedMessage {...messages.promooption} />
      </div>
    </div>
  );
};

const Shopping = ({meta, cards, vouchers, promos, getVoucherList}) => {
  const [from, setFrom] = useState(fromStates.cards);

  useEffect(() => {
    if (meta && meta.from) {
      setFrom(meta.from);
    }
  }, [setFrom, meta]);

  useEffect(() => {
    getVoucherList();
  }, []);

  return (
    <>
      <Header
        headerTitle={<FormattedMessage {...messages.header} />}
        navLinks={[]}
        logoImage={ShoppingIcon}
      >
        <div className="messages__header__actionBlock">
          <Button
            type="button"
            text={messages.manage}
            className="btn-ui btn-bold-black btn-with-icon"
            renderIcon={className => <AHPiconManage className={className} />}
          />
        </div>
      </Header>

      <div className="shopping-page">
        <div className="shopping-page__list">
          {from === fromStates.cards && (
            <ShoppingCards
              formTabs={() => <FormTabs from={from} setFrom={setFrom} />}
              personalCards={cards}
            />
          )}
          {from === fromStates.vouchers && (
            <ShoppingVouchers
              formTabs={() => <FormTabs from={from} setFrom={setFrom} />}
              personalVouchers={vouchers}
            />
          )}
          {from === fromStates.promo && (
            <ShoppingPromos
              formTabs={() => <FormTabs from={from} setFrom={setFrom} />}
              personalPromos={promos}
            />
          )}
        </div>
        <div className="shopping-page__selected">
          <ShoppingDetailsModals />
          {from === fromStates.cards && <CardsInfo />}
          {from === fromStates.vouchers && <VouchersInfo />}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = store => ({
  meta: fromStates.cards,
  cards: getPersonalCards(store),
  vouchers: getPersonalVouchers(store),
  promos: getPersonalPromos(store),
});
const dispatchToProps = {
  getVoucherList,
};

export default connect(mapStateToProps, dispatchToProps)(Shopping);
