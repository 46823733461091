import {defineMessages} from "react-intl";

const scope = "MessagesPage";

const messages = defineMessages({
  ordersList: {
    id: `${scope}.ordersList`,
    defaultMessage: "MY ORDERS",
  },
  header: {
    id: `${scope}.header`,
    defaultMessage: "Messages",
  },
  input: {
    id: `${scope}.input`,
    defaultMessage: "Search",
  },
  today: {
    id: `${scope}.today`,
    defaultMessage: "Today",
  },
  showUnReadOnly: {
    id: `${scope}.showUnReadOnly`,
    defaultMessage: "Show Unread only",
  },
  markAllAsRead: {
    id: `${scope}.markAllAsRead`,
    defaultMessage: "mark all as read",
  },
  unreadMessages: {
    id: `${scope}.unreadMessages`,
    defaultMessage: "unread messages",
  },
  showAll: {
    id: `${scope}.showAll`,
    defaultMessage: "Show All",
  },
  searchResult: {
    id: `${scope}.searchResult`,
    defaultMessage: "Search Result",
  },
  reset: {
    id: `${scope}.reset`,
    defaultMessage: "Reset",
  },
  noMessageForNow: {
    id: `${scope}.noMessageForNow`,
    defaultMessage: "No Message for Now",
  },
  goBack: {
    id: `${scope}.goBack`,
    defaultMessage: "Go Back",
  },
});

export default messages;
