import {defineMessages} from "react-intl";

const scope = "LegalDocs";

const messages = defineMessages({
  disabled: {
    id: `${scope}.disabled`,
    defaultMessage: "Disabled",
  },
  legalDocs: {
    id: `${scope}.legalDocs`,
    defaultMessage: "Legal Docs",
  },
  legalDocuments: {
    id: `${scope}.legalDocuments`,
    defaultMessage: "Legal Documents",
  },
  eula: {
    id: `${scope}.eula`,
    defaultMessage: "End User License Agreement",
  },
  agreement: {
    id: `${scope}.agreement`,
    defaultMessage: "Agreement Between Merchant and OnWallet",
  },
  privacy: {
    id: `${scope}.privacy`,
    defaultMessage: "Privacy Policy",
  },
  terms: {
    id: `${scope}.termsAndConditions`,
    defaultMessage: "Terms and Conditions",
  },
  refund: {
    id: `${scope}.refundPolicy`,
    defaultMessage: "Refund Policy",
  },
  cookies: {
    id: `${scope}.cookiesPolicy`,
    defaultMessage: "Cookies Policy",
  },
});

export default messages;
