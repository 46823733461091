import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {daysLeftDay} from "shared/tools";
import {voucherGetQRCode} from "shared/shoppingService/actions";
import {getVoucherQRCode, getVoucherManage} from "shared/shoppingService/selectors";
import {openRightModal} from "shared/components/RightModal/actions";
import Barcode from "react-barcode";
// import {FormattedMessage} from "react-intl";
// import messages from "../../messages";
// import config from "shared/config";
// import PersonalDetailsModals from "components/PersonalDetailsPage/components/PersonalDetailsModals";

// const infoIcon = config.prefixImgUrl + "info-icon-blue.svg";

const VouchersInfo = ({openRightModal, voucherData, voucherGetQRCode, voucherQRCode}) => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    // Handler Error
    if (voucherData === undefined) return false;

    if (isLoading && !!voucherData && voucherData.voucherId && voucherData.merchantId) {
      // Request
      voucherGetQRCode(voucherData.voucherId, voucherData.merchantId);
      setLoading(false);
    }
  }, [isLoading, voucherData, voucherGetQRCode]);

  return (
    <div>
      {!!voucherData && (
        <div className="main_block voucher">
          <div className={"top_block"}>
            <div className={"short_info"}>
              <div className={"data_card"}>
                <div className={"imageLogo"}>
                  <img
                    src="http://www.alluserpics.com/data/media/29/01911.jpg"
                    alt="alt_image"
                  />
                </div>
                <div className={"info"}>
                  {/* Change on brand (wait back-end) */}
                  <h2>{voucherData.title}</h2>
                  <div className={"little_desc"}>{voucherData.description}</div>
                </div>
              </div>
              <div className={"bar_code"}>
                <Barcode
                  value="1234567890"
                  format="CODE128"
                  displayValue={false}
                  width={5}
                  height={32}
                />
              </div>

              <div className={"data_info"}>
                <div className={"card_id"}># 1234567890</div>
                <span className={"valid_till"}>
                  Valid till {daysLeftDay(voucherData.expirationDate)}
                </span>
              </div>
            </div>

            <div className={"action_block"}>
              <button
                className="button"
                type="button"
                onClick={() =>
                  openRightModal({
                    name: "shoppingModalVoucher",
                    title: `${voucherData.title}`,
                    subTitle: "Voucher # 1234567890",
                  })
                }
              >
                Manage
                {/* <FormattedMessage {...messages.reset_password} /> */}
              </button>
            </div>
          </div>

          <div className={"bottom_block"}>
            {/* Need description brand  (wait from back-end) */}
            The North Face® fundamental mission remains unchanged since 1966: Provide the
            best gear for our athletes and the modern day explorer, support the
            preservation of the outdoors, and inspire a global movement of exploration.
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = store => ({
  voucherData: getVoucherManage(store),
  voucherQRCode: getVoucherQRCode(store),
});

function dispatchToProps(dispatch) {
  return {
    openRightModal: data => {
      dispatch(openRightModal(data));
    },
    voucherGetQRCode: (voucherId, merchantId) => {
      dispatch(voucherGetQRCode(voucherId, merchantId));
    },
  };
}

export default connect(mapStateToProps, dispatchToProps)(VouchersInfo);
