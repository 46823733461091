import {defineMessages} from "react-intl";

const scope = "OrdersHistoryPage";

const messages = defineMessages({
  tableHeaderDate: {
    id: `${scope}.tableHeaderDate`,
    defaultMessage: "Date",
  },
  tableHeaderPair: {
    id: `${scope}.tableHeaderPair`,
    defaultMessage: "Pair",
  },
  tableHeaderSide: {
    id: `${scope}.tableHeaderSide`,
    defaultMessage: "Side",
  },
  tableHeaderPrice: {
    id: `${scope}.tableHeaderPrice`,
    defaultMessage: "Price",
  },
  tableHeaderAvarage: {
    id: `${scope}.tableHeaderAvarage`,
    defaultMessage: "Avarage",
  },
  tableHeaderAmount: {
    id: `${scope}.tableHeaderAmount`,
    defaultMessage: "Amount",
  },
  tableHeaderFilled: {
    id: `${scope}.tableHeaderFilled`,
    defaultMessage: "Filled",
  },
  tableHeaderTotal: {
    id: `${scope}.tableHeaderTotal`,
    defaultMessage: "Total",
  },
  tableHeaderStatus: {
    id: `${scope}.tableHeaderStatus`,
    defaultMessage: "Status",
  },
  linkOrdersHistory: {
    id: `${scope}.linkOrdersHistory`,
    defaultMessage: "Orders History",
  },
  closedOrders: {
    id: `${scope}.closedOrders`,
    defaultMessage: "Closed Orders",
  },
  resetFilters: {
    id: `${scope}.resetFilters`,
    defaultMessage: "Reset Filters",
  },
  openedOrders: {
    id: `${scope}.orderHistory`,
    defaultMessage: "MY OPENED ORDERS",
  },
  filterPeriod: {
    id: `${scope}.filterPeriod`,
    defaultMessage: "Period",
  },
  filterPair: {
    id: `${scope}.filterPair`,
    defaultMessage: "Pair",
  },
  filterSide: {
    id: `${scope}.filterSide`,
    defaultMessage: "Side",
  },
});

export default messages;
