import React from "react";
// Selectors
import {getCryptoIconBySymbol} from "shared/walletService/selectors";
// Styles
import "./index.scss";

const CryptoItem = ({cryptoWallet}) => {
  return (
    <>
      <div className="crypto-wallet">
        <div className="onwallet-balance__header">
          <div className="onwallet-balance__header__item">
            <img src={getCryptoIconBySymbol(cryptoWallet.currency)} alt="onWallet logo" />
            <div className="onwallet-balance__header__item-text">
              <div className="onwallet-balance__header__item-text__item">{`${cryptoWallet.balance} ${cryptoWallet.currency}`}</div>
              <div className="onwallet-balance__header__item-text__item">
                {cryptoWallet.name}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CryptoItem;
