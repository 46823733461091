import React from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import "./index.scss";

import {openRightModal} from "shared/components/RightModal/actions";
import {getDehoWallet} from "shared/walletService/selectors";
import {getOnwalletAccount} from "shared/cardService/selectors";

import config from "shared/config";
const BuyDehoIcon = config.prefixImgUrl + "buy-deho-icon.svg";
const TradeDehoIcon = config.prefixImgUrl + "trade-deho-icon.svg";
const SendDehoIcon = config.prefixImgUrl + "send-deho-icon.svg";
const RequestDehoIcon = config.prefixImgUrl + "request-deho-icon.svg";
const AddMoneyIcon = config.prefixImgUrl + "add-money-icon.svg";
const SendMoneyIcon = config.prefixImgUrl + "send-money.svg";
const RequestMoneyIcon = config.prefixImgUrl + "request-money-icon.svg";

const NavBarElement = ({icon, label, onClick}) => {
  return (
    <div className="icon-wrapper" onClick={onClick}>
      <img src={icon} alt="icon" />
      <span>{label}</span>
    </div>
  );
};

const TopNavBar = ({openRightModal, onWalletAccount, dehoWallet}) => {
  const history = useHistory();

  const navBarElements = [
    {
      icon: BuyDehoIcon,
      label: "BUY DEHO",
      onClick: () => openRightModal("exchangeDeho", "deho"),
    },
    {icon: TradeDehoIcon, label: "TRADE DEHO", onClick: () => history.push("/trading")},
    {
      icon: SendDehoIcon,
      label: "SEND DEHO",
      onClick: () =>
        openRightModal("transferOwnWallet", {
          from: "wallet",
          walletAddress: dehoWallet.address,
        }),
    },
    {
      icon: RequestDehoIcon,
      label: "REQUEST DEHO",
      onClick: () => openRightModal("receiveWallet"),
    },
    {
      icon: AddMoneyIcon,
      label: "ADD MONEY",
      onClick: () => openRightModal("transferToOwnOnWallet"),
    },
    {
      icon: SendMoneyIcon,
      label: "SEND MONEY",
      onClick: () => openRightModal("transferToSomeoneOnWallet"),
    },
    {
      icon: RequestMoneyIcon,
      label: "REQUEST MONEY",
      onClick: () => openRightModal("receiveOnWallet"),
    },
  ];

  return (
    <>
      <nav className="top-nav-bar">
        {navBarElements.map((item, index) => (
          <NavBarElement {...item} key={item.icon} />
        ))}
      </nav>
    </>
  );
};

const mapStateToProps = state => ({
  onWalletAccount: getOnwalletAccount(state),
  dehoWallet: getDehoWallet(state),
});

const dispatchToProps = {
  openRightModal,
};

export default connect(mapStateToProps, dispatchToProps)(TopNavBar);
