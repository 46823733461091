import React from "react";
import {connect} from "react-redux";
import {Formik, Form, Field} from "formik";
import {FormattedMessage} from "react-intl";
import {selectUserProfile} from "shared/auth/selectors";
import {closeRightModal} from "shared/components/RightModal/actions";
import {updateUserProfile} from "shared/auth/actions";
import * as Yup from "yup";
import Input from "shared/components/Input";
import Button from "shared/components/Button";
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import PropTypes from "prop-types";
import messages from "../../messages";
import "./index.scss";

const ChangeCompanyName = ({userProfile, updateUserProfile, closeRightModal}) => {
  const initFormik = {
    companyName: userProfile.companyName ? userProfile.companyName : "",
  };

  // Validation schema
  const validationCompanyName = Yup.object().shape({
    companyName: Yup.string()
      .min(3)
      .required("Company name is required"),
  });

  // Submit function
  const handlerSubmit = companyName => {
    const payload = {...userProfile, ...companyName};

    // Request on update user profile
    updateUserProfile(payload);
  };

  return (
    <Formik
      initialValues={initFormik}
      validationSchema={validationCompanyName}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={values => handlerSubmit(values)}
    >
      {({errors, touched}) => (
        <Form className="ChangeDetailsForm">
          <FormSectionSeparator sectionName={messages.newCompanyName} />

          {/* Input change company name */}
          <Field name="companyName">
            {({field}) => (
              <div className="inputContainer">
                <label className="label">
                  <FormattedMessage {...messages.companyName} />
                </label>
                <Input error={touched.companyName && errors.companyName} {...field} />
              </div>
            )}
          </Field>

          {/* Buttons */}
          <div className="buttonsContainer">
            <Button
              type="button"
              text={messages.cancel}
              isSecondary="true"
              onClick={() => {
                closeRightModal();
              }}
            />
            <Button type="submit" text={messages.applyToChange} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

// Types
ChangeCompanyName.propTypes = {
  userProfile: PropTypes.object.isRequired,
  closeRightModal: PropTypes.func.isRequired,
};

// Redux
const mapStateToProps = store => ({
  userProfile: selectUserProfile(store),
});

const mapDispatchToProps = {
  updateUserProfile: userData => updateUserProfile(userData),
  closeRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeCompanyName);
