import React, {Fragment} from "react";
import {
  SECOND_SECTION,
  HOW_DATA_COLLECTED,
  DATA_GIVEN_BY_USERS,
  DATA_COLLECTED_AUTOMATICALLY,
  RIGHTS_OF_USERS,
  RESTRICTED_CONTENT,
} from "./constance";

const PrivacyPolicy = () => {
  return (
    <Fragment>
      <div className="document-wrapper text__container_article">
        <div>
          Onwallet considers privacy as a fundamental right of an individual and we will
          ensure to protect or safeguard the information or data given by to us by the
          users with utmost security. Please read our privacy policy before accessing,
          using, downloading and installing Onwallet app or website. Once you tap the I
          ACCEPT you give your consent to use your personal information by us. We may
          change/review our privacy policy from time to time without giving any prior
          intimation to the users, kindly go through our privacy policy regularly.
        </div>

        <div className="mt-6 text__container_title">1. Definitions: </div>

        <div>
          <span> Website - </span>the website you are currently using, Onwallet, and any
          sub-domain of this site unless expressly excluded by their own terms and
          conditions.
          <br /> <span>Effective date - </span> means the date on which this privacy
          policy has come into force and effect.
          <br /> <span>User or You - </span> for the purpose of this policy, user or you
          means any person who has an access to our website and who is not either (i)
          employed by Onwallet or acting in the course of their employment or (ii) engaged
          in providing services to Onwallet and accessing the website in connection with
          the provisions of such services.
          <br /> <span>Data - </span> the collective information that the user submits via
          the website. This definition incorporates, where applicable, the definition
          provided in the data Protection Laws.
          <br /> <span>Data Protection Laws - </span> any applicable law relating to the
          processing and protection of personal data, including but not limited to any
          national implementing laws, regulations and secondary legislations.
          <br /> <span>Anti Money Laundering - </span> refers to the laws, regulations and
          procedures intended to prevent individuals/companies from disguising illegally
          obtained funds as legitimate income.
          <br /> <span>Applicable law:</span> means all laws, statutes, by-laws, rules,
          regulations, orders, ordinances, protocols, codes, guidelines, policies,
          notices, directions, judgments, decrees, arbitral awards or other requirements
          or official directive of any Governmental Authority or person acting under the
          authority of any Governmental Authority of that respective Country.
          <br /> <span>Governing Law:</span> the rules and laws that govern the agreement
          in case of any legal issues/disputes that arise out of this agreement.
          <br /> A reference to any legislation shall include any statutory modification
          or re-enactment thereof or any statutory provisions substituted therefore.
        </div>

        <div className="mt-6 text__container_title">
          2. In this Privacy policy, the context otherwise requires and different
          interpretation:
        </div>

        <div className="document-wrapper-restrictions__items">
          {SECOND_SECTION.map((item, index) => (
            <div className="document-wrapper-restrictions__items__item" key={index}>
              <div>{item.id}</div>
              <div>{item.text}</div>
            </div>
          ))}
        </div>

        <div className="mt-6 text__container_title">3.Scope of Privacy Policy:</div>
        <div>
          This privacy policy applies to the actions of Onwallet and users with respect to
          this website and it does not extend to any third party websites that can be
          accessed from this website, including but not limited to, any links we may
          provide to any social media websites. For the purposes of applicable Data
          Protection Laws, Onwallet is the “Data Controller,” which determines the
          purposes for which, and the manner in which, your data is processed.
        </div>

        <div className="mt-6 text__container_title">
          4. How Onwallet collects data from Users:
        </div>
        <div>Onwallet collects data in the following ways:</div>
        <div className="document-wrapper-restrictions__items">
          {HOW_DATA_COLLECTED.map((item, index) => (
            <div className="document-wrapper-restrictions__items__item" key={index}>
              <div>{item.id}</div>
              <div>{item.text}</div>
            </div>
          ))}
        </div>

        <div className="mt-6 text__container_title">
          5. Data given to Onwallet by Users:
        </div>
        <div className="document-wrapper-restrictions__items">
          {DATA_GIVEN_BY_USERS.map((item, index) => (
            <div className="document-wrapper-restrictions__items__item" key={index}>
              <div>{item.id}</div>
              <div>{item.text}</div>
            </div>
          ))}
        </div>

        <div className="mt-6 text__container_title">
          6. Data collected and used by Onwallet automatically:
        </div>
        <div>
          We will be using the personal information collected from the users for the
          following purposes:
        </div>
        <div className="document-wrapper-restrictions__items">
          {DATA_COLLECTED_AUTOMATICALLY.map((item, index) => (
            <div className="document-wrapper-restrictions__items__item" key={index}>
              <div>{item.id}</div>
              <div>{item.text}</div>
            </div>
          ))}
        </div>

        <div className="mt-6 text__container_title">7. Security of data:</div>
        <div>
          Onwallet uses technical and organisational measures to safeguard your data and
          store the data provided by the users on secured servers. Technical and
          organisational measures include measures to deal with any suspected data breach.
          If you suspect any misuse or loss or any unauthorised access to you data, please
          let us know immediately by contacting us via this email address-
          info@onwallet.com
        </div>

        <div className="mt-6 text__container_title">8. Data Retention:</div>
        <div>
          Unless a longer retention period is required or permitted by law, we will hold
          you data in our servers for a period necessary to fulfil the purposes outlined
          in this privacy policy or until you request to delete the data. The data deleted
          by us, may persist on backup or archival media for legal, tax or regulatory
          purposes.
        </div>

        <div className="mt-6 text__container_title">9. Rights of Users:</div>
        <div>You have the following rights in relation to your data.</div>
        <div className="document-wrapper-restrictions__items">
          {RIGHTS_OF_USERS.map((item, index) => (
            <div className="document-wrapper-restrictions__items__item" key={index}>
              <div>{item.id}</div>
              <div>{item.text}</div>
            </div>
          ))}
        </div>
        <div className="mt-4">
          To make enquiries, exercise of any rights set out above or withdraw your consent
          to processing of your data (where consent is our legal basis for processing of
          data), please contact us via the email address info@onwallet.com
        </div>

        <div className="mt-6 text__container_title">
          10. Third party Website links and hyperlinks:
        </div>
        <div>
          Our website may from time to time, provide links to other websites. Onwallet
          shall not have any control over those websites and are not responsible for the
          content of such websites. This privacy policy does not extend to your use of
          such websites. Onwallet may share your personal information to the extent if
          necessary to such third parties to fulfil requests associated with our services.
          The third parties with which we share the information includes telecom service
          providers, international and national payment service providers, banking
          institutions and etc.
        </div>

        <div className="mt-6 text__container_title">
          11. Sharing and transferring of Information to Third Parties
        </div>
        <div>
          In the event of sharing your personal information, Onwallet tries and sets
          contractual restrictions with such third parties who ensure that they protect
          your personal information in accordance with the data protection laws applicable
          in your Country. Onwallet shall not guarantee that such third parties will not
          use or disclose your personal information without your consent.
        </div>
        <div className="mt-4">
          We may from time to time require to share your personal information with other
          Countries, including all the Countries inside and outside the UK, based on
          adequacy decisions, corporate rules, standardized contracts and applicable code
          of conduct. In the event of such transfer or sharing of personal information,
          Onwallet ensures appropriate technical and organisational security measures to
          protect the personal information transferred or stored with other Countries is
          in accordance with all the applicable security measures, data protection laws
          and provisions of this privacy policy.
        </div>

        <div className="mt-6 text__container_title">12. Un-solicited Communication:</div>
        <div>Onwallet does not allow the use of its website or services for any </div>
        <ul>
          <li>
            transfer, distribution, sharing or delivery of any mass or bulk spam or junk
            e-mails;{" "}
          </li>
          <li>
            sharing or exchange of any invalid or falsified titles, invalid domain names,
            false or misleading information from and to our customers which use or
            violates the terms of use of our privacy policy.
          </li>
          <li>
            collection of e-mails or of general information of our customers and
            subscribers, via our website or our services.
          </li>
          <li>
            authorize any use of our website and services in a way that could harm or
            burden any part of our services.
          </li>
        </ul>
        <div>
          If Onwallet detects any un-solicited or illegal or invalid use of our website or
          services, Onwallet may, without any caution and assuming absolute discretion,
          take all the necessary steps to block or suspend a specific web domain, server
          or IP address. Onwallet shall be authorized to terminate or suspend immediately
          any account that uses website and services which it considers it to be connected
          with the transmission of any messages that violate this privacy policy
        </div>

        <div className="mt-6 text__container_title">13. Grievance Redressal:</div>
        <div>
          In the event that any users think that their grievances relating to data
          protection have not be adequately addressed by Onwallet, they may lodge a
          complaint with their respective Country’s Data Protection Authority.
        </div>

        <div className="mt-6 text__container_title">14. Anti-Money Laundering Policy</div>
        <div>
          To be in accordance with Anti- Money Laundering Policy and in order to avoid and
          monitor any suspicious transactions, the users are required to complete their
          KYC procedure by submitting their identity proof containing their address. In
          case of any suspicious transaction, Onwallet reserves all the rights to report
          such suspicious/malicious transaction to the respective law enforcement
          agencies/authorities. If we observe any fraudulent, suspicious, malicious
          transactions, illegal activities or any criminal activity, Onwallet may directly
          and without any prior notification do the following immediately:
        </div>
        <ul>
          <li>De-activate their Onwallet account.</li>
          <li>Retain any money available in their Onwallet account.</li>
          <li>Report such transactions to any law enforcement agencies/authorities.</li>
          <li>
            Regulate the user from using or registering their accounts on Onwallet for a
            certain period of time.
          </li>
        </ul>

        <div className="mt-6 text__container_title">15. Acceptance of Risk:</div>
        <div>
          By using, installing or continuing to use our website, mobile application or
          services, you accept and agree to this privacy policy. You further acknowledge
          that no transmission of information or data shared through internet is not
          completely secure. You acknowledge and accept that Onwallet does not guarantee
          the security of any information given to us by you and that you share such
          information at your own risk.
        </div>

        <div className="mt-6 text__container_title">16. Spam Policy</div>
        <div>
          You are strictly prohibited from using the website or any of OnWallet's services
          for any illegal or spam activities including but not limited to gathering email
          addresses and personal information from others or sending any mass commercial
          emails.
        </div>

        <div className="mt-6 text__container_title">
          17. Dispute Resolution and Governing Law:
        </div>
        <div>
          In the event of any complaint or grievance, Onwallet ensures that the same is
          resolved within 10 working days from the date of receiving the complaint or
          grievance. If Onwallet fails to resolve the complaint or grievance within the
          stipulated time due to any unforeseen circumstances and for the reasons not
          attributed to Onwallet, we shall notify the users the reason for such delay and
          try to resolve the complaint or grievance at the earliest, in which case the
          deadline for resolving the complaint shall not exceed 40 working days from the
          date of receipt of such grievance or complaint. In case of any dispute
          resolution relating to the rights and obligations arising out of this privacy
          policy between the user and Onwallet, the user may email regarding the same to
          the following email address info@onwallet.com. Onwallet tries to resolve the
          complaint or grievance amicably by and in the event the dispute stands
          unresolved, both the parties (OnWallet and User) shall mutually agree and
          consent to appoint a Single Arbitrator for resolving the issue at the
          International Arbitration Centre, London located at 190 Fleet St, Holborn,
          London EC4A 2AG, United Kingdom.
          <br /> The validity, construction and performance of this agreement shall be
          governed by English Courts.
        </div>

        <div className="mt-6 text__container_title">
          18. Modifications and Amendment to the Privacy Policy:
        </div>
        <div>
          Onwallet reserves all the rights to modify, amend, change, reverse or otherwise
          revise this privacy policy at any time in any manner, with or without notifying
          the user about the changes. If any clause of this privacy policy requires the
          consent of the user, Onwallet shall notify the user about the same to obtain the
          consent.
        </div>

        <div className="mt-6 text__container_title">19. Restricted Content:</div>
        <div className="document-wrapper-restrictions__items">
          {RESTRICTED_CONTENT.map((item, index) => (
            <div className="document-wrapper-restrictions__items__item" key={index}>
              <div>{item.id}</div>
              <div>{item.text}</div>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default PrivacyPolicy;
