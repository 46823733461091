/**
 * @author: Seleznyov
 */

import React from "react";
import {connect} from "react-redux";
import {userType} from "shared/auth/selectors";
import ShoppingPrivate from "./components/Private";
import ShoppingCompany from "./components/Company";

import "./index.scss";

const Shopping = ({userType}) => {
  let typeComponent;

  if (userType === "private") {
    typeComponent = <ShoppingPrivate />;
  } else if (userType === "company") {
    typeComponent = <ShoppingCompany />;
  }

  return <>{typeComponent}</>;
};

const mapStateToProps = store => ({
  userType: userType(store),
});
const dispatchToProps = {};

export default connect(mapStateToProps, dispatchToProps)(Shopping);
