/**
 * @author: Seleznyov
 */
import React, {Component} from "react";
import messages from "../../messages";
import {FormattedMessage} from "react-intl";
import RolesListItem from "./item";

class RolesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemsRole: [],
    };
  }

  componentDidMount() {
    this.mockupData();
  }

  mockupData() {
    let _data = [
      {
        id: 1,
        name: "Master",
        desc:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      },
      {
        id: 2,
        name: "Manager Lvl 1",
        desc: "Many desktop publishing packages and web page editors now use",
      },
      {
        id: 3,
        name: "Manager Lvl 2",
        desc:
          "Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy",
      },
      {
        id: 4,
        name: "Operator",
        desc:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        id: 5,
        name: "Trial Operator",
        desc:
          "The point of using Lorem Ipsum is that it has a more-or-less normal distribution",
      },
      {
        id: 6,
        name: "Manager Junior",
        desc:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      },
    ];
    this.setState({itemsRole: _data});
  }

  render() {
    return (
      <div className="role-list-section">
        <div className="card">
          <div className="card__body">
            <div>
              <div className="table-ui">
                <div className="table-ui__header">
                  <div className="table-ui__row">
                    <div className="table-ui__item item-left">
                      <FormattedMessage {...messages.cellName} />
                    </div>
                    <div className="table-ui__item item-left">
                      <FormattedMessage {...messages.cellDescription} />
                    </div>
                    <div className="table-ui__item item-left"></div>
                  </div>
                </div>
                <div className="table-ui__body">
                  {this.state.itemsRole.map((item, key) => {
                    return <RolesListItem key={key} item={item} />;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RolesList;
