import {defineMessages} from "react-intl";

const scope = "LandingPage";

const messages = defineMessages({
  easyPayEasyReceive: {
    id: `${scope}.easyPayEasyReceive`,
    defaultMessage: "Easy Pay - Easy Receive",
  },
  someSubheader: {
    id: `${scope}.someSubheader`,
    defaultMessage: "The Most Secured Blockchain Global Crypto and Fiat Platform",
  },
  copyRight: {
    id: `${scope}.copyRight`,
    defaultMessage: "Copyright © 2021 OnWallet. All rights reserved.",
  },
  getOnWalletForPhone: {
    id: `${scope}.getOnWalletForPhone`,
    defaultMessage: "Get OnWallet for your phone:",
  },
  eula: {
    id: `${scope}.eula`,
    defaultMessage: "Eula Agreement",
  },
  eulaLong: {
    id: `${scope}.eulaLong`,
    defaultMessage: "End User License Agreement",
  },
  agreement: {
    id: `${scope}.agreement`,
    defaultMessage: "Agreement",
  },
  agreementLong: {
    id: `${scope}.agreementLong`,
    defaultMessage: "Agreement Between Merchant and OnWallet",
  },
  support: {
    id: `${scope}.support`,
    defaultMessage: "Support",
  },
  privacyPolicy: {
    id: `${scope}.privacyPolicy`,
    defaultMessage: "Privacy Policy",
  },
  termsOfUse: {
    id: `${scope}.termsOfUse`,
    defaultMessage: "Terms of Use",
  },
  termsAndConditions: {
    id: `${scope}.termsAndConditions`,
    defaultMessage: "Terms & Conditions",
  },
  login: {
    id: `${scope}.login`,
    defaultMessage: "Log in",
  },
  loginToOnWallet: {
    id: `${scope}.loginToOnWallet`,
    defaultMessage: "Login to OnWallet",
  },
  forgotYourPassword: {
    id: `${scope}.forgotYourPassword`,
    defaultMessage: "Forgot your password?",
  },
  recoverPassword: {
    id: `${scope}.recoverPassword`,
    defaultMessage: "Recover Password",
  },
  register: {
    id: `${scope}.register`,
    defaultMessage: "Register",
  },
  dontHaveAccountYet: {
    id: `${scope}.dontHaveAccountYet`,
    defaultMessage: "Don't have an account yet?",
  },
  weSentSMS: {
    id: `${scope}.weSentSMS`,
    defaultMessage:
      "We sent on your phone SMS with confirmation pass code. Enter pass code in the fields below.",
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: "Cancel",
  },
});

export default messages;
