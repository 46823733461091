/**
 * @author: Seleznyov and Bazyliuk
 */

import {
  GET_DATA_CONTACTS,
  ADD_CONTACT_SUCCESS,
  ADD_CONTACT_FAILED,
  UPDATE_CONTACT_SUCCESS,
  UPDATE_CONTACT_FAILED,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAILED,
  SHARE_CONTACT_SUCCESS,
  SHARE_CONTACT_FAILED,
} from "./types";
import {
  contactsAPIAddContact,
  contactsAPIGetContacts,
  contactsAPIUpdateContact,
  contactsAPIDeleteContact,
  contactsAPISharedContact,
} from "../../../shared/api/contactApi";
import {getTokenData} from "shared/auth/selectors";
import {handleUnAuthorizationError} from "shared/auth/actions";

const getOption = getState => {
  const tokenData = getTokenData(getState());

  // Err handler
  if (!tokenData) return;

  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };

  return option;
};

// Requests
export const getDataContacts = () => (dispatch, getState) => {
  contactsAPIGetContacts(getOption(getState))
    .then(res => {
      dispatch({
        type: GET_DATA_CONTACTS,
        payload: res.data,
      });
    })
    .catch(error => {
      console.error("error", error);
    });

  return true;
};

export const addContact = contact => (dispatch, getState) => {
  contactsAPIAddContact(contact, getOption(getState))
    .then(res => {
      dispatch({type: ADD_CONTACT_SUCCESS, payload: res.data});
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error));
      dispatch({type: ADD_CONTACT_FAILED});
    });
};

export const updateContact = contact => (dispatch, getState) => {
  contactsAPIUpdateContact(getOption(getState), contact.id, contact)
    .then(res => {
      dispatch({type: UPDATE_CONTACT_SUCCESS, payload: res.data});
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error));
      dispatch({type: UPDATE_CONTACT_FAILED});
    });
};

export const deleteContact = contact => (dispatch, getState) => {
  contactsAPIDeleteContact(getOption(getState), contact.id)
    .then(() => {
      dispatch({type: DELETE_CONTACT_SUCCESS, payload: contact.id});
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error));
      dispatch({type: DELETE_CONTACT_FAILED});
    });
};

export const shareContact = data => (dispatch, getState) => {
  contactsAPISharedContact(getOption(getState), data.contactId, data)
    .then(() => {
      dispatch({type: SHARE_CONTACT_SUCCESS, payload: data});
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error));
      dispatch({type: SHARE_CONTACT_FAILED});
    });
};
