import {defineMessages} from "react-intl";

const scope = "TextPage";

const messages = defineMessages({
  ordersList: {
    id: `${scope}.ordersList`,
    defaultMessage: "MY ORDERS",
  },
  header: {
    id: `${scope}.header`,
    defaultMessage: "Trading Rules",
  },
  input: {
    id: `${scope}.input`,
    defaultMessage: "Search",
  },
});

export default messages;
