import axios from "axios";
import {CryptoEndpoints} from "./apiEndpoints";

export const cryptoAPiGetAvailableWallets = option => {
  return axios.get(CryptoEndpoints.cryptoWalletAvailable, {
    ...option,
  });
};

export const cryptoAPiGetAllCryptoCurrency = option => {
  return axios.get(CryptoEndpoints.cryptoCurrencyAll, {
    ...option,
  });
};

export const cryptoAPIGetAllCryptoWallets = option => {
  return axios.get(CryptoEndpoints.cryptoWalletAll, {
    ...option,
  });
};

export const cryptoAPICreateWallet = (data, option) => {
  return axios.post(CryptoEndpoints.cryptoWallet, data, {
    ...option,
  });
};

export const cryptoAPITransferCrypto = (data, option) => {
  return axios.post(CryptoEndpoints.cryptoWalletTransfer, data, {
    ...option,
  });
};

export const cryptoAPITransferCryptoToCard = (data, option) => {
  return axios.post(CryptoEndpoints.transferCryptoToCard, data, {
    ...option,
  });
};

export const cryptoAPITransferCardToCrypto = (data, option) => {
  return axios.post(CryptoEndpoints.transferCardToCrypto, data, {
    ...option,
  });
};

export const cryptoAPITransferOnWalletToCrypto = (data, option) => {
  return axios.post(CryptoEndpoints.transferOnWalletToCrypto, data, {
    ...option,
  });
};

export const cryptoAPITransferCryptoToOnWallet = (data, option) => {
  return axios.post(CryptoEndpoints.transferCryptoToOnWallet, data, {
    ...option,
  });
};

export const cryptoAPIWalletDelete = (currency, option) => {
  return axios.delete(CryptoEndpoints.walletDelete(currency), {
    ...option,
  });
};
