import React from "react";
import "./index.scss";
import Checkbox from "@material-ui/core/Checkbox";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";

const rootStyles = makeStyles({
  root: {
    padding: "0",
  },
});

const useStyles = makeStyles({
  icon: {
    borderRadius: 2,
    width: 18,
    height: 18,
    backgroundColor: "#f5f8fa",
    backgroundImage: "#0080FF",

    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
      border: "1px solid #2B56F5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      backgroundColor: "#1F333D",
      opacity: "0.5",
      border: "none",
    },
  },
  checkedIcon: {
    backgroundColor: "#0080FF",
    "&:before": {
      display: "block",
      width: 11,
      height: 9,
      backgroundImage: "url('http://d12yjp1rg5mty4.cloudfront.net/check-icon.svg')",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#2B56F5",
      border: "1px solid #2B56F5",
    },
  },
});

const CustomCheckbox = ({className, value, onChange, ...props}) => {
  const classes = useStyles();
  const rootClasses = rootStyles();
  return (
    <Checkbox
      checked={value}
      onClick={e => {
        onChange(e);
      }}
      classes={rootClasses}
      checkedIcon={
        <div className={clsx("CustomCheckbox", classes.icon, classes.checkedIcon)} />
      }
      icon={<div className={clsx("CustomCheckbox", classes.icon)} />}
      {...props}
    />
  );
};

export default CustomCheckbox;
