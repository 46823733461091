import axios from "axios";
import {RatesEndpoints} from "./apiEndpoints";

export const getAllCurrencyRates = option => {
  return axios.get(RatesEndpoints.currencyRates, {
    ...option,
  });
};

export const getCurrencyRate = (option, data) => {
  return axios.post(RatesEndpoints.getCurrencyRate, data, {
    ...option,
  });
};

export const getCurrencyRatesTable = (option, data) => {
  return axios.post(RatesEndpoints.getCurrencyRatesTable, data, {
    ...option,
  });
};
