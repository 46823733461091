export const decimal2digits = value => {
  return (Math.round(Number(value) * 100) / 100).toFixed(2);
};

export const daysLeftDay = nextDate => {
  const today = new Date();
  const endDate = new Date(nextDate);
  // The number of milliseconds in one day
  const msPerDay = 24 * 60 * 60 * 1000;
  // We calculate the number of days
  const daysLeft = Math.round((endDate.getTime() - today.getTime()) / msPerDay);

  if (daysLeft > 1) {
    if (daysLeft >= 365) {
      if (daysLeft >= 365 * 2) return "2 years";
      if (daysLeft >= 365 * 3) return "3 years";

      return "1 year";
    }

    if (daysLeft < 365) {
      if (30 % daysLeft < 1) return `${daysLeft} days`;
      if (Math.round(daysLeft / 31) === 12) return "1 year";
      if (Math.round(daysLeft / 31) === 0) return `${daysLeft} days`;

      return `${Math.round(daysLeft / 31)} months`;
    }
  } else {
    return `${daysLeft} day`;
  }
};
