import React from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
// Components
import Header from "shared/components/Header";
import DownHeader from "./components/DownHeader";
import DashboardTransactions from "./components/DashboardTransactions";
import OnWalletBalance from "./components/OnWalletBalance";
import ChartComposition from "./components/Chart";
import CryptoBlock from "./components/CryptoBlock";
import DehoStats from "./components/DehoStats";
import DashboardAdminDetailsModals from "./DashboardAdminModals";
// Utils
import config from "shared/config";
import messages from "./messages";
// Styles
import "./index.scss";
// Images
const logoPage = config.prefixImgUrl + "dashboard-header-icon.svg";

const mockData = [
  {name: "Balancer", status: "ok"},
  {name: "Rabbitmq", status: "ok"},
  {name: "Elk", status: "ok"},
  {name: "Main db", status: "ok"},
  {name: "Elasticsearch", status: "ok"},
  {name: "Idp webapi", status: "ok"},
  {name: "Webapi", status: "ok"},
  {name: "Crypto db", status: "error"},
];

const DashboardAdmin = () => {
  return (
    <>
      <Header
        headerTitle={<FormattedMessage {...messages.headerDashboardTitle} />}
        navLinks={[]}
        logoImage={logoPage}
        middleStyle="coin-registry-header"
      >
        <div className="info">
          <div className="info-title">internal:</div>
          <div className="internal">
            {mockData.map((item, index) => (
              <div
                className={`internal__item ${
                  item.status === "error" ? "internal-error" : ""
                }`}
                key={index}
              >
                <div
                  className={`internal__item-name ${
                    item.status === "error" ? "internal-error" : ""
                  } `}
                >
                  {item.name}
                </div>
                <div
                  className={`internal__item-status ${
                    item.status === "error" ? "internal-error" : ""
                  }`}
                >
                  {item.status}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Header>

      {/* Controller modals */}
      <DashboardAdminDetailsModals />

      {/* Down header */}
      <DownHeader />

      {/* Body */}
      <div className="dashboard-admin-body content">
        <div className="dashboard-admin-body-left">
          <OnWalletBalance />
          <ChartComposition />
          <CryptoBlock />
          <DehoStats />
          <div className="dashboard-admin-body-left__title">
            <div>Reserved Crypto on Merchants' Accounts</div>
            <div className="blue-text">Exchange Crypto</div>
          </div>
          <CryptoBlock />
        </div>
        <div className="dashboard-admin-body-right">
          <DashboardTransactions />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardAdmin);
