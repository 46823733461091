const initialState = {
  filterVars: {
    keyword: "",
    source: "",
    period: "",
    operation: "All",
    amount: 0,
    amountFrom: "",
    amountTo: "",
  },
};

export default initialState;
