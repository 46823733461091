import React from "react";
import {connect} from "react-redux";
import {Formik, Form, Field} from "formik";
import PropTypes from "prop-types";
// Components
import DetailsBlock from "./components/DetailsBlock";
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import CustomTabs from "shared/components/CustomTabs";
import Select from "shared/components/Select";
import {WalletShortOption, CardOption} from "shared/components/CardWalletOptions";
// Selectors
import {getBankCards} from "shared/cardService/selectors.js";
import {getCurrencyRates} from "shared/ratesService/selectors";
// Utils
import {FormattedMessage} from "react-intl";
import messages from "../../messages";
// Styles
import "./index.scss";

const NewTransferCustomerModal = ({customer, currencyRates, cards}) => {
  const initFormik = {
    source: "",
    tabs: ["OnWallet", "Crypto", "Card"],
    activeTab: 0,
    typeValue: "wallet",
    // Insert normal data from back-end
    wallets: [
      {walletName: "Bitcoin", currency: "BTC", balance: 2000, address: "dDSA3244Xrfhk32"},
      {walletName: "Deho Gold", currency: "DHG", balance: 3000, address: "794LFcxXgjlry"},
    ],
    onwalletAccount: {account: "123456789", balance: 1000, currency: "usd"},
    cryptoAccount: null,
    card: "",
    amount: 0,
    resultAmount: "0 USD",
    description: "",
    currentCustomer: false,
    sendReceipt: false,
    cards,
  };

  const handleDefineType = data => {
    if (!!data.currency) {
      switch (data.currency.toUpperCase()) {
        case "USD":
          return <CardOption card={data} value={data} />;
        default:
          return <WalletShortOption wallet={data} />;
      }
    }
  };

  return (
    <>
      <Formik initialValues={initFormik} onSubmit={values => console.log(values)}>
        {({values, errors, touched, setFieldValue}) => (
          <Form>
            <div className="customer-transfer">
              <FormSectionSeparator sectionName={messages.source} />

              {/* Select */}
              <Field name="source">
                {({field}) => (
                  <div className="customer-transfer__source">
                    <label className="label">
                      <FormattedMessage {...messages.sourceOfTransfer} />
                    </label>
                    <Select
                      {...field}
                      error={touched.source && errors.source ? errors.source : ""}
                      className="full-width"
                      renderValue={value => handleDefineType(value)}
                    >
                      {/* Crypto wallets */}
                      <div className="customer-transfer__source__block-label">
                        <div className="text">Crypto Wallets</div>
                        <div className="line" />
                      </div>
                      {values.wallets.map((wallet, index) => (
                        <WalletShortOption key={index} value={wallet} wallet={wallet} />
                      ))}
                      {/* Bank Cards */}
                      <div className="customer-transfer__source__block-label">
                        <div className="text">Bank Cards</div>
                        <div className="line" />
                      </div>
                      {values.cards.map((card, index) => (
                        <CardOption key={index} value={card} card={card} />
                      ))}
                    </Select>
                  </div>
                )}
              </Field>

              <FormSectionSeparator sectionName={messages.recipient} />

              {/* TABS */}
              <div className="customer-transfer__tabs">
                <CustomTabs
                  name="activeTab"
                  value={values.activeTab}
                  handleChange={(e, value) => setFieldValue("activeTab", value)}
                  tabs={values.tabs}
                />
              </div>

              {/* Details block */}
              <DetailsBlock
                cards={cards}
                values={values}
                errors={errors}
                touched={touched}
                currencyRates={currencyRates}
                setFieldValue={setFieldValue}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

// Types
NewTransferCustomerModal.propTypes = {
  // customer: PropTypes.object.isRequired,
  currencyRates: PropTypes.object.isRequired,
};

// Redux
const mapStateToProps = state => ({
  currencyRates: getCurrencyRates(state),
  cards: getBankCards(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewTransferCustomerModal);
