import {defineMessages} from "react-intl";

const scope = "Trading";

const messages = defineMessages({
  time: {
    id: `${scope}.time`,
    defaultMessage: "Time",
  },
  tradeHistory: {
    id: `${scope}.tradeHistory`,
    defaultMessage: "Trade History",
  },
  total: {
    id: `${scope}.total`,
    defaultMessage: "Total",
  },
  amount: {
    id: `${scope}.amount`,
    defaultMessage: "Amount",
  },
  price: {
    id: `${scope}.price`,
    defaultMessage: "Price",
  },
  orders: {
    id: `${scope}.orders`,
    defaultMessage: "Orders",
  },
  ordersList: {
    id: `${scope}.ordersList`,
    defaultMessage: "MY ORDERS",
  },
  header: {
    id: `${scope}.header`,
    defaultMessage: "Trading",
  },
});

export default messages;
