export default {
  localStorageTokenKey: "onwallet-refresh-token",
  authConfig: {
    client_id: "OnWallet.WebApi",
    grant_type: "password",
    scope: "OnWallet.WebApi openid offline_access",
    refresh_grant_type: "refresh_token",
  },
  prefixImgUrl: "https://d12yjp1rg5mty4.cloudfront.net/",
  REACT_APP_RAZOR_SCRIPT: "https://checkout.razorpay.com/v1/checkout.js",
  REACT_APP_RAZOR_PAY_KEY: "rzp_test_JE0IK42ktHckET",
  REACT_APP_SERVER_URL_RAZOR_PAY: "http://localhost:1337/v1/settings/razorpay",
};
