import React, {Component} from "react";

class TimerShow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      elapsed: 10,
    };
  }

  componentDidMount() {
    this.setState({elapsed: parseInt(this.props.times, 10)});
    this.intervalId = setInterval(this.timer.bind(this), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  timer() {
    this.setState({
      elapsed: this.state.elapsed - 1,
    });
    if (this.state.elapsed < 1) {
      clearInterval(this.intervalId);
      this.props.endtime(true);
    }
  }

  render() {
    let date = new Date(null);
    date.setSeconds(this.state.elapsed);
    return <>{date.toISOString().substr(14, 5)}</>;
  }
}

export default TimerShow;
