import React from "react";
import "./index.scss";

const MessageViewElement = ({
  id,
  selected,
  status,
  time,
  date,
  heading,
  messageBodyFull,
  messageKey,
  onClick,
}) => {
  status = 0;
  let isShowButton = messageKey === "contact.share" || messageKey === "profile.share";

  return (
    <div className="message__block view">
      <div className={`message__container_view ${selected ? "true" : ""}`}>
        <div className="message__wrap">
          <div className="message__header">
            <div className="message__fulldate">
              {date} <span>{time}</span>
            </div>
            <div className="message__heading">{heading}</div>
            <div className="message__action_button action__button">...</div>
          </div>
          <div
            id={id}
            className="message__body message__main"
            dangerouslySetInnerHTML={{__html: messageBodyFull}}
          ></div>
        </div>

        {isShowButton && (
          <div className="message__foter">
            {/* <div className="message__action_button action__button">Transfer</div> */}
            {/* <div className="message__action_button action__button">Receive</div> */}

            <div className="message-contact__action">
              <div className="message__action_button action__button" onClick={onClick}>
                Save Contact
              </div>
            </div>

            {/* {!isShowButton && (
            <div className="message__action_button action__button" onClick={onClick}>
              Mark as read
            </div>
          )} */}
          </div>
        )}
      </div>
    </div>
  );
};
export default MessageViewElement;
