import React, {useEffect} from "react";
import {connect} from "react-redux";
import messages from "../../messages";
import {FormattedMessage} from "react-intl";
import "./index.scss";
import HistoryDayWrapper from "./components/HistoryDayWrapper";
import {Scrollbars} from "react-custom-scrollbars";
import {getHistoryDays, getAllTransactions} from "shared/historyService/selectors";
import {fetchTransactions} from "shared/historyService/actions";
import {openRightModal} from "shared/components/RightModal/actions";

const History = ({
  isLoading,
  transactions,
  historyDays,
  fetchTransactions,
  openRightModal,
}) => {
  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);
  return (
    <>
      <div className="walletsPageContentTitle">
        <span>
          <FormattedMessage {...messages.history} />
        </span>
        <span
          className="HistoryFilterButton"
          onClick={() => {
            openRightModal("modalHistoryFilter");
          }}
        >
          <FormattedMessage {...messages.filter} />
        </span>
      </div>
      <div className="ExchangeContainer HistoryContainer">
        <div className="HistoryItemsContainer">
          <Scrollbars
            renderTrackVertical={props => <div {...props} className="track-vertical " />}
            renderThumbVertical={props => <div {...props} className="thumb-vertical " />}
          >
            {historyDays.map((day, index) => (
              <HistoryDayWrapper
                key={index}
                date={day.date}
                totalAmount={day.totalAmount}
                items={day.items}
              />
            ))}
          </Scrollbars>
        </div>
        <div className="AllTransactions">
          <FormattedMessage {...messages.allTransactions} />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = store => ({
  isLoading: store.transactionsHistoryData.isLoading,
  historyDays: getHistoryDays(store),
  transactions: getAllTransactions(store),
});

const mapDispatchToProps = {
  fetchTransactions,
  openRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(History);
