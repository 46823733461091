import faker from "faker";
import moment from "moment";

const empty = new Array(20).fill(null);

const minute = empty.map(() => {
  return {
    name: moment(faker.date.past()).format("MM-DD-YYYY h:mm"),
    value: faker.random.number({min: 1030, max: 1070}),
    secondValue: faker.random.number({min: 100, max: 130}),
  };
});

export default minute;
