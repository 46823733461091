import {defineMessages} from "react-intl";

const scope = "DropZone";

const messages = defineMessages({
  dragAndDrop: {
    id: `${scope}.dragAndDrop`,
    defaultMessage: "Drag and drop here to",
  },
  attachFiles: {
    id: `${scope}.attachFiles`,
    defaultMessage: "Attach files",
  },
});

export default messages;
