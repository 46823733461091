import React from "react";
import {FormattedMessage} from "react-intl";
// Components
import Input from "shared/components/Input";
import SearchInput from "shared/components/SearchInput";
import CustomDateRangePicker from "shared/components/DateRangePicker";
import CustomSelect from "shared/components/Select";
// Utils
import messages from "../../messages";
// Styles
import "./index.scss";

// MockData
const arr = ["All"];

const TransactionMonitorFilters = ({
  search,
  setSearch,
  setDateStart,
  setDateEnd,
  setAmountFrom,
  setTill,
  currency,
  setCurrency,
  lastTransactionPage,
  amountFrom,
  till,
}) => {
  return (
    <>
      <div className="transaction-monitor-filters">
        {/* Search by ID, Coin ID or Owner ID */}
        <div className="transaction-monitor-filters__search transaction-monitor-filters__item">
          <label className="label">
            <FormattedMessage {...messages.searchById} />
          </label>
          <SearchInput
            value={search}
            onChange={e => setSearch(e.target.value)}
            onClick={() => setSearch("")}
            className="coin-registry-body__search__item"
            placeholder="Search By ID"
          />
        </div>

        {/* Data Picker */}
        <div className="transaction-monitor-filters__datepicker transaction-monitor-filters__item">
          <CustomDateRangePicker
            style={{left: 100}}
            onChange={value => {
              setDateStart(value.start);
              setDateEnd(value.end);
            }}
            title={messages.period}
          />
        </div>

        {/* Amount */}
        {lastTransactionPage && (
          <div className="transaction-monitor-filters__amount transaction-monitor-filters__item">
            <div className="input-wrapper__item">
              <div className="label">
                <FormattedMessage {...messages.amountFrom} />
              </div>
              <Input
                type="text"
                value={amountFrom}
                placeholder="0.00"
                onChange={e => setAmountFrom(e.target.value)}
              />
            </div>

            <div className="input-wrapper__item">
              <div className="label">
                <FormattedMessage {...messages.till} />
              </div>
              <Input
                type="text"
                value={till}
                placeholder="0.00"
                onChange={e => setTill(e.target.value)}
              />
            </div>
          </div>
        )}

        {/* Select */}
        <div className="transaction-monitor-filters__select transaction-monitor-filters__item">
          <label className="label">
            <FormattedMessage {...messages.currency} />
          </label>
          <CustomSelect
            className="full-width"
            value={currency}
            options={arr.map(item => item)}
            onChange={e => setCurrency(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};

export default TransactionMonitorFilters;
