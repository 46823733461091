export const OPEN_FILE_VIEWER = "OPEN_FILE_VIEWER";
export const CLOSE_FILE_VIEWER = "CLOSE_FILE_VIEWER";

export const openFileViewer = (file, meta) => ({
  type: OPEN_FILE_VIEWER,
  payload: {
    file,
    meta,
  },
});

export const closeFileViewer = () => ({
  type: CLOSE_FILE_VIEWER,
});
