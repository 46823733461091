/**
 * @author: Seleznyov
 */
import React, {Component} from "react";

class FormatPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valPrice: "",
    };
  }

  componentDidMount() {
    let _val = this.formatNumber((Math.round(this.props.number * 100) / 100).toFixed(2));
    this.setState({valPrice: _val});
  }

  formatNumber(value) {
    if (value > 0) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return 0;
  }

  render() {
    return <span>{this.state.valPrice}</span>;
  }
}

export default FormatPrice;
