import {
  FETCH_CRYPTO_WALLETS_PENDING,
  FETCH_CRYPTO_WALLETS_SUCCESS,
  SET_ACTIVE_CRYPTO_WALLET,
  GET_AVAILABLE_WALLET_SUCCESS,
  GET_AVAILABLE_WALLET_FAILED,
  FETCH_THIRD_WALLETS_PENDING,
  FETCH_THIRD_WALLETS_SUCCESS,
  FETCH_THIRD_WALLETS_FAILED,
  SET_ACTIVE_THIRD_WALLET,
  GET_AVAILABLE_WALLET_PENDING,
  GET_ALL_CRYPTO_CURRENCY_PENDING,
  GET_ALL_CRYPTO_CURRENCY_SUCCESS,
  GET_ALL_CRYPTO_CURRENCY_FAILED,
  CRYPTO_WALLET_DELETE_SUCCESS,
} from "./actions/types";

const initCryptoWalletsState = {
  activeWalletName: null,
  isLoading: false,
  data: null,
  availableWallets: [],
  isAvailableWalletsFetched: false,
  allCryptoCurrency: [],
  isAllCurrencyFetched: false,
};

const cryptoWalletsData = (state = initCryptoWalletsState, action) => {
  switch (action.type) {
    case FETCH_CRYPTO_WALLETS_PENDING:
      return {...state, isLoading: true};
    case FETCH_CRYPTO_WALLETS_SUCCESS:
      return {...state, data: action.payload, isLoading: false};
    case SET_ACTIVE_CRYPTO_WALLET:
      return {...state, activeWalletName: action.payload};
    case GET_AVAILABLE_WALLET_PENDING:
      return {...state, isAvailableWalletsFetched: true};
    case GET_AVAILABLE_WALLET_SUCCESS:
      return {
        ...state,
        availableWallets: action.payload,
        isAvailableWalletsFetched: true,
      };
    case GET_AVAILABLE_WALLET_FAILED:
      return {...state, availableWallets: [], isAvailableWalletsFetched: true};
    case GET_ALL_CRYPTO_CURRENCY_PENDING:
      return {...state, isAllCurrencyFetched: true};
    case GET_ALL_CRYPTO_CURRENCY_SUCCESS:
      return {...state, isAllCurrencyFetched: false, allCryptoCurrency: action.payload};
    case GET_ALL_CRYPTO_CURRENCY_FAILED:
      return {...state, isAllCurrencyFetched: false, allCryptoCurrency: []};
    case CRYPTO_WALLET_DELETE_SUCCESS:
      return {
        ...state,
        data: state.data.filter(wallet => wallet.address !== action.payload),
      };
    default:
      return state;
  }
};

const initThirdWalletsState = {
  isLoading: false,
  thirdWallets: [],
  activeThirdWalletNumber: null,
};

const thirdWalletsData = (state = initThirdWalletsState, action) => {
  switch (action.type) {
    case FETCH_THIRD_WALLETS_PENDING:
      return {...state, isLoading: true};
    case FETCH_THIRD_WALLETS_SUCCESS:
      return {...state, isLoading: false, thirdWallets: action.payload};
    case FETCH_THIRD_WALLETS_FAILED:
      return {...state, isLoading: false, thirdWallets: []};
    case SET_ACTIVE_THIRD_WALLET:
      return {...state, activeThirdWalletNumber: action.payload};
    default:
      return state;
  }
};

export {cryptoWalletsData, thirdWalletsData};
