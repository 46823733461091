import React from "react";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
// Components
import Header from "shared/components/Header";
import TransactionMonitorBody from "./components/TransactionMonitorBody";
// Utils
import config from "shared/config";
import messages from "./messages";
// Styles
import "./index.scss";
// Images
const headerLogo = config.prefixImgUrl + "dashboard-header-icon.svg";
const lastTransaction = config.prefixImgUrl + "clock-icon.svg";

const TransactionMonitorPage = () => {
  return (
    <>
      <Header
        headerTitle={<FormattedMessage {...messages.transactionMonitor} />}
        navLinks={[]}
        logoImage={headerLogo}
        middleStyle="coin-registry-header"
      >
        <Link to="/last-transactions">
          <div className="transaction-monitor-header">
            <img src={lastTransaction} alt="Last transaction" />
            <div className="transaction-monitor-header__text">
              <FormattedMessage {...messages.lastTransaction} />
            </div>
          </div>
        </Link>
      </Header>

      <TransactionMonitorBody />
    </>
  );
};

export default TransactionMonitorPage;
