import {defineMessages} from "react-intl";

const scope = "CoinRegistryPage";

const messages = defineMessages({
  coinRegistry: {
    id: `${scope}.coinRegistry`,
    defaultMessage: "Coin Registry",
  },
  dehoCoins: {
    id: `${scope}.dehoCoins`,
    defaultMessage: "Deho Coins",
  },
  coinCurrency: {
    id: `${scope}.coinCurrency`,
    defaultMessage: "Coin Currency",
  },
  searchById: {
    id: `${scope}.searchById`,
    defaultMessage: "Search by Coin ID or Owner ID",
  },
  coinId: {
    id: `${scope}.coinId`,
    defaultMessage: "Coin ID",
  },
  ownerId: {
    id: `${scope}.ownerId`,
    defaultMessage: "Owner ID",
  },
  totalValue: {
    id: `${scope}.totalValue`,
    defaultMessage: "Total Value in metal",
  },
  linkedMetal: {
    id: `${scope}.linkedMetal`,
    defaultMessage: "Linked Metal",
  },
  refiner: {
    id: `${scope}.refiner`,
    defaultMessage: "Refiner",
  },
  weight: {
    id: `${scope}.weight`,
    defaultMessage: "Weight",
  },
  bullionId: {
    id: `${scope}.bullionId`,
    defaultMessage: "Bullion ID",
  },
  valueInMetal: {
    id: `${scope}.valueInMetal`,
    defaultMessage: "Value in metal",
  },
});

export default messages;
