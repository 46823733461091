import React, {useState, useEffect} from "react";
import {connect} from "react-redux";

import "./index.scss";
import {
  getCurrentCartItems,
  getFailedItems,
  getProcessingItems,
  hasExecuted,
  isEmpty,
  isExecuting,
  isLoading,
} from "shared/cartService/selectors";
import {getShortAddress} from "shared/walletService/selectors";
import {
  executeCartItems,
  executeItemsAgain,
  confirmCartItems,
  removeCartItem,
  removeFailedCartItem,
  clearCart,
} from "shared/cartService/actions";

import Header from "shared/components/Header";
import TransactionsTable from "./components/TransactionsTable";
import CodeInput from "shared/components/CodeInput";
import Loader from "shared/components/Loader";
import Button from "shared/components/Button";
import {Link} from "react-router-dom";

import {routes} from "shared/appUrls";

import {FormattedMessage} from "react-intl";
import messages from "./messages";
import {ReactComponent as LeftArrowIcon} from "images/left-arrow-icon.svg";

import config from "shared/config";
const logoPage = config.prefixImgUrl + "cart-header-logo.svg";
const transferIcon = config.prefixImgUrl + "transfer-icon-active.svg";
const receiveIcon = config.prefixImgUrl + "receive-icon-active.svg";
const exchangeIcon = config.prefixImgUrl + "buy-icon-active.svg";
const leftArrow = config.prefixImgUrl + "left-arrow-icon.svg";
const editIcon = config.prefixImgUrl + "edit-icon.svg";
const deleteIcon = config.prefixImgUrl + "delete-icon.svg";
const expandIcon = config.prefixImgUrl + "arrow-down-transaction.svg";
const cartClearBG = config.prefixImgUrl + "clear_cart_background.svg";

const mapIcons = type => {
  switch (type) {
    case "transfer":
      return transferIcon;
    case "receive":
      return receiveIcon;
    case "exchange":
      return exchangeIcon;
    default:
      return transferIcon;
  }
};

const CartPage = ({
  currentItems,
  isExecuting,
  isEmpty,
  isLoading,
  hasExecuted,
  processItems,
  failedItems,
  execute,
  executeAgain,
  confirm,
  removeItem,
  removeFailedItem,
  clear,
  history,
}) => {
  const [step, setStep] = useState("execution");
  useEffect(() => {
    if (hasExecuted) setStep("confirmation");
  }, [hasExecuted]);
  const navLinks = [
    {
      text: <FormattedMessage {...messages.headerBack} />,
      icon: className => <LeftArrowIcon className={className} />,
      link: routes.wallets,
    },
  ];

  const mapTableRows = rows => {
    return rows.map((row, index) => ({
      cols: [
        <div className="CartTableSource">
          <img src={mapIcons(row.type)} className="icon" alt="icon" />
          <p className="text">
            {row.data && row.data.sourceOnWallet && (
              <>
                <span className="source">{row.data.sourceOnWallet.account}</span>
              </>
            )}
            {row.data && row.data.sourceWallet && (
              <>
                <span className="source">{row.data.sourceWallet.walletName}</span>
                <span className="address">
                  {getShortAddress(row.data.sourceWallet.address)}
                </span>
              </>
            )}
            {row.data && row.data.sourceCard && (
              <>
                <span className="source">
                  <span className="dots" />
                  {row.data.sourceCard["last4"] ? row.data.sourceCard["last4"] : 0}
                </span>
              </>
            )}
          </p>
        </div>,
        <div className="CartTablePurpose">
          {row.data.description ? row.data.description : ""}
        </div>,
        {
          xs: 4,
          md: 4,
          body: (
            <div className="CartTableAmount">
              {row.data && row.data.sourceAmount && row.data.sourceCurrency && (
                <>
                  <p className="target">
                    {row.data.sourceAmount ? row.data.sourceAmount : 0}{" "}
                    {row.data.sourceCurrency}
                  </p>
                </>
              )}
              {row.data && row.data.recipientAmount && row.data.recipientCurrency && (
                <>
                  <div className="icon">
                    <img src={leftArrow} alt="arrow" />
                  </div>
                  <p className="current">
                    {row.data.recipientAmount} {row.data.recipientCurrency}
                  </p>
                </>
              )}
            </div>
          ),
        },
        {
          xs: 2,
          md: 2,
          body: (
            <div className="CartTableActions">
              {row.status && (row.status === "pending" || row.status === "confirmed") ? (
                <p className={`status ${row.status}`}>
                  {row.status === "pending" && <FormattedMessage {...messages.pending} />}
                  {row.status === "confirmed" && (
                    <FormattedMessage {...messages.confirmed} />
                  )}
                </p>
              ) : (
                <>
                  {row.status && row.status === "failed" && (
                    <p className={`status ${row.status}`}>
                      <FormattedMessage {...messages.failed} />
                    </p>
                  )}
                  <div className="button">
                    <img src={editIcon} alt="edit" />
                  </div>
                  <div
                    className="button"
                    onClick={() => {
                      if (row.status === "failed") {
                        removeFailedItem(row.id);
                      } else {
                        removeItem(row.id);
                      }
                    }}
                  >
                    <img src={deleteIcon} alt="delete" />
                  </div>
                </>
              )}
              <div className="button">
                <img src={expandIcon} alt="expand" />
              </div>
            </div>
          ),
        },
      ],
    }));
  };

  const transactionsTableHeader = [
    <FormattedMessage {...messages.source} />,
    <FormattedMessage {...messages.purposeOfPayment} />,
    {
      xs: 4,
      md: 4,
      body: (
        <span className="number">
          <FormattedMessage {...messages.amount} />
        </span>
      ),
    },
    {
      xs: 2,
      md: 2,
      body: (
        <span className="actions">
          <FormattedMessage {...messages.actions} />
        </span>
      ),
    },
  ];
  return (
    <>
      <Header
        headerTitle={<FormattedMessage {...messages.headerCart} />}
        navLinks={navLinks}
        logoImage={logoPage}
      />
      <div className="CartPageWrapper">
        <div className="CartPageContainer">
          {isLoading && <Loader />}
          {!isLoading && isEmpty && (
            <div className="CartPageClearWrapper">
              <img src={cartClearBG} alt="clearBG" />
              <div className="content">
                <h1 className="title">
                  <FormattedMessage {...messages.yourCartEmpty} />
                </h1>
                <p className="text">
                  <FormattedMessage {...messages.youCanAdd} />
                  <Link to={routes.wallets}>
                    <span className="link">
                      <FormattedMessage {...messages.wallets} />
                    </span>
                  </Link>
                  <FormattedMessage {...messages.sectionOrReturn} />
                </p>
                <Button
                  text={messages.goBack}
                  className="button"
                  onClick={() => {
                    history.goBack();
                  }}
                />
              </div>
            </div>
          )}
          {currentItems.length > 0 && !isLoading && (
            <>
              <div className="CartPageHeader">
                <h1 className="header">
                  <FormattedMessage {...messages.transactions} />
                </h1>
                <p
                  className={`clearButton ${step === "confirmation" ? "disabled" : ""}`}
                  onClick={() => clear()}
                >
                  <FormattedMessage {...messages.clearCart} />
                </p>
              </div>
              <div className="CartPageContent">
                <TransactionsTable
                  isLoading={false}
                  headers={transactionsTableHeader}
                  rows={mapTableRows(currentItems)}
                />
              </div>
              {step === "execution" && (
                <div className="CartPageExecute">
                  <Button
                    className="button"
                    text={messages.execute}
                    isLoading={isExecuting}
                    onClick={() => execute()}
                  />
                </div>
              )}
              {step === "confirmation" && (
                <div className="CartPageExecute">
                  <p className="CartPageConfirmText">
                    <FormattedMessage {...messages.weSentSMS} />
                  </p>
                  <div className="CartPageCodeInput">
                    <CodeInput onChange={value => console.log(value)} />
                  </div>
                  <Button
                    className="button"
                    isLoading={isLoading}
                    text={messages.confirm}
                    onClick={() => confirm()}
                  />
                  <p className="cancelButton" onClick={() => setStep("execution")}>
                    <FormattedMessage {...messages.cancel} />
                  </p>
                  <div className="CartPageSendText">
                    <FormattedMessage {...messages.iHaveIssue} />
                    <span className="newCode">
                      <FormattedMessage {...messages.sendNewCode} />
                    </span>
                  </div>
                </div>
              )}
            </>
          )}
          {failedItems.length > 0 && !isLoading && (
            <>
              <div className="CartPageHeader">
                <h1 className="header">
                  <FormattedMessage {...messages.failedTransactions} />
                </h1>
                {currentItems.length === 0 && (
                  <p className={`clearButton`} onClick={() => clear()}>
                    <FormattedMessage {...messages.clearCart} />
                  </p>
                )}
              </div>
              <div className="CartPageContent">
                <TransactionsTable
                  isLoading={false}
                  headers={currentItems.length === 0 ? transactionsTableHeader : null}
                  rows={mapTableRows(failedItems)}
                />
              </div>
              <div className="CartPageExecute">
                <p className="CartPageConfirmText">
                  <FormattedMessage {...messages.someFailed} />
                </p>
                <Button
                  className="button"
                  text={messages.executeAgain}
                  onClick={() => executeAgain()}
                />
              </div>
            </>
          )}
          {processItems.length > 0 && !isLoading && (
            <>
              <div className="CartPageHeader">
                <h1 className="header">
                  <FormattedMessage {...messages.successfulTransactions} />
                </h1>
                {failedItems.length === 0 && currentItems.length === 0 && (
                  <p className={`clearButton`} onClick={() => clear()}>
                    <FormattedMessage {...messages.clearCart} />
                  </p>
                )}
              </div>
              <div className="CartPageContent">
                <TransactionsTable
                  isLoading={false}
                  headers={
                    failedItems.length === 0 && currentItems.length === 0
                      ? transactionsTableHeader
                      : null
                  }
                  rows={mapTableRows(processItems)}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = store => ({
  currentItems: getCurrentCartItems(store),
  isExecuting: isExecuting(store),
  hasExecuted: hasExecuted(store),
  processItems: getProcessingItems(store),
  failedItems: getFailedItems(store),
  isLoading: isLoading(store),
  isEmpty: isEmpty(store),
  history: store.historyData.history,
});

const dispatchToProps = {
  execute: executeCartItems,
  executeAgain: executeItemsAgain,
  confirm: confirmCartItems,
  removeItem: removeCartItem,
  removeFailedItem: removeFailedCartItem,
  clear: clearCart,
};

export default connect(mapStateToProps, dispatchToProps)(CartPage);
