import React from "react";
import {MenuItem} from "@material-ui/core";
import {getCardIconBank, getCardOperatorName} from "shared/cardService/selectors";
import {getCoinNameByCurrency, getWalletIconByName} from "shared/walletService/selectors";
import {getShortAddress} from "shared/walletService/selectors";
import "./index.scss";

import config from "shared/config";
import {decimal2digits} from "../../tools";
const onWalletIcon = config.prefixImgUrl + "onwallet-icon-short_v1.svg";

export const CardOption = React.forwardRef(({card, value, className, ...props}, ref) => {
  return (
    <MenuItem
      value={value}
      className={`cardOption ${className ? className : ""}`}
      {...props}
    >
      <div className="name">
        <img src={getCardIconBank(card.operator)} className="icon" alt="icon" />{" "}
        <p className="operator">
          {card.operator ? getCardOperatorName(card.operator) : ""}
        </p>
      </div>
      <div className="currency">{card.currency ? card.currency.toUpperCase() : ""}</div>
      <div className="number">
        <span className="dots" />
        {card["last4"] ? card["last4"] : 0}
      </div>
    </MenuItem>
  );
});

export const WalletShortOption = React.forwardRef(
  ({wallet, className, ...props}, ref) => {
    return (
      <MenuItem
        value={wallet}
        className={`walletShortOption ${className ? className : ""}`}
        {...props}
      >
        <div className="walletShortOption-prefix">
          <img
            src={getWalletIconByName(getCoinNameByCurrency(wallet.currency))}
            className="walletShortOption-prefix__icon"
            alt="icon"
          />{" "}
          <p className="walletShortOption-prefix__name">
            {wallet.currency ? getCoinNameByCurrency(wallet.currency) : ""}
          </p>
        </div>
        <div className="walletShortOption-prefix__address bold">
          <span className="dots" />
          {wallet.address ? getShortAddress(wallet.address) : 0}
        </div>
      </MenuItem>
    );
  }
);

export const WalletOptionSelected = React.forwardRef(
  ({wallet, className, value, ...props}, ref) => {
    return (
      <MenuItem value={value} className={`walletOption ${className}`} {...props}>
        <div className="title">
          <div className="name">{wallet.walletName}</div>
          <div className="balance">
            {wallet.balance} {wallet.currency}
          </div>
        </div>
        <div className="address">{wallet.address}</div>
      </MenuItem>
    );
  }
);

export const WalletOption = React.forwardRef(
  ({wallet, className, value, ...props}, ref) => {
    return (
      <MenuItem value={value} className={`walletOption ${className}`} {...props}>
        <div className="title">
          <div className="name">
            <img
              className="icon"
              src={getWalletIconByName(wallet.walletName)}
              alt="coin"
            />{" "}
            {wallet.walletName}
          </div>
          <div className="balance">
            {wallet.balance} {wallet.currency}
          </div>
        </div>
      </MenuItem>
    );
  }
);

export const MetalOption = React.forwardRef(
  ({metal, value, className, ...props}, ref) => {
    return (
      <MenuItem value={value} className={`metalOption ${className}`} {...props}>
        {!!metal.img && (
          <img className="icon" src={config.prefixImgUrl + metal.img} alt="metal" />
        )}
        <div className="name">{`${metal.metal} ${
          metal.metal_value ? metal.metal_value : ""
        }`}</div>
      </MenuItem>
    );
  }
);

export const WalletOptionShort = React.forwardRef(
  ({wallet, className, groupHeader, value, ...props}, ref) => {
    return (
      <>
        {groupHeader && <div className="groupSeparator">{groupHeader}</div>}
        <MenuItem value={value} className={`walletOptionShort ${className}`} {...props}>
          {!!getWalletIconByName(wallet.walletName) && (
            <img
              className="icon"
              src={getWalletIconByName(wallet.walletName)}
              alt="coin"
            />
          )}
          <div className="name">{wallet.walletName}</div>
        </MenuItem>
      </>
    );
  }
);

export const WalletFiatOption = React.forwardRef(
  ({className, groupHeader, icon, currency, value, ...props}, ref) => {
    return (
      <>
        {groupHeader && <div className="groupSeparator">{groupHeader}</div>}
        <MenuItem value={value} className={`walletOptionShort ${className}`} {...props}>
          <img className="icon" src={icon} alt="coin" />
          <div className="name">{currency}</div>
        </MenuItem>
      </>
    );
  }
);

export const WalletAddOption = React.forwardRef(
  ({currency, title, className, value, ...props}, ref) => {
    return (
      <MenuItem value={value} className={`walletCurrency ${className}`} {...props}>
        <img
          className="icon"
          src={getWalletIconByName(getCoinNameByCurrency(currency))}
          alt="coin"
        />
        <div className="name">
          {title} ({currency})
        </div>
      </MenuItem>
    );
  }
);

export const OnWalletOption = ({account, className, ...props}, ref) => {
  if (!account) return null;
  return (
    <div className={`OnWalletOption ${className}`} {...props}>
      <div className="number">
        <img className="icon" src={onWalletIcon} alt="coin" />
        {account.account ? account.account : ""}
      </div>
      <div className="balance">
        {account.balance ? decimal2digits(account.balance) : 0}{" "}
        {account.currency ? account.currency.toUpperCase() : ""}
      </div>
    </div>
  );
};

export const SendToOption = React.forwardRef(
  ({currency, title, className, value, ...props}, ref) => {
    return (
      <MenuItem value={value} className={`cardOption ${className}`} {...props}>
        <div className="name">{title}</div>
      </MenuItem>
    );
  }
);

export const CountryOption = React.forwardRef(
  ({country, value, className, ...props}, ref) => {
    return (
      <MenuItem
        value={value}
        className={`cardOption ${className ? className : ""}`}
        {...props}
      >
        <div className="name">{country.title}</div>
      </MenuItem>
    );
  }
);

export const BankAccountOption = React.forwardRef(
  ({bank, value, className, ...props}, ref) => {
    return (
      <MenuItem
        value={value}
        className={`bankOption ${className ? className : ""}`}
        {...props}
      >
        <img className="icon" src={bank.icon} alt="coin" />
        <div className="name">{bank.name}</div>
      </MenuItem>
    );
  }
);

export const ThirdPartyWalletsOption = React.forwardRef(
  ({thirdPartyWallet, className, value, ...props}, ref) => {
    return (
      <MenuItem value={value} className={`walletOption ${className}`} {...props}>
        <div className="title">
          <div className="name">
            <img className="icon" src={thirdPartyWallet.icon} alt="icon" />{" "}
            {thirdPartyWallet.walletName}
          </div>
        </div>
      </MenuItem>
    );
  }
);

export const WalletProviderOption = React.forwardRef(
  ({provider, value, className, ...props}, ref) => {
    return (
      <MenuItem
        value={value}
        className={`providerOption ${className ? className : ""}`}
        {...props}
      >
        <img className="icon" src={provider.icon} alt="coin" />
      </MenuItem>
    );
  }
);
