import React, {useRef} from "react";
import {connect} from "react-redux";
import {closeQRCodeModal} from "./actions";
import "./index.scss";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {useClickOutside} from "../../hooks";
import QRCode from "qrcode.react";

const QRCodeModal = ({show, qr, title, close}) => {
  const refContainer = useRef(null);
  useClickOutside(refContainer, () => close());
  return (
    <div className={`QRCodeModalWrapper ${show ? "show" : "hide"}`}>
      <div ref={refContainer} className="qrContainer">
        <div className="qrContent">
          <p className="title">{title || ""}</p>
          <QRCode size={145} value={qr ? qr : ""} />
          <CopyToClipboard text={qr || ""} onCopy={() => console.log("copied")}>
            <p className="value">{qr || ""}</p>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = store => ({
  show: store.qrCodeModalData.show,
  qr: store.qrCodeModalData.qr,
  title: store.qrCodeModalData.title,
});

const mapDispatchToProps = {
  close: closeQRCodeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeModal);
