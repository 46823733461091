import {defineMessages} from "react-intl";

const scope = "RecoveryPage";

const messages = defineMessages({
  passwordRecovery: {
    id: `${scope}.passwordRecovery`,
    defaultMessage: "Password Recovery",
  },
  withEmail: {
    id: `${scope}.withEmail`,
    defaultMessage: "with E-mail",
  },
  withRecoveryPhrase: {
    id: `${scope}.withRecoveryPhrase`,
    defaultMessage: "with recovery phrase",
  },
  enterYourEmail: {
    id: `${scope}.enterYourEmail`,
    defaultMessage:
      "Enter your email and we'll send you a link to get back into your account.",
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: "Cancel",
  },
  recover: {
    id: `${scope}.recover`,
    defaultMessage: "Recover",
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: "Email",
  },
  recoveryPhrase: {
    id: `${scope}.recoveryPhrase`,
    defaultMessage: "Recovery Phrase",
  },
  enterYouPhrase: {
    id: `${scope}.enterYouPhrase`,
    defaultMessage: "Enter your recovery phrase and email to reset your password.",
  },
  resetPassword: {
    id: `${scope}.resetPassword`,
    defaultMessage: "Reset Password",
  },
  passwordReset: {
    id: `${scope}.passwordReset`,
    defaultMessage: "Password Reset",
  },
  enterNewPassword: {
    id: `${scope}.enterNewPassword`,
    defaultMessage: "Enter and save new password",
  },
  newPassword: {
    id: `${scope}.newPassword`,
    defaultMessage: "New Password",
  },
  confirmNewPassword: {
    id: `${scope}.confirmNewPassword`,
    defaultMessage: "Confirm New Password",
  },
  useBothUppercase: {
    id: `${scope}.useBothUppercase`,
    defaultMessage:
      " Use both upper-case and lower-case letters, one or more numerical digits",
  },
  saveNewPassword: {
    id: `${scope}.saveNewPassword`,
    defaultMessage: "Save new Password",
  },
  checkYourEmail: {
    id: `${scope}.checkYourEmail`,
    defaultMessage: "Check your email box and follow the instructions to reset password.",
  },
});

export default messages;
