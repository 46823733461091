import React from "react";
import {FormattedMessage} from "react-intl";
import "./index.scss";

const TextButton = ({text, type = "submit", className, disabled, ...props}) => {
  return (
    <>
      <button
        type={type}
        className={`CustomTextButton ${disabled ? "disabled" : ""}
        ${className ? className : ""}`}
        {...props}
      >
        <FormattedMessage {...text} />
      </button>
    </>
  );
};

export default TextButton;
