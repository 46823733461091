export const REFUND_POLICY = [
  {
    id: "i.",
    text:
      "any transaction happened between the user and vendor/merchant/seller our App will not at any time warrant any rights, claims or possession of any products or services provided by the merchant/vendor/seller.",
  },
  {
    id: "ii.",
    text:
      "in case of any refunds/cancellations, if any, they will be executed according to the terms and conditions laid down by the vendor/merchant/seller.",
  },
  {
    id: "iii.",
    text:
      "for reversing any transacted amount, unless the vendor/merchant/seller requests for the reversal of the transacted amount, only in that event, our App will reverse the transacted amount to the user.",
  },
  {
    id: "iv.",
    text:
      "If the transaction is relating to the purchase/redemption/sale/transfer of Gold, the relevant taxes will be charged as per the regulations issued by the respective Country's Government. The user is not allowed to redeem and purchase Gold on the same day.",
  },
];
