/**
 * @author: Seleznyov
 */
import React, {Component} from "react";
import {connect} from "react-redux";
// import messages from "../../messages";
// import {FormattedMessage} from "react-intl";
import {openRightModal} from "shared/components/RightModal/actions";
import config from "shared/config";
import FormatPrice from "../../../../shared/components/FormatPrice";

class AcceptedOrdersItem extends Component {
  constructor(props) {
    super(props);

    this.outMetal = this.outMetal.bind(this);

    this.state = {
      src: null,
      status: {
        1: "In Transit",
        2: "Completed",
        3: "Canceled",
      },
      metal: {
        1: {img: "metal-au.svg", title: "Gold 999.9"},
        2: {img: "metal-ag.svg", title: "Silver"},
        3: {img: "metal-pt.svg", title: "Platinum"},
      },
    };
  }

  componentDidMount() {
    // this.loadImage(this.props.item.img);
    this.setState({src: config.prefixImgUrl + this.props.item.img});
  }

  // Transfer to selectors
  outMetal = idx => {
    let _m = this.state.metal[idx];
    return (
      <span className="ordered-metal">
        <img src={config.prefixImgUrl + _m.img} alt={_m.title} />
        {_m.title}
      </span>
    );
  };

  render() {
    let item = this.props.item;
    return (
      <div className="table-ui__row">
        <div className="table-ui__item item-left">{item.id}.</div>
        <div className="table-ui__item item-left">{item.dateTime}</div>
        <div className="table-ui__item item-left">{this.outMetal(item.orderedMetal)}</div>
        <div className="table-ui__item item-right">{item.weight} Kg</div>
        <div className="table-ui__item item-right">
          <FormatPrice number={item.price} /> USD
        </div>
        <div className="table-ui__item item-right">
          <FormatPrice number={item.orderAmount} /> USD{" "}
        </div>
        <div
          className="table-ui__item item-right cursor-pointe"
          onClick={() =>
            this.props.openRightModal(
              {
                name: "orderDetails",
                title: `Order #${item.id} Details`,
                buttonType: "print",
              },
              null,
              item
            )
          }
        >
          {this.state.status[item.status]}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  openRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptedOrdersItem);
