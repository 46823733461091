import React from "react";
import {FormattedMessage} from "react-intl";
import messages from "../../../messages";
// Styles
import "../index.scss";

const CoinRegistryTableHeader = props => {
  return (
    <div className="table-header">
      <div className="table-header__item">
        <FormattedMessage {...messages.coinId} />
      </div>
      <div className="table-header__item">
        <FormattedMessage {...messages.coinCurrency} />
      </div>
      <div className="table-header__item">
        <FormattedMessage {...messages.ownerId} />
      </div>
      <div className="table-header__item">
        <FormattedMessage {...messages.totalValue} />
      </div>
    </div>
  );
};

export default CoinRegistryTableHeader;
