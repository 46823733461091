/**
 * @author: Seleznyov
 */

export const DATA_FAILURE = "DATA_FAILURE";

export const SET_DATA_BUY = "SET_DATA_BUY";
export const ADD_DATA_BUY = "ADD_DATA_BUY";
export const SET_DATA_SEL = "SET_DATA_SEL";
export const ADD_DATA_SEL = "ADD_DATA_SEL";
export const SET_DATA_LASTORDER = "SET_DATA_LASTORDER";
export const REMOVE_DATA_BUY = "REMOVE_DATA_BUY";
export const REMOVE_DATA_SEL = "REMOVE_DATA_SEL";
export const UPDATE_DATA_BUY = "UPDATE_DATA_BUY";
export const UPDATE_DATA_SEL = "UPDATE_DATA_SEL";

export const SET_DATA_TRADEHISTORY = "SET_DATA_TRADEHISTORY";
export const ADD_DATA_TRADEHISTORY = "ADD_DATA_TRADEHISTORY";
export const SET_TYPEVIEW = "SET_TYPEVIEW";
export const SET_LIMITVIEW = "SET_LIMITVIEW";
export const ORDERS_HISTORY_WIDGET = "ORDERS_HISTORY_WIDGET";

export const BUY = 1;
export const SELL = 2;
export const MIX = 3;

export const SelectMarket = "SelectMarket";
export const GRAPH_LOAD_DATA = "GRAPH_LOAD_DATA";
export const GRAPH_HEADER_DATA = "GRAPH_HEADER_DATA";
export const TRADING_SELECT_PAIR = "TRADING_SELECT_PAIR";
export const CHART_PENDING_DATA = "CHART_PENDING_DATA";
export const CHART_SUCCESS_DATA = "CHART_SUCCESS_DATA";
export const CHART_FAIL_DATA = "CHART_FAIL_DATA";
export const CHART_HEADER_SUCCESS_DATA = "CHART_HEADER_SUCCESS_DATA";
export const CHART_HEADER_FAIL_DATA = "CHART_HEADER_FAIL_DATA";
