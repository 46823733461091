import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import "./index.scss";

const DialogModal = ({
  dialogtitle,
  btntext,
  btnAction,
  content,
  contentText,
  icon,
  btnCallIn,
  textCallIn,
  secondBtnText,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {!!btnCallIn && (
        <Button className="ui-btn ui-btn-info" onClick={handleClickOpen}>
          {btnCallIn}
        </Button>
      )}

      {!!textCallIn && <div onClick={handleClickOpen}>{textCallIn}</div>}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialog-modal"
      >
        <DialogTitle id="alert-dialog-title">
          {icon ? <img src={icon} alt="personalLogo" /> : ""}
          {dialogtitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {!!content && <div dangerouslySetInnerHTML={{__html: content}} />}
            {!!contentText && contentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{secondBtnText}</Button>
          <Button onClick={btnAction ? btnAction : handleClose} color="primary">
            {btntext}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogModal;
