/**
 * @author: Seleznyov
 */

import React, {Component} from "react";
// import {string} from "prop-types";
import * as signalR from "@aspnet/signalr";
import {connect} from "react-redux";
import {getTokenData} from "shared/auth/selectors";
import {APILinks} from "shared/appUrls";
import {
  onHandleTradeHistory,
  onCreateTradeUnit,
  onHandleTradeUnit,
  onRemoveTradeUnit,
  onHandleTradeStats,
} from "shared/tradeService/actions";

class signalRCl extends Component {
  constructor(props) {
    super(props);

    this.connection = null;
    this.onNotifReceived = this.onNotifReceived.bind(this);

    this.state = {
      connectionHub: APILinks.socketAPIUrl,
    };
  }

  componentDidMount() {
    const protocol = new signalR.JsonHubProtocol();

    const transport = signalR.HttpTransportType.WebSockets;

    const {access_token} = this.props.accessToken;

    const options = {
      transport,
      logMessageContent: true,
      logger: signalR.LogLevel.Trace,
      accessTokenFactory: () => access_token,
      // useDefaultPath: false,
    };

    // console.log("options", options);
    // console.log("this.state.connectionHub", this.state.connectionHub);

    // create the connection instance
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(this.state.connectionHub, options)
      .withHubProtocol(protocol)
      .build();

    this.connection.on("createTradeUnit", this.props.onCreateTradeUnit);
    this.connection.on("updateTradeUnit", this.props.onHandleTradeUnit);
    this.connection.on("deleteTradeUnit", this.props.onRemoveTradeUnit);
    this.connection.on("handleTradeHistory", this.props.onHandleTradeHistory);
    this.connection.on("handleTradeStats", this.props.onHandleTradeStats);

    this.connection
      .start()
      .then(() => console.info("SignalR Connected"))
      .catch(err => console.error("SignalR Connection Error: ", err));
  }

  componentWillUnmount() {
    this.connection.stop();
  }

  onNotifReceived(res) {
    console.info(" NOTIFICATION !!! ", res);
  }

  render() {
    return <span />;
  }
}

// SignalR.propTypes = {
//   connectionHub: string,
//   accessToken: string,
// };

const mapStateToProps = store => ({
  accessToken: getTokenData(store),
});

function mapDispatchToProps(dispatch) {
  return {
    onCreateTradeUnit: data => {
      dispatch(onCreateTradeUnit(data));
    },
    onHandleTradeUnit: data => {
      dispatch(onHandleTradeUnit(data));
    },
    onRemoveTradeUnit: data => {
      dispatch(onRemoveTradeUnit(data));
    },
    onHandleTradeHistory: data => {
      dispatch(onHandleTradeHistory(data));
    },
    onHandleTradeStats: data => {
      dispatch(onHandleTradeStats(data));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(signalRCl);
