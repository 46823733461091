import * as Yup from "yup";

// Validation for contact add and contact edit form
export const ContactDetailsSchema = Yup.object().shape({
  onWalletAccount: Yup.string().min(18),
  firstName: Yup.string()
    .required("First Name is required")
    .matches(/^[a-zA-Z]+$/, "First Name is not valid"),
  lastName: Yup.string()
    .required("Last Name is required")
    .matches(/^[a-zA-Z]+$/, "Last Name is not valid"),
  companyName: Yup.string()
    .min(3)
    .matches(/^[a-zA-Z]+$/, "Company Name is not valid"),
  address: Yup.string()
    .min(3)
    .matches(/^\s*[a-z0-9-а-яё]+(?:\s+[a-z0-9-а-яё]+){1,2}\s*$/i, "Address is not valid"),
  email: Yup.string()
    .nullable()
    .notRequired()
    .email(),
  phoneNumber: Yup.string().matches(
    /^((\\+[1-9]{1,4}[\\-]*)|(\\([0-9]{2,3}\\)[\\-]*)|([0-9]{2,4})[\\-]*)*?[0-9]{3,4}?[\\-]*[0-9]{3,4}?$/,
    "Phone Number is not valid"
  ),
  cryptoWallets: Yup.array().of(
    Yup.object({
      account: Yup.string()
        .nullable()
        .notRequired()
        .matches(/^\w{16,128}$/, "Crypto wallet is not valid"),
      currency: Yup.string(),
    })
  ),
  cards: Yup.array().of(
    Yup.string()
      .nullable()
      .notRequired()
      .matches(
        /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,
        "Card is not valid"
      )
  ),
});
