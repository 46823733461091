/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import "./index.scss";
import PropTypes from "prop-types";
import {selectUserProfile, getUserMainAvatar} from "shared/auth/selectors";
import {getOnwalletAccount} from "shared/cardService/selectors";
import {getUserAvatar} from "shared/auth/actions";
import config from "shared/config";
const avatarDefault = config.prefixImgUrl + "avatar-default.svg";

const SidebarUser = ({
  active,
  onClick,
  userProfile,
  onWalletAccount,
  userAvatar,
  getUserAvatar,
}) => {
  const [avatar, setAvatar] = useState(avatarDefault);
  const [isLoading, setLoading] = useState(true);

  // useEffect(() => {
  //   if (onWalletAccount && !!onWalletAccount.account)
  //     getUserAvatar(onWalletAccount.account);
  // }, []);

  useEffect(() => {
    if (!!onWalletAccount && isLoading) {
      getUserAvatar(onWalletAccount.account);

      if (!!userAvatar) {
        const avatar = window.URL.createObjectURL(userAvatar);
        setAvatar(avatar);
        setLoading(false);
      }
    }
  }, [getUserAvatar, isLoading, onWalletAccount, userAvatar]);

  useEffect(() => {
    if (!!userAvatar) {
      const avatar = window.URL.createObjectURL(userAvatar);
      setAvatar(avatar);
    }
  }, [userAvatar]);

  return (
    <li className="UserProfileItem">
      <div className={`UserContainer ${active ? "active" : ""}`} onClick={onClick}>
        <img className="UserAvatar" src={avatar} alt="Avatar" />
        Hi,
        {userProfile && userProfile.firstName && userProfile.lastName
          ? userProfile.firstName.length + userProfile.lastName.length < 15
            ? `${userProfile.firstName} ${userProfile.lastName}`
            : `${userProfile.firstName} ${userProfile.lastName.slice(0, -5)}...`
          : "Unknown"}
      </div>
    </li>
  );
};

SidebarUser.propTypes = {
  name: PropTypes.string,
  avatar: PropTypes.string,
};

const mapStateToProps = store => ({
  userProfile: selectUserProfile(store),
  onWalletAccount: getOnwalletAccount(store),
  userAvatar: getUserMainAvatar(store),
});

const dispatchToProps = {
  getUserAvatar: onwalletAccount => getUserAvatar(onwalletAccount),
};

export default connect(mapStateToProps, dispatchToProps)(SidebarUser);
