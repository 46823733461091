import React from "react";
import "./index.scss";

const Textarea = React.forwardRef(
  ({options, className, error, children, ...props}, ref) => {
    return (
      <>
        <textarea
          className={`input textarea ${error ? "errorInput" : ""} ${
            className ? className : ""
          }`}
          ref={ref}
          {...props}
        />
        {error && <div className="inputErrorText">{error}</div>}
      </>
    );
  }
);

export default Textarea;
