/**
 * @author: Seleznyov
 *
 * The component returns a value depending on the input type
 *
 * @param {String} types   default: phone
 * phone | onWalletAccount | cards | bankAccounts
 * the type of value to be processed and returned from the list.
 *
 * @param {Array} setfield поля которые нужно установить в форме
 *
 * @param {Object} otherfield если поле для значение не текущее, а другое
 *
 * Values to form the input field
 * @param {String} label
 * @param {String} name       default: contactList *
 * @param {String} leftIcon
 *
 * Values for processing client information
 * @param {Object} customer
 * {firstName, lastName, cardNumber}
 *
 */

import React, {Component} from "react";

import Input from "shared/components/Input";
import {FormattedMessage} from "react-intl";
import messages from "./messages";

import {ReactComponent as ContactIcon} from "images/contact-icon.svg";

import "./index.scss";
import ModalContactList from "./listContacts";
import ModalContactNew from "./saveNewContacts";
import ModalAddExistContacts from "./addExistContacts";

class AddressBookInput extends Component {
  constructor(props) {
    super(props);

    this.infoValue = this.infoValue.bind(this);
    this.toggleShowContacts = this.toggleShowContacts.bind(this);
    this.toggleShowContactsNew = this.toggleShowContactsNew.bind(this);
    this.toggleShowContactsAdd = this.toggleShowContactsAdd.bind(this);
    this.handleSetSelect = this.handleSetSelect.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.setCustomerOfState = this.setCustomerOfState.bind(this);

    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.popupCL = React.createRef();
    this.btnPopupCL = React.createRef();

    this.popupCN = React.createRef();
    this.btnPopupCN = React.createRef();

    this.popupCA = React.createRef();
    this.btnPopupCA = React.createRef();

    this.state = {
      valueInput: "",
      isShowContacts: false,
      isShowContactsNew: false,
      isShowContactsAdd: false,
      currentClick: null,
      customer: {},
      otherField: {},
    };
  }

  static defaultProps = {
    name: "contactList",
    customer: {},
    otherfield: null,
    setfield: [],
    valfilter: null,
  };

  /**
   *
   */
  componentDidMount() {
    this.setState({valueInput: this.props.value || ""});
    this.setState({customer: this.props.customer});
    document.addEventListener("click", this.handleClickOutside, false);
  }

  /**
   *
   * @param prevProps
   * @param prevState
   */
  componentDidUpdate(prevProps, prevState) {
    switch (this.props.types) {
      case "bankAccounts":
      case "cards":
        break;

      default:
        if (
          (this.props.customer.firstName !== prevProps.customer.firstName &&
            this.state.customer.firstName === prevState.customer.firstName) ||
          (this.props.customer.lastName !== prevProps.customer.lastName &&
            this.state.customer.lastName === prevState.customer.lastName)
        ) {
          this.setCustomerOfState({
            first: this.props.customer.firstName,
            last: this.props.customer.lastName,
          });
        }
        break;
    }
  }

  /**
   *
   */
  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, false);
  }

  /**
   *
   * @param e
   */
  handleClickOutside(e) {
    if (
      !this.btnPopupCL.current.contains(e.target) &&
      !(this.popupCL.current.contains(e.target) && this.state.isShowContacts)
    ) {
      this.toggleShowContacts("off");
    }
    if (
      !this.btnPopupCN.current.contains(e.target) &&
      !(this.popupCN.current.contains(e.target) && this.state.isShowContactsNew)
    ) {
      this.toggleShowContactsNew("off");
    }
    if (
      !this.btnPopupCA.current.contains(e.target) &&
      !(this.popupCA.current.contains(e.target) && this.state.isShowContactsAdd)
    ) {
      this.toggleShowContactsAdd("off");
    }
  }

  /**
   *
   */
  toggleShowContacts(val) {
    this.setState({
      isShowContacts:
        val === "off" ? false : val === "on" ? true : !this.state.isShowContacts,
    });
  }
  toggleShowContactsNew(val) {
    this.setState({
      isShowContactsNew:
        val === "off" ? false : val === "on" ? true : !this.state.isShowContactsNew,
    });
  }
  toggleShowContactsAdd(val) {
    this.setState({
      isShowContactsAdd:
        val === "off" ? false : val === "on" ? true : !this.state.isShowContactsAdd,
    });
  }

  /**
   * @param item
   * @returns {*}
   *
   * Returning a value from a contact record depending on the input parameter of the type component
   */
  infoValue(item) {
    let value;
    switch (this.props.types) {
      case "phone":
        value = item.info.phone;
        break;
      case "onWalletAccount":
        value = item.info.onWalletAccount;
        break;
      case "cards":
        value = item.info.cardNumber;
        break;
      case "bankAccounts":
        value = item.info.bankAccounts;
        break;

      default:
        value = item.phone;
        break;
    }

    return value;
  }

  /**
   * @param item
   *
   * processing data on click from the contact list and depending on the input parameter of the type component
   */
  handleSetSelect = item => {
    let val;
    let valOther = null;
    let nameField = this.props.name;
    this.setCustomerOfState({first: item.firstName, last: item.lastName});

    switch (this.props.types) {
      case "cards":
      case "bankAccounts":
        val = item.firstName + " " + item.lastName;
        valOther = item.value;
        break;

      default:
        // val = this.infoValue(item);
        val = item.value;
        nameField = this.props.name;
    }

    // value for the input field
    this.setState({valueInput: val});
    // setting field values from the forms specified in the parameters
    this.props.form.setFieldValue(nameField, val);

    // setting the value of another field from the form specified in the parameters
    if (this.props.otherfield) {
      this.props.form.setFieldValue(this.props.otherfield.name, valOther);
    }

    // setting values for additional fields from the form specified in the parameters
    this.props.setfield.forEach((sfItem, idx) => {
      this.props.form.setFieldValue(sfItem.name, item[sfItem.name]);
    });
  };

  /**
   * @param e
   * processing changes to the main input field
   */
  handleOnChange = e => {
    let value = e.target.value;
    this.setState(prevState => {
      return {...prevState, valueInput: value};
    });

    switch (this.props.types) {
      case "cards":
      case "bankAccounts":
        let _name = value.split(" ");
        this.setCustomerOfState({first: _name[0] || "", last: _name[1] || ""});
        break;

      default:
        break;
    }
    this.props.onChange(e);
  };

  /**
   *
   * @param data
   */
  setCustomerOfState(data) {
    let customer = this.state.customer;
    customer["firstName"] = data.first;
    customer["lastName"] = data.last;
    this.setState({customer: customer});
  }

  render() {
    const {value, onChange, ...inProps} = this.props;
    return (
      <div className="contact-listinput-wrapper">
        <div className="wrapper">
          <div className="wrapper__row">
            <div className="wrapper__col col-input">
              <div className="input-section">
                <label className="label">{this.props.label}</label>
                <Input
                  iconStyles="onWalletIcon"
                  onChange={this.handleOnChange}
                  value={this.state.valueInput}
                  {...inProps}
                />
              </div>
            </div>

            <div className="wrapper__col col-btn">
              <button
                type="button"
                className="clw-btn"
                ref={this.btnPopupCL}
                onClick={this.toggleShowContacts}
              >
                <ContactIcon />
              </button>

              <div
                ref={this.popupCL}
                className={
                  "wrapper__list-contacts " + (this.state.isShowContacts ? "visible" : "")
                }
              >
                <ModalContactList
                  isShow={this.state.isShowContacts}
                  handleSetSelect={this.handleSetSelect}
                  toggleShowContacts={this.toggleShowContacts}
                  type={this.props.types}
                  customer={this.state.customer}
                  valfilter={this.props.valfilter}
                />
              </div>
            </div>
          </div>

          <div className="wrapper__row row-btns">
            <div
              ref={this.popupCN}
              className={
                "wrapper__new-contacts " + (this.state.isShowContactsNew ? "visible" : "")
              }
            >
              <ModalContactNew
                isShow={this.state.isShowContactsNew}
                toggleShowContactsNew={this.toggleShowContactsNew}
                customer={this.state.customer}
                otherField={
                  this.props.otherfield
                    ? this.props.otherfield
                    : {
                        label: this.props.label,
                        value: this.state.valueInput,
                        name: this.props.name,
                      }
                }
              />
            </div>

            <div
              ref={this.popupCA}
              className={
                "wrapper__add-contacts " + (this.state.isShowContactsAdd ? "visible" : "")
              }
            >
              <ModalAddExistContacts
                isShow={this.state.isShowContactsAdd}
                handleSetSelect={this.handleSetSelect}
                toggleShowContactsAdd={this.toggleShowContactsAdd}
                type={this.props.types}
                customer={this.state.customer}
                valfilter={this.props.valfilter}
              />
            </div>

            <button
              ref={this.btnPopupCN}
              type="button"
              className="btn-ui btn-bold-blue"
              onClick={this.toggleShowContactsNew}
            >
              <FormattedMessage {...messages.saveAsNewContact} />
            </button>
            <button
              ref={this.btnPopupCA}
              onClick={this.toggleShowContactsAdd}
              type="button"
              className="btn-ui btn-bold-blue"
            >
              <FormattedMessage {...messages.addToExistContact} />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default AddressBookInput;
