import React from "react";
import {connect} from "react-redux";
import Input from "shared/components/Input";
import Button from "shared/components/Button";
import {FormattedMessage} from "react-intl";
import {CopyToClipboard} from "react-copy-to-clipboard";

import {closeRightModal} from "shared/components/RightModal/actions";
import {getOnwalletAccount} from "shared/cardService/selectors";

import "./index.scss";
import messages from "../../../../messages";

import config from "shared/config";

const copyIcon = config.prefixImgUrl + "copy-icon.svg";

const ReferalLinkModal = ({closeRightModal, onWalletAccount}) => {
  const textToCopy = `alpha.onwallet.com\\${onWalletAccount.account}`;
  return (
    <>
      <div className="referal-link-contaiter">
        <div className="text">
          {
            "Description of bonuses for bringing a new user by referal lnk goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
          }
        </div>
        <div className="input-wrapper">
          <div className="label">
            <FormattedMessage {...messages.referal_link_share} />
          </div>
          <div className="linkInput">
            <Input type="text" value={textToCopy} />
            <CopyToClipboard text={textToCopy || ""}>
              <div className="copyIcon">
                <img src={copyIcon} alt="copy" />
              </div>
            </CopyToClipboard>
          </div>
        </div>
        <div className="buttonsContainer">
          <Button
            type="button"
            text={messages.referal_link_button_close}
            isSecondary="true"
            onClick={() => {
              closeRightModal();
            }}
          />
          <CopyToClipboard text={textToCopy || ""}>
            <Button type="sumbit" text={messages.referal_link_button_copy} />
          </CopyToClipboard>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = store => ({onWalletAccount: getOnwalletAccount(store)});

const mapDispatchToProps = {closeRightModal};

export default connect(mapStateToProps, mapDispatchToProps)(ReferalLinkModal);
