/**
 * @author: Gnapovska
 */

import React, {Component} from "react";
import {connect} from "react-redux";

import {openRightModal} from "shared/components/RightModal/actions";

import {FormattedMessage} from "react-intl";
import messages from "../../../messages";

import "./index.scss";

import config from "shared/config";

//const arrowUp = config.prefixImgUrl + "arrow-up.svg";
const arrowDown = config.prefixImgUrl + "arrow-down.svg";
const bothArrows = config.prefixImgUrl + "arrows-gorizontal.svg";
const saveToFile = config.prefixImgUrl + "savetofile-transactions.svg";
const repeat = config.prefixImgUrl + "repeat-transactions.svg";
const sendByEmail = config.prefixImgUrl + "sendbyemail-transactions.svg";
const selectUp = config.prefixImgUrl + "arrow-transaction.svg";
const selectDown = config.prefixImgUrl + "arrow-down-transaction.svg";

class TransactionItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayDetails: false,
      meta: {
        from: this.getTransactionType(),
      },
      transactionData: {
        sourceAccount: this.props.item.sourceAccount,
        sourceCurrency: this.props.item.currency,
        targetAccount: this.props.item.targetAccount,
        targetCurrency: this.props.item.targetCurrency,
        amount: this.props.item.amount,
        description: this.props.item.description,
      },
    };

    this.handleDropDownClick = this.handleDropDownClick.bind(this);
  }

  getTransactionType = () => {
    switch (this.props.item.sourceType) {
      case "OnWallet":
        return "OnWallet";
      case "Stripe":
        return "card";
      case "Crypto":
        return "wallet";
      default:
        return null;
    }
  };

  handleDropDownClick() {
    this.setState({displayDetails: !this.state.displayDetails});
  }

  render() {
    return (
      <div className={`${this.state.displayDetails ? "itemExpanded" : ""} itemWrap`}>
        <div className="mainInfoWrap" onClick={this.handleDropDownClick}>
          <div className="icon">
            <img
              src={this.props.item.baseAmount === 200 ? arrowDown : bothArrows}
              alt=""
            />
          </div>
          <div className="time">{this.props.item.time}</div>
          <div className="card-wallet">
            {this.props.item.sourceAccount.substring(0, 6)}
          </div>
          <div className="purposeOfPayment">{this.props.item.purpose}</div>
          <div
            className="status"
            style={{
              color:
                this.props.item.status !== "Confirmed"
                  ? {...(this.props.item.status === "Failed" ? "#FF3333" : "#FF9500")}
                  : "#2FA100",
            }}
          >
            {this.props.item.status}
          </div>
          <div className="amount" title={this.props.item.baseAmount}>
            {this.props.item.baseAmount.toFixed(2)}{" "}
            {this.props.item.baseAmount ? this.props.item.currency : ""}
          </div>
          <div className="dropDown">
            <img src={this.state.displayDetails ? selectUp : selectDown} alt="" />
          </div>
        </div>
        <div
          style={{display: this.state.displayDetails ? "block" : "none"}}
          className="detailsWrap"
        >
          <div className="wallet">
            {" "}
            <FormattedMessage {...messages.sourceWallet} />
            {": " + this.props.item.sourceAccount}
          </div>
          <div className="wallet">
            <FormattedMessage {...messages.recipientWallet} />
            {": " + this.props.item.targetAccount}
          </div>
          <div className="optionsWrap">
            <div className="transactionOption">
              <img src={saveToFile} alt="" />
              <div className="optionText">
                <FormattedMessage {...messages.saveToFile} />
              </div>
            </div>
            <div className="transactionOption">
              <img src={sendByEmail} alt="" />
              <div className="optionText">
                <FormattedMessage {...messages.sendByEmail} />
              </div>
            </div>
            <div
              className="transactionOption"
              onClick={() =>
                this.props.openRightModal(
                  "transferOwnWallet",
                  this.state.meta,
                  this.state.transactionData
                )
              }
            >
              <img src={repeat} alt="" />
              <div className="optionText">
                <FormattedMessage {...messages.repeatTransaction} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({});

function mapDispatchToProps(dispatch) {
  return {
    openRightModal: (data1, data2, data3) => {
      dispatch(openRightModal(data1, data2, data3));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(TransactionItem);
