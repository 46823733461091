import React, {Fragment} from "react";
// Styles
import "../../index.scss";

const Eula = () => {
  return (
    <Fragment>
      <div className="document-wrapper text__container_article">
        <div>
          This End user License Agreement (EULA) is a legal agreement between you and
          OnWallet and its affiliates (“OnWallet”, “us”, “we”) and governs your use of
          OnWallet mobile application. By downloading, installing and using our mobile
          application, you agree to be bound by all the terms and conditions of this EULA.
          please do not download or install the mobile application, if you do not accept
          the terms and conditions under this eula or of onwallet.
        </div>

        <div className="mt-3">
          By accepting to agree with the end user license agreement when prompted, you
          thereby acknowledge that you have read and understood this eula agreement. you
          also agree that you are at or over the age of majority in the respective
          jurisdiction(s) in which you reside and in which you are using onwallet
          application and otherwise have the competency and capacity to validly enter into
          this eula agreement in a binding manner. you also accept that you are legally
          bound by the terms of this eula agreement and if you do not agree to any term or
          condition of this agreement, then you do not have any right or license to use
          the application and cannot legally bind onwallet for any action thereof.
        </div>

        <div className="mt-6 text__container_title">Definitions: </div>

        <div>
          <span>Applicable Law – </span> It means all the laws, statutes, by laws, rules ,
          regulations, orders, ordinances, protocols, codes, guidelines, policies,
          notices, directions, judgments, decrees, arbitral awards or other requirements
          or official directive of any Governmental authority or person under the
          Governmental Authority of that respective Country.
          <br />
          <span>Website – </span> the website you are currently using, OnWallet, and any
          sub-domain of this site unless expressly excluded by their own terms and
          conditions.
          <br />
          <span>User or You – </span> for the purpose of this policy, user or you mean any
          person who has an access to our website and who is not either (i) employed by
          OnWallet or acting in the course of their employment or (ii) engaged in
          providing services to OnWallet and accessing the website in connection with the
          provisions of such services.
          <br />
          <span>Data – </span> the collective information that the user submits via the
          website. This definition incorporates, where applicable, the definition provided
          in the data Protection Laws.
          <br />
          <span>Applicable law: </span> means all laws, statutes, by-laws, rules,
          regulations, orders, ordinances, protocols, codes, guidelines, policies,
          notices, directions, judgments, decrees, arbitral awards or other requirements
          or official directive of any Governmental Authority or person acting under the
          authority of any Governmental Authority of that respective Country.
          <br />
          <span>Governing Law: </span> the rules and laws that govern the agreement in
          case of any legal issues/disputes that arise out of this agreement. The singular
          shall include the plural and vice versa, and the masculine shall include the
          feminine and vice versa. A reference to any legislation shall include any
          statutory modification or re-enactment thereof or any statutory provisions
          substituted therefore.
        </div>

        <div className="mt-6 text__container_title">License:</div>

        <div>
          Onwallet grants you a non-revocable, non-exclusive, non-transferable limited
          right to download, install and use our mobile application on a single device,
          used and controlled by you and to access our mobile application strictly in
          accordance with the terms and conditions under this EULA.
        </div>

        <div className="mt-6 text__container_title">Restrictions of use:</div>

        <div className="document-wrapper-restrictions">
          <div>
            You shall use this mobile application strictly in accordance with the terms
            and conditions mentioned under this EULA. You are restricted to use this
            application:
          </div>

          <div className="document-wrapper-restrictions__items mt-4">
            <div className="document-wrapper-restrictions__items__item">
              <div>3.1</div>
              <div>
                Separate the component parts of the Software for use on more than one
                Computer. Reverse engineer, modify, adapt, translate, decompile,
                disassemble, create derivative works, attempt to discover software source
                code, underlying ideas or algorithms of the Software;
              </div>
            </div>

            <div className="document-wrapper-restrictions__items__item">
              <div>3.2</div>
              <div>
                Sub-license, lease, or lend or pledge or distribute the Software or assign
                any rights herein;
              </div>
            </div>

            <div className="document-wrapper-restrictions__items__item">
              <div>3.3</div>
              <div>Copy or Publish except as specifically allowed in this agreement;</div>
            </div>

            <div className="document-wrapper-restrictions__items__item">
              <div>3.4</div>
              <div>
                Represent or give the impression that you are the owner or originator of
                this mobile application;
              </div>
            </div>

            <div className="document-wrapper-restrictions__items__item">
              <div>3.5</div>
              <div>
                Remove, modify or conceal any identification or reference number or other
                information which may be embedded in any file.
              </div>
            </div>

            <div className="document-wrapper-restrictions__items__item">
              <div>3.6</div>
              <div>
                Use this application for creating a product, service of software that is
                directly or indirectly, competitive with or in any way a substitute for
                any software or services provided by us.
              </div>
            </div>

            <div className="document-wrapper-restrictions__items__item">
              <div>3.7</div>
              <div>
                Use any proprietary information or interfaces of Company or other
                Intellectual property of the Company in the design development,
                manufacture, licensing or distribution of any applications, accessories or
                devices for use with the Application.
              </div>
            </div>

            <div className="mt-3">
              You also agree that you will not use or allow anyone else to use or
              undertake any activity which is or may:
            </div>

            <div className="document-wrapper-restrictions__items__item mt-3">
              <div>3.8</div>
              <div>Be unlawful, or tend to incite another person to commit a crime;</div>
            </div>

            <div className="document-wrapper-restrictions__items__item">
              <div>3.9</div>
              <div>
                Be obscene, offensive, threatening, violent, malicious or defamatory;
              </div>
            </div>

            <div className="document-wrapper-restrictions__items__item">
              <div>3.10</div>
              <div>Be sexually explicit or pornographic;</div>
            </div>

            <div className="document-wrapper-restrictions__items__item">
              <div>3.11</div>
              <div>
                Be likely to deceive any person or be used to impersonate any person, or
                to misrepresent your identity, age or affiliation with any person;
              </div>
            </div>

            <div className="document-wrapper-restrictions__items__item">
              <div>3.12</div>
              <div>
                Request or collect passwords or other personal information from another
                user without his permission;
              </div>
            </div>

            <div className="document-wrapper-restrictions__items__item">
              <div>3.13</div>
              <div>
                Be used to sell any goods or services or for any other commercial use not
                intended by us, for yourself or for any other person. Examples are:
                sending private messages with a commercial purpose, or collecting
                information with the intention of passing it to a third party for his
                commercial use;
              </div>
            </div>

            <div className="document-wrapper-restrictions__items__item">
              <div>3.14</div>
              <div>
                Include anything other than words (i.e. you will not include any symbols
                or photographs) except for a photograph of yourself in your profile in
                such place as we designate;
              </div>
            </div>

            <div className="document-wrapper-restrictions__items__item">
              <div>3.15</div>
              <div>
                Facilitate the provision of unauthorised copies of another person's
                copyright work;
              </div>
            </div>

            <div className="document-wrapper-restrictions__items__item">
              <div>3.16</div>
              <div>Link to any of the material specified in this paragraph;</div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Eula;
