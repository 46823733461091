import React, {useEffect, useRef} from "react";
import {FormattedMessage} from "react-intl";
import messages from "../../messages";
import {connect} from "react-redux";
//import {userCopyToClipboard} from "shared/hooks";
import {CopyToClipboard} from "react-copy-to-clipboard";

import Button from "shared/components/Button";
import Textarea from "shared/components/Input/Textarea";
import Checkbox from "shared/components/Checkbox";

import config from "shared/config";
import {getGeneratedPassPhrase} from "shared/auth/selectors";
import {getPassPhrase} from "shared/auth/actions";
const copyIcon = config.prefixImgUrl + "copy-icon.svg";

const FifthRegisterStep = ({step, setFieldValue, values, passPhrase, getPassPhrase}) => {
  const inputRef = useRef(null);
  //const handleClick = userCopyToClipboard(inputRef);

  useEffect(() => {
    if (!passPhrase) getPassPhrase();
    if (passPhrase) setFieldValue("passPhrase", passPhrase);
  }, [getPassPhrase, passPhrase, setFieldValue]);
  return (
    <div className="fifthStep">
      {step === 5 && (
        <>
          <div className="phraseInfo">
            <FormattedMessage {...messages.weGeneratedRecoveryPhrase} />
          </div>
          <div className="phraseContainer">
            <Textarea ref={inputRef} value={passPhrase || ""} readOnly />
            <CopyToClipboard text={passPhrase || ""}>
              <img src={copyIcon} className="copyIcon" alt="copy" />
            </CopyToClipboard>
          </div>
          <div className="checkboxContainer">
            <div className="checkboxWrapper">
              <Checkbox onChange={e => setFieldValue("copyPhrase", e.target.checked)} />
            </div>
            <FormattedMessage {...messages.iHaveSavedPhrase} />
          </div>

          <Button
            disabled={!values.copyPhrase}
            type="submit"
            className="nextButton"
            text={messages.letsGetStarted}
          />
          <div className="copyright">{messages.copyRight.defaultMessage}</div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = store => ({
  passPhrase: getGeneratedPassPhrase(store),
});

const mapDispatchToProps = {
  getPassPhrase,
};

export default connect(mapStateToProps, mapDispatchToProps)(FifthRegisterStep);
