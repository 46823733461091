import React from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";

import Header from "shared/components/Header";
import TrustedDevices from "./components/TrustedDevices";
import LoginSettings from "./components/LoginSettings";
import messages from "./messages";
import "./index.scss";

import config from "shared/config";

const ContactsIconHeader = config.prefixImgUrl + "account_settings_header.svg";
// import {routes} from "shared/appUrls";

const AccountSettings = contactData => {
  return (
    <>
      <Header
        headerTitle={<FormattedMessage {...messages.header} />}
        navLinks={[]}
        logoImage={ContactsIconHeader}
      ></Header>
      <div className="main-Contacts-page mainContent">
        <LoginSettings />
        <TrustedDevices />
      </div>
    </>
  );
};

const mapStateToProps = store => ({});

const dispatchToProps = {
  execute: "MESSAGES_EXECUTE_SUCCESS",
};
export default connect(mapStateToProps, dispatchToProps)(AccountSettings);
