import React from "react";
import {FormattedMessage} from "react-intl";
import {ReactComponent as SettingIcon} from "images/settings-icon-gray.svg";
// Utils
import messages from "../../messages";
// Styles
import "./index.scss";

const mockData = [
  {
    idFrom: "12345678912345",
    nameFrom: "Michael Hansen",
    onWalletFrom: "12345678",
    idTo: "12345678912345",
    nameTo: "Jane Swanson",
    onWalletTo: "23412342",
    count: 100,
    date: "20 Sep, 2020 10:45",
    status: "Pending status description lorem ipsum",
  },
  {
    idFrom: "12345678912345",
    nameFrom: "Michael Hansen",
    onWalletFrom: "12345678",
    idTo: "12345678912345",
    nameTo: "Jane Swanson",
    onWalletTo: "23412342",
    count: 100,
    date: "20 Sep, 2020 10:45",
    status: "Pending status description lorem ipsum",
  },
  {
    idFrom: "12345678912345",
    nameFrom: "Michael Hansen",
    onWalletFrom: "12345678",
    idTo: "12345678912345",
    nameTo: "Jane Swanson",
    onWalletTo: "23412342",
    count: 100,
    date: "20 Sep, 2020 10:45",
    status: "Pending status description lorem ipsum",
  },
  {
    idFrom: "12345678912345",
    nameFrom: "Michael Hansen",
    onWalletFrom: "12345678",
    idTo: "12345678912345",
    nameTo: "Jane Swanson",
    onWalletTo: "23412342",
    count: 100,
    date: "20 Sep, 2020 10:45",
    status: "Pending status description lorem ipsum",
  },
];

const DashboardTransactions = () => {
  return (
    <>
      <div className="pending-transactions">
        <div className="pending-transactions__titles">
          <div className="ending-transactions__titles__item">
            <FormattedMessage {...messages.pendingTransactions} />
          </div>
          <div className="ending-transactions__titles__item blue-text">
            <FormattedMessage {...messages.showAll} />
          </div>
        </div>

        <div className="pending-transactions__list">
          {mockData.map((item, index) => (
            <div className="pending-transactions__list__item" key={index}>
              {/* Header card */}
              <div className="pending-transactions__list__item-header">
                <div className="pending-transactions__list__item-header__item gray-text">
                  {item.date}
                </div>
                <div className="pending-transactions__list__item-header__item bold">
                  {`${item.count} USD`}
                </div>
              </div>

              {/* Block from customer */}
              <div className="pending-transactions__list__item-account">
                <div className="pending-transactions__list__item-account__item gray-text">
                  From
                </div>
                <div className="pending-transactions__list__item-account__info pending-transactions__list__item-account__item">
                  <div className="pending-transactions__list__item-account__info-onwallet">{`OnWallet Account #${item.onWalletFrom}`}</div>
                  <div className="pending-transactions__list__item-account__info-name gray-text">{`${item.nameFrom}, ID ${item.idFrom}`}</div>
                </div>
              </div>

              {/* Settings icon */}
              <div className="pending-transactions__list__item-setting-icon">
                <SettingIcon className="settingsIcon" />
              </div>

              {/* Block to customer */}
              <div className="pending-transactions__list__item-account">
                <div className="pending-transactions__list__item-account__item gray-text">
                  To
                </div>
                <div className="pending-transactions__list__item-account__info pending-transactions__list__item-account__item">
                  <div className="pending-transactions__list__item-account__info-onwallet">{`OnWallet Account #${item.onWalletTo}`}</div>
                  <div className="pending-transactions__list__item-account__info-name gray-text">{`${item.nameTo}, ID ${item.idTo}`}</div>
                </div>
              </div>

              {/* Pending status description */}
              <div className="pending-transactions__list__item-description">
                Pending status description lorem ipsum
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="pending-transactions">
        <div className="pending-transactions__titles">
          <div className="ending-transactions__titles__item">
            <FormattedMessage {...messages.lastTransactions} />
          </div>
        </div>

        <div className="pending-transactions__list">
          {mockData.map((item, index) => (
            <div className="pending-transactions__list__item" key={index}>
              {/* Header card */}
              <div className="pending-transactions__list__item-header">
                <div className="pending-transactions__list__item-header__item gray-text">
                  {item.date}
                </div>
                <div className="pending-transactions__list__item-header__item bold">
                  {`${item.count} USD`}
                </div>
              </div>

              {/* Block from customer */}
              <div className="pending-transactions__list__item-account">
                <div className="pending-transactions__list__item-account__item gray-text">
                  From
                </div>
                <div className="pending-transactions__list__item-account__info pending-transactions__list__item-account__item">
                  <div className="pending-transactions__list__item-account__info-onwallet">{`OnWallet Account #${item.onWalletFrom}`}</div>
                  <div className="pending-transactions__list__item-account__info-name gray-text">{`${item.nameFrom}, ID ${item.idFrom}`}</div>
                </div>
              </div>

              {/* Settings icon */}
              <div className="pending-transactions__list__item-setting-icon">
                <SettingIcon className="settingsIcon" />
              </div>

              {/* Block to customer */}
              <div className="pending-transactions__list__item-account">
                <div className="pending-transactions__list__item-account__item gray-text">
                  To
                </div>
                <div className="pending-transactions__list__item-account__info pending-transactions__list__item-account__item">
                  <div className="pending-transactions__list__item-account__info-onwallet">{`OnWallet Account #${item.onWalletTo}`}</div>
                  <div className="pending-transactions__list__item-account__info-name gray-text">{`${item.nameTo}, ID ${item.idTo}`}</div>
                </div>
              </div>

              {/* Pending status description */}
              <div className="pending-transactions__list__item-description status-ok">
                <FormattedMessage {...messages.completed} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DashboardTransactions;
