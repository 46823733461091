import React from "react";
import {connect} from "react-redux";
// Actions
import {openRightModal} from "shared/components/RightModal/actions";
// Utils
import config from "shared/config";
// Styles
import "./index.scss";

// Images
const onWalletBlueLogo = config.prefixImgUrl + "onwallet-icon-short.svg";
const onWalletOrangeLogo = config.prefixImgUrl + "onwallet_orange.svg";

const OnWalletBalance = ({openRightModal}) => {
  return (
    <>
      <div className="onwallet-balance">
        <div className="onwallet-balance__titles">
          <div className="onwallet-balance__titles__item">OnWallet Balance</div>
          <div
            className="onwallet-balance__titles__item blue-text"
            onClick={() =>
              openRightModal({
                name: "ratesAndFees",
                title: "Standard Rates and Fees",
              })
            }
          >
            % Rates & Fees
          </div>
        </div>

        <div className="onwallet-balance__header">
          <div className="onwallet-balance__header__item">
            <img src={onWalletBlueLogo} alt="onWallet logo" />
            <div className="onwallet-balance__header__item-text">
              <div className="onwallet-balance__header__item-text__item">
                1,897,450.75 USD
              </div>
              <div className="onwallet-balance__header__item-text__item">
                Total Master Account
              </div>
            </div>
          </div>
          <div className="onwallet-balance__header__item">
            <img src={onWalletOrangeLogo} alt="onWallet logo" />
            <div className="onwallet-balance__header__item-text">
              <div className="onwallet-balance__header__item-text__item">
                235,300.58 USD
              </div>
              <div className="onwallet-balance__header__item-text__item">
                Own OnWallet Funds
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  openRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(OnWalletBalance);
