import React from "react";
import {connect} from "react-redux";
import Barcode from "react-barcode";
import "./index.scss";
import config from "shared/config";
import {shoppingCardModal} from "shared/shoppingService/actions/index";

const brandIcon = config.prefixImgUrl + "brand-image.svg";

const CardItem = ({item, shoppingModal}) => {
  return (
    <>
      <div className="card-container" onClick={() => shoppingModal(item)}>
        <div className="card-item">
          <img className="brand-logo" alt="" src={brandIcon}></img>
          {/* Change brand-name (wait back-end)*/}
          <div className="brand-name">{item.title}</div>
          <div className="card-name">{item.title}</div>
          <div className={"bar_code"}>
            <Barcode
              value="1234567890"
              format="CODE128"
              displayValue={false}
              width={8}
              height={32}
            />
          </div>
        </div>
      </div>
    </>
  );
};

// Redux
const mapStateToProps = store => ({});

const mapDispatchToProps = {
  shoppingModal: data => shoppingCardModal(data),
};

export default connect(mapStateToProps, mapDispatchToProps)(CardItem);
