import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {dataRightModal} from "shared/components/RightModal/selectors";
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import messages from "../../../messages";
import config from "shared/config";
// Styles
import "./index.scss";

const mockData = [
  {
    coinId: "123456789012345",
    linkedAmount: 5,
  },
  {
    coinId: "4419898226",
    linkedAmount: 5,
  },
  {
    coinId: "4419898226",
    linkedAmount: 5,
  },
  {
    coinId: "4419898226",
    linkedAmount: 5,
  },
];

const OrderDescriptionModal = ({order}) => {
  const state = {
    src: null,
    status: {
      1: "In Transit",
      2: "Completed",
      3: "Canceled",
    },
    metal: {
      1: {img: "metal-au.svg", title: "Gold 999.9"},
      2: {img: "metal-ag.svg", title: "Silver"},
      3: {img: "metal-pt.svg", title: "Platinum"},
    },
  };

  // Transfer to selectors
  const outMetal = idx => {
    let _m = state.metal[idx];

    return (
      <span className="ordered-metal">
        <img src={config.prefixImgUrl + _m.img} alt={_m.title} />
        {_m.title}
      </span>
    );
  };

  return (
    <>
      <FormSectionSeparator sectionName={messages.details} />

      {/* Description order */}
      <div className="order-details">
        <div className="order-details__item">
          <div className="order-details__item-text">Date & Time: </div>
          <div className="order-details__item-value">{order.dateTime}</div>
        </div>
        <div className="order-details__item">
          <div className="order-details__item-text">Ordered Metal: </div>
          <div className="order-details__item-value">{outMetal(order.orderedMetal)}</div>
        </div>
        <div className="order-details__item">
          <div className="order-details__item-text">Weight: </div>
          <div className="order-details__item-value">{`${order.weight} Kg`}</div>
        </div>
        <div className="order-details__item">
          <div className="order-details__item-text">Price per Kg: </div>
          <div className="order-details__item-value">
            {`${order.price.toFixed(2)} USD`}
          </div>
        </div>
        <div className="order-details__item">
          <div className="order-details__item-text">Total Amount: </div>
          <div className="order-details__item-value">
            {`${order.orderAmount.toFixed(2)} USD`}
          </div>
        </div>
        <div className="order-details__item">
          <div className="order-details__item-text">Status: </div>
          <div className="order-details__item-value">{state.status[order.status]}</div>
        </div>
      </div>

      {/* Second section */}
      <FormSectionSeparator sectionName={messages.linkedToCoins} />

      <div className="coin-info">
        356 DHG coins are linked to the gold in this order in the following shares:
      </div>

      <div className="table-coin">
        <div className="table-coin-header">
          <div className="table-coin-header__item">Coin ID</div>
          <div className="table-coin-header__item">Linked Amount</div>
        </div>
        <div className="table-coin-body">
          {!!mockData.length &&
            mockData.map(item => (
              <div className="table-coin-body__item">
                <div>{item.coinId}</div>
                <div>{`${item.linkedAmount} USD`}</div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

// Types
OrderDescriptionModal.propTypes = {
  order: PropTypes.object.isRequired,
};

// Redux
const mapStateToProps = state => ({
  order: dataRightModal(state),
});

export default connect(mapStateToProps, null)(OrderDescriptionModal);
