import React, {useEffect} from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {Formik, Form, Field, useFormikContext} from "formik";
import * as Yup from "yup";
import {addNewCryptoWallet} from "shared/walletService/actions";
import {
  getAllAvailableWallets,
  getCryptoWallets,
  isAvailableWalletsFetched,
  getCoinNameByCurrency,
} from "shared/walletService/selectors";
import {fetchAllAvailableWallets} from "shared/walletService/actions";
import "./index.scss";
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import messages from "../../messages";

import Select from "shared/components/Select";
import Button from "shared/components/Button";
import {WalletAddOption} from "shared/components/CardWalletOptions";

const initialValues = {
  walletCurrency: "",
};

const AddNewWalletSchema = Yup.object().shape({
  walletCurrency: Yup.string().required("Currency is required"),
});

const AddNewWallet = ({
  addNewCryptoWallet,
  availableWallets,
  fetchAllAvailableWallets,
  isAvailableWalletsFetched,
  wallets,
}) => {
  useEffect(() => {
    if (availableWallets.length === 0 && !isAvailableWalletsFetched) {
      fetchAllAvailableWallets();
    }
  }, [availableWallets, fetchAllAvailableWallets, isAvailableWalletsFetched]);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddNewWalletSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, actions) => {
        addNewCryptoWallet({currency: values.walletCurrency});
      }}
    >
      {({touched, errors}) => (
        <Form className="AddWalletForm">
          <PopulateCryptoCurrency
            availableWallets={availableWallets}
            field="walletCurrency"
          />
          <FormSectionSeparator sectionName={messages.walletDetails} />
          <Field
            name="walletCurrency"
            render={({field}) => (
              <div className="currencyInputContainer">
                <label className="label">
                  <FormattedMessage {...messages.currency} />
                </label>
                <Select
                  className="select"
                  {...field}
                  renderValue={value =>
                    !!value ? (
                      <WalletAddOption
                        className="no-padding"
                        currency={value}
                        title={getCoinNameByCurrency(value)}
                      />
                    ) : (
                      ""
                    )
                  }
                >
                  {availableWallets
                    .filter(availableWallet =>
                      wallets.every(
                        wallet => wallet.currency !== availableWallet.currency
                      )
                    )
                    .map((availableWalletCurrency, index) => (
                      <WalletAddOption
                        key={index}
                        value={availableWalletCurrency}
                        currency={availableWalletCurrency}
                        title={getCoinNameByCurrency(availableWalletCurrency)}
                      />
                    ))}
                </Select>
              </div>
            )}
          />
          <Button text={messages.createWallet} />
        </Form>
      )}
    </Formik>
  );
};

export const PopulateCryptoCurrency = ({field, availableWallets}) => {
  const {setFieldValue} = useFormikContext();
  useEffect(() => {
    if (availableWallets && availableWallets[0]) {
      setFieldValue(field, availableWallets[0]);
    }
  }, [availableWallets, setFieldValue, field]);
  return null;
};

const mapStateToProps = store => ({
  availableWallets: getAllAvailableWallets(store),
  isAvailableWalletsFetched: isAvailableWalletsFetched(store),
  wallets: getCryptoWallets(store),
});

const mapDispatchToProps = {
  addNewCryptoWallet,
  fetchAllAvailableWallets,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewWallet);
