import React from "react";
import PropTypes from "prop-types";

import {format} from "d3-format";

import {ChartCanvas, Chart} from "react-stockcharts";
import {BarSeries, CandlestickSeries} from "react-stockcharts/lib/series";
import {XAxis, YAxis} from "react-stockcharts/lib/axes";
import {
  CrossHairCursor,
  CurrentCoordinate,
  MouseCoordinateY,
} from "react-stockcharts/lib/coordinates";

import {discontinuousTimeScaleProvider} from "react-stockcharts/lib/scale";

import {ema, sma, macd} from "react-stockcharts/lib/indicator";
import {fitWidth} from "react-stockcharts/lib/helper";
import {last} from "react-stockcharts/lib/utils";

class CandlestickChart extends React.Component {
  render() {
    const height = 750;
    const {data: initialData, width, ratio} = this.props;
    const gridHeight = height - 380;
    const gridWidth = width - 55;
    const showGrid = true;
    const yGrid = showGrid ? {innerTickSize: -1 * gridWidth, tickStrokeOpacity: 0.1} : {};
    const xGrid = showGrid
      ? {innerTickSize: -1 * gridHeight, tickStrokeOpacity: 0.1}
      : {};

    const ema26 = ema()
      .id(0)
      .options({windowSize: 26})
      .merge((d, c) => {
        d.ema26 = c;
      })
      .accessor(d => d.ema26);

    const ema12 = ema()
      .id(1)
      .options({windowSize: 12})
      .merge((d, c) => {
        d.ema12 = c;
      })
      .accessor(d => d.ema12);

    const macdCalculator = macd()
      .options({
        fast: 12,
        slow: 26,
        signal: 9,
      })
      .merge((d, c) => {
        d.macd = c;
      })
      .accessor(d => d.macd);

    const smaVolume50 = sma()
      .id(3)
      .options({
        windowSize: 50,
        sourcePath: "volume",
      })
      .merge((d, c) => {
        d.smaVolume50 = c;
      })
      .accessor(d => d.smaVolume50);

    const calculatedData = macdCalculator(smaVolume50(ema12(ema26(initialData))));
    const xScaleProvider = discontinuousTimeScaleProvider.inputDateAccessor(d => d.date);
    const {data, xScale, xAccessor, displayXAccessor} = xScaleProvider(calculatedData);
    const start = xAccessor(last(data));
    const end = xAccessor(data[Math.max(0, data.length - 60)]);
    const xExtents = [start, end];

    return (
      <ChartCanvas
        height={440}
        width={width}
        type={"svg"}
        ratio={ratio}
        margin={{left: 10, right: 45, top: 20, bottom: 50}}
        data={data}
        xScale={xScale}
        zoomEvent={false}
        xAccessor={xAccessor}
        displayXAccessor={displayXAccessor}
        xExtents={xExtents}
      >
        <Chart
          id={1}
          height={290}
          yExtents={[d => [d.high, d.low], ema26.accessor(), ema12.accessor()]}
        >
          <XAxis ticks={6} axisAt="bottom" orient="bottom" showTicks={false} {...xGrid} />
          <YAxis
            fontSize={10}
            stroke={"#1F333D"}
            axisAt="right"
            orient="right"
            fontFamily={"Roboto"}
            ticks={5}
            fill={"green"}
            {...yGrid}
          />

          <MouseCoordinateY
            at="right"
            orient="right"
            displayFormat={format(".2f")}
            fontSize={"10px"}
          />

          <CandlestickSeries
            stroke={"none"}
            wickStroke={d => (d.close > d.open ? "#2FA100" : "#FF3333")}
            fill={d => (d.close > d.open ? "#2FA100" : "#FF3333")}
            opacity={1}
          />

          <CurrentCoordinate yAccessor={ema26.accessor()} fill={ema26.stroke()} />
          <CurrentCoordinate yAccessor={ema12.accessor()} fill={ema12.stroke()} />
        </Chart>
        <Chart
          id={2}
          height={94}
          yExtents={[d => d.volume, smaVolume50.accessor()]}
          origin={(w, h) => [0, h - 80]}
        >
          <YAxis
            axisAt="right"
            orient="right"
            ticks={3}
            tickFormat={format(".2s")}
            stroke={"#1F333D"}
            fontSize={10}
            {...yGrid}
          />
          <XAxis
            axisAt="bottom"
            orient="bottom"
            ticks={4}
            showTicks={true}
            outerTickSize={0}
            {...xGrid}
          />

          <MouseCoordinateY
            at="right"
            orient="right"
            displayFormat={format(".4s")}
            fontSize={"12px"}
          />

          <BarSeries
            stroke={false}
            fill={d => (d.close > d.open ? "#2FA100" : "#FF3333")}
            yAccessor={d => d.volume}
          />
        </Chart>

        <CrossHairCursor />
      </ChartCanvas>
    );
  }
}

CandlestickChart.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  ratio: PropTypes.number.isRequired,
  type: PropTypes.oneOf(["svg", "hybrid"]).isRequired,
};

CandlestickChart.defaultProps = {
  type: "svg",
};

const CandleStickChartWithClickHandlerCallback = fitWidth(CandlestickChart);

export default CandleStickChartWithClickHandlerCallback;
