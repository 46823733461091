import {defineMessages} from "react-intl";

const scope = "Layout";

const messages = defineMessages({
  dashboard: {
    id: `${scope}.dashboard`,
    defaultMessage: "Dashboard",
  },
  transactionMonitor: {
    id: `${scope}.transactionMonitor`,
    defaultMessage: "Transaction Monitor",
  },
  legalDocs: {
    id: `${scope}.legalDocs`,
    defaultMessage: "Legal Docs",
  },
  customers: {
    id: `${scope}.customers`,
    defaultMessage: "Customers",
  },
  trading: {
    id: `${scope}.trading`,
    defaultMessage: "Trading",
  },
  shoppingCards: {
    id: `${scope}.shoppingCards`,
    defaultMessage: "Shopping",
  },
  actionsHistory: {
    id: `${scope}.actionsHistory`,
    defaultMessage: "Actions History",
  },
  blockchainNews: {
    id: `${scope}.blockchainNews`,
    defaultMessage: "Blockchain News",
  },
  help: {
    id: `${scope}.help`,
    defaultMessage: "Help & Tutorials",
  },
  wallets: {
    id: `${scope}.wallets`,
    defaultMessage: "Wallets",
  },
  finance: {
    id: `${scope}.finance`,
    defaultMessage: "Finance",
  },
  support: {
    id: `${scope}.support`,
    defaultMessage: "Support",
  },
  messages: {
    id: `${scope}.messages`,
    defaultMessage: "Messages",
  },
  contacts: {
    id: `${scope}.contacts`,
    defaultMessage: "Contacts",
  },
  personalDetails: {
    id: `${scope}.personalDetails`,
    defaultMessage: "Personal Details",
  },
  accountSettings: {
    id: `${scope}.accountSettings`,
    defaultMessage: "Account Settings",
  },
  keysVault: {
    id: `${scope}.keysVault`,
    defaultMessage: "Keys Vault",
  },
  account: {
    id: `${scope}.account`,
    defaultMessage: "Account",
  },
  orders: {
    id: `${scope}.orders`,
    defaultMessage: "Orders",
  },
  textpage: {
    id: `${scope}.textpage`,
    defaultMessage: "Textpage",
  },
  logOut: {
    id: `${scope}.logOut`,
    defaultMessage: "Log out",
  },
  referal_link: {
    id: `${scope}.referal_link`,
    defaultMessage: "Referral link",
  },
  referal_link_button_close: {
    id: `${scope}.referal_link_button_close`,
    defaultMessage: "close",
  },
  referal_link_button_copy: {
    id: `${scope}.referal_link_button_copy`,
    defaultMessage: "copy",
  },
  referal_link_share: {
    id: `${scope}.referal_link_share`,
    defaultMessage: "Share this link",
  },
  ui_settings: {
    id: `${scope}.ui_settings`,
    defaultMessage: "UI Settings",
  },
  privacyPolicy: {
    id: `${scope}.privacyPolicy`,
    defaultMessage: "Privacy Policy",
  },
  rigthsReserved: {
    id: `${scope}.rigthsReserved`,
    defaultMessage: "Copyright © 2021 OnWallet. All rights reserved.",
  },
  providedBy: {
    id: `${scope}.providedBy`,
    defaultMessage: "OnWallet Services are provided by OnWallet Limited.",
  },
  financeOrders: {
    id: `${scope}.financeOrders`,
    defaultMessage: "Orders",
  },
  financeCoinRegistry: {
    id: `${scope}.financeCoinRegistry`,
    defaultMessage: "Coin Registry",
  },
  financeMetalSupply: {
    id: `${scope}.financeMetalSupply`,
    defaultMessage: "Metal Supply",
  },
  managementStuff: {
    id: `${scope}.managementStuff`,
    defaultMessage: "Management stuff",
  },
  staff: {
    id: `${scope}.staff`,
    defaultMessage: "Staff",
  },
  roles: {
    id: `${scope}.roles`,
    defaultMessage: "Roles",
  },
  actionsApproval: {
    id: `${scope}.actionsApproval`,
    defaultMessage: "Actions Approval",
  },
  actionsLog: {
    id: `${scope}.actionsLog`,
    defaultMessage: "Actions Log",
  },
});

export default messages;
