import React, {useRef} from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import "./index.scss";
import messages from "../../messages";

import Button from "shared/components/Button";
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import {openRightModal} from "shared/components/RightModal/actions";
import {userCopyToClipboard} from "shared/hooks";
import QRCode from "qrcode.react";
import Select from "shared/components/Select";
import {SendToOption} from "shared/components/CardWalletOptions";
import Input from "shared/components/Input";

import config from "shared/config";
const onWalletIcon = config.prefixImgUrl + "onwallet-icon-short_v1.svg";
const copyIcon = config.prefixImgUrl + "copy-icon.svg";
const contactIcon = config.prefixImgUrl + "contact-icon.svg";
const emailIcon = config.prefixImgUrl + "email-icon.svg";
const phoneIcon = config.prefixImgUrl + "phone-icon.svg";

const SendToOptions = [
  {
    text: <FormattedMessage {...messages.onWalletAccount} />,
    value: "onWallet",
  },
  {
    text: <FormattedMessage {...messages.email} />,
    value: "email",
  },
  {
    text: <FormattedMessage {...messages.phoneNumber} />,
    value: "phone",
  },
];

const initialValues = {
  sendTo: null,
  email: "",
  phone: "",
  recipientAddress: "",
};

const ReceiveDetailsSchema = Yup.object().shape({
  sendTo: Yup.object()
    .nullable()
    .default(null)
    .required("Send to is required"),
  email: Yup.string().email(),
  phone: Yup.string().matches(
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    "Phone Number is not valid"
  ),
  recipientAddress: Yup.string(),
});

const getLink = (details, type) => {
  return `${window.location.href}?to=${type}&recipient=${details.recipient}&amount=${details.amount}&currency=${details.currency}&description=${details.description}`;
};

const ReceiveDetailsForm = ({
  details,
  cardNumber,
  walletAddress,
  onWallet,
  openRightModal,
}) => {
  const linkRef = useRef(null);
  let to = null;
  if (cardNumber) {
    to = "card";
  } else if (walletAddress) {
    to = "wallet";
  } else if (onWallet) {
    to = "onWallet";
  }
  const link = getLink(details, to);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ReceiveDetailsSchema}
      onSubmit={(values, actions) => {
        console.log(values);
      }}
    >
      {({errors, touched, values}) => (
        <div className="ReceiveForm">
          <Form className="form">
            <FormSectionSeparator sectionName={messages.details} />
            <div className="DetailsContainer">
              <div className="DetailsRow">
                <span className="title">
                  <FormattedMessage {...messages.recipient} />
                </span>
                <span className="value">{details.recipient}</span>
              </div>
              <div className="DetailsRow">
                <span className="title">
                  <FormattedMessage {...messages.currency} />
                </span>
                <span className="value">{details.currency}</span>
              </div>
              <div className="DetailsRow">
                <span className="title">
                  <FormattedMessage {...messages.amount} />
                </span>
                <span className="value">{details.amount}</span>
              </div>
              <div className="DetailsRow">
                <span className="title">
                  <FormattedMessage {...messages.description} />
                </span>
                <span className="value description">{details.description}</span>
              </div>
            </div>
            <div className="Link">
              <input ref={linkRef} className="link" readOnly value={link ? link : 0} />
              <img
                src={copyIcon}
                className="copyIcon"
                alt="icon"
                onClick={userCopyToClipboard(linkRef)}
              />
            </div>
            <div className="receiveQRCode">
              <QRCode size={145} value={link ? link : 0} />
            </div>
            <FormSectionSeparator sectionName={messages.sendInvoice} />
            <Field
              name="sendTo"
              render={({field}) => (
                <div className="sendToContainer">
                  <label className="label">
                    <FormattedMessage {...messages.sendTo} />
                  </label>
                  <Select
                    error={touched.sendTo && errors.sendTo ? errors.sendTo : ""}
                    className="select"
                    {...field}
                    renderValue={value => (
                      <SendToOption value={value} title={value.text} />
                    )}
                  >
                    {SendToOptions.map((sendTo, index) => (
                      <SendToOption key={index} value={sendTo} title={sendTo.text} />
                    ))}
                  </Select>
                </div>
              )}
            />

            {values.sendTo && values.sendTo.value === "onWallet" && (
              <SendToInput
                touched={touched}
                errors={errors}
                fieldName="recipientAddress"
                icon={onWalletIcon}
                placeholder="Paste or scan an address"
                label={<FormattedMessage {...messages.onwalletAccount} />}
                withButtons={true}
              />
            )}
            {values.sendTo && values.sendTo.value === "email" && (
              <SendToInput
                touched={touched}
                errors={errors}
                fieldName="email"
                icon={emailIcon}
                label={<FormattedMessage {...messages.email} />}
              />
            )}
            {values.sendTo && values.sendTo.value === "phone" && (
              <SendToInput
                touched={touched}
                errors={errors}
                fieldName="phone"
                icon={phoneIcon}
                label={<FormattedMessage {...messages.phoneNumber} />}
              />
            )}

            <div className="ReceiveButtons">
              <Button
                text={messages.newInvoice}
                type="button"
                isSecondary="true"
                onClick={() => {
                  if (cardNumber) {
                    openRightModal("receiveCard", {cardNumber});
                  } else if (walletAddress) {
                    openRightModal("receiveWallet", {walletAddress});
                  } else if (onWallet) {
                    openRightModal("receiveOnWallet");
                  }
                }}
              />
              <Button text={messages.send} type="submit" />
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export const SendToInput = ({
  touched,
  errors,
  label,
  placeholder,
  fieldName,
  withButtons,
  icon,
}) => {
  return (
    <Field
      name={fieldName}
      render={({field}) => (
        <div className="onWalletAccountInputContainer">
          <label className="label">{label}</label>
          <div className="accountInput">
            <Input
              type="text"
              error={touched[fieldName] && errors[fieldName]}
              leftIcon={icon}
              iconStyles="onWalletIcon"
              {...field}
              placeholder={placeholder}
            />
            <img className="contactIcon" src={contactIcon} alt="contact" />
          </div>
          {withButtons && (
            <div className="actionButtons">
              <p className="button">
                <FormattedMessage {...messages.saveAsNewContact} />
              </p>
              <p className="button">
                <FormattedMessage {...messages.addToExistContact} />
              </p>
            </div>
          )}
        </div>
      )}
    />
  );
};

const mapStateToProps = store => ({});

const mapDispatchToProps = {
  openRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReceiveDetailsForm);
