/**
 * @author: Seleznyov
 */

import React, {Component} from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import messages from "../../messages";

import {
  dataBuy,
  dataSel,
  cryptoCurrency,
  currency,
  typeView,
  dataSelBuyLimit,
} from "shared/tradeService/selectors";
import {getDataSelBuy, setTypeView, setLimitView} from "shared/tradeService/actions";
import {BUY, SELL, MIX} from "shared/tradeService/actions/types";

import "./index.scss";
import OrderItem from "./OrderItem";
import OrderLast from "./OrderLast";

class Orders extends Component {
  constructor(props) {
    super(props);

    this.onChangeTypeView = this.onChangeTypeView.bind(this);

    this.state = {
      typeView: "mix",
    };
  }

  /**
   *
   */
  componentDidMount() {
    this.props.getDataSelBuy();
  }

  /**
   *
   * @param prevProps
   */
  componentDidUpdate(prevProps) {
    if (
      prevProps.limit !== this.props.limit ||
      prevProps.typeView !== this.props.typeView
    ) {
      this.props.getDataSelBuy();
    }
  }

  /**
   *
   * @param e
   */
  onChangeTypeView(e) {
    let _val = parseInt(e.target.value, 10);
    this.props.setTypeView(_val);
    this.props.setLimitView(_val === MIX ? 10 : 20);
  }

  render() {
    return (
      <div className="orders widget">
        <div className="widget__wrapper">
          <div className="widget__header">
            <div className="widget__header__title">
              <h2>
                <FormattedMessage {...messages.orders} />
              </h2>
            </div>
            <div className="widget__header__tools">
              <label>
                <input
                  type="radio"
                  id="order_mixed"
                  name="type_view"
                  value={MIX}
                  checked={this.props.typeView === MIX}
                  onChange={this.onChangeTypeView}
                />
                <span className="icons"></span>
              </label>

              <label>
                <input
                  type="radio"
                  id="order_sell"
                  name="type_view"
                  value={SELL}
                  checked={this.props.typeView === SELL}
                  onChange={this.onChangeTypeView}
                />
                <span className="icons"></span>
              </label>

              <label>
                <input
                  type="radio"
                  id="order_buy"
                  name="type_view"
                  value={BUY}
                  checked={this.props.typeView === BUY}
                  onChange={this.onChangeTypeView}
                />
                <span className="icons"></span>
              </label>
            </div>
          </div>

          <div className="widget__body">
            <div className="widget__body__head">
              <div className="title-item">
                <FormattedMessage {...messages.price} />,{" "}
                <span className="values">{this.props.currency}</span>
              </div>
              <div className="title-item">
                <FormattedMessage {...messages.amount} />,{" "}
                <span className="values">{this.props.cryptoCurrency}</span>
              </div>
              <div className="title-item">
                <FormattedMessage {...messages.total} />,{" "}
                <span className="values">{this.props.currency}</span>
              </div>
            </div>

            <div className="widget__body__content">
              <div className="data-items data-sel">
                {this.props.dataListSel
                  .slice(0, this.props.limit)
                  .map((item, key, array) => {
                    return (
                      <OrderItem
                        key={key + "_" + item.tradeUnitId}
                        item={item}
                        prevItem={array[key ? key - 1 : null]}
                      />
                    );
                  })}
              </div>

              <OrderLast />

              <div className="data-items data-buy">
                {this.props.dataListBuy
                  .slice(0, this.props.limit)
                  .map((item, key, array) => {
                    return (
                      <OrderItem
                        key={key + "_" + item.tradeUnitId}
                        item={item}
                        prevItem={array[key ? key - 1 : null]}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  dataListBuy: dataBuy(store),
  dataListSel: dataSel(store),
  cryptoCurrency: cryptoCurrency(store),
  currency: currency(store),
  typeView: typeView(store),
  limit: dataSelBuyLimit(store),
});

function mapDispatchToProps(dispatch) {
  return {
    getDataSelBuy: () => {
      dispatch(getDataSelBuy());
    },
    setTypeView: type => {
      dispatch(setTypeView(type));
    },
    setLimitView: limit => {
      dispatch(setLimitView(limit));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Orders);
