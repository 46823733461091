import React, {Fragment} from "react";
// Styles
import "../../index.scss";

const CookiesPolicy = () => {
  return (
    <Fragment>
      <div className="document-wrapper text__container_article">
        <div className="mt-6 text__container_title">Cookies</div>

        <div>
          Cookies are small text files stored on your computers main memory out of which a
          few are deleted after you close OnWallet and other cookies remain on your
          electronic device and permit its recognition on your next visit on OnWallet.
          These cookies help us improve your access to our website and help us learn more
          about your interest and provide you with essential features and services for
          additional purposes. OnWallet may use Cookies to collect information from the
          computer or device that you use to access our online services, such as your
          operating system type, browser type, domain and other system settings, as well
          as the language your system uses and the country and time zone in which your
          computer or device is located. We automatically collect certain information from
          and about the computers, phones, and other devices you use with our products and
          services and further may combine this information across different devices you
          use to provide uninterrupted OnWallet services throughout.
        </div>

        <div className="mt-6text__container_title">Types of cookies</div>

        <div className="mt-4">
          <span>Session Cookies -</span> These Cookies only retain information about a
          Users activity for as long as they are on the OnWallet website/application. Once
          an OnWallet website/application is closed, the cookies are deleted.
        </div>

        <div className="mt-4">
          <div>
            <span>Persistent Cookies - </span>These Cookies retain information about a
            Users activity even after they leave the OnWallet website/application. They
            store information which includes;
          </div>

          <ul>
            <li>Login and Password information,</li>
            <li>Credit and Debit Card information,</li>
            <li>User settings,</li>
            <li>Audio and display settings,</li>
            <li>Track information about the Users to improve advertising.</li>
          </ul>
        </div>

        <div>
          <span>Analytical cookies – </span>These cookies help us to understand how people
          are using our OnWallet website/application and services we offer, so we can make
          them better. These cookies are for the statistical purposes and provide us with
          aggregated information that we use to improve our website/application and
          services features. To measure performance of our website/application and
          services we may use third-party analytics software services which help develop
          the data and thereby improve our services and personalise the data.
        </div>

        <div className="mt-6 text__container_title">Consent for Cookies</div>

        <div>
          Consent to use Cookies on the OnWallet website/application is taken and you are
          notified. However, there are exceptions when it comes to essential Cookies,
          which are accepted by default as they are essential for us to provide you the
          Service you request for or which is of utmost importance for the functioning of
          the website/application.
        </div>

        <div className="mt-6 text__container_title">Third Party Cookies</div>

        <div>
          OnWallet website/application may contain links to other websites on the
          Internet, and other websites may contain links to our website/application. These
          third party websites/applications are not under our control, and this Policy
          does not cover the privacy and security practices of those third party
          operators. We are not responsible for the privacy or security practices or the
          content of such websites, and we recommend that you review the privacy practices
          of any third party website to which you use or submit your information.
        </div>

        <div className="mt-6 text__container_title">Amendments to Cookie Policy</div>

        <div>
          OnWallet reserves the right to change the terms of this Cookie Policy at any
          time when necessary to reflect changes in our use of the same, how we process
          Personal Data, or applicable law. When we make changes, we will revise the “last
          updated” date at the bottom of the policy. If there are material changes to this
          statement, we will provide notice or obtain consent regarding such changes as
          may be required by law. OnWallet encourages you to review this Cookie Policy
          whenever you visit the Website.
        </div>
      </div>
    </Fragment>
  );
};
export default CookiesPolicy;
