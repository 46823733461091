import React from "react";
import {connect} from "react-redux";
import {openRightModal} from "shared/components/RightModal/actions";
import {getCartItemsCount} from "shared/cartService/selectors";
import WalletsContainer from "./components/WalletsContainer";
import History from "./components/History";
import "./index.scss";
import {routes} from "shared/appUrls";

import Header from "shared/components/Header";

import {FormattedMessage} from "react-intl";
import messages from "./messages";

import TransferDetector from "./components/TransferDetector";
import FileViewer from "shared/components/FileViewer";

import {ReactComponent as TransferIcon} from "images/transfer-icon.svg";
import {ReactComponent as ReceiveIcon} from "images/receive-icon.svg";
import {ReactComponent as BuyIcon} from "images/buy-icon.svg";
import {ReactComponent as AddIcon} from "images/add-icon.svg";
import {ReactComponent as CartIcon} from "images/cart-icon.svg";

import config from "shared/config";
const logoPage = config.prefixImgUrl + "wallet-icon-light.svg";
const ownCardIcon = config.prefixImgUrl + "own-card-icon.svg";
const cardIcon = config.prefixImgUrl + "card-icon.svg";
const bankIcon = config.prefixImgUrl + "bank-icon.svg";
const globeIcon = config.prefixImgUrl + "globe-icon.svg";
const walletIcon = config.prefixImgUrl + "wallet-icon.svg";
const headerDehoIcon = config.prefixImgUrl + "header-deho-icon.svg";
const exchangeIcon = config.prefixImgUrl + "exhange-icon.svg";
const ownCardIconSecond = config.prefixImgUrl + "own-card-icon-second.svg";
const walletIconSecond = config.prefixImgUrl + "wallet-icon-second.svg";
const newCardIcon = config.prefixImgUrl + "new-card-icon.svg";
const newWalletIcon = config.prefixImgUrl + "new-wallet-icon.svg";
const linkAccountIcon = config.prefixImgUrl + "link-account-icon.svg";
const linkWalletIcon = config.prefixImgUrl + "link-wallet-icon.svg";
const receiveOnWalletIcon = config.prefixImgUrl + "recieve-onwallet-icon.svg";
const depositOnwalletIcon = config.prefixImgUrl + "onwallet-empty.svg";
const someoneDepositOnwalletIcon = config.prefixImgUrl + "onwallet-hand.svg";

const MainContent = ({openRightModal, cartItemsCount}) => {
  const navLinks = [
    {
      text: <FormattedMessage {...messages.headerTransfer} />,
      icon: className => <TransferIcon className={className} />,
      link: "#",
      subList: [
        {
          title: <FormattedMessage {...messages.depositOnwalletAccount} />,
          subTitle: <FormattedMessage {...messages.easyTransferToYourAccount} />,
          icon: depositOnwalletIcon,
          rightModal: () => openRightModal("transferToOwnOnWallet"),
        },
        {
          title: <FormattedMessage {...messages.toSomeoneOnWalletAccount} />,
          subTitle: <FormattedMessage {...messages.transferToAnyOnWalletAccount} />,
          icon: someoneDepositOnwalletIcon,
          rightModal: () => openRightModal("transferToSomeoneOnWallet"),
        },
        {
          title: <FormattedMessage {...messages.toOwnCard} />,
          subTitle: <FormattedMessage {...messages.transferEasyTransfer} />,
          icon: ownCardIcon,
          rightModal: () => openRightModal("transferOwnCard"),
        },
        {
          title: <FormattedMessage {...messages.transferToSomeoneCard} />,
          subTitle: <FormattedMessage {...messages.transferPayFast} />,
          icon: cardIcon,
          rightModal: () => openRightModal("transferSomeoneCard"),
        },
        {
          title: <FormattedMessage {...messages.transferBankDetails} />,
          subTitle: <FormattedMessage {...messages.transferToBankAccount} />,
          icon: bankIcon,
          rightModal: () => openRightModal("transferToBankDetails"),
        },
        {
          title: <FormattedMessage {...messages.transferInternationalPayment} />,
          subTitle: <FormattedMessage {...messages.transferPayAbroad} />,
          icon: globeIcon,
          rightModal: () => openRightModal("transferSWIFT"),
        },
        {
          title: <FormattedMessage {...messages.transferToCryptoWallet} />,
          subTitle: <FormattedMessage {...messages.transferFundsToPopular} />,
          icon: walletIcon,
          rightModal: () => openRightModal("transferOwnWallet"),
        },
      ],
    },
    {
      text: <FormattedMessage {...messages.headerReceive} />,
      icon: className => <ReceiveIcon className={className} />,
      link: "#",
      subList: [
        {
          title: <FormattedMessage {...messages.toOwnOnwalletAccount} />,
          subTitle: <FormattedMessage {...messages.createInvoiceWithYourAccount} />,
          icon: receiveOnWalletIcon,
          rightModal: () => openRightModal("receiveOnWallet"),
        },
        {
          title: <FormattedMessage {...messages.toOwnCard} />,
          subTitle: <FormattedMessage {...messages.transferCreateInvoiceWithCard} />,
          icon: ownCardIconSecond,
          rightModal: () => openRightModal("receiveCard"),
        },
        {
          title: <FormattedMessage {...messages.transferToCryptoWallet} />,
          subTitle: <FormattedMessage {...messages.transferCreateInvoiceWithCrypto} />,
          icon: walletIconSecond,
          rightModal: () => openRightModal("receiveWallet"),
        },
      ],
    },
    {
      text: <FormattedMessage {...messages.headerExchange} />,
      icon: className => <BuyIcon className={className} />,
      link: "#",
      subList: [
        {
          title: <FormattedMessage {...messages.exchangeBuyDeho} />,
          subTitle: <FormattedMessage {...messages.exchangeToLowestPrice} />,
          icon: headerDehoIcon,
          rightModal: () => openRightModal("exchangeDeho", "deho"),
        },
        {
          title: <FormattedMessage {...messages.headerExchange} />,
          subTitle: <FormattedMessage {...messages.exchangeCryptoToFiat} />,
          icon: exchangeIcon,
          rightModal: () => openRightModal("exchange"),
        },
      ],
    },
    {
      text: <FormattedMessage {...messages.headerAddNew} />,
      icon: className => <AddIcon className={className} />,
      link: "#",
      subList: [
        {
          title: <FormattedMessage {...messages.newCryptoWallet} />,
          subTitle: <FormattedMessage {...messages.exchangeCryptoToFiat} />,
          icon: newWalletIcon,
          rightModal: () => openRightModal("addNewWallet"),
        },
        {
          title: <FormattedMessage {...messages.addNewBankCard} />,
          subTitle: <FormattedMessage {...messages.attachYouExistingBankCards} />,
          icon: newCardIcon,
          rightModal: () => openRightModal("addNewCard"),
        },
        {
          title: <FormattedMessage {...messages.linkBankAccount} />,
          subTitle: <FormattedMessage {...messages.attachYourExistingBankAccount} />,
          icon: linkAccountIcon,
          rightModal: () => openRightModal("addBankAccount"),
        },
        {
          title: <FormattedMessage {...messages.linkThirdPartyWallet} />,
          subTitle: <FormattedMessage {...messages.attachYourExistingWallet} />,
          icon: linkWalletIcon,
          rightModal: () => openRightModal("addThirdWallet"),
        },
      ],
    },
    {
      text: <FormattedMessage {...messages.headerCart} />,
      icon: className => <CartIcon className={className} />,
      leftBorder: true,
      link: routes.cart,
      notifications: cartItemsCount,
    },
  ];

  return (
    <>
      <TransferDetector />
      <FileViewer />
      <Header
        headerTitle={<FormattedMessage {...messages.headerWallets} />}
        navLinks={navLinks}
        logoImage={logoPage}
      />
      <div className="main-content">
        <WalletsContainer />
        <div className="HistoryContainer">
          <History />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = store => ({
  cartItemsCount: getCartItemsCount(store),
});

const dispatchToProps = {
  openRightModal,
};

export default connect(mapStateToProps, dispatchToProps)(MainContent);
