import React from "react";
import {connect} from "react-redux";
import moment from "moment";

import {getOrdersHistory} from "shared/ordersHistoryService/selectors";

import "./index.scss";
import {routes} from "shared/appUrls";
import messages from "./messages";

import Header from "shared/components/Header";
import {FormattedMessage} from "react-intl";

import OrdersTable from "./OrdersTable";
import CustomDateRangePicker from "shared/components/DateRangePicker";
import Select from "shared/components/Select";

import config from "shared/config";
import {useEffect} from "react";
import {ReactComponent as OrdersListIcon} from "images/orders-list.svg";

const LeftArrowIcon = config.prefixImgUrl + "left-arrow-icon.svg";

const OrdersHistory = ({currentItems}) => {
  let [selectedItems, setSelectedItems] = React.useState(currentItems);
  const [dateStart, setDateStart] = React.useState("");
  const [dateEnd, setDateEnd] = React.useState("");
  const [sideData, setSideData] = React.useState("");
  const [pair1Data, setPair1Data] = React.useState("");
  const [pair2Data, setPair2Data] = React.useState("");

  const [side, setSide] = React.useState("");
  const [pair1, setPair1] = React.useState("");
  const [pair2, setPair2] = React.useState("");

  useEffect(() => {
    let newList = currentItems;
    if (dateStart !== "" && dateEnd !== "") {
      newList = newList.filter(
        item => moment(item.Date) > dateStart && moment(item.Date) < dateEnd
      );
    }
    if (sideData !== "") {
      newList = newList.filter(item => item.Side === sideData);
    }
    if (pair1Data !== "") {
      newList = newList.filter(item => item.Pair1 === pair1Data);
    }

    if (pair2Data !== "") {
      newList = newList.filter(item => item.Pair2 === pair2Data);
    }
    setSelectedItems(newList);
  }, [dateStart, dateEnd, sideData, pair1Data, pair2Data, currentItems]);

  const setSideValue = value => {
    setSide(value);
    if (value === "All") {
      setSideData("");
      return;
    }
    if (value === "Sell") {
      setSideData("0");
      return;
    }
    if (value === "Buy") {
      setSideData("1");
      return;
    }
  };
  const setPair1Value = value => {
    setPair1(value);
    if (value === "All") {
      setPair1Data("");
      return;
    }
    if (value === "BTC") {
      setPair1Data("BTC");
      return;
    }
    if (value === "DHO") {
      setPair1Data("DHO");
      return;
    }
  };
  const setPair2Value = value => {
    setPair2(value);
    if (value === "All") {
      setPair2Data("");
      return;
    }
    if (value === "USDT") {
      setPair2Data("USDT");
      return;
    }
    if (value === "USD") {
      setPair2Data("USD");
      return;
    }
  };

  const navLinks = [
    {
      text: <FormattedMessage {...messages.openedOrders} />,
      icon: className => <OrdersListIcon className={className} />,
      link: routes.orderlist,
    },
  ];

  const mapTableRows = rows => {
    return rows.map((row, index) => {
      return {
        cols: [
          <div style={{"min-width": "130px"}}>
            {row.Date && (
              <>
                <span className="source">
                  <span className="dots" />
                  {row.Date ? row.Date : ""}
                </span>
              </>
            )}
          </div>,
          <div>
            {row.Pair1 && (
              <>
                <span className="source">
                  <span className="dots" />
                  {row.Pair1 ? row.Pair1 : ""}
                </span>
              </>
            )}{" "}
            {"/"}{" "}
            {row.Pair2 && (
              <>
                <span className="source">
                  <span className="dots" />
                  {row.Pair2 ? row.Pair2 : ""}
                </span>
              </>
            )}
          </div>,
          <div style={{"font-weight": "500"}}>
            {row.Side === "0" ? (
              <>
                <span className="source">
                  <span className="dots" />
                  <div style={{color: "#FF3333"}}>{"Sell"}</div>
                </span>
              </>
            ) : (
              <>
                <span className="source">
                  <span className="dots" />
                  <div style={{color: "#2FA100"}}>{"Buy"}</div>
                </span>
              </>
            )}
          </div>,
          <div>
            {row.Price && (
              <>
                <span className="source">
                  <span className="dots" />
                  {row.Price ? row.Price : ""}
                </span>
              </>
            )}
          </div>,
          <div>
            {row.Avarage && (
              <>
                <span className="source">
                  <span className="dots" />
                  {row.Avarage ? row.Avarage : ""}
                </span>
              </>
            )}
          </div>,
          <div style={{"font-weight": "500"}}>
            {row.Amount && (
              <>
                <span className="source">
                  <span className="dots" />
                  {row.Amount ? row.Amount : ""}
                </span>
              </>
            )}
          </div>,
          <div>
            {row.Filled && (
              <>
                <span className="source">
                  <span className="dots" />
                  {row.Filled ? row.Filled : ""}
                </span>
              </>
            )}
          </div>,
          <div>
            {row.Total && (
              <>
                <span className="source">
                  <span className="dots" />
                  {row.Total ? row.Total : ""}
                </span>
              </>
            )}
          </div>,
          <div>
            {row.Status === "0" ? (
              <>
                <span className="source">
                  <span className="dots" />
                  {"Canceled"}
                </span>
              </>
            ) : (
              <>
                <span className="source">
                  <span className="dots" />
                  {"Filled"}
                </span>
              </>
            )}
          </div>,
        ],
      };
    });
  };

  const transactionsTableHeader = [
    <FormattedMessage {...messages.tableHeaderDate} />,
    <FormattedMessage {...messages.tableHeaderPair} />,
    <FormattedMessage {...messages.tableHeaderSide} />,
    <FormattedMessage {...messages.tableHeaderPrice} />,
    <FormattedMessage {...messages.tableHeaderAvarage} />,
    <FormattedMessage {...messages.tableHeaderAmount} />,
    <FormattedMessage {...messages.tableHeaderFilled} />,
    <FormattedMessage {...messages.tableHeaderTotal} />,
    <FormattedMessage {...messages.tableHeaderStatus} />,
  ];
  return (
    <>
      <Header
        headerTitle={<FormattedMessage {...messages.linkOrdersHistory} />}
        navLinks={navLinks}
        logoImage={LeftArrowIcon}
        link={routes.orderlist}
      />
      <div className="main-content-orderslist">
        <div className="OrdersHistoryOptions">
          <div className="ClosedOrders">
            <FormattedMessage {...messages.closedOrders} />
          </div>
          <div
            className="ResetFilters"
            onClick={() => {
              setSideData("");
              setSide("All");
              setPair1Data("");
              setPair1("All");
              setPair2Data("");
              setPair2("All");
              setDateStart(moment.now);
              setDateEnd(moment.now);
            }}
          >
            <FormattedMessage {...messages.resetFilters} />
          </div>
        </div>
        <div className="OrdersFilter">
          <div className="FilterOption placeDatesSelector" style={{width: 300}}>
            <CustomDateRangePicker
              style={{left: 0}}
              onChange={value => {
                setDateStart(value.start);
                setDateEnd(value.end);
              }}
              title={messages.filterPeriod}
            />
          </div>
          <div className="FilterOption">
            <label className="label">
              <FormattedMessage {...messages.filterPair} />
            </label>
            <Select
              value={pair1}
              options={["All", "BTC", "DHO"]}
              onChange={e => {
                setPair1Value(e.target.value);
              }}
            />
            <div className="PairSeparator">/</div>
            <Select
              value={pair2}
              options={["All", "USDT", "USD"]}
              onChange={e => {
                setPair2Value(e.target.value);
              }}
            />
          </div>
          <div className="FilterOption">
            <label className="label">
              <FormattedMessage {...messages.filterSide} />
            </label>
            <Select
              value={side}
              options={["All", "Sell", "Buy"]}
              onChange={e => {
                setSideValue(e.target.value);
              }}
            />
          </div>
        </div>

        <div>
          <OrdersTable
            headers={transactionsTableHeader}
            rows={mapTableRows(selectedItems)}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = store => ({
  currentItems: getOrdersHistory(store),
});

const dispatchToProps = {
  execute: "ORDERS_HISTORY_EXECUTE_SUCCESS",
};
export default connect(mapStateToProps, dispatchToProps)(OrdersHistory);
