export const OPEN_CONTACTS_MODAL = "OPEN_CONTACTS_MODAL";
export const CLOSE_CONTACTS_MODAL = "CLOSE_CONTACTS_MODAL";
export const UPDATE_SEARCH_VALUE_SHARED = "UPDATE_SEARCH_VALUE_SHARED";

export const openContactsModal = (name, title, sub, data, image, className) => ({
  type: OPEN_CONTACTS_MODAL,
  payload: {
    title,
    name,
    sub,
    data,
    image,
    className,
  },
});

export const updateSearchValueShared = search => ({
  type: UPDATE_SEARCH_VALUE_SHARED,
  search: search,
});

export const closeContactsModal = () => ({
  type: CLOSE_CONTACTS_MODAL,
});
