/**
 * @author: Seleznyov
 */
import React, {Component} from "react";
import {connect} from "react-redux";
import {openRightModal} from "shared/components/RightModal/actions";

class ActionsLogItem extends Component {

  render() {
    let item = this.props.item;
    return (
      <div className="table-ui__row">
        <div className="table-ui__item item-left">{item.id}</div>
        <div className="table-ui__item item-left">{item.dateTime}</div>
        <div className="table-ui__item item-left">{item.actionName}</div>
        <div className="table-ui__item item-left">{item.customer}</div>
        <div className="table-ui__item item-left">{item.originator}</div>
      </div>
    );
  }

}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  openRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionsLogItem);
