import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import config from "shared/config";
// Styles
import "./index.scss";
// Images
const closeIcon = config.prefixImgUrl + "close-icon.svg";

const DialogWithBlur = ({
  title,
  btnText,
  btnAction,
  children,
  btnCallIn,
  textCallIn,
  secondBtnText,
}) => {
  const [open, setOpen] = React.useState(false);
  const body = document.getElementById("root");

  const handleClickOpen = () => {
    setOpen(true);
    body.style = "filter: blur(2px)";
  };

  const handleClose = () => {
    setOpen(false);
    body.style = "filter: unset";
  };

  return (
    <>
      {!!btnCallIn && (
        <Button className="ui-btn ui-btn-info" onClick={handleClickOpen}>
          {btnCallIn}
        </Button>
      )}

      {!!textCallIn && <div onClick={handleClickOpen}>{textCallIn}</div>}

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        scroll="body"
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialog-modal"
      >
        <DialogContent>
          <div className="dialog-blur-title">
            <div className="dialog-blur-title__text">{title}</div>
            <img src={closeIcon} alt="personalLogo" onClick={handleClose} />
          </div>
          {children}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{secondBtnText}</Button>
          <Button onClick={btnAction ? btnAction : handleClose} color="primary">
            {btnText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogWithBlur;
