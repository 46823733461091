import {useEffect} from "react";

export const useClickOutside = (ref, action) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        action();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, action]);
};

export const userCopyToClipboard = ref => {
  return event => {
    if (ref && ref.current) {
      ref.current.select();
      document.execCommand("copy");
    }
  };
};
