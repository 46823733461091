import React from "react";
import {connect} from "react-redux";
import moment from "moment";
import ToggleMenu from "shared/components/ToggleMenu";
import {openRightModal} from "shared/components/RightModal/actions";
import {daysLeftDay} from "shared/tools";
import {ReactComponent as SettingIcon} from "images/settings-icon-gray.svg";
import "./index.scss";
import config from "shared/config";

const brandIcon = config.prefixImgUrl + "corporate-card-shopping.png";
const editIcon = config.prefixImgUrl + "edit-icon-short.svg";

const CardItem = ({item, openRightModal}) => {
  return (
    <>
      <div className="corporate-card-container">
        <div className="corporate-card-item">
          <img className="brand-logo" alt="" src={brandIcon}></img>
          <div className="info-layout">
            <div className="card-name">{item.title}</div>
            <div className="discount-layout">
              {!!item.percent && (
                <div className="discount">{`${item.percent}% Discount`}</div>
              )}
            </div>
            <div className="expiration">{`${daysLeftDay(
              item.expirationDate
            )} (till ${moment(item.expirationDate).format("DD MMMM, YYYY")})`}</div>
          </div>
          <div className="dots-container">
            <ToggleMenu
              className="popup"
              contentStyle="changeBackground"
              label={() => (
                <div className="dots">
                  <SettingIcon className="settingsIcon" />
                </div>
              )}
              children={
                <div
                  className="dots-container__edit"
                  onClick={() => {
                    openRightModal(
                      {
                        name: "shoppingCompanyCardEdit",
                        title: "Edit: Advanced Customer",
                      },
                      null,
                      item
                    );
                  }}
                >
                  <img src={editIcon} alt="edit" />
                  <div>Edit</div>
                </div>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = store => ({});

const mapDispatchToProps = {
  openRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardItem);
