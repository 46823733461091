import React from "react";
import messages from "../../../messages";
import {connect} from "react-redux";
// Actions
import {closeRightModal} from "shared/components/RightModal/actions";
// Components
import Button from "shared/components/Button";

const FifthRegisterStep = ({step, closeRightModal}) => {
  return (
    <div className="fifthStep">
      {step === 5 && (
        <>
          <div className="phraseInfo">
            A letter with instructions and temporary password was sent to customers'
            e-mail. All missing data will be asked after customer logins.
          </div>

          <div className="fifthStep__button">
            <Button
              type="button"
              className="nextButton"
              text={messages.close}
              onClick={() => closeRightModal()}
            />
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = store => ({});

const mapDispatchToProps = {
  closeRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(FifthRegisterStep);
