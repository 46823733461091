import React from "react";
import TopNavBar from "./Components/TopNavBar";
import Header from "./Components/Header";
import DehoInfoBar from "./Components/DehoInfoBar";
import ChartComposition from "./Components/Chart";
import CryptoInfoTable from "./Components/CryptoInfoTable";
import LatestEvents from "./Components/LatestEvents";

import "./index.scss";

const Dashboard = () => {
  return (
    <>
      <Header />
      <TopNavBar />
      <div className="main-dashboard-page">
        <div className="deho-info-bar-container">
          <DehoInfoBar />
        </div>
        <div className="chart-container">
          <ChartComposition />
        </div>
        <div className="markets-dynamic-history">
          <CryptoInfoTable />
        </div>
        <div className="latests-events">
          <LatestEvents />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
