import React from "react";
// import {FormattedMessage} from "react-intl";
import ToggleMenu from "shared/components/ToggleMenu";
import {ReactComponent as SettingIcon} from "images/settings-icon-gray.svg";
// Utils
import config from "shared/config";
// Styles
import "./index.scss";
// Images
const repeatIcon = config.prefixImgUrl + "repeat_icon.svg";

const TransactionMonitorTableItem = ({item}) => {
  return (
    <div className="transaction-monitor-table__body-items">
      <div className="transaction-monitor-table__body-items__item">{item.date}</div>
      <div className="transaction-monitor-table__body-items__item">
        <div className="transaction-monitor-table__body-items__item-main">
          {item.source.main}
        </div>
        <div className="transaction-monitor-table__body-items__item-sub">
          {item.source.sub}
        </div>
      </div>
      <div className="transaction-monitor-table__body-items__item">
        <div className="transaction-monitor-table__body-items__item-main">
          {item.recipient.main}
        </div>
        <div className="transaction-monitor-table__body-items__item-sub">
          {item.recipient.sub}
        </div>
      </div>
      <div className="transaction-monitor-table__body-items__item bold">
        {item.amount.toFixed(2)}
      </div>
      <div className="transaction-monitor-table__body-items__item">{item.currency}</div>
      <div className="transaction-monitor-table__body-items__item">
        <div className="transaction-monitor-table__body-items__item-main">
          {item.status.main}
        </div>
        <div className="transaction-monitor-table__body-items__item-sub">
          {item.status.sub}
        </div>
      </div>
      <div className="transaction-monitor-table__body-items__item">
        <ToggleMenu
          label={() => (
            <button className="staff-settings-icon">
              <SettingIcon />
            </button>
          )}
        >
          <div className="transaction-monitor-action">
            <img src={repeatIcon} alt="repeat_icon" />
            <div className="text">Revert Transaction</div>
          </div>
        </ToggleMenu>
      </div>
    </div>
  );
};

export default TransactionMonitorTableItem;
