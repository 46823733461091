const initialState = {
  formAddCard: {
    discountChk: false,
    discountAmount: 0,
    includedPointsChk: false,
    includedPointsAmount: 0,
    cardName: "",
    cardImage: "",
    expiryDate: null,
    validPeriod: "years",
    numberOff: "1",
    description: "",
  },
  voucherList: [],
  shoppingCardData: {},
  shoppingVoucherData: null,
  voucherQRCode: null,
};

export default initialState;
