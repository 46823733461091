import React from "react";
import {connect} from "react-redux";
import Button from "./Button";
import Input from "shared/components/Input";
import messages from "./messages";
import "./index.scss";

import {createNewOrder} from "shared/tradeService/actions/index";
import {useState} from "react";

const NewOrderItem = ({formTabs, market, mode, isBuy, createNewOrder}) => {
  const [price, setPrice] = useState(0);
  const [amount, setAmount] = useState(0);
  const [total, setTotal] = useState("");
  const [gold, setGold] = useState(0);

  React.useEffect(() => {
    document.getElementById("totalInput").value = total;
  }, [total]);
  React.useEffect(() => {
    if (document.getElementById("reserveInput")) {
      document.getElementById("reserveInput").value = gold;
    }
  }, [gold]);

  return (
    <>
      {formTabs && formTabs()}
      <div className="gorizontal">
        <div className="inputBlock" style={{marginLeft: "0px"}}>
          <label className="label">{"Price"}</label>
          <Input
            id="priceInput"
            type="text"
            onChange={e => {
              let gold;

              setPrice(e.target.value);

              setTotal(
                document.getElementById("amountInput").value *
                  document.getElementById("priceInput").value
              );

              gold =
                (document.getElementById("priceInput").value / 2 - 5) *
                document.getElementById("amountInput").value;
              if (gold < 0) {
                setGold(0);
              } else {
                setGold(gold);
              }
            }}
          ></Input>
        </div>
        <div className="inputBlock" style={{marginRight: "0px"}}>
          <label className="label" style={{marginRight: "0px"}}>
            {"Amount"}
          </label>
          <Input
            id="amountInput"
            type="text"
            onChange={e => {
              let gold;

              setAmount(e.target.value);

              setTotal(
                document.getElementById("amountInput").value *
                  document.getElementById("priceInput").value
              );

              gold =
                (document.getElementById("priceInput").value / 2 - 25) *
                document.getElementById("amountInput").value;
              if (gold < 0) {
                setGold(0);
              } else {
                setGold(gold);
              }
            }}
          ></Input>
        </div>
      </div>
      <div style={{display: market.code === "DHG" && !isBuy ? "flex" : "block"}}>
        <div
          className="inputBlock"
          style={{
            marginLeft: !document.getElementById("reserveInput") ? "0px" : "10px",
          }}
        >
          {market.code === "DHG" && !isBuy && (
            <>
              <label className="label">{"Gold Reserve Payment"}</label>
              <Input disabled="disabled" id="reserveInput" type="text"></Input>
            </>
          )}
        </div>
        <div
          className="inputBlockTotal"
          style={{
            width: !isBuy & (market.code === "DHG") ? "50%" : "",
            marginRight: "0px",
            marginLeft: !isBuy & (market.code === "DHG") ? "10px" : "0px",
          }}
        >
          <label className="label">{"Total"}</label>
          <Input id="totalInput" type="text"></Input>
        </div>
      </div>
      <Button
        style={{width: "100%"}}
        currencyCode={market.code}
        isBuy={isBuy}
        type="button"
        text={messages.buy}
        onClick={() => {
          let data = {
            price: Number(price),
            currency: "usd",
            amount: Number(amount),
            cryptoCurrency: market.code.toLowerCase(),
            type: isBuy ? 1 : 0,
          };

          createNewOrder(data);

          document.getElementById("priceInput").value = "";
          document.getElementById("amountInput").value = "";
          document.getElementById("totalInput").value = "";

          if (document.getElementById("reserveInput")) {
            document.getElementById("reserveInput").value = "";
          }
        }}
      ></Button>
    </>
  );
};

const mapStateToProps = store => ({});

const mapDispatchToProps = {
  createNewOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewOrderItem);
