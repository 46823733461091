export const LOGIN_PENDING = "LOGIN_PENDING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const DEVICE_SUCCESS = "DEVICE_SUCCESS";
export const DEVICE_FAILED = "DEVICE_FAILED";

export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const POPULATE_TOKEN_DATA = "POPULATE_TOKEN_DATA";

export const LOGOUT = "LOGOUT";

export const SIGN_UP_PENDING = "SIGN_UP_PENDING";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAILED = "SIGN_UP_FAILED";

export const USER_INFO_PENDING = "USER_INFO_PENDING";
export const USER_INFO_SUCCESS = "USER_INFO_SUCCESS";
export const USER_INFO_FAILED = "USER_INFO_FAILED";

export const USER_AVATAR_SUCCESS = "USER_AVATAR_SUCCESS";
export const USER_AVATAR_FAILED = "USER_AVATAR_FAILED";

export const FETCH_USER_PROFILE_PENDING = "FETCH_USER_PROFILE_PENDING";
export const FETCH_USER_PROFILE_SUCCESS = "FETCH_USER_PROFILE_SUCCESS";
export const FETCH_USER_PROFILE_FAILED = "FETCH_USER_PROFILE_FAILED";

export const UPDATE_USER_PROFILE_PENDING = "UPDATE_USER_PROFILE_PENDING";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAILED = "UPDATE_USER_PROFILE_FAILED";

export const SHARE_USER_PROFILE_PENDING = "SHARE_USER_PROFILE_PENDING";
export const SHARE_USER_PROFILE_SUCCESS = "SHARE_USER_PROFILE_SUCCESS";
export const SHARE_USER_PROFILE_FAILED = "SHARE_USER_PROFILE_FAILED";

export const GENERATE_PASSPHRASE_PENDING = "GENERATE_PASSPHRASE_PENDING";
export const GENERATE_PASSPHRASE_SUCCESS = "GENERATE_PASSPHRASE_SUCCESS";
export const GENERATE_PASSPHRASE_FAILED = "GENERATE_PASSPHRASE_FAILED";

export const COUNTRIES_DOC_SUCCESS = "COUNTRIES_DOC_SUCCESS";
export const COUNTRIES_DOC_FAILED = "COUNTRIES_DOC_FAILED";
