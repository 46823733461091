export const OPEN_QRCODE_MODAL = "OPEN_QRCODE_MODAL";
export const CLOSE_QRCODE_MODAL = "CLOSE_QRCODE_MODAL";

export const openQRCodeModal = (qr, title, meta) => ({
  type: OPEN_QRCODE_MODAL,
  payload: {
    qr,
    title,
    meta,
  },
});

export const closeQRCodeModal = () => ({
  type: CLOSE_QRCODE_MODAL,
});
