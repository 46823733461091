import React from "react";
import "./index.scss";

const Button = ({
  text,
  currencyCode,
  type = "submit",
  error,
  isBuy = false,
  className,
  disabled,
  ...props
}) => {
  return (
    <>
      <button
        type={type}
        className={`${isBuy ? "CustomSubmitButtonBuy" : "CustomSubmitButtonSell"} ${
          disabled ? "disabled" : ""
        }
        ${className ? className : ""}`}
        {...props}
      >
        {isBuy ? "BUY " + currencyCode : "SELL " + currencyCode}
      </button>
    </>
  );
};

export default Button;
