import React from "react";
import "./index.scss";

const MessageElement = ({id, onClick, status, time, heading, messageBody, selected}) => {
  return (
    <div
      onClick={onClick}
      id={id}
      className={`message__container_preview ${
        status ? "message__container_unread" : ""
      } ${selected ? "message__container_selected" : ""}`}
    >
      <div className="message__status_read"></div>
      <div className="message__time">{time}</div>
      <div className="message__heading">{heading}</div>
      <div
        className="message__body_short"
        dangerouslySetInnerHTML={{__html: messageBody}}
      ></div>
    </div>
  );
};

export default MessageElement;
