/**
 * @author: Seleznyov
 */

import React, {Component} from "react";
import {Scrollbars} from "react-custom-scrollbars";
import {FormattedMessage} from "react-intl";
import messages from "../../messages";

import {connect} from "react-redux";
import {cryptoCurrency, currency, dataTradeHistory} from "shared/tradeService/selectors";
import {getDataTradeHistory} from "shared/tradeService/actions";

import "./index.scss";
import TradeHistoryItem from "./TradeHistoryItem";

class TradeHistory extends Component {
  componentDidMount() {
    this.props.getDataTradeHistory();
  }

  render() {
    return (
      <div className="trade-history widget">
        <div className="widget__wrapper">
          <div className="widget__header">
            <div className="widget__header__title">
              <h2>
                <FormattedMessage {...messages.tradeHistory} />
              </h2>
            </div>
          </div>

          <div className="widget__body">
            <div className="widget__body__head">
              <div className="title-item">
                <FormattedMessage {...messages.price} />,{" "}
                <span className="values">{this.props.currency}</span>
              </div>
              <div className="title-item">
                <FormattedMessage {...messages.amount} />,{" "}
                <span className="values">{this.props.cryptoCurrency}</span>
              </div>
              <div className="title-item">
                <FormattedMessage {...messages.time} />
              </div>
            </div>

            <div className="widget__body__content">
              <Scrollbars style={{width: "100%", height: "inherit"}}>
                <div className="data-items">
                  {this.props.dataList.map((item, key) => {
                    return (
                      <TradeHistoryItem key={item.tradeHistoryId} item={item} idx={key} />
                    );
                  })}
                </div>
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  dataList: dataTradeHistory(store),
  cryptoCurrency: cryptoCurrency(store),
  currency: currency(store),
});

function mapDispatchToProps(dispatch) {
  return {
    getDataTradeHistory: () => {
      dispatch(getDataTradeHistory());
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(TradeHistory);
