import {ROLE} from "shared/constants";

const isDev = process.env.NODE_ENV !== "production";

export const routes = {
  home: "/home",
  wallets: "/wallets",
  login: "/login",
  landing: "/landing",
  register: "/register",
  cart: "/cart",
  eul: "/eul",
  agreement: "/agreement",
  privacyPolicy: "/privacy-policy",
  termsAndConditions: "/terms-conditions",
  websocket: "/websocket",
  dashboard: "/dashboard",
  dashboardAdmin: "/dashboard-admin",
  orderlist: "/orderlist",
  trading: "/trading",
  messages: "/messages",
  contacts: "/contacts",
  textpage: "/textpage",
  customers: "/customers",
  orderHistory: "/orderhistory",
  currentOrders: "/currentorders",
  recovery: "/recovery",
  details: "/details",
  accountSettings: "/settings",
  actionsHistory: "/action_history",
  orders: "/orders",
  metalSupply: "/metal-supply",
  coinRegistry: "/coin-registry",
  notfound: "/404",
  shopping: "/shopping",
  staff: "/staff",
  roles: "/roles",
  actionsApproval: "/actions-approval",
  approvalHistory: "/approval-history",
  actionsLog: "/actions-log",
  transactionMonitor: "/transaction-monitor",
  lastTransactions: "/last-transactions",
  legalDocs: "/legal-documents",
  legalDocsWithId: "/legal-documents/:id",
};

export const routesACL = {
  wallets: [ROLE.USER],
  dashboard: [ROLE.USER],
  transactionMonitor: [ROLE.MASTER_ACCOUNT],
  lastTransactions: [ROLE.MASTER_ACCOUNT],
  dashboardAdmin: [ROLE.MASTER_ACCOUNT],
  customers: [ROLE.MASTER_ACCOUNT],
  trading: [ROLE.USER],
  actionsHistory: [ROLE.USER],
  orders: [ROLE.GOLD_PROVIDER],
  coinRegistry: [ROLE.BANK, ROLE.MASTER_ACCOUNT],
  metalSupply: [ROLE.BANK, ROLE.MASTER_ACCOUNT],
  shopping: [ROLE.USER],
  contacts: [ROLE.USER],

  staff: [ROLE.MASTER_ACCOUNT],
  roles: [ROLE.MASTER_ACCOUNT],
  actionsApproval: [ROLE.MASTER_ACCOUNT],
  actionsLog: [ROLE.MASTER_ACCOUNT],
  approvalHistory: [ROLE.MASTER_ACCOUNT],
};

export const externalLinks = {
  // here put other external links
  appStoreLink: window.config.REACT_APP_APP_STORE_LINK,
  googlePlayLink: window.config.REACT_APP_GOOGLE_PLAY_LINK,
};

/* here put API urls */
const APILinksDef = {
  dev: {
    baseIDPUrl: window.config.REACT_APP_API_DEV_BASEIDPURL,
    baseAPIUrl: window.config.REACT_APP_API_DEV_BASEAPIURL,
    socketAPIUrl: window.config.REACT_APP_SOCKET_API_URL,
  },
  production: {
    baseIDPUrl: window.config.REACT_APP_API_PROD_BASEIDPURL,
    baseAPIUrl: window.config.REACT_APP_API_PROD_BASEAPIURL,
    socketAPIUrl: window.config.REACT_APP_SOCKET_PROD_API_URL,
  },
};

export const APILinks = isDev ? APILinksDef.dev : APILinksDef.production;
