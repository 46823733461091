export const CART_EXECUTE_PENDING = "CART_EXECUTE_PENDING";
export const CART_EXECUTE_SUCCESS = "CART_EXECUTE_SUCCESS";
// export const CART_EXECUTE_FAILED = "CART_EXECUTE_FAILED";

export const CART_EXECUTE_AGAIN = "CART_EXECUTE_AGAIN";

export const CART_CONFIRM_START = "CART_CONFIRM_START";
export const CART_ADD_ITEM = "CART_ADD_ITEM";
export const CART_REMOVE_ITEM = "CART_REMOVE_ITEM";
export const CART_REMOVE_FAILED_ITEM = "CART_REMOVE_FAILED_ITEM";
export const CART_FAILED_ITEM = "CART_FAILED_ITEM";
export const CART_SUCCESS_ITEM = "CART_SUCCESS_ITEM";
export const CART_CLEAR = "CART_CLEAR";
