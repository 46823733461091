import React from "react";
import "./index.scss";

const convertCardNumber = value => {
  const v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
  const matches = v.match(/\d{4,16}/g);
  console.log(matches);
  const match = (matches && matches[0]) || "";
  const parts = [];
  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }
  if (parts.length) {
    return parts.join(" ");
  } else {
    return v;
  }
};

const SimpleCardInput = ({className, onChange, withoutError, error, ...props}) => {
  return (
    <>
      <input
        type="text"
        className={`${className ? className : "input"} ${error ? "error" : ""}`}
        placeholder="Card Number"
        {...props}
        onChange={e => {
          e.target.value = convertCardNumber(e.target.value);
          onChange(e);
        }}
      />
      {!withoutError && error && <div className="inputErrorText">{error}</div>}
    </>
  );
};

export default SimpleCardInput;
