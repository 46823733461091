/**
 * @author: Seleznyov
 */
import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {openRightModal} from "shared/components/RightModal/actions";
import ToggleMenu from "shared/components/ToggleMenu";
import {ReactComponent as SettingIcon} from "images/settings-icon-gray.svg";
import messages from "../../messages";
import {FormattedMessage} from "react-intl";
import config from "shared/config";

// Images
const editIcon = config.prefixImgUrl + "edit-icon-short.svg";
const blockIcon = config.prefixImgUrl + "block_icon_black.svg";
const actionLogIcon = config.prefixImgUrl + "action_log_icon_black.svg";

class RolesListItem extends Component {
  render() {
    const actions = [
      {
        title: "edit",
        icon: editIcon,
        onClick: item =>
          this.props.openRightModal(
            {
              name: "editStaffMember",
              title: "Edit Staff Member",
              subTitle: `ID ${item.location.postcode}`,
            },
            null,
            item
          ),
      },
      {
        title: "block",
        icon: blockIcon,
        onClick: () => {},
      },
      {
        title: "actionsLog",
        icon: actionLogIcon,
        onClick: () => this.props.history.push("/actions-log"),
      },
    ];
    let {item} = this.props;

    return (
      <div className="table-ui__row">
        <div className="table-ui__item item-left ">
          <div className="item-image">
            <img src={item.picture.medium} alt="" />
          </div>
        </div>
        <div className="table-ui__item item-left">
          {item.name.first} {item.name.last}
        </div>
        <div className="table-ui__item item-left">{item.email}</div>
        <div className="table-ui__item item-left">Master</div>
        <div className="table-ui__item item-left">{item.location.postcode}</div>
        <div className="table-ui__item item-left">Active</div>
        <div className="table-ui__item item-right">
          <ToggleMenu
            label={() => (
              <button className="staff-settings-icon">
                <SettingIcon />
              </button>
            )}
          >
            {actions.map((action, index) => (
              <div
                className="staff-settings__actions"
                key={index}
                onClick={() => action.onClick(item)}
              >
                <img src={action.icon} className="icon" alt="icon" />
                <div className="text">
                  <FormattedMessage {...messages[action.title]} />
                </div>
              </div>
            ))}
          </ToggleMenu>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  openRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RolesListItem));
