/**
 * @author: Seleznyov
 */

import React, {Component} from "react";
import {connect} from "react-redux";
import {dataLastOrder} from "shared/tradeService/selectors";
import {BUY, SELL} from "shared/tradeService/actions/types";

class OrderLast extends Component {
  /**
   *
   * @returns {*}
   */
  render() {
    let price = this.props.dataLastOrder.price;
    price = price
      ? price
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : "";
    return (
      <div className="data-items data-info">
        <span
          className={
            this.props.dataLastOrder.type === SELL
              ? "sell"
              : this.props.dataLastOrder.type === BUY
              ? "buy"
              : ""
          }
        >
          {price}
          <div className="icon-arrow"></div>
        </span>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  dataLastOrder: dataLastOrder(store),
});

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderLast);
