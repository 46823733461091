import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import messages from "./messages";
import {getDehoWallets, getWalletIconByName} from "shared/walletService/selectors";
import {FormattedMessage} from "react-intl";
// Components
import Header from "shared/components/Header";
import CoinRegistryBody from "./components/CoinRegistryBody";
import config from "shared/config";
// Styles
import "./index.scss";
// Images
const dehoTitleLogo = config.prefixImgUrl + "deho_coin_registry.svg";

const CoinRegistryPage = ({dehoWallets, getWalletIconByName}) => {
  return (
    <div>
      <Header
        headerTitle={<FormattedMessage {...messages.coinRegistry} />}
        navLinks={[]}
        logoImage={dehoTitleLogo}
        middleStyle="coin-registry-header"
      >
        <div className="wallets">
          {!!dehoWallets.length &&
            dehoWallets.map(wallet => (
              <div className="wallets__wallet" key={wallet.address}>
                <div className="wallets__wallet-logo">
                  <img src={getWalletIconByName(wallet.walletName)} alt="Logo wallet" />
                </div>

                <div className="wallets__wallet-description">
                  <div className="wallets__wallet-balance">
                    {`${wallet.balance} ${wallet.currency.toUpperCase()}`}
                  </div>
                  <div className="wallets__wallet-name">{wallet.walletName}</div>
                </div>
              </div>
            ))}
        </div>
      </Header>
      {/* Content */}
      {!!dehoWallets.length && <CoinRegistryBody dehoWallets={dehoWallets} />}
    </div>
  );
};

// Types
CoinRegistryPage.propTypes = {
  dehoWallets: PropTypes.array.isRequired,
  getWalletIconByName: PropTypes.func,
};

// Redux
const mapStateToProps = store => ({
  dehoWallets: getDehoWallets(store),
  getWalletIconByName: name => getWalletIconByName(name),
});

const mapDispatchToProps = {
  // TODO
};

export default connect(mapStateToProps, mapDispatchToProps)(CoinRegistryPage);
