/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import messages from "./messages";
import "./index.scss";
import Header from "shared/components/Header";
import {openRightModal} from "shared/components/RightModal/actions";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {selectUserProfile, getUserMainAvatar} from "shared/auth/selectors";
import {getOnwalletAccount} from "shared/cardService/selectors";
import {uploadUserAvatar, getUserAvatar} from "shared/auth/actions";
import PersonalDetailsModals from "./components/PersonalDetailsModals";
import config from "shared/config";

const logoPage = config.prefixImgUrl + "personal-logo.svg";
const avatarDefault = config.prefixImgUrl + "avatar-default-light.svg";
const imageIcon = config.prefixImgUrl + "image-icon.svg";

const PersonalDetails = ({
  openRightModal,
  userProfile,
  userAvatar,
  uploadUserAvatar,
  onWalletAccount,
  getUserAvatar,
}) => {
  const [changeName, setChangeName] = useState(false);
  const [changeAddress, setChangeAddress] = useState(false);
  const [avatar, setAvatar] = useState(avatarDefault);
  const [isLoading, setLoading] = useState(true);
  const ADVANCED = "Advanced";

  useEffect(() => {
    if (onWalletAccount && !!onWalletAccount.account)
      getUserAvatar(onWalletAccount.account);
  }, []);

  useEffect(() => {
    if (!!userAvatar) {
      const avatar = window.URL.createObjectURL(userAvatar);
      setAvatar(avatar);
      setLoading(false);
    }
  }, [isLoading, onWalletAccount, userAvatar]);

  const handlerUploadImage = ({files}) => {
    if (files && files.length > 0) {
      const avatar = window.URL.createObjectURL(files[0]);
      uploadUserAvatar(files[0], onWalletAccount);
      setAvatar(avatar);
    }
  };

  return (
    <>
      <PersonalDetailsModals
        setChangeName={setChangeName}
        setChangeAddress={setChangeAddress}
      />
      <Header
        headerTitle={<FormattedMessage {...messages.personalDetails} />}
        navLinks={[]}
        logoImage={logoPage}
      />
      <div className="PersonalDetailsWrapper">
        <div className="PersonalDetailsContainer">
          <div className="AvatarContainer avatar">
            <div className="avatarImage">
              <img src={avatar} alt="avatar" />
            </div>
            <div className="changeAvatar">
              <img src={imageIcon} alt="change" />
              <input
                type="file"
                className="avatarInput"
                onChange={e => handlerUploadImage(e.target)}
              />
            </div>
          </div>
          <div className="DetailsContainer details">
            <div className="detailsContainer">
              <div className="detailsTitle">
                <div className="text name">
                  {(userProfile && userProfile.firstName) || "Unknown"}{" "}
                  {(userProfile && userProfile.lastName) || "Unknown"}
                </div>
                <button
                  className="button"
                  type="button"
                  onClick={() => openRightModal("changeName")}
                >
                  <FormattedMessage {...messages.change} />
                </button>
              </div>
            </div>
            {changeName && (
              <div className="changeInfo">
                <FormattedMessage {...messages.yourRequestToChangeName} />
              </div>
            )}
            <div className="separator" />
            <div className="detailsContainer">
              <div className="detailsTitle">
                <div className="text">
                  {(userProfile && userProfile.phone) || "Unknown phone"}
                </div>
                <button
                  className="button"
                  type="button"
                  onClick={() => openRightModal("changePhone")}
                >
                  <FormattedMessage {...messages.change} />
                </button>
              </div>
              <div className="detailsSubTitle">
                <FormattedMessage {...messages.cellPhoneNumber} />
              </div>
            </div>
            <div className="separator" />
            <div className="detailsContainer">
              <div className="detailsTitle">
                <div className="text">{userProfile && userProfile.email}</div>
              </div>
              <div className="detailsSubTitle">
                <FormattedMessage {...messages.email} />
              </div>
            </div>
            <div className="separator" />
            <div className="detailsContainer">
              <div className="detailsTitle">
                <div className="text">
                  {userProfile && userProfile.apartment}{" "}
                  {userProfile && userProfile.street}, {userProfile && userProfile.city},{" "}
                  {userProfile && userProfile.region},{" "}
                  {userProfile && userProfile.country}, {userProfile && userProfile.zip}
                </div>
                <button
                  className="button"
                  type="button"
                  onClick={() => openRightModal("changeAddress")}
                >
                  <FormattedMessage {...messages.change} />
                </button>
              </div>
              <div className="detailsSubTitle">
                <FormattedMessage {...messages.legalAddress} />
              </div>
            </div>
            {changeAddress && (
              <div className="changeInfo">
                <FormattedMessage {...messages.yourRequestToChangeAddress} />
              </div>
            )}
            {/* Field Company Name */}
            {userProfile && userProfile.roles.includes(ADVANCED) && (
              <div className="detailsContainer">
                <div className="detailsTitle">
                  <div className="text">{userProfile.companyName}</div>
                  <button
                    className="button"
                    type="button"
                    onClick={() => openRightModal("changeCompanyName")}
                  >
                    <FormattedMessage {...messages.change} />
                  </button>
                </div>
                <div className="detailsSubTitle">
                  <FormattedMessage {...messages.companyName} />
                </div>
              </div>
            )}
          </div>
          <div className="MessageContainer message">
            <div className="messageTitle">
              <FormattedMessage {...messages.changePersonalDetails} />
            </div>
            <div className="messageBody">
              <FormattedMessage {...messages.messageText} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = store => ({
  userProfile: selectUserProfile(store),
  onWalletAccount: getOnwalletAccount(store),
  userAvatar: getUserMainAvatar(store),
});

const dispatchToProps = {
  openRightModal,
  uploadUserAvatar: (image, onWalletAccount) => uploadUserAvatar(image, onWalletAccount),
  getUserAvatar: onWalletAccount => getUserAvatar(onWalletAccount),
};

export default connect(mapStateToProps, dispatchToProps)(PersonalDetails);
