import React from "react";
import "../../index.scss";
import {connect} from "react-redux";
import {Formik, Field, Form} from "formik";
import * as Yup from "yup";
import {FormattedMessage} from "react-intl";
import messages from "components/AccountSettings/messages";
import Input from "shared/components/Input";
import LinkButton from "shared/components/Button/LinkButton";
// import {routes} from "shared/appUrls";
import PasswordInput from "shared/components/PasswordInput";

import {closeRightModal} from "shared/components/RightModal/actions";
import {changeUserPsw} from "shared/auth/actions";
import Button from "shared/components/Button";

import {selectUserProfile} from "shared/auth/selectors";

const initialValues = {
  current_password: "",
  password: "",
  confirmPassword: "",
};

const RecoveryWithEmailSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(
      "^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{8,}$",
      "Password must contain uppercase and lowercase chars and digits"
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Password not equals")
    .required("Confirm password is required"),
});

const ResetPasswordForm = ({
  userProfile,
  closeRightModal,
  setChangeAddress,
  changeUserPsw,
}) => {
  console.log(userProfile, "userProfile");
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={RecoveryWithEmailSchema}
      onSubmit={(values, {resetForm}) => {
        changeUserPsw({
          password: values.password,
          confirm_password: values.confirmPassword,
          current_password: values.current_password,
        });
        closeRightModal();
        setChangeAddress(true);
        resetForm();
      }}
    >
      {({errors, touched, values}) => (
        <Form className="recoveryForm">
          <div className={"modal_block"}>
            <Field
              name="current_password"
              render={({field}) => (
                <div className="inputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.currentPassword} />
                  </label>
                  <PasswordInput error={touched.password && errors.password} {...field} />
                </div>
              )}
            />
          </div>
          <div className={"modal_block"}>
            <Field
              name="password"
              render={({field}) => (
                <div className="inputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.newPassword} />
                  </label>
                  <PasswordInput error={touched.password && errors.password} {...field} />
                </div>
              )}
            />
            <p className="passwordMessage">
              <FormattedMessage {...messages.useBothUppercase} />
            </p>
          </div>
          <div className={"modal_block"}>
            <Field
              name="confirmPassword"
              render={({field}) => (
                <div className="inputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.confirmNewPassword} />
                  </label>
                  <Input
                    type="password"
                    error={touched.confirmPassword && errors.confirmPassword}
                    {...field}
                    power="bad"
                  />
                </div>
              )}
            />
          </div>
          <div className="buttonsContainer">
            <LinkButton
              text={messages.cancel}
              isSecondary="true"
              onClick={() => {
                closeRightModal();
              }}
            />
            <Button
              type="sumbit"
              disabled={
                !values.current_password ||
                !values.password ||
                !values.confirmPassword ||
                errors.current_password ||
                errors.password ||
                errors.confirmPassword
              }
              // link={routes.login}
              text={messages.saveNewPassword}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = store => ({
  userProfile: selectUserProfile(store),
});

const mapDispatchToProps = {
  closeRightModal,
  changeUserPsw,
};
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
