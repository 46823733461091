/**
 * @author: Seleznyov
 */
import React, {Component} from "react";
import {connect} from "react-redux";
import {openRightModal} from "shared/components/RightModal/actions";
import ToggleMenu from "shared/components/ToggleMenu";
import messages from "../../messages";
import {ReactComponent as SettingIcon} from "images/settings-icon-gray.svg";
import {FormattedMessage} from "react-intl";
import config from "shared/config";

// Images
const editIcon = config.prefixImgUrl + "edit-icon-short.svg";
const deleteIcon = config.prefixImgUrl + "delete-icon.svg";

class StaffListItem extends Component {
  render() {
    let item = this.props.item;
    return (
      <div className="table-ui__row">
        <div className="table-ui__item item-left">{item.name}</div>
        <div className="table-ui__item item-left">{item.desc}</div>
        <div className="table-ui__item item-right">
          <ToggleMenu
            label={() => (
              <button className="staff-settings-icon">
                <SettingIcon />
              </button>
            )}
          >
            <div
              className="roles-page__actions"
              onClick={() =>
                this.props.openRightModal(
                  {
                    name: "editRole",
                    title: `Edit Role ${item.name}`,
                  },
                  null,
                  item
                )
              }
            >
              <img src={editIcon} className="icon" alt="icon" />
              <div className="text">
                <FormattedMessage {...messages.edit} />
              </div>
            </div>

            <div className="roles-page__actions">
              <img src={deleteIcon} className="icon" alt="icon" />
              <div className="text">
                <FormattedMessage {...messages.delete} />
              </div>
            </div>
          </ToggleMenu>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  openRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffListItem);
