/**
 * @author: Seleznyov
 */
import React, {Component} from "react";
import messages from "../../messages";
import {FormattedMessage} from "react-intl";
import AcceptedOrdersItem from "../AcceptedOrders/item";

class AcceptedOrders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemsOrder: [],
    };
  }

  componentDidMount() {
    this.getDataList();
  }

  getDataList() {
    let _data = [];
    for (let i = 1; i < 16; i++) {
      _data[i] = {
        id: i,
        dateTime:
          parseInt(this.getRandom(1, 30), 10) +
          " " +
          parseInt(this.getRandom(1, 9), 10) +
          " 2020 | " +
          parseInt(this.getRandom(1, 24), 10) +
          ":" +
          parseInt(this.getRandom(0, 59), 10),
        orderedMetal: parseInt(this.getRandom(1, 4), 10),
        weight: 5,
        price: this.getRandom(900, 70000),
        orderAmount: this.getRandom(4000, 400000),
        status: parseInt(this.getRandom(1, 4), 10),
      };
    }
    this.setState({itemsOrder: _data});
  }

  getRandom(min, max) {
    return Math.random() * (max - min) + min;
  }

  render() {
    return (
      <div className="accepted-orders-section">
        <div className="card">
          <div className="card__header">
            <h3>
              <FormattedMessage {...messages.acceptedOrders} />
            </h3>
          </div>
          <div className="card__body">
            <div className="table-ui">
              <div className="table-ui__header">
                <div className="table-ui__row">
                  <div className="table-ui__item item-left">#</div>
                  <div className="table-ui__item item-left">
                    <FormattedMessage {...messages.dateTime} />
                  </div>
                  <div className="table-ui__item item-left">
                    <FormattedMessage {...messages.orderedMetal} />
                  </div>
                  <div className="table-ui__item item-right">
                    <FormattedMessage {...messages.weight} />
                  </div>
                  <div className="table-ui__item item-right">
                    <FormattedMessage {...messages.priceKg} />
                  </div>
                  <div className="table-ui__item item-right">
                    <FormattedMessage {...messages.orderAmount} />
                  </div>
                  <div className="table-ui__item item-right">
                    <FormattedMessage {...messages.status} />
                  </div>
                </div>
              </div>
              <div className="table-ui__body">
                {this.state.itemsOrder.map((item, key) => {
                  return <AcceptedOrdersItem key={key} item={item} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AcceptedOrders;
