import {OPEN_ERROR_MODAL, CLOSE_ERROR_MODAL} from "./actions";

const initState = {
  show: false,
  error: null,
  mainAction: null,
  secondAction: null,
};

const errorModalData = (state = initState, action) => {
  switch (action.type) {
    case CLOSE_ERROR_MODAL:
      return {...initState};
    case OPEN_ERROR_MODAL:
      return {
        ...state,
        show: true,
        error: action.payload.error,
        mainAction: action.payload.main,
        secondAction: action.payload.secondAction,
      };
    default:
      return state;
  }
};

export {errorModalData};
