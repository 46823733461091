import React from "react";
import {connect} from "react-redux";
// Components
import RightModal from "shared/components/RightModal";
import OrderDescriptionModal from "./OrderDescriptionModal";
import OrderConfirmModal from "./OrderConfirmModal";
import {closeRightModal} from "shared/components/RightModal/actions";

const modalVariants = {
  orderDetails: "orderDetails",
  orderConfirm: "orderConfirm",
};

const MetalSupplyDetailsModals = ({modalData, closeRightModal}) => {
  if (modalData === null) {
    modalData = {
      title: "none",
      name: "none",
      sub: "none",
      buttonType: "",
    };
  }

  return (
    <>
      {modalData.title && (
        <RightModal
          title={modalData && modalData.title ? modalData.title : null}
          subTitle={modalData && modalData.subTitle ? modalData.subTitle : null}
          buttonType={modalData && modalData.buttonType ? modalData.buttonType : null}
        >
          <div>
            {modalData.name === modalVariants.orderDetails && <OrderDescriptionModal />}
            {modalData.name === modalVariants.orderConfirm && (
              <OrderConfirmModal close={() => closeRightModal()} />
            )}
          </div>
        </RightModal>
      )}
    </>
  );
};

const mapStateToProps = store => ({
  modalData: store.rightModalData.variant,
});

const mapDispatchToProps = {
  closeRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(MetalSupplyDetailsModals);
