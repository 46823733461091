import {defineMessages} from "react-intl";

const scope = "RegisterPage";

const messages = defineMessages({
  onWalletRegistration: {
    id: `${scope}.onWalletRegistration`,
    defaultMessage: "Registration",
  },
  copyRight: {
    id: `${scope}.copyRight`,
    defaultMessage: "Copyright © 2021 OnWallet. All rights reserved.",
  },
  generalInfo: {
    id: `${scope}.generalInfo`,
    defaultMessage: "General Info",
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: "Edit",
  },
  privatePerson: {
    id: `${scope}.privatePerson`,
    defaultMessage: "Private Person",
  },
  company: {
    id: `${scope}.company`,
    defaultMessage: "Company",
  },
  person: {
    id: `${scope}.person`,
    defaultMessage: "Person",
  },
  next: {
    id: `${scope}.next`,
    defaultMessage: "Next",
  },
  password: {
    id: `${scope}.password`,
    defaultMessage: "Password",
  },
  confirmPassword: {
    id: `${scope}.confirmPassword`,
    defaultMessage: "Confirm Password",
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: "E-mail",
  },
  businessEmail: {
    id: `${scope}.businessEmail`,
    defaultMessage: "Business E-mail",
  },
  companySite: {
    id: `${scope}.companySite`,
    defaultMessage: "Company Site (optional)",
  },
  specialization: {
    id: `${scope}.specialization`,
    defaultMessage: "Account Specialization",
  },
  firstName: {
    id: `${scope}.firstName`,
    defaultMessage: "First Name",
  },
  lastName: {
    id: `${scope}.lastName`,
    defaultMessage: "Last Name",
  },
  usersFirstName: {
    id: `${scope}.usersFirstName`,
    defaultMessage: "Users's First Name",
  },
  usersLastName: {
    id: `${scope}.usersLastName`,
    defaultMessage: "User's Last Name",
  },
  companyName: {
    id: `${scope}.companyName`,
    defaultMessage: "Company Name",
  },
  legalAddress: {
    id: `${scope}.legalAddress`,
    defaultMessage: "Legal Address",
  },
  legalDocuments: {
    id: `${scope}.legalDocuments`,
    defaultMessage: "Legal Documents",
  },
  country: {
    id: `${scope}.country`,
    defaultMessage: "Country",
  },
  zip: {
    id: `${scope}.zip`,
    defaultMessage: "Postal / Zip Code",
  },
  street: {
    id: `${scope}.street`,
    defaultMessage: "Street",
  },
  apartment: {
    id: `${scope}.apartment`,
    defaultMessage: "Apartment, Unit, ets",
  },
  region: {
    id: `${scope}.region`,
    defaultMessage: "State/Province/Region",
  },
  city: {
    id: `${scope}.city`,
    defaultMessage: "City",
  },
  toConfirmAddress: {
    id: `${scope}.toConfirmAddress`,
    defaultMessage:
      "To confirm this is your address, please, attach last bill of electricity provider for this address.",
  },
  attachBill: {
    id: `${scope}.attachBill`,
    defaultMessage: "Attach bill",
  },
  add: {
    id: `${scope}.add`,
    defaultMessage: "Add",
  },
  companyRegistrationCertificate: {
    id: `${scope}.companyRegistrationCertificate`,
    defaultMessage: " Company Registration Certificate",
  },
  memorandumOfAssociation: {
    id: `${scope}.memorandumOfAssociation`,
    defaultMessage: "Memorandum of Association",
  },
  taxID: {
    id: `${scope}.taxID`,
    defaultMessage: "Tax ID",
  },
  companyNumber: {
    id: `${scope}.companyNumber`,
    defaultMessage: "Company Number",
  },
  personValidation: {
    id: `${scope}.personValidation`,
    defaultMessage: "Person Validation",
  },
  toConfirmYourIdentity: {
    id: `${scope}.toConfirmYourIdentity`,
    defaultMessage: "To confirm your identity, please attach:",
  },
  scanCopyOfYourPassport: {
    id: `${scope}.scanCopyOfYourPassport`,
    defaultMessage: "1. Scan copy of your passport or ID Card or Driving License.",
  },
  freshPhotoFYourFace: {
    id: `${scope}.freshPhotoFYourFace`,
    defaultMessage:
      "2. Fresh photo of your face according to requirements of standard ID photo",
  },
  skipThisTime: {
    id: `${scope}.skipThisTime`,
    defaultMessage: "Skip this time",
  },
  youWillBePrompt: {
    id: `${scope}.youWillBePrompt`,
    defaultMessage:
      "You will be prompted to confirm your identity later, before executing any financial operation",
  },
  mobileNumberValidation: {
    id: `${scope}.mobileNumberValidation`,
    defaultMessage: "Mobile Number Validation",
  },
  phoneNumber: {
    id: `${scope}.phoneNumber`,
    defaultMessage: "Phone Number",
  },
  mobileNumber: {
    id: `${scope}.mobileNumber`,
    defaultMessage: "Mobile Number",
  },
  weWillSendOnThisNumberSMS: {
    id: `${scope}.weWillSendOnThisNumberSMS`,
    defaultMessage: "We will send on this number SMS with confirmation pass code.",
  },
  weSentOnThisPhoneSMS: {
    id: `${scope}.weSentOnThisPhoneSMS`,
    defaultMessage:
      "We sent on this phone SMS with confirmation pass code. Enter confirmation pass code in the fields below.",
  },
  sendCode: {
    id: `${scope}.sendCode`,
    defaultMessage: "Send code",
  },
  iHaveIssue: {
    id: `${scope}.iHaveIssue`,
    defaultMessage: "I have issue with receiving of this pass code",
  },
  sendNewCode: {
    id: `${scope}.sendNewCode`,
    defaultMessage: "send new code",
  },
  weGeneratedRecoveryPhrase: {
    id: `${scope}.weGeneratedRecoveryPhrase`,
    defaultMessage:
      " We generated a recovery phrase for you in case you forget your password. Copy and save it secretly.",
  },
  iHaveSavedPhrase: {
    id: `${scope}.iHaveSavedPhrase`,
    defaultMessage: "I have saved the recovery phrase",
  },
  letsGetStarted: {
    id: `${scope}.letsGetStarted`,
    defaultMessage: "Let's get started",
  },
  onWalletRegistrationFinished: {
    id: `${scope}.onWalletRegistrationFinished`,
    defaultMessage: "Registration Finished",
  },

  dialogtitlePrivate: {
    id: `${scope}.dialogtitlePrivate`,
    defaultMessage: "Private Person Account Specialization",
  },
  dialogtitleCompany: {
    id: `${scope}.dialogtitleCompany`,
    defaultMessage: "Company Account Specialization",
  },
  dialogBtntext: {
    id: `${scope}.dialogBtntext`,
    defaultMessage: "Ok, Got It",
  },
  dialogContent: {
    id: `${scope}.dialogContent`,
    defaultMessage: "<h2>General Purpose</h2><p>A person without specific trading needs",
  },
  emailPassCode: {
    id: `${scope}.emailPassCode`,
    defaultMessage: "We will sent a letter on this address with pass code.",
  },
  checkMailPassCode: {
    id: `${scope}.checkMailPassCode`,
    defaultMessage: "Check your mail and enter pass code in the fields below.",
  },
  codeWillExpire: {
    id: `${scope}.codeWillExpire`,
    defaultMessage: "Code will expire in ",
  },
  passwordNote: {
    id: `${scope}.passwordNote`,
    defaultMessage:
      "Use both upper-case and lower-case letters, one or more numerical digits",
  },
  userExists: {
    id: `${scope}.userExists`,
    defaultMessage: "User Exists",
  },
  sendEmailCode: {
    id: `${scope}.sendEmailCode`,
    defaultMessage: "Send",
  },
  verifyCode: {
    id: `${scope}.verifyCode`,
    defaultMessage: "Verify Code",
  },
  frontSideOfPersonIdCard: {
    id: `${scope}.frontSideOfPersonIdCard`,
    defaultMessage: "Front side of person ID card",
  },
  backSideOfPersonIdCard: {
    id: `${scope}.backSideOfPersonIdCard`,
    defaultMessage: "Back side of person ID card",
  },
  freshPhotoCustomer: {
    id: `${scope}.freshPhotoCustomer`,
    defaultMessage: "Fresh photo of customers' face",
  },
});

export default messages;
