import React from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import {addNewRegularCard} from "shared/cardService/actions";

import {fetchAllRegularCurrency} from "shared/cardService/actions";
import "./index.scss";
import FormSectionSeparator from "shared/components/FormSectionSeparator";

import messages from "../../messages";
import Select from "shared/components/Select";
import Button from "shared/components/Button";
import {WalletProviderOption} from "shared/components/CardWalletOptions";
import Input from "shared/components/Input";

import config from "shared/config";
const walletOneIcon = config.prefixImgUrl + "walletone-logo.svg";

const WalletProvides = [
  {
    name: "Wallet One",
    icon: walletOneIcon,
  },
];
const initialValues = {
  provider: null,
  login: "",
  password: "",
};

const AddBankAccountSchema = Yup.object().shape({
  provider: Yup.object()
    .nullable()
    .default(null)
    .required("Provider is required"),
});

const AddThirdWallet = () => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddBankAccountSchema}
      onSubmit={(values, actions) => {}}
    >
      {({errors, touched, values}) => (
        <Form className="AddThirdWallet">
          <Field
            name="provider"
            render={({field}) => (
              <div className="providerInputContainer">
                <label className="label">
                  <FormattedMessage {...messages.bank} />
                </label>
                <Select
                  className="select"
                  error={touched.provider && errors.provider ? errors.provider : ""}
                  {...field}
                  renderValue={value =>
                    !!value ? (
                      <WalletProviderOption className="short" provider={value} />
                    ) : (
                      ""
                    )
                  }
                >
                  {WalletProvides.map((provider, index) => (
                    <WalletProviderOption
                      key={index}
                      value={provider}
                      provider={provider}
                    />
                  ))}
                </Select>
              </div>
            )}
          />
          {values.provider && (
            <>
              <FormSectionSeparator sectionName={messages.walletLogin} />
              <WalletOneLogin
                provider={values.provider}
                errors={errors}
                touched={touched}
              />
            </>
          )}
          <Button text={messages.linkWallet} />
        </Form>
      )}
    </Formik>
  );
};

const WalletOneLogin = ({provider, touched, errors}) => {
  return (
    <div className="WalletOneContainer">
      <div className="title">
        <img className="icon" src={provider.icon} alt="icon" />
      </div>
      <Field
        name="login"
        render={({field}) => (
          <div className="inputContainer">
            <label className="label">
              <FormattedMessage {...messages.login} />
            </label>
            <Input type="text" error={touched.login && errors.login} {...field} />
          </div>
        )}
      />
      <Field
        name="password"
        render={({field}) => (
          <div className="inputContainer">
            <label className="label">
              <FormattedMessage {...messages.password} />
            </label>
            <Input
              type="password"
              error={touched.password && errors.password}
              {...field}
            />
          </div>
        )}
      />
      <Button text={messages.login} />
    </div>
  );
};

const mapStateToProps = store => ({});

const mapDispatchToProps = {
  addNewRegularCard,
  fetchAllRegularCurrency,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddThirdWallet);
