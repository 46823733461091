/**
 * @author: Ihor Bazyliuk
 */
import React, {Component} from "react";
import messages from "../../messages";
import {FormattedMessage} from "react-intl";
import OrderRequestsItem from "./item";

class OrderRequests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemsMetal: [
        {
          img: "metal-au.svg",
          metal: "gold",
          metal_value: 999.9,
          sum_currency: 155588.18,
          kg: 15,
          currency: "usd",
          currency2: "dhg",
          sum_currency2: 5316,
        },
        {
          img: "metal-ag.svg",
          metal: "silver",
          metal_value: 0,
          kg: 10,
          sum_currency: 2617.2,
          currency: "usd",
          currency2: "dhs",
          sum_currency2: 458,
        },
        {
          img: "metal-pt.svg",
          metal: "platinum",
          metal_value: 0,
          kg: 5,
          sum_currency: 18410.0,
          currency: "usd",
          currency2: "dhp",
          sum_currency2: 280,
        },
      ],
    };
  }

  render() {
    return (
      <div className="order-requests-section">
        <div className="card">
          <div className="card__header">
            <div className="order-requests-section__title">
              <FormattedMessage {...messages.allocatedMetals} />
            </div>
          </div>
          <div className="card__body">
            <div className="order-requests__metal-root">
              {this.state.itemsMetal.map((item, key) => {
                return <OrderRequestsItem key={key} item={item} />;
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OrderRequests;
