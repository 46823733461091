import axios from "axios";
import {AuthEndpoints} from "./apiEndpoints";
import config from "shared/config";
import qs from "querystring";
import {DeviceUUID} from "device-uuid";

/**
 *
 * @param data
 * @param option
 * @returns {Promise<AxiosResponse<T>>}
 */
export const authAPILogin = (data, option) => {
  const requestPayload = {
    username: data.email,
    password: data.password,
    client_id: config.authConfig.client_id,
    grant_type: config.authConfig.grant_type,
    scope: config.authConfig.scope,
    device_id: new DeviceUUID().get(),
  };

  return axios.post(AuthEndpoints.login, qs.stringify(requestPayload), {
    ...option,
    headers: {"Content-Type": "application/x-www-form-urlencoded"},
  });
};

/**
 *
 * @param data
 * @param option
 */
export const authAPISignUp = (data, option) => {
  const requestPayload = {
    email: data.email,
    emailConfirmationCode: data.emailConfirmationCode,
    password: data.password,
    confirmPassword: data.confirmPassword,
    passPhrase: data.passPhrase,
    firstName: data.firstName,
    lastName: data.lastName,
    companyName: data.companyName,
    apartment: data.apartment,
    city: data.city,
    country: data.country,
    region: data.region,
    street: data.street,
    zip: data.zip,
  };
  return axios.post(AuthEndpoints.signUp, requestPayload, {
    ...option,
  });
};

/**
 *
 * @param option
 * @returns {Promise<AxiosResponse<T>>}
 */
export const authAPIGetUserInfo = option => {
  return axios.get(AuthEndpoints.getUserInfo, {
    ...option,
  });
};

/**
 *
 * @param refresh_token
 * @param option
 * @returns {Promise<AxiosResponse<T>>}
 */
export const authAPIRefreshToken = (refresh_token, option) => {
  const requestPayload = {
    refresh_token,
    client_id: config.authConfig.client_id,
    grant_type: config.authConfig.refresh_grant_type,
    scope: config.authConfig.scope,
  };

  return axios.post(AuthEndpoints.login, qs.stringify(requestPayload), {
    ...option,
  });
};

/**
 *
 * @param option
 * @returns {Promise<AxiosResponse<T>>}
 */
export const authAPIGetUserProfile = option => {
  return axios.get(AuthEndpoints.userProfile, {
    ...option,
  });
};

/**
 *
 * @param data
 * @param option
 * @returns {Promise<AxiosResponse<T>>}
 */
export const authAPIUpdateUserProfile = (data, option) => {
  return axios.post(AuthEndpoints.userProfile, data, {
    ...option,
  });
};

/**
 *
 * @param data
 * @param option
 * @returns {Promise<AxiosResponse<T>>}
 */
export const authAPIUploadUserDrivingLicence = (data, option) => {
  return axios.post(AuthEndpoints.uploadDrivingLicence, data, {
    ...option,
  });
};

/**
 *
 * @param data
 * @param option
 * @returns {Promise<AxiosResponse<T>>}
 */
export const authAPIcheckAccountValidate = option => {
  return axios.post(AuthEndpoints.accountCheck, null, {
    ...option,
  });
};

/**
 *
 * @param option
 * @returns {Promise<AxiosResponse<T>>}
 */
export const authAPIGeneratePassPhrase = option => {
  return axios.get(AuthEndpoints.passPhrase, {
    ...option,
  });
};

/**
 *
 * @param option
 * @returns {Promise<AxiosResponse<T>>}
 */
export const authAPIChangePsw = option => {
  return axios.get(AuthEndpoints.passReset, {
    ...option,
  });
};

/**
 *
 * @param id
 * @param option
 * @returns {Promise<AxiosResponse<T>>}
 */
export const authAPIGetAvatar = (id, option) => {
  return axios.get(AuthEndpoints.getAvatarUser(id), {
    ...option,
  });
};

/**
 *
 * @param data
 * @param option
 * @returns {Promise<AxiosResponse<T>>}
 */
export const authAPIUploadAvatar = (data, option) => {
  return axios.post(AuthEndpoints.uploadAvatar, data, {
    ...option,
  });
};

/**
 *
 * @param data
 * @param option
 * @returns {Promise<AxiosResponse<T>>}
 */
export const authAPIShareMyContact = (data, option) => {
  return axios.post(AuthEndpoints.shareMyContact, data, {
    ...option,
  });
};

/**
 *
 * @param option
 * @returns {Promise<AxiosResponse<T>>}
 */
export const authAPIGetDevices = option => {
  return axios.get(AuthEndpoints.getDevices, {
    ...option,
  });
};

/**
 *
 * @param option
 * @returns {Promise<AxiosResponse<T>>}
 */
export const authAPIUpdateDevices = option => {
  const du = new DeviceUUID().parse();
  const device = {
    name_device: du.platform,
    os: du.os,
    active: false,
    region: "Vinnitsia, Vinnitsia Region, Ukraine",
    isDesktop: du.isDesktop,
    isMobile: du.isMobile,
    isTablet: du.isTablet,
  };

  return axios.put(
    AuthEndpoints.updateDevice(new DeviceUUID().get()),
    {info: JSON.stringify(device)},
    {
      ...option,
    }
  );
};

/**
 *
 * @param option
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getAPICountriesWithDocs = () => {
  return axios.get(AuthEndpoints.countriesWithDocs);
};

/**
 *
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
export const sendIDPconfirmCodeEmail = (data, option) => {
  return axios.post(AuthEndpoints.sendEmailConfirmCode, JSON.stringify(data), {
    ...option,
  });
};
