import React, {useState} from "react";
import {connect} from "react-redux";
import {Formik, Field, Form} from "formik";
import {FormattedMessage} from "react-intl";

import * as Yup from "yup";
import CustomSelect from "shared/components/Select";
import InputUniversal from "shared/components/InputUniversal";
import Button from "shared/components/Button";
import {sendVoucherOnWalletAccount} from "shared/shoppingService/actions";

import "./index.scss";
import messages from "../Company/messages";

import config from "shared/config";

const onWalletIcon = config.prefixImgUrl + "onwallet-icon-short_v1.svg";
const contactIcon = config.prefixImgUrl + "contact-icon.svg";
const emailIcon = config.prefixImgUrl + "email-icon.svg";
const phoneIcon = config.prefixImgUrl + "phone-icon.svg";

const SendOption = ({
  formTabs,
  cards,
  vouchers,
  formTabsType,
  sendVoucherOnWalletAccount,
}) => {
  const options = ["OnWallet Account", "E-mail", "Phone Number"];
  const initStateFormik = {
    onWallet: "",
    phone: "",
    email: "",
  };

  const [selectedOption, setSelectedOption] = useState("OnWallet Account");
  const [selectedOptionCard, setSelectedOptionCard] = useState("");
  const [selectedOptionVoucher, setSelectedOptionVoucher] = useState("");
  const [enterData, setEnterData] = useState("");

  const handlerChangeEnterData = data => {
    setEnterData(data);
  };

  const setOptionValue = value => {
    setSelectedOption(value);
    switch (value) {
      case "OnWallet Account":
        document.getElementById("onwalletaccount").style.display = "block";
        document.getElementById("phonenumber").style.display = "none";
        document.getElementById("email").style.display = "none";
        return;
      case "E-mail":
        document.getElementById("onwalletaccount").style.display = "none";
        document.getElementById("phonenumber").style.display = "none";
        document.getElementById("email").style.display = "block";
        return;
      case "Phone Number":
        document.getElementById("onwalletaccount").style.display = "none";
        document.getElementById("phonenumber").style.display = "block";
        document.getElementById("email").style.display = "none";
        return;
      default:
        document.getElementById("onwalletaccount").style.display = "block";
        document.getElementById("phonenumber").style.display = "none";
        document.getElementById("email").style.display = "none";
        return;
    }
  };

  const handlerGenerationBody = item => {
    return {
      voucherId: item.voucherId,
      merchantId: item.merchantId,
      recipientAccount: enterData,
    };
  };

  const handlerSubmit = () => {
    switch (formTabsType) {
      case "card":
        const card = cards.find(card => card.title === selectedOptionCard);

        if (!!card && selectedOption && selectedOption === options[0]) {
          const payloadCard = handlerGenerationBody(card);
          sendVoucherOnWalletAccount(payloadCard);
        }

        if (!!card && selectedOption && selectedOption === options[1]) {
          const payloadCard = handlerGenerationBody(card);
          console.log("payloadCard: ", payloadCard);
          // TODO request for card Email
        }

        if (!!card && selectedOption && selectedOption === options[2]) {
          const payloadCard = handlerGenerationBody(card);
          console.log("payloadCard: ", payloadCard);
          // TODO request for card phone
        }
        break;
      case "voucher":
        const voucher = vouchers.find(voucher => voucher.title === selectedOptionVoucher);

        if (!!voucher && selectedOption && selectedOption === options[0]) {
          const payloadVoucher = handlerGenerationBody(voucher);
          sendVoucherOnWalletAccount(payloadVoucher);
        }

        if (!!voucher && selectedOption && selectedOption === options[1]) {
          const payloadVoucher = handlerGenerationBody(voucher);
          console.log("payloadCard: ", payloadVoucher);
          // TODO request for voucher Email
        }

        if (!!voucher && selectedOption && selectedOption === options[2]) {
          const payloadVoucher = handlerGenerationBody(voucher);
          console.log("payloadCard: ", payloadVoucher);
          // TODO request for voucher phone
        }
        break;
      default:
        break;
    }
  };

  const SendCustomerDetailsSchema = Yup.object().shape({
    onWallet: Yup.string().min(18),
    email: Yup.string()
      .nullable()
      .notRequired()
      .email(),
    phone: Yup.string().matches(
      /^((\\+[1-9]{1,4}[\\-]*)|(\\([0-9]{2,3}\\)[\\-]*)|([0-9]{2,4})[\\-]*)*?[0-9]{3,4}?[\\-]*[0-9]{3,4}?$/,
      "Phone Number is not valid"
    ),
  });

  return (
    <>
      <Formik
        initialValues={initStateFormik}
        validateOnChange={true}
        validationSchema={SendCustomerDetailsSchema}
        onSubmit={() => handlerSubmit()}
      >
        {({errors, touched, values}) => (
          <Form>
            {formTabs && formTabs()}
            <div className="send-option-layout">
              {cards && (
                <CustomSelect
                  className="select"
                  value={selectedOptionCard}
                  options={cards.map(card => card.title)}
                  onChange={e => {
                    setSelectedOptionCard(e.target.value);
                  }}
                />
              )}
              {vouchers && (
                <CustomSelect
                  className="select"
                  value={selectedOptionVoucher}
                  options={vouchers.map(voucher => voucher.title)}
                  onChange={e => {
                    setSelectedOptionVoucher(e.target.value);
                  }}
                />
              )}
              <div className="send-to">
                <div className="label">
                  <FormattedMessage {...messages.sendtolabel} />
                </div>
                <CustomSelect
                  className="select"
                  value={selectedOption}
                  options={options}
                  onChange={e => {
                    setOptionValue(e.target.value);
                    setEnterData("");
                  }}
                />
              </div>

              {/* OnWallet Account */}
              <Field name="onWallet">
                {({field}) => (
                  <div id="onwalletaccount" className="on-wallet-account">
                    <div className="label">
                      <FormattedMessage {...messages.onwalletnumber} />
                    </div>
                    <InputUniversal
                      type="text"
                      error={touched.onWallet && errors.onWallet}
                      value={values.onWallet}
                      leftIcon={onWalletIcon}
                      rightIcon={contactIcon}
                      iconStyles="onWalletIcon"
                      placeholder="Paste wallet"
                      suffix="John Smith"
                      typeContact="onWalletAccount"
                      enterData={enterData}
                      setEnterData={handlerChangeEnterData}
                      {...field}
                    />
                  </div>
                )}
              </Field>

              {/* Phone */}
              <Field name="phone">
                {({field}) => (
                  <div
                    id="phonenumber"
                    className="phone-number"
                    style={{display: "none"}}
                  >
                    <div className="label">
                      <FormattedMessage {...messages.phonenumber} />
                    </div>
                    <InputUniversal
                      type="text"
                      error={touched.phone && errors.phone}
                      value={values.phone}
                      leftIcon={phoneIcon}
                      rightIcon={contactIcon}
                      iconStyles="onWalletIcon"
                      placeholder="Paste phone"
                      typeContact="phone"
                      enterData={enterData}
                      setEnterData={handlerChangeEnterData}
                      {...field}
                    />
                  </div>
                )}
              </Field>

              {/* Email address */}
              <Field name="email">
                {({field}) => (
                  <div id="email" className="e-mail" style={{display: "none"}}>
                    <div className="label">
                      <FormattedMessage {...messages.email} />
                    </div>
                    <InputUniversal
                      type="text"
                      error={touched.email && errors.email}
                      value={values.email}
                      leftIcon={emailIcon}
                      rightIcon={contactIcon}
                      iconStyles="onWalletIcon"
                      placeholder="Paste email"
                      typeContact="email"
                      enterData={enterData}
                      setEnterData={handlerChangeEnterData}
                      {...field}
                    />
                  </div>
                )}
              </Field>

              <Button text={messages.sendtocustomerbutton} type="submit" />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = store => ({});

function mapDispatchToProps(dispatch) {
  return {
    sendVoucherOnWalletAccount: data => {
      dispatch(sendVoucherOnWalletAccount(data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SendOption);
