import React from "react";
import {connect} from "react-redux";
import {openRightModal} from "shared/components/RightModal/actions";
import {FormattedMessage} from "react-intl";

import CompanyVoucherItem from "./CompanyVourcherItem";

import {getCompanyVouchers} from "shared/shoppingService/selectors";

import messages from "../Company/messages";

import "./index.scss";

const ShoppingCompanyVouchers = ({companyVouchers, openRightModal}) => {
  return (
    <>
      <div className="corporate-vouchers-layout">
        <div className="corporate-vouchers-header">
          <FormattedMessage {...messages.corporatevoucherssheader} />
        </div>
        {companyVouchers.map((item, index) => (
          <CompanyVoucherItem item={item} key={index} />
        ))}
        <div
          className="add-voucher-button"
          onClick={() =>
            openRightModal({name: "shoppingCompanyVoucher", title: "New Voucher"})
          }
        >
          <span>
            <FormattedMessage {...messages.addvoucher} />
          </span>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = store => ({
  companyVouchers: getCompanyVouchers(store),
});

const mapDispatchToProps = {
  openRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCompanyVouchers);
