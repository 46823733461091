import React from "react";
import {usePagination} from "@material-ui/lab/Pagination";
import {makeStyles} from "@material-ui/core/styles";
import config from "shared/config";
import "./index.scss";

const arrowLeftIcon = config.prefixImgUrl + "left-arrow-icon-active.svg";

const useStyles = makeStyles({
  ul: {
    display: "flex",
    justifyContent: "center",
    marginTop: "44px",
    listStyle: "none",
    padding: 0,
    margin: 0,
    backgroundColor: "#e5eff5",
  },
  button: {
    backgroundColor: "inherit",
  },
});

export default function CustomPaginator({count, getPage}) {
  const classes = useStyles();
  const {items} = usePagination({
    count,
  });

  const handleDefineType = type => {
    const str =
      type === "previous" ? (
        <img src={arrowLeftIcon} alt="back" className="paginator-icons" />
      ) : type === "next" ? (
        <div className="paginator-next paginator-icons">
          <div className="paginator-next__text">Next</div>
          <img src={arrowLeftIcon} className="paginator-next__icon" alt="next" />
        </div>
      ) : (
        type
      );

    return str;
  };

  return (
    <nav>
      <ul className={`${classes.ul} paginator`}>
        {items.map(({page, type, selected, ...item}, index) => {
          let children = null;

          if (selected) getPage(page);

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = "…";
          } else if (type === "page") {
            children = (
              <button
                type="button"
                className={`paginator-buttons ${selected ? "blue-text" : ""}`}
                {...item}
              >
                {page}
              </button>
            );
          } else {
            children = (
              <button type="button" className="paginator-buttons blue-text" {...item}>
                {handleDefineType(type)}
              </button>
            );
          }

          return <li key={index}>{children}</li>;
        })}
      </ul>
    </nav>
  );
}
