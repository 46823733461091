import React from "react";
import "./index.scss";
import {Grid, Row, Col} from "react-flexbox-grid";
import Loader from "shared/components/Loader";

const Table = ({
  headers,
  rows,
  xs,
  md,
  styles,
  clickRow,
  isLoading,
  rowWrapper,
  action,
}) => {
  const mapRows = () => {
    return rows.map((row, index) => (
      <Row
        key={index}
        className={`TableRow ${row.isActive ? "active" : ""}`}
        onClick={() => {
          if (clickRow) clickRow(index);
        }}
      >
        {row.cols.map((col, colKey) => (
          <Col
            key={colKey}
            className="TableCol"
            xs={col.xs ? col.xs : xs}
            md={col.md ? col.md : md}
          >
            {col.body ? col.body : col}
          </Col>
        ))}
      </Row>
    ));
  };
  return (
    <Grid className={`GridContainer ${styles}`} fluid>
      {headers && (
        <Row className="TableHeaderRow">
          {headers.map((header, index) => (
            <Col
              className="TableHeaderCol"
              key={index}
              xs={header.xs ? header.xs : xs}
              md={header.md ? header.md : md}
            >
              {header.body ? header.body : header}
            </Col>
          ))}
        </Row>
      )}

      {isLoading && (
        <Row className={`TableRow`}>
          <Loader local small />
        </Row>
      )}
      {!isLoading && !rowWrapper && (
        <>
          {mapRows()}
          {action && (
            <div
              className={`TableRow action ${rows.length > 0 ? "notEmpty" : ""}`}
              onClick={action.handler}
            >
              {rows.length === 0 && action.message && (
                <p className="emptyMessage">{action.message}</p>
              )}
              <p className="actionText">{action.text ? action.text : "Action"}</p>
            </div>
          )}
        </>
      )}
      {!isLoading && rowWrapper && <div className="TableRowWrapper">{mapRows()}</div>}
    </Grid>
  );
};

export default Table;
