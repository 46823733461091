import React from "react";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
// Components
import Header from "shared/components/Header";
import TransactionMonitorBody from "../TransactionMonitorBody";
// Utils
import config from "shared/config";
import messages from "../../messages";
// Styles
import "./index.scss";
// Images
const leftArrowIcon = config.prefixImgUrl + "left-arrow-icon.svg";
const pendingTransactionIcon = config.prefixImgUrl + "pending_transaction_icon.svg";

const LastTransactionPage = () => {
  return (
    <>
      <Header
        headerTitle={<FormattedMessage {...messages.lastTransaction} />}
        navLinks={[]}
        logoImage={leftArrowIcon}
        link={"/transaction-monitor"}
        middleStyle="coin-registry-header"
      >
        <Link to="/transaction-monitor">
          <div className="transaction-monitor-header">
            <img src={pendingTransactionIcon} alt="Last transaction" />
            <div className="transaction-monitor-header__text">
              <FormattedMessage {...messages.pendingTransactions} />
            </div>
          </div>
        </Link>
      </Header>

      <TransactionMonitorBody lastTransactionPage={true} />
    </>
  );
};

export default LastTransactionPage;
