import React, {Component} from "react";
import {connect} from "react-redux";
import {getDataContacts, shareContact} from "shared/contacts/actions";
import {contactList} from "shared/contacts/selectors";
import {getValueSearchShared} from "../selectors";
import Checkbox from "shared/components/Checkbox";
import Button from "shared/components/Button";
import messages from "../../../messages";
import config from "shared/config";
import "../index.scss";

const defaultIcon = config.prefixImgUrl + "userdefault-icon.svg";

class ShareContactModal extends Component {
  constructor(props) {
    super(props);
    props.getDataContacts();
    this.state = {
      isCheck: new Array(this.props.contactList.length).fill(false),
      contactId: props.data.id,
    };
  }

  handlerChangeCheck = (event, index) => {
    const copyArray = this.state.isCheck.slice();
    copyArray[index] = event.target.checked;

    this.setState({isCheck: copyArray});
  };

  handlerSubmit = () => {
    const accounts = [];

    this.state.isCheck.map((item, index) => {
      return item
        ? accounts.push(this.props.contactList[index].info.onWalletAccount)
        : null;
    });

    const payload = {
      accounts,
      contactId: this.state.contactId,
    };

    this.props.shareContact(payload);
    this.props.close();
  };

  render() {
    const filterContacts = this.props.contactList.filter(item =>
      this.props.search
        ? `${item.info.firstName} ${item.info.lastName}`
            .toLowerCase()
            .includes(this.props.search.toLowerCase()) && item.id !== this.state.contactId
        : item.id !== this.state.contactId
    );

    return (
      <div className="contact-add-container mt-4">
        {filterContacts.map((item, index) => {
          return (
            <div key={index} className="contact-list">
              <div className="contact-list-avatar">
                <img src={defaultIcon} alt="avatar" />
              </div>
              <div className="contact-list-info">
                <div className="contact-list-info__name">{`${item.info.firstName} ${item.info.lastName}`}</div>
                <div className="contact-list-info__id">{`#${item.id.slice(0, 8)}`}</div>
              </div>
              <div className="contact-list-checkbox">
                <Checkbox
                  className="checkbox"
                  value={this.state.isCheck[index]}
                  onChange={e => this.handlerChangeCheck(e, index)}
                />
              </div>
            </div>
          );
        })}
        <div className="action-shared">
          <Button type="button" text={messages.share} onClick={this.handlerSubmit} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  contactList: contactList(store),
  search: getValueSearchShared(store),
});

function mapDispatchToProps(dispatch) {
  return {
    getDataContacts: () => {
      dispatch(getDataContacts());
    },
    shareContact: data => {
      dispatch(shareContact(data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareContactModal);
