import React from "react";
import "./index.scss";
import PropTypes from "prop-types";
import TransactionItem from "../TransactionItem";

const TransactionsDay = ({date, totalAmount, items}) => {
  return (
    <div className="TransactionDayWrapper">
      <div className="TransactionWrapperTitle">
        <span className="TransactionDayDate">{date}</span>
        <span className="TransactionDayBalance">{totalAmount} USD</span>
      </div>
      {!!items.length && (
        <div className="TransactionDayContainer">
          {items.map((item, index) => (
            <TransactionItem item={item} idx={index} key={index + item.sourceAccount} />
          ))}
        </div>
      )}
    </div>
  );
};

TransactionsDay.propTypes = {
  date: PropTypes.string,
  balance: PropTypes.string,
  items: PropTypes.array,
};

export default TransactionsDay;
