import React, {Component} from "react";
import "./index.scss";

class CodeInput6 extends Component {
  constructor(props) {
    super(props);

    this.firstRef = React.createRef();
    this.secondRef = React.createRef();
    this.thirdRef = React.createRef();

    this.handleFirstValue = this.handleFirstValue.bind(this);
    this.handleSecondValue = this.handleSecondValue.bind(this);
    this.handleThirdValue = this.handleThirdValue.bind(this);

    this.state = {
      firstValue: "",
      secondValue: "",
      thirdValue: "",
      inputPattern: "[0-9]{2}",
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.firstValue !== undefined &&
        prevState.firstValue !== this.state.firstValue) ||
      (prevState.secondValue !== undefined &&
        prevState.secondValue !== this.state.secondValue) ||
      (prevState.thirdValue !== undefined &&
        prevState.thirdValue !== this.state.thirdValue)
    ) {
      this.props.onChange(
        parseInt(
          this.state.firstValue +
            "" +
            this.state.secondValue +
            "" +
            this.state.thirdValue,
          10
        )
      );
    }
  }

  /**
   *
   * @param value
   * @returns {boolean}
   */
  isNumber = value => {
    const pattern = /^\d+$/;
    const test = pattern.test(value);
    return !!test;
  };

  /**
   *
   * @param e
   */
  handleFirstValue(e) {
    if (this.secondRef.current && e.target.value.length >= 2) {
      this.secondRef.current.focus();
      this.setState({secondValue: ""});
    }
    this.setState({
      firstValue:
        this.isNumber(e.target.value) && e.target.value.length < 3
          ? e.target.value
          : this.state.firstValue,
    });
  }

  /**
   *
   * @param e
   */
  handleSecondValue(e) {
    if (this.thirdRef.current && e.target.value.length >= 2) {
      this.thirdRef.current.focus();
      this.setState({thirdValue: ""});
    }
    this.setState({
      secondValue:
        this.isNumber(e.target.value) && e.target.value.length < 3
          ? e.target.value
          : this.state.secondValue,
    });
  }

  /**
   *
   * @param e
   */
  handleThirdValue(e) {
    this.setState({
      thirdValue:
        this.isNumber(e.target.value) && e.target.value.length < 3
          ? e.target.value
          : this.state.thirdValue,
    });
  }

  /**
   *
   * @returns {*}
   */
  render() {
    return (
      <div className="CodeInputWrapper">
        <div className="CodeInputContainer">
          <input
            ref={this.firstRef}
            type="text"
            className="codeInput"
            pattern={this.state.inputPattern}
            value={this.state.firstValue}
            onChange={this.handleFirstValue}
          />
          <div className="line">-</div>
          <input
            ref={this.secondRef}
            type="text"
            className="codeInput"
            pattern={this.state.inputPattern}
            value={this.state.secondValue}
            onChange={this.handleSecondValue}
          />
          <div className="line">-</div>
          <input
            ref={this.thirdRef}
            type="text"
            className="codeInput"
            pattern={this.state.inputPattern}
            value={this.state.thirdValue}
            onChange={this.handleThirdValue}
          />
        </div>
        {this.props.error && <div className="codeInputError">{this.props.error}</div>}
      </div>
    );
  }
}
export default CodeInput6;
