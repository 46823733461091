import React from "react";
import {connect} from "react-redux";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import {FormattedMessage} from "react-intl";

import "./index.scss";
import messages from "../../messages";
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import Input from "shared/components/Input";
import Button from "shared/components/Button";

import {addCartItem} from "shared/cartService/actions";
import {getOnwalletAccount} from "shared/cardService/selectors";
import {OnWalletOption} from "shared/components/CardWalletOptions";
import Textarea from "shared/components/Input/Textarea";
import {transferOnWalletToOnWallet} from "shared/cardService/actions";
import {
  DetectAmount,
  DetectDescription,
  DetectOnWalletAccount,
  DetectRecipient,
  SendRecipient,
} from "./components";

import config from "shared/config";
import AddressBookInput from "shared/components/AddressBook";
const onWalletIcon = config.prefixImgUrl + "onwallet-icon-short_v1.svg";

const initialValues = {
  sourceOnWallet: null,
  recipientAddress: "",
  firstName: "",
  lastName: "",
  amount: "0",
  description: "",
  sendReceipt: false,
  email: "",
};
const OnWalletFromOnWalletSchema = Yup.object().shape({
  sourceOnWallet: Yup.object()
    .nullable()
    .default(null)
    .required("Source account is required"),
  recipientAddress: Yup.string().required("OnWallet address is required"),
  firstName: Yup.string(),
  lastName: Yup.string(),
  amount: Yup.string()
    .required("Amount is required")
    .matches(
      "([1-9]\\d*(\\.\\d*[1-9])?|0\\.\\d*[1-9]+)|\\d+(\\.\\d*[1-9])?",
      "Amount must be a number"
    ),
  email: Yup.string().email("Email must be valid"),
});

const onSubmit = values => ({
  amount: Number.parseFloat(values.amount),
  sourceCurrency: values.sourceOnWallet
    ? values.sourceOnWallet.currency.toLowerCase()
    : "",
  recipientAccount: values.recipientAddress,
  receiptEmail: values.email || null,
});

const OnWalletFromOnWalletForm = ({
  isSending,
  addCartItem,
  formTabs,
  onWalletAccount,
  transferOnWalletToOnWallet,
  recipientAddress,
  amount,
  description,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={OnWalletFromOnWalletSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, {setSubmitting}) => {
        transferOnWalletToOnWallet(onSubmit(values));
      }}
    >
      {({errors, touched, values}) => (
        <div className="CardToCardTransferForm">
          <DetectOnWalletAccount
            field="sourceOnWallet"
            onWalletAccount={onWalletAccount}
          />
          <DetectRecipient field="recipientAddress" recipient={recipientAddress} />
          <DetectAmount field="amount" amount={amount} />
          <DetectDescription field="description" description={description} />
          <Form className="form">
            <FormSectionSeparator sectionName={messages.source} />
            {formTabs && formTabs()}
            <Field name="sourceOnWallet">
              {({field, form}) => (
                <div className="cardInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.myOnWalletAccount} />
                  </label>
                  <OnWalletOption account={field.value} />
                </div>
              )}
            </Field>
            <FormSectionSeparator sectionName={messages.recipient} />
            <SomeoneOnWallet errors={errors} touched={touched} values={values} />
            <FormSectionSeparator sectionName={messages.details} />
            <Field name="amount">
              {({field, form}) => (
                <div className="cardInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.transactionAmount} />
                  </label>
                  <Input error={touched.amount && errors.amount} {...field} />
                </div>
              )}
            </Field>
            <Field name="description">
              {({field, form}) => (
                <div className="descriptionInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.description} />
                  </label>
                  <Textarea type="text" {...field} />
                </div>
              )}
            </Field>
            <SendRecipient touched={touched} errors={errors} values={values} />
            {!!values.sourceOnWallet && (
              <div className="feeRate">
                <div className="rate">
                  <FormattedMessage {...messages.transactionFee} />: 1.5% -{" "}
                  {values.amount * 0.015} {values.sourceOnWallet.currency}
                </div>
                <div className="description">
                  <FormattedMessage {...messages.transactionFeeDescription} />
                </div>
              </div>
            )}
            <div className="buttonsContainer">
              <Button
                type="button"
                text={messages.addToCart}
                isSecondary="true"
                onClick={() => {
                  addCartItem({
                    type: "transfer",
                    data: {
                      description: values.description,
                      sourceOnWallet: values.sourceOnWallet,
                      sourceAmount: values.amount,
                      sourceCurrency: values.sourceOnWallet
                        ? values.sourceOnWallet.currency
                        : "USD",
                    },
                    action: option =>
                      transferOnWalletToOnWallet(onSubmit(values), option),
                  });
                }}
              />

              <Button type="sumbit" text={messages.sendNow} isLoading={isSending} />
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

const SomeoneOnWallet = ({touched, errors, values}) => {
  let {firstName = "", lastName = ""} = values || {};

  return (
    <>
      <Field name="recipientAddress">
        {({field, form}) => (
          <div className="onWalletAccountInputContainer">
            <AddressBookInput
              type="text"
              label={<FormattedMessage {...messages.onWalletAccount} />}
              types="onWalletAccount"
              placeholder="Paste or scan an address"
              name="recipientAddress"
              leftIcon={onWalletIcon}
              error={touched.recipientAddress && errors.recipientAddress}
              customer={{
                firstName: firstName,
                lastName: lastName,
              }}
              form={form}
              {...field}
              setfield={[
                {
                  label: "",
                  value: "",
                  name: "firstName",
                },
                {
                  label: "",
                  value: "",
                  name: "lastName",
                },
              ]}
            />
          </div>
        )}
      </Field>

      <div className="NameInputContainer">
        <Field name="firstName">
          {({field, form}) => (
            <div className="nameInputContainer">
              <label className="label">
                <FormattedMessage {...messages.firstName} />
              </label>
              <div className="nameWrapper">
                <Input
                  type="text"
                  placeholder="John"
                  error={touched.firstName && errors.firstName}
                  {...field}
                />
              </div>
            </div>
          )}
        </Field>
        <Field name="lastName">
          {({field, form}) => (
            <div className="nameInputContainer">
              <label className="label">
                <FormattedMessage {...messages.lastName} />
              </label>
              <div className="nameWrapper">
                <Input
                  type="text"
                  placeholder="Smith"
                  error={touched.lastName && errors.lastName}
                  {...field}
                />
              </div>
            </div>
          )}
        </Field>
      </div>
    </>
  );
};

const mapStateToProps = store => ({
  isSending: store.transferCardData.isLoading,
  onWalletAccount: getOnwalletAccount(store),
});

const mapDispatchToProps = {
  transferOnWalletToOnWallet,
  addCartItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(OnWalletFromOnWalletForm);
