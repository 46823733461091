import React from "react";
import "./index.scss";
import {FormattedMessage} from "react-intl";
import {ListSubheader} from "@material-ui/core";

const SelectGroupSeparator = ({groupName}) => {
  return (
    <ListSubheader>
      <div className="selectGroupSeparator">
        <span className="groupName">
          <FormattedMessage {...groupName} />
        </span>
        <span className="separatorLine"></span>
      </div>
    </ListSubheader>
  );
};

export default SelectGroupSeparator;
