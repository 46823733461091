import React from "react";
import {connect} from "react-redux";
import "./index.scss";
import {closeError} from "./actions";

const ErrorModal = ({show, error, mainAction, secondAction, closeError}) => {
  return (
    <div className={`ErrorModalWrapper ${show ? "show" : ""}`}>
      <div className="ErrorModalContainer">
        <div className="ErrorHeader">
          <span className="icon" />
          <h1 className="header">{error && error.header ? error.header : "Error"}</h1>
        </div>
        <p className="ErrorText">
          {error && error.description ? error.description : "Something went wrong"}
        </p>
        <div className="ErrorButtons">
          {secondAction ? (
            <button onClick={secondAction.handler} className="secondary">
              {secondAction.text}
            </button>
          ) : (
            <button onClick={() => closeError()} className="secondary">
              close
            </button>
          )}
          {mainAction && (
            <button onClick={mainAction.handler} className="primary">
              {mainAction.text}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = store => ({
  show: store.errorModalData.show,
  error: store.errorModalData.error,
  mainAction: store.errorModalData.mainAction,
  secondAction: store.errorModalData.secondAction,
});

const mapDispatchToProps = {closeError};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);
