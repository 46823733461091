import React, {useRef} from "react";
import {connect} from "react-redux";
import "./index.scss";
import {getWalletIconByName} from "shared/walletService/selectors";
import QRCode from "qrcode.react";
import {FormattedMessage} from "react-intl";
import messages from "../../messages";
import {userCopyToClipboard} from "shared/hooks";
import {deleteWallet} from "shared/walletService/actions";

import config from "shared/config";
const copyIcon = config.prefixImgUrl + "copy-icon.svg";

const ManageWalletModal = ({wallet, deleteWallet}) => {
  const addressRef = useRef(null);
  return (
    <div className="ManageWalletModal">
      <label className="label">
        <FormattedMessage {...messages.balance} />
      </label>
      <div className="cryptoBalance">
        <img src={getWalletIconByName(wallet.walletName)} className="icon" alt="icon" />
        <div className="balance">
          {wallet ? wallet.balance : 0} {!!wallet && wallet.currency}
        </div>
      </div>
      <div className="cryptoAddress">
        <input
          ref={addressRef}
          className="address"
          readOnly
          value={wallet ? wallet.address : 0}
        />
        <img
          src={copyIcon}
          className="copyIcon"
          alt="icon"
          onClick={userCopyToClipboard(addressRef)}
        />
      </div>
      <div className="qrCode">
        <QRCode size={145} value={wallet ? wallet.address : 0} />
      </div>
      {wallet &&
        wallet.balance === 0 &&
        wallet.walletName !== "Deho Gold" &&
        wallet.walletName !== "Deho Silver" &&
        wallet.walletName !== "Deho Platinum" && (
          <div
            className="deleteButton red"
            onClick={() => deleteWallet(wallet.address, wallet.currency.toLowerCase())}
          >
            <FormattedMessage {...messages.deleteWallet} />
          </div>
        )}
      {wallet &&
        wallet.balance !== 0 &&
        wallet.walletName !== "Deho Gold" &&
        wallet.walletName !== "Deho Silver" &&
        wallet.walletName !== "Deho Platinum" && (
          <div className="deleteButton">
            <FormattedMessage {...messages.toDeleteTheWallet} />
          </div>
        )}
    </div>
  );
};

const mapStateToProps = store => ({});

const mapDispatchToProps = {
  deleteWallet,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageWalletModal);
