import faker from "faker";
import moment from "moment";

const empty = new Array(40).fill(null);

const weekly = empty.map(() => {
  return {
    name: moment(faker.date.past()).format("MM-DD-YYYY"),
    value: faker.random.number({min: 1000, max: 1500}),
    secondValue: faker.random.number({min: 100, max: 1000}),
  };
});

export default weekly;
