import React, {useState} from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import messages from "../../../messages";
import config from "shared/config";
// Selectors
import {getCryptoIconBySymbol} from "shared/walletService/selectors";
// Images
const selectUp = config.prefixImgUrl + "arrow-transaction.svg";
const selectDown = config.prefixImgUrl + "arrow-down-transaction.svg";
const goldIcon = config.prefixImgUrl + "metal-au.svg";

const CoinRegistryTableItem = ({item}) => {
  // Hooks
  const [isShow, setShow] = useState(false);

  return (
    <div>
      <div className="table-body__item" onClick={() => setShow(!isShow)}>
        <div className="table-body__item-id">{item.id}</div>
        <div className="table-body__item-currency">
          <div className="table-body__item-currency-logo">
            <img
              src={getCryptoIconBySymbol(item.currency.toUpperCase())}
              alt="Logo Currency"
            />
          </div>
          <div>{item.currency.toUpperCase()}</div>
        </div>
        <div className="table-body__item-owner">{item.ownerId}</div>
        <div className="table-body__item-total">
          <div className="table-body__item-total-info">
            {`${item.total.toFixed(2)} ${item.totalCurrency}`}
          </div>
          <div className="table-body__item-total-icon">
            <img src={isShow ? selectUp : selectDown} alt="select" />
          </div>
        </div>
      </div>

      {/* Details Table */}

      {isShow && item.detailsInfo.length && (
        <div>
          <div className="table-header__details">
            <div className="table-header__details__item">
              <FormattedMessage {...messages.linkedMetal} />
            </div>
            <div className="table-header__details__item">
              <FormattedMessage {...messages.refiner} />
            </div>
            <div className="table-header__details__item">
              <FormattedMessage {...messages.weight} />
            </div>
            <div className="table-header__details__item">
              <FormattedMessage {...messages.bullionId} />
            </div>
            <div className="table-header__details__item">
              <FormattedMessage {...messages.valueInMetal} />
            </div>
          </div>
          <div className="table-body__details">
            {item.detailsInfo.length &&
              item.detailsInfo.map(info => (
                <div className="table-body__details__item">
                  <div className="table-body__details__item-info">
                    <img src={goldIcon} alt="Gold Icon" />
                    <div>{`${info.metal} ${info.type}`}</div>
                  </div>
                  <div className="table-body__details__item-info">{info.refiner}</div>
                  <div className="table-body__details__item-info">{`${info.weight} Kg`}</div>
                  <div className="table-body__details__item-info">{info.bullionId}</div>
                  <div className="table-body__details__item-info">{`${info.value} USD`}</div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

// Types
CoinRegistryTableItem.propTypes = {
  item: PropTypes.object.isRequired,
  getCryptoIconBySymbol: PropTypes.func,
};

// Redux
const mapStateToProps = state => ({
  getCryptoIconBySymbol: name => getCryptoIconBySymbol(name),
});

export default connect(mapStateToProps, null)(CoinRegistryTableItem);
