import React, {useEffect, useState} from "react";
import CoinRegistryTableItem from "../CoinRegistryTableItem";
import PropTypes from "prop-types";
// Styles
import "../index.scss";

// Mock data (TODO normal data)
const data = [
  {
    id: "11111111",
    currency: "dhg",
    ownerId: "4444444",
    total: 25,
    totalCurrency: "USD",
    detailsInfo: [
      {
        metal: "Gold",
        type: 999,
        refiner: "The Royal Mint",
        weight: 5,
        bullionId: "B323256",
        value: 12,
      },
      {
        metal: "Gold",
        type: 999,
        refiner: "The Royal Mint",
        weight: 5,
        bullionId: "B323256",
        value: 12,
      },
    ],
  },
  {
    id: "22222222",
    currency: "dhs",
    ownerId: "55555555",
    total: 15,
    totalCurrency: "USD",
    detailsInfo: [
      {
        metal: "Gold",
        type: 999,
        refiner: "The Royal Mint",
        weight: 3,
        bullionId: "B323256",
        value: 8,
      },
    ],
  },
  {
    id: "333333333",
    currency: "dhp",
    ownerId: "66666666",
    total: 5,
    totalCurrency: "USD",
    detailsInfo: [
      {
        metal: "Gold",
        type: 999,
        refiner: "The Royal Mint",
        weight: 3,
        bullionId: "B323256",
        value: 8,
      },
    ],
  },
];

const CoinRegistryTableBody = ({searchValue, coinCurrency}) => {
  const [filterData, setFilterData] = useState(data);

  useEffect(() => {
    if (!!coinCurrency && coinCurrency.currency === "all") {
      // Search filter
      if (!!searchValue && !!data.length) {
        setFilterData(
          data.filter(
            item => item.id.includes(searchValue) || item.ownerId.includes(searchValue)
          )
        );
      } else {
        setFilterData(data);
      }
    } else {
      if (!!coinCurrency) {
        // Coin Filter
        const filterCoin = data.filter(
          item => item.currency.toLowerCase() === coinCurrency.currency.toLowerCase()
        );

        setFilterData(filterCoin);
        // Search filter
        if (!!searchValue && !!filterCoin.length) {
          setFilterData(
            filterCoin.filter(
              item => item.id.includes(searchValue) || item.ownerId.includes(searchValue)
            )
          );
        } else {
          setFilterData(filterCoin);
        }
      }
    }
  }, [coinCurrency, searchValue]);

  return (
    <div className="table-body">
      {!!filterData.length &&
        filterData.map((item, index) => (
          <CoinRegistryTableItem item={item} key={index} />
        ))}
    </div>
  );
};

// Types
CoinRegistryTableBody.propTypes = {
  searchValue: PropTypes.string,
  coinCurrency: PropTypes.object,
};

export default CoinRegistryTableBody;
