import {OPEN_QRCODE_MODAL, CLOSE_QRCODE_MODAL} from "./actions";

const initState = {
  show: false,
  qr: null,
  title: "",
  meta: null,
};

const qrCodeModalData = (state = initState, action) => {
  switch (action.type) {
    case OPEN_QRCODE_MODAL:
      return {
        ...state,
        show: true,
        qr: action.payload.qr,
        title: action.payload.title,
        meta: action.payload.meta,
      };
    case CLOSE_QRCODE_MODAL:
      return {...state, show: false, qr: null, meta: null, title: ""};
    default:
      return state;
  }
};

export {qrCodeModalData};
