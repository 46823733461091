/**
 * @author: Seleznyov and Bazyliuk
 */

import {
  GET_DATA_CONTACTS,
  ADD_CONTACT_SUCCESS,
  ADD_CONTACT_FAILED,
  UPDATE_CONTACT_SUCCESS,
  UPDATE_CONTACT_FAILED,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAILED,
  SHARE_CONTACT_SUCCESS,
  SHARE_CONTACT_FAILED,
} from "./actions/types";
import initialState from "./initialState";

const addElement = (copyState, payload) => {
  const element = copyState.find(item => item.id === payload.id);
  const index = copyState.indexOf(element);
  copyState[index] = payload;

  return copyState;
};

const dataContacts = (state = initialState, action) => {
  const {type, payload} = action;
  const copyState = state.contacts.slice();

  switch (type) {
    case GET_DATA_CONTACTS:
      return {
        ...state,
        contacts: payload,
      };

    case ADD_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: [...copyState, payload],
      };

    case ADD_CONTACT_FAILED:
      return state;

    case UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: addElement(copyState, payload),
      };

    case UPDATE_CONTACT_FAILED:
      return state;

    case DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: copyState.filter(item => item.id !== payload),
      };

    case DELETE_CONTACT_FAILED:
      return state;

    case SHARE_CONTACT_SUCCESS:
      return state;

    case SHARE_CONTACT_FAILED:
      return state;

    default:
      return state;
  }
};

export {dataContacts};
