import {defineMessages} from "react-intl";

const scope = "ActionsApprovalPage";

const messages = defineMessages({
  actionsApprovalPageTitle: {
    id: `${scope}.actionsApprovalPageTitle`,
    defaultMessage: "Actions Approval",
  },
  approvalHistory: {
    id: `${scope}.approvalHistory`,
    defaultMessage: "Approval history",
  },
  pendingActions: {
    id: `${scope}.pendingActions`,
    defaultMessage: "Pending Actions",
  },
  dateTime: {
    id: `${scope}.dateTime`,
    defaultMessage: "Date & Time",
  },
  actionName: {
    id: `${scope}.actionName`,
    defaultMessage: "Action Name",
  },
  customer: {
    id: `${scope}.ustomer`,
    defaultMessage: "Customer",
  },
  originator: {
    id: `${scope}.originator`,
    defaultMessage: "Originator",
  },
  supervisors: {
    id: `${scope}.supervisors`,
    defaultMessage: "Supervisors",
  },
  approvalHistoryTitle: {
    id: `${scope}.approvalHistoryTitle`,
    defaultMessage: "Approval History",
  },
});

export default messages;
