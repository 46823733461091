export const FIRST_SECTION = [
  {
    id: "1.",
    text: "To respect our privacy policy and Intellectual property rights.",
  },
  {
    id: "2.",
    text: "To comply with applicable laws including laws of your respective Country.",
  },
  {
    id: "3.",
    text: "Not to defraud, defame, harass and disrupt.",
  },
  {
    id: "4.",
    text:
      "In case of any disagreements we try to fix it as soon as possible avoid any legal action.",
  },
];

export const LICENSE_OUR_SERVICES = [
  {
    id: "1.",
    text: "You must be at least or above 18 years of age. ",
  },
  {
    id: "2.",
    text:
      "You should neither be barred nor prohibited under any applicable laws from receiving or using our services.",
  },
  {
    id: "3.",
    text: "You should be capable of entering into a legally binding agreement.",
  },
];

export const TERM_OF_USE = [
  {
    id: "2.1",
    text:
      "On entering into this contract you have not relied on any representation or information from any source except the definition and explanation of the Services given on Our Website.",
  },
  {
    id: "2.2",
    text:
      "Subject to these terms and conditions, we agree to provide to you some or all of the services described on Our Website from time to time.",
  },
  {
    id: "2.3",
    text:
      "OnWallet opens Master Account in Master/Custodian Bank. Master Account consists of;",
    details: [
      {
        id: "a)",
        text:
          "Set of Customers sub accounts. Each sub account represents funds allocated within OnWallet system by customers. Funds can be allocated in Fiat currency.",
      },
      {
        id: "b)",
        text: "OnWallet fund account - general account of OnWallet Limited.",
      },
    ],
  },
  {
    id: "2.4",
    text:
      "Creation of an Account: You can download or install our App from Google Play for Android and Apple Store for iOS. Then, by registering and providing the KYC details like Name, Address, Age, and Verification ID you can create an OnWallet account successfully. You can create three types of accounts with us; ",
    details: [
      {
        id: "•",
        text: "Personal Account",
      },
      {
        id: "•",
        text: "Institutional Account",
      },
      {
        id: "•",
        text: "Broker Account",
      },
    ],
  },
  {
    id: "2.5",
    text:
      "Range of our Services Upon creation of your account, you can send, receive and exchange money anywhere in the World.",
    details: [
      {
        id: "i.",
        text:
          "Creation of Gold/Silver Account: Upon creation of an account, users can sell, buy or repurchase Gold/Silver service providers through the app.  OnWallet assumes no liability with regard to your sale, purchase or repurchase using our OnWallet gold/silver account.",
      },
      {
        id: "ii.",
        text:
          "Movie & Bus/Train Tickets: Users can buy or book Bus/Train and Movie tickets using their OnWallet account.",
      },
      {
        id: "iii.",
        text:
          "Our Website and our Services are provided “as is”. We make no representation or warranty that the Service will be useful to you; of satisfactory quality; fit for a particular purpose; available or accessible, without interruption, or without error.",
      },
      {
        id: "iv.",
        text:
          "If it is necessary for us to interrupt the Services, we will give you reasonable notice where this is possible and when we judge the down time is such as to justify telling you.",
      },
      {
        id: "v.",
        text:
          "You acknowledge that the Services may also be interrupted for many reasons beyond our control.",
      },
      {
        id: "vi.",
        text:
          "You agree that we are not liable to you for any loss, foreseeable or not, arising from any interruption to the Services.",
      },
    ],
  },
  {
    id: "2.6",
    text: "Obligations",
    upperText: "We shall use our best endeavours to provide the following:",
    lowerText:
      "The information you provide is subject to Confidentiality and accuracy requirements as the rest of your identifying information. Providing false or inaccurate information or using the website or services to further Fraud or unlawful activity is ground for immediate termination of these Terms of Service. The owner reserves the right to refuse service, terminate accounts or remove content on its sole discretion. You agree that you will be solely responsible for any fraud or illegal or unauthorized use or access of your information through our App. In case you detect or notice any use of your OnWallet account in any unauthorized/illegal/fraudulent manner, you shall inform the same in writing to our email info@onwallet.com.",
    details: [
      {
        id: "i.",
        text: "Uninterrupted access to this website and the services it provides",
      },
      {
        id: "ii.",
        text:
          "Accurate updating of material and information as often as reasonably possible.",
      },
      {
        id: "iii.",
        text:
          "Protection of your privacy as stated in our privacy policy including the protection of personal and financial data and debit/credit card information.",
      },
    ],
  },
  {
    id: " ",
    text: "Your obligations include providing the following information to us;",
    details: [
      {
        id: "i.",
        text:
          "i.	Your personal information including but not limited to your name, address, age, email address, password",
      },
      {
        id: "ii.",
        text: "Debit/Credit card information - Card Number, Account Number and Pin.",
      },
      {
        id: "iii.",
        text:
          "iii.	Transaction history - Billing address and other relevant payment information.",
      },
    ],
  },
  {
    id: "2.7",
    text: "Restrictions of usage",
    upperText:
      " You shall use this mobile application strictly in accordance with the terms and conditions below mentioned;",
    details: [
      {
        id: "i.",
        text:
          "Separate the component parts of the Software for use on more than one Computer. Reverse engineer, modify, adapt, translate, decompile, disassemble, create derivative works, attempt to discover software source code, underlying ideas or algorithms of the Software;",
      },
      {
        id: "ii.",
        text:
          "Sub-license, lease, or lend or pledge or distribute  the Software or assign any rights herein;",
      },
      {
        id: "iii.",
        text: "Copy or Publish except as specifically allowed in this agreement;",
      },
      {
        id: "iv.",
        text:
          "Represent or give the impression that you are the owner or originator of this mobile application;",
      },
      {
        id: "v.",
        text:
          "Remove, modify or conceal any identification or reference number or other information which may be embedded in any file.",
      },
      {
        id: "vi.",
        text:
          "Use this application for creating a product, service of software that is directly or indirectly, competitive with or in any way a substitute for any software or services provided by us.",
      },
      {
        id: "vii.",
        text:
          "Use any proprietary information or interfaces of Company or other Intellectual property of the Company in the design development, manufacture, licensing or distribution of any applications, accessories or devices for use with the Application.",
      },
      {
        id: " ",
        text:
          "You also agree that you will not use or allow anyone else to use or undertake any activity which is or may:",
      },
      {
        id: "viii.",
        text: "Be unlawful, or tend to incite another person to commit a crime;",
      },
      {
        id: "ix.",
        text: "Be obscene, offensive, threatening, violent, malicious or defamatory;",
      },
      {
        id: "x.",
        text: "Be sexually explicit or pornographic;",
      },
      {
        id: "xi.",
        text:
          "Be likely to deceive any person or be used to impersonate any person, or to misrepresent your identity, age or affiliation with any person;",
      },
      {
        id: "xii.",
        text:
          "Request or collect passwords or other personal information from another user without his permission;",
      },
      {
        id: "xiii.",
        text:
          "Be used to sell any goods or services or for any other commercial use not intended by us, for you or for any other person. Examples are: sending private messages with a commercial purpose, or collecting information with the intention of passing it to a third party for his commercial use;",
      },
      {
        id: "xiv.",
        text:
          "Include anything other than words (i.e. you will not include any symbols or photographs) except for a photograph of yourself in your profile in such place as we designate;",
      },
      {
        id: "xv.",
        text:
          "Facilitate the provision of unauthorised copies of another person's copyright work;",
      },
      {
        id: "xvi.",
        text: "Link to any of the material specified in this paragraph;",
      },
      {
        id: "xvii.",
        text: "Post excessive or repeated off-topic messages to any forum or group;",
      },
      {
        id: "xviii.",
        text:
          "Sending age-inappropriate communications or Content to anyone under the age of 18.",
      },
    ],
  },
  {
    id: "2.8",
    text: "Payments & Fees",
    upperText:
      "Should you register for any of the paid services on this website/app or purchase any products or services on this website, you agree to pay us the specific monetary amount as required for the product or services. The monetary terms will be described to you during registration of your account. The final amount required for payment will be shown to you immediately prior to purchase. We reserve the right to refuse service or to sell the products on the website at our sole discretion at any time to anyone.",
  },
  {
    id: "2.9",
    text: "Offers",
    upperText:
      "The offers provided by us are according to the general Terms and Conditions available on our website.The offers provided by the Merchants/Vendors on our website/app are between the user and the service provider and OnWallet shall not assume any responsibility for such content or fulfilment of those offers.",
  },
  {
    id: "2.10",
    text: "Indemnification",
    upperText:
      " You agree to indemnify, defend and hold harmless OnWallet and its affiliates (“Indemnified Parties”) from and against all third party claims,  liabilities, obligations, losses, damages, costs, claims and expenses (including but not limited to attorney’s fees and court costs)  (“Indemnified Costs”) arising directly or indirectly from:",
    details: [
      {
        id: "i.",
        text: "your failure to comply with the law of any country",
      },
      {
        id: "ii.",
        text:
          "your breach of this agreement or any of your acts, omissions or representations;",
      },
      {
        id: "iii.",
        text:
          "any act, neglect or default by any agent, employee, licensee or customer of yours;",
      },
      {
        id: "iv.",
        text: "a contractual claim arising from your use of the Services",
      },
      {
        id: "v",
        text: "a breach of the intellectual property rights of any person;",
      },
    ],
  },
  {
    id: " ",
    text:
      "and for the purpose of this paragraph you agree that the cost of our management and technical time is properly recoverable and can reasonably be valued at per hour without further proof.",
  },
  {
    id: "2.11",
    text: "Assumption of Risk",
    upperText:
      "You acknowledge and agree that the information posted on our website is not intended to be Legal advice, Medical advice, and financial advice and creates no fiduciary relationship between You and Us. You further agree that your purchase of any products on the website is at your own risk and OnWallet does not assume any responsibility or liability for any information or advice provided on our website. You agree that your use of website or services is at your own risk and OnWallet does no accept any responsibility for the security of your account.",
  },
  {
    id: "2.12",
    text: "Third Party Link & Contents",
    upperText:
      "OnWallet may occasionally post any links or content related to third party websites or services. You agree that OnWallet is not responsible or liable for any loss or Damage caused as a result of your use of the third party content or links provided on our website.",
  },
  {
    id: "2.13",
    text: "Intellectual Property Rights",
    upperText: "You agree that at all times you will:",
    details: [
      {
        id: "a.",
        text:
          "not do anything which does or might reduce the value of our Intellectual Property or challenge our ownership of it.",
      },
      {
        id: "b.",
        text: "notify us of any suspected infringement of the Intellectual Property;",
      },
      {
        id: "c.",
        text:
          "c.	so far as concerns software provided or made accessible by us to you, you will not:",
        details: [
          {
            id: "i.",
            text: "copy, or make any change to any part of its code;",
          },
          {
            id: "ii.",
            text: "use it in any way not anticipated by this agreement;",
          },
          {
            id: "iii.",
            text:
              "give access to it to any other person than you, the licensee in this agreement;",
          },
          {
            id: "iv.",
            text:
              "in any way provide any information about it to any other person or generally.",
          },
        ],
      },
      {
        id: "d.",
        text:
          "You acknowledge and agree that the Application and all copyrights, patents, trademarks and other Intellectual Property Rights associated therewith, are, and shall remain the property of OnWallet exclusively. Except, as expressly stated in this Terms & Conditions, you are not granted any Intellectual Property Rights in or to the application by implication, estoppels or other legal theory, and all rights in and to the Application not expressly granted in this Terms & Conditions are hereby reserved and retained by OnWallet.",
      },
      {
        id: "e.",
        text:
          "You are not authorised to use the trademarks of OnWallet in any advertising, publicity or in any other commercial manner without any prior consent on OnWallet, which may be withheld for any or no reason.",
      },
      {
        id: "f.",
        text:
          "You acknowledge and accept that in any event of a third party claim on your use of the mobile application infringes any third party’s Intellectual property rights, you will be held responsible for the investigation, defence, settlement and discharge of any such claims. In such an event, you will notify OnWallet about the claim.",
      },
    ],
  },
  {
    id: "2.14",
    text: "Term, Termination or Suspension",
    upperText: "These Terms and Conditions shall be effective unless terminated by you.",
    details: [
      {
        id: "a.",
        text:
          "You may terminate your subscription to the website on (10) days notice to us.",
      },
      {
        id: "b.",
        text: "We may terminate your use of the website, if:",
        details: [
          {
            id: "i.",
            text: "you fail to pay sums due to us promptly",
          },
          {
            id: "ii.",
            text: "you are in material breach of any of these terms and conditions",
          },
        ],
      },
      {
        id: "c.",
        text:
          "Any rights that have accrued to either party at the date of termination will remain enforceable after termination.",
      },
    ],
  },
  {
    id: "2.15",
    text: "Governing Law",
    upperText:
      "You hereby agree that the Terms & Conditions of our website shall be governed by English law. Any dispute relating to or arising out of these Terms & Conditions of service, as well as any dispute of any kind that may arise between You and OnWallet with the exception of its Conflict of law provision. In case any litigation specifically permitted under these terms is initiated, the user and OnWallet agreed to submit to the exclusive jurisdiction of English courts.",
  },
  {
    id: "2.16",
    text: "Severability and Waiver",
    details: [
      {
        id: "i.",
        text:
          "If in any event, any provision of these Terms & Conditions is held to be invalid or ultra virus or unenforceable in respect to a party and the remaining provisions of this Terms & Conditions shall be valid and enforceable to the fullest extent possible.",
      },
      {
        id: "ii.",
        text:
          "Except as provided, the failure to exercise a right or an obligation under this Terms & Conditions shall not affect a party’s ability to exercise such a right or obligation at any time thereafter nor shall the waiver of a breach constitute waiver of any subsequent breach.",
      },
    ],
  },
  {
    id: "2.17",
    text: "Amendments & Modification",
    upperText:
      "OnWallet may modify or amend the terms of this Terms & Conditions from time to time with or without providing any prior information or notification to you. If you continue to use the application even after the modification or amendment, you will be deemed to have agreed or accepted such amendments or modifications. In the event you fail to monitor any modifications or amendments to our Terms & Conditions, you agree that such failure shall be considered an affirmative waiver of your right to review the modified Terms & Conditions and thereby bound by it.",
  },
  {
    id: "2.18",
    text: "Invalid Clause",
    upperText:
      "In the event that any of these terms and conditions is found to be invalid or otherwise unenforceable, then such term shall be regarded and construed as severable from the remaining terms and conditions so as to not to affect the validity and enforceability of the remainder.",
  },
  {
    id: "2.19",
    text: "Permitted Communications",
    upperText:
      "In the event of any questions, concerns, queries and enquiry You and OnWallet are permitted to communicate through email to the following email address; info@onwallet.com.",
  },
  {
    id: "2.20",
    text: "Security of Our Website",
    details: [
      {
        id: "i.",
        text: "If you violate Our website we shall take legal action against you",
      },
      {
        id: "ii.",
        text: "You now agree that you will not, and will not allow any other person to:",
        details: [
          {
            id: "a.",
            text:
              "modify, copy, or cause damage or unintended effect to any portion of Our Website, or any software used within it.",
          },
          {
            id: "b.",
            text:
              "link to our site in any way that would cause the appearance or presentation of the site to be different from what would be seen by a user who accessed the site by typing the URL into a standard browser;",
          },
          {
            id: "c.",
            text:
              "download any part of Our Website, without our express written consent;",
          },
          {
            id: "d.",
            text:
              "collect or use any product or service listings, descriptions, or prices;",
          },
          {
            id: "e.",
            text:
              "collect or use any information obtained from or about Our Website or the Content except as intended by this agreement;",
          },
          {
            id: "f.",
            text:
              "aggregate, copy or duplicate in any manner any of the Content or information available from Our Website, other than as permitted by this agreement or as is reasonably necessary for your use of the Services;",
          },
          {
            id: "g.",
            text: "share with a third party any login credentials to Our Website;",
          },
        ],
      },

      {
        id: "iii.",
        text:
          "create a hyperlink to Our Website for the purpose of promoting an interest common to both of us. You can do this without specific permission. This licence is conditional upon your not portraying us or any product or service in a false, misleading, derogatory, or otherwise offensive manner. You may not use any logo or other proprietary graphic or trademark of ours as part of the link without our express written consent.",
      },
      {
        id: "iv.",
        text:
          "you may copy the text of any page for your personal use in connection with the purpose of Our Website or a Service we provide.",
      },
    ],
  },
  {
    id: "2.21",
    text: "Spam Policy",
    upperText:
      "You are strictly prohibited from using the website or any of OnWallet's services for any illegal or spam activities including but not limited to gathering email addresses and personal information from others or sending any mass commercial emails. ",
  },
  {
    id: "2.22",
    text: "Deletion of your OnWallet Account",
    upperText:
      "Upon deleting your account, all your pending transactions will be cancelled by OnWallet and if any fund is held by us at the time of deleting your account, we will return the same according to our Opt-Out policy after deducting the applicable fees.",
  },
  {
    id: "2.23",
    text: "Miscellaneous matters",
    details: [
      {
        id: "a.",
        text:
          "The schedules, if any, to this agreement are part of the agreement and have the same force and effect.",
      },
      {
        id: "b.",
        text:
          "If any term or provision of this agreement is at any time held by any jurisdiction to be void, invalid or unenforceable, then it shall be treated as changed or reduced, only to the extent minimally necessary to bring it within the laws of that jurisdiction and to prevent it from being void and it shall be binding in that changed or reduced form. Subject to that, each provision shall be interpreted as severable and shall not in any way affect any other of these terms.",
      },
      {
        id: "c.",
        text:
          "The rights and obligations of the parties set out in this agreement shall pass to any permitted successor in title.",
      },
      {
        id: "d.",
        text: "If you are in breach of any term of this agreement, we may:",
        details: [
          {
            id: "i.",
            text: "terminate your account and refuse access to Our Website;",
          },
          {
            id: "ii.",
            text: "remove or edit Content, or cancel any order at our discretion;",
          },
          {
            id: "iii.",
            text: "issue a claim in any court.",
          },
        ],
      },
      {
        id: "e.",
        text:
          "Any obligation in this agreement intended to continue to have effect after termination or completion shall so continue.",
      },
      {
        id: "f.",
        text:
          "No failure or delay by any party to exercise any right, power or remedy will operate as a waiver of it nor indicate any intention to reduce that or any other right in the future.",
      },
      {
        id: "g.",
        text:
          "You agree that we may disclose your information including assigned IP numbers, account history, account use, etc to any judicial or proper legal authority that makes a written request without further consent or notification to you.",
      },
      {
        id: "h.",
        text:
          "Any communication to be served on either party by the other shall be delivered by hand or sent by first class post or recorded delivery or by e-mail.",
        details: [
          {
            id: "i.",
            text: "It shall be deemed to have been delivered:",
          },
          {
            id: "ii.",
            text: "if delivered by hand: on the day of delivery;",
          },
          {
            id: "iii.",
            text: "if sent by post to the correct address: within 72 hours of posting;",
          },
          {
            id: "iv.",
            text:
              "If sent by e-mail to the address from which the receiving party has last sent e-mail: within 24 hours if no notice of non-receipt has been received by the sender.",
          },
        ],
      },
      {
        id: "i.",
        text:
          "In the event of a dispute between the parties to this agreement, then they undertake to attempt to settle the dispute by engaging in good faith with the other in a process of mediation before commencing arbitration or litigation.",
      },
      {
        id: "j.",
        text: "This agreement does not give any right to any third party.",
      },
      {
        id: "k.",
        text:
          "Neither party shall be liable for any failure or delay in performance of this agreement which is caused by circumstances beyond its reasonable control.",
      },
      {
        id: "l.",
        text:
          "In the event of any conflict between any term of this agreement and the provisions of the articles of a limited company or any comparable document intended to regulate any other corporate or collective body, then the terms of this agreement shall prevail.",
      },
    ],
  },
];

export const POLICIES = [
  {
    id: "3.1",
    text: "Privacy Policy",
    upperText:
      "We believe that privacy is a fundamental right of an individual and we will protect/safeguard the information given by the users with utmost security. Please read our privacy policy before accessing, using and downloading OnWallet app or website. Once you tap the I ACCEPT you give us your consent to use your personal information by us. We may change/review our privacy policy from time to time without giving any prior intimation to the users, kindly go through our privacy policy regularly.",
    details: [
      {
        id: "3.1.1",
        text: "Personal information we collect from users:",
        details: [
          {
            id: "i.",
            text:
              "Name, address, contact information, email ID, date of birth, credit/debit card numbers, pin numbers, identity proof. ",
          },
          {
            id: "ii.",
            text: "Transaction details you make using our app or website.",
          },
          {
            id: "iii.",
            text:
              "Bank transaction details (account number, name of the account holder, expiry date of the card, PIN numbers, passwords, previous transaction history)",
          },
          {
            id: "iv.",
            text: "Any SMS or calls made by us to the users.",
          },
          {
            id: "v.",
            text: "The details of the surveys which you fill.",
          },
          {
            id: "vi.",
            text: "Store information received through cookies.",
          },
          {
            id: "vii.",
            text:
              "If you use our website- login times, IP address, cookie, browser type, number of visits to our website, weblogs, communication data and location.",
          },
        ],
      },
      {
        id: "3.1.2",
        text: "Usage of Personal information collected from users:",
        upperText:
          "We will be using the personal information collected from the users for the following purposes:",
        details: [
          {
            id: "i.",
            text:
              "To provide users suggestions regarding the products and services they intend to order.",
          },
          {
            id: "ii.",
            text: "To collect feedback in regard to the products and services.",
          },
          {
            id: "iii.",
            text:
              "To conduct optional surveys periodically. We will be using the data collected through surveys in improvising our app and website experiences and preferences that the users might be interested in and also in upgrading the security our app or website.",
          },
          {
            id: "iv.",
            text:
              "To send information with regard to any important notices, changes in services, terms and conditions and policies.",
          },
          {
            id: "v.",
            text:
              "To protect and detect the users and us from spam, fraud, error or any other criminal activity, misuse of our terms and conditions and anti- money laundering.",
          },
          {
            id: "vi.",
            text:
              "To enhance or revamp our app and website appearance and usage making it more user friendly.",
          },
        ],
      },
      {
        id: "3.1.3",
        text: "General Use of information provided by users:",
        upperText:
          "The personal information provided by the users is disclosed to any third party other than the following:",
        lowerText:
          "Note: Kindly note that we may provide all the personal information of the users if it is so required by law or in the better interests to protect the rights and safety of our website and users.",
        details: [
          {
            id: "i.",
            text:
              "Any legal authorities, police or courts only when such information is requested by them.",
          },
          {
            id: "ii.",
            text: "To any of the buyers of our app or website.",
          },
          {
            id: "iii.",
            text:
              "Anyone with whom we partner jointly in providing better services to users.",
          },
          {
            id: "iv.",
            text:
              "When the users are interested in knowing information about any third parties.",
          },
          {
            id: "v.",
            text: "The parties who have referred the users to us. ",
          },
        ],
      },
      {
        id: "3.1.4",
        text: "Anti-Money Laundering Policy",
        upperText:
          "To be in accordance with Anti- Money Laundering Policy and in order to avoid and monitor any suspicious transactions, the users are required to complete their KYC procedure by submitting their identity proof containing their address. In case of any suspicious transaction, OnWallet reserves all the rights to report such suspicious/malicious transaction to the respective law enforcement agencies/authorities. If we observe any fraudulent, suspicious, malicious transactions, illegal activities or any criminal activity, OnWallet may directly and without any prior notification do the following immediately:",
        details: [
          {
            id: "i.",
            text: "De-activate their OnWallet account.",
          },
          {
            id: "ii.",
            text: "Retain any money available in their OnWallet account.",
          },
          {
            id: "iii.",
            text: "Report such transactions to any law enforcement agencies/authorities.",
          },
          {
            id: "iv.",
            text:
              "Regulate the user from using or registering their accounts on OnWallet for a certain period of time.",
          },
        ],
      },
      {
        id: "3.1.5",
        text: "Sharing of Personal Information",
        upperText:
          "We do not share your personal information with any Third parties without your prior consent other than;",
        details: [
          {
            id: "i.",
            text:
              "the third parties who work for us or on our behalf provided such third parties comply to the data protection principles and other applicable legislations or enter into a written agreement with us requiring that they provide same level of privacy protection as is required,",
          },
          {
            id: "ii.",
            text: "to comply with laws or respond to lawful requests and processes,",
          },
          {
            id: "iii.",
            text:
              "to protect the rights of OnWallet, our agents, affiliates, employees and customers including to enforce our agreement, policies and terms of use,",
          },
          {
            id: "iv.",
            text:
              "in an emergency including but not limited to protection of personal safety of any person.",
          },
        ],
      },
    ],
  },
  {
    id: "3.2",
    text: "Cookie Policy",
    upperText:
      "Cookies are small text files stored on your computers main memory out of which a few are deleted after you close the browser and other cookies remain on your electronic device and permit its recognition on your next visit. These cookies help us improve your access to our website and help us learn more about your interest and provide you with essential features and services for additional purposes including;",
    details: [
      {
        id: "i.",
        text: "Preventing any fraud lent activity,",
      },
      {
        id: "ii.",
        text: "Improving our security.",
      },
    ],
  },
  {
    id: "3.3",
    text: "Refund and Cancellation Policy",
    upperText:
      "All the transactions, processed through our App are non-refundable and non-reversible/cancellable as OnWallet does not assume any liability, responsibility, or ownership for;",
    lowerText:
      "Any sales of prepaid transactions made using our App are final with no refund or cancellation policy allowed. The User is solely responsible for the transaction and our App does not assume any responsibility in case of any transaction made improperly by the user. In case the transaction happened through our App and the user have not received any information about the same, within 24 hours of the completion of the transaction, the user shall inform us or by sending an email to our email address available on the App, our team will investigate the matter and if it is found that your money has been deducted and the transaction has not been made, our App will refund the money once the refund has been processed by the merchant/seller/vendor in the following timeline mentioned below:",
    details: [
      {
        id: "i.",
        text:
          "any transaction happened between the user and vendor/merchant/seller our App will not at any time warrant any rights, claims or possession of any products or services provided by the merchant/vendor/seller. ",
      },
      {
        id: "ii.",
        text:
          "in case of any refunds/cancellations, if any, they will be executed according to the terms and conditions laid down by the vendor/merchant/seller.",
      },
      {
        id: "iii.",
        text:
          "for reversing any transacted amount, unless the vendor/merchant/seller requests for the reversal of the transacted amount, only in that event, our App will reverse the transacted amount to the user. ",
      },
      {
        id: "iv.",
        text:
          "If the transaction is relating to the purchase/redemption/sale/transfer of Gold, the relevant taxes will be charged as per the regulations issued by the Government. The user is not allowed to redeem and purchase Gold on the same day. ",
      },
    ],
  },
];
