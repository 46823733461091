/**
 * @author: Seleznyov and Bazyliuk
 */

import React, {Fragment} from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {ReactComponent as StaffAddIcon} from "images/icon-contacts-add.svg";
// Actions
import {openRightModal} from "shared/components/RightModal/actions";
// Components
import Button from "shared/components/Button";
import Header from "shared/components/Header";
import StaffDetailsModals from "./StaffDetailsModals";
// Utils
import messages from "./messages";
// Styles
import "./index.scss";

import config from "shared/config";
import StaffList from "./components/StaffList";
const logoPage = config.prefixImgUrl + "staff-icon-title.svg";

const StaffPage = ({openRightModal}) => {
  return (
    <Fragment>
      <StaffDetailsModals />

      <Header
        headerTitle={<FormattedMessage {...messages.staffTitle} />}
        navLinks={[]}
        logoImage={logoPage}
      >
        <div className="messages__header__actionBlock">
          <Button
            type="button"
            text={messages.newStaffMember}
            className="btn-ui btn-bold-black btn-with-icon"
            renderIcon={className => <StaffAddIcon className={className} />}
            onClick={() =>
              openRightModal({
                name: "newStaffMember",
                title: "New Staff Member",
              })
            }
          />
        </div>
      </Header>

      <div className="staff-page">
        <StaffList />
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  openRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffPage);
