import React, {useState} from "react";
import "./index.scss";

//todo get dynamiclly
const statisticsFilter = [
  "Total Customers",
  "Last 24 hours",
  "Private Individuals",
  "Companies",
];

const CategoryDropDown = ({onChange}) => {
  return (
    <select className="customers-chart-dropdown" onChange={onChange}>
      {statisticsFilter.map(item => {
        return (
          <option value={item} key={item}>
            {item}
          </option>
        );
      })}
    </select>
  );
};

//TODO: get dynamiclly
const intervals = [
  {label: "1m", value: "1MIN", isActive: false},
  {label: "1H", value: "1HOUR", isActive: false},
  {label: "1D", value: "1DAY", isActive: false},
  {label: "1W", value: "1WEEK", isActive: true},
  {label: "1M", value: "1MONTH", isActive: false},
];

const TimeFilter = ({onChange}) => {
  const [timeIntervals, updateTimeIntervals] = useState(intervals);

  return (
    <div className="time-filter">
      {timeIntervals.map((interval, index) => (
        <div
          className={`time-filter-button ${interval.isActive ? "active" : null}`}
          key={index}
          onClick={() => {
            updateTimeIntervals(
              timeIntervals.map(item => ({
                ...item,
                ...{isActive: item.value === interval.value},
              }))
            );
            onChange(interval);
          }}
        >
          {interval.label}
        </div>
      ))}
    </div>
  );
};

const ChartFilter = ({onChangeFilter}) => {
  const [interval, setTimeInterval] = useState("1WEEK");
  const [category, setCoin] = useState("Total Customers");

  return (
    <>
      <CategoryDropDown
        onChange={e => {
          setCoin(e.target.value);
          onChangeFilter({category: e.target.value, interval});
        }}
      />

      <TimeFilter
        onChange={interval => {
          setTimeInterval(interval.value);
          onChangeFilter({category, interval: interval.value});
        }}
      />
    </>
  );
};

export default ChartFilter;
