import React, {useEffect} from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {Formik, Form, Field, useFormikContext} from "formik";
import * as Yup from "yup";
import {
  getAmountInOtherCoin,
  getPerOtherCoin,
  getCryptoWallets,
} from "shared/walletService/selectors";
import "./index.scss";
import messages from "../../messages";

import Select from "shared/components/Select";
import Input from "shared/components/Input";
import Button from "shared/components/Button";
import Textarea from "shared/components/Input/Textarea";
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
// import InputWithTimer from "shared/components/InputWithTimer";
import {addCartItem} from "shared/cartService/actions";
import {transferCryptoToCrypto} from "shared/walletService/actions";
import {
  WalletOptionShort,
  WalletOption,
  WalletOptionSelected,
  CardOption,
} from "shared/components/CardWalletOptions";

import {getCurrencyRates} from "shared/ratesService/selectors";
import {WalletFiatOption} from "shared/components/CardWalletOptions";
import {getBankCards} from "shared/cardService/selectors";
import {dataRightModal} from "shared/components/RightModal/selectors";
import {
  DetectCryptoWallet,
  DetectRegularCard,
  SendRecipient,
} from "../TransferModal/components";
import {transferCardToCrypto} from "shared/walletService/actions";

import config from "shared/config";
const dollarIcon = config.prefixImgUrl + "dollar-icon.svg";
const euroIcon = config.prefixImgUrl + "euro-icon.svg";
const poundIcon = config.prefixImgUrl + "pound-icon.svg";
const arrowIcon = config.prefixImgUrl + "left-arrow-icon.svg";

const FiatCurrency = [
  {
    value: "card",
    name: "Dollar USA",
    icon: dollarIcon,
    currency: "USD",
  },
  {
    value: "card",
    name: "Euro",
    icon: euroIcon,
    currency: "EUR",
  },
  {
    value: "card",
    name: "British Pound",
    icon: poundIcon,
    currency: "GBP",
  },
];

// TODO: Transfer to global config
const DEHO = "Deho";
const DEHO_GOLD = "Deho Gold";

const ExchangeSchema = Yup.object().shape({
  source: Yup.object()
    .nullable()
    .default(null)
    .required("Source is required"),
  sourceCard: Yup.object()
    .nullable()
    .default(null)
    .when("source.value", {
      is: "card",
      then: fieldSchema =>
        fieldSchema.required("Date is required").required("Source card is required"),
    }),
  recipient: Yup.object()
    .nullable()
    .default(null)
    .required("Recipient is required"),
  sourceAmount: Yup.string()
    .required("Amount is required")
    .matches(
      "([1-9]\\d*(\\.\\d*[1-9])?|0\\.\\d*[1-9]+)|\\d+(\\.\\d*[1-9])?",
      "Source Amount must be a number"
    ),
  recipientAmount: Yup.string()
    .required("Amount is required")
    .when("recipient.walletName", {
      is: DEHO_GOLD, // alternatively: (val) => val == true
      then: fieldSchema =>
        fieldSchema.matches("^\\d+$", "Deho Amount must be a integer number"),
      otherwise: fieldSchema =>
        fieldSchema.matches(
          "([1-9]\\d*(\\.\\d*[1-9])?|0\\.\\d*[1-9]+)|\\d+(\\.\\d*[1-9])?",
          "Recipient Amount must be a number"
        ),
    }),
});

const ExchangeModal = ({
  allWallets,
  recipient,
  addCartItem,
  transferCryptoToCrypto,
  currencyRates,
  bankCards,
  transferCardToCrypto,
  customer,
}) => {
  // HINT: recipient define type modal deho buy or exchange
  // TODO: remove when Deho will be available
  // TODO: Optimization for customer (admin panel)
  const filteredSourceWallet = allWallets.filter(
    wallet => !wallet.walletName.includes(DEHO)
  );

  const initialValues = {
    source: null,
    sourceCard: null,
    sourceAmount: 0,
    recipient: allWallets.find(wallet => wallet.walletName === DEHO_GOLD),
    recipientAmount: 0,
    description: "",
    sendRecipient: false,
  };

  const getRounded = value => {
    let rounded;
    rounded = Math.round(value);
    if (rounded < value) {
      rounded = rounded + 1;
    }

    return rounded;
  };

  const onSubmit = values => {
    if (values.source.value === "card" && values.sourceCard) {
      return {
        cryptoAmount: getAmountInOtherCoin(
          values.source ? values.source.currency : values.source,
          values.source ? values.recipient.currency : values.source,
          values.recipientAmount,
          currencyRates
        ),
        recipientAddress: values.recipient ? values.recipient.address : "",
        recipientCurrency: values.recipient
          ? values.recipient.currency.toLowerCase()
          : "",
        sourceNumber: values.sourceCard ? values.sourceCard.number : "",
      };
    } else {
      return {
        cryptoAmount: values.recipientAmount,
        sourceCurrency: values.source ? values.source.currency.toLowerCase() : "",
        recipientCurrency: values.recipient
          ? values.recipient.currency.toLowerCase()
          : "",
        recipientAddress: values.recipient ? values.recipient.address : "",
      };
    }
  };

  const getCartData = values => {
    if (values.source.value === "card") {
      return {
        description: values.description,
        sourceCard: values.sourceCard,
        sourceAmount: values.sourceAmount,
        sourceCurrency: values.sourceCard ? values.sourceCard.currency : "",
        recipientAmount: Number.parseFloat(values.recipientAmount),
        recipientCurrency: values.recipient
          ? values.recipient.currency.toLowerCase()
          : "",
      };
    } else {
      return {
        sourceWallet: values.source,
        sourceAmount: Number.parseFloat(values.sourceAmount),
        sourceCurrency: values.source ? values.source.currency.toLowerCase() : "",
        recipientCurrency: values.recipient
          ? values.recipient.currency.toLowerCase()
          : "",
        recipientAddress: values.recipient ? values.recipient.address : "",
        description: values.description,
        recipientAmount: Number.parseFloat(values.recipientAmount),
      };
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ExchangeSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={values => {
        if (values.source.value === "card") {
          transferCardToCrypto(onSubmit(values));
        } else {
          transferCryptoToCrypto(onSubmit(values));
        }
      }}
    >
      {({errors, touched, values}) => {
        return (
          <div className="ExchangeForm">
            <DetectDeho recipient={recipient} allWallets={allWallets} />
            <DetectRegularCard field="sourceCard" allCards={bankCards} />
            <DetectCryptoWallet field="source" allWallets={filteredSourceWallet} />
            <Form className="form">
              <FormSectionSeparator sectionName={messages.exchangeSubject} />
              <div className="GiveGetContainer">
                <div className="give">
                  <Field
                    name="source"
                    render={({field}) => (
                      <div className="sourceInputContainer">
                        <label className="label">
                          <FormattedMessage {...messages.give} />
                        </label>
                        <Select
                          {...field}
                          renderValue={value =>
                            !!value && value.value === "card" ? (
                              <WalletFiatOption
                                className="no-padding"
                                icon={value.icon}
                                currency={value.name}
                              />
                            ) : !!value ? (
                              <WalletOptionShort className="no-padding" wallet={value} />
                            ) : (
                              ""
                            )
                          }
                        >
                          {filteredSourceWallet
                            .filter(
                              wallet =>
                                !wallet.walletName.includes(DEHO) &&
                                values.recipient &&
                                wallet.walletName !== values.recipient.walletName
                            )
                            .map((wallet, index) => {
                              return (
                                <WalletOptionShort
                                  key={index}
                                  groupHeader={index === 0 ? "Crypto currency" : null}
                                  value={wallet}
                                  wallet={wallet}
                                />
                              );
                            })}
                          {FiatCurrency.map((fiat, index) => (
                            <WalletFiatOption
                              key={index}
                              groupHeader={index === 0 ? "Fiat currency" : null}
                              value={fiat}
                              icon={fiat.icon}
                              currency={fiat.name}
                            />
                          ))}
                        </Select>
                      </div>
                    )}
                  />

                  <Field
                    name="recipientAmount"
                    render={({field}) => (
                      <div className="amountInputContainer">
                        <Input
                          type="number"
                          error={touched.recipientAmount && errors.recipientAmount}
                          value={getRounded(values.recipientAmount)}
                          {...field}
                        />
                      </div>
                    )}
                  />
                </div>
                <div className="equal-sign">
                  <img src={arrowIcon} alt="arrow" />
                </div>
                <div className="give">
                  <Field
                    name="recipient"
                    render={({field}) => (
                      <div className="recipientInputContainer">
                        <label className="label">
                          <FormattedMessage {...messages.get} />
                        </label>
                        <Select
                          {...field}
                          renderValue={value =>
                            !!value ? (
                              <WalletOptionShort className="no-padding" wallet={value} />
                            ) : (
                              ""
                            )
                          }
                          readOnly={recipient === DEHO_GOLD}
                        >
                          {!!recipient
                            ? allWallets
                                .filter(wallet => wallet.walletName.includes(DEHO))
                                .map((wallet, index) => {
                                  return (
                                    <WalletOptionShort
                                      key={index}
                                      value={wallet}
                                      wallet={wallet}
                                    />
                                  );
                                })
                            : !!values.source &&
                              allWallets
                                .filter(
                                  wallet => wallet.walletName !== values.source.walletName
                                )
                                .map((wallet, index) => {
                                  return (
                                    <WalletOptionShort
                                      key={index}
                                      value={wallet}
                                      wallet={wallet}
                                    />
                                  );
                                })}
                        </Select>
                      </div>
                    )}
                  />
                  <div className="amountInputContainer">
                    <Input
                      type="text"
                      value={getAmountInOtherCoin(
                        values.source ? values.source.currency : values.source,
                        values.source ? values.recipient.currency : values.source,
                        values.recipientAmount,
                        currencyRates
                      )}
                      error={touched.sourceAmount && errors.sourceAmount}
                      readOnly={recipient === DEHO_GOLD}
                    />
                  </div>
                </div>
              </div>
              {!!values.source && !!values.recipient && (
                <div className="exchangeRate">
                  <div className="rate">
                    <FormattedMessage {...messages.exchangeRate} />: 1{" "}
                    {values.source.currency}
                    <div className="arrow-right">
                      <img src={arrowIcon} alt="arrow" />
                    </div>
                    {getPerOtherCoin(values.source, values.recipient, currencyRates)}{" "}
                    {values.recipient.currency}
                  </div>
                  <div className="info">
                    <FormattedMessage {...messages.finalExchangeRate} />
                  </div>
                  <div className="timer">
                    {/* TODO add function to restart timer from https://github.com/vydimitrov/react-countdown-circle-timer#props-for-both-reactreact-native*/}
                    <CountdownCircleTimer
                      isPlaying
                      size={26}
                      strokeWidth={2}
                      duration={60}
                      colors={[["#0080ff", 1]]}
                    >
                      {({remainingTime}) => remainingTime}
                    </CountdownCircleTimer>
                  </div>
                </div>
              )}
              {/* <InputWithTimer
              sectionName='Exchange Rate:'
              srcCurrencyVal='1'
              srcCurAbbrev='BTC'
              dstCurrencyVal='163.12057800'
              dstCurAbbrev='DHG'
              secondlineText='Final exchange rate will be fixed at the moment of transaction' /> */}
              <FormSectionSeparator sectionName={messages.details} />
              {values.source && values.source.value === "card" && (
                <Field
                  name="sourceCard"
                  render={({field}) => (
                    <div className="cardInputContainer">
                      <label className="label">
                        <FormattedMessage {...messages.sourceOfTransfer} />
                      </label>
                      <Select
                        {...field}
                        renderValue={value =>
                          !!value ? <CardOption card={value} /> : ""
                        }
                      >
                        {bankCards.map((card, index) => (
                          <CardOption
                            key={index}
                            className="currency-m"
                            value={card}
                            card={card}
                          />
                        ))}
                      </Select>
                    </div>
                  )}
                />
              )}
              {values.source && !values.source.value && (
                <Field
                  name="source"
                  render={({field}) => (
                    <div className="sourceInputContainer">
                      <label className="label">
                        <FormattedMessage {...messages.sourceWallet} />
                      </label>
                      <Select
                        {...field}
                        height={64}
                        renderValue={value =>
                          !!value ? (
                            <WalletOptionSelected className="no-padding" wallet={value} />
                          ) : (
                            ""
                          )
                        }
                      >
                        {allWallets
                          .filter(
                            wallet =>
                              !wallet.walletName.includes("Deho") &&
                              values.recipient &&
                              wallet.walletName !== values.recipient.walletName
                          )
                          .map((wallet, index) => (
                            <WalletOption key={index} value={wallet} wallet={wallet} />
                          ))}
                      </Select>
                    </div>
                  )}
                />
              )}
              <Field
                name="recipient"
                render={({field}) => (
                  <div className="recipientInputContainer topMargin">
                    <label className="label">
                      <FormattedMessage {...messages.recipientWallet} />
                    </label>
                    <Select
                      {...field}
                      height={64}
                      renderValue={value =>
                        !!value ? (
                          <WalletOptionSelected className="no-padding" wallet={value} />
                        ) : (
                          ""
                        )
                      }
                    >
                      {!!recipient
                        ? allWallets
                            .filter(wallet => wallet.walletName.includes(DEHO))
                            .map((wallet, index) => {
                              return (
                                <WalletOption
                                  key={index}
                                  value={wallet}
                                  wallet={wallet}
                                />
                              );
                            })
                        : !!values.source &&
                          allWallets
                            .filter(
                              wallet => wallet.walletName !== values.source.walletName
                            )
                            .map((wallet, index) => {
                              return (
                                <WalletOptionShort
                                  key={index}
                                  value={wallet}
                                  wallet={wallet}
                                />
                              );
                            })}
                    </Select>
                  </div>
                )}
              />
              <Field
                name="description"
                render={({field}) => (
                  <div className="descriptionInputContainer">
                    <label className="label">
                      <FormattedMessage {...messages.descriptionOptional} />
                    </label>
                    <Textarea
                      placeholder="Write an optional description"
                      type="text"
                      {...field}
                    />
                  </div>
                )}
              />
              <SendRecipient touched={touched} errors={errors} values={values} />
              <div className="ReceiveButtons">
                <Button
                  type="button"
                  isSecondary
                  text={messages.addToCart}
                  className="submit"
                  onClick={() => {
                    addCartItem({
                      type: "exchange",
                      data: getCartData(values),
                      action: option =>
                        values.source && values.source.value === "card"
                          ? transferCardToCrypto(onSubmit(values), option)
                          : transferCryptoToCrypto(onSubmit(values), option),
                    });
                  }}
                />
                <Button
                  type="submit"
                  className="submit"
                  text={recipient === DEHO_GOLD ? messages.buyNow : messages.exchangeNow}
                />
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

const DetectDeho = ({recipient, allWallets}) => {
  const {setFieldValue} = useFormikContext();
  useEffect(() => {
    if (recipient === DEHO_GOLD) {
      setFieldValue(
        "recipient",
        allWallets.find(wallet => wallet.walletName === DEHO_GOLD)
      );
    }
  }, [recipient, setFieldValue, allWallets]);
  return null;
};

const mapStateToProps = store => ({
  allWallets: getCryptoWallets(store),
  currencyRates: getCurrencyRates(store),
  bankCards: getBankCards(store),
  customer: dataRightModal(store),
});

const mapDispatchToProps = {
  addCartItem,
  transferCryptoToCrypto,
  transferCardToCrypto,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeModal);
