import React from "react";
import {connect} from "react-redux";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import {FormattedMessage} from "react-intl";

import "./index.scss";
import messages from "../../messages";
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import Select from "shared/components/Select";
import Button from "shared/components/Button";

import {closeRightModal} from "shared/components/RightModal/actions";
import {addCartItem} from "shared/cartService/actions";
import {getAllRegularCurrency, getBankCards} from "shared/cardService/selectors";
import {CardOption} from "shared/components/CardWalletOptions";
import {DetectRegularCard, FeeRate, SendRecipient, SwiftBankForm} from "./components";
import {openFileViewer} from "shared/components/FileViewer/actions";

const initialOwnValues = {
  sourceCard: null,
  country: "",
  amount: "0",
  currency: "USD",
  recipientName: "",
  recipientAccountNumber: "",
  recipientCountry: "",
  recipientCity: "",
  recipientAddress: "",
  valueDate: "",
  purposeFirst: "",
  purposeSecond: "",
  documents: null,
  description: "",
  sendReceipt: false,
  email: "",
};

const swiftFromCardScheme = Yup.object().shape({
  sourceCard: Yup.object()
    .nullable()
    .default(null)
    .required("Source card is required"),
  amount: Yup.string()
    .required("Amount is required")
    .matches(
      "([1-9]\\d*(\\.\\d*[1-9])?|0\\.\\d*[1-9]+)|\\d+(\\.\\d*[1-9])?",
      "Amount must be a number"
    ),
  currency: Yup.string().required("Currency is required"),
  email: Yup.string().email("Email must be valid"),
});

const SWIFTFromCardForm = ({
  isSending,
  openFileViewer,
  bankCards,
  allCurrency,
  addCartItem,
  formTabs,
  cardNumber,
  closeRightModal,
}) => {
  return (
    <Formik
      initialValues={initialOwnValues}
      validationSchema={swiftFromCardScheme}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, {setSubmitting}) => {}}
    >
      {({errors, touched, values, setFieldValue}) => (
        <div className="CardToCardTransferForm">
          <DetectRegularCard
            field="sourceCard"
            allCards={bankCards}
            cardNumber={cardNumber}
          />
          <Form className="form">
            <FormSectionSeparator sectionName={messages.source} />
            {formTabs && formTabs()}
            <Field name="sourceCard">
              {({field}) => (
                <div className="cardInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.sourceOfTransfer} />
                  </label>
                  <Select
                    {...field}
                    error={
                      touched.sourceCard && errors.sourceCard ? errors.sourceCard : ""
                    }
                    renderValue={value =>
                      !!value ? <CardOption className="short" card={value} /> : ""
                    }
                  >
                    {bankCards.map((card, index) => (
                      <CardOption
                        key={index}
                        className="currency-m"
                        value={card}
                        card={card}
                      />
                    ))}
                  </Select>
                </div>
              )}
            </Field>
            <SwiftBankForm
              touched={touched}
              errors={errors}
              allCurrency={allCurrency}
              setFieldValue={setFieldValue}
              values={values}
              openFileViewer={openFileViewer}
            />

            <SendRecipient touched={touched} errors={errors} values={values} />
            <FeeRate
              card={values.sourceCard}
              amount={values.amount}
              currency={values.currency ? values.currency : null}
            />
            <div className="buttonsContainer">
              <Button
                type="button"
                text={messages.addToCart}
                isSecondary="true"
                onClick={() => {
                  closeRightModal();
                  addCartItem({variant: "cardToCard", type: "transfer", data: values});
                }}
              />

              <Button type="sumbit" text={messages.sendNow} isLoading={isSending} />
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

const mapStateToProps = store => ({
  isSending: store.transferCardData.isLoading,
  bankCards: getBankCards(store),
  allCurrency: getAllRegularCurrency(store),
});

const mapDispatchToProps = {
  addCartItem,
  openFileViewer,
  closeRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(SWIFTFromCardForm);
