import {
  FETCH_CRYPTO_WALLETS_PENDING,
  FETCH_CRYPTO_WALLETS_SUCCESS,
  FETCH_CRYPTO_WALLETS_FAILED,
  SET_ACTIVE_CRYPTO_WALLET,
  GET_AVAILABLE_WALLET_PENDING,
  GET_AVAILABLE_WALLET_SUCCESS,
  GET_AVAILABLE_WALLET_FAILED,
  CREATE_CRYPTO_WALLET_FAILED,
  CREATE_CRYPTO_WALLET_PENDING,
  CREATE_CRYPTO_WALLET_SUCCESS,
  FETCH_THIRD_WALLETS_PENDING,
  FETCH_THIRD_WALLETS_SUCCESS,
  SET_ACTIVE_THIRD_WALLET,
  TRANSFER_CRYPTO_TO_CRYPTO_PENDING,
  TRANSFER_CRYPTO_TO_CRYPTO_SUCCESS,
  TRANSFER_CRYPTO_TO_CRYPTO_FAILED,
  TRANSFER_CRYPTO_TO_CARD_PENDING,
  TRANSFER_CRYPTO_TO_CARD_SUCCESS,
  TRANSFER_CRYPTO_TO_CARD_FAILED,
  TRANSFER_CARD_TO_CRYPTO_PENDING,
  TRANSFER_CARD_TO_CRYPTO_SUCCESS,
  TRANSFER_CARD_TO_CRYPTO_FAILED,
  TRANSFER_ONWALLET_TO_CRYPTO_PENDING,
  TRANSFER_ONWALLET_TO_CRYPTO_SUCCESS,
  TRANSFER_ONWALLET_TO_CRYPTO_FAILED,
  GET_ALL_CRYPTO_CURRENCY_PENDING,
  GET_ALL_CRYPTO_CURRENCY_SUCCESS,
  GET_ALL_CRYPTO_CURRENCY_FAILED,
  TRANSFER_CRYPTO_TO_ONWALLET_PENDING,
  TRANSFER_CRYPTO_TO_ONWALLET_SUCCESS,
  TRANSFER_CRYPTO_TO_ONWALLET_FAILED,
  CRYPTO_WALLET_DELETE_PENDING,
  CRYPTO_WALLET_DELETE_SUCCESS,
  CRYPTO_WALLET_DELETE_FAILED,
} from "./types";
import {getTokenData} from "../../auth/selectors";
import {
  cryptoAPICreateWallet,
  cryptoAPiGetAllCryptoCurrency,
  cryptoAPiGetAvailableWallets,
  cryptoAPITransferCardToCrypto,
  cryptoAPITransferCrypto,
  cryptoAPITransferCryptoToCard,
  cryptoAPITransferCryptoToOnWallet,
  cryptoAPITransferOnWalletToCrypto,
  cryptoAPIWalletDelete,
} from "../../api/cryptoApi";
import {handleUnAuthorizationError} from "../../auth/actions";
import {cryptoAPIGetAllCryptoWallets} from "../../api/cryptoApi";
import {closeRightModal} from "../../components/RightModal/actions";
import {getCoinNameByCurrency} from "../selectors";
import {fetchTransactions} from "../../historyService/actions";
import {fetchBankCards, fetchOnwalletCard} from "../../cardService/actions";

const updateInfo = dispatch => {
  setTimeout(() => {
    // TODO: update card and wallet information
    dispatch(fetchTransactions());
    dispatch(fetchOnwalletCard());
    dispatch(fetchBankCards());
    dispatch(fetchCryptoWallets());
  }, 1000);
};

let isFirstGetDeho = true;

export const fetchCryptoWallets = () => (dispatch, getState) => {
  const tokenData = getTokenData(getState());
  if (!tokenData) return;
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };
  dispatch({
    type: FETCH_CRYPTO_WALLETS_PENDING,
  });
  cryptoAPIGetAllCryptoWallets(option)
    .then(res => {
      dispatch({
        type: FETCH_CRYPTO_WALLETS_SUCCESS,
        payload: res.data.map(wallet => {
          wallet.walletName = getCoinNameByCurrency(wallet.currency);
          wallet.currency = wallet.currency.toUpperCase();
          return wallet;
        }),
      });
      if (isFirstGetDeho && !res.data.some(wallet => wallet.currency === "DHO")) {
        setTimeout(() => dispatch(fetchCryptoWallets()), 1000);
        isFirstGetDeho = false;
      }
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error, fetchCryptoWallets));
      dispatch({type: FETCH_CRYPTO_WALLETS_FAILED});
    });
};

export const setActiveCryptoWallet = name => ({
  type: SET_ACTIVE_CRYPTO_WALLET,
  payload: name,
});

export const fetchAllAvailableWallets = () => (dispatch, getState) => {
  const tokenData = getTokenData(getState());
  if (!tokenData) return;
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };
  dispatch({type: GET_AVAILABLE_WALLET_PENDING});
  cryptoAPiGetAvailableWallets(option)
    .then(res => {
      dispatch({type: GET_AVAILABLE_WALLET_SUCCESS, payload: res.data});
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error, fetchAllAvailableWallets));
      dispatch({type: GET_AVAILABLE_WALLET_FAILED});
    });
};

export const fetchAllCryptoCurrency = () => (dispatch, getState) => {
  const tokenData = getTokenData(getState());
  if (!tokenData) return;
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };
  dispatch({type: GET_ALL_CRYPTO_CURRENCY_PENDING});
  cryptoAPiGetAllCryptoCurrency(option)
    .then(res => {
      dispatch({type: GET_ALL_CRYPTO_CURRENCY_SUCCESS, payload: res.data});
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error, fetchAllCryptoCurrency));
      dispatch({type: GET_ALL_CRYPTO_CURRENCY_FAILED});
    });
};

export const addNewCryptoWallet = data => (dispatch, getState) => {
  const tokenData = getTokenData(getState());
  if (!tokenData) return;
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };
  dispatch({type: CREATE_CRYPTO_WALLET_PENDING});
  return cryptoAPICreateWallet(data, option)
    .then(() => {
      dispatch({type: CREATE_CRYPTO_WALLET_SUCCESS});
      dispatch(fetchCryptoWallets());
      dispatch(fetchAllAvailableWallets());
      dispatch(closeRightModal());
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error, addNewCryptoWallet, data));
      dispatch({type: CREATE_CRYPTO_WALLET_FAILED});
      dispatch(closeRightModal());
    });
};

const mockThirdWallets = [
  // {
  //   name: "WalletOne",
  //   number: "#123456789",
  // },
  // {
  //   name: "Payments",
  //   number: "#321654987",
  // },
];

export const fetchThirdWallets = () => dispatch => {
  dispatch({type: FETCH_THIRD_WALLETS_PENDING});
  setTimeout(() => {
    dispatch({type: FETCH_THIRD_WALLETS_SUCCESS, payload: mockThirdWallets});
  }, 2500);
};

export const setActiveThirdWallet = number => ({
  type: SET_ACTIVE_THIRD_WALLET,
  payload: number,
});

export const transferCryptoToCrypto = (data, opt) => (dispatch, getState) => {
  const tokenData = getTokenData(getState());

  // Error handler
  if (!tokenData) return;

  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };

  dispatch({type: TRANSFER_CRYPTO_TO_CRYPTO_PENDING});

  return cryptoAPITransferCrypto(data, option)
    .then(() => {
      dispatch({type: TRANSFER_CRYPTO_TO_CRYPTO_SUCCESS});
      dispatch(fetchCryptoWallets());
      dispatch(closeRightModal());
      updateInfo(dispatch);
      if (opt && opt.success) dispatch(opt.success());
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error, transferCryptoToCrypto, data, opt));
      dispatch({type: TRANSFER_CRYPTO_TO_CRYPTO_FAILED});
      dispatch(closeRightModal());
      updateInfo(dispatch);
      if (opt && opt.error) dispatch(opt.error());
    });
};

export const transferCryptoToCard = (data, opt) => (dispatch, getState) => {
  const tokenData = getTokenData(getState());
  if (!tokenData) return;
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };
  dispatch({type: TRANSFER_CRYPTO_TO_CARD_PENDING});
  return cryptoAPITransferCryptoToCard(data, option)
    .then(() => {
      dispatch({type: TRANSFER_CRYPTO_TO_CARD_SUCCESS});
      dispatch(closeRightModal());
      updateInfo(dispatch);
      if (opt && opt.success) dispatch(opt.success());
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error, transferCryptoToCard, data, opt));
      dispatch({type: TRANSFER_CRYPTO_TO_CARD_FAILED});
      dispatch(closeRightModal());
      updateInfo(dispatch);
      if (opt && opt.error) dispatch(opt.error());
    });
};

export const transferCardToCrypto = (data, opt) => (dispatch, getState) => {
  const tokenData = getTokenData(getState());
  if (!tokenData) return;
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };
  dispatch({type: TRANSFER_CARD_TO_CRYPTO_PENDING});
  return cryptoAPITransferCardToCrypto(data, option)
    .then(() => {
      dispatch({type: TRANSFER_CARD_TO_CRYPTO_SUCCESS});
      dispatch(closeRightModal());
      updateInfo(dispatch);
      if (opt && opt.success) dispatch(opt.success());
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error, transferCardToCrypto, data, opt));
      dispatch({type: TRANSFER_CARD_TO_CRYPTO_FAILED});
      dispatch(closeRightModal());
      updateInfo(dispatch);
      if (opt && opt.error) dispatch(opt.error());
    });
};

export const transferOnWalletToCrypto = (data, opt) => (dispatch, getState) => {
  const tokenData = getTokenData(getState());
  if (!tokenData) return;
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };
  dispatch({type: TRANSFER_ONWALLET_TO_CRYPTO_PENDING});
  return cryptoAPITransferOnWalletToCrypto(data, option)
    .then(() => {
      dispatch({type: TRANSFER_ONWALLET_TO_CRYPTO_SUCCESS});
      dispatch(closeRightModal());
      updateInfo(dispatch);
      if (opt && opt.success) dispatch(opt.success());
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error, transferOnWalletToCrypto, data, opt));
      dispatch({type: TRANSFER_ONWALLET_TO_CRYPTO_FAILED});
      dispatch(closeRightModal());
      updateInfo(dispatch);
      if (opt && opt.error) dispatch(opt.error());
    });
};

export const transferCryptoToOnWallet = (data, opt) => (dispatch, getState) => {
  const tokenData = getTokenData(getState());
  if (!tokenData) return;
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };
  dispatch({type: TRANSFER_CRYPTO_TO_ONWALLET_PENDING});
  return cryptoAPITransferCryptoToOnWallet(data, option)
    .then(() => {
      dispatch({type: TRANSFER_CRYPTO_TO_ONWALLET_SUCCESS});
      dispatch(closeRightModal());
      updateInfo(dispatch);
      if (opt && opt.success) dispatch(opt.success());
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error, transferCryptoToOnWallet, data, opt));
      dispatch({type: TRANSFER_CRYPTO_TO_ONWALLET_FAILED});
      dispatch(closeRightModal());
      updateInfo(dispatch);
      if (opt && opt.error) dispatch(opt.error());
    });
};

export const deleteWallet = (address, currency) => (dispatch, getState) => {
  const tokenData = getTokenData(getState());
  if (!tokenData) return;
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };
  dispatch({type: CRYPTO_WALLET_DELETE_PENDING});
  cryptoAPIWalletDelete(currency, option)
    .then(() => {
      dispatch(closeRightModal());
      dispatch(fetchAllAvailableWallets());
      dispatch({type: CRYPTO_WALLET_DELETE_SUCCESS, payload: address});
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error, deleteWallet, address, currency));
      dispatch({type: CRYPTO_WALLET_DELETE_FAILED});
    });
};
