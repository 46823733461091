import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import "./index.scss";
import {selectUserProfile} from "shared/auth/selectors";
import * as Yup from "yup";
import {Field, Form, Formik, useFormikContext} from "formik";
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import messages from "../../messages";
import {FormattedMessage} from "react-intl";
import Button from "shared/components/Button";
import {closeRightModal} from "shared/components/RightModal/actions";
import {
  countries,
  getCountryByCode,
  getDialCodeByCountry,
  getDialCodes,
} from "shared/commonDataService/countries";
import PhoneNumberInput from "shared/components/PhoneNumberInput";
import CodeInput from "shared/components/CodeInput";
import Select from "shared/components/Select";
import {updateUserProfile} from "shared/auth/actions";

const initialValues = {
  country: "US",
  dialCode: "",
  phoneCode: "",
  phoneNumber: "",
};

const ChangePhoneSchema = Yup.object().shape({
  phoneNumber: Yup.string().required("Phone number is required"),
  phoneCode: Yup.string().required("Code from SMS is required"),
  country: Yup.string().required("Country is required"),
});

const ChangePhoneForm = ({userProfile, closeRightModal, updateUserProfile}) => {
  const [send, setSend] = useState(false);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ChangePhoneSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, {resetForm}) => {
        updateUserProfile({phone: `${values.dialCode}${values.phoneNumber}`});
        closeRightModal();
        resetForm();
      }}
    >
      {({errors, touched, values, setFieldValue}) => {
        return (
          <Form className="ChangeDetailsForm">
            <DetectCountry country={userProfile.country || ""} />
            <DetectDialCode country={values.country} />
            <div className="currentName">
              <div className="nameLabel">
                <FormattedMessage {...messages.currentLegalPhone} />:
              </div>
              <div className="name">
                {(userProfile && userProfile.phone) || "Unknown phone"}
              </div>
            </div>
            <FormSectionSeparator sectionName={messages.newPhone} />
            <Field
              name="country"
              render={({field}) => (
                <div className="inputContainer marginTop">
                  <label className="label">
                    <FormattedMessage {...messages.country} />
                  </label>
                  <Select
                    disabled={send}
                    {...field}
                    options={countries.map(country => country.name)}
                    placeholder="Country of your residence"
                  />
                </div>
              )}
            />
            <Field
              name="phoneNumber"
              render={({field}) => (
                <div className="inputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.phoneNumber} />
                  </label>
                  <PhoneNumberInput
                    disabled={send}
                    error={touched.phoneNumber && errors.phoneNumber}
                    {...field}
                    dialValue={values.dialCode}
                    dialOptions={getDialCodes()}
                    dialOnChange={e => setFieldValue("dialCode", e.target.value)}
                  />
                </div>
              )}
            />
            <div className="smsInfo">
              {!send && <FormattedMessage {...messages.weWillSendOnThisNumberSMS} />}
              {send && <FormattedMessage {...messages.weSentOnThisPhoneSMS} />}
            </div>
            {send && (
              <div className="codeInputWrapper">
                <CodeInput
                  error={touched.phoneCode && errors.phoneCode}
                  onChange={value => setFieldValue("phoneCode", value)}
                />
              </div>
            )}
            <div className="buttonsContainer">
              <Button
                type="button"
                text={messages.cancel}
                isSecondary="true"
                onClick={() => {
                  closeRightModal();
                }}
              />
              {!send && (
                <Button
                  type="button"
                  className="nextButton"
                  onClick={() => {
                    if (values.phoneNumber) {
                      setSend(true);
                    }
                  }}
                  text={messages.sendCode}
                />
              )}
              {send && (
                <Button
                  disabled={!values.phoneCode}
                  type="sumbit"
                  text={messages.applyToChange}
                />
              )}
            </div>
            {send && (
              <div className="issueInfo">
                <div className="text">
                  <FormattedMessage {...messages.iHaveIssue} />
                </div>
                <div className="button">
                  <FormattedMessage {...messages.sendNewCode} />
                </div>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

const DetectCountry = ({country}) => {
  const {setFieldValue} = useFormikContext();
  useEffect(() => {
    const localCountry = getCountryByCode(country || "US");
    setFieldValue("country", localCountry ? localCountry.name : "United States");
  }, [setFieldValue, country]);
  return null;
};

const DetectDialCode = ({country}) => {
  const {setFieldValue} = useFormikContext();
  useEffect(() => {
    setFieldValue("dialCode", getDialCodeByCountry(country || "US"));
  }, [setFieldValue, country]);
  return null;
};

const mapStateToProps = store => ({
  userProfile: selectUserProfile(store),
});

const mapDispatchToProps = {
  closeRightModal,
  updateUserProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePhoneForm);
