import React from "react";
import {connect} from "react-redux";
import {
  LineChart,
  Line,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  // ReferenceLine,
} from "recharts";
// Selectors
import {getLoading} from "shared/ratesService/selectors";
// Components
import Loader from "shared/components/Loader";
// Styles
import "./index.scss";

// TODO: Delete after integration with back-end
import weekly from "components/Dashboard/Components/Chart/mockData/1week.js";

const CustomTooltip = ({active, payload, children}) => {
  if (active && !!payload) {
    return (
      <div className="custom-chart-tooltip">
        <span className="custom-chart-tooltip__date">{payload[0].payload.name}</span>
        <span className="custom-chart-tooltip__value">{`Total Master Account ${payload[0].value}`}</span>
        <span className="custom-chart-tooltip__value">{`Own OnWallet Funds ${payload[0].payload.secondValue}`}</span>
        {children}
      </div>
    );
  }

  return null;
};

const Chart = ({chartData}) => {
  return (
    <ResponsiveContainer width="99%">
      <LineChart data={chartData} margin={{top: 17, right: 0, bottom: 0, left: -20}}>
        <XAxis dataKey="name" tick={{fontSize: 11}} />
        <YAxis axisLine={false} tickLine={false} tick={{fontSize: 11}} />
        <CartesianGrid vertical={false} />
        <Tooltip content={<CustomTooltip />} />
        <Line
          isAnimationActive={false}
          type="monotone"
          dataKey="value"
          stroke="#0080FF"
          strokeWidth="2"
          activeDot={{stroke: "#0080FF", strokeWidth: 2, r: 5, fill: "#FFF"}}
          dot={false}
        />
        <Line
          isAnimationActive={false}
          type="monotone"
          dataKey="secondValue"
          stroke="#FF9500"
          strokeWidth="2"
          activeDot={{stroke: "#FF9500", strokeWidth: 2, r: 5, fill: "#FFF"}}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const ChartComposition = ({isLoading}) => {
  return (
    <div className="admin-chart-wrapper">
      <div className="admin-chart-wrapper__chart">
        {!isLoading ? <Chart chartData={weekly} /> : <Loader />}
      </div>
    </div>
  );
};

const mapStateToProps = store => ({
  isLoading: getLoading(store),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ChartComposition);
