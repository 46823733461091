import React, {Component} from "react";
import {connect} from "react-redux";
import Input from "shared/components/Input";
import Button from "shared/components/Button";
import Select from "shared/components/Select";
import PhoneNumberInput from "shared/components/PhoneNumberInput";
import {FormattedMessage} from "react-intl";
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import {Formik, Field, FieldArray} from "formik";
import {getDialCodes} from "shared/commonDataService/countries";
import {addContact} from "shared/contacts/actions";
import {getAllCryptoCurrency} from "shared/walletService/selectors";
import {ContactDetailsSchema} from "../validation";

import "../index.scss";
import messages from "../../../messages";
import config from "shared/config";

const deleteIcon = config.prefixImgUrl + "delete-icon.svg";

class ContactAddModal extends Component {
  constructor() {
    super();
    this.state = {
      isShowCompanyName: true,
      isShowPhoneNumber: true,
      isShowAddress: true,
      isShowEmail: true,
      dialCode: "+380",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = data => {
    // Get data from formik
    const {
      firstName,
      lastName,
      address,
      onWalletAccount,
      email,
      companyName,
      phoneNumber,
      cryptoWallets,
      bankAccounts,
      cards,
    } = data;

    // Generate object for send
    const payload = {
      firstName,
      lastName,
      address,
      onWalletAccount,
      companyName,
      bankAccounts,
      cards,
      cryptoWallets: cryptoWallets.map(item => {
        item.currency = item.currency.toLowerCase();
        return item;
      }),
      email: email ? email : null,
      phone: phoneNumber ? `${this.state.dialCode}${phoneNumber}` : null,
    };

    // Request to server
    this.props.addContact(payload);
    this.props.close();
  };

  render() {
    const initStateFormik = {
      bankAccounts: [],
      cards: [],
      address: "",
      email: null,
      companyName: "",
      firstName: "",
      lastName: "",
      onWalletAccount: "",
      phoneNumber: "",
      cryptoWallets: [],
      allCurrency: [],
    };

    return (
      <Formik
        initialValues={initStateFormik}
        validateOnChange={true}
        validationSchema={ContactDetailsSchema}
        onSubmit={values => this.handleSubmit(values)}
      >
        {({errors, touched, values, handleSubmit, isSubmitting}) => (
          <form onSubmit={handleSubmit}>
            <div className="contact-add-container">
              <FormSectionSeparator sectionName={messages.general} />

              {/* Section First and Last name */}

              <div className="input-wrapper">
                <Field name="firstName">
                  {({field}) => (
                    <div className="input-wrapper__item">
                      <div className="label">
                        <FormattedMessage {...messages.firstName} />
                      </div>
                      <div className="linkInput">
                        <Input
                          type="text"
                          error={touched.firstName && errors.firstName}
                          value={values.firstName}
                          name="firstName"
                          {...field}
                        />
                      </div>
                    </div>
                  )}
                </Field>
                <Field name="lastName">
                  {({field}) => (
                    <div className="input-wrapper__item">
                      <div className="label">
                        <FormattedMessage {...messages.lastName} />
                      </div>
                      <div className="linkInput">
                        <Input
                          type="text"
                          error={touched.lastName && errors.lastName}
                          value={values.lastName}
                          name="lastName"
                          {...field}
                        />
                      </div>
                    </div>
                  )}
                </Field>
              </div>

              {/* Section Email */}

              <div className="additional">
                <div
                  className="additional__item"
                  hidden={!this.state.isShowEmail}
                  onClick={() => this.setState({isShowEmail: !this.state.isShowEmail})}
                >
                  Add Email
                </div>

                <Field name="email">
                  {({field}) => (
                    <div hidden={this.state.isShowEmail}>
                      <div className="label">
                        <FormattedMessage {...messages.email} />
                      </div>
                      <div className="input-wrapper__item mb-5 additional__items">
                        <div className="additional__items__input">
                          <Input
                            type="text"
                            value={values.email}
                            error={touched.email && errors.email}
                            name="email"
                            {...field}
                          />
                        </div>
                        <div
                          className="additional__items--delete-icon"
                          onClick={() => {
                            values.email = "";
                            this.setState({isShowEmail: !this.state.isShowEmail});
                          }}
                        >
                          <img src={deleteIcon} alt="delete icon" />
                        </div>
                      </div>
                    </div>
                  )}
                </Field>

                {/* Section Company Name */}

                <div
                  className="additional__item"
                  hidden={!this.state.isShowCompanyName}
                  onClick={() =>
                    this.setState({isShowCompanyName: !this.state.isShowCompanyName})
                  }
                >
                  Add Company Name
                </div>

                <Field name="companyName">
                  {({field}) => (
                    <div hidden={this.state.isShowCompanyName}>
                      <div className="label">
                        <FormattedMessage {...messages.companyName} />
                      </div>
                      <div className="input-wrapper__item mb-5 additional__items">
                        <div className="additional__items__input">
                          <Input
                            type="text"
                            value={values.companyName}
                            error={touched.companyName && errors.companyName}
                            name="companyName"
                            {...field}
                          />
                        </div>
                        <div
                          className="additional__items--delete-icon"
                          onClick={() => {
                            values.companyName = "";
                            this.setState({
                              isShowCompanyName: !this.state.isShowCompanyName,
                            });
                          }}
                        >
                          <img src={deleteIcon} alt="delete icon" />
                        </div>
                      </div>
                    </div>
                  )}
                </Field>

                {/* Section Phone */}

                <div
                  className="additional__item"
                  hidden={!this.state.isShowPhoneNumber}
                  onClick={() =>
                    this.setState({isShowPhoneNumber: !this.state.isShowPhoneNumber})
                  }
                >
                  Add Phone Number
                </div>

                <Field name="phoneNumber">
                  {({field}) => (
                    <div hidden={this.state.isShowPhoneNumber} className="mb-5">
                      <label className="label">
                        <FormattedMessage {...messages.phoneNumber} />
                      </label>
                      <div className="input-wrapper__item mb-5 additional__items">
                        <div className="additional__items__input">
                          <PhoneNumberInput
                            name="phoneNumber"
                            value={values.phoneNumber}
                            error={touched.phoneNumber && errors.phoneNumber}
                            dialValue={this.state.dialCode}
                            dialOptions={getDialCodes()}
                            dialOnChange={e => this.setState({dialCode: e.target.value})}
                            {...field}
                          />
                        </div>
                        <div
                          className="additional__items--delete-icon"
                          onClick={() => {
                            values.phoneNumber = "";
                            this.setState({
                              isShowPhoneNumber: !this.state.isShowPhoneNumber,
                            });
                          }}
                        >
                          <img src={deleteIcon} alt="delete icon" />
                        </div>
                      </div>
                    </div>
                  )}
                </Field>

                <div
                  className="additional__item"
                  hidden={!this.state.isShowAddress}
                  onClick={() =>
                    this.setState({isShowAddress: !this.state.isShowAddress})
                  }
                >
                  Add Address
                </div>

                {/* Section Address */}

                <Field name="address">
                  {({field}) => (
                    <div hidden={this.state.isShowAddress}>
                      <div className="label">
                        <FormattedMessage {...messages.address} />
                      </div>
                      <div className="input-wrapper__item mb-5 additional__items">
                        <div className="additional__items__input">
                          <Input
                            type="text"
                            value={values.address}
                            error={touched.address && errors.address}
                            name="address"
                            {...field}
                          />
                        </div>
                        <div
                          className="additional__items--delete-icon"
                          onClick={() => {
                            this.setState({isShowAddress: !this.state.isShowAddress});
                            values.address = "";
                          }}
                        >
                          <img src={deleteIcon} alt="delete icon" />
                        </div>
                      </div>
                    </div>
                  )}
                </Field>
              </div>

              <FormSectionSeparator sectionName={messages.finance} />

              {/* Section onWallet Account */}

              <Field name="onWalletAccount">
                {({field}) => (
                  <div className="finance">
                    <div className="input-wrapper__item">
                      <div className="label">
                        <FormattedMessage {...messages.onWalletAccount} />
                      </div>
                      <div>
                        <Input
                          type="text"
                          value={values.onWalletAccount}
                          error={touched.onWalletAccount && errors.onWalletAccount}
                          name="onWalletAccount"
                          {...field}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Field>

              {/* Section Crypto Wallets */}

              <div className="additional">
                <FieldArray name="cryptoWallets">
                  {fieldArrayProps => {
                    const {push, remove} = fieldArrayProps;
                    const {cryptoWallets} = values;
                    return (
                      <div>
                        {cryptoWallets.map((crypto, index) => (
                          <div key={index} className="walletAddressCurrency">
                            <Field name={`cryptoWallets[${index}].account`}>
                              {({field}) => (
                                <div className="walletAddressCurrency__wallet">
                                  <label className="label">
                                    <FormattedMessage {...messages.walletAddress} />
                                  </label>
                                  <Input
                                    type="text"
                                    key={index}
                                    error={
                                      touched &&
                                      touched.cryptoWallets &&
                                      touched.cryptoWallets[index] &&
                                      touched.cryptoWallets[index].account &&
                                      errors &&
                                      errors.cryptoWallets &&
                                      errors.cryptoWallets[index] &&
                                      errors.cryptoWallets[index].account
                                    }
                                    value={crypto.account}
                                    {...field}
                                  />
                                </div>
                              )}
                            </Field>
                            <Field name={`cryptoWallets[${index}].currency`}>
                              {({field}) => (
                                <div className="walletAddressCurrency__currency">
                                  <label className="label">
                                    <FormattedMessage {...messages.currency} />
                                  </label>
                                  <Select
                                    className="select"
                                    value={crypto.currency}
                                    options={this.props.allCurrency.map(currency =>
                                      currency.toUpperCase()
                                    )}
                                    {...field}
                                  />
                                </div>
                              )}
                            </Field>
                            <div
                              className="walletAddressCurrency__delete"
                              onClick={() => remove()}
                            >
                              <img src={deleteIcon} alt="delete icon" />
                            </div>
                          </div>
                        ))}
                        <div
                          className="additional__item"
                          onClick={() => push({account: "", currency: ""})}
                        >
                          Add Crypto Wallet
                        </div>
                      </div>
                    );
                  }}
                </FieldArray>

                {/* Section Cards */}

                <FieldArray name="cards">
                  {fieldArrayProps => {
                    const {push, remove} = fieldArrayProps;
                    const {cards} = values;
                    return (
                      <div>
                        {cards.map((card, index) => (
                          <div key={index}>
                            <Field name={`cards[${index}]`}>
                              {({field}) => (
                                <div>
                                  <div className="label">
                                    <FormattedMessage {...messages.bankCardNumber} />
                                  </div>
                                  <div className="input-wrapper__item mb-5 additional__items">
                                    <div className="additional__items__input">
                                      <Input
                                        type="text"
                                        key={index}
                                        value={card}
                                        error={
                                          touched.cards &&
                                          touched.cards[index] &&
                                          errors.cards &&
                                          errors.cards[index]
                                        }
                                        {...field}
                                      />
                                    </div>
                                    <div
                                      className="additional__items--delete-icon"
                                      onClick={() => remove(index)}
                                    >
                                      <img src={deleteIcon} alt="delete icon" />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Field>
                          </div>
                        ))}
                        <div className="additional__item" onClick={() => push("")}>
                          Add Card
                        </div>
                      </div>
                    );
                  }}
                </FieldArray>

                {/* Section Bank Account (IBAN) */}

                <FieldArray name="bankAccounts">
                  {fieldArrayProps => {
                    const {push, remove} = fieldArrayProps;
                    const {bankAccounts} = values;
                    return (
                      <div>
                        {bankAccounts.map((bank, index) => (
                          <div key={index}>
                            <Field name={`bankAccounts[${index}]`}>
                              {({field}) => (
                                <div>
                                  <div className="label">
                                    <FormattedMessage {...messages.bankAccountIBAN} />
                                  </div>
                                  <div className="input-wrapper__item mb-5 additional__items">
                                    <div className="additional__items__input">
                                      <Input
                                        type="text"
                                        key={index}
                                        value={bankAccounts[index]}
                                        error={
                                          bankAccounts[index] &&
                                          errors &&
                                          errors.bankAccounts
                                        }
                                        {...field}
                                      />
                                    </div>
                                    <div
                                      className="additional__items--delete-icon"
                                      onClick={() => remove(index)}
                                    >
                                      <img src={deleteIcon} alt="delete icon" />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Field>
                          </div>
                        ))}
                        <div className="additional__item" onClick={() => push("")}>
                          Add bank Account
                        </div>
                      </div>
                    );
                  }}
                </FieldArray>
              </div>

              <div className="line"></div>

              {/* Action buttons */}

              <div className="contact-add-container--actions">
                <Button
                  type="button"
                  text={messages.cancel}
                  isSecondary="true"
                  onClick={this.props.close}
                />
                <Button type="submit" text={messages.save} disabled={isSubmitting} />
              </div>
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = store => ({
  allCurrency: getAllCryptoCurrency(store),
});

const mapDispatchToProps = dispatch => {
  return {
    addContact: params => {
      dispatch(addContact(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactAddModal);
