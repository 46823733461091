import React from "react";
import {withRouter} from "react-router-dom";

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      setTimeout(function() {
        let _obj = document.getElementsByClassName("LayoutContent")[0];
        if (_obj) _obj.scrollTo(0, 0);
      }, 300);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(ScrollToTop);
