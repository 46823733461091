import React from "react";
import {connect} from "react-redux";
// Components
import RightModal from "shared/components/RightModal";
import NewCustomerModal from "./NewCustomerModal";
import NewTransferCustomerModal from "./NewTransferCustomerModal";
import ChangeStatusCustomerModal from "./ChangeStatusCustomerModal";
import TrustedDevicesCustomerModal from "./TrustedDevicesCustomerModal";
import ChangeNameCustomerModal from "./ChangeNameCustomerModal";
import ChangeAddressCustomerModal from "./ChangeAddressCustomerModal";
import ChangeFinancialAccountStatusModal from "./ChangeFinancialAccountStatusModal";
import ExchangeModal from "components/WalletsPage/components/ExchangeModal";
import ReceiveToOwnOnWallet from "components/WalletsPage/components/RecieveModal/ReceveToOwnOnWallet.jsx";

// Types modals
const modalVariants = {
  newCustomer: "newCustomer",
  changeStatus: "changeStatus",
  trustedDevices: "trustedDevices",
  changeName: "changeName",
  changeAddress: "changeAddress",
  changeFinancialAccountStatus: "changeFinancialAccountStatus",
  exchange: "exchange",
  invoice: "invoice",
  transfer: "transfer",
};

const CustomersDetailsModals = ({modalData}) => {
  if (modalData === null) {
    modalData = {
      title: "none",
      name: "none",
      sub: "none",
    };
  }

  return (
    <>
      {modalData.title && (
        <RightModal
          title={modalData && modalData.title ? modalData.title : null}
          subTitle={modalData && modalData.subTitle ? modalData.subTitle : null}
          image={modalData && modalData.image ? modalData.image : null}
        >
          {/* Define of the child by type */}
          <div>
            {modalData.name === modalVariants.newCustomer && <NewCustomerModal />}
            {modalData.name === modalVariants.transfer && <NewTransferCustomerModal />}
            {modalData.name === modalVariants.changeStatus && (
              <ChangeStatusCustomerModal />
            )}
            {modalData.name === modalVariants.changeFinancialAccountStatus && (
              <ChangeFinancialAccountStatusModal />
            )}
            {modalData.name === modalVariants.trustedDevices && (
              <TrustedDevicesCustomerModal />
            )}
            {modalData.name === modalVariants.changeName && <ChangeNameCustomerModal />}
            {modalData.name === modalVariants.exchange && <ExchangeModal />}
            {modalData.name === modalVariants.invoice && <ReceiveToOwnOnWallet />}
            {modalData.name === modalVariants.changeAddress && (
              <ChangeAddressCustomerModal />
            )}
          </div>
        </RightModal>
      )}
    </>
  );
};

const mapStateToProps = store => ({
  modalData: store.rightModalData.variant,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomersDetailsModals);
