import {
  FETCH_BANK_CARDS_FAILED,
  FETCH_BANK_CARDS_PENDING,
  FETCH_BANK_CARDS_SUCCESS,
  GET_ONWALLET_FAILED,
  GET_ONWALLET_PENDING,
  GET_ONWALLET_SUCCESS,
  SET_ACTIVE_BANK_CARD,
  GET_REGULAR_CURRENCY_FAILED,
  GET_REGULAR_CURRENCY_SUCCESS,
  RECEIVE_CARD_QR_FAILED,
  RECEIVE_CARD_QR_PENDING,
  RECEIVE_CARD_QR_SUCCESS,
  RESET_RECEIVE_CARD_QR,
  TRANSFER_CARD_TO_CARD_FAILED,
  TRANSFER_CARD_TO_CARD_PENDING,
  TRANSFER_CARD_TO_CARD_SUCCESS,
  FETCH_BANK_ACCOUNT_PENDING,
  FETCH_BANK_ACCOUNT_SUCCESS,
  FETCH_BANK_ACCOUNT_FAILED,
  SET_ACTIVE_BANK_ACCOUNT,
  SET_CARD_DEFAULT_PENDING,
  SET_CARD_DEFAULT_SUCCESS,
  SET_CARD_DEFAULT_FAILED,
  UPDATE_CARD_DEFAULT_SUCCESS,
  DELETE_CARD_DEFAULT_SUCCESS,
} from "./actions/types";

const initBankCardsState = {
  activeBankCardNumber: null,
  isLoading: false,
  regularCards: [],
  onwalletCard: null,
  regularCurrencies: [],
  isRegularCurrenciesFetched: false,
  isDefaultCardLoading: false,
};

const bankCardsData = (state = initBankCardsState, action) => {
  switch (action.type) {
    case FETCH_BANK_CARDS_PENDING:
      return {...state, isLoading: true};
    case FETCH_BANK_CARDS_SUCCESS:
      return {
        ...state,
        regularCards: action.payload,
        activeBankCardNumber: state.activeBankCardNumber
          ? state.activeBankCardNumber
          : !!action.payload[0]
          ? action.payload[0]["last4"]
          : null,
        isLoading: false,
      };
    case FETCH_BANK_CARDS_FAILED:
      return {...state, isLoading: false};
    case SET_ACTIVE_BANK_CARD:
      return {...state, activeBankCardNumber: action.payload};
    case GET_ONWALLET_PENDING:
      return {...state, isLoading: true};
    case GET_ONWALLET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        onwalletCard: action.payload,
        activeBankCardNumber: "OnWallet",
      };
    case GET_ONWALLET_FAILED:
      return {...state, isLoading: false};
    case GET_REGULAR_CURRENCY_SUCCESS:
      return {
        ...state,
        regularCurrencies: action.payload,
        isRegularCurrenciesFetched: true,
      };
    case GET_REGULAR_CURRENCY_FAILED:
      return {...state, regularCurrencies: [], isRegularCurrenciesFetched: true};
    case SET_CARD_DEFAULT_PENDING:
      return {...state, isDefaultCardLoading: true};
    case SET_CARD_DEFAULT_SUCCESS:
      return {
        ...state,
        isDefaultCardLoading: false,
        regularCards: state.regularCards.map(card => {
          card.isDefault = card.id === action.payload;
          return card;
        }),
      };
    case SET_CARD_DEFAULT_FAILED:
      return {...state, isDefaultCardLoading: false};
    case UPDATE_CARD_DEFAULT_SUCCESS:
      return {
        ...state,
        regularCards: state.regularCards.map(card => {
          if (action.payload.id === card.id) {
            card = {...card, ...action.payload.data};
          }
          return card;
        }),
      };
    case DELETE_CARD_DEFAULT_SUCCESS:
      return {
        ...state,
        regularCards: state.regularCards.filter(card => card.id !== action.payload),
      };
    default:
      return state;
  }
};

const initState = {
  isLoading: false,
  qrData: null,
};

const receiveCardData = (state = initState, action) => {
  switch (action.type) {
    case RESET_RECEIVE_CARD_QR:
      return {...state, isLoading: false, qrData: null};
    case RECEIVE_CARD_QR_PENDING:
      return {...state, isLoading: true};
    case RECEIVE_CARD_QR_SUCCESS:
      return {...state, isLoading: false, qrData: action.payload};
    case RECEIVE_CARD_QR_FAILED:
      return {...state, isLoading: false, qrData: null};
    default:
      return state;
  }
};

const initTransferState = {
  isLoading: false,
};

const transferCardData = (state = initTransferState, action) => {
  switch (action.type) {
    case TRANSFER_CARD_TO_CARD_PENDING:
      return {...state, isLoading: true};
    case TRANSFER_CARD_TO_CARD_SUCCESS:
      return {...state, isLoading: false};
    case TRANSFER_CARD_TO_CARD_FAILED:
      return {...state, isLoading: false};
    default:
      return state;
  }
};

const initBankAccountsState = {
  isLoading: false,
  bankAccounts: [],
  activeBankAccountNumber: null,
};

const bankAccountData = (state = initBankAccountsState, action) => {
  switch (action.type) {
    case FETCH_BANK_ACCOUNT_PENDING:
      return {...state, isLoading: true};
    case FETCH_BANK_ACCOUNT_SUCCESS:
      return {...state, isLoading: false, bankAccounts: action.payload};
    case FETCH_BANK_ACCOUNT_FAILED:
      return {...state, isLoading: false, bankAccounts: []};
    case SET_ACTIVE_BANK_ACCOUNT:
      return {...state, activeBankAccountNumber: action.payload};
    default:
      return state;
  }
};

export {bankCardsData, receiveCardData, transferCardData, bankAccountData};
