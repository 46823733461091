import React from "react";
import "./index.scss";

const FileInputText = ({className, error, children, ...props}) => {
  return (
    <div className="FileInputWrapper">
      <div className={`FileInputText ${className}`}>
        <input type="file" className="fileInput" {...props} />
        <div className="text">{children}</div>
      </div>{" "}
      {error && <div className="fileInputError">{error}</div>}
    </div>
  );
};

export default FileInputText;
