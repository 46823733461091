/**
 * @author: Seleznyov
 */

import React, {Component} from "react";
import {BUY, SELL} from "../../../../shared/tradeService/actions/types";

class TradeHistoryItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      price: 0,
      amount: 0,
      time: 0,
      type: 0,
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  /**
   *
   */
  prepareData() {
    const {item} = this.props;

    this.setState({
      price: this.formatPrice(item.price),
      amount: this.formatAmount(item.completedCryptoAmount),
      time: this.formatTime(item.date),
      type: item.type === SELL ? "item-sale" : item.type === BUY ? "item-buy" : "",
    });
  }

  /**
   *
   * @param time
   * @returns {string}
   */
  formatTime(time) {
    let _time = new Date(time);
    return _time.toLocaleTimeString();
  }

  /**
   *
   * @param price
   * @returns {number}
   */
  formatPrice(price) {
    return this.formatNumber((Math.round(price * 100) / 100).toFixed(2));
  }

  /**
   *
   * @param amount
   * @returns {number}
   */
  formatAmount(amount) {
    let _val = (Math.round(amount * 10) / 10).toFixed(1);
    return this.formatNumber(_val);
  }

  /**
   *
   * @param value
   * @returns {string|number}
   */
  formatNumber(value) {
    if (value > 0) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return 0;
  }

  render() {
    return (
      <div className={"item-row " + this.state.type}>
        <div className="item-value price">{this.state.price}</div>
        <div className="item-value amount">{this.state.amount}</div>
        <div className="item-value time">{this.state.time}</div>
      </div>
    );
  }
}

export default TradeHistoryItem;
