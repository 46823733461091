import React, {useState} from "react";
import {connect} from "react-redux";
import {contactList} from "shared/contacts/selectors";
import ModalContactList from "shared/components/AddressBook/listContacts";
import InputBase from "@material-ui/core/InputBase";
import InputAdornment from "@material-ui/core/InputAdornment";
import {withStyles} from "@material-ui/core/styles";
import "./index.scss";

const StyledInput = withStyles({
  root: {
    borderRadius: 6,
    position: "relative",
    border: "1px solid #a6d3ff",
    backgroundColor: "#f2f8fc",
    color: "#1f333d",
    lineHeight: 19,
    fontSize: 16,
    width: "100%",
    height: 44,
    padding: "12px",

    "&:focus": {
      border: "1px solid #0080ff",
    },

    "&:hover": {
      border: "1px solid #0080ff80",
    },
  },
  input: {},
})(InputBase);

const InputUniversal = ({
  options,
  className,
  error,
  children,
  suffix,
  leftIcon,
  rightIcon,
  iconStyles,
  iconRightStyles,
  typeContact,
  handlerChange,
  enterData,
  setEnterData,
  contactList,
  ...props
}) => {
  const [isShowContactList, setIsShowContactList] = useState(false);
  const [customerName, setCustomerName] = useState("John Smith");

  const handleSetSelect = item => {
    switch (typeContact) {
      case "email":
        setEnterData(item.info.email);
        setCustomerName(`${item.info.firstName} ${item.info.lastName}`);
        break;
      case "phone":
        setEnterData(item.info.phone);
        setCustomerName(`${item.info.firstName} ${item.info.lastName}`);
        break;
      case "onWalletAccount":
        setEnterData(item.info.onWalletAccount);
        setCustomerName(`${item.info.firstName} ${item.info.lastName}`);
        break;
      default:
        break;
    }
  };

  const handlerFindCustomer = value => {
    const customer = contactList.find(
      contact =>
        contact.info.onWalletAccount === value ||
        contact.info.email === value ||
        contact.info.phone === value
    );

    if (customer) {
      setCustomerName(`${customer.info.firstName} ${customer.info.lastName}`);
    } else {
      setCustomerName("New");
    }
  };

  return (
    <div>
      {/* Contact list */}
      {typeContact && (
        <div className="contact-listinput-wrapper">
          <div className="wrapper">
            <div className="wrapper__row">
              <div
                className={`wrapper__list-contacts ${
                  isShowContactList ? "visible position-contact-list" : ""
                }`}
              >
                <ModalContactList
                  type={typeContact}
                  isShowSearch={true}
                  handleSetSelect={handleSetSelect}
                  toggleShowContacts={() => setIsShowContactList(!isShowContactList)}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Input */}
      <div className="InputUniversal">
        <div className="InputUniversal__item">
          <StyledInput
            type="text"
            className={`input ${error ? "errorInput" : ""} ${className ? className : ""}`}
            startAdornment={
              <InputAdornment position="start">
                <img
                  style={{width: "20px"}}
                  className={`icon ${iconStyles}`}
                  src={leftIcon}
                  alt="icon"
                />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">{customerName || suffix}</InputAdornment>
            }
            {...props}
            value={enterData}
            onChange={e => {
              setEnterData(e.target.value);
              handlerFindCustomer(e.target.value);
            }}
          />
          {rightIcon && (
            <img
              src={rightIcon}
              className={`rightIcon ${iconRightStyles}`}
              alt="icon"
              onClick={() => setIsShowContactList(!isShowContactList)}
            />
          )}
        </div>
        {error && <div className="inputErrorText">{error}</div>}
      </div>
    </div>
  );
};

const mapStateToProps = store => ({
  contactList: contactList(store),
});

export default connect(mapStateToProps, null)(InputUniversal);
