export const USE_AGREEMENT = [
  {
    id: "a.",
    text:
      "You are required to create an account for yourself and on completion of your KYC; your account will become active on Onwallet. By creating an account with us, you ",
  },
  {
    id: "b.",
    text:
      "You can create only one account on one device using two step authentications. On successful creation of your account Onwallet shall send a One Time Password to you and Onwallet shall provide a unique Merchant Identification Number to you. ",
  },
  {
    id: "c.",
    text:
      "You accept and acknowledge to indemnify Onwallet against and in the event of any misuse, misconduct or illegal activity by you or your affiliates, agents and partners.",
  },
  {
    id: "d.",
    text:
      "Onwallet mobile application and website is the sole and exclusive property of Onwallet and you shall abide by all the terms and conditions of this Agreement.",
  },
  {
    id: "e.",
    text:
      "You agree and acknowledge that Onwallet reserves all the rights to terminate or revoke your account at any time without any prior notification to you in the events of any misuse, misconduct, fraudulent, illegal or criminal activity if found by Onwallet or its employees, affiliates or agents. ",
  },
  {
    id: "f.",
    text:
      "You shall not assign, transfer, sub-license any of the rights, duties, and obligations either in whole or in part of this agreement to any person, entity or organisation without the consent or permission of Onwallet. ",
  },
  {
    id: "g.",
    text:
      "This Agreement shall not establish any partnership or joint venture or principal agent relationship between the Merchant and Onwallet. No party has the right to bind the other with any agreement. ",
  },
  {
    id: "h.",
    text:
      "All the notices, proposals and approvals shall be in writing and shall be deemed to have been duly given receipt as if they are delivered personally or by any registered post or any electronic mail during the working days to the Merchant. ",
  },
];

export const LEGAL_PERMISSIONS = [
  {
    id: "a.",
    text:
      "You acknowledge and agree that Onwallet is just an intermediary/facilitator between you and your customers. All the required documentation, trade licenses along with obtaining any permissions or licenses under State or Central or local governmental authorities is the sole responsibility of Merchants.",
  },
  {
    id: "b.",
    text:
      "Onwallet shall not assume any responsibility to obtain any licenses or permissions on behalf of you. Onwallet shall be fully absolved of any non-compliance of the same.",
  },
];

export const MERCHANTS_ACCOUNT = [
  {
    id: "a.",
    text:
      "Upon creating an account with Onwallet you are required to pay certain registration amount as mentioned in the Merchant Application Form. The Registration fee paid by you is non-refundable once the amount is transferred to Onwallet.",
  },
  {
    id: "b.",
    text:
      "Upon successful completion of payment by you, Onwallet will issue a Merchant Code which can be used by you.  ",
  },
  {
    id: "c.",
    text:
      "In the event of transfer of money from your customers, you accept and acknowledge the same shall be transferred to you upon payment of certain percentage to Onwallet. Onwallet shall deduct the applicable charges and remit the balance amount to your account.",
  },
  {
    id: "d.",
    text:
      "The Merchant and Onwallet shall bear their own cost of any obligation arising out of or imposed upon them under any applicable law with respect to any taxes or similar items in connection with this Agreement. ",
  },
];

export const SECURITY_WEBSITE = [
  {
    id: "a.",
    text:
      "modify, copy, or cause damage or unintended effect to any portion of Our Website, or any software used within it.",
  },
  {
    id: "b.",
    text:
      "link to our site in any way that would cause the appearance or presentation of the site to be different from what would be seen by a user who accessed the site by typing the URL into a standard browser;",
  },
  {
    id: "c.",
    text: "download any part of Our Website, without our express written consent;",
  },
  {
    id: "d.",
    text: "collect or use any product or service listings, descriptions, or prices;",
  },
  {
    id: "e.",
    text:
      "collect or use any information obtained from or about Our Website or the Content except as intended by this agreement;",
  },
  {
    id: "f.	",
    text:
      "aggregate, copy or duplicate in any manner any of the Content or information available from Our Website, other than as permitted by this agreement or as is reasonably necessary for your use of the Services;",
  },
  {
    id: "g.",
    text: "share with a third party any login credentials to Our Website;",
    details: [
      {
        id: "i.",
        text:
          "create a hyperlink to Our Website for the purpose of promoting an interest common to both of us. You can do this without specific permission. This licence is conditional upon your not portraying us or any product or service in a false, misleading, derogatory, or otherwise offensive manner. You may not use any logo or other proprietary graphic or trademark of ours as part of the link without our express written consent.",
      },
      {
        id: "ii.",
        text:
          "you may copy the text of any page for your personal use in connection with the purpose of Our Website or a Service we provide.",
      },
    ],
  },
];

export const SERVICES_PROVIDED = [
  {
    id: "a.",
    text:
      "In respect of services we provide we do not warrant that they will be performed with due care and skill. ",
  },
  {
    id: "b.",
    text:
      "Our Website and our Services are provided “as is”. We make no representation or warranty that the Service will be:",
    details: [
      {
        id: "i.",
        text: "useful to you;",
      },
      {
        id: "ii.",
        text: "of satisfactory quality;",
      },
      {
        id: "iii.",
        text: "fit for a particular purpose;",
      },
      {
        id: "iv.",
        text: "available or accessible, without interruption, or without error.",
      },
    ],
  },
];

export const PROMOTIONAL_ACTIVITIES = [
  {
    id: "a.",
    text:
      "You agree and acknowledge that you shall not advertise or use the brand name/trademark details or logo of Onwallet without prior intimation to us.",
  },
  {
    id: "b.",
    text:
      "In the event of advertising or promoting Onwallet, you shall take appropriate permissions from us.",
  },
  {
    id: "c.",
    text:
      "You shall agree and acknowledge that Onwallet reserves the right to inspect your shops/retail outlets at any time without any notification.",
  },
  {
    id: "d.",
    text:
      "You agree and acknowledge that you shall follow the terms and conditions as mentioned in this Merchant Agreement which is subject to amendments or modification by Onwallet from time to time. ",
  },
];

export const PRIVACY = [
  {
    id: "a.",
    text:
      "You shall agree and acknowledge to maintain all the personal information relating to customers and Onwallet with utmost security and confidentiality. As an industry practice, you shall not store any data or information or any authentications (PIN/OTP) relating to Onwallet.",
  },
  {
    id: "b.",
    text:
      "You agree and acknowledge that you shall not store any credentials relating to Onwallet and your customers.",
  },
];

export const REFUND = [
  {
    id: "a.",
    text:
      "Onwallet shall not assume any responsibility or liability to refund or cancel and transactions. You shall agree and acknowledge that you shall be solely responsible for any refund, delivery, cancellation or any chargeback.",
  },
  {
    id: "b.",
    text:
      "In case of any refunds, Onwallet shall initiate the refund process after receiving a request or confirmation from you. Onwallet ensures that it may take 7-10 working days to refund back the amount to the customers Onwallet account.",
  },
  {
    id: "c.",
    text:
      "You shall agree and acknowledge that you shall be solely responsible and liable for any quality/service related issues.",
  },
  {
    id: "d.",
    text:
      "In case of any chargeback or cancellation of any transaction by customers, Onwallet shall terminate the customer’s wallet from the time such claim is reported to Onwallet.",
  },
];

export const INTELLECTUAL = [
  {
    id: "a.",
    text:
      "Both the Merchant and Onwallet shall agree to grant each other non- exclusive, royalty free and limited license to use, advertise, exhibit, reproduce and promote the trademarks, brand name, logo, device, service mark and copyright details only in connection with this Agreement.",
  },
];

export const DISPUTE_RESOLUTION = [
  {
    id: "11.1",
    text:
      "In case of any dispute arising out of any transaction, the Merchant shall produce the invoice to Onwallet as and when the dispute arises, not less than 24 hours from the time of request.",
  },
  {
    id: "11.2",
    text: "In case of any invalid transaction, the same shall be charged back if:",
    details: [
      {
        id: "i.",
        text:
          "Invalid transaction was declined either by the customer or Onwallet for any reason.",
      },
      {
        id: "ii.",
        text: "The transaction is unlawful or unenforceable;",
      },
      {
        id: "iii.",
        text:
          "The transaction has not been processed according to the terms and conditions of this Agreement.",
      },
    ],
  },
  {
    id: "11.3",
    text:
      "In the event that a transaction has been declined/cancelled by Onwallet and the   amount has been deducted from customers account, Onwallet shall refund back the amount to the customers within 7-10 days from the time of deduction/cancellation. Onwallet reserves the right to inform the same to the Merchant and shall indemnify the merchant from such an action instituted by the customer against such deduction.",
  },
  {
    id: "11.4",
    text:
      "In the event of any dispute between the user and the Merchant, Onwallet shall not be made liable, responsible, obligated or bound to resolve the matter nor Onwallet shall be made liable for any actions/causes arising out of such dispute/issue/matter.",
  },
  {
    id: "11.5",
    text:
      "In case of any dispute resolution relating to the rights and obligations arising out of this agreement the merchant shall email regarding the same to the following email address info@onwallet.com. Onwallet tries to resolve the complaint or grievance amicably by and in the event the dispute stands unresolved, both the parties (OnWallet and Merchant) shall mutually agree and consent to appoint a Single Arbitrator for resolving the issue at the International Arbitration Centre, London located at 190 Fleet St, Holborn, London EC4A 2AG, United Kingdom.",
  },
  {
    id: "11.6",
    text:
      "The validity, construction and performance of this agreement shall be governed by English Courts. ",
  },
];

export const REPRESENTATIONS = [
  {
    id: "",
    text: "",
    details: [
      {
        id: "i.",
        text:
          "Abide by all the terms and conditions, policies and applicable laws under this agreement.",
      },
      {
        id: "ii.",
        text:
          "Will not refund, cancel, deliver, chargeback, transfer or accept ant funds or transactions in connection with any illegal, fraudulent, deceptive or criminal activity.",
      },
      {
        id: "iii.",
        text:
          "Provide true, accurate and complete information relating to your business and services to Onwallet.",
      },
      {
        id: "iv.",
        text:
          "Shall maintain all the confidential information provided to you by Onwallet with utmost security and confidentiality.",
      },
    ],
  },
];

export const TERM_AND_TERMINATION = [
  {
    id: "13.1",
    text:
      "This Agreement shall be valid and effective unless terminated by either party.",
  },
  {
    id: "13.2",
    text:
      "Termination: Onwallet reserves the right to terminate/suspend your account in case:",
    details: [
      {
        id: "i.",
        text:
          "You fail to comply with the terms and conditioned mentioned under this agreement.",
      },
      {
        id: "ii.",
        text: "In case of any breach of terms and conditions of this agreement by you.",
      },
      {
        id: "iii.",
        text:
          "If Onwallet suspects that you are not acting in good faith or in case of any illegal, deceptive, fraudulent or criminal activity.",
      },
    ],
  },
];

export const SEVERABILITY_AND_WAIVER = [
  {
    id: "15.1",
    text:
      "If in any event, any provision of this agreement is held to be invalid or ultravires or unenforceable in respect to a party and the remaining provisions of this agreement shall be valid and enforceable to the fullest extent possible.",
  },
  {
    id: "15.2",
    text:
      "Except as provided, the failure to exercise a right or an obligation under this agreement shall not affect a party’s ability to exercise such a right or obligation at any time thereafter nor shall the waiver of a breach constitute waiver of any subsequent breach.",
  },
];

export const MISCELLANEOUS = [
  {
    id: "18.1",
    text:
      "The schedules, if any, to this agreement are part of the agreement and have the same force and effect.",
  },
  {
    id: "18.2",
    text:
      "If any term or provision of this agreement is at any time held by any jurisdiction to be void, invalid or unenforceable, then it shall be treated as changed or reduced, only to the extent minimally necessary to bring it within the laws of that jurisdiction and to prevent it from being void and it shall be binding in that changed or reduced form. Subject to that, each provision shall be interpreted as severable and shall not in any way affect any other of these terms.",
  },
  {
    id: "18.3",
    text:
      "The rights and obligations of the parties set out in this agreement shall pass to any permitted successor in title.",
  },
  {
    id: "18.4",
    text: "If you are in breach of any term of this agreement, we may:",
    details: [
      {
        id: "18.4.1",
        text: "terminate your account and refuse access to Our Website;",
      },
      {
        id: "18.4.2",
        text: "remove or edit Content, or cancel any order at our discretion;",
      },
      {
        id: "18.4.3",
        text: "issue a claim in any court.",
      },
    ],
  },
  {
    id: "18.5",
    text:
      "Any obligation in this agreement intended to continue to have effect after termination or completion shall so continue.",
  },
  {
    id: "18.6",
    text:
      "No failure or delay by any party to exercise any right, power or remedy will operate as a waiver of it nor indicate any intention to reduce that or any other right in the future.",
  },
  {
    id: "18.7",
    text:
      "You agree that we may disclose your information including assigned IP numbers, account history, account use, etc to any judicial or proper legal authority who makes a written request without further consent or notification to you.",
  },
  {
    id: "18.8",
    text:
      "Any communication to be served on either party by the other shall be delivered by hand or sent by first class post or recorded delivery or by e-mail.",
    details: [
      {
        id: "",
        text: "It shall be deemed to have been delivered;",
      },
      {
        id: "",
        text: "If delivered by hand: on the day of delivery;",
      },
      {
        id: "",
        text: "If sent by post to the correct address: within 72 hours of posting;",
      },
      {
        id: "",
        text:
          "If sent by e-mail to the address from which the receiving party has last sent e-mail: within 24 hours if no notice of non-receipt has been received by the sender.",
      },
    ],
  },
  {
    id: "18.9",
    text:
      "In the event of a dispute between the parties to this agreement, then they undertake to attempt to settle the dispute by engaging in good faith with the other in a process of mediation before commencing arbitration or litigation.",
  },
  {
    id: "18.10",
    text: "This agreement does not give any right to any third party.",
  },
  {
    id: "18.11",
    text:
      "Neither party shall be liable for any failure or delay in performance of this agreement which is caused by circumstances beyond its reasonable control.",
  },
  {
    id: "18.12",
    text:
      "In the event of any conflict between any term of this agreement and the provisions of the articles of a limited company or any comparable document intended to regulate any other corporate or collective body, then the terms of this agreement shall prevail.",
  },
];
