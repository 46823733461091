import {Redirect, useLocation} from "react-router";
import React from "react";

const SaveRedirect = ({to}) => {
  const location = useLocation();
  return (
    <Redirect
      to={{
        ...location,
        pathname: to,
      }}
    />
  );
};

export default SaveRedirect;
