import React from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import "./index.scss";
import messages from "../../messages";

import Select from "shared/components/Select";
import Input from "shared/components/Input";
import Button from "shared/components/Button";
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import {WalletOption, WalletOptionSelected} from "shared/components/CardWalletOptions";
import Textarea from "shared/components/Input/Textarea";
import {getCurrencyRates} from "shared/ratesService/selectors";
import {DetectCryptoWallet} from "../TransferModal/components";
import {
  getCoinAmountInUSD,
  getCryptoWallets,
  getWalletIconByName,
} from "shared/walletService/selectors";
import {openRightModal} from "shared/components/RightModal/actions";

const initialValues = {
  wallet: null,
  amount: "0",
  description: "",
};

const ReceiveWalletSchema = Yup.object().shape({
  wallet: Yup.object()
    .nullable()
    .default(null)
    .required("Wallet is required"),
  amount: Yup.string()
    .required("Amount is required")
    .matches(
      "([1-9]\\d*(\\.\\d*[1-9])?|0\\.\\d*[1-9]+)|\\d+(\\.\\d*[1-9])?",
      "Amount must be a number"
    ),
});

const ReceiveToOwnWallet = ({
  walletAddress,
  currencyRates,
  allWallets,
  openRightModal,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ReceiveWalletSchema}
      onSubmit={(values, actions) => {
        openRightModal("receiveWalletDetails", {
          details: {
            recipient: values.wallet.address,
            currency: values.wallet.currency,
            amount: values.amount,
            description: values.description,
          },
          walletAddress: values.wallet.address,
        });
      }}
    >
      {({errors, touched, values}) => (
        <div className="ReceiveForm">
          <DetectCryptoWallet
            field="wallet"
            allWallets={allWallets}
            walletAddress={walletAddress}
          />
          <Form className="form">
            <FormSectionSeparator sectionName={messages.details} />

            <Field
              name="wallet"
              render={({field}) => (
                <div className="cardInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.wallet} />
                  </label>
                  <Select
                    {...field}
                    error={touched.wallet && errors.wallet ? errors.wallet : ""}
                    height={64}
                    renderValue={value =>
                      !!value ? (
                        <WalletOptionSelected className="no-padding" wallet={value} />
                      ) : (
                        ""
                      )
                    }
                  >
                    {allWallets.map((wallet, index) => (
                      <WalletOption key={index} value={wallet} wallet={wallet} />
                    ))}
                  </Select>
                </div>
              )}
            />
            <div className="AmountCurrency">
              <Field
                name="amount"
                render={({field}) => (
                  <div className="amountInputContainer">
                    <label className="label">
                      <FormattedMessage {...messages.amountInWalletCurrency} />
                    </label>
                    <div className="amountWrapper">
                      {!!values.wallet && (
                        <img
                          className="walletIcon"
                          src={getWalletIconByName(values.wallet.walletName)}
                          alt="coin"
                        />
                      )}
                      <Input
                        type="text"
                        error={touched.amount && errors.amount}
                        {...field}
                      />
                    </div>
                  </div>
                )}
              />

              <div className="amountInUSDContainer">
                <label className="label">
                  <FormattedMessage {...messages.amountInUSD} />
                </label>
                <div className="amountWrapper">
                  <span className="equal">≈</span>
                  <Input
                    type="text"
                    value={getCoinAmountInUSD(
                      values.wallet,
                      values.amount,
                      currencyRates
                    )}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <Field
              name="description"
              render={({field}) => (
                <div className="descriptionInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.descriptionOptional} />
                  </label>
                  <Textarea
                    placeholder="Write an optional description"
                    type="text"
                    {...field}
                  />
                </div>
              )}
            />
            <Button text={messages.generateInvoice} />
          </Form>
        </div>
      )}
    </Formik>
  );
};

const mapStateToProps = store => ({
  currencyRates: getCurrencyRates(store),
  allWallets: getCryptoWallets(store),
});

const mapDispatchToProps = {
  openRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReceiveToOwnWallet);
