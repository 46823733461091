import React, {useState} from "react";
import {connect} from "react-redux";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import {FormattedMessage} from "react-intl";

import "./index.scss";
import messages from "../../messages";
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import Select from "shared/components/Select";
import Input from "shared/components/Input";
import Button from "shared/components/Button";
import Checkbox from "shared/components/Checkbox";

import {closeRightModal} from "shared/components/RightModal/actions";
import {addCartItem} from "shared/cartService/actions";
import {getAllRegularCurrency, getBankCards} from "shared/cardService/selectors";
import {
  CardOption,
  WalletOption,
  WalletOptionSelected,
} from "shared/components/CardWalletOptions";
import Textarea from "shared/components/Input/Textarea";
import {
  getCryptoWallets,
  getWalletIconByName,
  getCoinAmountFromOtherCurrency,
  getCoinNameByCurrency,
  getAllCryptoCurrency,
} from "shared/walletService/selectors";
import {getCurrencyRates} from "shared/ratesService/selectors";
import {transferCardToCrypto} from "shared/walletService/actions";
import {
  CardAmountWithCurrency,
  DetectAmount,
  DetectCryptoCurrency,
  DetectDescription,
  DetectRecipient,
  DetectRegularCard,
  DetectSecondCryptoWallet,
  FeeRate,
  SendRecipient,
  SomeoneCryptoWallet,
} from "./components";

import config from "shared/config";
const arrowIcon = config.prefixImgUrl + "left-arrow-icon.svg";

const ownInitialValues = {
  sourceCard: null,
  recipientWallet: null,
  amount: "0",
  description: "",
  currency: "USD",
  sendReceipt: false,
  email: "",
};

const someoneInitialValues = {
  sourceCard: null,
  walletCurrency: "",
  recipientAddress: "",
  amount: "0",
  description: "",
  currency: "USD",
  sendReceipt: false,
  email: "",
};

const OwnWalletFromCardSchema = Yup.object().shape({
  sourceCard: Yup.object()
    .nullable()
    .default(null)
    .required("Source card is required"),
  recipientWallet: Yup.object()
    .nullable()
    .default(null)
    .required("Recipient wallet is required"),
  amount: Yup.string()
    .required("Amount is required")
    .matches(
      "([1-9]\\d*(\\.\\d*[1-9])?|0\\.\\d*[1-9]+)|\\d+(\\.\\d*[1-9])?",
      "Amount must be a number"
    ),
  currency: Yup.string().required("Currency is required"),
  email: Yup.string().email("Email must be valid"),
});

const SomeoneWalletFromCardSchema = Yup.object().shape({
  sourceCard: Yup.object()
    .nullable()
    .default(null)
    .required("Source card is required"),
  walletCurrency: Yup.string().required("Recipient currency is required"),
  recipientAddress: Yup.string().required("Recipient address is required"),
  amount: Yup.string()
    .required("Amount is required")
    .matches(
      "([1-9]\\d*(\\.\\d*[1-9])?|0\\.\\d*[1-9]+)|\\d+(\\.\\d*[1-9])?",
      "Amount must be a number"
    ),
  currency: Yup.string().required("Currency is required"),
  email: Yup.string().email("Email must be valid"),
});

const onSubmit = (values, own, currencyRates) => {
  if (own) {
    return {
      cryptoAmount: getCoinAmountFromOtherCurrency(
        values.recipientWallet,
        values.amount,
        currencyRates,
        values.currency
      ),
      recipientAddress: values.recipientWallet ? values.recipientWallet.address : "",
      recipientCurrency: values.recipientWallet
        ? values.recipientWallet.currency.toLowerCase()
        : "",
      sourceNumber: values.sourceCard ? values.sourceCard.number : "",
      receiptEmail: values.email || null,
    };
  } else {
    return {
      cryptoAmount: getCoinAmountFromOtherCurrency(
        values.recipientWallet,
        values.amount,
        currencyRates,
        values.currency
      ),
      recipientAddress: values.recipientAddress,
      recipientCurrency: values.walletCurrency ? values.walletCurrency.toLowerCase() : "",
      sourceNumber: values.sourceCard ? values.sourceCard.number : "",
      receiptEmail: values.email || null,
    };
  }
};

const ToWalletFromCardForm = ({
  isSending,
  bankCards,
  addCartItem,
  allWallets,
  formTabs,
  currencyRates,
  availableWallets,
  transferCardToCrypto,
  allCurrency,
  cardNumber,
  recipientAddress,
  currency,
  amount,
  description,
  closeRightModal,
}) => {
  const [own, setOwn] = useState(false);
  return (
    <Formik
      initialValues={own ? ownInitialValues : someoneInitialValues}
      validationSchema={own ? OwnWalletFromCardSchema : SomeoneWalletFromCardSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, {setSubmitting}) => {
        transferCardToCrypto(onSubmit(values, own, currencyRates));
      }}
    >
      {({errors, touched, values, resetForm, setFieldValue}) => (
        <div className="CardToCardTransferForm">
          <DetectSecondCryptoWallet field="recipientWallet" allWallets={allWallets} />
          <DetectRegularCard
            field="sourceCard"
            cardNumber={cardNumber}
            allCards={bankCards}
          />
          <DetectRecipient field="recipientAddress" recipient={recipientAddress} />
          <DetectDescription field="description" description={description} />
          <DetectCryptoCurrency
            currency={currency}
            field="walletCurrency"
            availableWallets={availableWallets}
          />
          <DetectAmount field="amount" amount={amount} />
          <Form className="form">
            <FormSectionSeparator sectionName={messages.source} />
            {formTabs && formTabs()}
            <Field name="sourceCard">
              {({field}) => (
                <div className="cardInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.sourceOfTransfer} />
                  </label>
                  <Select
                    {...field}
                    renderValue={value =>
                      !!value ? <CardOption className="short" card={value} /> : ""
                    }
                  >
                    {bankCards.map((card, index) => (
                      <CardOption
                        key={index}
                        className="currency-m"
                        value={card}
                        card={card}
                      />
                    ))}
                  </Select>
                </div>
              )}
            </Field>
            <FormSectionSeparator sectionName={messages.recipient} />
            <div className="switchReceiptContainer">
              <Checkbox
                className="checkbox"
                value={own}
                onChange={e => {
                  setOwn(e.target.checked);
                }}
              />
              <div className="text">
                <FormattedMessage {...messages.ownCryptoWallet} />
              </div>
            </div>
            {own && (
              <Field name="recipientWallet">
                {({field}) => (
                  <div className="cardInputContainer">
                    <label className="label">
                      <FormattedMessage {...messages.recipientWallet} />
                    </label>
                    <Select
                      {...field}
                      height={64}
                      renderValue={value =>
                        !!value ? (
                          <WalletOptionSelected className="no-padding" wallet={value} />
                        ) : (
                          ""
                        )
                      }
                    >
                      {allWallets.map((wallet, index) => (
                        <WalletOption key={index} value={wallet} wallet={wallet} />
                      ))}
                    </Select>
                  </div>
                )}
              </Field>
            )}
            {!own && (
              <SomeoneCryptoWallet
                touched={touched}
                errors={errors}
                availableWallets={availableWallets}
                values={values}
              />
            )}
            <FormSectionSeparator sectionName={messages.details} />
            <div className="AmountCryptoWalletCurrency">
              <CardAmountWithCurrency
                touched={touched}
                errors={errors}
                allCurrency={allCurrency}
              />

              <div className="amountCryptoInputContainer">
                <label className="label">
                  <FormattedMessage {...messages.amountInRecipientCurrency} />
                </label>
                <div className="amountWrapper">
                  <span className="equal">≈</span>
                  <div className="amountWrapper">
                    <Input
                      type="text"
                      error={touched.amount && errors.amount}
                      leftIcon={
                        own && !!values.recipientWallet
                          ? getWalletIconByName(values.recipientWallet.walletName)
                          : !own && !!values.walletCurrency
                          ? getWalletIconByName(
                              getCoinNameByCurrency(values.walletCurrency)
                            )
                          : null
                      }
                      iconStyles="walletIcon"
                      value={getCoinAmountFromOtherCurrency(
                        !own && values.walletCurrency
                          ? {currency: values.walletCurrency}
                          : values.recipientWallet,
                        values.amount,
                        currencyRates,
                        values.currency ? values.currency : null
                      )}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
            {own && !!values.recipientWallet && !!values.currency && (
              <div className="exchangeRate">
                <div className="rate">
                  <FormattedMessage {...messages.exchangeRate} />: 1{" "}
                  {values.recipientWallet.currency}
                  <img className="arrow" src={arrowIcon} alt="arrow" />
                  {getCoinAmountFromOtherCurrency(
                    values.recipientWallet,
                    1,
                    currencyRates,
                    values.currency
                  )}{" "}
                  {values.currency}
                </div>
                <div className="info">
                  <FormattedMessage {...messages.finalExchangeRate} />
                </div>
              </div>
            )}
            {!own && !!values.walletCurrency && !!values.currency && (
              <div className="exchangeRate">
                <div className="rate">
                  <FormattedMessage {...messages.exchangeRate} />: 1{" "}
                  {values.walletCurrency.toUpperCase()}
                  <img className="arrow" src={arrowIcon} alt="arrow" />
                  {getCoinAmountFromOtherCurrency(
                    {currency: values.walletCurrency},
                    1,
                    currencyRates,
                    values.currency
                  )}{" "}
                  {values.currency}
                </div>
                <div className="info">
                  <FormattedMessage {...messages.finalExchangeRate} />
                </div>
              </div>
            )}
            <Field name="description">
              {({field}) => (
                <div className="descriptionInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.description} />
                  </label>
                  <Textarea
                    type="text"
                    placeholder="Write an optional message"
                    {...field}
                  />
                </div>
              )}
            </Field>
            <SendRecipient touched={touched} errors={errors} values={values} />
            <FeeRate
              card={values.sourceCard}
              amount={values.amount}
              currency={values.currency ? values.currency : null}
            />
            <div className="buttonsContainer">
              <Button
                type="button"
                text={messages.addToCart}
                isSecondary="true"
                onClick={() => {
                  closeRightModal();
                  addCartItem({
                    type: "transfer",
                    data: {
                      description: values.description,
                      sourceCard: values.sourceCard,
                      sourceAmount: values.amount,
                      sourceCurrency: values.sourceCard
                        ? values.sourceCard.currency
                        : "USD",
                    },
                    action: option =>
                      transferCardToCrypto(onSubmit(values, own, currencyRates), option),
                  });
                }}
              />

              <Button type="sumbit" text={messages.sendNow} isLoading={isSending} />
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

const mapStateToProps = store => ({
  isSending: store.transferCardData.isLoading,
  bankCards: getBankCards(store),
  allWallets: getCryptoWallets(store),
  currencyRates: getCurrencyRates(store),
  availableWallets: getAllCryptoCurrency(store),
  allCurrency: getAllRegularCurrency(store),
});

const mapDispatchToProps = {
  transferCardToCrypto,
  addCartItem,
  closeRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ToWalletFromCardForm);
