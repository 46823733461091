import React from "react";
import {withStyles} from "@material-ui/core/styles";
// Components
import Radio from "@material-ui/core/Radio";
// Styles
import "./index.scss";

const RadioBlue = withStyles({
  // Material styles
  root: {
    color: "#0080FF",
    width: "18px",
    height: "18px",
    marginRight: "24px",
    "&$checked": {
      color: "#0080FF",
    },
  },
  checked: {},
})(({title, subTitle, ...props}) => (
  <div className="radio">
    <Radio color="default" {...props} />
    {!!title && !!subTitle && (
      <div className="radio-text">
        <div className="radio-text__title">{title}</div>
        <div className="radio-text__subTitle">{subTitle}</div>
      </div>
    )}
  </div>
));

export default RadioBlue;
