import React from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import {fetchTransactions} from "shared/historyService/actions";
import "./index.scss";
import Select from "shared/components/Select";
import InputWithIcon from "shared/components/InputWithIcon";
import InputWithSuffix from "shared/components/InputWithSuffix";
import Button from "shared/components/Button";
import SelectGroupSeparator from "shared/components/SelectGroupSeparator";
import DateRangePicker from "shared/components/DateRangePicker";
import {MenuItem} from "@material-ui/core";
import {getBankCards} from "shared/cardService/selectors";
import {getCryptoWallets} from "shared/walletService/selectors";
import {
  CardOption,
  WalletOption,
  WalletOptionSelected,
} from "shared/components/CardWalletOptions";

import messages from "../../../messages";

import config from "shared/config";
const searchIcon = config.prefixImgUrl + "search-icon.svg";

const initialValues = {
  keyword: "",
  source: "",
  period: null,
  operation: "All",
  amount: null,
  amountFrom: null,
  amountTo: null,
};

const HistoryFilterModalSchema = Yup.object().shape({
  amountTo: Yup.string()
    .nullable()
    .matches(
      "([1-9]\\d*(\\.\\d*[1-9])?|0\\.\\d*[1-9]+)|\\d+(\\.\\d*[1-9])?",
      "Amount must be a number"
    )
    .test(
      "should-be-greather-than-amountFrom",
      "Should not be less than amount from",
      function(value) {
        const amountFrom = Number.parseFloat(this.parent.amountFrom);

        if (!amountFrom) {
          return true;
        }
        return Number.parseFloat(value) >= amountFrom;
      }
    ),

  amountFrom: Yup.string()
    .nullable()
    .matches(
      "([1-9]\\d*(\\.\\d*[1-9])?|0\\.\\d*[1-9]+)|\\d+(\\.\\d*[1-9])?",
      "Amount must be a number"
    ),
});

const HistoryFilterModal = ({bankCards, allWallets, fetchTransactions}) => {
  const [selectedSource, setSelectedSource] = React.useState("All Sources");
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={HistoryFilterModalSchema}
      onSubmit={values => {
        const {keyword, source, period, operation, amountFrom, amountTo} = values;

        fetchTransactions({
          purpose: keyword,
          sourceAccount: source && (source.number || source.address),
          startTime: period && [period.start, period.end],
          operation: operation === "All" ? null : operation,
          amount:
            source && amountFrom && amountTo
              ? [Number.parseFloat(amountFrom), Number.parseFloat(amountTo)]
              : null,
          baseAmount:
            !source && amountFrom && amountTo
              ? [Number.parseFloat(amountFrom), Number.parseFloat(amountTo)]
              : null,
        });
      }}
      on
    >
      {({values, errors, touched, setFieldValue}) => (
        <Form className="HistoryFilterModal">
          <Field
            name="keyword"
            render={({field}) => (
              <div className="byKeywordContainer">
                <label className="label">
                  <FormattedMessage {...messages.byKeyword} />
                </label>
                <InputWithIcon
                  {...field}
                  //placeholder={messages.byKeywordPlaceholder.defaultMessage}
                  icon={searchIcon}
                />
              </div>
            )}
          />
          <Field
            name="source"
            render={({field}) => (
              <div className="bySourceWalletCardContainer">
                <label className="label">
                  <FormattedMessage {...messages.bySourceWalletCard} />
                </label>
                <Select
                  {...field}
                  value={selectedSource}
                  renderValue={value =>
                    !!value ? (
                      !!value.number ? (
                        <CardOption card={value} />
                      ) : !!value.address ? (
                        <WalletOptionSelected className="no-padding" wallet={value} />
                      ) : (
                        <MenuItem value="All Sources">
                          <div className="title">
                            <div className="name">All Sources</div>
                          </div>
                        </MenuItem>
                      )
                    ) : (
                      <MenuItem>
                        <div className="title">
                          <div className="name">All Sources</div>
                        </div>
                      </MenuItem>
                    )
                  }
                  onChange={e => {
                    setSelectedSource(e.target.value);
                  }}
                >
                  <MenuItem value="All Sources">
                    <div className="title">
                      <div className="name">All Sources</div>
                    </div>
                  </MenuItem>

                  <SelectGroupSeparator groupName={messages.cryptoWallets} />
                  {allWallets.map((wallet, index) => (
                    <WalletOption key={index} value={wallet} wallet={wallet} />
                  ))}

                  <SelectGroupSeparator groupName={messages.bankCards} />
                  {bankCards.map((card, index) => (
                    <CardOption key={index} value={card} card={card} />
                  ))}
                </Select>
              </div>
            )}
          />

          <Field
            name="period"
            render={({field}) => (
              <DateRangePicker
                title={messages.period}
                onChange={value => setFieldValue("period", value)}
              />
            )}
          />
          <Field
            name="operation"
            render={({field}) => (
              <div className="operationTypeInputContainer">
                <label className="label">
                  <FormattedMessage {...messages.operationType} />
                </label>
                <Select
                  className="select"
                  {...field}
                  options={["All", "Transfer", "Receive", "Exchange"]}
                />
              </div>
            )}
          />
          <div className="transactionsAmountContainer">
            <Field
              name="amountFrom"
              render={({field}) => (
                <div className="fromInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.transactionAmountFrom} />
                  </label>
                  <InputWithSuffix
                    {...field}
                    //suffix={values.source ? values.source.currency.toUpperCase() : "USD"}
                    error={
                      touched.amountFrom && errors.amountFrom ? errors.amountFrom : ""
                    }
                  />
                </div>
              )}
            />
            <Field
              name="amountTo"
              render={({field}) => (
                <div className="toInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.till} />
                  </label>
                  <InputWithSuffix
                    {...field}
                    //suffix={values.source ? values.source.currency.toUpperCase() : "USD"}
                    error={touched.amountTo && errors.amountTo ? errors.amountTo : ""}
                  />
                </div>
              )}
            />
          </div>
          <Button text={messages.save} />
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = store => ({
  bankCards: getBankCards(store),
  allWallets: getCryptoWallets(store),
});

const mapDispatchToProps = {
  fetchTransactions,
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryFilterModal);
