import React, {useState} from "react";
import {FormattedMessage} from "react-intl";
import messages from "../../messages";
import {Field} from "formik";

import PasswordInput from "shared/components/PasswordInput";
import Input from "shared/components/Input";
import Button from "shared/components/Button";
import Select from "shared/components/Select";
import DialogModal from "shared/components/DialogModal";
import CodeInput6 from "shared/components/CodeInput6";
import {ReactComponent as IconInfo} from "images/info-icon-wc.svg";
import "shared/styles/ui-elements.scss";

import config from "shared/config";
// import {sendIDPconfirmCodeEmail} from "../../../../shared/api/authApi";
import TimerShow from "../../../../shared/components/Timer";
const personalLogo = config.prefixImgUrl + "personal-logo.svg";
const registerPersonIcon = config.prefixImgUrl + "register-person.svg";
const registerPersonActiveIcon = config.prefixImgUrl + "register-person-active.svg";
const registerCompanyIcon = config.prefixImgUrl + "register-company.svg";
const registerCompanyActiveIcon = config.prefixImgUrl + "register-company-active.svg";

const RegisterTypes = {
  person: "person",
  company: "company",
};

const FirstRegisterStep = ({
  step,
  type,
  setFieldValue,
  errors,
  values,
  setStep,
  validateForm,
  setErrors,
}) => {
  const [send, setSend] = useState(false);
  const [sendResult, setSendResult] = useState(false);
  const isNotValid = errors => {
    return (
      !values.firstName ||
      !values.lastName ||
      !values.email ||
      !values.password ||
      !values.confirmPassword ||
      !values ||
      errors.firstName ||
      errors.lastName ||
      errors.email ||
      errors.password ||
      errors.confirmPassword ||
      (type === RegisterTypes.company && (!values.companyName || errors.companyName))
    );
  };

  return (
    <div className="firstStep">
      {step > 1 && (
        <div className="readyStep">
          <div className="stepTitle">
            <div className="title">
              <FormattedMessage {...messages.generalInfo} />
            </div>
            <div className="separator" />
            <button className="titleEdit" onClick={() => setStep(1)}>
              <FormattedMessage {...messages.edit} />
            </button>
          </div>
          <div className="stepInfo">
            {type === RegisterTypes.person && (
              <>
                {values.firstName} {values.lastName}, {values.email} (
                <FormattedMessage {...messages.person} />)
              </>
            )}
            {type === RegisterTypes.company && (
              <>
                {values.firstName} {values.lastName}, {values.email} (
                <FormattedMessage {...messages.company} />)
              </>
            )}
          </div>
        </div>
      )}
      {step === 1 && (
        <>
          <div className="firstStepTitle">
            <FormattedMessage {...messages.generalInfo} />
          </div>
          <p className="firstStepTitle-status">I'm registering as:</p>
          <div className="typeContainer">
            <div
              className={`typeItem ${type === RegisterTypes.person ? "active" : ""}`}
              onClick={() => {
                // resetForm();
                setFieldValue("type", RegisterTypes.person);
              }}
            >
              <img
                src={
                  type === RegisterTypes.person
                    ? registerPersonActiveIcon
                    : registerPersonIcon
                }
                className="icon"
                alt="person"
              />
              <div className="text">
                <FormattedMessage {...messages.privatePerson} />
              </div>
            </div>
            <div
              className={`typeItem ${type === RegisterTypes.company ? "active" : ""}`}
              onClick={() => {
                // resetForm();
                setFieldValue("type", RegisterTypes.company);
              }}
            >
              <img
                src={
                  type === RegisterTypes.company
                    ? registerCompanyActiveIcon
                    : registerCompanyIcon
                }
                className="icon"
                alt="person"
              />
              <div className="text">
                <FormattedMessage {...messages.company} />
              </div>
            </div>
          </div>
          {type === RegisterTypes.person && (
            <>
              <div className="ui-input-wrapper">
                <div className="ui-input-col col-input">
                  <Field name="specialization">
                    {({field}) => (
                      <div className="inputContainer">
                        <label className="label">
                          <FormattedMessage {...messages.specialization} />
                        </label>
                        <Select
                          error={errors.specialization}
                          {...field}
                          options={["General Purpose", "Broker"]}
                        />
                      </div>
                    )}
                  </Field>
                </div>
                <div className="ui-input-col col-btn">
                  <DialogModal
                    btnCallIn={<IconInfo />}
                    icon={personalLogo}
                    dialogtitle={<FormattedMessage {...messages.dialogtitlePrivate} />}
                    btntext={<FormattedMessage {...messages.dialogBtntext} />}
                    content="<h3>General Purpose</h3>
                    <p>A person without specific trading needs</p>
                    <p>&nbsp;</p>
                    <h3>Broker</h3>
                    <p>A person that is intented to trade Deho in large amounts .For purchase of coins the broker receives Commission Fee:</p>
                    <p>1000 - 10,000 coins = 1,75%</p>
                    <p>10,000 - 100,000 coins = 2,5%</p>
                    <p>100,000 - 500,000 coins = 3,5%</p>
                    <p>500,000 - 1000,000 coins = 5%</p>
                    <p>more than 1000,000 coins = 7%</p>
                    <p>more than 10,000,000 coins = 10% from coin purchase price</p>"
                  />
                </div>
              </div>

              <Field name="firstName">
                {({field}) => (
                  <div className="inputContainer">
                    <label className="label">
                      <FormattedMessage {...messages.firstName} />
                    </label>
                    <Input error={errors.firstName} {...field} />
                  </div>
                )}
              </Field>
              <Field name="lastName">
                {({field}) => (
                  <div className="inputContainer">
                    <label className="label">
                      <FormattedMessage {...messages.lastName} />
                    </label>
                    <Input error={errors.lastName} {...field} />
                  </div>
                )}
              </Field>
              {/* EMAIL */}
            </>
          )}
          {type === RegisterTypes.company && (
            <>
              <div className="ui-input-wrapper">
                <div className="ui-input-col col-input">
                  <Field name="specialization">
                    {({field}) => (
                      <div className="inputContainer">
                        <label className="label">
                          <FormattedMessage {...messages.specialization} />
                        </label>
                        <Select
                          error={errors.specialization}
                          {...field}
                          options={["General Purpose", "Broker", "Institutional"]}
                        />
                      </div>
                    )}
                  </Field>
                </div>
                <div className="ui-input-col col-btn">
                  <DialogModal
                    btnCallIn={<IconInfo />}
                    icon={personalLogo}
                    dialogtitle={<FormattedMessage {...messages.dialogtitleCompany} />}
                    btntext={<FormattedMessage {...messages.dialogBtntext} />}
                    content="<h3>General Purpose</h3>
                    <p>A company without specific trading needs.</p>
                    <p>&nbsp;</p>
                    <h3>Broker</h3>
                    <p>A company that is intended to trade Deho in large amounts. For purchase of coins the broker company receives Commission Fee:</p>
                    <p>1000 - 10,000 coins = 1,75%</p>
                    <p>10,000 - 100,000 coins = 2,5%</p>
                    <p>100,000 - 500,000 coins = 3,5%</p>
                    <p>500,000 - 1000,000 coins = 5%</p>
                    <p>more than 1000,000 coins = 7%</p>
                    <p>more than 10,000,000 coins = 10% from coin purchase price</p>
                    <p>&nbsp;</p>
                    <h3>Institutional</h3>
                    <p>A company that is intended to make bulk coin purchases (from 500,000 coins per order) can receive special discount offer (we will contact after registration).</p>
                    "
                  />
                </div>
              </div>
              <Field name="companyName">
                {({field}) => (
                  <div className="inputContainer">
                    <label className="label">
                      <FormattedMessage {...messages.companyName} />
                    </label>
                    <Input error={errors.companyName} {...field} />
                  </div>
                )}
              </Field>
              {/* EMAIL */}
              <Field name="companySite">
                {({field}) => (
                  <div className="inputContainer">
                    <label className="label">
                      <FormattedMessage {...messages.companySite} />
                    </label>
                    <Input error={errors.companySite} {...field} />
                  </div>
                )}
              </Field>
              <Field name="firstName">
                {({field}) => (
                  <div className="inputContainer">
                    <label className="label">
                      <FormattedMessage {...messages.usersFirstName} />
                    </label>
                    <Input error={errors.firstName} {...field} />
                  </div>
                )}
              </Field>
              <Field name="lastName">
                {({field}) => (
                  <div className="inputContainer">
                    <label className="label">
                      <FormattedMessage {...messages.usersLastName} />
                    </label>
                    <Input error={errors.lastName} {...field} />
                  </div>
                )}
              </Field>
            </>
          )}
          <Field name="password">
            {({field}) => (
              <div className="inputContainer">
                <label className="label">
                  <FormattedMessage {...messages.password} />
                </label>
                <PasswordInput error={errors.password} {...field} />
                <p className="hint">
                  <FormattedMessage {...messages.passwordNote} />
                </p>
              </div>
            )}
          </Field>
          <Field name="confirmPassword">
            {({field}) => (
              <div className="inputContainer">
                <label className="label">
                  <FormattedMessage {...messages.confirmPassword} />
                </label>
                <Input
                  type="password"
                  error={errors.confirmPassword}
                  {...field}
                  power="bad"
                />
              </div>
            )}
          </Field>

          {type === RegisterTypes.person && (
            <Field name="email">
              {({field}) => (
                <div className="inputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.email} />
                  </label>
                  <Input
                    error={errors.email}
                    {...field}
                    onChange={e => {
                      setFieldValue("email", e.target.value);
                      setSendResult(false);
                      setSend(false);
                    }}
                  />
                  <p className="hint">
                    <FormattedMessage {...messages.emailPassCode} />
                  </p>
                  {sendResult && <p className="inputErrorText">{sendResult}</p>}
                </div>
              )}
            </Field>
          )}

          {type === RegisterTypes.company && (
            <Field name="email">
              {({field}) => (
                <div className="inputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.businessEmail} />
                  </label>
                  <Input
                    error={errors.email}
                    {...field}
                    onChange={e => {
                      setFieldValue("email", e.target.value);
                      setSendResult(false);
                      setSend(false);
                    }}
                  />
                  <p className="hint">
                    <FormattedMessage {...messages.emailPassCode} />
                  </p>
                  {sendResult && <p className="inputErrorText">{sendResult}</p>}
                </div>
              )}
            </Field>
          )}

          {send && (
            <div className="inputContainer">
              <p className="hint">
                <FormattedMessage {...messages.checkMailPassCode} />
              </p>
              <CodeInput6
                error={errors.emailConfirmationCode}
                onChange={value => {
                  setFieldValue("emailConfirmationCode", value);
                }}
              />
              <p className="hint">
                <FormattedMessage {...messages.codeWillExpire} />
                <TimerShow
                  times="300"
                  endtime={e => {
                    setSendResult(false);
                    setSend(false);
                  }}
                />
              </p>
            </div>
          )}

          <Button
            type="button"
            className="nextButton"
            onClick={() => {
              // ================================================================================
              // if (send && values.emailConfirmationCode > 99999) {
              //   validateForm().then(errors => {
              //     if (!isNotValid(errors)) {
              //       setErrors({});
              //       setStep(2);
              //     }
              //   });
              // }
              validateForm().then(errors => {
                if (!isNotValid(errors)) {
                  setErrors({});
                  setStep(2);
                }
              });

              // ================================================================================
              // else if (!send && values.emailConfirmationCode.length === 0) {
              //   validateForm().then(errors => {
              //     if (!isNotValid(errors)) {
              //       let _option = {
              //         headers: {
              //           "Content-Type": "application/json",
              //         },
              //       };
              //       sendIDPconfirmCodeEmail(values.email, _option)
              //         .then(response => {
              //           setSendResult(false);
              //           setSend(true);
              //         })
              //         .catch(error => {
              //           let _msg = "";
              //           let _status = error.response ? error.response.status : 0;
              //           switch (_status) {
              //             case 400:
              //               _msg = "User Exists";
              //               break;
              //             case 500:
              //               _msg = "Server error, please try again";
              //               break;

              //             default:
              //               _msg = "System Error, please try again";
              //           }
              //           setSendResult(_msg);
              //         });
              //     }
              //   });
              // }
            }}
            text={send ? messages.verifyCode : messages.sendEmailCode}
          />

          <div className="copyright">{messages.copyRight.defaultMessage}</div>
        </>
      )}
    </div>
  );
};

export default FirstRegisterStep;
