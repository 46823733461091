import React from "react";
import {FormattedMessage} from "react-intl";
import messages from "../../../messages";
// Components
import {CompanyFileInput} from "../SecondRegisterStep";
import Button from "shared/components/Button";

const ThirdRegisterStep = ({
  step,
  setFieldValue,
  values,
  setStep,
  validateForm,
  setErrors,
}) => {
  const isDisabled = () => {
    return (
      !values.validFilesFront || !values.validFilesBack || !values.validFilesPhotoCustomer
    );
  };

  const isNotValid = errors => {
    return (
      !values.validFilesFront ||
      !values.validFilesBack ||
      !values.validFilesPhotoCustomer ||
      errors.validFilesFront ||
      errors.validFilesBack ||
      errors.validFilesPhotoCustomer
    );
  };

  return (
    <div className="thirdStep">
      {step > 3 && (
        <div className="readyStep">
          <div className="stepTitle">
            <div className="title">
              <FormattedMessage {...messages.personValidation} />
            </div>
            <div className="separator" />
            <button className="titleEdit" onClick={() => setStep(3)}>
              <FormattedMessage {...messages.edit} />
            </button>
          </div>
          <div className="stepInfo">
            {values.validFilesFront.name}
            {values.validFilesBack.name}
            {values.validFilesPhotoCustomer.name}
          </div>
        </div>
      )}
      {step === 3 && (
        <>
          <div className="thirdStepTitle">
            <FormattedMessage {...messages.personValidation} />
          </div>
          {/* Upload validation files for customer */}
          <CompanyFileInput
            file={values.validFilesFront ? values.validFilesFront.name : ""}
            clear={() => setFieldValue("validFilesFront", "")}
            onChange={e => setFieldValue("validFilesFront", e.target.files[0])}
          >
            <FormattedMessage {...messages.frontSideOfPersonIdCard} />
          </CompanyFileInput>

          <CompanyFileInput
            file={values.validFilesBack ? values.validFilesBack.name : ""}
            clear={() => setFieldValue("validFilesBack", "")}
            onChange={e => setFieldValue("validFilesBack", e.target.files[0])}
          >
            <FormattedMessage {...messages.backSideOfPersonIdCard} />
          </CompanyFileInput>

          <CompanyFileInput
            file={
              values.validFilesPhotoCustomer ? values.validFilesPhotoCustomer.name : ""
            }
            clear={() => setFieldValue("validFilesPhotoCustomer", "")}
            onChange={e => setFieldValue("validFilesPhotoCustomer", e.target.files[0])}
          >
            <FormattedMessage {...messages.freshPhotoCustomer} />
          </CompanyFileInput>

          <div className="formButtons">
            <Button
              disabled={isDisabled()}
              type="button"
              className="nextButton"
              onClick={() => {
                validateForm().then(errors => {
                  if (!isNotValid(errors)) {
                    setErrors({});
                    setStep(4);
                  }
                });
              }}
              text={messages.next}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ThirdRegisterStep;
