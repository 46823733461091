import React, {Component} from "react";
import {Formik, Field} from "formik";
import {connect} from "react-redux";
import {selectUserProfile} from "shared/auth/selectors";
import {getAllCards, getOnwalletAccount} from "shared/cardService/selectors";
import {shareMyContact} from "shared/auth/actions";
import {FormattedMessage} from "react-intl";
import {SendToOption} from "shared/components/CardWalletOptions";
import * as Yup from "yup";
import Select from "shared/components/Select";
import Input from "shared/components/Input";
import Button from "shared/components/Button";
import Checkbox from "shared/components/Checkbox";
import ModalContactList from "shared/components/AddressBook/listContacts";
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import messages from "../../../messages";
import "../index.scss";
import config from "shared/config";

const onWalletIcon = config.prefixImgUrl + "onwallet-icon-short_v1.svg";
const contactIcon = config.prefixImgUrl + "contact-icon.svg";
const emailIcon = config.prefixImgUrl + "email-icon.svg";
const phoneIcon = config.prefixImgUrl + "phone-icon.svg";
const defaultIcon = config.prefixImgUrl + "userdefault-icon.svg";

class SendMyContactModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCheck: [
        {
          status: true,
          title: "Company Name",
          userInfo: "Five System Development",
          disabled: true,
          isShow: props.userProfile.roles.includes("Advanced"),
        },
        {
          status: true,
          isShow: true,
          title: "OnWallet Account",
          userInfo:
            props.onWalletAccount && props.onWalletAccount.account
              ? props.onWalletAccount.account
              : "",
          disabled: true,
        },
        {
          status: false,
          isShow: true,
          title: "Phone",
          userInfo: props.userProfile.phone ? `+${props.userProfile.phone}` : "",
        },
        {
          status: false,
          isShow: true,
          title: "E-mail",
          userInfo: props.userProfile.email ? props.userProfile.email : "",
        },
        {
          status: false,
          isShow: true,
          title: "Address",
          userInfo:
            props.userProfile.zip &&
            props.userProfile.country &&
            props.userProfile.city &&
            props.userProfile.street &&
            props.userProfile.apartment
              ? `${props.userProfile.zip} ${props.userProfile.country} ${props.userProfile.city} ${props.userProfile.street} ${props.userProfile.apartment}`
              : "",
        },
        {
          status: false,
          isShow: true,
          title: "Bank Account",
          userInfo: "",
        },
        {
          status: false,
          isShow: true,
          title: "Card Number",
          userInfo: props.bankCards[0].last4 ? `**** ${props.bankCards[0].last4}` : "",
        },
      ],
      isShowContactList: false,
      type: "",
      enterData: "",
    };
  }

  handleSetSelect = item => {
    switch (this.state.type) {
      case "email":
        this.setState({enterData: item.info.email});
        break;
      case "phone":
        this.setState({enterData: item.info.phone});
        break;
      case "onWallet":
        this.setState({enterData: item.info.onWalletAccount});
        break;
      default:
        break;
    }
  };

  handlerChangeEnterData = e => {
    this.setState({enterData: e.target.value});
  };

  handlerChangeCheck = (event, index) => {
    const copyArray = this.state.isCheck.slice();
    copyArray[index].status = event.target.checked;

    this.setState({isCheck: copyArray});
  };

  handlerSubmit = () => {
    const payload = {
      sendTo: this.state.enterData,
      type: this.state.type.toLowerCase(),
      contact: {
        firstName: this.props.userProfile.firstName,
        lastName: this.props.userProfile.lastName,
        companyName: this.state.isCheck[0].status ? this.state.isCheck[0].userInfo : "",
        onWalletAccount: this.state.isCheck[1].status
          ? this.state.isCheck[1].userInfo
          : "",
        phone: this.state.isCheck[2].status ? this.state.isCheck[2].userInfo : "",
        email: this.state.isCheck[3].status ? this.state.isCheck[3].userInfo : "",
        address: this.state.isCheck[4].status ? this.state.isCheck[4].userInfo : "",
        bankAccount: this.state.isCheck[5].status ? this.state.isCheck[5].userInfo : "",
        bankCard: this.state.isCheck[6].status ? this.state.isCheck[6].userInfo : "",
      },
    };

    this.props.shareContact(payload);
    this.props.close();
  };

  render() {
    const SendToOptions = [
      {
        text: <FormattedMessage {...messages.onWalletAccount} />,
        value: "onWallet",
      },
      {
        text: <FormattedMessage {...messages.email} />,
        value: "email",
      },
      {
        text: <FormattedMessage {...messages.phoneNumber} />,
        value: "phone",
      },
    ];

    const initialValues = {
      sendTo: null,
      onWallet: "",
      email: "",
      phone: "",
      onwalletAccount: "",
    };

    const ReceiveDetailsSchema = Yup.object().shape({
      sendTo: Yup.object()
        .default(null)
        .required("Send to is required"),
      email: Yup.string().email(),
      phone: Yup.string().matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone Number is not valid"
      ),
      onwalletAccount: Yup.string().min(18),
    });

    return (
      <div className="contact-container">
        <Formik
          initialValues={initialValues}
          validationSchema={ReceiveDetailsSchema}
          onSubmit={() => this.handlerSubmit()}
        >
          {({errors, touched, values, isSubmitting, handleSubmit}) => (
            <form onSubmit={handleSubmit}>
              <div>
                <FormSectionSeparator sectionName={messages.contactDetails} />

                <div className="contact-details">
                  <img src={defaultIcon} alt="avatar" />
                  <div className="contact-details-user">
                    <div className="contact-details-user__name">{`${this.props.userProfile.firstName} ${this.props.userProfile.lastName}`}</div>
                    <div className="contact-details-user__hint">
                      Select what to be sent
                    </div>
                  </div>
                </div>

                {this.state.isCheck.map((item, index) => {
                  return (
                    <div
                      className={`user-info ${
                        !this.state.isCheck[index].isShow ? "hide-company-name" : ""
                      }`}
                      key={index}
                    >
                      <Checkbox
                        className="checkbox"
                        value={this.state.isCheck[index].status}
                        onChange={e => this.handlerChangeCheck(e, index)}
                        disabled={item.disabled}
                      />
                      <div className="user-info__title">{item.title}</div>
                      <div className="user-info__description">{item.userInfo}</div>
                    </div>
                  );
                })}

                <FormSectionSeparator sectionName={messages.recipient} />

                <Field name="sendTo">
                  {({field}) => (
                    <div className="sendToContainer">
                      <label className="label">
                        <FormattedMessage {...messages.sendTo} />
                      </label>
                      <Select
                        error={touched.sendTo && errors.sendTo ? errors.sendTo : ""}
                        className="select"
                        renderValue={value => (
                          <SendToOption value={value} title={value.text} />
                        )}
                        {...field}
                      >
                        {SendToOptions.map((sendTo, index) => (
                          <SendToOption key={index} value={sendTo} title={sendTo.text} />
                        ))}
                      </Select>
                    </div>
                  )}
                </Field>

                {values.sendTo && values.sendTo.value === "onWallet" && (
                  <SendToInput
                    touched={touched}
                    errors={errors}
                    fieldName="onwalletAccount"
                    icon={onWalletIcon}
                    placeholder="Paste or scan an address"
                    label={<FormattedMessage {...messages.onWalletAccount} />}
                    withButtons={true}
                    type="onWalletAccount"
                    state={this.state}
                    setShow={() => {
                      this.setState({
                        isShowContactList: !this.state.isShowContactList,
                        type: values.sendTo.value,
                      });
                    }}
                    setEnterData={this.handlerChangeEnterData}
                    handleSetSelect={this.handleSetSelect}
                  />
                )}
                {values.sendTo && values.sendTo.value === "email" && (
                  <SendToInput
                    touched={touched}
                    errors={errors}
                    fieldName="email"
                    type="email"
                    state={this.state}
                    setShow={() => {
                      this.setState({
                        isShowContactList: !this.state.isShowContactList,
                        type: values.sendTo.value,
                      });
                    }}
                    setEnterData={this.handlerChangeEnterData}
                    handleSetSelect={this.handleSetSelect}
                    icon={emailIcon}
                    label={<FormattedMessage {...messages.email} />}
                  />
                )}
                {values.sendTo && values.sendTo.value === "phone" && (
                  <SendToInput
                    touched={touched}
                    errors={errors}
                    fieldName="phone"
                    type="phone"
                    state={this.state}
                    setShow={() => {
                      this.setState({
                        isShowContactList: !this.state.isShowContactList,
                        type: values.sendTo.value,
                      });
                    }}
                    setEnterData={this.handlerChangeEnterData}
                    handleSetSelect={this.handleSetSelect}
                    icon={phoneIcon}
                    label={<FormattedMessage {...messages.phoneNumber} />}
                  />
                )}

                <div className="contact-add-container--actions">
                  <Button
                    type="button"
                    text={messages.cancel}
                    isSecondary="true"
                    onClick={this.props.close}
                  />
                  <Button type="submit" disabled={isSubmitting} text={messages.send} />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

export const SendToInput = ({
  touched,
  errors,
  label,
  placeholder,
  fieldName,
  icon,
  state,
  setShow,
  type,
  setEnterData,
  handleSetSelect,
}) => {
  return (
    <div>
      <div className="contact-listinput-wrapper">
        <div className="wrapper">
          <div className="wrapper__row">
            <div
              className={`wrapper__list-contacts ${
                state.isShowContactList ? "visible position-contact-list" : ""
              }`}
            >
              <ModalContactList
                type={type}
                isShowSearch={true}
                handleSetSelect={handleSetSelect}
                toggleShowContacts={setShow}
              />
            </div>
          </div>
        </div>
      </div>

      <Field name={fieldName}>
        {({field}) => (
          <div className="onWalletAccountInputContainer">
            <label className="label">{label}</label>
            <div className="accountInput">
              <div className="accountInput__item">
                <Input
                  type="text"
                  leftIcon={icon}
                  iconStyles="onWalletIcon"
                  error={touched[fieldName] && errors[fieldName]}
                  placeholder={placeholder}
                  {...field}
                  onChange={setEnterData}
                  value={state.enterData || ""}
                />
              </div>
              <div className="accountInput__item" onClick={setShow}>
                <img className="contactIcon" src={contactIcon} alt="contact" />
              </div>
            </div>
          </div>
        )}
      </Field>
    </div>
  );
};

const mapStateToProps = store => ({
  userProfile: selectUserProfile(store),
  bankCards: getAllCards(store),
  onWalletAccount: getOnwalletAccount(store),
});

function mapDispatchToProps(dispatch) {
  return {
    shareContact: data => {
      dispatch(shareMyContact(data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SendMyContactModal);
