/**
 * @author: Seleznyov
 */

import React, {Component} from "react";

class OrderItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      price: 0,
      amount: 0,
      total: 0,
      progres: 0,
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  /**
   *
   */
  prepareData() {
    const {item} = this.props;

    this.setState({
      price: this.formatPrice(item.price),
      amount: this.formatAmount(item.cryptoAmount),
      total: this.calcTotal(item.price, item.cryptoAmount),
      progres: this.calcProgress(item.baseCryptoAmount, item.cryptoAmount),
    });
  }

  /**
   *
   * @param baseCryptoAmount
   * @param cryptoAmount
   * @returns {number}
   */
  calcProgress(baseCryptoAmount, cryptoAmount) {
    return (baseCryptoAmount / 100) * (baseCryptoAmount - cryptoAmount);
  }

  /**
   *
   * @param price
   * @param amount
   * @returns {number}
   */
  calcTotal(price, amount) {
    let _val = (Math.round(price * amount * 100) / 100).toFixed(2);
    return this.formatNumber(_val);
  }

  /**
   *
   * @param price
   * @returns {number}
   */
  formatPrice(price) {
    let _val = this.formatNumber((Math.round(price * 100) / 100).toFixed(2));

    let _diff = this.props.prevItem ? this.props.prevItem.price - price : 0;
    let _f = _val.toString().split(".");

    if (_diff < 1 && _diff > 0) {
      _val = _f[0] + '.<span class="change">' + _f[1] + "</span>";
    } else if (_diff >= 1) {
      _val = '<span class="change">' + _val + "</span>";
    }
    return _val;
  }

  /**
   *
   * @param amount
   * @returns {number}
   */
  formatAmount(amount) {
    let _val = (Math.round(amount * 10) / 10).toFixed(1);
    return this.formatNumber(_val);
  }

  /**
   *
   * @param value
   * @returns {string|number}
   */
  formatNumber(value) {
    if (value > 0) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return 0;
  }

  /**
   *
   * @returns {*}
   */
  render() {
    return (
      <div className="item-row">
        <div
          className="item-value price"
          dangerouslySetInnerHTML={{__html: this.state.price}}
        ></div>
        <div className="item-value amount">{this.state.amount}</div>
        <div className="item-value total">{this.state.total}</div>
        <div
          className="item-value progress-total"
          style={{width: this.state.progres + "%"}}
        ></div>
      </div>
    );
  }
}

export default OrderItem;
