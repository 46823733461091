import React, {useState} from "react";
import "./index.scss";
import {connect} from "react-redux";
import {getAllCards} from "shared/cardService/selectors";
import {FormattedMessage} from "react-intl";
import {setDefaultCard} from "shared/cardService/actions";
import CustomCheckbox from "shared/components/Checkbox";
import messages from "../../messages";
import config from "shared/config";

const onwalletEye = config.prefixImgUrl + "onwallet-eye.svg";
const visaLogo = config.prefixImgUrl + "visa-icon-light.svg";
const cardBackground = config.prefixImgUrl + "card-back-small.png";
const onwalletLogo = config.prefixImgUrl + "onwallet-logo_v1.svg";

const ReceiveVirtualCard = cards => {
  const {bankCards} = cards;
  const virtualCard = bankCards.find(card => card.operator === "OnWallet");
  const numberCard = virtualCard.number.match(/.{1,4}/g);
  const [typeInput, setType] = useState(true);
  const [localCard, setLocalCard] = useState(virtualCard);

  return (
    <div className="cardContainer">
      <div className="cardContainer-checkbox">
        <CustomCheckbox
          value={localCard.isDefault}
          onChange={() => {
            if (!localCard.isDefault) {
              setDefaultCard(localCard.id);
              setLocalCard({...localCard, isDefault: true});
            }
          }}
        />
        <FormattedMessage {...messages.useThisCardByDefault} />
      </div>
      <div className="cardContainer-cards">
        <div
          className="cardContainer-cards__card"
          style={{background: `url(${cardBackground})`}}
        >
          <div className="cardContainer-cards__card-logo">
            <img src={onwalletLogo} alt="OnWallet Logo" />
          </div>
          {numberCard.length && (
            <div className="cardContainer-cards__card-number">
              <div className="cardContainer-cards__card-number__item">
                {numberCard[0]}
              </div>
              <div className="cardContainer-cards__card-number__item">
                {numberCard[1]}
              </div>
              <div className="cardContainer-cards__card-number__item">
                {numberCard[2]}
              </div>
              <div className="cardContainer-cards__card-number__item">
                {numberCard[3]}
              </div>
            </div>
          )}
          <div className="cardContainer-cards__card-valid">
            <div className="cardContainer-cards__card-valid__text">valid thru</div>
            <div className="cardContainer-cards__card-valid__text">
              {virtualCard.dateValid}
            </div>
          </div>
          <div className="cardContainer-cards__card-name"> {virtualCard.name} </div>
          <div className="cardContainer-cards__card-currency">{virtualCard.currency}</div>
          <div className="cardContainer-cards__card-visa__logo">
            <img src={visaLogo} alt="Visa Logo" />
          </div>
        </div>
        <div className="cardContainer-cards__card">
          <div className="cardContainer-cards__card__line"></div>

          <div className="cardContainer-cards__card-cvv">
            <div className="cardContainer-cards__card-cvv__item">
              <label htmlFor="cvv">CVV</label>
              <input
                type={typeInput ? "text" : "password"}
                readOnly="readonly"
                id="cvv"
                defaultValue={virtualCard.cvv}
              />
            </div>

            <div className="cardContainer-cards__card-cvv__item">
              <img
                src={onwalletEye}
                className="eye"
                alt="eye"
                onClick={() => setType(!typeInput)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = store => ({
  bankCards: getAllCards(store),
});

const mapDispatchToProps = {
  setDefaultCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReceiveVirtualCard);
