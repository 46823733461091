import React from "react";
import InputBase from "@material-ui/core/InputBase";
import InputAdornment from "@material-ui/core/InputAdornment";
import {withStyles} from "@material-ui/core/styles";

const StyledInput = withStyles({
  root: {
    borderRadius: 6,
    position: "relative",
    border: "1px solid #a6d3ff",
    backgroundColor: "#f2f8fc",
    color: "#1f333d",
    lineHeight: 19,
    fontSize: 16,
    width: "100%",
    height: 44,
    padding: "12px",

    "&:focus": {
      border: "1px solid #0080ff",
    },

    "&:hover": {
      border: "1px solid #0080ff80",
    },
  },
  input: {},
})(InputBase);

const InputWithSuffix = ({options, className, error, children, suffix, ...props}) => {
  return (
    <div>
      <StyledInput
        type="text"
        className={`input ${error ? "errorInput" : ""} ${className ? className : ""}`}
        endAdornment={<InputAdornment position="end">{suffix}</InputAdornment>}
        {...props}
      />
      {error && <div className="inputErrorText">{error}</div>}
    </div>
  );
};

export default InputWithSuffix;
