import React from "react";
import {connect} from "react-redux";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import {FormattedMessage} from "react-intl";

import "./index.scss";
import messages from "../../messages";
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import Select from "shared/components/Select";
import Button from "shared/components/Button";

import {addCartItem} from "shared/cartService/actions";
import {
  getAllRegularCurrency,
  getBankCards,
  getOnwalletAccount,
} from "shared/cardService/selectors";
import {CardOption, OnWalletOption} from "shared/components/CardWalletOptions";
import Textarea from "shared/components/Input/Textarea";
import {transferOnWalletToCard} from "shared/cardService/actions";
import {
  CardAmountWithCurrency,
  DetectOnWalletAccount,
  FeeRate,
  SendRecipient,
} from "./components";

const OnWalletToCardTransferSchema = Yup.object().shape({
  sourceOnWallet: Yup.object()
    .nullable()
    .default(null)
    .required("Source account is required"),
  recipientCard: Yup.object()
    .nullable()
    .default(null)
    .required("Recipient card is required"),
  amount: Yup.string()
    .required("Amount is required")
    .matches(
      "([1-9]\\d*(\\.\\d*[1-9])?|0\\.\\d*[1-9]+)|\\d+(\\.\\d*[1-9])?",
      "Amount must be a number"
    ),
  currency: Yup.string().required("Currency is required"),
  email: Yup.string().email("Email must be valid"),
});

const onSubmit = values => ({
  amount: Number.parseFloat(values.amount),
  sourceCurrency: values.sourceOnWallet.currency.toLowerCase(),
  recipientNumber: values.recipientCard.number,
  receiptEmail: values.email || null,
});

const OwnCardFromOnWalletForm = ({
  isSending,
  bankCards,
  allCurrency,
  addCartItem,
  formTabs,
  onWalletAccount,
  transferOnWalletToCard,
}) => {
  const initialValues = {
    sourceOnWallet: null,
    recipientCard: bankCards[0],
    amount: "0",
    currency: "USD",
    description: "",
    sendReceipt: false,
    email: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={OnWalletToCardTransferSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, {setSubmitting}) => {
        transferOnWalletToCard(onSubmit(values));
      }}
    >
      {({errors, touched, values}) => (
        <div className="CardToCardTransferForm">
          <DetectOnWalletAccount
            field="sourceOnWallet"
            onWalletAccount={onWalletAccount}
          />
          <Form className="form">
            <FormSectionSeparator sectionName={messages.source} />
            {formTabs && formTabs()}
            <Field name="sourceOnWallet">
              {({field}) => (
                <div className="cardInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.myOnWalletAccount} />
                  </label>
                  <OnWalletOption account={field.value} />
                </div>
              )}
            </Field>
            <FormSectionSeparator sectionName={messages.recipient} />
            <Field name="recipientCard">
              {({field}) => (
                <div className="cardInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.ownCard} />
                  </label>
                  <Select
                    {...field}
                    renderValue={value =>
                      !!value ? <CardOption className="short" card={value} /> : ""
                    }
                  >
                    {bankCards.map((card, index) => (
                      <CardOption key={index} value={card} card={card} />
                    ))}
                  </Select>
                </div>
              )}
            </Field>
            <FormSectionSeparator sectionName={messages.details} />
            <CardAmountWithCurrency
              touched={touched}
              errors={errors}
              allCurrency={allCurrency}
            />
            <Field name="description">
              {({field}) => (
                <div className="descriptionInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.description} />
                  </label>
                  <Textarea
                    type="text"
                    placeholder={messages.transferFromCryptoWallet.defaultMessage}
                    {...field}
                  />
                </div>
              )}
            </Field>
            <SendRecipient touched={touched} errors={errors} values={values} />
            <FeeRate
              card={values.sourceOnWallet}
              amount={values.amount}
              currency={values.sourceOnWallet ? values.sourceOnWallet.currency : null}
            />
            <div className="buttonsContainer">
              <Button
                type="button"
                text={messages.addToCart}
                isSecondary="true"
                onClick={() => {
                  addCartItem({
                    type: "transfer",
                    data: {
                      description: values.description,
                      sourceOnWallet: values.sourceOnWallet,
                      sourceAmount: values.amount,
                      sourceCurrency: values.sourceOnWallet
                        ? values.sourceOnWallet.currency
                        : "USD",
                    },
                    action: option => transferOnWalletToCard(onSubmit(values), option),
                  });
                }}
              />

              <Button type="sumbit" text={messages.sendNow} isLoading={isSending} />
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

const mapStateToProps = store => ({
  isSending: store.transferCardData.isLoading,
  bankCards: getBankCards(store),
  allCurrency: getAllRegularCurrency(store),
  onWalletAccount: getOnwalletAccount(store),
});

const mapDispatchToProps = {
  addCartItem,
  transferOnWalletToCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(OwnCardFromOnWalletForm);
