/**
 * @author: Seleznyov
 */
import config from "shared/config";

export const dataBuy = store => {
  return store.dataTradingWidgetsOrders.dataBuy;
};

export const dataSel = store => {
  return store.dataTradingWidgetsOrders.dataSel;
};

export const dataSelBuyLimit = store => {
  return store.dataTradingWidgetsOrders.limit;
};
export const dataSelBuyLimitGet = store => {
  return store.dataTradingWidgetsOrders.limitGet;
};

export const dataTradeHistory = store => {
  return store.dataTradingWidgetsTradeHistory.dataList;
};

export const limitTradeHistory = store => {
  return store.dataTradingWidgetsTradeHistory.limit;
};

export const cryptoCurrency = store => {
  return store.dataTradingWidgets.filterVars.cryptoCurrency;
};

export const currency = store => {
  return store.dataTradingWidgets.filterVars.currency;
};

export const typeView = store => {
  return parseInt(store.dataTradingWidgetsOrders.typeView, 10);
};

export const dataLastOrder = store => {
  return store.dataTradingWidgetsOrders.dataLastOrder;
};

export const dataOrderHistory = store => {
  return store.dataTradingWidgetsOrdersHistory.dataListOrders;
};

const dehoCoinGold = config.prefixImgUrl + "deho_coin_gold.svg";
const dehoCoinSilver = config.prefixImgUrl + "deho_coin_silver.svg";
// const dehoCoinPlatinum = config.prefixImgUrl + "deho_coin_platinum.svg";
const bitCoin = config.prefixImgUrl + "bitcoin-icon.svg";
const ethereumCoin = config.prefixImgUrl + "ethereum-coin.svg";
const zcashCoin = config.prefixImgUrl + "zcash-coin.svg";
const moneroCoin = config.prefixImgUrl + "monero-coin.svg";
const liteCoin = config.prefixImgUrl + "litecoin-icon.svg";
const rippleCoin = config.prefixImgUrl + "ripple-coin.svg";
const bitcoinCash = config.prefixImgUrl + "bitcoin-cash-icon.svg";
const hydroMineLogo = config.prefixImgUrl + "hydro_mine_logo.jpeg";

export const getCoinNameByCurrency = currency => {
  if (!currency) return null;

  switch (currency.toUpperCase()) {
    case "DHG":
      return "Deho Gold";
    case "DHS":
      return "Deho Silver";
    case "DHP":
      return "Hydro Mine";
    case "BTC":
      return "Bitcoin";
    case "ETH":
      return "Ethereum";
    case "ZEC":
      return "Zcash";
    case "XMR":
      return "Monero";
    case "LTC":
      return "Litecoin";
    case "BCH":
      return "Bitcoin Cash";
    case "XRP":
      return "Ripple";
    default:
      return "Deho Gold";
  }
};

export const getWalletIconByName = name => {
  switch (name) {
    case "Deho Gold":
      return dehoCoinGold;
    case "Deho Silver":
      return dehoCoinSilver;
    case "Hydro Mine":
      return hydroMineLogo;
    case "Bitcoin":
      return bitCoin;
    case "Ethereum":
      return ethereumCoin;
    case "Zcash":
      return zcashCoin;
    case "Monero":
      return moneroCoin;
    case "Litecoin":
      return liteCoin;
    case "Bitcoin Cash":
      return bitcoinCash;
    case "Ripple":
      return rippleCoin;
    default:
      return dehoCoinGold;
  }
};

export const getCurrenciesCodes = store => {
  return [
    {id: "1", code: "DHG"},
    {id: "2", code: "DHS"},
    {id: "3", code: "DHP"},
    {id: "4", code: "BTC"},
    {id: "5", code: "ETH"},
    {id: "6", code: "ZEC"},
    {id: "7", code: "XMR"},
    {id: "8", code: "LTC"},
    {id: "9", code: "BCH"},
    {id: "10", code: "XRP"},
  ];
};

export const getPairs = store => {
  return [
    {id: "1", code: "DHG/USDT"},
    {id: "2", code: "DHG/USD"},
  ];
};
export const dataGraph = store => {
  return store.dataTradingGraph.graph_data;
};

export const dataGraphHeader = store => {
  return store.dataTradingGraph.graph_header_data;
};

export const getChartDataLoading = store => {
  return store.tradingChartData.isLoading;
};

export const getChartDataTrading = store => {
  return !!store.tradingChartData.coinToPair && !!store.tradingChartData.coinToPair.data
    ? store.tradingChartData.coinToPair.data.map(item => {
        return {...item, date: new Date(item.date)};
      })
    : [];
};

export const getChartHeaderData = store => {
  return !!store.tradingChartData.header ? store.tradingChartData.header : null;
};
