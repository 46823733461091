import React, {Component} from "react";
import {connect} from "react-redux";
import {closeRightModal} from "shared/components/RightModal/actions";
import {createVoucher} from "shared/shoppingService/actions";
import {getVoucherList} from "shared/shoppingService/actions";
import {dataRightModal} from "shared/components/RightModal/selectors";

import FormSectionSeparator from "shared/components/FormSectionSeparator";
import Checkbox from "shared/components/Checkbox";
import Input from "shared/components/Input";
import Button from "shared/components/Button";
import Select from "shared/components/Select";
import Textarea from "shared/components/Input/Textarea";
import DatePicker from "shared/components/DatePicker";
import {MenuItem} from "@material-ui/core";
import moment from "moment";

import {Formik, Form, Field} from "formik";

import {FormattedMessage} from "react-intl";
import messages from "../../messages";
import "../index.scss";
import {fieldsAddCard} from "shared/shoppingService/selectors";
import {storeUserInfo} from "shared/auth/selectors";

class ShoppingCardEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      validity_period: [
        {
          key: "date",
          title: <FormattedMessage {...messages.fieldValidityPeriodSpecifiedDate} />,
        },
        {
          key: "years",
          title: <FormattedMessage {...messages.fieldValidityPeriodYears} />,
        },
        {
          key: "months",
          title: <FormattedMessage {...messages.fieldValidityPeriodMonths} />,
        },
        {key: "days", title: <FormattedMessage {...messages.fieldValidityPeriodDays} />},
        {
          key: "unlimit",
          title: <FormattedMessage {...messages.fieldValidityPeriodUnlimited} />,
        },
      ],
    };
  }

  PeriodItems() {
    return this.state.validity_period.map((item, idx) => {
      return (
        <MenuItem value={item.key} key={idx}>
          {item.title}
        </MenuItem>
      );
    });
  }

  handlerCreate = data => {
    const {
      cardName,
      description,
      discountAmount,
      includedPointsAmount,
      numberOff,
      validPeriod,
    } = data;
    let countDate = null;

    // Define duration card
    switch (validPeriod) {
      case "days":
        countDate = numberOff;
        break;
      case "months":
        countDate = numberOff * 30;
        break;
      case "years":
        countDate = numberOff * 365;
        break;
      case "date":
        console.log("expiryDate: ", data.expiryDate);
        break;
      default:
        break;
    }

    const payload = {
      type: 4, // define type card
      merchantId: this.props.userInfo.sub,
      quantity: 1,
      title: cardName,
      percent: discountAmount ? +discountAmount : 0,
      amount: includedPointsAmount ? includedPointsAmount : 0, // Remove key when talk with back-end
      duration: +countDate,
      description,
    };

    // Talk with back-end about (how send date and image ?)
    console.log("payload: ", payload);
    // this.props.getVoucherList();
    // this.props.closeRightModal();
  };

  render() {
    console.log("card: ", this.props.card);
    this.props.card.validPeriod = "";
    return (
      <Formik initialValues={this.props.card} onSubmit={this.handlerCreate}>
        {({values, errors, touched, handleSubmit, setFieldValue}) => (
          <Form className="form new-shopping-card" onSubmit={handleSubmit}>
            <FormSectionSeparator sectionName={messages.ShCompBonuses} />

            <div className="inputWrapper">
              <div className="inputWrapper__col">
                <Field name="discountChk">
                  {({field, form, meta}) => (
                    <div className="fields-wrapper">
                      <Checkbox className="checkbox" {...field} />
                      <div className="text">
                        <FormattedMessage {...messages.fieldDiscountProc} />
                      </div>
                    </div>
                  )}
                </Field>

                <Field name="percent">
                  {({field, form, meta}) => (
                    <div className="fields-wrapper">
                      <Input {...field} disabled={!form.values.discountChk} />
                    </div>
                  )}
                </Field>
              </div>
            </div>

            <FormSectionSeparator sectionName={messages.ShCompDetails} />

            <Field name="title">
              {({field, form, meta}) => (
                <div className="fields-wrapper">
                  <label className="label">
                    <FormattedMessage {...messages.fieldCardName} />
                  </label>
                  <Input {...field} />
                </div>
              )}
            </Field>

            <div className="inputWrapper add-card-image">
              <Button
                type="button"
                text={messages.ShCompAddCardImage}
                className="btn-ui btn-bold-blue btn-with-icon"
              />
              <div className="txt-note">
                <FormattedMessage {...messages.ShCompOptional} />
              </div>
            </div>

            <div className="inputWrapper validity-period">
              <div className="inputWrapper__col">
                <Field name="expirationDate">
                  {({field, form, meta}) => (
                    <div className="fields-wrapper column">
                      <label className="label">
                        <FormattedMessage {...messages.fieldValidityPeriod} />
                      </label>
                      <Select {...field} className="select">
                        {this.PeriodItems()}
                      </Select>
                    </div>
                  )}
                </Field>
              </div>
              <div className="inputWrapper__col">
                {values.validPeriod === "date" ? (
                  <Field name="validPeriod">
                    {({field, form, meta}) => (
                      <div className="fields-wrapper column">
                        <label className="label">
                          <FormattedMessage {...messages.ShCompExpiryDate} />
                        </label>
                        <DatePicker
                          error={touched.valueDate && errors.valueDate}
                          value={field.value ? moment(field.value) : moment()}
                          onChange={value => setFieldValue("expiryDate", value)}
                          onBlur={() => {}}
                        />
                      </div>
                    )}
                  </Field>
                ) : values.validPeriod === "unlimit" ? (
                  ""
                ) : (
                  <Field name="numberOff">
                    {({field, form, meta}) => (
                      <div className="fields-wrapper column">
                        <label className="label">
                          {values.validPeriod === "years" && (
                            <FormattedMessage {...messages.ShCompNumberOfYears} />
                          )}
                          {values.validPeriod === "months" && (
                            <FormattedMessage {...messages.ShCompNumberOfMonths} />
                          )}
                          {values.validPeriod === "days" && (
                            <FormattedMessage {...messages.ShCompNumberOfDays} />
                          )}
                        </label>
                        <Input {...field} />
                      </div>
                    )}
                  </Field>
                )}
              </div>
            </div>

            <Field name="description">
              {({field, form, meta}) => (
                <div className="fields-wrapper column">
                  <label className="label">
                    <FormattedMessage {...messages.fieldDescription} />
                  </label>
                  <Textarea
                    placeholder={messages.ShCompOptionalDescription.defaultMessage}
                    type="text"
                    {...field}
                  />
                </div>
              )}
            </Field>

            <div className="fields-wrapper column delete-block">
              <div className="delete-block__text">Delete</div>
            </div>

            <div className="inputWrapper">
              <div className="inputWrapper__col">
                <Button
                  type="button"
                  text={messages.ShCompCancel}
                  isSecondary="true"
                  onClick={() => {
                    this.props.closeRightModal();
                  }}
                />
              </div>
              <div className="inputWrapper__col">
                <Button type="submit" text={messages.saveChanges} />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = store => ({
  fieldsAddCard: fieldsAddCard(store),
  userInfo: storeUserInfo(store),
  card: dataRightModal(store),
});
const dispatchToProps = {
  closeRightModal,
  getVoucherList,
  createVoucher: data => createVoucher(data),
};

export default connect(mapStateToProps, dispatchToProps)(ShoppingCardEdit);
