import React from "react";
import SidebarNavigation from "../Sidebar";
import "./index.scss";
import {isAuthLoading, isUserAuth} from "shared/auth/selectors";
import {connect} from "react-redux";
import {routes} from "shared/appUrls";
import SaveRedirect from "shared/components/SaveRedirect";

const Layout = ({children, isAuthLoading, isUserAuth}) => {
  if (!isAuthLoading && !isUserAuth) {
    return <SaveRedirect to={routes.login} />;
  }
  return (
    <div className="h-100">
      <div className="LayoutContainer">
        {/* left sidebar */}
        <SidebarNavigation />
        <div className="LayoutContent">{children}</div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  isAuthLoading: isAuthLoading(state),
  isUserAuth: isUserAuth(state),
});

// Func which map actionCreators to props
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
