import React, {Fragment, useState} from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
// Actions
import {openRightModal} from "shared/components/RightModal/actions";
// Components
import Header from "shared/components/Header";
import Button from "shared/components/Button";
import SearchInputWithButton from "shared/components/SearchInputWithButton";
import CustomersPageBody from "./components/CustomersPageBody";
import CustomersDetailsModals from "./CustomersDetailsModals";
// Utils
import messages from "./messages";
import config from "shared/config";
import {ReactComponent as CustomerAddIcon} from "images/icon-contacts-add.svg";
// Images
const CustomersIcon = config.prefixImgUrl + "customers_header_icon.svg";

const CustomersPage = ({openRightModal}) => {
  const [search, setSearch] = useState("");

  return (
    <Fragment>
      {/* Modals Controller */}
      <CustomersDetailsModals />

      <Header
        headerTitle={<FormattedMessage {...messages.header} />}
        navLinks={[]}
        logoImage={CustomersIcon}
      >
        <div className="messages__header__actionBlock">
          <Button
            type="button"
            text={messages.newCustomer}
            className="btn-ui btn-bold-black btn-with-icon"
            renderIcon={className => <CustomerAddIcon className={className} />}
            onClick={() => openRightModal({name: "newCustomer", title: "New Customer"})}
          />
          <div className="contact__header__searchWrapper">
            <SearchInputWithButton
              value={search}
              onChange={e => setSearch(e.target.value)}
              onClick={() => setSearch("")}
              placeholder={"Search by ID or name"}
            />
          </div>
        </div>
      </Header>

      {/* Body Customers Page */}
      <CustomersPageBody />
    </Fragment>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  openRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomersPage);
