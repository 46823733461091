import React from "react";
import "./index.scss";

const powerState = {
  bad: "Bad",
  average: "Average",
  good: "Good",
};

const getPowerState = power => {
  switch (power) {
    case 1:
      return powerState.bad;
    case 2:
      return powerState.average;
    case 3:
      return powerState.good;
    default:
      return "";
  }
};

const getPasswordPower = value => {
  let power = 0;
  if (value.match("[A-Z]+")) power++;
  if (value.match("[a-z]+")) power++;
  if (value.match("\\d+$")) power++;
  return getPowerState(power);
};

const PasswordInput = ({className, error, disabled, children, value, ...props}) => {
  const power = getPasswordPower(value);
  return (
    <>
      <div className={`CustomPasswordInput`}>
        <input
          value={value}
          type="password"
          className={`input ${error ? "errorInput" : ""} ${disabled ? "disabled" : ""} ${
            className ? className : ""
          }`}
          disabled={disabled}
          {...props}
        />
        {value && (
          <label
            className={`powerLabel ${value ? getPasswordPower(value).toLowerCase() : ""}`}
          >
            {power}
          </label>
        )}
      </div>
      {error && <div className="inputErrorText">{error}</div>}
    </>
  );
};

export default PasswordInput;
