import axios from "axios";
import {ContactsEndpoints} from "./apiEndpoints";

export const contactsAPIAddContact = (data, option) => {
  return axios.post(ContactsEndpoints.contacts, data, {
    ...option,
  });
};

export const contactsAPIGetContacts = option => {
  return axios.get(ContactsEndpoints.contacts, {
    ...option,
  });
};

export const contactsAPIUpdateContact = (option, id, payload) => {
  return axios.put(ContactsEndpoints.contactsUpdateAndDelete(id), payload, {
    ...option,
    payload,
  });
};

export const contactsAPIDeleteContact = (option, id) => {
  return axios.delete(ContactsEndpoints.contactsUpdateAndDelete(id), {
    ...option,
  });
};

export const contactsAPISharedContact = (option, id, payload) => {
  return axios.post(ContactsEndpoints.contactsShared(id), payload, {
    ...option,
  });
};
