import {defineMessages} from "react-intl";

const scope = "RolesPage";

const messages = defineMessages({
  rolesTitle: {
    id: `${scope}.rolesTitle`,
    defaultMessage: "Roles",
  },
  newRole: {
    id: `${scope}.newRole`,
    defaultMessage: "New Role",
  },
  cellName: {
    id: `${scope}.cellName`,
    defaultMessage: "Name",
  },
  cellDescription: {
    id: `${scope}.cellDescription`,
    defaultMessage: "Description",
  },
  supervisorApproval: {
    id: `${scope}.supervisorApproval`,
    defaultMessage: "Supervisor Approval",
  },
  permissionName: {
    id: `${scope}.permissionName`,
    defaultMessage: "Permission Name",
  },
  roleName: {
    id: `${scope}.roleName`,
    defaultMessage: "Role Name",
  },
  roleDescription: {
    id: `${scope}.roleDescription`,
    defaultMessage: "Role Description",
  },
  details: {
    id: `${scope}.details`,
    defaultMessage: "Details",
  },
  actionsSupervisors: {
    id: `${scope}.actionsSupervisors`,
    defaultMessage: "Actions Supervisors",
  },
  addSupervisor: {
    id: `${scope}.addSupervisor`,
    defaultMessage: "Add Supervisor",
  },
  permissions: {
    id: `${scope}.permissions`,
    defaultMessage: "Permissions",
  },
  customersManagement: {
    id: `${scope}.customersManagement`,
    defaultMessage: "Customers Management",
  },
  goldAndCoin: {
    id: `${scope}.goldAndCoin`,
    defaultMessage: "Gold & coins",
  },
  staffAndRoles: {
    id: `${scope}.staffAndRoles`,
    defaultMessage: "Staff & Roles",
  },
  supervision: {
    id: `${scope}.supervision`,
    defaultMessage: "Supervision",
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: "Edit",
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: "Delete",
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: "Cancel",
  },
  createRole: {
    id: `${scope}.createRole`,
    defaultMessage: "Create Role",
  },
});

export default messages;
