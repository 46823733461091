import {defineMessages} from "react-intl";

const scope = "WalletsPage";

const messages = defineMessages({
  source: {
    id: `${scope}.source`,
    defaultMessage: "Source",
  },
  valueDate: {
    id: `${scope}.valueDate`,
    defaultMessage: "Value date",
  },
  recipient: {
    id: `${scope}.recipient`,
    defaultMessage: "Recipient",
  },
  headerTransfer: {
    id: `${scope}.headerTransfer`,
    defaultMessage: "Transfer",
  },
  headerReceive: {
    id: `${scope}.headerReceive`,
    defaultMessage: "Receive",
  },
  headerExchange: {
    id: `${scope}.headerExchange`,
    defaultMessage: "Exchange",
  },
  headerAddNew: {
    id: `${scope}.headerAddNew`,
    defaultMessage: "Add",
  },
  headerOther: {
    id: `${scope}.headerOther`,
    defaultMessage: "Other",
  },
  headerCart: {
    id: `${scope}.headerCart`,
    defaultMessage: "Cart",
  },
  headerWallets: {
    id: `${scope}.headerWallets`,
    defaultMessage: "Wallets",
  },
  onWalletDehoBalance: {
    id: `${scope}.onWalletDehoBalance`,
    defaultMessage: "  OnWallet / Deho Balance",
  },
  onwalletAccount: {
    id: `${scope}.onwalletAccount`,
    defaultMessage: "Onwallet Account",
  },
  dehoWalletAccount: {
    id: `${scope}.dehoWalletAccount`,
    defaultMessage: "Deho Wallet",
  },
  toOwnCard: {
    id: `${scope}.toOwnCard`,
    defaultMessage: "to Own Card",
  },
  transferSource: {
    id: `${scope}.transferSource`,
    defaultMessage: "Source of Transfer",
  },
  transferEasyTransfer: {
    id: `${scope}.transferEasyTransfer`,
    defaultMessage: "Easy transfer between your cards",
  },
  transferCreateInvoiceWithCard: {
    id: `${scope}.transferCreateInvoiceWithCard`,
    defaultMessage: "Create Invoice with your card",
  },
  transferToSomeoneCard: {
    id: `${scope}.transferToSomeoneCard`,
    defaultMessage: "to Someone's Card",
  },
  transferPayFast: {
    id: `${scope}.transferPayFast`,
    defaultMessage: "Pay fast to other cards",
  },
  transferBankDetails: {
    id: `${scope}.transferBankDetails`,
    defaultMessage: "by bank details",
  },
  transferToBankAccount: {
    id: `${scope}.transferToBankAccount`,
    defaultMessage: "Transfer to bank's current account",
  },
  transferInternationalPayment: {
    id: `${scope}.transferInternationalSwiftPayment`,
    defaultMessage: "International SWIFT payment",
  },
  transferPayAbroad: {
    id: `${scope}.transferPayAbroad`,
    defaultMessage: "Pay abroad with SWIFT transaction",
  },
  transferToCryptoWallet: {
    id: `${scope}.transferToCryptoWallet`,
    defaultMessage: "to Crypto Wallet",
  },
  transferFundsToPopular: {
    id: `${scope}.transferFundsToPopular`,
    defaultMessage: "Transfer funds to popular crypto wallets",
  },
  transferFromCryptoWallet: {
    id: `${scope}.transferFromCryptoWallet`,
    defaultMessage: "Transfer from own crypto wallet",
  },
  tableCurrency: {
    id: `${scope}.tableCurrency`,
    defaultMessage: "Currency",
  },
  tableAddress: {
    id: `${scope}.tableAddress`,
    defaultMessage: "Address",
  },
  tableBalance: {
    id: `${scope}.tableBalance`,
    defaultMessage: "Balance",
  },
  tableBalanceUSD: {
    id: `${scope}.tableBalanceUSD`,
    defaultMessage: "Balance, USD",
  },
  cryptoWalletsAddWallets: {
    id: `${scope}.cryptoWalletsAddWallets`,
    defaultMessage: "Add Crypto Wallet",
  },
  bankCardsAddCard: {
    id: `${scope}.bankCardsAddCard`,
    defaultMessage: " Add Card",
  },
  cryptoWalletsTitle: {
    id: `${scope}.cryptoWalletsTitle`,
    defaultMessage: "Crypto Wallets",
  },
  bankCardsTitle: {
    id: `${scope}.bankCardsTitle`,
    defaultMessage: "Debit/Credit Cards",
  },
  deleteWallet: {
    id: `${scope}.deleteWallet`,
    defaultMessage: "Delete Wallet",
  },
  tableIssuedBy: {
    id: `${scope}.tableIssuedBy`,
    defaultMessage: "Issued by",
  },
  tableCardNumber: {
    id: `${scope}.tableCardNumber`,
    defaultMessage: "Card Number",
  },
  tableOperator: {
    id: `${scope}.tableOperator`,
    defaultMessage: "Operator",
  },
  tableDefault: {
    id: `${scope}.tableDefault`,
    defaultMessage: "Default",
  },
  transferCreateInvoiceWithCrypto: {
    id: `${scope}.transferCreateInvoiceWithCrypto`,
    defaultMessage: "Create Invoice with your crypto wallet",
  },
  exchangeBuyDeho: {
    id: `${scope}.exchangeBuyDeho`,
    defaultMessage: "Buy Deho",
  },
  exchangeToLowestPrice: {
    id: `${scope}.exchangeToLowestPrice`,
    defaultMessage: "The lowest price for Deho",
  },
  exchangeCryptoToFiat: {
    id: `${scope}.exchangeCryptoToFiat`,
    defaultMessage: "Crypto to fiat currency and vice versa",
  },
  newTransfer: {
    id: `${scope}.newTransfer`,
    defaultMessage: "New Transfer",
  },
  history: {
    id: `${scope}.history`,
    defaultMessage: "History",
  },
  filter: {
    id: `${scope}.filter`,
    defaultMessage: "Filter",
  },
  historyFilters: {
    id: `${scope}.historyFilters`,
    defaultMessage: "History Filters",
  },
  reset: {
    id: `${scope}.reset`,
    defaultMessage: "Reset",
  },
  byKeyword: {
    id: `${scope}.byKeyword`,
    defaultMessage: "By Keyword",
  },
  byKeywordPlaceholder: {
    id: `${scope}.byKeywordPlaceholder`,
    defaultMessage: "Purpose of payment",
  },
  bySourceWalletCard: {
    id: `${scope}.bySourceWalletCard`,
    defaultMessage: "By Source Wallet/Card",
  },
  cryptoWallets: {
    id: `${scope}.cryptoWallets`,
    defaultMessage: "Crypto Wallets",
  },
  bankCards: {
    id: `${scope}.bankCards`,
    defaultMessage: "Bank Cards",
  },
  period: {
    id: `${scope}.period`,
    defaultMessage: "Period",
  },
  operationType: {
    id: `${scope}.operationType`,
    defaultMessage: "Operation Type",
  },
  transactionAmountFrom: {
    id: `${scope}.transactionAmountFrom`,
    defaultMessage: "Transaction amount from",
  },
  till: {
    id: `${scope}.till`,
    defaultMessage: "till",
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: "Save",
  },
  allTransactions: {
    id: `${scope}.allTransactions`,
    defaultMessage: "All Transactions",
  },
  addNewBankCard: {
    id: `${scope}.addNewBankCard`,
    defaultMessage: "New bank card",
  },
  attachYouExistingBankCards: {
    id: `${scope}.attachYouExistingBankCards`,
    defaultMessage: "Attach you existing bank cards",
  },
  newCryptoWallet: {
    id: `${scope}.newCryptoWallet`,
    defaultMessage: "New Crypto Wallet",
  },
  transactionHistory: {
    id: `${scope}.transactionHistory`,
    defaultMessage: "Transaction history",
  },
  manageWallet: {
    id: `${scope}.manageWallet`,
    defaultMessage: "manage Wallet",
  },
  manageCard: {
    id: `${scope}.manageCard`,
    defaultMessage: "manage Card",
  },
  addNewCard: {
    id: `${scope}.addNewCard`,
    defaultMessage: "Add New Card",
  },
  addCard: {
    id: `${scope}.addCard`,
    defaultMessage: "Add Card",
  },
  cardDetails: {
    id: `${scope}.cardDetails`,
    defaultMessage: "Card Details",
  },
  cardCurrency: {
    id: `${scope}.cardCurrency`,
    defaultMessage: "Card Currency",
  },
  weWillCharge: {
    id: `${scope}.weWillCharge`,
    defaultMessage:
      "We will charge any sum between $0-3 from the card for validation. The sum will be refunded after successful validation or in 3 days if validation fails.",
  },
  newInvoice: {
    id: `${scope}.newInvoice`,
    defaultMessage: "New Invoice",
  },
  details: {
    id: `${scope}.details`,
    defaultMessage: "Details",
  },
  fromCryptoWallet: {
    id: `${scope}.fromCryptoWallet`,
    defaultMessage: "From Crypto wallet",
  },
  fromCard: {
    id: `${scope}.fromCard`,
    defaultMessage: "From card",
  },
  sourceOfTransfer: {
    id: `${scope}.sourceOfTransfer`,
    defaultMessage: "Source of Transfer",
  },
  generateInvoice: {
    id: `${scope}.generateInvoice`,
    defaultMessage: "Generate Invoice",
  },
  ownCard: {
    id: `${scope}.ownCard`,
    defaultMessage: "Own Card",
  },
  invoiceAmount: {
    id: `${scope}.invoiceAmount`,
    defaultMessage: "Invoice Amount",
  },
  transactionAmount: {
    id: `${scope}.transactionAmount`,
    defaultMessage: "Transaction Amount",
  },
  transactionAmountPlaceholder: {
    id: `${scope}.transactionAmountPlaceholder`,
    defaultMessage: "Enter Transaction Amount",
  },
  currency: {
    id: `${scope}.currency`,
    defaultMessage: "Currency of Transfer",
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: "Description",
  },
  descriptionOptional: {
    id: `${scope}.descriptionOptional`,
    defaultMessage: "Description (optional)",
  },
  invoiceLink: {
    id: `${scope}.invoiceLink`,
    defaultMessage: "Invoice Link",
  },
  copyLink: {
    id: `${scope}.copyLink`,
    defaultMessage: "Copy link",
  },
  wallet: {
    id: `${scope}.wallet`,
    defaultMessage: "Wallet",
  },
  amountInWalletCurrency: {
    id: `${scope}.amountInWalletCurrency`,
    defaultMessage: "Amount inWallet's Currency",
  },
  amountInUSD: {
    id: `${scope}.amountInUSD`,
    defaultMessage: "Amount in USD",
  },
  toOwnCryptoWallet: {
    id: `${scope}.toOwnCryptoWallet`,
    defaultMessage: "to Own Crypto Wallet",
  },
  exchange: {
    id: `${scope}.exchange`,
    defaultMessage: "Exchange",
  },
  cryptoOrFiat: {
    id: `${scope}.cryptoOrFiat`,
    defaultMessage: "Buy using crypto or fiat currency",
  },
  exchangeSubject: {
    id: `${scope}.exchangeSubject`,
    defaultMessage: "Exchange Subject",
  },
  give: {
    id: `${scope}.give`,
    defaultMessage: "Give",
  },
  get: {
    id: `${scope}.get`,
    defaultMessage: "Get",
  },
  exchangeRate: {
    id: `${scope}.exchangeRate`,
    defaultMessage: "Exchange Rate",
  },
  finalExchangeRate: {
    id: `${scope}.finalExchangeRate`,
    defaultMessage: "Final exchange rate will be fixed at the moment of transaction",
  },
  sourceWallet: {
    id: `${scope}.sourceWallet`,
    defaultMessage: "Source Wallet",
  },
  recipientWallet: {
    id: `${scope}.recipientWallet`,
    defaultMessage: "Recipient Wallet",
  },
  sendTransactionReceipt: {
    id: `${scope}.sendTransactionReceipt`,
    defaultMessage: "Send transaction receipt",
  },
  transactionFee: {
    id: `${scope}.transactionFee`,
    defaultMessage: "Transaction Fee",
  },
  transactionFeeDescription: {
    id: `${scope}.transactionFeeDescription`,
    defaultMessage:
      "* Approximate amount (final amount will be recalculated basing on exchange rate at the moment of transaction)",
  },
  addToCart: {
    id: `${scope}.addToCart`,
    defaultMessage: "Add to Cart",
  },
  exchangeNow: {
    id: `${scope}.exchangeNow`,
    defaultMessage: "Exchange now",
  },
  addNewCryptoWallet: {
    id: `${scope}.addNewCryptoWallet`,
    defaultMessage: "Add New Crypto Wallet",
  },
  walletDetails: {
    id: `${scope}.walletDetails`,
    defaultMessage: "Wallet Details",
  },
  createWallet: {
    id: `${scope}.createWallet`,
    defaultMessage: " Create Wallet",
  },
  sendNow: {
    id: `${scope}.sendNow`,
    defaultMessage: "Send Now",
  },
  recipientCardNumber: {
    id: `${scope}.recipientCardNumber`,
    defaultMessage: "Recipient Card Number",
  },
  recipientCardholder: {
    id: `${scope}.recipientCardholder`,
    defaultMessage: "Recipient Cardholder Name",
  },
  bankAccounts: {
    id: `${scope}.bankAccounts`,
    defaultMessage: "Bank Accounts",
  },
  linkBankAccount: {
    id: `${scope}.linkBankAccount`,
    defaultMessage: "Link Bank Account",
  },
  thirdPartyWallets: {
    id: `${scope}.thirdPartyWallets`,
    defaultMessage: "Third Party Wallets",
  },
  linkWallet: {
    id: `${scope}.linkWallet`,
    defaultMessage: "Link Wallet",
  },
  attachYourExistingBankAccount: {
    id: `${scope}.attachYourExistingBankAccount`,
    defaultMessage: "Attach your existing bank account",
  },
  linkThirdPartyWallet: {
    id: `${scope}.linkThirdPartyWallet`,
    defaultMessage: "Link Third Party Wallet",
  },
  attachYourExistingWallet: {
    id: `${scope}.attachYourExistingWallet`,
    defaultMessage: "Attach your existing wallet",
  },
  unlinkAccount: {
    id: `${scope}.unlinkAccount`,
    defaultMessage: "Unlink Account",
  },
  unlinkWallet: {
    id: `${scope}.unlinkWallet`,
    defaultMessage: "Unlink Wallet",
  },
  useThisCurrentAccountByDefault: {
    id: `${scope}.useThisCurrentAccountByDefault`,
    defaultMessage: "Use this current account by default",
  },
  useThisCardByDefault: {
    id: `${scope}.useThisCardByDefault`,
    defaultMessage: "Use this card by default",
  },
  toOwnOnwalletAccount: {
    id: `${scope}.toOwnOnwalletAccount`,
    defaultMessage: "to Own OnWallet Account",
  },
  createInvoiceWithYourAccount: {
    id: `${scope}.createInvoiceWithYourAccount`,
    defaultMessage: "Create Invoice with your account",
  },
  depositOnwalletAccount: {
    id: `${scope}.depositOnwalletAccount`,
    defaultMessage: "Deposit onwallet account",
  },
  easyTransferToYourAccount: {
    id: `${scope}.easyTransferToYourAccount`,
    defaultMessage: "Easy transfer to your account",
  },
  toSomeoneOnWalletAccount: {
    id: `${scope}.toSomeoneOnWalletAccount`,
    defaultMessage: "to Someone's OnWallet Account",
  },
  transferToAnyOnWalletAccount: {
    id: `${scope}.transferToAnyOnWalletAccount`,
    defaultMessage: "Transfer to any OnWallet account",
  },
  depositCard: {
    id: `${scope}.depositCard`,
    defaultMessage: "Deposit Card",
  },
  fromCardOrOtherWallet: {
    id: `${scope}.fromCardOrOtherWallet`,
    defaultMessage: "From card or other wallet",
  },
  createInvoice: {
    id: `${scope}.createInvoice`,
    defaultMessage: "Create Invoice",
  },
  generateInvoiceLink: {
    id: `${scope}.generateInvoiceLink`,
    defaultMessage: "Generate invoice link",
  },
  emptyCryptoWallets: {
    id: `${scope}.emptyCryptoWallets`,
    defaultMessage:
      "With OnWallet you can transfer, receive, trade and exchange popular crypto currencies. Control all your coins.",
  },
  emptyBankCards: {
    id: `${scope}.emptyBankCards`,
    defaultMessage:
      "Add your cards to OnWallet service and easily transfer funds from/to cards. Visa, Mastercard, Amex and UnionPay are accepted.",
  },
  emptyBankAccounts: {
    id: `${scope}.emptyBankAccounts`,
    defaultMessage: "You can deposit OnWallet account from your bank account as well.",
  },
  emptyThirdWallets: {
    id: `${scope}.emptyThirdWallets`,
    defaultMessage: "You can deposit OnWallet account from third party wallets.",
  },
  depositWallet: {
    id: `${scope}.depositWallet`,
    defaultMessage: "Deposit Wallet",
  },
  manage: {
    id: `${scope}.manage`,
    defaultMessage: "Manage",
  },
  deleteCard: {
    id: `${scope}.deleteCard`,
    defaultMessage: "Delete Card",
  },
  fromOnWallet: {
    id: `${scope}.fromOnWallet`,
    defaultMessage: "OnWallet",
  },
  myOnWalletAccount: {
    id: `${scope}.myOnWalletAccount`,
    defaultMessage: "My OnWallet Account",
  },
  amountInSourceCurrency: {
    id: `${scope}.amountInSourceCurrency`,
    defaultMessage: "Amount in Source's Currency",
  },
  amountInRecipientCurrency: {
    id: `${scope}.amountInRecipientCurrency`,
    defaultMessage: "Amount in Recipient's Currency*",
  },
  ownCryptoWallet: {
    id: `${scope}.ownCryptoWallet`,
    defaultMessage: "Own Crypto Wallet",
  },
  recipientCryptoCurrency: {
    id: `${scope}.recipientCryptoCurrency`,
    defaultMessage: "Recipient Crypto Currency",
  },
  recipientAddress: {
    id: `${scope}.recipientAddress`,
    defaultMessage: "Recipient Address",
  },
  saveAsNewContact: {
    id: `${scope}.saveAsNewContact`,
    defaultMessage: "Save as new contact",
  },
  addToExistContact: {
    id: `${scope}.addToExistContact`,
    defaultMessage: "add to existing contact",
  },
  firstName: {
    id: `${scope}.firstName`,
    defaultMessage: "First Name",
  },
  lastName: {
    id: `${scope}.lastName`,
    defaultMessage: "Last Name",
  },
  toDeleteTheWallet: {
    id: `${scope}.toDeleteTheWallet`,
    defaultMessage: "To delete the wallet it should have zero (0.00000000) balance.",
  },
  balance: {
    id: `${scope}.balance`,
    defaultMessage: "Balance",
  },
  card: {
    id: `${scope}.card`,
    defaultMessage: "Card",
  },
  onWallet: {
    id: `${scope}.onWallet`,
    defaultMessage: "OnWallet",
  },
  recipientName: {
    id: `${scope}.recipientName`,
    defaultMessage: "Recipient Name",
  },
  recipientAccountNumber: {
    id: `${scope}.recipientAccountNumber`,
    defaultMessage: "Recipient Account Number (IBAN)",
  },
  addRecipientAddress: {
    id: `${scope}.addRecipientAddress`,
    defaultMessage: "Add recipient address",
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: "E-mail",
  },
  byBankDetails: {
    id: `${scope}.byBankDetails`,
    defaultMessage: "by Bank Details",
  },
  phoneNumber: {
    id: `${scope}.phoneNumber`,
    defaultMessage: "Phone Number",
  },
  onWalletAccount: {
    id: `${scope}.onWalletAccount`,
    defaultMessage: "OnWallet Account",
  },
  send: {
    id: `${scope}.send`,
    defaultMessage: "Send",
  },
  amount: {
    id: `${scope}.amount`,
    defaultMessage: "Amount",
  },
  sendInvoice: {
    id: `${scope}.sendInvoice`,
    defaultMessage: "Send Invoice",
  },
  sendTo: {
    id: `${scope}.sendTo`,
    defaultMessage: "Send to",
  },
  buyNow: {
    id: `${scope}.buyNow`,
    defaultMessage: "Buy Now",
  },
  recipientBank: {
    id: `${scope}.recipientBank`,
    defaultMessage: "Recipient's Bank",
  },
  country: {
    id: `${scope}.country`,
    defaultMessage: "Country",
  },
  amountOfTransfer: {
    id: `${scope}.amountOfTransfer`,
    defaultMessage: "Amount of Transfer",
  },
  city: {
    id: `${scope}.city`,
    defaultMessage: "City",
  },
  address: {
    id: `${scope}.address`,
    defaultMessage: "Address",
  },
  purposeOfPayment: {
    id: `${scope}.purposeOfPayment`,
    defaultMessage: "Purpose of Payment",
  },
  confirmationDocuments: {
    id: `${scope}.confirmationDocuments`,
    defaultMessage: "Confirmation Documents",
  },
  ifNecessaryAttachScannedCopies: {
    id: `${scope}.ifNecessaryAttachScannedCopies`,
    defaultMessage:
      "If necessary, attach scanned copies of the documents with the details of the swift transfer, or documents confirming the agreement (contract, contract, invoice, letters, etc.) or documents confirming your family relations with the recipient.",
  },
  addDocument: {
    id: `${scope}.addDocument`,
    defaultMessage: "Add Document",
  },
  linkExistingBankAccount: {
    id: `${scope}.linkExistingBankAccount`,
    defaultMessage: "Link Existing Bank Account",
  },
  accountDetails: {
    id: `${scope}.accountDetails`,
    defaultMessage: " Account Details",
  },
  linkAccount: {
    id: `${scope}.linkAccount`,
    defaultMessage: "Link Account",
  },
  bank: {
    id: `${scope}.bank`,
    defaultMessage: "Bank",
  },
  walletProvider: {
    id: `${scope}.walletProvider`,
    defaultMessage: "Wallet Provider",
  },
  walletLogin: {
    id: `${scope}.walletLogin`,
    defaultMessage: "Wallet Login",
  },
  login: {
    id: `${scope}.login`,
    defaultMessage: "Log in",
  },
  password: {
    id: `${scope}.password`,
    defaultMessage: "Password",
  },
  linkExistingWallet: {
    id: `${scope}.linkExistingWallet`,
    defaultMessage: "Link Existing Wallet",
  },
  crypto: {
    id: `${scope}.crypto`,
    defaultMessage: "Crypto",
  },
  deleteCardInfo: {
    id: `${scope}.deleteCardInfo`,
    defaultMessage: "To confirm card deletion - enter your account password.",
  },
  cardAgain: {
    id: `${scope}.cardAgain`,
    defaultMessage:
      "Your card will be deleted from OnWallet Service. You can add it again.",
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: "Cancel",
  },
});

export default messages;
