import {
  FETCH_CURRENCY_RATES_PENDING,
  FETCH_CURRENCY_RATES_FAILED,
  FETCH_CURRENCY_RATES_SUCCESS,
  CURRENCY_RATE_PENDING,
  CURRENCY_RATE_FAILED,
  CURRENCY_RATE_SUCCESS,
  CURRENCY_RATE_TABLE_SUCCESS,
  CURRENCY_RATE_TABLE_PENDING,
  CURRENCY_RATE_TABLE_FAILED,
} from "./actions/types";

const initCryptoWalletsState = {
  isLoading: false,
  rates: {},
  base: null,
  currencyRate: null,
  currencyRatesTable: {
    isLoading: false,
    currency: null,
  },
};

const currencyRatesData = (state = initCryptoWalletsState, action) => {
  const {payload} = action;

  switch (action.type) {
    case FETCH_CURRENCY_RATES_PENDING:
      return {...state, isLoading: true};
    case FETCH_CURRENCY_RATES_SUCCESS:
      return {
        ...state,
        rates: {...action.payload.rates},
        base: action.payload.base,
        isLoading: false,
      };
    case FETCH_CURRENCY_RATES_FAILED:
      return {...state, rates: {}, isLoading: false};
    case CURRENCY_RATE_PENDING:
      return {...state, isLoading: true};
    case CURRENCY_RATE_SUCCESS:
      return {
        ...state,
        currencyRate: payload,
        isLoading: false,
      };
    case CURRENCY_RATE_FAILED:
      return {...state, currencyRate: null, isLoading: true};
    case CURRENCY_RATE_TABLE_PENDING:
      return {...state, currencyRatesTable: {isLoading: true}};
    case CURRENCY_RATE_TABLE_SUCCESS:
      return {
        ...state,
        currencyRatesTable: {
          isLoading: false,
          currency: payload,
        },
      };
    case CURRENCY_RATE_TABLE_FAILED:
      return {...state, currencyRatesTable: {isLoading: true, currency: null}};
    default:
      return state;
  }
};

export {currencyRatesData};
