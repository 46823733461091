import {connect} from "react-redux";
import qs from "query-string";

import {useLocation} from "react-router";
import {openRightModal} from "shared/components/RightModal/actions";

const TransferDetector = ({openRightModal}) => {
  const location = useLocation();
  const parsedQueryString = qs.parse(location.search);
  if (parsedQueryString.to && parsedQueryString.to === "card") {
    openRightModal("transferSomeoneCard", {
      recipientCardNumber: parsedQueryString.recipient,
      currency: parsedQueryString.currency,
      amount: parsedQueryString.amount,
      description: parsedQueryString.description,
    });
  } else if (parsedQueryString.to && parsedQueryString.to === "onWallet") {
    openRightModal("transferToSomeoneOnWallet", {
      recipientAddress: parsedQueryString.recipient,
      currency: parsedQueryString.currency,
      amount: parsedQueryString.amount,
      description: parsedQueryString.description,
    });
  } else if (parsedQueryString.to && parsedQueryString.to === "wallet") {
    openRightModal("transferOwnWallet", {
      recipientAddress: parsedQueryString.recipient,
      currency: parsedQueryString.currency,
      amount: parsedQueryString.amount,
      description: parsedQueryString.description,
    });
  }
  return null;
};

export default connect(null, {openRightModal})(TransferDetector);
