/**
 * @author: Seleznyov
 */

import React from "react";
import {FormattedMessage} from "react-intl";
import Header from "shared/components/Header";
import Button from "shared/components/Button";

import messages from "./messages";
import "./index.scss";

import config from "shared/config";
import PendingActions from "./components/PendingActions";
import {ReactComponent as RoleAddIcon} from "images/icon-contacts-add.svg";
const logoPage = config.prefixImgUrl + "actions-approval-icon-toptitle.svg";

const ActionsApprovalPage = () => {
  return (
    <>
      <Header
        headerTitle={<FormattedMessage {...messages.actionsApprovalPageTitle} />}
        navLinks={[]}
        logoImage={logoPage}
      >
        <div className="messages__header__actionBlock">
          <Button
            type="button"
            text={messages.approvalHistory}
            className="btn-ui btn-bold-black btn-with-icon"
            renderIcon={className => <RoleAddIcon className={className} />}
          />
        </div>
      </Header>

      <div className="actions-approval-page">
        <PendingActions />
      </div>
    </>
  );
};

export default ActionsApprovalPage;
