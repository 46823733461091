import {
  MESSAGES_MARK_ALL_READ,
  MESSAGES_MARK_AS_READ,
  GET_MESSAGES,
} from "./actions/types";

const initState = {
  isLoading: false,
  messages: [],
};

const mark = (list, id) => {
  const newList = list.map(message => {
    if (message.id === id) {
      message.isRead = true;
    }
    return message;
  });

  return newList;
};

const markAll = list => {
  const newList = list.map(message => {
    message.isRead = true;
    return message;
  });

  return newList;
};

const messagesData = (state = initState, action) => {
  const {type, payload} = action;
  switch (type) {
    case MESSAGES_MARK_AS_READ:
      return {...state, messages: mark(state.messages, action.payload)};
    case MESSAGES_MARK_ALL_READ:
      return {...state, messages: markAll(state.messages)};
    case GET_MESSAGES:
      return {...state, messages: payload};
    default:
      return state;
  }
};

export {messagesData};
