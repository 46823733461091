import React from "react";
import {connect} from "react-redux";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import {FormattedMessage} from "react-intl";

import "./index.scss";
import messages from "../../messages";
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import Select from "shared/components/Select";
import Button from "shared/components/Button";

import {addCartItem} from "shared/cartService/actions";
import {getAllRegularCurrency} from "shared/cardService/selectors";
import {WalletOption, WalletOptionSelected} from "shared/components/CardWalletOptions";
import {DetectCryptoWallet, FeeRate, SendRecipient, SwiftBankForm} from "./components";
import {openFileViewer} from "shared/components/FileViewer/actions";
import {getCryptoWallets} from "shared/walletService/selectors";

const initialOwnValues = {
  sourceWallet: null,
  country: "",
  amount: "0",
  currency: "USD",
  recipientName: "",
  recipientAccountNumber: "",
  recipientCountry: "",
  recipientCity: "",
  recipientAddress: "",
  valueDate: "",
  purposeFirst: "",
  purposeSecond: "",
  documents: null,
  description: "",
  sendReceipt: false,
  email: "",
};

const swiftFromWalletScheme = Yup.object().shape({
  sourceWallet: Yup.object()
    .nullable()
    .default(null)
    .required("Source wallet is required"),
  amount: Yup.string()
    .required("Amount is required")
    .matches(
      "([1-9]\\d*(\\.\\d*[1-9])?|0\\.\\d*[1-9]+)|\\d+(\\.\\d*[1-9])?",
      "Amount must be a number"
    ),
  currency: Yup.string().required("Currency is required"),
  email: Yup.string().email("Email must be valid"),
});

const SWIFTFromWalletForm = ({
  isSending,
  openFileViewer,
  allCurrency,
  allWallets,
  addCartItem,
  formTabs,
  walletAddress,
}) => {
  // TODO: remove when Deho will be available
  const filteredSourceWallet = allWallets.filter(wallet => wallet.currency !== "DHO");
  return (
    <Formik
      initialValues={initialOwnValues}
      validationSchema={swiftFromWalletScheme}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, {setSubmitting}) => {}}
    >
      {({errors, touched, values, setFieldValue}) => (
        <div className="CardToCardTransferForm">
          <DetectCryptoWallet
            field="sourceWallet"
            allWallets={filteredSourceWallet}
            walletAddress={walletAddress}
          />
          <Form className="form">
            <FormSectionSeparator sectionName={messages.source} />
            {formTabs && formTabs()}
            <Field name="sourceWallet">
              {({field}) => (
                <div className="cardInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.transferSource} />
                  </label>
                  <Select
                    {...field}
                    error={
                      touched.sourceWallet && errors.sourceWallet
                        ? errors.sourceWallet
                        : ""
                    }
                    height={64}
                    renderValue={value =>
                      !!value ? (
                        <WalletOptionSelected className="no-padding" wallet={value} />
                      ) : (
                        ""
                      )
                    }
                  >
                    {filteredSourceWallet.map((wallet, index) => (
                      <WalletOption key={index} value={wallet} wallet={wallet} />
                    ))}
                  </Select>
                </div>
              )}
            </Field>
            <SwiftBankForm
              touched={touched}
              errors={errors}
              allCurrency={allCurrency}
              setFieldValue={setFieldValue}
              values={values}
              openFileViewer={openFileViewer}
            />

            <SendRecipient touched={touched} errors={errors} values={values} />
            <FeeRate
              card={values.sourceCard}
              amount={values.amount}
              currency={values.currency ? values.currency : null}
            />
            <div className="buttonsContainer">
              <Button
                type="button"
                text={messages.addToCart}
                isSecondary="true"
                onClick={() => {
                  // addCartItem({variant: "cardToCard", type: "transfer", data: values})
                }}
              />

              <Button type="sumbit" text={messages.sendNow} isLoading={isSending} />
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

const mapStateToProps = store => ({
  isSending: store.transferCardData.isLoading,
  allCurrency: getAllRegularCurrency(store),
  allWallets: getCryptoWallets(store),
});

const mapDispatchToProps = {
  addCartItem,
  openFileViewer,
};

export default connect(mapStateToProps, mapDispatchToProps)(SWIFTFromWalletForm);
