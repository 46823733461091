import React from "react";
import {connect} from "react-redux";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import {FormattedMessage} from "react-intl";

import "./index.scss";
import messages from "../../messages";
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import Button from "shared/components/Button";

import {addCartItem} from "shared/cartService/actions";
import {getAllRegularCurrency, getOnwalletAccount} from "shared/cardService/selectors";
import {OnWalletOption} from "shared/components/CardWalletOptions";
import SimpleCardInput from "shared/components/SimpleCardInput";
import Textarea from "shared/components/Input/Textarea";
import {transferOnWalletToCard} from "shared/cardService/actions";
import AddressBookInput from "shared/components/AddressBook";

import {
  CardAmountWithCurrency,
  DetectAmount,
  DetectDescription,
  DetectOnWalletAccount,
  DetectRecipient,
  DetectRegularCurrency,
  FeeRate,
  SendRecipient,
} from "./components";

const initialValues = {
  sourceOnWallet: null,
  recipientCardNumber: null,
  recipientCardHolder: null,
  amount: "0",
  currency: "USD",
  description: "",
  sendReceipt: false,
  email: "",
};

const onSubmit = values => ({
  amount: Number.parseFloat(values.amount),
  sourceCurrency: values.sourceOnWallet
    ? values.sourceOnWallet.currency.toLowerCase()
    : "",
  recipientNumber: values.recipientCardNumber
    ? values.recipientCardNumber.replace(/ /g, "")
    : 0,
  receiptEmail: values.email || null,
});

const OnWalletToSomeCardTransferSchema = Yup.object().shape({
  sourceOnWallet: Yup.object()
    .nullable()
    .default(null)
    .required("Source account is required"),
  recipientCardNumber: Yup.string()
    .min(18, "Card Number is too short")
    .max(23, "Card Number is too long")
    .required("Recipient Card Number is required"),
  amount: Yup.string()
    .required("Amount is required")
    .matches(
      "([1-9]\\d*(\\.\\d*[1-9])?|0\\.\\d*[1-9]+)|\\d+(\\.\\d*[1-9])?",
      "Amount must be a number"
    ),
  currency: Yup.string().required("Currency is required"),
  email: Yup.string().email("Email must be valid"),
});

const SomeoneCardFromOnWalletForm = ({
  isSending,
  allCurrency,
  addCartItem,
  formTabs,
  onWalletAccount,
  transferOnWalletToCard,
  recipientCardNumber,
  currency,
  amount,
  description,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={OnWalletToSomeCardTransferSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, {setSubmitting}) => {
        transferOnWalletToCard(onSubmit(values));
      }}
    >
      {({errors, touched, values}) => (
        <div className="CardToCardTransferForm">
          <DetectOnWalletAccount
            field="sourceOnWallet"
            onWalletAccount={onWalletAccount}
          />
          <DetectRecipient field="recipientCardNumber" recipient={recipientCardNumber} />
          <DetectRegularCurrency
            allCurrency={allCurrency}
            field="currency"
            currency={currency}
          />
          <DetectAmount field="amount" amount={amount} />
          <DetectDescription field="description" description={description} />
          <Form className="form">
            <FormSectionSeparator sectionName={messages.source} />
            {formTabs && formTabs()}
            <Field name="sourceOnWallet">
              {({field}) => (
                <div className="cardInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.myOnWalletAccount} />
                  </label>
                  <OnWalletOption account={field.value} />
                </div>
              )}
            </Field>
            <FormSectionSeparator sectionName={messages.recipient} />
            <Field name="recipientCardNumber">
              {({field}) => (
                <div className="cardInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.recipientCardNumber} />
                  </label>
                  <SimpleCardInput
                    error={touched.recipientCardNumber && errors.recipientCardNumber}
                    {...field}
                  />
                </div>
              )}
            </Field>
            <div className="cardInputContainer">
              <div className="cardInputContainer-block">
                <div className="cardInputContainer-block__item">
                  <Field name="recipientCardHolder">
                    {({field, form}) => (
                      <AddressBookInput
                        type="text"
                        label={<FormattedMessage {...messages.recipientCardholder} />}
                        types="cards"
                        placeholder="Name as marked on card"
                        name="recipientCardHolder"
                        error={touched.recipientCardHolder && errors.recipientCardHolder}
                        customer={{
                          cardNumber: values.recipientCardNumber,
                        }}
                        otherfield={{
                          label: <FormattedMessage {...messages.recipientCardNumber} />,
                          value: values.recipientCardNumber,
                          name: "recipientCardNumber",
                        }}
                        form={form}
                        {...field}
                      />
                    )}
                  </Field>
                </div>
              </div>
            </div>
            <FormSectionSeparator sectionName={messages.details} />
            <CardAmountWithCurrency
              touched={touched}
              errors={errors}
              allCurrency={allCurrency}
            />
            <Field name="description">
              {({field}) => (
                <div className="descriptionInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.description} />
                  </label>
                  <Textarea
                    type="text"
                    placeholder={messages.transferFromCryptoWallet.defaultMessage}
                    {...field}
                  />
                </div>
              )}
            </Field>
            <SendRecipient touched={touched} errors={errors} values={values} />
            <FeeRate
              card={values.sourceOnWallet}
              amount={values.amount}
              currency={values.sourceOnWallet ? values.sourceOnWallet.currency : null}
            />
            <div className="buttonsContainer">
              <Button
                type="button"
                text={messages.addToCart}
                isSecondary="true"
                onClick={() => {
                  addCartItem({
                    type: "transfer",
                    data: {
                      description: values.description,
                      sourceOnWallet: values.sourceOnWallet,
                      sourceAmount: values.amount,
                      sourceCurrency: values.sourceOnWallet
                        ? values.sourceOnWallet.currency
                        : "USD",
                    },
                    action: option => transferOnWalletToCard(onSubmit(values), option),
                  });
                }}
              />

              <Button type="sumbit" text={messages.sendNow} isLoading={isSending} />
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

const mapStateToProps = store => ({
  isSending: store.transferCardData.isLoading,
  allCurrency: getAllRegularCurrency(store),
  onWalletAccount: getOnwalletAccount(store),
});

const mapDispatchToProps = {
  addCartItem,
  transferOnWalletToCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(SomeoneCardFromOnWalletForm);
