import React, {useState} from "react";
import {connect} from "react-redux";
import {injectIntl, FormattedMessage} from "react-intl";
import "./index.scss";
import {routes} from "../../shared/appUrls";
import messages from "./messages";
import {Link} from "react-router-dom";
import WithEmailForm from "./components/WithEmailForm";
import WithPhraseForm from "./components/WithPhraseForm";
import ResetPasswordForm from "./components/ResetPasswordForm";

import {ReactComponent as CloseIcon} from "images/close-icon.svg";

import config from "shared/config";
const onWalletIcon = config.prefixImgUrl + "onwallet-icon-light_v1.svg";

const recoverVariants = {
  email: "email",
  phrase: "phrase",
  resetPhrase: "resetPhrase",
  resetEmail: "resetEmail",
};

const RecoveryPage = () => {
  const [variant, setVariant] = useState(recoverVariants.email);
  return (
    <div className="RecoveryPageWrapper">
      <div className="mainBackground">
        <a href="https://onwallet.com/">
          <img className="onWalletLogo" src={onWalletIcon} alt="logo" />
        </a>
        <div class="copyright">Copyright © 2021 OnWallet. All rights reserved.</div>
      </div>
      <div className="rightContent">
        <div className="recoveryHeader">
          <Link to={routes.login} className="closeButton">
            <CloseIcon />
          </Link>
          <div className="title">
            {variant === recoverVariants.resetPhrase ? (
              <FormattedMessage {...messages.passwordReset} />
            ) : (
              <FormattedMessage {...messages.passwordRecovery} />
            )}
          </div>
        </div>
        <div className="recoveryContent">
          {(variant === recoverVariants.email || variant === recoverVariants.phrase) && (
            <div className="formTabsContainer">
              <div
                className={`formTab ${
                  variant === recoverVariants.email ? "ActiveTab" : ""
                }`}
                onClick={() => setVariant(recoverVariants.email)}
              >
                <FormattedMessage {...messages.withEmail} />
              </div>
              <div
                className={`formTab ${
                  variant === recoverVariants.phrase ? "ActiveTab" : ""
                }`}
                onClick={() => setVariant(recoverVariants.phrase)}
              >
                <FormattedMessage {...messages.withRecoveryPhrase} />
              </div>
            </div>
          )}
          {variant === recoverVariants.email && (
            <WithEmailForm click={() => setVariant(recoverVariants.resetEmail)} />
          )}
          {variant === recoverVariants.phrase && (
            <WithPhraseForm click={() => setVariant(recoverVariants.resetPhrase)} />
          )}
          {variant === recoverVariants.resetPhrase && <ResetPasswordForm />}
          {variant === recoverVariants.resetEmail && (
            <p className="text">
              <FormattedMessage {...messages.checkYourEmail} />
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = store => ({});
const mapDispatchToProps = {};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RecoveryPage));
