import {defineMessages} from "react-intl";

const scope = "CartPage";

const messages = defineMessages({
  headerCart: {
    id: `${scope}.headerCard`,
    defaultMessage: "Cart",
  },
  headerBack: {
    id: `${scope}.headerBack`,
    defaultMessage: "Back",
  },
  transactions: {
    id: `${scope}.transactions`,
    defaultMessage: "Transactions",
  },
  failedTransactions: {
    id: `${scope}.failedTransactions`,
    defaultMessage: "Failed Transactions",
  },
  successfulTransactions: {
    id: `${scope}.successfulTransactions`,
    defaultMessage: "Successful Transactions",
  },
  clearCart: {
    id: `${scope}.clearCart`,
    defaultMessage: "Clear cart",
  },
  source: {
    id: `${scope}.source`,
    defaultMessage: "Source",
  },
  purposeOfPayment: {
    id: `${scope}.purposeOfPayment`,
    defaultMessage: "Purpose of Payment",
  },
  amount: {
    id: `${scope}.amount`,
    defaultMessage: "Amount",
  },
  actions: {
    id: `${scope}.actions`,
    defaultMessage: "Actions",
  },
  execute: {
    id: `${scope}.execute`,
    defaultMessage: "Execute",
  },
  confirm: {
    id: `${scope}.confirm`,
    defaultMessage: "Confirm",
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: "Cancel",
  },
  weSentSMS: {
    id: `${scope}.weSentSMS`,
    defaultMessage:
      "We sent on your phone SMS with confirmation pass code. Enter confirmation pass code in the fields below.",
  },
  iHaveIssue: {
    id: `${scope}.iHaveIssue`,
    defaultMessage: "I have issue with receiving of this pass code -",
  },
  sendNewCode: {
    id: `${scope}.sendNewCode`,
    defaultMessage: " send new code",
  },
  someFailed: {
    id: `${scope}.someFailed`,
    defaultMessage:
      "Some transactions failed, you can try execute again or edit transactions",
  },
  executeAgain: {
    id: `${scope}.executeAgain`,
    defaultMessage: "Execute again",
  },
  pending: {
    id: `${scope}.pending`,
    defaultMessage: "Pending",
  },
  confirmed: {
    id: `${scope}.confirmed`,
    defaultMessage: "Confirmed",
  },
  failed: {
    id: `${scope}.failed`,
    defaultMessage: "Failed",
  },
  yourCartEmpty: {
    id: `${scope}.yourCartEmpty`,
    defaultMessage: "Your Cart is Empty",
  },
  youCanAdd: {
    id: `${scope}.youCanAdd`,
    defaultMessage: "You can add transactions from ",
  },
  sectionOrReturn: {
    id: `${scope}.sectionOrReturn`,
    defaultMessage: " section or return to previous page.",
  },
  wallets: {
    id: `${scope}.wallets`,
    defaultMessage: "Wallets",
  },
  goBack: {
    id: `${scope}.goBack`,
    defaultMessage: "Go back",
  },
});

export default messages;
