import React, {useEffect, useState} from "react";
import PropsTypes from "prop-types";
import "./index.scss";
import {
  LineChart,
  ResponsiveContainer,
  Tooltip,
  Line,
  YAxis,
  XAxis,
  CartesianGrid,
} from "recharts";
import {decimal2digits} from "../../tools";

const tooltipContainerStyles = {
  boxShadow: "0px 3px 8px #004D993F",
  borderRadius: "6px",
  border: "none",
  opacity: ".85",
};

const tooltipContentStyles = {
  border: "none",
  padding: "14px 16px 12px 16px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const tooltipLabelStyles = {
  marginRight: "24px",
  fontSize: "14px",
  color: "#1F333D",
  lineHeight: "24px",
};

const tooltipItemStyle = {
  fontSize: "14px",
  color: "#1F333D",
  lineHeight: "24px",
};

const mapValue = value => {
  if (value < 1000) return value;
  if (value < 1000000) return `${value / 1000}K`;
  if (value < 1000000000) return `${value / 1000000}KK`;
  return value;
};

const CustomDot = ({color, dataKey, ...props}) => {
  return <circle {...props} r={7} stroke={`${color}`} strokeWidth={2} fill={`#fff`} />;
};

const Chart = ({id, data, withTooltip, colors, keys, formatValues, withDots}) => {
  const [loaded, setLoaded] = useState(false);
  // let maxValue, minValue, maxSecondValue, minSecondValue;
  // if (keys[0]) {
  //   maxValue = Math.max(...data.map(item => item[keys[0]]));
  //   minValue = Math.min(...data.map(item => item[keys[0]]));
  //   maxValue += 0.1 * maxValue;
  //   minValue -= 0.1 * minValue;
  // }
  // if (keys[1]) {
  //   maxSecondValue = Math.max(...data.map(item => item[keys[1]]));
  //   minSecondValue = Math.min(...data.map(item => item[keys[1]]));
  //   maxSecondValue += 0.1 * maxSecondValue;
  //   minSecondValue -= 0.1 * minSecondValue;
  // }
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoaded(true);
    }, 0);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const svg = document.querySelector(`#${id}`);
    if (!svg) return;
    const filter = document.createElementNS("http://www.w3.org/2000/svg", "filter");
    filter.setAttribute("id", "dropShadow");
    /*filter.setAttribute("x", "0");
    filter.setAttribute("y", "0");*/
    filter.setAttribute("width", "200%");
    filter.setAttribute("height", "200%");
    const offset = document.createElementNS("http://www.w3.org/2000/svg", "feOffset");
    offset.setAttribute("result", "offOut");
    offset.setAttribute("in", "SourceGraphic");
    offset.setAttribute("dx", "0");
    offset.setAttribute("dy", "4");
    const blur = document.createElementNS("http://www.w3.org/2000/svg", "feGaussianBlur");
    blur.setAttribute("result", "blurOut");
    blur.setAttribute("in", "offOut");
    blur.setAttribute("stdDeviation", "4");
    const blend = document.createElementNS("http://www.w3.org/2000/svg", "feBlend");
    blend.setAttribute("in", "SourceGraphic");
    blend.setAttribute("in2", "blurOut");
    blend.setAttribute("mode", "normal");

    filter.appendChild(offset);
    filter.appendChild(blur);
    filter.appendChild(blend);

    svg.childNodes[0].appendChild(filter);
    const lines = document.querySelectorAll(`#${id} .recharts-line`);
    lines.forEach(line => {
      line.style.filter = `url(#dropShadow)`;
    });
  }, [loaded, id]);
  if (!data) return;
  return (
    <ResponsiveContainer width="100%">
      <LineChart data={data} id={id} className="LineChartContainer">
        <CartesianGrid vertical={false} />
        <XAxis dataKey="name" height={13} />
        {withTooltip && (
          <Tooltip
            offset={5}
            wrapperStyle={tooltipContainerStyles}
            contentStyle={tooltipContentStyles}
            labelStyle={tooltipLabelStyles}
            itemStyle={tooltipItemStyle}
            allowEscapeViewBox={{x: true, y: true}}
            formatter={(value, name) => [formatValues(value, name)]}
          />
        )}
        <YAxis
          yAxisId="left"
          domain={["dataMin", "auto"]}
          tickFormatter={value =>
            !!formatValues ? formatValues(mapValue(value), keys[0]) : mapValue(value)
          }
          axisLine={false}
          width={40}
        />
        <Line
          id="left"
          yAxisId="left"
          type="monotone"
          dataKey={keys[0]}
          stroke={colors[0]}
          dot={withDots}
          activeDot={props => <CustomDot color={colors[0]} {...props} />}
          strokeWidth={1.5}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          domain={["dataMin", "auto"]}
          tickFormatter={value =>
            !!formatValues
              ? formatValues(mapValue(decimal2digits(value)), keys[1])
              : mapValue(decimal2digits(value))
          }
          axisLine={false}
          width={40}
        />
        <Line
          id="right"
          yAxisId="right"
          type="monotone"
          dataKey={keys[1]}
          stroke={colors[1]}
          dot={withDots}
          activeDot={props => <CustomDot color={colors[1]} {...props} />}
          strokeWidth={1.5}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

Chart.propTypes = {
  id: PropsTypes.string,
  data: PropsTypes.arrayOf(PropsTypes.object),
};

export default Chart;
