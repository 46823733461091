import {
  LOGIN_PENDING,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  DEVICE_FAILED,
  DEVICE_SUCCESS,
  REFRESH_TOKEN,
  LOGOUT,
  SIGN_UP_PENDING,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILED,
  USER_INFO_FAILED,
  USER_INFO_PENDING,
  USER_INFO_SUCCESS,
  USER_AVATAR_SUCCESS,
  USER_AVATAR_FAILED,
  POPULATE_TOKEN_DATA,
  FETCH_USER_PROFILE_PENDING,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_FAILED,
  UPDATE_USER_PROFILE_PENDING,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILED,
  GENERATE_PASSPHRASE_PENDING,
  GENERATE_PASSPHRASE_SUCCESS,
  GENERATE_PASSPHRASE_FAILED,
  COUNTRIES_DOC_SUCCESS,
  COUNTRIES_DOC_FAILED,
} from "./types";
import {
  authAPILogin,
  authAPISignUp,
  authAPIGetUserInfo,
  authAPIRefreshToken,
  authAPIGetUserProfile,
  authAPIUpdateUserProfile,
  authAPIGeneratePassPhrase,
  authAPIChangePsw,
  authAPIShareMyContact,
  authAPIGetDevices,
  authAPIUpdateDevices,
  authAPIUploadAvatar,
  authAPIGetAvatar,
  authAPIcheckAccountValidate,
  authAPIUploadUserDrivingLicence,
  getAPICountriesWithDocs,
} from "shared/api/authApi";
import {getTokenData, isAuthLoading, selectUserProfile} from "../selectors";
import {handleError} from "shared/components/ErrorModal/actions";
import {fetchOnwalletCard} from "../../cardService/actions";
// import {Form} from "formik";

let refreshStack = [];
let isRefreshStart = false;

export const populateToken = () => ({
  type: POPULATE_TOKEN_DATA,
});

/**
 *
 * @param error
 * @returns {function(...[*]=)}
 */
const alertError = error => dispatch => {
  if (error.response && error.response.data && error.response.data.error_description) {
    dispatch(
      handleError({
        description: error.response.data.error_description,
      })
    );
  } else if (
    error.response &&
    error.response.data &&
    error.response.data.errorcode &&
    error.response.data.errors
  ) {
    const errors = error.response.data.errors;
    const errorText = errors[Object.keys(errors)[0]][0];
    dispatch(
      handleError({
        header: error.response.data.errorcode,
        description: errorText,
      })
    );
  } else if (
    error.response &&
    error.response.data &&
    error.response.data.title &&
    error.response.data.detail
  ) {
    dispatch(
      handleError({
        header: error.response.data.title,
        description: error.response.data.detail,
      })
    );
  } else {
    dispatch(
      handleError({
        error: error.response,
        description:
          error.response && error.response.description
            ? error.response.description
            : error.message,
      })
    );
  }
};

/**
 *
 * @param data
 * @returns {function(...[*]=)}
 */
export const signUp = data => dispatch => {
  dispatch({type: SIGN_UP_PENDING});

  authAPISignUp(data)
    .then(res => {
      dispatch({type: SIGN_UP_SUCCESS});
      dispatch(
        login(
          {email: data.email, password: data.password},
          {
            success: () => updateUserProfile(data),
          }
        )
      );
    })
    .catch(error => {
      dispatch(alertError(error));
      dispatch({type: SIGN_UP_FAILED});
    });
};

/**
 *
 * @param data
 * @param opt
 * @returns {function(...[*]=)}
 */
export const login = (data, opt) => dispatch => {
  dispatch({type: LOGIN_PENDING});
  authAPILogin(data)
    .then(res => {
      dispatch({type: LOGIN_SUCCESS, payload: res.data});
      dispatch(getUserInfo());
      dispatch(getUserProfile());
      dispatch(updateDevices());
      if (opt && opt.success) {
        dispatch(opt.success());
      }
    })
    .catch(error => {
      dispatch(alertError(error));
      dispatch({type: LOGIN_FAILED});
    });
};

/**
 *
 * @param action
 * @param params
 * @returns {function(...[*]=)}
 */
export const refreshToken = (action, ...params) => (dispatch, getState) => {
  const tokenData = getTokenData(getState());
  // Error handler
  if (!tokenData) return;

  const option = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };

  const loading = isAuthLoading(getState());
  if (loading) {
    refreshStack.push({
      action,
      params,
    });
    return;
  }
  dispatch({type: REFRESH_TOKEN});
  dispatch(populateToken());

  // Error handler
  if (!tokenData || isRefreshStart) return;
  isRefreshStart = true;

  authAPIRefreshToken(tokenData.refresh_token, option)
    .then(res => {
      dispatch({type: LOGIN_SUCCESS, payload: res.data});
      dispatch(action(...params));
      refreshStack.forEach(item => {
        dispatch(item.action(...item.params));
      });
      refreshStack = [];
      isRefreshStart = false;
    })
    .catch(error => {
      dispatch(alertError(error));
      dispatch({type: LOGOUT});
    });
};

/**
 *
 * @returns {function(...[*]=)}
 */
export const logout = () => dispatch => {
  dispatch({type: LOGOUT});
};

/**
 *
 * @returns {function(...[*]=)}
 */
export const getUserInfo = () => (dispatch, getState) => {
  const tokenData = getTokenData(getState());
  if (!tokenData) {
    dispatch({type: USER_INFO_FAILED});
    return;
  }
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };
  dispatch({type: USER_INFO_PENDING});
  authAPIGetUserInfo(option)
    .then(res => {
      dispatch({type: USER_INFO_SUCCESS, payload: res.data});
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error, getUserInfo));
      dispatch({type: USER_INFO_FAILED});
    });
};

/**
 *
 * @param error
 * @param action
 * @param params
 * @returns {function(...[*]=)}
 */
export const handleUnAuthorizationError = (error, action, ...params) => dispatch => {
  if (error.response && error.response.status === 401) {
    dispatch(refreshToken(action, ...params));
  } else {
    dispatch(alertError(error));
  }
};

/**
 *
 * @returns {function(...[*]=)}
 */
export const getUserProfile = () => (dispatch, getState) => {
  const tokenData = getTokenData(getState());
  // Error handler
  if (!tokenData) return;

  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };

  dispatch({type: FETCH_USER_PROFILE_PENDING});

  authAPIGetUserProfile(option)
    .then(res => {
      dispatch({type: FETCH_USER_PROFILE_SUCCESS, payload: res.data});
      dispatch(fetchOnwalletCard());
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error, getUserProfile));
      dispatch({type: FETCH_USER_PROFILE_FAILED});
    });
};

/**
 *
 * @param data
 * @returns {function(...[*]=)}
 */
export const updateUserProfile = data => (dispatch, getState) => {
  const tokenData = getTokenData(getState());

  // Error handler
  if (!tokenData) return;

  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };

  let userProfile = selectUserProfile(getState());
  userProfile = userProfile || {};

  dispatch({type: UPDATE_USER_PROFILE_PENDING});
  authAPIUpdateUserProfile({...userProfile, ...data}, option)
    .then(res => {
      dispatch({type: UPDATE_USER_PROFILE_SUCCESS, payload: res.data});
      dispatch(getUserProfile());

      dispatch(uploadDrivingLicence(data.billFile));
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error, getUserProfile, data));
      dispatch({type: UPDATE_USER_PROFILE_FAILED});
    });
};

/**
 *
 * @returns {function(...[*]=)}
 */
export const getPassPhrase = () => (dispatch, getState) => {
  dispatch({type: GENERATE_PASSPHRASE_PENDING});
  authAPIGeneratePassPhrase()
    .then(res => {
      dispatch({type: GENERATE_PASSPHRASE_SUCCESS, payload: res.data});
    })
    .catch(error => {
      dispatch({type: GENERATE_PASSPHRASE_FAILED});
    });
};

/**
 *
 * @param data
 * @returns {function(...[*]=)}
 */
export const changeUserPsw = data => (dispatch, getState) => {
  const tokenData = getTokenData(getState());
  if (!tokenData) return;
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };
  let userProfile = selectUserProfile(getState());
  userProfile = userProfile || {};
  dispatch({type: UPDATE_USER_PROFILE_PENDING});
  authAPIChangePsw({...userProfile, ...data}, option)
    .then(res => {
      dispatch({type: UPDATE_USER_PROFILE_SUCCESS, payload: res.data});
      dispatch(getUserProfile());
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error, getUserProfile, data));
      dispatch({type: UPDATE_USER_PROFILE_FAILED});
    });
};

/**
 *
 * @param data
 * @returns {function(...[*]=)}
 */
export const uploadDrivingLicence = data => (dispatch, getState) => {
  if (!data) return false;

  const tokenData = getTokenData(getState());
  if (!tokenData) return;
  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  dispatch({type: UPDATE_USER_PROFILE_PENDING});

  data.forEach((file, index) => {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("DocumentType", "driving_licence");
    formData.append("Side", "front");

    authAPIUploadUserDrivingLicence(formData, option)
      .then(res => {
        dispatch({type: UPDATE_USER_PROFILE_SUCCESS, payload: res.data});
        dispatch(accountCheckValidate());
        dispatch(getUserProfile());
      })
      .catch(error => {
        dispatch(handleUnAuthorizationError(error, getUserProfile, data));
        dispatch({type: UPDATE_USER_PROFILE_FAILED});
      });
  });
};

/**
 *
 * @returns {function(...[*]=)}
 */
export const accountCheckValidate = () => (dispatch, getState) => {
  const tokenData = getTokenData(getState());
  if (!tokenData) return;
  const option = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };

  dispatch({type: UPDATE_USER_PROFILE_PENDING});

  authAPIcheckAccountValidate(option)
    .then(res => {
      dispatch({type: UPDATE_USER_PROFILE_SUCCESS, payload: res.data});
      dispatch(authAPIRefreshToken(tokenData.refresh_token, option));
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error, getUserProfile));
      dispatch({type: UPDATE_USER_PROFILE_FAILED});
    });
};

/**
 *
 * @param onwalletAccount
 * @returns {function(...[*]=)}
 */
export const getUserAvatar = onwalletAccount => (dispatch, getState) => {
  const tokenData = getTokenData(getState());

  // Error handler
  if (!tokenData) return;

  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
    responseType: "blob",
  };

  authAPIGetAvatar(onwalletAccount, option)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: USER_AVATAR_SUCCESS,
          payload: res.data,
        });
      }
    })
    .catch(error => {
      dispatch({type: USER_AVATAR_FAILED});
      dispatch(handleUnAuthorizationError(error));
    });
};

/**
 *
 * @param image
 * @returns {function(...[*]=)}
 */
export const uploadUserAvatar = (image, onwalletAccount) => (dispatch, getState) => {
  const tokenData = getTokenData(getState());

  // Error handler
  if (!tokenData) return;

  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const data = new FormData();
  data.append("file", image);

  authAPIUploadAvatar(data, option)
    .then(() => dispatch(getUserAvatar(onwalletAccount.account)))
    .catch(error => {
      dispatch(handleUnAuthorizationError(error, data));
    });
};

/**
 *
 * @param data
 * @returns {function(...[*]=)}
 */
export const shareMyContact = data => (dispatch, getState) => {
  const tokenData = getTokenData(getState());

  // Error handler
  if (!tokenData) return;

  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };

  authAPIShareMyContact(data, option)
    .then()
    .catch(error => {
      dispatch(handleUnAuthorizationError(error, data));
    });
};

/**
 *
 * @returns {function(...[*]=)}
 */
export const getDevices = () => (dispatch, getState) => {
  const tokenData = getTokenData(getState());

  // Error handler
  if (!tokenData) return;

  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };

  authAPIGetDevices(option)
    .then(res => {
      // Parse JSON data
      res.data.map(device => {
        if (!!device.info) device.info = JSON.parse(device.info);
        if (!!device.geo) device.geo = JSON.parse(device.geo);

        return device;
      });

      dispatch({type: DEVICE_SUCCESS, payload: res.data});
    })
    .catch(error => {
      dispatch({type: DEVICE_FAILED});
      dispatch(handleUnAuthorizationError(error));
    });
};

/**
 *
 * @returns {function(...[*]=)}
 */
export const updateDevices = () => (dispatch, getState) => {
  const tokenData = getTokenData(getState());

  // Error handler
  if (!tokenData) return;

  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };

  authAPIUpdateDevices(option)
    .then(res => {
      dispatch({type: DEVICE_SUCCESS, payload: res.data});
    })
    .catch(error => {
      dispatch({type: DEVICE_FAILED});
      dispatch(handleUnAuthorizationError(error));
    });
};

export const getOnfidoCountriesWithDoc = () => (dispatch, getState) => {
  getAPICountriesWithDocs()
    .then(res => {
      dispatch({type: COUNTRIES_DOC_SUCCESS, payload: res.data});
    })
    .catch(error => {
      dispatch({type: COUNTRIES_DOC_FAILED});
      dispatch(handleUnAuthorizationError(error));
    });
};
