import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {storeUserInfo} from "shared/auth/selectors";

const WebsocketTestPage = ({userInfo}) => {
  // uncomment for send message
  // const [ws, setWS] = useState(null);
  const [connected, setConnected] = useState(false);
  const [value, setValue] = useState("");
  const handleMessage = event => {
    // parse if json
    const parsedData = JSON.parse(event.data);
    // set Value
    setValue(parsedData);
  };
  // send message handler
  // const sendMessage = message => {
  //   ws.send(JSON.stringify(message));
  // };

  useEffect(() => {
    if (userInfo) {
      // here link to websocket
      // you can use baseAPIUrl config from shared/appUrls.js
      // Example: `${baseAPIUrl}/websoket-route-here` (copy & paste)
      const ws = new WebSocket("wss://echo.websocket.org");
      ws.onopen = () => {
        setConnected(true);
      };
      ws.onerror = () => {
        alert("Websocket Error");
      };
      ws.onclose = () => {
        setConnected(false);
      };
      ws.addEventListener("message", handleMessage);
      // uncomment for send message
      // setWS(ws);
    }
  }, [userInfo]);
  return (
    <div className="main-content">
      <div>Connected: {connected ? "connected" : "not connected"}</div>
      <div>Value: {`${value}`}</div>
    </div>
  );
};

const mapStateToProps = store => ({
  userInfo: storeUserInfo(store),
});

const dispatchToProps = {};

export default connect(mapStateToProps, dispatchToProps)(WebsocketTestPage);
