import {
  FETCH_CURRENCY_RATES_FAILED,
  FETCH_CURRENCY_RATES_PENDING,
  FETCH_CURRENCY_RATES_SUCCESS,
  CURRENCY_RATE_SUCCESS,
  CURRENCY_RATE_PENDING,
  CURRENCY_RATE_FAILED,
  CURRENCY_RATE_TABLE_SUCCESS,
  CURRENCY_RATE_TABLE_PENDING,
  CURRENCY_RATE_TABLE_FAILED,
} from "./types";
import {
  getAllCurrencyRates,
  getCurrencyRate,
  getCurrencyRatesTable,
} from "../../api/ratesApi";
import {getTokenData} from "../../auth/selectors";
import {handleUnAuthorizationError} from "../../auth/actions";

const getOption = getState => {
  const tokenData = getTokenData(getState());

  // Err handler
  if (!tokenData) return;

  const option = {
    headers: {
      Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
    },
  };

  return option;
};

export const fetchCurrenciesRates = () => (dispatch, getState) => {
  dispatch({type: FETCH_CURRENCY_RATES_PENDING});

  return getAllCurrencyRates(getOption(getState))
    .then(res => {
      dispatch({type: FETCH_CURRENCY_RATES_SUCCESS, payload: res.data});
    })
    .catch(error => {
      dispatch(handleUnAuthorizationError(error, fetchCurrenciesRates));
      dispatch({type: FETCH_CURRENCY_RATES_FAILED});
    });
};

export const getCurrencyRateForChart = data => async (dispatch, getState) => {
  dispatch({type: CURRENCY_RATE_PENDING});

  try {
    await getCurrencyRate(getOption(getState), data).then(res => {
      dispatch({type: CURRENCY_RATE_SUCCESS, payload: res.data});
    });
  } catch (error) {
    dispatch(handleUnAuthorizationError(error, getCurrencyRateForChart));
    dispatch({type: CURRENCY_RATE_FAILED});
  }
};

export const getCurrencyRateForTable = data => async (dispatch, getState) => {
  dispatch({type: CURRENCY_RATE_TABLE_PENDING});

  try {
    await getCurrencyRatesTable(getOption(getState), data).then(res => {
      dispatch({type: CURRENCY_RATE_TABLE_SUCCESS, payload: res.data});
    });
  } catch (error) {
    dispatch(handleUnAuthorizationError(error, getCurrencyRateForChart));
    dispatch({type: CURRENCY_RATE_TABLE_FAILED});
  }
};
