import {defineMessages} from "react-intl";

const scope = "PersonalDetails";

const messages = defineMessages({
  personalDetails: {
    id: `${scope}.personalDetails`,
    defaultMessage: "Personal Details",
  },
  changePersonalDetails: {
    id: `${scope}.changePersonalDetails`,
    defaultMessage: "Changing of Personal Details",
  },
  messageText: {
    id: `${scope}.messageText`,
    defaultMessage:
      "You can update your personal details. Note that changing Legal Name or Address will require verification process.",
  },
  change: {
    id: `${scope}.change`,
    defaultMessage: "change",
  },
  changeCompanyName: {
    id: `${scope}.changeCompanyName`,
    defaultMessage: "Change Company Name",
  },
  cellPhoneNumber: {
    id: `${scope}.cellPhoneNumber`,
    defaultMessage: "Phone number",
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: "E-mail",
  },
  legalAddress: {
    id: `${scope}.legalAddress`,
    defaultMessage: "Legal address",
  },
  companyName: {
    id: `${scope}.companyName`,
    defaultMessage: "Company name",
  },
  changeName: {
    id: `${scope}.changeName`,
    defaultMessage: "  Change Name",
  },
  changeCellPhone: {
    id: `${scope}.changeCellPhone`,
    defaultMessage: "Change Cell Phone Number",
  },
  changeAddress: {
    id: `${scope}.changeAddress`,
    defaultMessage: "Change Address",
  },
  currentLegalName: {
    id: `${scope}.currentLegalName`,
    defaultMessage: "Current Legal Name",
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: "Cancel",
  },
  applyToChange: {
    id: `${scope}.applyToChange`,
    defaultMessage: "Apply To Change",
  },
  firstName: {
    id: `${scope}.firstName`,
    defaultMessage: "First Name",
  },
  lastName: {
    id: `${scope}.lastName`,
    defaultMessage: "Last Name",
  },
  newName: {
    id: `${scope}.newName`,
    defaultMessage: "New Name",
  },
  newCompanyName: {
    id: `${scope}.newCompanyName`,
    defaultMessage: "New Company Name",
  },
  inOrderToConfirm: {
    id: `${scope}.inOrderToConfirm`,
    defaultMessage:
      "  In order to confirm change of your legal name, please attach copies of documents confirming it. We will proceed them as soon as possible.",
  },
  newAddress: {
    id: `${scope}.newAddress`,
    defaultMessage: "New Address",
  },
  toConfirmAddress: {
    id: `${scope}.toConfirmAddress`,
    defaultMessage:
      "To confirm this is your address, please, attach last bill of electricity provider for this address.",
  },
  attachBill: {
    id: `${scope}.attachBill`,
    defaultMessage: "Attach bill",
  },
  country: {
    id: `${scope}.country`,
    defaultMessage: "Country",
  },
  zip: {
    id: `${scope}.zip`,
    defaultMessage: "Postal / Zip Code",
  },
  street: {
    id: `${scope}.street`,
    defaultMessage: "Street",
  },
  apartment: {
    id: `${scope}.apartment`,
    defaultMessage: "Apartment, Unit, ets",
  },
  region: {
    id: `${scope}.region`,
    defaultMessage: "State/Province/Region",
  },
  city: {
    id: `${scope}.city`,
    defaultMessage: "City",
  },
  currentAddress: {
    id: `${scope}.currentLegalAddress`,
    defaultMessage: "Current Address",
  },
  phoneNumber: {
    id: `${scope}.phoneNumber`,
    defaultMessage: "Phone Number",
  },
  weWillSendOnThisNumberSMS: {
    id: `${scope}.weWillSendOnThisNumberSMS`,
    defaultMessage: "We will send on this number SMS with confirmation pass code.",
  },
  weSentOnThisPhoneSMS: {
    id: `${scope}.weSentOnThisPhoneSMS`,
    defaultMessage:
      "We sent on this phone SMS with confirmation pass code. Enter confirmation pass code in the fields below.",
  },
  sendCode: {
    id: `${scope}.sendCode`,
    defaultMessage: "Send code",
  },
  iHaveIssue: {
    id: `${scope}.iHaveIssue`,
    defaultMessage: "I have issue with receiving of this pass code",
  },
  sendNewCode: {
    id: `${scope}.sendNewCode`,
    defaultMessage: "send new code",
  },
  currentLegalPhone: {
    id: `${scope}.currentLegalPhone`,
    defaultMessage: "Current Legal Phone",
  },
  newPhone: {
    id: `${scope}.newPhone`,
    defaultMessage: "New Phone",
  },
  yourRequestToChangeName: {
    id: `${scope}.yourRequestToChangeName`,
    defaultMessage:
      "Your request to change your legal name is processing now. We will inform you on the result.",
  },
  yourRequestToChangeAddress: {
    id: `${scope}.yourRequestToChangeAddress`,
    defaultMessage:
      "Your request to change your legal address is processing now. We will inform you on the result.",
  },
  resetPassword: {
    id: `${scope}.resetPassword`,
    defaultMessage: "Reset Password",
  },
});

export default messages;
