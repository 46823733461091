import React from "react";

import {LineChart, Line, ResponsiveContainer} from "recharts";

const MiniChart = ({chartData, strokeColor, width = "99%"}) => {
  return (
    <ResponsiveContainer width={width}>
      <LineChart data={chartData} margin={{top: 0, right: 0, bottom: 0, left: 0}}>
        <Line
          isAnimationActive={false}
          type="monotone"
          dataKey="value"
          stroke={strokeColor}
          strokeWidth="2"
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default MiniChart;
