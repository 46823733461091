import React from "react";
import "./index.scss";

const TextElement = ({heading, children}) => {
  return (
    <div className="text__container_preview">
      <h3 className="text__container_title">{heading}</h3>
      <div className="text__container_article">{children}</div>
    </div>
  );
};
export default TextElement;
