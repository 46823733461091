/**
 * @author: Seleznyov
 */

import React from "react";
import {FormattedMessage} from "react-intl";
import MetalSupplyDetailsModals from "./components/MetalSupplyDetailsModals";
import Header from "shared/components/Header";

import messages from "./messages";
import "./index.scss";

import config from "shared/config";
import OrderRequests from "./components/OrderRequests";
import AcceptedOrders from "./components/AcceptedOrders";
const logoPage = config.prefixImgUrl + "icon-orders-metalsupply-top.svg";

const MetalSupplyPage = () => {
  return (
    <>
      <Header
        headerTitle={<FormattedMessage {...messages.metalSupplyTitle} />}
        navLinks={[]}
        logoImage={logoPage}
      />

      <div className="metal-supply-page">
        <MetalSupplyDetailsModals />
        <OrderRequests />
        <AcceptedOrders />
      </div>
    </>
  );
};

export default MetalSupplyPage;
