/**
 * @author: Seleznyov
 */

// import axios from "axios";
// import {getTokenData} from "shared/auth/selectors";

import {SET_DATA_FILTER, CLEAN_DATA_FILTER} from "./types";

export const setFilterVars = data => (dispatch, getState) => {
  dispatch({
    type: SET_DATA_FILTER,
    payload: data,
  });
  return true;
};

export const resetFilterVars = () => (dispatch, getState) => {
  dispatch({
    type: CLEAN_DATA_FILTER,
    payload: null,
  });
  return true;
};
