import config from "shared/config";
const onwalletIcon = config.prefixImgUrl + "onwallet-icon-short_v1.svg";
const defaultBankIcon = config.prefixImgUrl + "default-bank-icon.svg";
const bankOfAmerica = config.prefixImgUrl + "bankofamerica-logo.svg";
const bankAustria = config.prefixImgUrl + "bankAustria-logo.svg";
const visaIcon = config.prefixImgUrl + "visa-icon.svg";
const mcIcon = config.prefixImgUrl + "mastercard-icon.svg";
const unionPayIcon = config.prefixImgUrl + "unionpay-icon.svg";
const amexIcon = config.prefixImgUrl + "amex-icon.svg";

export const getActiveCard = store => {
  return getAllCards(store).find(
    card => card["last4"] === store.bankCardsData.activeBankCardNumber
  );
};

export const getAllCards = store => {
  return getBankCards(store);
};

export const getBankCards = store => {
  if (!store.bankCardsData || !store.bankCardsData.regularCards) return [];

  // For mockup
  const virtualCard = {
    id: "pm_2c3f441f7bf34b6b9a568a2e2b1d5f80",
    name: "OnWallet Card",
    dateValid: "05/11",
    cvv: 312,
    currency: "usd",
    isDefault: false,
    last4: "5781",
    number: "4485042155055781",
    operator: "OnWallet",
  };

  const checkVirtualCard = store.bankCardsData.regularCards.find(
    card => card.id === virtualCard.id
  );

  if (!checkVirtualCard) {
    store.bankCardsData.regularCards.push(virtualCard);
  }

  return store.bankCardsData.regularCards;
};

export const getOnwalletAccount = store => {
  return store.bankCardsData.onwalletCard;
};

export const getAllRegularCurrency = store => {
  return store.bankCardsData.regularCurrencies;
};

export const getCardIconBank = name => {
  if (name) {
    switch (name.toLowerCase()) {
      case "onwallet":
        return onwalletIcon;
      case "visa":
        return visaIcon;
      case "mastercard":
        return mcIcon;
      case "unionpay":
        return unionPayIcon;
      case "amex":
        return amexIcon;
      default:
        return defaultBankIcon;
    }
  }
};

export const getCardOperatorName = name => {
  switch (name.toLowerCase()) {
    case "onwallet":
      return "OnWallet";
    case "visa":
      return "VISA";
    case "mastercard":
      return "MasterCard";
    case "unionpay":
      return "UnionPay";
    case "amex":
      return "AMEX";
    default:
      return name;
  }
};

export const isRegularCurrenciesFetched = store =>
  store.bankCardsData.isRegularCurrenciesFetched;

export const isCardsLoading = store => store.bankCardsData.isLoading;

export const getBankAccountLogoByName = name => {
  switch (name) {
    case "BankOfAmerica":
      return bankOfAmerica;
    case "BankAustria":
      return bankAustria;
    default:
      return bankOfAmerica;
  }
};

export const getBankAccounts = store => {
  if (!store.bankAccountData || !store.bankAccountData.bankAccounts) return [];
  return store.bankAccountData.bankAccounts;
};
export const isBankAccountLoading = store => store.bankAccountData.isLoading;
export const getActiveBankAccountNumber = store =>
  store.bankAccountData.activeBankAccountNumber;

export const isDefaultCardLoading = store => store.bankAccountData.isDefaultCardLoading;
export const getDefaultCard = store =>
  store.bankAccountData.regularCards.find(card => card.isDefault);
