import {OPEN_RIGHT_MODAL, CLOSE_RIGHT_MODAL} from "./actions";

const initState = {
  show: false,
  variant: null,
  meta: null,
};

const rightModalData = (state = initState, action) => {
  switch (action.type) {
    case OPEN_RIGHT_MODAL:
      return {
        ...state,
        show: true,
        variant: action.payload.variant,
        meta: action.payload.meta,
        savedData: action.payload.savedData,
      };
    case CLOSE_RIGHT_MODAL:
      return {...state, show: false, variant: null, meta: null};
    default:
      return state;
  }
};

export {rightModalData};
