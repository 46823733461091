import React from "react";
import {connect} from "react-redux";
import "./index.scss";
import PropTypes from "prop-types";
import SVG from "react-inlinesvg";
import {Link} from "react-router-dom";
import {ROLE} from "shared/constants";
import {logout} from "shared/auth/actions";
import {userProfileRoles, selectUserProfile} from "shared/auth/selectors";
import messages from "../../../messages";
import {FormattedMessage} from "react-intl";
import {openRightModal} from "shared/components/RightModal/actions";
// import {routes} from "shared/appUrls";

import config from "shared/config";
const linkIcon = config.prefixImgUrl + "link-icon.svg";
const logoutIcon = config.prefixImgUrl + "logout-icon.svg";

const SidebarMenu = ({
  menuTitle,
  items,
  withLogout,
  collapse,
  classSection,
  userRoles,
}) => {
  return (
    <div
      className={
        `SidebarMenuItemsContainer ${collapse ? "collapseMenu" : ""}` +
        (classSection ? ` ${classSection}` : "")
      }
    >
      <div className="SidebarMenuTitle">
        {menuTitle}
        <span className="SidebarBorder"></span>
      </div>
      <ul className="SidebarMenuItemsList">
        {items.map((item, index) => {
          return (
            <li
              key={index}
              className={`${
                item.isActive() ? "SidebarMenuItemActive" : ""
              } SidebarMenuItem`}
            >
              <Link to={item.link} className={"SidebarMenuItemLink"}>
                <SVG
                  className={`${
                    item.active ? "SidebarMenuIconActive" : ""
                  } SidebarMenuIcon`}
                  src={item.icon}
                />
                <span className="SidebarMenuText">{item.text}</span>
                {!!item.notificationsCount && (
                  <span className="NotificationsCount">{item.notificationsCount}</span>
                )}
              </Link>
            </li>
          );
        })}
      </ul>
      {withLogout && <LogoutButtons userRoles={userRoles} />}
    </div>
  );
};

const LogoutButtons = connect(null, {logout, openRightModal})(
  ({logout, openRightModal, userRoles}) => {
    return (
      <>
        {(!!userRoles &&
          !!userRoles.length &&
          (userRoles.includes(ROLE.GOLD_PROVIDER) ||
            userRoles.includes(ROLE.BANK) ||
            userRoles.includes(ROLE.MASTER_ACCOUNT))) ||
        (!!userRoles && !userRoles.length) ? (
          <div className="LogoutButtons">
            <div className="button logout" onClick={() => logout()}>
              <SVG className={`icon`} src={logoutIcon} />
              <p className="button_text">
                <FormattedMessage {...messages.logOut} />
              </p>
            </div>
          </div>
        ) : (
          <div className="LogoutButtons">
            <div className="button" onClick={() => openRightModal("referralLink")}>
              <SVG className="icon" alt="icon" src={linkIcon} />
              <p className={"button_text"}>
                <FormattedMessage {...messages.referal_link} />
              </p>
            </div>
            <div className="button logout" onClick={() => logout()}>
              <SVG className={`icon`} src={logoutIcon} />
              <p className={"button_text"}>
                <FormattedMessage {...messages.logOut} />
              </p>
            </div>
          </div>
        )}
      </>
    );
  }
);

SidebarMenu.propTypes = {
  menuTitle: PropTypes.object,
  items: PropTypes.array,
};

const mapStateToProps = store => ({
  userProfile: selectUserProfile(store),
  userRoles: userProfileRoles(store),
});

export default connect(mapStateToProps)(SidebarMenu);
