import React, {useState, useEffect} from "react";
import {connect} from "react-redux";

import Header from "shared/components/Header";

import messages from "./messages";

import "./index.scss";
import {routes} from "shared/appUrls";

import {CurrencyOption, CurrencyOptionSelected} from "./CurrencyOption";
import {PairOption, PairOptionSelected} from "./PairOption";
import Select from "shared/components/Select";
import {getCurrenciesCodes, getPairs} from "shared/tradeService/selectors";

import {FormattedMessage} from "react-intl";

import config from "shared/config";
import Orders from "./components/Orders";
import TradeHistory from "./components/TradeHistory";

import OpenOrders from "./components/OpenOrders";
import NewOrder from "./components/NewOrder";
import OrderHistory from "./components/OrderHistory";
import ChartComponent from "./components/Graph";
import {selectMarket, SelectedPair} from "shared/tradeService/actions";

import SignalR from "shared/socket/signalRCl";
import {ReactComponent as OrdersListIcon} from "images/orders-list.svg";

const TradingIcon = config.prefixImgUrl + "trading-icon.svg";

const Trading = ({currencies, pairs, selectMarket, market, SelectedPair}) => {
  const navLinks = [
    {
      text: <FormattedMessage {...messages.ordersList} />,
      icon: className => <OrdersListIcon className={className} />,
      link: routes.orderlist,
    },
  ];
  const [selectedPair, setSelectedPair] = useState(pairs[0]);
  const [tradePairs, setTradePairs] = useState(pairs);

  useEffect(() => {
    setSelectedPair({id: 1, code: `${market.code}/ USDT`});
    setTradePairs([
      {id: 1, code: `${market.code}/ USDT`},
      // {id: 2, code: `${market.code}/ USD`},
    ]);
  }, [market]);

  return (
    <>
      <SignalR />
      <Header
        headerTitle={<FormattedMessage {...messages.header} />}
        navLinks={navLinks}
        logoImage={TradingIcon}
      >
        <Select
          style={{marginLeft: "48px"}}
          className="CurrencySelect"
          height={64}
          value={market}
          renderValue={value =>
            !!value ? (
              <CurrencyOptionSelected className="no-padding" currency={market} />
            ) : (
              ""
            )
          }
          onChange={e => {
            //setSelectedValue(e.target.value);
            selectMarket(e.target.value);
          }}
        >
          {currencies.map((currencyItem, index) => (
            <CurrencyOption key={index} currency={currencyItem} value={currencyItem} />
          ))}
        </Select>
        <Select
          style={{marginLeft: "4px"}}
          className="CurrencySelect"
          height={64}
          value={selectedPair}
          renderValue={value =>
            !!value ? (
              <PairOptionSelected
                className="no-padding"
                pair={selectedPair}
                value={selectedPair}
              />
            ) : (
              ""
            )
          }
          onChange={e => {
            setSelectedPair(e.target.value);
            SelectedPair(e.target.value);
          }}
        >
          {tradePairs.map((pairItem, index) => (
            <PairOption key={index} pair={pairItem} value={pairItem} />
          ))}
        </Select>
      </Header>
      <div className="main-trading-page">
        <div className="graph">
          <ChartComponent />
        </div>

        <div className="orders">
          <Orders />
        </div>
        <div className="trade-history">
          <TradeHistory />
        </div>

        <div className="right-column">
          <div className="new-orders">
            <NewOrder />
          </div>
          <div className="open-orders">
            <OpenOrders />
          </div>
          <div className="my-twf-order-history">
            <OrderHistory />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = store => ({
  currencies: getCurrenciesCodes(store),
  pairs: getPairs(store),
  market: store.marketData.market,
});

const dispatchToProps = {
  execute: "TRADING_EXECUTE_SUCCESS",
  selectMarket,
  SelectedPair,
};
export default connect(mapStateToProps, dispatchToProps)(Trading);
