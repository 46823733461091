import React from "react";
import "../../index.scss";
import {Formik, Field, Form} from "formik";
import * as Yup from "yup";
import {FormattedMessage} from "react-intl";
import messages from "../../messages";
import Input from "shared/components/Input";
import LinkButton from "shared/components/Button/LinkButton";
import {routes} from "shared/appUrls";
import PasswordInput from "shared/components/PasswordInput";

const initialValues = {
  password: "",
  confirmPassword: "",
};

const RecoveryWithEmailSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(
      "^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{8,}$",
      "Password must contain uppercase and lowercase chars and digits"
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Password not equals")
    .required("Confirm password is required"),
});

const ResetPasswordForm = () => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={RecoveryWithEmailSchema}
      onSubmit={values => {}}
    >
      {({errors, touched, values}) => (
        <Form className="recoveryForm">
          <p className="text">
            <FormattedMessage {...messages.enterNewPassword} />
          </p>
          <Field
            name="password"
            render={({field}) => (
              <div className="inputContainer">
                <label className="label">
                  <FormattedMessage {...messages.newPassword} />
                </label>
                <PasswordInput error={touched.password && errors.password} {...field} />
              </div>
            )}
          />
          <p className="passwordMessage">
            <FormattedMessage {...messages.useBothUppercase} />
          </p>
          <Field
            name="confirmPassword"
            render={({field}) => (
              <div className="inputContainer">
                <label className="label">
                  <FormattedMessage {...messages.confirmNewPassword} />
                </label>
                <Input
                  type="password"
                  error={touched.confirmPassword && errors.confirmPassword}
                  {...field}
                  power="bad"
                />
              </div>
            )}
          />
          <div className="buttonsContainer">
            <LinkButton text={messages.cancel} isSecondary="true" link={routes.login} />
            <LinkButton
              disabled={
                !values.password ||
                !values.confirmPassword ||
                errors.password ||
                errors.confirmPassword
              }
              link={routes.login}
              text={messages.saveNewPassword}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
