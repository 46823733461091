import React, {useState, useEffect} from "react";
import axios from "axios";
// Components
import CustomersTableItem from "./item";
// import PropTypes from "prop-types";
// Styles
import "../index.scss";

// Mock data (TODO normal data)
const wallets = [
  {
    id: "DS32...wpl3g",
    name: "Bitcoin",
    type: "crypto wallet",
    currency: "BTC",
    balance: "4505.032 BTC",
    status: "normal",
  },
];

const cards = [
  {
    id: "123456789",
    name: "OnWallet",
    type: "wallet",
    currency: "USD",
    balance: "1000 USD",
    status: "normal",
  },
  {
    id: "**** 2100",
    name: "mastercard",
    type: "card",
    currency: "USD",
    balance: "n/a",
    status: "normal",
  },
  {
    id: "**** 5360",
    name: "visa",
    type: "card",
    currency: "USD",
    balance: "n/a",
    status: "normal",
  },
];

const CustomersTableBody = ({searchValue, coinCurrency}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    mockupContacts();
  }, []);

  const mockupContacts = () => {
    axios
      .get("https://randomuser.me/api/?results=10")
      .then(res => {
        setData(res.data.results);
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <div className="customers-table-body">
      {!!data.length &&
        data.map((item, index) => (
          <CustomersTableItem item={item} wallets={wallets} cards={cards} key={index} />
        ))}
    </div>
  );
};

// Types
// CustomersTableBody.propTypes = {};

export default CustomersTableBody;
