import React, {useState, useEffect, Fragment} from "react";
import {connect} from "react-redux";
import MiniChart from "../MiniChart";
import {getCurrencyRateForTable} from "shared/ratesService/actions/index.js";
// Selectors
import {getCurrencyRatesTable} from "shared/ratesService/selectors";
import {
  getCryptoIconBySymbol,
  getCoinNameByCurrency,
} from "shared/walletService/selectors";
// Components
import MoreLessButton from "shared/components/MoreLessButton";
import Loader from "shared/components/Loader";
// Styles
import "./index.scss";

const CryptoInfoTable = ({
  getCurrencyRateForTable,
  currencyRates,
  getCoinNameByCurrency,
}) => {
  const currencyList = ["BTC", "ETH", "ZEC", "XRP", "LTC", "XMR"];
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [currencyTable, setCurrencyTable] = useState([]);

  // Hook for get currency for table
  useEffect(() => {
    if (isLoading) {
      getCurrencyRateForTable(currencyList);
      setLoading(false);
    }
  }, [currencyList, getCurrencyRateForTable, isLoading]);

  // Hook for show more currency
  useEffect(() => {
    if (!!currencyRates)
      setCurrencyTable(
        isExpanded ? currencyRates.currency : currencyRates.currency.slice(0, 5)
      );
  }, [currencyRates, isExpanded]);

  const formatNumber = data => {
    // Convert to number
    const number = +data;
    return number.toLocaleString();
  };

  return (
    <>
      <div className="crypto-info-table">
        {/* Header Table */}
        <div className="row-style head"></div>
        <div className="c1 head">#</div>
        <div className="c2 head">Name</div>
        <div className="c4 head">Market Cap</div>
        <div className="c5 head">Price</div>
        <div className="c6 head">Change (24h)</div>
        <div className="empty-space head"></div>
        <div className="c7 head">Price Graph (7d)</div>

        {/* Body Table */}
        {!!currencyRates && !currencyRates.isLoading ? (
          <>
            {currencyTable.map((currency, index) => {
              index += 2;

              return (
                <Fragment key={index}>
                  <div className="row-style" style={{"--row-index": index}}></div>
                  <div className="c1" style={{"--row-index": index}}>
                    {index - 1}
                  </div>
                  <div className="c2" style={{"--row-index": index}}>
                    <img alt={currency.coin} src={getCryptoIconBySymbol(currency.coin)} />
                    {getCoinNameByCurrency(currency.coin)} ({currency.coin})
                  </div>
                  <div className="c4" style={{"--row-index": index}}>
                    {`$${formatNumber(currency.marketCap.toFixed(0))}`}
                  </div>
                  <div className="c5" style={{"--row-index": index}}>
                    {`$${currency.price.toFixed(2)}`}
                  </div>
                  <div
                    className={`c6 ${
                      currency.changeOneDay <= 0 ? "negative" : "positive"
                    }`}
                    style={{"--row-index": index}}
                  >
                    {currency.changeOneDay <= 0
                      ? parseFloat(currency.changeOneDay).toFixed(2)
                      : `+${parseFloat(currency.changeOneDay).toFixed(2)}`}
                  </div>
                  <div className="empty-space" style={{"--row-index": index}}></div>
                  <div className="mini-chart" style={{"--row-index": index}}>
                    <MiniChart strokeColor="#0080FF" chartData={currency.chartData} />
                  </div>
                </Fragment>
              );
            })}
          </>
        ) : (
          <>
            <div className="crypto-table-loader">
              <Loader small />
            </div>
            <div className="empty-block"></div>
          </>
        )}
      </div>
      <div className="more-less-button-container">
        <MoreLessButton
          moreLabel="More Markets"
          lessLabel="Less Markets"
          isExpanded={isExpanded}
          onClick={() => setIsExpanded(value => !value)}
        />
      </div>
    </>
  );
};

// Redux
const mapStateToProps = store => ({
  currencyRates: getCurrencyRatesTable(store),
  getCoinNameByCurrency: currency => getCoinNameByCurrency(currency),
});

const mapDispatchToProps = {
  getCurrencyRateForTable,
};

export default connect(mapStateToProps, mapDispatchToProps)(CryptoInfoTable);
