import React, {Component} from "react";
import {connect} from "react-redux";
import {getDevices} from "shared/auth/actions";
import {getUserDevices} from "shared/auth/selectors";
import messages from "../../messages";
import {FormattedMessage} from "react-intl";
import TrustedOneDevice from "../TrustedOneDevice";

import "../../index.scss";

class TrustedDevices extends Component {
  componentDidMount() {
    // Create request for customer if props forAdmin === TRUE
    this.props.getDevices();
  }

  render() {
    return (
      <div
        className={`settings__block view ${
          this.props.className ? this.props.className : ""
        }`}
      >
        <div className={"wrapperMain"}>
          {!this.props.forAdmin && (
            <div>
              <div className="wrapper_header">
                <div className={"header"}>
                  <FormattedMessage {...messages.trusted_devices} />
                </div>
              </div>
              <div className={"description font-16-regular"}>
                <FormattedMessage {...messages.decription} />
              </div>
            </div>
          )}
          <div className={"trusted_devices"}>
            {this.props.userDevices &&
              this.props.userDevices.map((device, index) => {
                if (!!device.info) {
                  return (
                    <TrustedOneDevice
                      key={index}
                      data={device}
                      forAdmin={this.props.forAdmin}
                    />
                  );
                }
                return null;
              })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  userDevices: getUserDevices(store),
});

function mapDispatchToProps(dispatch) {
  return {
    getDevices: () => {
      dispatch(getDevices());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrustedDevices);
