/**
 * @author: Seleznyov
 */

import React, {Component} from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import messages from "../../messages";

import {getHistoryDays, getAllTransactions} from "shared/historyService/selectors";
import {fetchTransactions} from "shared/historyService/actions";

import TransactionDay from "./TransactionsDay";
import "./index.scss";

class ActionHistoryTransactions extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.fetchTransactions();
  }

  render() {
    return (
      <div className="card">
        <div className="card__header">
          <h3>
            <FormattedMessage {...messages.transactions} />
          </h3>
          <div className="header-info">
            <div className="header-info-item">
              <FormattedMessage {...messages.incomming} /> {": "}
              <div className="sum">2,587.50 USD</div>
            </div>
            <div className="header-info-item">
              <FormattedMessage {...messages.outgoing} /> {": "}
              <div className="sum">2,587.50 USD</div>
            </div>
          </div>
        </div>
        <div className="card__body">
          <div>
            <div>
              <div className="tableHeader">
                <div className="tableHeader-date">
                  <FormattedMessage {...messages.date} />
                </div>
                <div className="tableHeader-cardwalet">
                  <FormattedMessage {...messages.cardwalet} />
                </div>
                <div className="tableHeader-purposeofpayment">
                  <FormattedMessage {...messages.purposeofpayment} />
                </div>
                <div className="tableHeader-status">
                  <FormattedMessage {...messages.status} />
                </div>
                <div className="tableHeader-amount">
                  <FormattedMessage {...messages.amount} />
                </div>
              </div>

              {/* Table Body */}
              {!!this.props.historyDays.length && (
                <div className="tableBody">
                  {this.props.historyDays.map((day, index) => {
                    return (
                      <TransactionDay
                        key={index}
                        date={day.date}
                        totalAmount={day.totalAmount}
                        items={day.items}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  historyDays: getHistoryDays(store),
  transactions: getAllTransactions(store),
});

function mapDispatchToProps(dispatch) {
  return {
    fetchTransactions: data => {
      dispatch(fetchTransactions(data));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ActionHistoryTransactions);
