import React from "react";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import "./index.scss";
import {
  // getThirdWalletLogoByName,
  getThirdWallets,
  getWalletIconByName,
  isThirdWalletsLoading,
  getShortAddress,
  getCryptoWallets,
} from "shared/walletService/selectors";
import {
  getAllCards,
  // getBankAccountLogoByName,
  getBankAccounts,
  getCardIconBank,
  getCardOperatorName,
  isBankAccountLoading,
} from "shared/cardService/selectors";
import {setActiveCryptoWallet, setActiveThirdWallet} from "shared/walletService/actions";
import {setActiveBankCard, setActiveBankAccount} from "shared/cardService/actions";
import DehoCharts from "../DehoCharts";
import WalletsTable from "../WalletsTables";
import TableRowMenu from "../TableRowMenu";
import messages from "../../messages";
import {openRightModal} from "shared/components/RightModal/actions";

import config from "shared/config";
const onwalletEye = config.prefixImgUrl + "onwallet-eye.svg";

const WalletsContainer = ({
  cryptoWallets,
  setActiveCryptoWallet,
  bankCards,
  setActiveBankCard,
  openRightModal,
  bankCardLoading,
  cryptoWalletsLoading,
  isBankAccountLoading,
  bankAccounts,
  setActiveBankAccount,
  isThirdWalletsLoading,
  thirdWallets,
  setActiveThirdWallet,
}) => {
  const mapCryptoWalletsCols = rows => {
    if (!rows) return [];
    return rows.map((row, index) => ({
      cols: [
        <p className="cryptoWalletsName">
          <img src={getWalletIconByName(row.walletName)} className="icon" alt="icon" />
          {row.walletName}
        </p>,
        <p className="cryptoWalletsAddress">{getShortAddress(row.address)}</p>,
        <div className="cryptoWalletsBalance">
          <p className="balanceValue">
            {row.balance} {row.currency}
          </p>
          <TableRowMenu
            wallet
            onClick={() => setActiveCryptoWallet(cryptoWallets[index].walletName)}
            row={row}
          />
        </div>,
      ],
    }));
  };

  const mapBankCardsCols = rows => {
    if (!rows) return [];

    return rows.map((row, index) => ({
      isActive: row.isDefault,
      cols: [
        <p className="bankCardName">
          <img src={getCardIconBank(row.operator)} className="icon" alt="icon" />
          {getCardOperatorName(row.operator)}
        </p>,
        <p className="bankCardNumber">
          {row && !!row["last4"] && (
            <>
              <span className="dots" />
              {row["last4"]}
            </>
          )}
        </p>,
        <div className="bankCardCurrency">
          <p className="currency"> {row.currency ? row.currency.toUpperCase() : "USD"}</p>
          {row.operator === "OnWallet" && (
            <img
              src={onwalletEye}
              className="eye"
              alt="eye"
              // TODO
              onClick={() => openRightModal("receiveVirtualCard")}
            />
          )}
          <TableRowMenu
            card
            onClick={() =>
              setActiveBankCard(
                !!bankCards[index]["last4"]
                  ? bankCards[index]["last4"]
                  : bankCards[index].name
              )
            }
            row={row}
          />
        </div>,
      ],
    }));
  };

  // TODO Bank Accounts
  // const mapBankAccountsCols = rows => {
  //   if (!rows) return [];
  //   return rows.map((row, index) => ({
  //     cols: [
  //       <p className="bankCardName">
  //         <img src={getBankAccountLogoByName(row.name)} className="icon" alt="icon" />
  //       </p>,
  //       <div className="bankCardCurrency">
  //         <p className="bankCardNumber">
  //           {row && !!row["last5"] && (
  //             <>
  //               <span className="dots" />
  //               {row["last5"]}
  //             </>
  //           )}
  //         </p>
  //         <TableRowMenu
  //           bankAccount
  //           onClick={() =>
  //             setActiveBankAccount(
  //               !!bankAccounts[index]["last5"]
  //                 ? bankAccounts[index]["last5"]
  //                 : bankAccounts[index].name
  //             )
  //           }
  //           row={row}
  //         />
  //       </div>,
  //     ],
  //   }));
  // };

  // TODO Third Wallets
  // const mapThirdWalletsCols = rows => {
  //   if (!rows) return [];
  //   return rows.map((row, index) => ({
  //     cols: [
  //       <p className="bankCardName">
  //         <img src={getThirdWalletLogoByName(row.name)} className="icon" alt="icon" />
  //       </p>,
  //       <div className="bankCardCurrency">
  //         <p className="bankCardNumber">{row && !!row["number"] && row["number"]}</p>
  //         <TableRowMenu
  //           thirdWallet
  //           onClick={() =>
  //             setActiveThirdWallet(
  //               !!thirdWallets[index]["number"]
  //                 ? thirdWallets[index]["number"]
  //                 : thirdWallets[index].name
  //             )
  //           }
  //           row={row}
  //         />
  //       </div>,
  //     ],
  //   }));
  // };

  return (
    <>
      <DehoCharts />
      <div className="CardWalletArea">
        <div className="CardArea">
          <div className="CryptoWalletTable">
            <h1 className="walletsPageContentTitle">
              <FormattedMessage {...messages.cryptoWalletsTitle} />
            </h1>
            <div>
              <WalletsTable
                isLoading={cryptoWalletsLoading}
                headers={null}
                rows={mapCryptoWalletsCols(cryptoWallets)}
                action={{
                  text: <FormattedMessage {...messages.cryptoWalletsAddWallets} />,
                  message: <FormattedMessage {...messages.emptyCryptoWallets} />,
                  handler: () => openRightModal("addNewWallet"),
                }}
              />
            </div>
          </div>
          {/* TODO ThirdWalletContainer Block */}
          {/* <div className="ThirdWalletContainer">
            <h1 className="walletsPageContentTitle">
              <FormattedMessage {...messages.thirdPartyWallets} />
            </h1>
            <div>
              <WalletsTable
                isLoading={isThirdWalletsLoading}
                headers={null}
                rows={mapThirdWalletsCols(thirdWallets)}
                action={{
                  text: <FormattedMessage {...messages.linkWallet} />,
                  message: <FormattedMessage {...messages.emptyThirdWallets} />,
                  handler: () => openRightModal("addThirdWallet"),
                }}
              />
            </div>
          </div> */}
        </div>
        <div className="WalletArea">
          <div className="BankCardTable">
            <h1 className="walletsPageContentTitle">
              <FormattedMessage {...messages.bankCardsTitle} />
            </h1>
            <div>
              <WalletsTable
                isLoading={bankCardLoading}
                headers={null}
                rows={mapBankCardsCols(bankCards)}
                isActiveRow={row => row.isDefault}
                action={{
                  text: <FormattedMessage {...messages.addCard} />,
                  message: <FormattedMessage {...messages.emptyBankCards} />,
                  handler: () => openRightModal("addNewCard"),
                }}
              />
            </div>
          </div>
          {/* TODO Bank Accounts Block */}
          {/* <div className="BankAccountsContainer">
            <h1 className="walletsPageContentTitle">
              <FormattedMessage {...messages.bankAccounts} />
            </h1>
            <div>
              <WalletsTable
                isLoading={isBankAccountLoading}
                headers={null}
                rows={mapBankAccountsCols(bankAccounts)}
                isActiveRow={row => row.isDefault}
                action={{
                  text: <FormattedMessage {...messages.linkBankAccount} />,
                  message: <FormattedMessage {...messages.emptyThirdWallets} />,
                  handler: () => openRightModal("addBankAccount"),
                }}
              />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = store => ({
  cryptoWallets: getCryptoWallets(store),
  bankCardLoading: store.bankCardsData.isLoading,
  cryptoWalletsLoading: store.cryptoWalletsData.isLoading,
  bankCards: getAllCards(store),
  isBankAccountLoading: isBankAccountLoading(store),
  bankAccounts: getBankAccounts(store),
  isThirdWalletsLoading: isThirdWalletsLoading(store),
  thirdWallets: getThirdWallets(store),
});

const mapDispatchToProps = {
  setActiveCryptoWallet,
  setActiveBankCard,
  openRightModal,
  setActiveBankAccount,
  setActiveThirdWallet,
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletsContainer);
