import React from "react";
import {connect} from "react-redux";

import Header from "shared/components/Header";
import WrapEl from "shared/components/wrapperEl";
import TextElement from "shared/components/TextElement";

import messages from "./messages";

import "./index.scss";
// import { routes } from "shared/appUrls";

import {FormattedMessage} from "react-intl";

// import config from "shared/config";
// const TextIcon = config.prefixImgUrl + "bell-icon.svg";

const TextPage = () => {
  // const intl = useIntl(); // intl extracted from useIntl hook
  // const titleMsg = intl.formatMessage({ ...messages.input });

  return (
    <>
      <Header
        headerTitle={<FormattedMessage {...messages.header} />}
        navLinks={[]}
        logoImage={null}
      >
        <div className="text__header__actionBlock haderRight">
          <div className="action__button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="11.998"
              viewBox="0 0 20 11.998"
            >
              <path
                class="a"
                d="M1148.22,101.2l5.244,5.251a.75.75,0,1,0,1.06-1.06l-3.964-3.971h16.69a.75.75,0,1,0,0-1.5h-16.689l3.966-3.967a.75.75,0,1,0-1.06-1.06l-5.247,5.247A.749.749,0,0,0,1148.22,101.2Z"
                transform="translate(-1148 -94.673)"
              />
            </svg>
            <span>back</span>
          </div>
        </div>
      </Header>
      <div className="main-Text-page mainContent">
        <WrapEl elementClass="textPage">
          <TextElement heading="What is Lorem Ipsum?">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
            when an unknown printer took a galley of type and scrambled it to make a type
            specimen book. It has survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged. It was popularised in
            the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus PageMaker
            including versions of Lorem Ipsum.
          </TextElement>
          <TextElement heading="What is Lorem Ipsum?">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
            when an unknown printer took a galley of type and scrambled it to make a type
            specimen book. It has survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged. It was popularised in
            the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus PageMaker
            including versions of Lorem Ipsum.
            <ul>
              <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. </li>
              <li>Modi perspiciatis libero eum nemo ea ipsa odit</li>
              <li>aperiam alias qui optio, doloremque impedit ex possimus</li>
              <li>nulla cupiditate, eaque, numquam esse illum.</li>
            </ul>
          </TextElement>
          <TextElement heading="What is Lorem Ipsum?">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
            when an unknown printer took a galley of type and scrambled it to make a type
            specimen book. It has survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged. It was popularised in
            the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus PageMaker
            including versions of Lorem Ipsum.
          </TextElement>
          <TextElement heading="What is Lorem Ipsum?">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
            when an unknown printer took a galley of type and scrambled it to make a type
            specimen book. It has survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged. It was popularised in
            the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus PageMaker
            including versions of Lorem Ipsum.
          </TextElement>
        </WrapEl>
      </div>
    </>
  );
};

const mapStateToProps = store => ({});

const dispatchToProps = {
  execute: "TEXTPAGE_EXECUTE_SUCCESS",
};
export default connect(mapStateToProps, dispatchToProps)(TextPage);
