/**
 * @author: Seleznyov
 */

import React, {Component} from "react";
import {connect} from "react-redux";
import {getDataContacts} from "shared/contacts/actions";
import {contactList} from "shared/contacts/selectors";
import {Scrollbars} from "react-custom-scrollbars";
import {ReactComponent as SearchIcon} from "images/icon-search.svg";
import messages from "./messages";
import {FormattedMessage} from "react-intl";
import {callForFilter, filterVars} from "./helpers";
import config from "shared/config";

const defaultIcon = config.prefixImgUrl + "userdefault-icon.svg";

class ModalAddExistContacts extends Component {
  constructor(props) {
    super(props);

    this.handleSetFilterValue = this.handleSetFilterValue.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.setActiveRow = this.setActiveRow.bind(this);

    this.input = React.createRef();

    this.state = {
      filterValue: null,
      currentId: 0,
      contactList: [],
    };
  }

  static defaultProps = {
    isShow: false,
    customer: {},
    valfilter: null,
  };

  /**
   *
   */
  componentDidMount() {
    this.props.getDataContacts();
    // this.setState({type: this.props.type});
    this.buildContactList();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      filterValue: filterVars(nextProps),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.contactList.length !== prevProps.contactList.length ||
      JSON.stringify(this.props.valfilter) !== JSON.stringify(prevProps.valfilter)
    )
      this.buildContactList();
  }

  handleSetFilterValue(e) {
    let value = e.target.value;
    this.setState(prevState => {
      return {...prevState, filterValue: value};
    });
  }

  /**
   *
   * @param id
   */
  setActiveRow(id) {
    this.setState({currentId: id});
  }

  /**
   *
   */
  handleFormSubmit() {
    this.setState({
      filterValue: null,
    });
    this.props.toggleShowContactsAdd();
  }

  /**
   *
   * @param data
   */
  buildContactList() {
    let _list = [];
    // let _filter = this.props.valfilter ? getFilterValue(this.props.valfilter) : null;

    this.props.contactList.forEach((item, idx) => {
      let _val = item.info[this.props.type];
      let _typeof = typeof _val;

      _list.push({
        index: idx,
        id: item.id,
        firstName: item.info.firstName,
        lastName: item.info.lastName,
        picture: item.info.picture ? item.info.picture : defaultIcon,
        value: _typeof === "string" ? _val : this.generateValue(_val),
      });
    });

    this.setState({contactList: _list});
  }

  generateValue(data) {
    let _str = "<ul><li>";
    data.forEach(item => {
      if (typeof item === "string") {
        _str += item;
      } else {
        let _item = Object.keys(item);
        _str += item[_item[0]] + " " + item[_item[1]];
      }
      _str += "</li><li>";
    });
    _str += "</li></ul>";
    return _str;
  }

  render() {
    return (
      <>
        <div className="add-contacts__input-search">
          <SearchIcon className="icon-search" />
          <input
            type="text"
            ref={this.input}
            value={this.state.filterValue || ""}
            onChange={this.handleSetFilterValue}
            placeholder={messages.search.defaultMessage}
          />
        </div>

        <Scrollbars
          className="add-contacts__wrapper-items-list"
          renderTrackVertical={props => <div {...props} className="track-vertical " />}
          renderThumbVertical={props => <div {...props} className="thumb-vertical " />}
        >
          <div className="add-contacts__items-list">
            {this.state.contactList
              .filter(item => {
                if (this.state.filterValue) {
                  return callForFilter(item, this.state.filterValue);
                } else return true;
              })
              .map((item, key, array) => {
                return (
                  <div
                    className={
                      "items-list__item-wrapper " +
                      (this.state.currentId === item.id ? "active" : "")
                    }
                    key={item.id}
                    onClick={() => {
                      this.setActiveRow(item.id);
                    }}
                  >
                    <div className="item-wrapper__img">
                      <div className="img-wrapper">
                        <img
                          src={item.picture}
                          alt={`${item.firstName} ${item.lastName}`}
                        />
                      </div>
                    </div>

                    <div className="item-wrapper__info">
                      <div className="info-section">
                        <div className="info-name">
                          {item.firstName} {item.lastName}
                        </div>

                        <div
                          className="info-value"
                          dangerouslySetInnerHTML={{__html: item.value}}
                        ></div>
                      </div>

                      <div className="info-checkit">
                        <div className="checkit-icon"></div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </Scrollbars>

        <div className="wrapper__row row-btns footer">
          <button
            type="button"
            className="CustomSubmitButton white"
            onClick={this.handleFormSubmit}
          >
            <FormattedMessage {...messages.cancel} />
          </button>
          <button
            type="button"
            className="CustomSubmitButton"
            onClick={this.handleFormSubmit}
          >
            <FormattedMessage {...messages.saveToContact} />
          </button>
        </div>
      </>
    );
  }
}

const mapStateToProps = store => ({
  contactList: contactList(store),
});

function mapDispatchToProps(dispatch) {
  return {
    getDataContacts: () => {
      dispatch(getDataContacts());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddExistContacts);
