export const getOrdersHistory = store => {
  return [
    {
      id: "1",
      Date: "4 Mar 2020 10:25:43",
      Pair1: "BTC",
      Pair2: "USDT",
      Side: "0",
      Price: "5316.83 USDT",
      Avarage: "0.150000 BTC",
      Amount: "0.150000 BTC",
      Filled: "90.00",
      Total: "797.523 USDT",
      Status: "0",
    },
    {
      id: "2",
      Date: "5 Mar 2020 10:25:43",
      Pair1: "BTC",
      Pair2: "USD",
      Side: "0",
      Price: "5316.83 USDT",
      Avarage: "0.150000 BTC",
      Amount: "0.150000 BTC",
      Filled: "40.00",
      Total: "797.523 USDT",
      Status: "1",
    },
    {
      id: "3",
      Date: "6 Mar 2020 10:25:43",
      Pair1: "DHO",
      Pair2: "USDT",
      Side: "1",
      Price: "5316.83 USDT",
      Avarage: "0.150000 BTC",
      Amount: "0.150000 BTC",
      Filled: "50.00",
      Total: "797.523 USDT",
      Status: "0",
    },
    {
      id: "4",
      Date: "7 Mar 2020 10:25:43",
      Pair1: "DHO",
      Pair2: "USD",
      Side: "1",
      Price: "5316.83 USDT",
      Avarage: "0.150000 BTC",
      Amount: "0.150000 BTC",
      Filled: "30.00",
      Total: "797.523 USDT",
      Status: "1",
    },
  ];
};
