import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import messages from "../../messages";
import {FormattedMessage} from "react-intl";
// Components
import SearchInput from "shared/components/SearchInput";
import CustomSelect from "shared/components/Select";
import CoinRegistryTable from "../CoinRegistryTable";
import {WalletOptionShort} from "shared/components/CardWalletOptions";
// Styles
import "./index.scss";

const CoinRegistryBody = ({dehoWallets}) => {
  const defaultValue = {walletName: "All", currency: "all"};

  // Hooks
  const [search, setSearch] = useState("");
  const [coinCurrency, setCoinCurrency] = useState(defaultValue);
  const [allDehoWallets, setAllDehoWallets] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (isLoading && dehoWallets.length) {
      setAllDehoWallets([...dehoWallets, defaultValue].reverse());
      setLoading(false);
    }
  }, [defaultValue, dehoWallets, isLoading]);

  return (
    <div className="content">
      <div className="coin-registry">
        {/* First Section */}
        <div className="coin-registry-title">
          <FormattedMessage {...messages.dehoCoins} />
        </div>

        <div className="coin-registry-body">
          {/* Search by ID, Coin ID or Owner ID */}
          <div className="coin-registry-body__search">
            <label className="label">
              <FormattedMessage {...messages.searchById} />
            </label>
            <SearchInput
              value={search}
              onChange={e => setSearch(e.target.value)}
              onClick={() => setSearch("")}
              className="coin-registry-body__search__item"
              placeholder="Search By ID"
            />
          </div>

          {/* Select Coin Currency */}
          <div className="coin-registry-body__select">
            <label className="label">
              <FormattedMessage {...messages.coinCurrency} />
            </label>
            <CustomSelect
              className="coin-registry-body__select__item"
              value={coinCurrency}
              onChange={e => setCoinCurrency(e.target.value)}
              renderValue={value =>
                !!value ? <WalletOptionShort className="no-padding" wallet={value} /> : ""
              }
            >
              {allDehoWallets.map((wallet, index) => {
                return <WalletOptionShort key={index} value={wallet} wallet={wallet} />;
              })}
            </CustomSelect>
          </div>
        </div>

        {/* Table */}
        <CoinRegistryTable searchValue={search} coinCurrency={coinCurrency} />
      </div>
    </div>
  );
};

// Types
CoinRegistryBody.propTypes = {
  dehoWallets: PropTypes.array.isRequired,
};

export default CoinRegistryBody;
