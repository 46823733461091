import {MIX} from "./actions/types";

const initialState = {
  orders: {
    dataBuy: [],
    dataSel: [],
    dataLastOrder: {},
    typeView: MIX,
    type: 1,
    limit: 10,
    limitGet: 40,
  },
  tradeHistory: {
    dataList: [],
    limit: 100,
  },
  filterVars: {
    cryptoCurrency: "dho",
    currency: "usd",
  },
  ordersHistory: {
    dataListOrders: [],
  },
  chartData: {
    isLoading: false,
    coinToPair: [],
    header: null,
  },
};

export default initialState;
