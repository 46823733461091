import React from "react";
import {connect} from "react-redux";
import {daysLeftDay} from "shared/tools";
import moment from "moment";
import "./index.scss";
import config from "shared/config";

const dots = config.prefixImgUrl + "settings-icon-gray.svg";

const CompanyVoucherItem = ({item}) => {
  return (
    <>
      <div className="corporate-voucher-container">
        <div className="corporate-voucher-item">
          <div className="info-layout">
            <div className="voucher-info">{item.title}</div>
            <div className="expiration">{`${daysLeftDay(
              item.expirationDate
            )} (till ${moment(item.expirationDate).format("DD MMMM, YYYY")})`}</div>
          </div>
          <div className="dots-container">
            <img className="dots" alt="" src={dots}></img>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = store => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyVoucherItem);
