import React from "react";
import {FormattedMessage} from "react-intl";
import "./index.scss";
import {Link} from "react-router-dom";

const LinkButton = ({
  text,
  link = "/",
  error,
  isSecondary = false,
  isLoading = false,
  className,
  disabled,
  ...props
}) => {
  return (
    <>
      <Link
        to={link}
        className={`CustomSubmitButton ${isSecondary ? "white" : ""} ${
          disabled ? "disabled" : ""
        }
        ${className ? className : ""}`}
        {...props}
      >
        {isLoading && (
          <div className="buttonSpinnerContainer">
            <div className="buttonSpinner">
              <div></div>
            </div>
          </div>
        )}
        <FormattedMessage {...text} />
      </Link>
    </>
  );
};

export default LinkButton;
