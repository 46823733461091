import React from "react";
import {connect} from "react-redux";
import "./index.scss";
import config from "shared/config";

const promoIcon = config.prefixImgUrl + "promo-image.png";
// const ShowPromoIcon = config.prefixImgUrl + "shopping-promo-show.svg";

const PromoItem = ({item}) => {
  return (
    <>
      <div className="promo-container">
        <div className="promo-item">
          <div className="promo-hovered">
            <img
              src={"http://d12yjp1rg5mty4.cloudfront.net/shopping-promo-show.svg"}
              alt="logo"
            />
          </div>

          <img alt="" className="promo-brand-logo" src={promoIcon}></img>
          <div className="promo-name">{item.name}</div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = store => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PromoItem);
