/**
 * @author: Gnapovska
 */

import React, {Component} from "react";

import "./index.scss";

class OrderHistoryItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      price: 0,
      amount: 0,
      total: 0,
      progres: 0,
      date: "",
      side: "",
      pair1: "",
      pair2: "",
      filled: 0,
      selected: false,
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  prepareData() {
    const {item} = this.props;

    this.setState({
      id: item.id,
      price: item.Price,
      amount: this.formatAmount(item.amount),
      exactAmount: item.Amount,
      progres: this.calcProgress(),
      date: item.Date,
      side: item.Side,
      pair1: item.Pair1,
      pair2: item.Pair2,
      filled: item.Filled,
      total: item.Total,
      avarage: item.Avarage,
      selected: item.selected,
    });
  }

  calcProgress() {
    let max = 100;
    let min = 1;
    return Math.random() * (max - min) + min;
  }

  formatAmount(amount) {
    let _val = (Math.round(amount * 10) / 10).toFixed(1);
    return this.formatNumber(_val);
  }

  formatNumber(value) {
    if (value > 0) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return 0;
  }

  render() {
    return (
      <div
        className={
          "OrderItemWrapOrderHistory " +
          (this.state.side === "0" ? "data-sel" : "data-buy")
        }
      >
        <div className="item-row-orders-history" style={{display: "flex"}}>
          <div className="item-row-orders-history">
            <div className="Date-Order-History">{this.state.date}</div>
            {this.state.side === "0" ? (
              <div className="Side-Order-History" style={{color: "#FF3333"}}>
                {" "}
                {"SELL "}
                {this.state.exactAmount}
              </div>
            ) : (
              <div className="Side-Order-History" style={{color: "#2FA100"}}>
                {" "}
                {"BUY "}
                {this.state.exactAmount}
              </div>
            )}
            <div className="Pair-Order-History">
              {this.state.pair1}
              {"/"}
              {this.state.pair2}
            </div>
          </div>
          <div className="item-row-orders-history">
            <div className="item-value Filled">
              {"Filled: "}
              {this.state.filled}
            </div>
            <div className="item-value Total">{this.state.total}</div>
            <div className="item-value">
              {"Avg. Price: "}
              {this.state.price}
            </div>
          </div>
          <div
            className={"item-value progress-total-orders-history"}
            style={{width: this.state.filled + "%"}}
          ></div>
        </div>
      </div>
    );
  }
}

export default OrderHistoryItem;
