import React from "react";
import PropTypes from "prop-types";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import {Field} from "formik";
// Components
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import Checkbox from "shared/components/Checkbox";
import Textarea from "shared/components/Input/Textarea";
import Button from "shared/components/Button";
import Input from "shared/components/Input";
import InputWithIcon from "shared/components/InputWithIcon";
import {OnWalletOption} from "shared/components/CardWalletOptions";
import Select from "shared/components/Select";
import {WalletShortOption, CardOption} from "shared/components/CardWalletOptions";
import {
  FeeRate,
  SendRecipient,
} from "components/WalletsPage/components/TransferModal/components";
// Selectors
import {getCardIconBank} from "shared/cardService/selectors";
import {getWalletIconByName, getCoinAmountInUSD} from "shared/walletService/selectors";
// Utils
import {FormattedMessage} from "react-intl";
import messages from "../../../messages";
import config from "shared/config";
// Images
const arrowIcon = config.prefixImgUrl + "left-arrow-icon.svg";

// MockData
const DehoWallet = {walletName: "Deho Gold", currency: "DHG", balance: 2000};

const DetailsBlock = ({values, errors, touched, setFieldValue, currencyRates, cards}) => {
  const handleDefineIcon = item => {
    switch (item.currency.toUpperCase()) {
      case "USD":
        return getCardIconBank(item.operator);
      default:
        return getWalletIconByName(item.walletName);
    }
  };

  const handleDefineActiveTab = values => {
    return values.tabs[values.activeTab].toLowerCase();
  };

  return (
    <>
      {!!values.source && !!values.source.currency && (
        <div className="customer-transfer-onwallet">
          <div className="customer-transfer-onwallet__current-customer">
            <Checkbox
              value={values.currentCustomer}
              onChange={e => setFieldValue("currentCustomer", e.target.checked)}
            />
            <div className="customer-transfer-onwallet__current-customer-text">
              Recipient is current customer
            </div>
          </div>

          {/* OnWallet Selector */}
          {handleDefineActiveTab(values) === "onwallet" && (
            <Field name="onwalletAccount">
              {({field}) => (
                <div className="customer-transfer-onwallet-account">
                  <label className="label">
                    <FormattedMessage {...messages.myOnWalletAccount} />
                  </label>
                  <OnWalletOption account={field.value} />
                </div>
              )}
            </Field>
          )}

          {/* Crypto Selector */}
          {handleDefineActiveTab(values) === "crypto" && (
            <Field name="cryptoAccount">
              {({field}) => (
                <div className="customer-transfer-onwallet-account">
                  <div className="customer-transfer__source">
                    <label className="label">
                      <FormattedMessage {...messages.wallet} />
                    </label>
                    <Select
                      className="full-width"
                      renderValue={value =>
                        value ? <WalletShortOption value={value} wallet={value} /> : ""
                      }
                      {...field}
                    >
                      {values.wallets.map(wallet => (
                        <WalletShortOption
                          key={wallet.walletName}
                          value={wallet}
                          wallet={wallet}
                        />
                      ))}
                    </Select>
                  </div>
                </div>
              )}
            </Field>
          )}

          {/* Card Selector */}
          {handleDefineActiveTab(values) === "card" && (
            <Field name="card">
              {({field}) => (
                <div className="customer-transfer-onwallet-account">
                  <label className="label">
                    <FormattedMessage {...messages.cards} />
                  </label>
                  <Select
                    className="full-width"
                    renderValue={value => (value ? <CardOption card={value} /> : "")}
                    {...field}
                  >
                    {cards.map((card, index) => (
                      <CardOption key={index} value={card} card={card} />
                    ))}
                  </Select>
                </div>
              )}
            </Field>
          )}

          <FormSectionSeparator sectionName={messages.details} />

          {/* Amount and result */}
          <div className="customer-transfer-onwallet__currency">
            <Field name="amount">
              {({field}) => (
                <div className="customer-transfer-onwallet__currency-amount">
                  <label className="label">
                    <FormattedMessage {...messages.amountInSourceCurrency} />
                  </label>
                  <div className="customer-transfer-onwallet__currency-amount-wrapper">
                    <InputWithIcon
                      icon={handleDefineIcon(values.source)}
                      error={touched.amount && errors.amount}
                      {...field}
                      onChange={e => {
                        setFieldValue("amount", e.target.value);
                        setFieldValue(
                          "resultAmount",
                          `${getCoinAmountInUSD(
                            DehoWallet,
                            e.target.value,
                            currencyRates
                          )} USD`
                        );
                      }}
                    />
                  </div>
                </div>
              )}
            </Field>

            <div className="equal">
              <span>≈</span>
            </div>

            <Field name="resultAmount">
              {({field}) => (
                <div className="customer-transfer-onwallet__currency-amount-result">
                  <label className="label">
                    <FormattedMessage {...messages.amountInRecipientCurrency} />
                  </label>
                  <div className="customer-transfer-onwallet__currency-amount-wrapper">
                    <Input type="text" {...field} readOnly />
                  </div>
                </div>
              )}
            </Field>
          </div>

          {/* Timer */}

          <div className="customer-transfer-onwallet__timer">
            <div className="customer-transfer-onwallet__timer-exchangeRate">
              <div className="customer-transfer-onwallet__timer-exchangeRate__rate">
                <FormattedMessage {...messages.exchangeRate} />
                {`${values.amount} ${values.source.currency}`}

                <div className="arrow-right">
                  <img src={arrowIcon} alt="arrow" />
                </div>

                {values.resultAmount}
              </div>
              <div className="timer">
                {/* TODO add function to restart timer from https://github.com/vydimitrov/react-countdown-circle-timer#props-for-both-reactreact-native*/}
                <CountdownCircleTimer
                  isPlaying
                  size={26}
                  strokeWidth={2}
                  duration={60}
                  colors={[["#0080ff", 1]]}
                >
                  {({remainingTime}) => remainingTime}
                </CountdownCircleTimer>
              </div>
            </div>
          </div>

          {/* Description */}
          <Field name="description">
            {({field}) => (
              <div className="customer-transfer-onwallet__description">
                <label className="label">
                  <FormattedMessage {...messages.description} />
                </label>
                <Textarea
                  type="text"
                  className="full-width"
                  placeholder={messages.transferFromCryptoWallet.defaultMessage}
                  {...field}
                />
              </div>
            )}
          </Field>

          <SendRecipient touched={touched} errors={errors} values={values} />

          <FeeRate
            wallet={DehoWallet}
            amount={values.amount}
            currency={values.source.currency}
            currencyRates={currencyRates}
          />

          {/* Actions */}
          <div className="customer-transfer__buttons">
            <Button type="sumbit" text={messages.send} />
          </div>
        </div>
      )}
    </>
  );
};

// Types
DetailsBlock.propTypes = {
  currencyRates: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default DetailsBlock;
