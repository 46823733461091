import {
  CART_ADD_ITEM,
  CART_EXECUTE_PENDING,
  CART_EXECUTE_SUCCESS,
  CART_FAILED_ITEM,
  CART_CONFIRM_START,
  CART_SUCCESS_ITEM,
  CART_CLEAR,
  CART_REMOVE_ITEM,
  CART_EXECUTE_AGAIN,
  CART_REMOVE_FAILED_ITEM,
} from "./types";
import {getCurrentCartItems} from "../selectors";

class CartItemIDGenerator {
  constructor() {
    this.count = 0;
  }
  getID() {
    this.count++;
    return this.count;
  }
  clear() {
    this.count = 0;
  }
}

const idGenerator = new CartItemIDGenerator();

export const clearCart = () => {
  idGenerator.clear();
  return {
    type: CART_CLEAR,
  };
};

export const addCartItem = item => {
  return {
    type: CART_ADD_ITEM,
    payload: {id: idGenerator.getID(), ...item},
  };
};

export const removeCartItem = id => ({
  type: CART_REMOVE_ITEM,
  payload: id,
});

export const removeFailedCartItem = id => ({
  type: CART_REMOVE_FAILED_ITEM,
  payload: id,
});

export const executeCartItems = () => dispatch => {
  dispatch({type: CART_EXECUTE_PENDING});
  setTimeout(() => {
    dispatch({type: CART_EXECUTE_SUCCESS});
  }, 1000);
};

export const executeItemsAgain = () => dispatch => {
  dispatch({type: CART_EXECUTE_AGAIN});
  dispatch(executeCartItems());
};

export const confirmCartItems = () => (dispatch, getState) => {
  const currentItems = getCurrentCartItems(getState());
  dispatch({type: CART_CONFIRM_START});
  currentItems.forEach((item, index) => {
    item.action({
      success: () => ({
        type: CART_SUCCESS_ITEM,
        payload: item.id,
      }),
      error: () => ({
        type: CART_FAILED_ITEM,
        payload: item.id,
      }),
    });
  });
};
