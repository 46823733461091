import {defineMessages} from "react-intl";

const scope = "ActionsLogPage";

const messages = defineMessages({
  actionsLogPageTitle: {
    id: `${scope}.actionsLogPageTitle`,
    defaultMessage: "Actions Log",
  },
  pageCurrent: {
    id: `${scope}.pageCurrent`,
    defaultMessage: "Page",
  },
  pageOf: {
    id: `${scope}.pageOf`,
    defaultMessage: "of",
  },
  totalItems: {
    id: `${scope}.totalItems`,
    defaultMessage: "Total",
  },
  items: {
    id: `${scope}.items`,
    defaultMessage: "items",
  },
  id: {
    id: `${scope}.id`,
    defaultMessage: "id",
  },
  dateTime: {
    id: `${scope}.dateTime`,
    defaultMessage: "Date & Time",
  },
  actionName: {
    id: `${scope}.actionName`,
    defaultMessage: "Action Name",
  },
  customer: {
    id: `${scope}.customer`,
    defaultMessage: "Customer",
  },
  originator: {
    id: `${scope}.originator`,
    defaultMessage: "Originator",
  },
  clearFilters: {
    id: `${scope}.clearFilters`,
    defaultMessage: "Clear Filters",
  },
  searchById: {
    id: `${scope}.searchById`,
    defaultMessage: "Search by Action, Customer Name or ID",
  },
  period: {
    id: `${scope}.period`,
    defaultMessage: "Period",
  },
});

export default messages;
