/**
 * @author: Seleznyov and Bazyliuk
 */

export const GET_DATA_CONTACTS = "GET_DATA_CONTACTS";
export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS";
export const ADD_CONTACT_FAILED = "ADD_CONTACT_FAILED";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const UPDATE_CONTACT_FAILED = "UPDATE_CONTACT_FAILED";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const DELETE_CONTACT_FAILED = "DELETE_CONTACT_FAILED";
export const SHARE_CONTACT_SUCCESS = "SHARE_CONTACT_SUCCESS";
export const SHARE_CONTACT_FAILED = "SHARE_CONTACT_FAILED";
