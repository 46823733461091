import React, {useCallback} from "react";
import "./index.scss";
import {useDropzone} from "react-dropzone";
import {FormattedMessage} from "react-intl";
import messages from "./messages";

const DropZone = ({className, onChange, children, ...props}) => {
  const onDrop = useCallback(
    acceptedFiles => {
      onChange(acceptedFiles);
      const input = document.getElementById("file-drop-zone");
      if (input) {
        input.files = null;
        input.value = "";
      }
    },
    [onChange]
  );
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});
  return (
    <div
      className={`DropZoneContainer ${isDragActive ? "active" : ""} ${className}`}
      {...getRootProps()}
    >
      <input id="file-drop-zone" {...getInputProps()} {...props} multiple={true} />
      <p className="title">
        <FormattedMessage {...messages.dragAndDrop} />
      </p>
      <p className="button">
        <FormattedMessage {...messages.attachFiles} />
      </p>
    </div>
  );
};

export default DropZone;
