import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {IntlProvider} from "react-intl";
import {connect} from "react-redux";

/* polifill pluralrule */
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/dist/locale-data/en";
/* polifill relativetimeformat */
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/dist/locale-data/en";

import localeData from "./locales/localeData.json";
import moment from "moment";
// moment locales here
// import "moment/locale/ru";

const messages = localeData;

export const TranslationProvider = ({children, locale}) => {
  useEffect(
    locale => {
      moment.locale(locale);
    },
    [locale]
  );
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      {children}
    </IntlProvider>
  );
};

TranslationProvider.defaultProps = {
  locale: "en",
};

TranslationProvider.propTypes = {
  locale: PropTypes.string,
};

const mapStateToProps = state => ({locale: state.localeData.locale});

export default connect(mapStateToProps)(TranslationProvider);
