import React from "react";
import {connect} from "react-redux";
import RightModal from "shared/components/RightModal";
import ShoppingCard from "./ShoppingCard";
import ShoppingCardEdit from "./ShoppingCardEdit";
import ShoppingVoucher from "./ShoppingVoucher";

const modalVariants = {
  shoppingCompanyVoucher: "shoppingCompanyVoucher",
  shoppingCompanyCard: "shoppingCompanyCard",
  shoppingCompanyCardEdit: "shoppingCompanyCardEdit",
};

const CompanyDetailsModals = ({modalData}) => {
  if (modalData === null) {
    modalData = {
      title: "none",
      name: "none",
      sub: "none",
    };
  }

  return (
    <>
      {modalData.title && (
        <RightModal
          title={modalData && modalData.title ? modalData.title : null}
          subTitle={modalData && modalData.subTitle ? modalData.subTitle : null}
        >
          {modalData.name === modalVariants.shoppingCompanyVoucher && <ShoppingVoucher />}
          {modalData.name === modalVariants.shoppingCompanyCard && <ShoppingCard />}
          {modalData.name === modalVariants.shoppingCompanyCardEdit && (
            <ShoppingCardEdit />
          )}
        </RightModal>
      )}
    </>
  );
};

const mapStateToProps = store => ({
  modalData: store.rightModalData.variant,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetailsModals);
