/**
 * @author: Seleznyov
 */
import React, {Component} from "react";
import messages from "../../messages";
import {FormattedMessage} from "react-intl";
import PendingActionsItem from "./item";
import {routes} from "shared/appUrls";
import {Link} from "react-router-dom";


class PendingActions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemsAction: [],
    };
  }

  componentDidMount() {
    this.getDataList();
  }

  getDataList() {
    let _data = [];
    let _month = {
      1: "Jan",
      2: "Feb",
      3: "Mar",
      4: "Apr",
      5: "May",
      6: "Jun",
      7: "Jul",
      8: "Aug",
      9: "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };
    for (let i = 1; i < 10; i++) {
      _data[i] = {
        id: i,
        dateTime:
          parseInt(this.getRandom(1, 30), 10) +
          " " +
          _month[parseInt(this.getRandom(1, 9), 10)] +
          " 2020 | " +
          parseInt(this.getRandom(1, 24), 10) +
          ":" +
          parseInt(this.getRandom(0, 59), 10),
        actionName: "Edit Customer Status",
        customer:
          "Ellie Smithgold, ID " +
          parseInt(this.getRandom(11111111111111, 99999999999999)),
        originator: "John Salomonov",
        supervisors: "German Johanson",
        supervisorsTwo: "Ronald Gibson",
      };
    }
    this.setState({itemsAction: _data});
  }

  getRandom(min, max) {
    return Math.random() * (max - min) + min;
  }

  render() {
    return (
      <div className="actions-approval-section">
        <div className="card">
          <div className="card__header">
            <h3>
              <FormattedMessage {...messages.pendingActions} /> (
              {parseInt(this.getRandom(10, 999), 10)})
            </h3>
          </div>
          <div className="card__body">
            <div className="table-ui">
              <div className="table-ui__header">
                <div className="table-ui__row">
                  <div className="table-ui__item item-left">
                    <FormattedMessage {...messages.dateTime} />
                  </div>
                  <div className="table-ui__item item-left">
                    <FormattedMessage {...messages.actionName} />
                  </div>
                  <div className="table-ui__item item-left">
                    <FormattedMessage {...messages.customer} />
                  </div>
                  <div className="table-ui__item item-left">
                    <FormattedMessage {...messages.originator} />
                  </div>
                  <div className="table-ui__item item-left">
                    <FormattedMessage {...messages.supervisors} />
                  </div>
                  <div className="table-ui__item item-left"></div>
                </div>
              </div>
              <div className="table-ui__body">
                {this.state.itemsAction.map((item, key) => {
                  return <PendingActionsItem key={key} item={item} />;
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="section-button">
          <Link to={routes.approvalHistory} className="btn-ui btn-bold-blue m-ui-x-auto" >
            <FormattedMessage {...messages.approvalHistory} />
          </Link>
        </div>
      </div>
    );
  }
}

export default PendingActions;
