import React from "react";
import "./index.scss";
import {Select, MenuItem} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const PhoneNumberInput = ({
  options,
  className,
  error,
  disabled,
  dialValue,
  dialOnChange,
  dialOptions,
  ...props
}) => {
  return (
    <>
      <div className={`CustomPhoneNumberInputWrapper`}>
        <div className="dial-input">
          <DialSelect
            height={44}
            disabled={disabled}
            value={dialValue}
            onChange={dialOnChange}
            options={dialOptions}
            hideArrow={true}
            removeRightRadius={true}
            error={error}
          />
        </div>
        <input
          type="text"
          className={`input ${error ? "errorInput" : ""} ${disabled ? "disabled" : ""} ${
            className ? className : ""
          }`}
          disabled={disabled}
          {...props}
        />
      </div>
      {error && <div className="inputErrorText">{error}</div>}
    </>
  );
};

const rootStyles = {
  "box-sizing": "border-box",
  "border-radius": "6px 0 0 6px",
  display: "flex",
  padding: "0 12px",
  "align-items": "center",
  color: "#1F333D",
  "font-size": "16px",
  "line-height": "24px",
  transition: "border .3s ease-out",
  "padding-right": "16px !important",
};

const useStyles = ({height}) => {
  return makeStyles({
    root: {
      ...rootStyles,
      height: height ? `${height}px` : "44px",
      background: "#f2f8fc",
      border: "1px solid #a6d3ff",
      "&:hover": {
        border: "1px solid #0080FF80",
      },
      "&:focus": {
        border: "1px solid #0080FF",
        background: "#f2f8fc",
        "border-radius": "6px 0 0 6px",
      },
    },
    rootError: {
      ...rootStyles,
      height: height ? `${height}px` : "44px",
      background: "#FF333314",
      border: "1px solid #FF3333",
      "&:hover": {
        border: "1px solid #FF3333",
      },
      "&:focus": {
        border: "1px solid #FF3333",
        background: "#f2f8fc",
        "border-radius": "6px 0 0 6px",
      },
    },
    icon: {
      display: "block",
      top: "auto",
      "margin-right": "16px",
      transition: "transform .3s ease-out",
    },
    menuStyle: {
      padding: "8px 0",
      "border-radius": "6px",
      "box-shadow": "0px 4px 16px #0060BF40",
      background: "white",
    },
    item: {
      "background-color": "transparent !important",
      transition: "background-color .3s ease-out",
      "&:hover": {
        "background-color": "#a6d3ff !important",
      },
    },
    itemSelected: {
      "background-color": "transparent !important",
      transition: "background-color .3s ease-out",
      "&:hover": {
        "background-color": "#a6d3ff !important",
      },
    },
    paperStyle: {
      "max-height": "300px",
    },
  })();
};

const DialSelect = ({options, value, height, error, ...props}) => {
  const classes = useStyles({height});
  return (
    <>
      <Select
        disableUnderline
        IconComponent={arrowProps => {
          return null;
        }}
        classes={{
          root: error ? classes.rootError : classes.root,
          icon: classes.icon,
        }}
        MenuProps={{
          disableScrollLock: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
          classes: {list: classes.menuStyle, paper: classes.paperStyle},
        }}
        {...props}
        value={!!value ? value : ""}
      >
        {options &&
          options.map((item, index) => (
            <MenuItem
              classes={{
                root: classes.item,
                selected: classes.itemSelected,
              }}
              key={index}
              value={item}
            >
              {item}
            </MenuItem>
          ))}
      </Select>
    </>
  );
};

export default PhoneNumberInput;
