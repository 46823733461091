import React, {Fragment} from "react";
import {connect} from "react-redux";
import {Formik, Form} from "formik";
import {FormattedMessage} from "react-intl";
// Actions
import {closeRightModal} from "shared/components/RightModal/actions";
// Components
import RadioBlue from "shared/components/RadioBlue";
import Button from "shared/components/Button";
// Utils
import messages from "../../messages";
// Styles
import "./index.scss";

const status = "active";

const ChangeStatusCustomerModal = ({closeRightModal}) => {
  const initFormik = {status};

  return (
    <Fragment>
      <Formik initialValues={initFormik} onSubmit={() => closeRightModal()}>
        {({values, setFieldValue, handleChange}) => (
          <Form>
            <div className="customer-change-status">
              {/* Radio Group */}
              <div className="customer-change-status__item">
                <RadioBlue
                  checked={values.status === "active"}
                  onChange={() => setFieldValue("status", "active")}
                  value="active"
                  name="status-group"
                  title={<FormattedMessage {...messages.active} />}
                  subTitle={<FormattedMessage {...messages.noLimits} />}
                />
              </div>
              <div className="customer-change-status__item">
                <RadioBlue
                  checked={values.status === "blocked"}
                  onChange={() => setFieldValue("status", "blocked")}
                  value="blocked"
                  name="status-group"
                  title={<FormattedMessage {...messages.blocked} />}
                  subTitle={<FormattedMessage {...messages.loginAccount} />}
                />
              </div>
              <div className="customer-change-status__item">
                <RadioBlue
                  checked={values.status === "limited"}
                  onChange={() => setFieldValue("status", "limited")}
                  value="limited"
                  name="status-group"
                  title={<FormattedMessage {...messages.limited} />}
                  subTitle={<FormattedMessage {...messages.financialOperations} />}
                />
              </div>
              <div className="customer-change-status__item">
                <RadioBlue
                  checked={values.status === "disabled"}
                  onChange={() => setFieldValue("status", "disabled")}
                  value="disabled"
                  name="status-group"
                  title={<FormattedMessage {...messages.disabled} />}
                  subTitle={<FormattedMessage {...messages.disabledInfo} />}
                />
              </div>

              {/* Actions */}
              <div className="customer-change-status__actions">
                <Button
                  type="button"
                  text={messages.cancel}
                  isSecondary="true"
                  onClick={() => closeRightModal()}
                />

                <Button type="submit" text={messages.changeStatus} />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

// Redux
const mapDispatchToProps = {
  closeRightModal,
};

export default connect(null, mapDispatchToProps)(ChangeStatusCustomerModal);
