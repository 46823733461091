import React from "react";
import "./index.scss";
import {FormattedMessage} from "react-intl";

const FormSectionSeparator = ({sectionName}) => {
  return (
    <div className="FormSectionSeparator">
      <span className="FormSeparatorLine"></span>
      <span className="FormSectionName">
        <FormattedMessage {...sectionName} />
      </span>
      <span className="FormSeparatorLine"></span>
    </div>
  );
};

export default FormSectionSeparator;
