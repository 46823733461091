import {CHANGE_LOCALE} from "./actions";

const initState = {
  locale: "en",
};

const localeData = (state = initState, action) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return {locale: action.payload};
    default:
      return state;
  }
};

export {localeData};
