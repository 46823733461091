import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import "./index.scss";
import {FormattedMessage} from "react-intl";
import messages from "../messages";
import SidebarMenu from "./components/SidebarMenu";
import SidebarUser from "./components/SidebarUser";
import {useLocation} from "react-router";

import config from "shared/config";
import {routes, routesACL} from "shared/appUrls";
import {Scrollbars} from "react-custom-scrollbars";
import {getMessageList} from "shared/messagesService/selectors";
import {getMessages} from "shared/messagesService/actions";
import {userProfileRoles} from "shared/auth/selectors";
import {checkRole} from "components/App";
import {ROLE} from "shared/constants";

const logo = config.prefixImgUrl + "logo-sidebar.svg";
const dashboardIcon = config.prefixImgUrl + "dashboard-icon.svg";
const walletsIcon = config.prefixImgUrl + "wallets-icon.svg";
const tradingIcon = config.prefixImgUrl + "trading_icon.svg";
const shoppingCardsIcon = config.prefixImgUrl + "shopping-cards-icon.svg";
const actionsHistoryIcon = config.prefixImgUrl + "actions-history-icon.svg";
// const blockchainNewsIcon = config.prefixImgUrl + "blockchain-news-icon.svg";
// const helpIcon = config.prefixImgUrl + "help-icon.svg";
const bellIcon = config.prefixImgUrl + "bell_icon.svg";
const contactsIcon = config.prefixImgUrl + "contacts-icon.svg";
const personalIcon = config.prefixImgUrl + "personal-icon.svg";
const accountSettingIcon = config.prefixImgUrl + "account-setting.svg";
const financeOrderIcon = config.prefixImgUrl + "icon-orders-metalsupply.svg";
const financeCoinRegistryIcon = config.prefixImgUrl + "icon-coinregistry-sidebar.svg";
const customerIcon = config.prefixImgUrl + "customers_sidebar_icon.svg";

const actionsLogIconSidebar = config.prefixImgUrl + "actions-log-icon-sidebar.svg";
const rolesIconSidebar = config.prefixImgUrl + "roles-icon-sidebar.svg";
const staffIconSidebar = config.prefixImgUrl + "staff-icon-sidebar.svg";
const transactionMonitorIcon = config.prefixImgUrl + "transaction-monitor-sidebar.svg";

// const keysVaultIcon = config.prefixImgUrl + "keys-vault.svg";
let messageUnreadCount = 0;

const accountMenuItems = path => [
  {
    text: <FormattedMessage {...messages.messages} />,
    icon: bellIcon,
    link: routes.messages,
    notificationsCount: messageUnreadCount,
    isActive: () => path === routes.messages,
  },
  {
    text: <FormattedMessage {...messages.contacts} />,
    icon: contactsIcon,
    link: routes.contacts,
    notificationsCount: 0,
    access: routesACL.contacts,
    isActive: () => path === routes.contacts,
  },
  {
    text: <FormattedMessage {...messages.personalDetails} />,
    icon: personalIcon,
    link: routes.details,
    notificationsCount: 0,
    isActive: () => path === routes.details,
  },
  {
    text: <FormattedMessage {...messages.accountSettings} />,
    icon: accountSettingIcon,
    link: routes.accountSettings,
    notificationsCount: 0,
    isActive: () => path === routes.accountSettings,
  },
  // {
  //   text: <FormattedMessage {...messages.keysVault} />,
  //   icon: keysVaultIcon,
  //   link: "#",
  //   notificationsCount: 0,
  //   // TODO: change for keys Vault
  //   isActive: () => false,
  // },
];

const financeMenuItems = path => [
  // {
  //   text: <FormattedMessage {...messages.dashboard} />,
  //   icon: dashboardIcon,
  //   link: "/websocket",
  //   notificationsCount: 0,
  //   active: false,
  // },
  {
    text: <FormattedMessage {...messages.dashboard} />,
    icon: dashboardIcon,
    link: routes.dashboardAdmin,
    notificationsCount: 0,
    active: false,
    access: routesACL.dashboardAdmin,
    isActive: () => path === routes.dashboardAdmin || path === "/dashboard-admin",
  },
  {
    text: <FormattedMessage {...messages.dashboard} />,
    icon: dashboardIcon,
    link: routes.dashboard,
    notificationsCount: 0,
    active: false,
    access: routesACL.dashboard,
    isActive: () => path === routes.dashboard || path === "/",
  },
  {
    text: <FormattedMessage {...messages.transactionMonitor} />,
    icon: transactionMonitorIcon,
    link: routes.transactionMonitor,
    notificationsCount: 0,
    active: false,
    access: routesACL.transactionMonitor,
    isActive: () => path === routes.transactionMonitor || path === "/transaction-monitor",
  },
  {
    text: <FormattedMessage {...messages.customers} />,
    icon: customerIcon,
    link: routes.customers,
    notificationsCount: 0,
    active: false,
    access: routesACL.customers,
    isActive: () => path === routes.customers || path === "/customers",
  },
  {
    text: <FormattedMessage {...messages.wallets} />,
    icon: walletsIcon,
    link: routes.wallets,
    notificationsCount: 0,
    access: routesACL.wallets,
    isActive: () => path === routes.wallets,
  },
  {
    text: <FormattedMessage {...messages.trading} />,
    icon: tradingIcon,
    link: routes.trading,
    notificationsCount: 0,
    access: routesACL.trading,
    isActive: () => path === routes.trading,
  },
  {
    text: <FormattedMessage {...messages.shoppingCards} />,
    icon: shoppingCardsIcon,
    link: routes.shopping,
    notificationsCount: 0,
    access: routesACL.shopping,
    isActive: () => path === routes.shopping,
  },
  {
    text: <FormattedMessage {...messages.actionsHistory} />,
    icon: actionsHistoryIcon,
    link: routes.actionsHistory,
    notificationsCount: 0,
    access: routesACL.actionsHistory,
    isActive: () => path === routes.actionsHistory,
  },
  {
    text: <FormattedMessage {...messages.financeCoinRegistry} />,
    icon: financeCoinRegistryIcon,
    link: routes.coinRegistry,
    notificationsCount: 0,
    access: routesACL.coinRegistry,
    isActive: () => path === routes.coinRegistry,
  },
  {
    text: <FormattedMessage {...messages.financeMetalSupply} />,
    icon: financeOrderIcon,
    link: routes.metalSupply,
    notificationsCount: 0,
    access: routesACL.metalSupply,
    isActive: () => path === routes.metalSupply,
  },
  {
    text: <FormattedMessage {...messages.financeOrders} />,
    icon: financeOrderIcon,
    link: routes.orders,
    notificationsCount: 0,
    access: routesACL.orders,
    isActive: () => path === routes.orders,
  },
];

const managementStuff = path => [
  {
    text: <FormattedMessage {...messages.staff} />,
    icon: staffIconSidebar,
    link: routes.staff,
    notificationsCount: 0,
    access: routesACL.staff,
    isActive: () => path === routes.staff,
  },
  {
    text: <FormattedMessage {...messages.roles} />,
    icon: rolesIconSidebar,
    link: routes.roles,
    notificationsCount: 0,
    access: routesACL.roles,
    isActive: () => path === routes.roles,
  },
  {
    text: <FormattedMessage {...messages.actionsApproval} />,
    icon: rolesIconSidebar,
    link: routes.actionsApproval,
    notificationsCount: 5,
    access: routesACL.actionsApproval,
    isActive: () => path === routes.actionsApproval,
  },
  {
    text: <FormattedMessage {...messages.actionsLog} />,
    icon: actionsLogIconSidebar,
    link: routes.actionsLog,
    notificationsCount: 0,
    access: routesACL.actionsLog,
    isActive: () => path === routes.actionsLog,
  },
];

const supportMenuItems = path => [
  // {
  //   text: <FormattedMessage {...messages.blockchainNews} />,
  //   icon: blockchainNewsIcon,
  //   link: "#",
  //   notificationsCount: 0,
  //   // TODO: add for news
  //   isActive: () => false,
  // },
  // {
  //   text: <FormattedMessage {...messages.help} />,
  //   icon: helpIcon,
  //   link: "#",
  //   notificationsCount: 0,
  //   // TODO: add for help
  //   isActive: () => false,
  // },
  {
    text: <FormattedMessage {...messages.legalDocs} />,
    icon: actionsLogIconSidebar,
    link: routes.legalDocs,
    notificationsCount: 0,
    isActive: () => path === routes.legalDocs,
  },
];

const handleFilterMenuItems = (items, userRoles) => {
  if (!!userRoles && userRoles.includes(ROLE.GOLD_PROVIDER)) {
    return items.filter(item => checkRole([ROLE.GOLD_PROVIDER], item.access));
  }

  if (!!userRoles && userRoles.includes(ROLE.BANK)) {
    return items.filter(item => checkRole([ROLE.BANK], item.access));
  }

  if (!!userRoles && userRoles.includes(ROLE.MASTER_ACCOUNT)) {
    return items.filter(
      item =>
        item.link !== routes.dashboard &&
        item.link !== routes.details &&
        checkRole([ROLE.MASTER_ACCOUNT], item.access)
    );
  }

  if (!!userRoles && !userRoles.length) {
    return items.filter(
      item => item.link === routes.details || item.link === routes.contacts
    );
  }

  return items.filter(item => checkRole(userRoles, item.access));
};

const Sidebar = ({userRoles, messagesList}) => {
  const [active, setActive] = useState(false);
  const location = useLocation();
  let notificationsCount = 0;

  useEffect(() => {
    getMessages();
  }, []);

  messagesList.forEach(message => {
    if (!message.isRead) {
      notificationsCount += 1;
    }
  });

  messageUnreadCount = notificationsCount;

  return (
    <div className="Sidebar">
      <Scrollbars
        renderTrackVertical={props => <div {...props} className="track-vertical " />}
        renderThumbVertical={props => <div {...props} className="thumb-vertical " />}
      >
        <ul className="SidebarList">
          <div className="SidebarHeader">
            <a href="https://onwallet.com/">
              <img className="SidebarLogo" src={logo} alt="OnWallet" />
            </a>
          </div>

          <SidebarUser active={active} onClick={() => setActive(!active)} />

          <SidebarMenu
            menuTitle={<FormattedMessage {...messages.account} />}
            items={handleFilterMenuItems(accountMenuItems(location.pathname), userRoles)}
            withLogout
            collapse={!active}
            key="account"
          />

          {!!handleFilterMenuItems(financeMenuItems(location.pathname), userRoles)
            .length && (
            <SidebarMenu
              menuTitle={<FormattedMessage {...messages.finance} />}
              items={handleFilterMenuItems(
                financeMenuItems(location.pathname),
                userRoles
              )}
              classSection="finance-section"
            />
          )}

          {!!handleFilterMenuItems(managementStuff(location.pathname), userRoles)
            .length && (
            <SidebarMenu
              menuTitle={<FormattedMessage {...messages.managementStuff} />}
              items={handleFilterMenuItems(managementStuff(location.pathname), userRoles)}
              classSection="management-stuff-section"
            />
          )}

          <SidebarMenu
            menuTitle={<FormattedMessage {...messages.support} />}
            items={supportMenuItems(location.pathname)}
          />
        </ul>
      </Scrollbars>
      <div className="copyrights">
        {/* <div>{<FormattedMessage {...messages.providedBy} />}</div> */}
        <div>{<FormattedMessage {...messages.rigthsReserved} />}</div>
        {/* <a href="./landing/policy.html" class="privacy_policy"> */}
        {/* {<FormattedMessage {...messages.privacyPolicy} />} */}
        {/* </a> */}
      </div>
    </div>
  );
};

const mapStateToProps = store => ({
  messagesList: getMessageList(store),
  userRoles: userProfileRoles(store),
});

function mapDispatchToProps(dispatch) {
  return {
    getMessages: data => {
      dispatch(getMessages(data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
