export const SECOND_SECTION = [
  {
    id: "a)",
    text: "the singular includes the plural and vice versa",
  },

  {
    id: "b)",
    text: "“including” to be understood as “including without limitation”",
  },

  {
    id: "c)",
    text:
      "reference to any statutory provision includes any modification or amendment of it",
  },
];

export const HOW_DATA_COLLECTED = [
  {
    id: "i.",
    text: "data given by you to us;",
  },
  {
    id: "ii.",
    text: "data we collect automatically.",
  },
];

export const DATA_GIVEN_BY_USERS = [
  {
    id: "i.",
    text:
      "Name, address, contact information, email ID, date of birth, credit/debit card numbers, pin numbers, identity proof.",
  },
  {
    id: "ii.",
    text: "Transaction details you make using our app or website.",
  },
  {
    id: "iii.",
    text:
      "iii.	Bank transaction details (account number, name of the account holder, expiry date of the card, PIN numbers, passwords, previous transaction history)",
  },
  {
    id: "iv.",
    text: "Any SMS or calls made by us to the users.",
  },
  {
    id: "v.",
    text: "The details of the surveys which you fill.",
  },
  {
    id: "vi.",
    text: "Store information received through cookies.",
  },
  {
    id: "vii.",
    text:
      "If you use our website- login times, IP address, cookie, browser type, number of visits to our website, weblogs, communication data and location.",
  },
];

export const DATA_COLLECTED_AUTOMATICALLY = [
  {
    id: "a.",
    text:
      "To provide users suggestions regarding the products and services they intend to order.",
  },
  {
    id: "b.",
    text: "To collect feedback in regard to the products and services.",
  },
  {
    id: "c.",
    text:
      "To conduct optional surveys periodically. We will be using the data collected through surveys in improvising our app and website experiences and preferences that the users might be interested in and also in upgrading the security our app or website.",
  },
  {
    id: "d.",
    text:
      "To send information with regard to any important notices, changes in services, terms and conditions and policies.",
  },
  {
    id: "e.",
    text:
      "To protect and detect the users and us from spam, fraud, error or any other criminal activity, misuse of our terms and conditions and anti- money laundering.",
  },
  {
    id: "f.",
    text:
      "To enhance or revamp our app and website appearance and usage making it more user friendly.",
  },
];

export const RIGHTS_OF_USERS = [
  {
    id: "a)",
    text:
      "Right to access- the users have the right to access and request copies of information Onwallet holds about the users at any time, or that Onwallet shall modify, update or delete such information. Where Onwallet is legally permitted to do so, we may refuse your request; if we refuse your request, we will provide the reasons for such refusal.",
  },
  {
    id: "b)",
    text:
      "Right to correct- users have the right to rectify or correct their data if it is incomplete or inaccurate",
  },
  {
    id: "c)",
    text:
      "Right to erase- the users have the right to request for removal or deletion of their data from Onwallet servers.",
  },
  {
    id: "d)",
    text:
      "d)	Right to restrict- the users have the right to restrict us from using your data or limit the way we use your data only for specific purposes, if:",
    details: [
      {
        id: "•",
        text: "it is not accurate",
      },
      {
        id: "•",
        text: "the processing is unlawful",
      },
      {
        id: "•",
        text:
          "Onwallet does not require your personal data anymore but are required by the users for exercising of legal and defence claims;",
      },
    ],
  },
  {
    id: "e)",
    text:
      "Right to data portability- the users have the right to request Onwallet to move, copy or transfer their data from one source to another. ",
  },
  {
    id: "f)",
    text:
      "Right to object- the users have the right to object our use of their data including where we use it for our legitimate interests.",
  },
];

export const RESTRICTED_CONTENT = [
  {
    id: "a.",
    text: "Our privacy policy is strong and precise. It complies fully with current law.",
  },
  {
    id: "b.",
    text:
      "Posting content of any sort does not change your ownership of the copyright in it. We have no claim over it and we will not protect your rights for you.",
  },
  {
    id: "c.",
    text:
      "You understand that you are personally responsible for your breach of someone else’s intellectual property rights, defamation, or any law, You accept all risk and responsibility for determining whether any Content is in the public domain and not confidential.",
  },
  {
    id: "d.",
    text:
      "You are not using our software to create any unlawful products or tend to incite another person to commit a crime;",
  },
  {
    id: "e.",
    text: "consist in commercial audio, video or music files;",
  },
  {
    id: "f.",
    text: "be obscene, offensive, threatening, violent, malicious or defamatory;",
  },
  {
    id: "g.",
    text: "be sexually explicit or pornographic films with adults or children.",
  },
  {
    id: "h.",
    text:
      "be likely to deceive any person or be used to impersonate any person, or to misrepresent your identity, age or affiliation with any person;",
  },
  {
    id: "i.",
    text:
      "request or collect passwords or other personal information from another user without his permission, nor Post any unnecessary personal information about yourself;",
  },
  {
    id: "j.",
    text:
      "be used to sell any goods or services or for any other commercial use not intended by us, for yourself or for any other person. Examples are: sending private messages with a commercial purpose, or collecting information with the intention of passing it to a third party for his commercial use;",
  },
  {
    id: "k.",
    text:
      "include anything other than words (i.e. you will not include any symbols or photographs) except for a photograph of yourself in your profile in such place as we designate;",
  },
  {
    id: "l.",
    text:
      "facilitate the provision of unauthorised copies of another person's copyright work;",
  },
  {
    id: "m.",
    text: "link to any of the material specified in this paragraph;",
  },
  {
    id: "n.",
    text: "Post excessive or repeated off-topic messages to any forum or group;",
  },
  {
    id: "o.",
    text:
      "sending age-inappropriate communications or Content to anyone under the age of 18.",
  },
];
