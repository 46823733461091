import {defineMessages} from "react-intl";

const scope = "NewOrder";

const messages = defineMessages({
  buy: {
    id: `${scope}.buy`,
    defaultMessage: "BUY",
  },
  sell: {
    id: `${scope}.sell`,
    defaultMessage: "SELL",
  },
});

export default messages;
