import React from "react";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
// Components
import Header from "shared/components/Header";
// Utils
import messages from "./messages";
import config from "shared/config";
// Styles
import "./index.scss";
// Images
const logoPage = config.prefixImgUrl + "actions-log-icon-toptitle.svg";
const arrowIcon = config.prefixImgUrl + "left-arrow-icon.svg";

const documents = [
  {
    link: "eula",
    name: "End User License Agreement",
    description: "Legal agreement between you and OnWallet and its affiliates",
  },
  {
    link: "agreement",
    name: "Agreement Between Merchant and OnWallet",
    description:
      "Onwallet and Merchant are two independent parties contracting for services and neither is an agent or a partner of the other.",
  },
  {
    link: "privacy",
    name: "Privacy Policy",
    description: "Onwallet considers privacy as a fundamental right of an individual",
  },
  {
    link: "terms",
    name: "Terms and Conditions",
    description:
      "OnWallet is a payment facilitator between customers and the service providers, merchants or any third party service providers.",
  },
  {
    link: "refund",
    name: "Refund Policy",
    description: "In case of any dispute",
  },
  {
    link: "cookies",
    name: "Cookies Policy",
    description: "Cookies are small text files stored on your computers",
  },
];

const Documents = () => {
  return (
    <>
      <Header
        headerTitle={<FormattedMessage {...messages.legalDocs} />}
        navLinks={[]}
        logoImage={logoPage}
      ></Header>

      {/* Body */}
      <div className="legal-docs">
        <div className="legal-docs__paper">
          <div className="legal-docs__paper-title">
            <FormattedMessage {...messages.legalDocuments} />
          </div>
          <div className="legal-docs__paper-body">
            {documents.map((item, index) => (
              <div key={index}>
                <Link to={`legal-documents/${item.link}`}>
                  <div className="legal-docs__paper-body__item">
                    <img
                      src={arrowIcon}
                      alt="arrow"
                      className="legal-docs__paper-body__item-image"
                    />

                    <div className="legal-docs__paper-body__item-doc">
                      <div className="legal-docs__paper-body__item-doc__name">
                        {item.name}
                      </div>
                      <div className="legal-docs__paper-body__item-doc__description">
                        {item.description}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Documents;
