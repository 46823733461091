import React, {useState, useRef, useEffect} from "react";
import "./index.scss";

const CodeInput = ({onChange, error, focusStatus = false}) => {
  const firstRef = useRef(null);
  const secondRef = useRef(null);
  const thirdRef = useRef(null);
  const fourthRef = useRef(null);
  const [firstValue, setFirstValue] = useState("");
  const [secondValue, setSecondValue] = useState("");
  const [thirdValue, setThirdValue] = useState("");
  const [fourthValue, setFourthValue] = useState("");

  useEffect(() => {
    if (focusStatus) {
      firstRef.current.focus();
    }
  }, [focusStatus]);

  const isNumber = value => {
    const pattern = /^\d+$/;
    const test = pattern.test(value);
    return !!test;
  };

  const getValue = () => {
    onChange(`${firstValue}${secondValue}${thirdValue}${fourthValue}`);
  };

  return (
    <div className="CodeInputWrapper">
      <div className="CodeInputContainer">
        <input
          ref={firstRef}
          type="text"
          className="codeInput"
          pattern="[0-9]{2}"
          value={firstValue}
          onChange={e => {
            if (secondRef.current && e.target.value.length >= 2) {
              secondRef.current.focus();
              setSecondValue("");
            }
            setFirstValue(
              isNumber(e.target.value) && e.target.value.length < 3
                ? e.target.value
                : firstValue
            );
            getValue();
          }}
        />
        <div className="line">-</div>
        <input
          ref={secondRef}
          type="text"
          className="codeInput"
          pattern="[0-9]{2}"
          value={secondValue}
          onChange={e => {
            if (thirdRef.current && e.target.value.length >= 2) {
              thirdRef.current.focus();
              setThirdValue("");
            }
            setSecondValue(
              isNumber(e.target.value) && e.target.value.length < 3
                ? e.target.value
                : secondValue
            );
            getValue();
          }}
        />
        <div className="line">-</div>
        <input
          ref={thirdRef}
          type="text"
          className="codeInput"
          pattern="[0-9]{2}"
          value={thirdValue}
          onChange={e => {
            if (fourthRef.current && e.target.value.length >= 2) {
              fourthRef.current.focus();
              setFourthValue("");
            }
            setThirdValue(
              isNumber(e.target.value) && e.target.value.length < 3
                ? e.target.value
                : thirdValue
            );
            getValue();
          }}
        />
        <div className="line">-</div>
        <input
          ref={fourthRef}
          type="text"
          className="codeInput"
          pattern="[0-9]{2}"
          value={fourthValue}
          onChange={e => {
            setFourthValue(
              isNumber(e.target.value) && e.target.value.length < 3
                ? e.target.value
                : fourthValue
            );
            getValue();
          }}
        />
      </div>
      {error && <div className="codeInputError">{error}</div>}
    </div>
  );
};

export default CodeInput;
