import React from "react";
import "./index.scss";
import ReactTooltip from "react-tooltip";

const Tooltip = ({children, label, name}) => {
  return (
    <>
      {label}
      <ReactTooltip place="bottom" arrowColor="#fff" className="Tooltip">
        {children}
      </ReactTooltip>
    </>
  );
};

export default Tooltip;
