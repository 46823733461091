/**
 * @author: Seleznyov
 */

import "./index.scss";

import React, {Component} from "react";
import {connect} from "react-redux";
import messages from "../../messages";
import messagesFrm from "../../../WalletsPage/messages";
import {FormattedMessage} from "react-intl";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";

import InputWithIcon from "shared/components/InputWithIcon";
import Select from "shared/components/Select";
import {
  CardOption,
  WalletOption,
  WalletOptionSelected,
} from "shared/components/CardWalletOptions";
import {MenuItem} from "@material-ui/core";
import SelectGroupSeparator from "shared/components/SelectGroupSeparator";
import {getBankCards} from "shared/cardService/selectors";
import {getCryptoWallets} from "shared/walletService/selectors";
import DateRangePicker from "shared/components/DateRangePicker";
import InputWithSuffix from "shared/components/InputWithSuffix";
import Button from "shared/components/Button";

import {filterVars} from "shared/actionsHistory/selectors";
import {setFilterVars, resetFilterVars} from "shared/actionsHistory/actions";

import config from "shared/config";
const searchIcon = config.prefixImgUrl + "search-icon.svg";

const AHFilterSchema = Yup.object().shape({
  amountTo: Yup.string()
    .nullable()
    .matches(
      "([1-9]\\d*(\\.\\d*[1-9])?|0\\.\\d*[1-9]+)|\\d+(\\.\\d*[1-9])?",
      "Amount must be a number"
    )
    .test(
      "should-be-greather-than-amountFrom",
      "Should not be less than amount from",
      function(value) {
        const amountFrom = Number.parseFloat(this.parent.amountFrom);

        if (!amountFrom) {
          return true;
        }
        return Number.parseFloat(value) >= amountFrom;
      }
    ),

  amountFrom: Yup.string()
    .nullable()
    .matches(
      "([1-9]\\d*(\\.\\d*[1-9])?|0\\.\\d*[1-9]+)|\\d+(\\.\\d*[1-9])?",
      "Amount must be a number"
    ),
});

class ActionHistoryFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      source: "All Sources",
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div className="card">
        <div className="card__header">
          <h3>
            <FormattedMessage {...messages.filters} />
          </h3>
        </div>
        <div className="card__body">
          <div className="wrapper-filter">
            <Formik
              initialValues={this.props.filterVars}
              onSubmit={values => {
                this.props.setFilterVars(values);
              }}
              validationSchema={AHFilterSchema}
            >
              {({values, errors, touched, setFieldValue, resetForm}) => (
                <Form className="HistoryFilterModal">
                  <Field name="keyword">
                    {({field}) => (
                      <div className="byKeywordContainer">
                        <label className="label">
                          <FormattedMessage {...messagesFrm.byKeyword} />
                        </label>
                        <InputWithIcon
                          {...field}
                          placeholder={messagesFrm.byKeywordPlaceholder.defaultMessage}
                          icon={searchIcon}
                        />
                      </div>
                    )}
                  </Field>
                  <Field name="source">
                    {({field}) => (
                      <div className="bySourceWalletCardContainer">
                        <label className="label">
                          <FormattedMessage {...messagesFrm.bySourceWalletCard} />
                        </label>
                        <Select
                          {...field}
                          value={this.state.source}
                          renderValue={value =>
                            !!value ? (
                              !!value.number ? (
                                <CardOption card={value} />
                              ) : !!value.address ? (
                                <WalletOptionSelected
                                  className="no-padding"
                                  wallet={value}
                                />
                              ) : (
                                <MenuItem value="All Sources">
                                  <div className="title">
                                    <div className="name">All Sources</div>
                                  </div>
                                </MenuItem>
                              )
                            ) : (
                              <MenuItem>
                                <div className="title">
                                  <div className="name">All Sources</div>
                                </div>
                              </MenuItem>
                            )
                          }
                          onChange={e => {
                            this.setState({source: e.target.value});
                          }}
                        >
                          <MenuItem value="All Sources">
                            <div className="title">
                              <div className="name">All Sources</div>
                            </div>
                          </MenuItem>

                          <SelectGroupSeparator groupName={messagesFrm.cryptoWallets} />
                          {this.props.allWallets.map((wallet, index) => (
                            <WalletOption key={index} value={wallet} wallet={wallet} />
                          ))}

                          <SelectGroupSeparator groupName={messagesFrm.bankCards} />
                          {this.props.bankCards.map((card, index) => (
                            <CardOption key={index} value={card} card={card} />
                          ))}
                        </Select>
                      </div>
                    )}
                  </Field>

                  <Field name="period">
                    {({field}) => (
                      <div className="mt-4">
                        <DateRangePicker
                          title={messagesFrm.period}
                          onChange={value => setFieldValue("period", value)}
                        />
                      </div>
                    )}
                  </Field>
                  <Field name="operation">
                    {({field}) => (
                      <div className="operationTypeInputContainer">
                        <label className="label">
                          <FormattedMessage {...messagesFrm.operationType} />
                        </label>
                        <Select
                          className="select"
                          {...field}
                          options={["All", "Transfer", "Receive", "Exchange"]}
                        />
                      </div>
                    )}
                  </Field>
                  <div className="transactionsAmountContainer">
                    <Field name="amountFrom">
                      {({field}) => (
                        <div className="fromInputContainer">
                          <label className="label">
                            <FormattedMessage {...messagesFrm.transactionAmountFrom} />
                          </label>
                          <InputWithSuffix
                            {...field}
                            error={
                              touched.amountFrom && errors.amountFrom
                                ? errors.amountFrom
                                : ""
                            }
                          />
                        </div>
                      )}
                    </Field>
                    <Field name="amountTo">
                      {({field}) => (
                        <div className="toInputContainer">
                          <label className="label">
                            <FormattedMessage {...messagesFrm.till} />
                          </label>
                          <InputWithSuffix
                            {...field}
                            error={
                              touched.amountTo && errors.amountTo ? errors.amountTo : ""
                            }
                          />
                        </div>
                      )}
                    </Field>
                  </div>

                  <div className="button-group">
                    <Button
                      type="button"
                      text={messages.reset}
                      isSecondary="true"
                      onClick={() => {
                        setFieldValue("keyword", "");
                        setFieldValue("source", "");
                        setFieldValue("period", "");
                        setFieldValue("operation", "");
                        setFieldValue("amount", "");
                        setFieldValue("amountFrom", "");
                        setFieldValue("amountTo", "");
                        this.props.resetFilterVars();
                      }}
                    />

                    <Button type="sumbit" text={messages.applyFilters} />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  bankCards: getBankCards(store),
  allWallets: getCryptoWallets(store),
  filterVars: filterVars(store),
});

function mapDispatchToProps(dispatch) {
  return {
    setFilterVars: data => {
      dispatch(setFilterVars(data));
    },
    resetFilterVars: () => {
      dispatch(resetFilterVars());
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ActionHistoryFilters);
