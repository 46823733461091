import {defineMessages} from "react-intl";

const scope = "TransactionMonitor";

const messages = defineMessages({
  transactionMonitor: {
    id: `${scope}.transactionMonitor`,
    defaultMessage: "Transaction Monitor",
  },
  lastTransaction: {
    id: `${scope}.lastTransaction`,
    defaultMessage: "Last Transaction",
  },
  pendingTransactions: {
    id: `${scope}.pendingTransactions`,
    defaultMessage: "Pending Transactions",
  },
  resetFilters: {
    id: `${scope}.resetFilters`,
    defaultMessage: "Reset Filters",
  },
  amountFrom: {
    id: `${scope}.amountFrom`,
    defaultMessage: "Amount from",
  },
  till: {
    id: `${scope}.till`,
    defaultMessage: "till",
  },
  transactionAsOf: {
    id: `${scope}.transactionAsOf`,
    defaultMessage: "Transaction as of",
  },
  searchById: {
    id: `${scope}.searchById`,
    defaultMessage: "Search By ID",
  },
  period: {
    id: `${scope}.period`,
    defaultMessage: "Period",
  },
  currency: {
    id: `${scope}.currency`,
    defaultMessage: "Currency",
  },
  source: {
    id: `${scope}.source`,
    defaultMessage: "Source",
  },
  dateAndTime: {
    id: `${scope}.dateAndTime`,
    defaultMessage: "Date & Time",
  },
  recipient: {
    id: `${scope}.recipient`,
    defaultMessage: "Recipient",
  },
  amount: {
    id: `${scope}.amount`,
    defaultMessage: "Amount",
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: "Status",
  },
});

export default messages;
