/**
 * @author: Seleznyov
 */

import {SET_DATA_FILTER, CLEAN_DATA_FILTER} from "./actions/types";
import initialState from "./initialState";

const dataActionsHistory = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case SET_DATA_FILTER:
      return {
        ...state,
        filterVars: payload,
      };

    case CLEAN_DATA_FILTER:
      return {
        ...state,
        filterVars: {},
      };

    default:
      return state;
  }
};

export {dataActionsHistory};
