import React from "react";
import {FormattedMessage} from "react-intl";
import * as Yup from "yup";
import {Formik, Field, Form} from "formik";
// Components
import CustomSelect from "shared/components/Select";
import Input from "shared/components/Input";
import Button from "shared/components/Button";
import {MetalOption} from "shared/components/CardWalletOptions";
// Styles
import "./index.scss";
// Utils
import messages from "../../../messages";

// TODO: get from selectors metals
const METALS = [
  {
    img: "metal-au.svg",
    metal: "gold",
    metal_value: 999.9,
    sum_currency: 155588.18,
    currency: "usd",
    currency2: "dhg",
    sum_currency2: 5316,
  },
  {
    img: "metal-ag.svg",
    metal: "silver",
    metal_value: 0,
    sum_currency: 2617.2,
    currency: "usd",
    currency2: "dhs",
    sum_currency2: 458,
  },
  {
    img: "metal-pt.svg",
    metal: "platinum",
    metal_value: 0,
    sum_currency: 18410.0,
    currency: "usd",
    currency2: "dhp",
    sum_currency2: 280,
  },
];

const AcceptNewOrderModal = () => {
  const initStateFormik = {
    select: {
      img: "metal-au.svg",
      metal: "gold",
      metal_value: 999.9,
    },
    weight: 5,
    price: "64,543.27 USD",
  };

  // Validation
  const AcceptNewOrderSchema = Yup.object().shape({
    weight: Yup.number().required("Weight is required"),
    price: Yup.number().required("Price is required"),
  });

  return (
    <div>
      <Formik
        initialValues={initStateFormik}
        validateOnChange={true}
        validationSchema={AcceptNewOrderSchema}
        onSubmit={values => console.log(values)}
      >
        {({errors, touched, values, setFieldValue}) => (
          <Form className="acceptNewOrder">
            {/* Select */}
            <div className="acceptNewOrder__select">
              <Field name="select">
                {({field}) => (
                  <div>
                    <label className="label">
                      <FormattedMessage {...messages.metalSupplied} />
                    </label>
                    <CustomSelect
                      name="select"
                      className="acceptNewOrder__select__item"
                      {...field}
                      renderValue={value =>
                        !!value ? (
                          <MetalOption className="no-padding" metal={value} />
                        ) : (
                          ""
                        )
                      }
                      value={values.select}
                      onChange={e => setFieldValue("select", e.target.value)}
                    >
                      {METALS.map((metal, index) => {
                        return <MetalOption key={index} value={metal} metal={metal} />;
                      })}
                    </CustomSelect>
                  </div>
                )}
              </Field>
            </div>

            {/* Inputs blocks */}
            <div className="acceptNewOrder__inputs">
              <Field name="weight">
                {({field}) => (
                  <div>
                    <div className="label">
                      <FormattedMessage {...messages.weightKg} />
                    </div>
                    <div>
                      <Input
                        type="text"
                        error={touched.weight && errors.weight}
                        value={values.weight}
                        name="weight"
                        {...field}
                      />
                    </div>
                  </div>
                )}
              </Field>

              <Field name="price">
                {({field}) => (
                  <div>
                    <div className="label">
                      <FormattedMessage {...messages.priceKgUSD} />
                    </div>
                    <div>
                      <Input
                        type="text"
                        error={touched.price && errors.price}
                        value={values.price}
                        name="price"
                        {...field}
                      />
                    </div>
                  </div>
                )}
              </Field>
            </div>

            {/* Description block */}
            <div className="acceptNewOrder__description">
              Order Amount: 322,716.35 USD
            </div>

            {/* Submit button */}
            <div className="acceptNewOrder__submit">
              <Button text={messages.acceptOrder} type="submit" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AcceptNewOrderModal;
