import React from "react";
import {connect} from "react-redux";
import {openRightModal} from "shared/components/RightModal/actions";
import {getCompanyCards} from "shared/shoppingService/selectors";
import {FormattedMessage} from "react-intl";
import CompanyCardItem from "./CompanyCardItem";
import messages from "../Company/messages";
import "./index.scss";

const ShoppingCompanyCards = ({companyCards, openRightModal}) => {
  return (
    <>
      <div className="corporate-cards-layout">
        <div className="corporate-cards-header">
          <FormattedMessage {...messages.corporatecardsheader} />
        </div>
        {companyCards.map((item, index) => (
          <CompanyCardItem item={item} key={index} />
        ))}
        <div
          className="add-card-button"
          onClick={() =>
            openRightModal({
              name: "shoppingCompanyCard",
              title: "New Shopping Card",
            })
          }
        >
          <span>
            <FormattedMessage {...messages.addcard} />
          </span>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = store => ({
  companyCards: getCompanyCards(store),
});

const mapDispatchToProps = {
  openRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCompanyCards);
