import React from "react";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import "./index.scss";
import {Link} from "react-router-dom";
//import { routes } from "../../shared/appUrls";
import imgFs from "images/land/phone-top-view-v4-dited@2x.png";

const LandingPage = () => {
  // function ready() {
  //   var x = document.getElementsByClassName('dwn');
  //   var Name = "Unknown OS";
  //   if (navigator.userAgent.indexOf("Win") != -1) Name =
  //     "Windows OS";
  //   if (navigator.userAgent.indexOf("Mac") != -1) Name =
  //     "Macintosh";
  //   if (navigator.userAgent.indexOf("Linux") != -1) Name =
  //     "Linux OS";
  //   if (navigator.userAgent.indexOf("Android") != -1) Name =
  //     "Android OS";
  //   if (navigator.userAgent.indexOf("like Mac") != -1) Name =
  //     "iOS";
  //   [].forEach.call(x, function (el) {
  //     el.href = '#' + Name;
  //     // el.innerHTML += ' ' + Name
  //   })
  //   // dwnlink.href = '#' + Name;
  // }
  function drop_d(e) {
    // console.log(e.target);
    //dd.classList.toggle('visible')
  }
  return (
    <>
      <div className="wrapper">
        <main>
          <div className="first__screen screen__page">
            <div className="background__figure_1"></div>
            <div className="background__figure_2"></div>
            <header>
              <div className="content">
                <Link to="/" className="logo"></Link>
                <ul className="menu">
                  <li>
                    <Link to="#">About</Link>
                  </li>
                  <li className="link">
                    <Link to="#">Download</Link>
                  </li>
                  <div className="drop__list dd">
                    <Link to="#" download="">
                      <i className="icon os windows"></i>OnWallet for Windows
                    </Link>
                    <Link to="#" download="">
                      <i className="icon os macos"></i>OnWallet for macOS X
                    </Link>
                  </div>
                  <li>
                    <Link to="/login">Log In</Link>
                  </li>
                  <li>
                    <Link to="/register">Register</Link>
                  </li>
                </ul>
              </div>
            </header>
            <div className="content first__screen_layer">
              <div className="first__screen_cont">
                <div className="screen__cont__action">
                  <h1 className="screen__cont_title">
                    Crypto & Digital Wallet, Redefined.
                  </h1>
                  <div className="drop">
                    <Link id="dwnlink" to="#" download="" className="drop__text">
                      Download OnWallet for Windows
                    </Link>
                    <div
                      className="drop__button"
                      onClick={e => drop_d(e)}
                      for="ddn"
                    ></div>
                    <div id="dd" className="drop__list dd">
                      <Link to="#" download="">
                        <i className="icon os windows"></i> OnWallet for Windows
                      </Link>
                      <Link to="#" download="">
                        <i className="icon os macos"></i>OnWallet for macOS X
                      </Link>
                    </div>
                  </div>
                  <div className="download__links">
                    <div className="download__links_title">Get OnWallet for mobile</div>
                    <div className="download__links_bloc">
                      <div className="download__links_qr"></div>
                      <div className="download__links_market">
                        <div className="download__links_apple"></div>
                        <div className="download__links_google"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <img className="screen__cont__image" src={imgFs} alt="" />
              </div>
            </div>
          </div>
          <div className="second__screen screen__page">
            <div className="background__wave_1"></div>
            <div className="background__figure_1"></div>
            <div className="background__figure_2"></div>
            <div className="content">
              <h2 className="screen__title">One Awesome Way</h2>
              <div className="screen_description">
                OnWallet is a new way of simple, smart and secure investing and trading.
                One Awesome way to banking.
              </div>
              <div className="screen_action">
                <div className="action__item">
                  <div className="action__item_img"></div>
                  <div className="action__item_description">
                    A multi-currency wallet management system, we’ve made OnWallet work
                    impeccably for buying, selling and trading our secure cryptocurrency,
                    Deho. But we’ve also made it work seamlessly for multi-asset
                    cryptocurrencies and FIAT currencies.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="third__screen screen__page">
            <div className="content">
              <h2 className="screen__title">The 0% Rule</h2>
              <div className="screen_description">
                OnWallet is a multi-currency block chain wallet supporting
                cryptocurrencies and FIAT currencies. Check out our fees.
              </div>
              <div className="block__fee first">
                <div className="fee__item">
                  <div className="fee__item_val">0%</div>
                  <div className="fee__item_desc">monthly fee</div>
                </div>
                <div className="fee__item">
                  <div className="fee__item_val">0%</div>
                  <div className="fee__item_desc">monthly fee</div>
                </div>
                <div className="fee__item">
                  <div className="fee__item_val">0%</div>
                  <div className="fee__item_desc">monthly fee</div>
                </div>
                <div className="fee__item">
                  <div className="fee__item_val">0%</div>
                  <div className="fee__item_desc">monthly fee</div>
                </div>
              </div>
            </div>
            <div className="screen_action video__action_block">
              <div className="background__for_video"></div>
              <button className="button_play_video"></button>
              <div className="button_play_video_text">Watch to discover more</div>
            </div>
            <div className="screen_action">
              <div className="content">
                <div className="screen_description">Low fees on other transactions</div>
                <div className="block__fee second">
                  <div className="fee__item">
                    <div className="fee__item_val">1,75%</div>
                    <div className="fee__item_desc">
                      flat charge on Deho & crypto trading
                    </div>
                  </div>
                  <div className="fee__item">
                    <div className="fee__item_val">3%</div>
                    <div className="fee__item_desc">
                      charge on global card & bank transactions
                    </div>
                  </div>
                  <div className="fee__item">
                    <div className="fee__item_val">3%</div>
                    <div className="fee__item_desc">flat on all currency conversions</div>
                  </div>
                </div>
                <Link to="#" download="" className="btn dwn">
                  Get OnWallet Now
                </Link>
              </div>
            </div>
          </div>
          <div className="foth__screen screen__page">
            <div className="background__figure_1"></div>
            <div className="background__figure_2"></div>
            <div className="background__figure_3"></div>
            <div className="background__figure_4"></div>
            <div className="background__figure_5"></div>
            <div className="background__figure_6"></div>
            <div className="background__figure_7"></div>
            <div className="background__figure_11"></div>
            <div className="background__figure_12"></div>
            <div className="background__figure_22"></div>
            <div className="content">
              <h2 className="screen__title">Deho Cryptocurrency</h2>
              <div className="screen_description">
                The Golden Rule of Cryptocurrency <br /> Deho Makes the New Rules.
              </div>
            </div>

            <div className="screen_action first">
              <div className="background__wave_2"></div>
              <div className="screen_action__img"></div>
            </div>
            <div className="content">
              <div className="screen_description">
                Deho gives Crypto a Face-Value: pure precious metals including but not
                limited to Gold!
              </div>
              <div className="screen_action second">
                <div className="action__item">
                  <h3 className="action__item_title">
                    Transact Real-time and Real-Value
                  </h3>
                  <div className="action__item_description">
                    Deho is Real value because all Deho coins are linked to real assets,
                    Gold, Silver and platinum. As a default, Deho is linked to Gold
                    ensuring all investments made on OnWallet using Deho are securer. We
                    developed high-speed mining to ensure vendors transact real-time and
                    instantly.
                  </div>
                </div>
                <div className="action__item">
                  <h3 className="action__item_title">
                    Hardware based End-to-End Security
                  </h3>
                  <div className="action__item_description">
                    Security first. Deho operates within a hybrid-decentralized
                    technology. Using hardware-based isolation, OnWallet reduces any
                    fraudulent transaction and intrusions during transactions and protects
                    records of transaction using the secure private key.
                  </div>
                </div>
                <div className="action__item">
                  <h3 className="action__item_title">
                    Your transaction is Super-Secure and Recoverable
                  </h3>
                  <div className="action__item_description">
                    Since OnWallet operates offline, single point failure is avoidable in
                    case of network failure, errors or system crash. Any fraudulent
                    transaction is recoverable in Deho using OnWallet in such cases.
                  </div>
                </div>
              </div>
              <div className="screen_description">
                We trade with popular crypto currencies
              </div>
              <div className="screen_action third">
                <div className="action__item">
                  <div className="action__item_img"></div>
                  <div className="action__item_delimeter"></div>
                </div>
                <div className="action__item">
                  <div className="action__item_img"></div>
                  <div className="action__item_delimeter"></div>
                </div>
                <div className="action__item">
                  <div className="action__item_img"></div>
                  <div className="action__item_delimeter"></div>
                </div>
                <div className="action__item">
                  <div className="action__item_img"></div>
                  <div className="action__item_delimeter"></div>
                </div>
                <div className="action__item">
                  <div className="action__item_img"></div>
                  <div className="action__item_delimeter"></div>
                </div>
                <div className="action__item">
                  <div className="action__item_img"></div>
                  <div className="action__item_delimeter"></div>
                </div>
                <div className="action__item">
                  <div className="action__item_img"></div>
                  <div className="action__item_delimeter"></div>
                </div>
                <div className="action__item">
                  <div className="action__item_img"></div>
                  <div className="action__item_delimeter"></div>
                </div>
                <div className="action__item">
                  <div className="action__item_img"></div>
                </div>
              </div>
              <Link to="#" download="" className="btn dwn">
                Trade Deho with OnWallet
              </Link>
            </div>
          </div>
          <div className="fifth__screen screen__page">
            <div className="background__figure_31"></div>
            <div className="background__figure_32"></div>
            <div className="background__figure_33"></div>
            <div className="background__figure_34"></div>
            <div className="content">
              <h2 className="screen__title">Rich Functionality</h2>
              <div className="screen_action">
                <div className="action__item first">
                  <div className="action__item_img">
                    <div className="action__item_img_phone">
                      <div className="item__1 visible"></div>
                      <div className="item__2"></div>
                      <div className="item__3"></div>
                      <div className="item__4"></div>
                    </div>
                  </div>
                  <div className="action__item_desc">
                    <h3 className="action__item_title">
                      On-spot. OnWallet. Locally, Internationally.
                    </h3>

                    <div className="action__item_description">
                      The OnWallet payment gateway is instantaneous. With one tap,
                      OnWallet lets you transfer/exchange FIAT or cryptocurrency anywhere,
                      real-time with the best exchange rate and no extra fees. That’s
                      fast, easy and cheap thanks to OnWallet’s accelerated mining.
                    </div>
                    <ul className="action__item_description__list">
                      <li className="item__hover" hattr="1">
                        <div className="item__img">
                          <i className="icon transfer"></i>
                        </div>
                        <div className="item__description">
                          <h4>Transfer</h4>
                          <div>
                            Transfer FIAT or Cryptocurrencies to anyone and anywhere in a
                            faster and easiest way than traditions. With Deho’s high-speed
                            crypto, the coin transfer between users or merchants will be
                            instant than any other cryptocurrencies.
                          </div>
                        </div>
                      </li>
                      <li className="item__hover" hattr="2">
                        <div className="item__img">
                          <i className="icon recive"></i>
                        </div>
                        <div className="item__description">
                          <h4>Receive</h4>
                          <div>
                            With OnWallet, personal and business account holders can send
                            payment request invoice to anyone. The sender can pay
                            instantly from different payment sources like OnWallet wallet,
                            Crypto wallet, Card, Shopping, and Shopping Rewards linked
                            third-party e-wallets.
                          </div>
                        </div>
                      </li>
                      <li className="item__hover" hattr="3">
                        <div className="item__img">
                          <i className="icon exchange"></i>
                        </div>
                        <div className="item__description">
                          <h4>Exchange</h4>
                          <div>
                            With OnWallet, user can exchange from Deho to other
                            cryptocurrencies or FIAT currency and vice-versa at 3% flat
                            currency conversion fee.
                          </div>
                        </div>
                      </li>
                      <li className="item__hover" hattr="4">
                        <div className="item__img">
                          <i className="icon nfc"></i>
                        </div>
                        <div className="item__description">
                          <h4>NFC Pay</h4>
                          <div>
                            Partnering with the world-className providers and exchange
                            platforms, OnWallet has developed a Virtual and Physical Card
                            with NFC support that lets you do more. With OnWallet card,
                            make payment, trading, buying and selling simple at anyplace
                            and anytime.
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="background__circle_4"></div>
                <div className="background__figure_14"></div>
                <div className="action__item second">
                  <div className="action__item_img">
                    <div className="action__item_img_phone">
                      <div className="item__5 visible"></div>
                      <div className="item__6"></div>
                      <div className="item__7"></div>
                    </div>
                  </div>
                  <div className="action__item_desc">
                    <h3 className="action__item_title">Buy. Save. Gain. Grow.</h3>

                    <div className="action__item_description">
                      With OnWallet, buying Deho cryptocurrency is an investing in what e
                      called MVV (Minimum Valid Value) in gold and/or silver and/or
                      platinum. The only cryptocurrency that allows the users to choose
                      the most precious metals to link with the Deho coin.
                    </div>
                    <ul className="action__item_description__list">
                      <li className="item__hover" hattr="5">
                        <div className="item__img">
                          <i className="icon trade"></i>
                        </div>
                        <div className="item__description">
                          <h4>Trade Deho and/or Another cryptocurrencies</h4>
                          <div>
                            OnWallet is a platform to make cryptocurrencies trading secure
                            yet simple. The OnWallet marketplace lets you trade other
                            cryptocurrincies and digital assets efficiently in few taps
                            from mobile and desktop App.
                          </div>
                        </div>
                      </li>
                      <li className="item__hover" hattr="6">
                        <div className="item__img">
                          <i className="icon manage"></i>
                        </div>
                        <div className="item__description">
                          <h4>OnWallet Business</h4>
                          <div>
                            With OnWallet business account, merchants can receive the
                            payments from the customers instantly in different formats of
                            currencies without any border restrictions. Merchants can
                            issue product offers, vouchers instantly to the users and make
                            the environment green.
                          </div>
                        </div>
                      </li>
                      <li className="item__hover" hattr="7">
                        <div className="item__img">
                          <i className="icon observe"></i>
                        </div>
                        <div className="item__description">
                          <h4>Shopping Cards</h4>
                          <div>
                            With OnWallet virtual shopping card, OnWallet account holders
                            can simply collect and redeem the rewards, gifts, loyalty
                            points, vouchers, e-tickets, etc.
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="background__figure_15"></div>

                <div className="background__circle_5"></div>

                <div className="background__circle_6"></div>
              </div>
            </div>
            <div className="global__screen">
              <div className="global__screen_img"></div>
            </div>
          </div>
          <div className="sixth__screen screen__page">
            <div className="background__figure_43"></div>
            <div className="background__figure_44"></div>
            <div className="background__figure_45"></div>

            <div className="content">
              <h2 className="screen__title">Frequently Asked Questions</h2>
              <div className="screen_action">
                <ul className="question__list">
                  <li className="question__item">
                    <input type="checkbox" name="" id="q1" />
                    <label for="q1">
                      <div className="question__text">
                        {" "}
                        When will OnWallet be available?
                      </div>
                      <div className="question__answer">
                        OnWallet is currently being built. Sign up here to be one of the
                        first to know about OnWallet.
                      </div>
                    </label>
                  </li>
                  <li className="question__item">
                    <input type="checkbox" name="" id="q2" />
                    <label for="q2">
                      <div className="question__text">What do I need to get started?</div>
                      <div className="question__answer">
                        When OnWallet is available, you will need a government-issued ID
                        to sign up for an account. Identity verification is important to
                        comply with laws and prevent fraud, so you know people are who
                        they say they are.
                      </div>
                    </label>
                  </li>
                  <li className="question__item">
                    <input type="checkbox" name="" id="q3" />
                    <label for="q3">
                      <div className="question__text">How can I use OnWallet?</div>
                      <div className="question__answer">
                        OnWallet will be available as a stand-alone app in the App Store,
                        Google Play and Desktop installers. With our stand-alone app, you
                        can send and receive money as easily as you message friends,
                        family, and businesses.
                      </div>
                    </label>
                  </li>
                  <li className="question__item">
                    <input type="checkbox" name="" id="q4" />
                    <label for="q4">
                      <div className="question__text">What can I use OnWallet for?</div>
                      <div className="question__answer">
                        OnWallet is designed for globally recognized FIAT currencies and
                        popular cryptocurrencies. You can use OnWallet to send money
                        around the world. You can also keep Deho cryptocurrency in your
                        OnWallet wallet and use it to pay for everyday transactions
                        wherever it is accepted.
                      </div>
                    </label>
                  </li>
                  <li className="question__item">
                    <input type="checkbox" name="" id="q5" />
                    <label for="q5">
                      <div className="question__text">
                        {" "}
                        When will OnWallet be available?
                      </div>
                      <div className="question__answer">
                        What you send is what they get. You can add, send, receive, and
                        withdraw money from your wallet without worrying about hidden
                        charges. OnWallet is cutting fees to help people keep more of
                        their money.
                      </div>
                    </label>
                  </li>
                  <li className="question__item">
                    <input type="checkbox" name="" id="q6" />
                    <label for="q6">
                      <div className="question__text">Where is OnWallet available?</div>
                      <div className="question__answer">
                        OnWallet is available to everyone, anywhere in the world. We’re
                        working to bring OnWallet to as many countries as possible.
                      </div>
                    </label>
                  </li>
                  <li className="question__item">
                    <input type="checkbox" name="" id="q7" />
                    <label for="q7">
                      <div className="question__text">
                        Can I use my local currency in OnWallet?
                      </div>
                      <div className="question__answer">
                        OnWallet is designed for globally recognized FIAT currencies and
                        popular cryptocurrencies, so when you send and spend your money,
                        you’ll be using the currency you choose. Through OnWallet, you can
                        use your local currency to purchase Deho, and also convert Deho
                        back to your local currency when you want to withdraw.
                      </div>
                    </label>
                  </li>
                  <li className="question__item">
                    <input type="checkbox" name="" id="q8" />
                    <label for="q8">
                      <div className="question__text">How will you prevent fraud?</div>
                      <div className="question__answer">
                        Accounts and transactions are verified, and fraud prevention is
                        built in throughout the mobile and desktop app. OnWallet uses
                        government-issued IDs to verify customers, so you can trust people
                        are who they say they are. OnWallet also has in-app reporting and
                        dedicated customer care. In the event of fraud, you will be
                        eligible to receive a full refund.
                      </div>
                    </label>
                  </li>
                  <li className="question__item">
                    <input type="checkbox" name="" id="q9" />
                    <label for="q9">
                      <div className="question__text">Can businesses use OnWallet?</div>
                      <div className="question__answer">
                        OnWallet merchant services will help businesses big and small
                        accept OnWallet and Deho online, in-app, and in-store.
                      </div>
                    </label>
                  </li>
                  <li className="question__item">
                    <input type="checkbox" name="" id="q10" />
                    <label for="q10">
                      <div className="question__text">
                        What is the OnWallet payment system?
                      </div>
                      <div className="question__answer">
                        The OnWallet payment system is a secure and scalable platform;
                        Deho cryptocurrency that are backed by a reserve of assets made up
                        of Gold, Silver and Platinum.
                      </div>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="seventh__screen screen__page">
            <div className="background__figure_54"></div>

            <div className="content">
              <h2 className="screen__title">
                One-Stop Crypto Trading and Wallet Solution
              </h2>
              <div className="screen_action">
                <Link to="#" download="" className="btn dwn">
                  Download OnWallet
                </Link>
                <div className="download__links_market">
                  <Link to="#" className="download__links_apple"></Link>
                  <Link to="#" className="download__links_google"></Link>
                </div>
              </div>
            </div>
          </div>
        </main>
        <footer>
          <div className="background__wave_3"></div>
          <div className="content">
            <div className="fline">
              <Link to="/" className="logo"></Link>
              <ul className="menu">
                <li>
                  <Link to="#">
                    <i className="icon os ios"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon os macos"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon os android"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon os windows"></i>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer__text_section">
              Banking services provided by OnWallet or Members FDIC. OnWallet Visa® Debit
              Card is issued by The Bank pursuant to a license from Visa U.S.A. Inc. and
              may be used everywhere Visa debit cards are accepted. The OnWallet Visa®
              Credit Builder Card is issued by Bank pursuant to a license from Visa U.S.A.
              Inc. and may be used everywhere Visa credit cards are accepted. Please see
              back of your Card for its issuing bank. Please note: By clicking on some of
              the links above, you can leave OnWallet website and be directed to an
              external website. The privacy policies of the external website may differ
              from our privacy policies. Please review the privacy policies and security
              indicators displayed on the external website before providing and personal
              information.
            </div>
            <div className="footer__text__privacy">
              <div className="footer__text__copyright">
                Ⓒ 2020 OnWallet. All rights reserved.
              </div>
              <Link to="#" className="footer__text__privacy_policy">
                Privacy Policy
              </Link>
            </div>
          </div>
        </footer>
      </div>
      <div className="overflow">
        <div className="no_design">design in progress</div>
      </div>
    </>
  );
};

const mapStateToProps = store => ({});
const mapDispatchToProps = {};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(LandingPage));
