import React from "react";
import "./index.scss";
import config from "shared/config";
const deleteIcon = config.prefixImgUrl + "delete-icon.svg";

const RegisterFileList = ({list, onDelete, ...props}) => {
  return (
    <div className="RegisterFilesList" {...props}>
      {list.map((file, index) => {
        return (
          <div key={index} className="fileItem">
            <div className="name">
              {index}. {file}
            </div>
            <img
              src={deleteIcon}
              className="deleteIcon"
              alt="delete"
              onClick={e => onDelete(index, e)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default RegisterFileList;
