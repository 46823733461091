import axios from "axios";
import {HistoryEndpoints} from "./apiEndpoints";

export const historyAPIGetTransactions = (data, option) => {
  return axios.post(HistoryEndpoints.history, data, {
    ...option,
  });
};

export const historyAPIGetRegularBalance = option => {
  return axios.get(HistoryEndpoints.regularHistory, {
    ...option,
  });
};

export const historyAPIGetCryptoBalance = option => {
  return axios.get(HistoryEndpoints.cryptoHistory, {
    ...option,
  });
};
