import React, {useState} from "react";
import "index.scss";
import {connect} from "react-redux";
import moment from "moment";
import CustomSelect from "shared/components/Select";
// import {FormattedMessage} from "react-intl";
// import messages from "../../../messages";

import Barcode from "react-barcode";
import InputUniversal from "shared/components/InputUniversal";
import Input from "shared/components/Input";
import {
  voucherUserDelete,
  voucherTransferOnWallet,
  voucherTransferEmail,
} from "shared/shoppingService/actions";

import {closeRightModal} from "shared/components/RightModal/actions";
import {selectUserProfile} from "shared/auth/selectors";

import config from "shared/config";

const onWalletIcon = config.prefixImgUrl + "onwallet-icon-short_v1.svg";
const contactIcon = config.prefixImgUrl + "contact-icon.svg";
const emailIcon = config.prefixImgUrl + "email-icon.svg";
const phoneIcon = config.prefixImgUrl + "phone-icon.svg";

const cardShortDescription = (discount, validTill) => {
  return (
    <div className="card_short_info">
      <div className={"block"}>
        <div className="block_value">{discount}%</div>
        <div className="block_text">discount</div>
      </div>
      <div className={"block"}>
        <div className="block_value">{validTill}</div>
        <div className="block_text">valid till</div>
      </div>
    </div>
  );
};

const voucherShortDescription = dataMain => {
  return (
    <>
      <div className={"short_desc"}>{dataMain.title}</div>
      <div className={"shortInfo"}>
        valid till {moment(dataMain.expirationDate).format("DD.MM.YYYY")}
      </div>
    </>
  );
};

const ManageForm = ({
  type,
  closeRightModal,
  voucherUserDelete,
  voucherData,
  cardData,
  voucherTransferOnWallet,
  voucherTransferEmail,
}) => {
  const [transfer, setTransfer] = useState("");
  const [deleteFlag, setDeleteFlag] = useState("");
  const [enterData, setEnterData] = useState("");
  const [price, setPrice] = useState("");
  const [selectedOption, setSelectedOption] = useState("OnWallet Account");
  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const currency = ["USD", "DHG"];
  const options = ["OnWallet Account", "E-mail", "Phone Number"];
  const ONWALLET = options[0];
  const EMAIL = options[1];
  const PHONE = options[2];

  let dataMain;
  let shortInfo;

  if (type === "card") {
    dataMain = cardData;
    shortInfo = cardShortDescription(10, "01.01.2021");
  } else {
    dataMain = voucherData;
    shortInfo = voucherShortDescription(dataMain);
  }

  const handlerChangeEnterData = data => {
    setEnterData(data);
  };

  const handlerSubmit = () => {
    const payload = {
      voucherId: dataMain.voucherId,
      merchantId: dataMain.merchantId,
      currency: selectedCurrency,
      price: +price,
    };

    switch (selectedOption) {
      case ONWALLET:
        payload.recipientAccount = enterData;
        voucherTransferOnWallet(payload);
        break;
      case EMAIL:
        payload.recipientEmail = enterData;
        voucherTransferEmail(payload);
        break;
      case PHONE:
        payload.recipientPhone = enterData;
        // TODO request
        break;
      default:
        break;
    }
  };

  // Error handler
  if (dataMain === undefined) return false;

  return (
    <div className={"wrapper_shopping_modal"}>
      <div className={"wrapper_image_barcode"}>
        <div className={"image"}>
          <img src="http://www.alluserpics.com/data/media/29/01911.jpg" alt="alt_image" />
        </div>
        <div className={"barcode"}>
          <Barcode
            value="1234567890"
            format="CODE128"
            displayValue={false}
            width={4}
            height={100}
            margin={0}
          />
        </div>
      </div>
      {shortInfo}
      <div className={"form_tracfer " + (transfer === "" ? "" : "active")}>
        <button
          className={`button ${transfer}`}
          onClick={() => {
            setTransfer("hide");
            setDeleteFlag("");
          }}
        >
          Transfer to other User
        </button>
        <div className={"transfer_window " + (transfer === "" ? "hide" : "")}>
          <div className="header">Transfer Coupon to other User</div>
          <div className="message">
            Transfer proposal will be sent to other user of OnWallet. The user should
            accept card transfer, after acceptance it will be transferred from you.
          </div>
          <div className="recall_transfer hide">
            <div className="message">
              Proposal to transfer this coupon for USD 10.00 was sent to
              anymail@gmail.com. Waiting for transfer acceptance. Till transfer is not
              accepted you can recall it.
            </div>
            <div className="actions">
              <button className={"button"}>Recall Transfer</button>
            </div>
          </div>
          <div className={"formik " + (transfer === "" ? "hide" : "")}>
            <div className="sendToContainer">
              <label className="label">Send to</label>
              <CustomSelect
                className="select"
                value={selectedOption}
                options={options}
                onChange={e => {
                  setSelectedOption(e.target.value);
                  setEnterData("");
                }}
              />
            </div>
            <div className="sendToContainer accounts">
              {selectedOption === EMAIL && (
                <div>
                  <label className="label">E-mail</label>
                  <InputUniversal
                    type="text"
                    leftIcon={emailIcon}
                    rightIcon={contactIcon}
                    iconStyles="onWalletIcon"
                    placeholder="Paste wallet"
                    typeContact="email"
                    suffix="John Smith"
                    enterData={enterData}
                    setEnterData={handlerChangeEnterData}
                  />
                </div>
              )}
              {selectedOption === ONWALLET && (
                <div>
                  <label className="label">OnWallet Account #</label>
                  <InputUniversal
                    type="text"
                    leftIcon={onWalletIcon}
                    rightIcon={contactIcon}
                    iconStyles="onWalletIcon"
                    placeholder="Paste wallet"
                    typeContact="onWalletAccount"
                    suffix="John Smith"
                    enterData={enterData}
                    setEnterData={handlerChangeEnterData}
                  />
                </div>
              )}
              {selectedOption === PHONE && (
                <div>
                  <label className="label">Phone Number</label>
                  <InputUniversal
                    type="text"
                    leftIcon={phoneIcon}
                    rightIcon={contactIcon}
                    iconStyles="onWalletIcon"
                    placeholder="Paste phone"
                    typeContact="phone"
                    suffix="John Smith"
                    enterData={enterData}
                    setEnterData={handlerChangeEnterData}
                  />
                </div>
              )}
            </div>
            <div className="pricing">
              <div className="sendToContainer amount">
                <label className="label">Proposal Price</label>
                <Input
                  type="number"
                  value={price}
                  onChange={e => setPrice(e.target.value)}
                />
              </div>
              <div className="sendToContainer currency">
                <label className="label">Currency</label>
                <CustomSelect
                  className="select"
                  disabled
                  value={selectedCurrency}
                  options={currency}
                  onChange={e => setSelectedCurrency(e.target.value)}
                />
              </div>
            </div>
            <div className="actions">
              <button
                className={"button"}
                onClick={() => {
                  setTransfer("");
                }}
              >
                Cancel
              </button>
              <button
                className={"button"}
                onClick={() => {
                  setTransfer("hide");
                  handlerSubmit();
                  closeRightModal();
                }}
              >
                Transfer Coupon
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={"form_delete " + (deleteFlag === "" ? "" : "active")}>
        <button
          className={"button button_red " + (deleteFlag === "" ? "" : "hide")}
          onClick={() => {
            setDeleteFlag("hide");
            setTransfer("");
          }}
        >
          Delete
        </button>
        <div className={"delete_message " + (deleteFlag === "" ? "hide" : "")}>
          <div className="header">Coupon Deletion</div>
          <div className="message">
            This coupon will be deleted from OnWallet Service permanently.
          </div>
          <div className="actions">
            <button
              className={"button"}
              onClick={() => {
                setDeleteFlag("");
              }}
            >
              Cancel
            </button>
            <button
              className={"button button_red"}
              onClick={() => {
                voucherUserDelete(dataMain.voucherId, dataMain.merchantId);
                closeRightModal();
              }}
            >
              Delete coupon
            </button>
          </div>
        </div>
      </div>
      <div className={"description"}>
        Description: On the other hand, we denounce with righteous indignation and dislike
        men who are so beguiled and demoralized by the charms of pleasure of the moment,
        so blinded by desire, that they cannot foresee the pain and trouble that are bound
        to ensue; and equal blame belongs to those who fail in their duty through weakness
        of will, which is the same as saying through shrinking from toil and pain. These
        cases are perfectly simple and easy to distinguish. In a free hour, when our power
        of choice is untrammelled and when nothing prevents our being able to do what we
        like best, every pleasure is to be welcomed and every pain avoided.
      </div>
    </div>
  );
};

const mapStateToProps = store => ({
  userProfile: selectUserProfile(store),
  voucherData: store.shoppingReducer.shoppingVoucherData,
  cardData: store.shoppingReducer.shoppingCardData,
});

const mapDispatchToProps = {
  closeRightModal,
  voucherUserDelete: (voucherId, merchantId) => voucherUserDelete(voucherId, merchantId),
  voucherTransferOnWallet: data => voucherTransferOnWallet(data),
  voucherTransferEmail: data => voucherTransferEmail(data),
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageForm);
