import React, {useEffect} from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import {dataRightModal} from "shared/components/RightModal/selectors";
import {getAllRegularCurrency, getOnwalletAccount} from "shared/cardService/selectors";
import {fetchAllRegularCurrency} from "shared/cardService/actions";
import "./index.scss";
import messages from "../../messages";

import Select from "shared/components/Select";
import Input from "shared/components/Input";
import Button from "shared/components/Button";
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import {OnWalletOption} from "shared/components/CardWalletOptions";
import Textarea from "shared/components/Input/Textarea";
import {DetectOnWalletAccount} from "../TransferModal/components";
import {openRightModal} from "shared/components/RightModal/actions";

const initialValues = {
  onWallet: null,
  amount: "0",
  currency: "USD",
  description: "",
};

const ReceiveOnWalletSchema = Yup.object().shape({
  onWallet: Yup.object()
    .nullable()
    .default(null)
    .required("OnWallet is required"),
  amount: Yup.string()
    .required("Amount is required")
    .matches(
      "([1-9]\\d*(\\.\\d*[1-9])?|0\\.\\d*[1-9]+)|\\d+(\\.\\d*[1-9])?",
      "Amount must be a number"
    ),
  currency: Yup.string().required("Currency is required"),
});

const ReceiveToOwnOnWallet = ({
  allCurrency,
  fetchAllRegularCurrency,
  onWalletAccount,
  openRightModal,
  customer,
}) => {
  // TODO: Optimization for customer (admin)
  useEffect(() => {
    if (allCurrency.length === 0) {
      fetchAllRegularCurrency();
    }
  }, [allCurrency, fetchAllRegularCurrency]);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ReceiveOnWalletSchema}
      onSubmit={(values, actions) => {
        openRightModal("receiveOnWalletDetails", {
          details: {
            recipient: values.onWallet.account,
            currency: values.currency,
            amount: values.amount,
            description: values.description,
          },
        });
      }}
    >
      {({errors, touched, values}) => (
        <div className="ReceiveForm">
          <DetectOnWalletAccount field="onWallet" onWalletAccount={onWalletAccount} />
          <Form className="form">
            <FormSectionSeparator sectionName={messages.details} />
            <Field
              name="onWallet"
              render={({field}) => (
                <div className="cardInputContainer">
                  <label className="label">
                    {!!customer ? (
                      <FormattedMessage {...messages.recipient} />
                    ) : (
                      <FormattedMessage {...messages.myOnWalletAccount} />
                    )}
                  </label>
                  <OnWalletOption account={field.value} />
                </div>
              )}
            />
            <div className="AmountCurrency">
              <Field
                name="amount"
                render={({field}) => (
                  <div className="amountInputContainer">
                    <label className="label">
                      <FormattedMessage {...messages.invoiceAmount} />
                    </label>
                    <Input error={touched.amount && errors.amount} {...field} />
                  </div>
                )}
              />
              <Field
                name="currency"
                render={({field}) => (
                  <div className="currencyInputContainer">
                    <label className="label">
                      <FormattedMessage {...messages.currency} />
                    </label>
                    <Select
                      className="select"
                      error={touched.currency && errors.currency ? errors.currency : ""}
                      {...field}
                      options={allCurrency.map(currency =>
                        currency.currency.toUpperCase()
                      )}
                    />
                  </div>
                )}
              />
            </div>
            <Field
              name="description"
              render={({field}) => (
                <div className="descriptionInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.descriptionOptional} />
                  </label>
                  <Textarea
                    placeholder="Write an optional description"
                    type="text"
                    {...field}
                  />
                </div>
              )}
            />
            <Button text={messages.generateInvoice} />
          </Form>
        </div>
      )}
    </Formik>
  );
};

const mapStateToProps = store => ({
  allCurrency: getAllRegularCurrency(store),
  onWalletAccount: getOnwalletAccount(store),
  customer: dataRightModal(store),
});

const mapDispatchToProps = {
  fetchAllRegularCurrency,
  openRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReceiveToOwnOnWallet);
