import React, {Fragment} from "react";
import {FIRST_SECTION, TERM_OF_USE, LICENSE_OUR_SERVICES, POLICIES} from "./constance";

const TermsAndConditions = () => {
  return (
    <Fragment>
      <div className="document-wrapper text__container_article">
        <div>
          <span>OnWallet</span> is a payment facilitator between customers and the service
          providers, merchants or any third party service providers. You can transfer,
          receive exchange or pay by NFC easily by using our application. It is a next
          generation of money management, payment and trading platform or application.
          <br />
          To make our Services more open, safe, secure and inclusive in order to comply
          with the laws of the land, we provide the following
          <span> Terms & Conditions</span> for availing services provided by us.
          <br />
          These terms of Service explain how ONWALLET LTD., works and our terms and
          conditions reflect as per the law which is applicable to our Company and all the
          facts and terms, we believe which are true to our knowledge and belief.
          <br />
          We permit you to use our service as long as you continue with your
          responsibilities. Though we allow you to use our service we retain our
          Intellectual Property rights.
          <br />
          Our resources include Privacy policy, help centre to interact others. To
          maintain a good relationship, we expect you to follow the following rules of
          conduct.
        </div>

        <div className="document-wrapper-restrictions__items mt-4">
          {FIRST_SECTION.map((item, index) => (
            <div className="document-wrapper-restrictions__items__item" key={index}>
              <div>{item.id}</div>
              <div>{item.text}</div>
            </div>
          ))}
        </div>

        <span className="mt-4">License to use our Services:</span>
        <div>To use our services you must comply with the following conditions;</div>
        <div className="document-wrapper-restrictions__items">
          {LICENSE_OUR_SERVICES.map((item, index) => (
            <div className="document-wrapper-restrictions__items__item" key={index}>
              <div>{item.id}</div>
              <div>{item.text}</div>
            </div>
          ))}
        </div>

        <div className="mt-6 text__container_title">1. Definitions:</div>

        <div>
          <ul>
            <li>
              <span>OnWallet, Us, We;</span> are the owner, creator, operator and
              publisher of the website/app. OnWallet, Us, We, Our, Ours and Other first
              person pronouns shall refer to the owner including but not limited to our
              affiliate, agents, directors and employees.
            </li>
            <li>
              <span>You, Your, the User, the Client, Customer </span>
              refers to User of the website/app and will be referred throughout these term
              & conditions with second person pronouns such as You, Yours, User, Customer
              and Client.
            </li>
            <li>
              <span>OnWallet Account: </span>
              The account you create by entering the required details on our Mobile
              Application or Website.
            </li>
            <li>
              <span>Governing Law: </span>
              The existing laws, rules, regulations, guidelines, notices and notifications
              issued by the Government from time to time.
            </li>
            <li>
              <span>OnWallet Services: </span>
              The services provided by us through our Mobile Application or Website.
            </li>
            <li>
              <span>Mobile Application (App): </span> A software programme or application
              that can be used on a Mobile, Tablet or any other Electronic device.
            </li>
            <li>
              <span>Know Your Customer (KYC): </span>
              The basic details given by the Customers while creating an account on
              OnWallet.
            </li>
            <li>
              <span>UPI: </span>
              UPI means Unified Payment Interface which helps in Inter-bank transactions.
            </li>
            <li>
              <span>Agreement/Contract: </span>
              The present Terms & Conditions mentioned in this agreement is a legally
              binding document and a valid contract between You and Us.
            </li>
            <li>
              <span>Confidential Information: </span>
              Information regarding Debit/Credit Card details and any other information
              that is shared with us that requires being confidential.
            </li>
          </ul>
        </div>

        <div className="mt-6 text__container_title">
          2. Terms of use of this agreement
        </div>

        <div className="document-wrapper-restrictions__items mt-4">
          {TERM_OF_USE.map((item, index) => (
            <div className="document-wrapper-restrictions__items__item" key={index}>
              <div>{item.id}</div>
              <div>
                <section>{item.text}</section>
                {item.upperText && <section>{item.upperText}</section>}
                {item.details &&
                  item.details.map((detailsItem, index) => (
                    <div
                      className="document-wrapper-restrictions__items__item"
                      key={index}
                    >
                      <div>{detailsItem.id}</div>
                      <div>
                        <section>{detailsItem.text}</section>
                        {detailsItem.details &&
                          detailsItem.details.map((item, index) => (
                            <div
                              className="document-wrapper-restrictions__items__item"
                              key={index}
                            >
                              <div>{item.id}</div>
                              <div>{item.text}</div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>

        <div className="mt-6 text__container_title">3. POLICIES</div>

        <div className="document-wrapper-restrictions__items">
          {POLICIES.map((item, index) => (
            <div className="document-wrapper-restrictions__items__item" key={index}>
              <div>{item.id}</div>
              <div>
                <section>{item.text}</section>
                {item.upperText && <section className="mt-4">{item.upperText}</section>}
                {item.details &&
                  item.details.map((detailsItem, index) => (
                    <div
                      className="document-wrapper-restrictions__items__item"
                      key={index}
                    >
                      <div>{detailsItem.id}</div>
                      <div>
                        <section>{detailsItem.text}</section>
                        {detailsItem.details &&
                          detailsItem.details.map((item, index) => (
                            <div
                              className="document-wrapper-restrictions__items__item"
                              key={index}
                            >
                              <div>{item.id}</div>
                              <div>{item.text}</div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
                {item.lowerText && <div className="mt-4">{item.lowerText}</div>}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default TermsAndConditions;
