import React from "react";
import "./index.scss";

const WalletBalance = ({type, balance, walletName, onClick}) => {
  return (
    <div className="wallet-balance" onClick={onClick}>
      <span className="wallet-balance__balance">{balance}</span>
      <span className="wallet-balance__title">{walletName}</span>
    </div>
  );
};

export default WalletBalance;
