import React, {useState} from "react";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {Formik, Form} from "formik";
import {Scrollbars} from "react-custom-scrollbars";
// Actions
import {closeRightModal} from "shared/components/RightModal/actions";
// Components
import FirstRegisterStep from "../components/FirstRegisterStep";
import SecondRegisterStep from "../components/SecondRegisterStep";
import ThirdRegisterStep from "../components/ThirdRegistrationStep";
import FourthRegisterStep from "../components/FourthRegistrationStep";
import FifthRegisterStep from "../components/FifthRegisterStep";
// Styles
import "./index.scss";
// Utils
import {NewCustomerSchema} from "./validation";

const NewCustomerModal = () => {
  const [step, setStep] = useState(1);

  const initFormik = {
    type: "person",
    email: "",
    firstName: "",
    lastName: "",
    companyName: "",
    companySite: "",
    country: "",
    zip: "",
    street: "",
    apartment: "",
    region: "",
    city: "",
    bill: "",
    billFile: "",
    companyNumber: "",
    certificate: "",
    memorandum: "",
    taxID: "",
    validFilesFront: "",
    validFilesBack: "",
    validFilesPhotoCustomer: "",
    dialCode: "",
    phoneNumber: "",
    specialization: "",
  };

  const handlerSubmit = values => {
    console.log("SUBMIT values: ", values);
  };

  return (
    <Formik
      initialValues={initFormik}
      validationSchema={NewCustomerSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={values => handlerSubmit(values)}
    >
      {({
        errors,
        touched,
        values,
        handleSubmit,
        setFieldValue,
        validateForm,
        setErrors,
      }) => (
        <div className="customer-register">
          <div className="customer-register__container">
            {step < 5 && <StepLine step={step} setStep={setStep} />}
            <Scrollbars
              renderTrackVertical={props => (
                <div {...props} className="track-vertical " />
              )}
              renderThumbVertical={props => (
                <div {...props} className="thumb-vertical " />
              )}
            >
              <Form className="customer-register__container-form">
                <FirstRegisterStep
                  type={values.type}
                  setFieldValue={setFieldValue}
                  setStep={setStep}
                  step={step}
                  errors={errors}
                  values={values}
                  validateForm={validateForm}
                  setErrors={setErrors}
                />
                <SecondRegisterStep
                  type={values.type}
                  setFieldValue={setFieldValue}
                  setStep={setStep}
                  step={step}
                  touched={touched}
                  errors={errors}
                  values={values}
                  validateForm={validateForm}
                  setErrors={setErrors}
                />
                <ThirdRegisterStep
                  setFieldValue={setFieldValue}
                  setStep={setStep}
                  step={step}
                  errors={errors}
                  values={values}
                  validateForm={validateForm}
                  setErrors={setErrors}
                />
                <FourthRegisterStep
                  setFieldValue={setFieldValue}
                  setStep={setStep}
                  step={step}
                  errors={errors}
                  values={values}
                  validateForm={validateForm}
                  handleSubmit={handleSubmit}
                />
                <FifthRegisterStep
                  step={step}
                  validateForm={validateForm}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  values={values}
                />
              </Form>
            </Scrollbars>
          </div>
        </div>
      )}
    </Formik>
  );
};

const StepLine = ({step, setStep}) => {
  return (
    <div className="stepLineContainer">
      <div className="stepLine" />
      {step > 0 && (
        <div className="stepButton" onClick={() => setStep(1)}>
          <div className={`circle ${step === 1 ? "active" : ""}`}>1</div>
        </div>
      )}
      {step > 1 && (
        <div className="stepButton" onClick={() => setStep(2)}>
          <div className={`circle ${step === 2 ? "active" : ""}`}>2</div>
        </div>
      )}
      {step > 2 && (
        <div className="stepButton" onClick={() => setStep(3)}>
          <div className={`circle ${step === 3 ? "active" : ""}`}>3</div>
        </div>
      )}
      {step > 3 && (
        <div className="stepButton" onClick={() => setStep(4)}>
          <div className={`circle ${step === 4 ? "active" : ""}`}>4</div>
        </div>
      )}
    </div>
  );
};

// Redux
const mapStateToProps = state => ({});

const mapDispatchToProps = {
  closeRightModal,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(NewCustomerModal));
