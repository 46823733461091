import axios from "axios";
import {tradingEndpoints} from "./apiEndpoints";

export const tradingAPIGraphGetData = (option, time = 1, limit = 12) => {
  const _data = {
    type: time,
    cryptoCurrency: "dho",
    currency: "usd",
    size: limit,
  };
  return axios.post(tradingEndpoints.graph, _data, option);
};

export const tradingAPIGetChartData = (option, data) => {
  return axios.post(tradingEndpoints.getTradeGraph, data, {
    ...option,
  });
};

export const tradingAPIGetChartDataHeader = (option, data) => {
  return axios.post(tradingEndpoints.getTradeGraphHeader, data, {
    ...option,
  });
};
