import moment from "moment";
import _ from "lodash";

const convertApiStatus = apiStatus => {
  switch (apiStatus) {
    case "InProgress":
      return "Pending";
    case "Failed":
      return "Canceled";
    case "Completed":
      return "Confirmed";
    default:
      return "Unknown";
  }
};

export const isBalanceLoading = store =>
  store.balanceHistoryData.isDehoLoading || store.balanceHistoryData.isOnWalletLoading;

export const getBalanceData = store => {
  let prevDeho = 0;
  let prevOnWallet = 0;
  const onwalletData = {};
  const dehoData = {};
  const result = {};
  if (store.balanceHistoryData.dehoHistory) {
    store.balanceHistoryData.dehoHistory.forEach((item, index) => {
      dehoData[item.date] = item.balance;
    });
  }
  if (store.balanceHistoryData.onwalletHistory) {
    store.balanceHistoryData.onwalletHistory.forEach((item, index) => {
      onwalletData[item.date] = item.balance;
    });
  }
  Object.entries(dehoData).forEach(([key, value]) => {
    if (onwalletData[key]) {
      prevOnWallet = onwalletData[key];
    } else {
      onwalletData[key] = prevOnWallet;
    }
    result[key] = {onwallet: onwalletData[key], deho: value};
  });
  Object.entries(onwalletData).forEach(([key, value]) => {
    if (dehoData[key]) {
      prevDeho = dehoData[key];
    } else {
      dehoData[key] = prevDeho;
    }
    result[key] = {deho: dehoData[key], onwallet: value};
  });
  return Object.entries(result)
    .map(([key, value]) => ({...value, day: key}))
    .sort((a, b) => new Date(a.day) - new Date(b.day));
};

export const getAllTransactions = store => {
  return store.transactionsHistoryData.transactions;
};

export const getHistoryDays = store => {
  let days = [];

  const parsedTransactions = store.transactionsHistoryData.transactions.map(t => {
    const dateTime = moment(t.startTime);
    return {
      date: dateTime.format("LL"),
      time: dateTime.format("H:mm"),
      amount: t.amount,
      currency: t.sourceCurrency ? t.sourceCurrency : "USD",
      targetCurrency: t.targetCurrency,
      purpose: t.purpose,
      status: convertApiStatus(t.state),
      description: t.description,
      sourceAccount: t.sourceAccount,
      targetAccount: t.targetAccount,
      baseAmount: t.baseAmount,
      sourceType: t.sourceType,
      targetType: t.targetType,
    };
  });

  if (parsedTransactions.length > 0) {
    days = _.chain(parsedTransactions)
      .groupBy(t => t.date)
      .map((items, date) => ({date, items}))
      .value();
  }

  return days;
};
