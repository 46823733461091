/**
 * @author: Seleznyov
 */

import React, {Component} from "react";
import {connect} from "react-redux";
import ContactElement from "../ContactElement";
import {getDataContacts} from "shared/contacts/actions";
import {contactList} from "shared/contacts/selectors";
import messages from "../../messages";
import config from "shared/config";

const defaultIcon = config.prefixImgUrl + "userdefault-icon.svg";
const NoContacts = config.prefixImgUrl + "no_contacts.svg";

// import "./index.scss";

class ContactsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstLoad: true,
    };
  }

  componentDidMount() {
    this.props.getDataContacts();
  }

  componentDidUpdate() {
    if (this.state.firstLoad) {
      this.props.onSelect(this.props.contactList[0]);
      this.setState({firstLoad: false});
    }
  }

  contactsData() {
    // if (idx === 0) this.props.onSelect(item);
  }

  render() {
    let filteredList = this.props.contactList;

    if (this.props.search) {
      filteredList = this.props.contactList.filter(item =>
        `${item.info.firstName} ${item.info.lastName}`
          .toLowerCase()
          .includes(this.props.search.toLowerCase())
      );
    }

    return (
      <div>
        {filteredList.length ? (
          <div className="contacts__list">
            {filteredList.map((item, key, array) => {
              return (
                <ContactElement
                  key={key}
                  id={item.id ? item.id.slice(0, 8) : ""}
                  imgUrl={defaultIcon}
                  UserFName={item.info ? item.info.firstName : ""}
                  UserLName={item.info ? item.info.lastName : ""}
                  PhoneNumber={item.info ? item.info.phone : ""}
                  selected={this.props.selectedId === item.id}
                  onClick={() => this.props.onSelect(item)}
                />
              );
            })}
          </div>
        ) : (
          <div className="contacts-empty">
            <div className="contacts-empty__img">
              <img src={NoContacts} alt="No contacts" />
            </div>
            <p>{messages.noContactsMatchingSearch.defaultMessage}</p>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = store => ({
  contactList: contactList(store),
});

function mapDispatchToProps(dispatch) {
  return {
    getDataContacts: () => {
      dispatch(getDataContacts());
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ContactsList);
