import React from "react";
import {connect} from "react-redux";
// Components
import RightModal from "shared/components/RightModal";
import NewAndEditRoleModal from "./NewAndEditRoleModal";

const modalVariants = {
  newRole: "newRole",
  editRole: "editRole",
};

const RolesDetailsModals = ({modalData}) => {
  if (modalData === null) {
    modalData = {
      title: "none",
      name: "none",
      sub: "none",
    };
  }

  return (
    <>
      {modalData.title && (
        <RightModal
          title={modalData && modalData.title ? modalData.title : null}
          subTitle={modalData && modalData.subTitle ? modalData.subTitle : null}
        >
          <div>{modalData.name === modalVariants.newRole && <NewAndEditRoleModal />}</div>
          <div>
            {modalData.name === modalVariants.editRole && <NewAndEditRoleModal />}
          </div>
        </RightModal>
      )}
    </>
  );
};

// Redux
const mapStateToProps = store => ({
  modalData: store.rightModalData.variant,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RolesDetailsModals);
