import {defineMessages} from "react-intl";

const scope = "CustomersPage";

const messages = defineMessages({
  newCustomer: {
    id: `${scope}.newCustomer`,
    defaultMessage: "New Customer",
  },
  header: {
    id: `${scope}.header`,
    defaultMessage: "Customers",
  },
  totalCustomers: {
    id: `${scope}.totalCustomers`,
    defaultMessage: "Total Customers",
  },
  last24hours: {
    id: `${scope}.last24hours`,
    defaultMessage: "Last 24 hours",
  },
  privateIndividuals: {
    id: `${scope}.privateIndividuals`,
    defaultMessage: "Private Individuals",
  },
  companies: {
    id: `${scope}.companies`,
    defaultMessage: "Companies",
  },
  customerId: {
    id: `${scope}.customerId`,
    defaultMessage: "Customer ID",
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: "Name",
  },
  id: {
    id: `${scope}.id`,
    defaultMessage: "ID",
  },
  type: {
    id: `${scope}.type`,
    defaultMessage: "Type",
  },
  currency: {
    id: `${scope}.currency`,
    defaultMessage: "Currency",
  },
  balance: {
    id: `${scope}.balance`,
    defaultMessage: "Balance",
  },
  transfer: {
    id: `${scope}.transfer`,
    defaultMessage: "Transfer",
  },
  invoice: {
    id: `${scope}.invoice`,
    defaultMessage: "Invoice",
  },
  exchange: {
    id: `${scope}.exchange`,
    defaultMessage: "Exchange",
  },
  transactionHistory: {
    id: `${scope}.transactionHistory`,
    defaultMessage: "Transaction History",
  },
  changeStatus: {
    id: `${scope}.changeStatus`,
    defaultMessage: "Change Status",
  },
  firstName: {
    id: `${scope}.firstName`,
    defaultMessage: "First Name",
  },
  lastName: {
    id: `${scope}.lastName`,
    defaultMessage: "Last Name",
  },
  companyName: {
    id: `${scope}.companyName`,
    defaultMessage: "Company Name",
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: "Status",
  },
  onWalletRegistration: {
    id: `${scope}.onWalletRegistration`,
    defaultMessage: "Registration",
  },
  generalInfo: {
    id: `${scope}.generalInfo`,
    defaultMessage: "General Info",
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: "Edit",
  },
  privatePerson: {
    id: `${scope}.privatePerson`,
    defaultMessage: "Private Person",
  },
  company: {
    id: `${scope}.company`,
    defaultMessage: "Company",
  },
  person: {
    id: `${scope}.person`,
    defaultMessage: "Person",
  },
  close: {
    id: `${scope}.close`,
    defaultMessage: "Close",
  },
  next: {
    id: `${scope}.next`,
    defaultMessage: "Next",
  },
  finishRegistration: {
    id: `${scope}.finishRegistration`,
    defaultMessage: "Finish Registration",
  },
  password: {
    id: `${scope}.password`,
    defaultMessage: "Password",
  },
  confirmPassword: {
    id: `${scope}.confirmPassword`,
    defaultMessage: "Confirm Password",
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: "E-mail",
  },
  businessEmail: {
    id: `${scope}.businessEmail`,
    defaultMessage: "Business E-mail",
  },
  companySite: {
    id: `${scope}.companySite`,
    defaultMessage: "Company Site (optional)",
  },
  specialization: {
    id: `${scope}.specialization`,
    defaultMessage: "Account Specialization",
  },
  usersFirstName: {
    id: `${scope}.usersFirstName`,
    defaultMessage: "Users's First Name",
  },
  usersLastName: {
    id: `${scope}.usersLastName`,
    defaultMessage: "User's Last Name",
  },
  legalAddress: {
    id: `${scope}.legalAddress`,
    defaultMessage: "Legal Address",
  },
  legalDocuments: {
    id: `${scope}.legalDocuments`,
    defaultMessage: "Legal Documents",
  },
  country: {
    id: `${scope}.country`,
    defaultMessage: "Country",
  },
  zip: {
    id: `${scope}.zip`,
    defaultMessage: "Postal / Zip Code",
  },
  street: {
    id: `${scope}.street`,
    defaultMessage: "Street",
  },
  apartment: {
    id: `${scope}.apartment`,
    defaultMessage: "Apartment, Unit, ets",
  },
  region: {
    id: `${scope}.region`,
    defaultMessage: "State/Province/Region",
  },
  city: {
    id: `${scope}.city`,
    defaultMessage: "City",
  },
  toConfirmAddress: {
    id: `${scope}.toConfirmAddress`,
    defaultMessage:
      "To confirm this is your address, please, attach last bill of electricity provider for this address.",
  },
  attachBill: {
    id: `${scope}.attachBill`,
    defaultMessage: "Attach bill",
  },
  add: {
    id: `${scope}.add`,
    defaultMessage: "Add",
  },
  companyRegistrationCertificate: {
    id: `${scope}.companyRegistrationCertificate`,
    defaultMessage: " Company Registration Certificate",
  },
  frontSideOfPersonIdCard: {
    id: `${scope}.frontSideOfPersonIdCard`,
    defaultMessage: "Front side of person ID card",
  },
  backSideOfPersonIdCard: {
    id: `${scope}.backSideOfPersonIdCard`,
    defaultMessage: "Back side of person ID card",
  },
  freshPhotoCustomer: {
    id: `${scope}.freshPhotoCustomer`,
    defaultMessage: "Fresh photo of customers' face",
  },
  memorandumOfAssociation: {
    id: `${scope}.memorandumOfAssociation`,
    defaultMessage: "Memorandum of Association",
  },
  taxID: {
    id: `${scope}.taxID`,
    defaultMessage: "Tax ID",
  },
  companyNumber: {
    id: `${scope}.companyNumber`,
    defaultMessage: "Company Number",
  },
  personValidation: {
    id: `${scope}.personValidation`,
    defaultMessage: "Person Validation",
  },
  toConfirmYourIdentity: {
    id: `${scope}.toConfirmYourIdentity`,
    defaultMessage: "To confirm your identity, please attach:",
  },
  scanCopyOfYourPassport: {
    id: `${scope}.scanCopyOfYourPassport`,
    defaultMessage: "1. Scan copy of your passport or ID Card or Driving License.",
  },
  freshPhotoFYourFace: {
    id: `${scope}.freshPhotoFYourFace`,
    defaultMessage:
      "2. Fresh photo of your face according to requirements of standard ID photo",
  },
  skipThisTime: {
    id: `${scope}.skipThisTime`,
    defaultMessage: "Skip this time",
  },
  youWillBePrompt: {
    id: `${scope}.youWillBePrompt`,
    defaultMessage:
      "You will be prompted to confirm your identity later, before executing any financial operation",
  },
  mobileNumberValidation: {
    id: `${scope}.mobileNumberValidation`,
    defaultMessage: "Mobile Number Validation",
  },
  phoneNumber: {
    id: `${scope}.phoneNumber`,
    defaultMessage: "Phone Number",
  },
  mobileNumber: {
    id: `${scope}.mobileNumber`,
    defaultMessage: "Mobile Number",
  },
  weWillSendOnThisNumberSMS: {
    id: `${scope}.weWillSendOnThisNumberSMS`,
    defaultMessage: "We will send on this number SMS with confirmation pass code.",
  },
  weSentOnThisPhoneSMS: {
    id: `${scope}.weSentOnThisPhoneSMS`,
    defaultMessage:
      "We sent on this phone SMS with confirmation pass code. Enter confirmation pass code in the fields below.",
  },
  send: {
    id: `${scope}.send`,
    defaultMessage: "Send",
  },
  ratesAndFees: {
    id: `${scope}.ratesAndFees`,
    defaultMessage: "Rates & Fees",
  },
  sendCode: {
    id: `${scope}.sendCode`,
    defaultMessage: "Send code",
  },
  iHaveIssue: {
    id: `${scope}.iHaveIssue`,
    defaultMessage: "I have issue with receiving of this pass code",
  },
  sendNewCode: {
    id: `${scope}.sendNewCode`,
    defaultMessage: "send new code",
  },
  currentAddress: {
    id: `${scope}.currentAddress`,
    defaultMessage: "Current Address: ",
  },
  documents: {
    id: `${scope}.documents`,
    defaultMessage: "Documents: ",
  },
  newName: {
    id: `${scope}.newName`,
    defaultMessage: "New Name",
  },
  newAddress: {
    id: `${scope}.newAddress`,
    defaultMessage: "New Address",
  },
  applyToChange: {
    id: `${scope}.applyToChange`,
    defaultMessage: "Apply To Change",
  },
  weGeneratedRecoveryPhrase: {
    id: `${scope}.weGeneratedRecoveryPhrase`,
    defaultMessage:
      " We generated a recovery phrase for you in case you forget your password. Copy and save it secretly.",
  },
  iHaveSavedPhrase: {
    id: `${scope}.iHaveSavedPhrase`,
    defaultMessage: "I have saved the recovery phrase",
  },
  letsGetStarted: {
    id: `${scope}.letsGetStarted`,
    defaultMessage: "Let's get started",
  },
  onWalletRegistrationFinished: {
    id: `${scope}.onWalletRegistrationFinished`,
    defaultMessage: "Registration Finished",
  },
  passwordResetConfirmation: {
    id: `${scope}.passwordResetConfirmation`,
    defaultMessage: "Password Reset Confirmation",
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: "Save",
  },
  transferFromCryptoWallet: {
    id: `${scope}.transferFromCryptoWallet`,
    defaultMessage: "Transfer from own crypto wallet",
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: "Description",
  },
  exchangeRate: {
    id: `${scope}.exchangeRate`,
    defaultMessage: "Exchange Rate: ",
  },
  details: {
    id: `${scope}.details`,
    defaultMessage: "Details",
  },
  myOnWalletAccount: {
    id: `${scope}.myOnWalletAccount`,
    defaultMessage: "My OnWallet Account",
  },
  amountInSourceCurrency: {
    id: `${scope}.amountInSourceCurrency`,
    defaultMessage: "Amount in Source's Currency",
  },
  amountInRecipientCurrency: {
    id: `${scope}.amountInRecipientCurrency`,
    defaultMessage: "Amount in Recipient Currency",
  },
  source: {
    id: `${scope}.source`,
    defaultMessage: "Source",
  },
  sourceOfTransfer: {
    id: `${scope}.sourceOfTransfer`,
    defaultMessage: "Source Of Transfer",
  },
  recipient: {
    id: `${scope}.recipient`,
    defaultMessage: "Recipient",
  },
  resetPasswordInfo: {
    id: `${scope}.resetPasswordInfo`,
    defaultMessage: "New temporary password will be sent to user's registered e-mail.",
  },
  dialogtitlePrivate: {
    id: `${scope}.dialogtitlePrivate`,
    defaultMessage: "Private Person Account Specialization",
  },
  dialogtitleCompany: {
    id: `${scope}.dialogtitleCompany`,
    defaultMessage: "Company Account Specialization",
  },
  dialogBtntext: {
    id: `${scope}.dialogBtntext`,
    defaultMessage: "Ok, Got It",
  },
  dialogContent: {
    id: `${scope}.dialogContent`,
    defaultMessage: "<h2>General Purpose</h2><p>A person without specific trading needs",
  },
  emailPassCode: {
    id: `${scope}.emailPassCode`,
    defaultMessage: "We will sent a letter on this address with pass code.",
  },
  checkMailPassCode: {
    id: `${scope}.checkMailPassCode`,
    defaultMessage: "Check your mail and enter pass code in the fields below.",
  },
  codeWillExpire: {
    id: `${scope}.codeWillExpire`,
    defaultMessage: "Code will expire in ",
  },
  passwordNote: {
    id: `${scope}.passwordNote`,
    defaultMessage:
      "Use both upper-case and lower-case letters, one or more numerical digits",
  },
  userExists: {
    id: `${scope}.userExists`,
    defaultMessage: "User Exists",
  },
  sendEmailCode: {
    id: `${scope}.sendEmailCode`,
    defaultMessage: "Send",
  },
  verifyCode: {
    id: `${scope}.verifyCode`,
    defaultMessage: "Verify Code",
  },
  change: {
    id: `${scope}.change`,
    defaultMessage: "Change",
  },
  personalDetails: {
    id: `${scope}.personalDetails`,
    defaultMessage: "Personal Details",
  },
  resetPassword: {
    id: `${scope}.resetPassword`,
    defaultMessage: "Reset Password",
  },
  trustedDevices: {
    id: `${scope}.trustedDevices`,
    defaultMessage: "Trusted Devices",
  },
  validationResult: {
    id: `${scope}.validationResult`,
    defaultMessage: "Validation Result",
  },
  walletsAndCards: {
    id: `${scope}.walletsAndCards`,
    defaultMessage: "Wallets & Cards",
  },
  wallet: {
    id: `${scope}.wallet`,
    defaultMessage: "Wallet",
  },
  cards: {
    id: `${scope}.cards`,
    defaultMessage: "Cards",
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: "Cancel",
  },
  normal: {
    id: `${scope}.normal`,
    defaultMessage: "Normal",
  },
  active: {
    id: `${scope}.active`,
    defaultMessage: "Active",
  },
  blocked: {
    id: `${scope}.blocked`,
    defaultMessage: "Blocked",
  },
  blockedOutgoing: {
    id: `${scope}.blockedOutgoing`,
    defaultMessage: "Blocked Outgoing",
  },
  blockedIncoming: {
    id: `${scope}.blockedIncoming`,
    defaultMessage: "Blocked Incoming",
  },
  limited: {
    id: `${scope}.limited`,
    defaultMessage: "Limited",
  },
  disabled: {
    id: `${scope}.disabled`,
    defaultMessage: "Disabled",
  },
  incoming: {
    id: `${scope}.incoming`,
    defaultMessage:
      "Incoming and outgoing transactions are prohibited with this account.",
  },
  outgoing: {
    id: `${scope}.outgoing`,
    defaultMessage: "Outgoing transactions are prohibited with this account.",
  },
  incomingTransactions: {
    id: `${scope}.incomingTransactions`,
    defaultMessage: "Incoming transactions are prohibited with this account.",
  },
  noLimits: {
    id: `${scope}.noLimits`,
    defaultMessage: "No limits. All documents are confirmed.",
  },
  loginAccount: {
    id: `${scope}.loginAccount`,
    defaultMessage: "Login to to this account is not allowed.",
  },
  financialOperations: {
    id: `${scope}.financialOperations`,
    defaultMessage: "Financial operations are restricted from this account.",
  },
  disabledInfo: {
    id: `${scope}.disabledInfo`,
    defaultMessage: "Account is disabled and not visible from customer's UI.",
  },
});

export default messages;
