export const OPEN_RIGHT_MODAL = "OPEN_RIGHT_MODAL";
export const CLOSE_RIGHT_MODAL = "CLOSE_RIGHT_MODAL";

export const openRightModal = (variant, meta, savedData) => ({
  type: OPEN_RIGHT_MODAL,
  payload: {
    variant,
    meta,
    savedData,
  },
});

export const closeRightModal = () => ({
  type: CLOSE_RIGHT_MODAL,
});
