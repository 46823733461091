import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
// Actions
import {shoppingCardModal} from "shared/shoppingService/actions/index";
// Components
import CardItem from "./CardItem";

import "./index.scss";

const ShoppingCards = ({formTabs, personalCards, shoppingModal}) => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (isLoading && !!personalCards.length) {
      shoppingModal(personalCards[0]);
      setLoading(false);
    }
  }, [isLoading, personalCards, shoppingModal]);

  return (
    <>
      {formTabs && formTabs()}
      <div className="cards-layout">
        {personalCards.map((item, index) => (
          <CardItem item={item} key={index} />
        ))}
      </div>
    </>
  );
};

const mapStateToProps = store => ({});

const mapDispatchToProps = {
  shoppingModal: data => shoppingCardModal(data),
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCards);
