import * as Yup from "yup";

export const NewCustomerSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  companyName: Yup.string().when("type", {
    is: "company",
    then: Yup.string().required("Company name is required"),
  }),
  email: Yup.string()
    .email("Email must be valid")
    .required("Email is required"),
  country: Yup.string().required("Country is required"),
  zip: Yup.string().when("type", {
    is: "person",
    then: Yup.string().required("Zip Code is required"),
  }),
  street: Yup.string().when("type", {
    is: "person",
    then: Yup.string().required("Street is required"),
  }),
  apartment: Yup.string().when("type", {
    is: "person",
    then: Yup.string().required("Apartment is required"),
  }),
  region: Yup.string().when("type", {
    is: "person",
    then: Yup.string().required("Region is required"),
  }),
  city: Yup.string().when("type", {
    is: "person",
    then: Yup.string().required("City is required"),
  }),
  bill: Yup.string().when("type", {
    is: "person",
    then: Yup.string().required("Bill is required"),
  }),
  companyNumber: Yup.string().when("type", {
    is: "company",
    then: Yup.string()
      .matches("^[0-9]*$", "Company Number must be a number")
      .required("Company Number is required"),
  }),
  certificate: Yup.string().when("type", {
    is: "company",
    then: Yup.string().required("Certificate is required"),
  }),
  memorandum: Yup.string().when("type", {
    is: "company",
    then: Yup.string().required("Memorandum is required"),
  }),
  taxID: Yup.string().when("type", {
    is: "company",
    then: Yup.string().required("Tax ID is required"),
  }),
  phoneNumber: Yup.string().required("Phone number is required"),
});
