import React from "react";
// Components
import CryptoItem from "./item";

const mockData = [
  {name: "Bitcoin", balance: "10000", currency: "BTC"},
  {name: "Ethereum", balance: "300", currency: "ETH"},
  {name: "Bitcoin Cash", balance: "200", currency: "BCH"},
  {name: "XRP", balance: "100", currency: "XRP"},
  {name: "Ethereum", balance: "300", currency: "ETH"},
  {name: "Bitcoin Cash", balance: "200", currency: "BCH"},
  {name: "XRP", balance: "100", currency: "XRP"},
  {name: "Bitcoin", balance: "10000", currency: "BTC"},
];

const CryptoBlock = () => {
  return (
    <>
      <div className="crypto-block">
        {mockData.map((item, index) => (
          <CryptoItem cryptoWallet={item} key={index} />
        ))}
      </div>
    </>
  );
};

export default CryptoBlock;
