import {defineMessages} from "react-intl";

const scope = "StaffPage";

const messages = defineMessages({
  staffTitle: {
    id: `${scope}.staffTitle`,
    defaultMessage: "Staff",
  },
  cellName: {
    id: `${scope}.cellName`,
    defaultMessage: "Name",
  },
  cellEmail: {
    id: `${scope}.cellEmail`,
    defaultMessage: "E-mail",
  },
  cellRole: {
    id: `${scope}.cellRole`,
    defaultMessage: "Role",
  },
  cellId: {
    id: `${scope}.cellId`,
    defaultMessage: "Id",
  },
  cellStatus: {
    id: `${scope}.cellStatus`,
    defaultMessage: "Status",
  },
  newStaffMember: {
    id: `${scope}.newStaffMember`,
    defaultMessage: "New Staff Member",
  },
  register: {
    id: `${scope}.register`,
    defaultMessage: "Register",
  },
  saveChanges: {
    id: `${scope}.saveChanges`,
    defaultMessage: "Save Changes",
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: "Cancel",
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: "Edit",
  },
  resetPassword: {
    id: `${scope}.resetPassword`,
    defaultMessage: "Reset Password",
  },
  deleteAccount: {
    id: `${scope}.deleteAccount`,
    defaultMessage: "Delete Account",
  },
  block: {
    id: `${scope}.block`,
    defaultMessage: "Block",
  },
  actionsLog: {
    id: `${scope}.actionsLog`,
    defaultMessage: "Actions Log",
  },
  firstName: {
    id: `${scope}.firstName`,
    defaultMessage: "First Name",
  },
  lastName: {
    id: `${scope}.lastName`,
    defaultMessage: "Last Name",
  },
  role: {
    id: `${scope}.role`,
    defaultMessage: "Role",
  },
});

export default messages;
