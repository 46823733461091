import React from "react";
import {connect} from "react-redux";
import "./index.scss";
import {selectUserProfile} from "shared/auth/selectors";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import messages from "../../messages";
import {FormattedMessage} from "react-intl";
import Button from "shared/components/Button";
import Input from "shared/components/Input";
import {closeRightModal} from "shared/components/RightModal/actions";
import DropZone from "shared/components/DropZone";
import RegisterFileList from "shared/components/RegisterFileList";
import {updateUserProfile} from "shared/auth/actions";

const initialValues = {
  firstName: "",
  lastName: "",
  validFiles: [],
  billFile: [],
};

const ChangeNameSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
});

const ChangeNameForm = ({
  userProfile,
  closeRightModal,
  setChangeName,
  updateUserProfile,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ChangeNameSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, {resetForm}) => {
        updateUserProfile({
          firstName: values.firstName,
          lastName: values.lastName,
          validFiles: values.validFiles,
          billFile: values.billFile,
        });
        setChangeName(true);
        closeRightModal();
        resetForm();
      }}
    >
      {({errors, touched, values, setFieldValue}) => (
        <Form className="ChangeDetailsForm">
          <div className="currentName">
            <div className="nameLabel">
              <FormattedMessage {...messages.currentLegalName} />:
            </div>
            <div className="name">
              {(userProfile && userProfile.firstName) || "Unknown"}{" "}
              {(userProfile && userProfile.lastName) || "Unknown"}
            </div>
          </div>

          <div className="currentName"></div>

          <FormSectionSeparator sectionName={messages.newName} />
          <div className="nameInput">
            <Field name="firstName">
              {({field}) => (
                <div className="inputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.firstName} />
                  </label>
                  <Input error={touched.firstName && errors.firstName} {...field} />
                </div>
              )}
            </Field>
            <Field name="lastName">
              {({field}) => (
                <div className="inputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.lastName} />
                  </label>
                  <Input error={touched.lastName && errors.lastName} {...field} />
                </div>
              )}
            </Field>
          </div>
          <div className="changeNameMessage">
            <FormattedMessage {...messages.inOrderToConfirm} />
          </div>
          <DropZone
            onChange={files => {
              const list = values.validFiles;
              const listF = values.billFile;
              files.forEach((file, index) => {
                list.push(file.name);
                listF.push(file);
              });
              setFieldValue("validFiles", list);
              setFieldValue("billFile", listF);
            }}
            accept=".jpg, .jpeg, .png, .pdf"
          />
          <RegisterFileList
            list={values.validFiles || []}
            onDelete={index => {
              const list = values.validFiles.filter((file, idx) => idx !== index);
              setFieldValue("validFiles", list);
              const listF = values.billFile.filter((file, idx) => idx !== index);
              setFieldValue("billFile", listF);
            }}
          />
          <div className="buttonsContainer">
            <Button
              type="button"
              text={messages.cancel}
              isSecondary="true"
              onClick={() => {
                closeRightModal();
              }}
            />
            <Button
              disabled={!values.validFiles || values.validFiles.length === 0}
              type="sumbit"
              text={messages.applyToChange}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = store => ({
  userProfile: selectUserProfile(store),
});

const mapDispatchToProps = {
  closeRightModal,
  updateUserProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeNameForm);
