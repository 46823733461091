import React from "react";
import {Link} from "react-router-dom";
import "./index.scss";

const NotFoundPage = () => {
  return (
    <div className="NotFoundContainer">
      <div className="NotFoundContent">
        <div className="title">404 Not Found</div>
        <div className="message">
          Go <Link to="/">home</Link>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
