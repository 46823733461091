import React, {Fragment, useState} from "react";
import {connect} from "react-redux";
import {Formik, Form, Field} from "formik";
import PropTypes from "prop-types";
// Components
import Textarea from "shared/components/Input/Textarea";
import Input from "shared/components/Input";
import Button from "shared/components/Button";
import CustomSelect from "shared/components/Select";
import Checkbox from "shared/components/Checkbox";
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import CustomSwitch from "shared/components/Switch";
// Actions
import {closeRightModal} from "shared/components/RightModal/actions";
// Selectors
import {dataRightModal} from "shared/components/RightModal/selectors";
// Utils
import {FormattedMessage} from "react-intl";
import messages from "../../messages";
import config from "shared/config";
import {DATA} from "./data";
// Styles
import "./index.scss";
// Images
const deleteSuperVisorIcon = config.prefixImgUrl + "delete_icon_supervisor.svg";

// Mock Data
const people = ["Martin Harez", "Fred Dominikano"];
const defaultRoles = ["Blank", "Admin", "Supervisor"];

const NewAndEditRoleModal = ({closeRightModal, roleData}) => {
  const [role] = useState(roleData);

  const initFormik = {
    roleName: !!role ? role.name : "",
    roleDescription: !!role ? role.desc : "",
    baseOnRole: defaultRoles[0],
    customersManagement: DATA.customersManagement,
    goldAndCoin: DATA.goldAndCoin,
    staff: DATA.staff,
    roles: DATA.roles,
    supervision: DATA.supervision,
  };

  const handlerResetCheckboxs = (block, name, type, setFieldValue) => {
    block.forEach((item, index) => {
      if (item.type === type) {
        setFieldValue(`${name}[${index}].checkBoxStatus`, false);
      }
    });
  };

  // Generate list with checkbox
  const listCheckbox = (name, type, items, setFieldValue) => {
    return (
      <Fragment>
        {items.map((item, index) => (
          <Field name={`${name}[${index}]`}>
            {({field}) => (
              <div className="role-permissions__table__item" key={index}>
                <div className="role-permissions__table__item-checkbox">
                  <div className="fields-wrapper">
                    <Checkbox
                      className="checkbox"
                      {...field}
                      value={item.checkBoxStatus}
                      onChange={e => {
                        setFieldValue(
                          `${name}[${index}].checkBoxStatus`,
                          e.target.checked
                        );

                        if (!e.target.checked && item.main)
                          handlerResetCheckboxs(items, name, type, setFieldValue);
                      }}
                    />
                  </div>
                </div>

                <div className="role-permissions__table__item-text">
                  <div className="role-permissions__table__item-text__title">
                    {item.title}
                  </div>
                  <div className="role-permissions__table__item-text__subtitle">
                    {item.description}
                  </div>
                </div>

                {item.isShowSwitch && (
                  <div className="role-permissions__table__item-switch">
                    <CustomSwitch
                      value={item.switchStatus}
                      onChange={e =>
                        setFieldValue(
                          `customersManagement[${index}].switchStatus`,
                          e.target.checked
                        )
                      }
                    />
                  </div>
                )}
              </div>
            )}
          </Field>
        ))}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Formik initialValues={initFormik} onSubmit={values => console.log(values)}>
        {({values, setFieldValue}) => (
          <Form>
            {/* Section Role Details */}
            <div className="role-details">
              <FormSectionSeparator sectionName={messages.details} />

              {/* Role Name */}
              <div className="role-details__form">
                <Field name="roleName">
                  {({field}) => (
                    <div className="fields-wrapper">
                      <label className="label">
                        <FormattedMessage {...messages.roleName} />
                      </label>
                      <Input placeholder="A unique role name" {...field} />
                    </div>
                  )}
                </Field>

                {/* Role Description */}
                <Field name="roleDescription">
                  {({field}) => (
                    <div className="fields-wrapper">
                      <label className="label">
                        <FormattedMessage {...messages.roleDescription} />
                      </label>
                      <Textarea
                        type="text"
                        placeholder="Write a description here"
                        {...field}
                      />
                    </div>
                  )}
                </Field>
              </div>
            </div>

            {/* Section Action Supervisors */}
            <div className="role-action-supervisors">
              <FormSectionSeparator sectionName={messages.actionsSupervisors} />

              <div className="role-action-supervisors-items">
                {people.map((item, index) => (
                  <div className="role-action-supervisors-items__item" key={index}>
                    <div>{`${index + 1}. ${item}`}</div>
                    <img src={deleteSuperVisorIcon} alt="delete" />
                  </div>
                ))}
              </div>

              <div className="blue-text mt-6 bold">
                <FormattedMessage {...messages.addSupervisor} />
              </div>
            </div>

            {/* Section Permissions */}
            <div className="role-permissions">
              <FormSectionSeparator sectionName={messages.permissions} />

              <div className="role-permissions__description">
                Select permissions that you want to be allowed within this role. You can
                set selections basing on existing role selections and modify them.
              </div>

              <Field name="baseOnRole">
                {({field}) => (
                  <div className="role-permissions__select-default">
                    <label className="label">
                      <FormattedMessage {...messages.roleDescription} />
                    </label>
                    <CustomSelect
                      className="select"
                      options={defaultRoles}
                      onChange={e => {
                        setFieldValue("baseOnRole", e.target.value);
                      }}
                      {...field}
                    />
                  </div>
                )}
              </Field>

              <div className="role-permissions__header">
                <div className="role-permissions__header__item">
                  <FormattedMessage {...messages.permissionName} />
                </div>
                <div className="role-permissions__header__item">
                  <FormattedMessage {...messages.supervisorApproval} />
                </div>
              </div>

              {/* Table */}
              <div className="role-permissions__customers-management">
                {/* Section Table Customers Management */}
                <div className="role-permissions__customers-management__title role-permissions__table__title">
                  <FormattedMessage {...messages.customersManagement} />
                </div>

                {listCheckbox(
                  "customersManagement",
                  "customer",
                  values.customersManagement,
                  setFieldValue
                )}

                {/* Section Table Gold & Coins */}
                <div className="role-permissions__customers-management__title role-permissions__table__title">
                  <FormattedMessage {...messages.goldAndCoin} />
                </div>

                {listCheckbox("goldAndCoin", "gold", values.goldAndCoin, setFieldValue)}

                {/* Section Table Staff & Roles */}
                <div className="role-permissions__customers-management__title role-permissions__table__title">
                  <FormattedMessage {...messages.staffAndRoles} />
                </div>

                {listCheckbox("staff", "staff", values.staff, setFieldValue)}
                {listCheckbox("roles", "roles", values.roles, setFieldValue)}

                {/* Section Table supervision */}
                <div className="role-permissions__customers-management__title role-permissions__table__title">
                  <FormattedMessage {...messages.supervision} />
                </div>

                {listCheckbox(
                  "supervision",
                  "supervision",
                  values.supervision,
                  setFieldValue
                )}
              </div>
            </div>

            {/* Section with buttons */}
            <div className="role-buttons">
              <Button
                text={messages.cancel}
                isSecondary={true}
                type="button"
                onClick={() => closeRightModal()}
              />
              <Button text={messages.createRole} type="submit" />
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

// Types
NewAndEditRoleModal.propTypes = {
  closeRightModal: PropTypes.func.isRequired,
};

// Redux
const mapStateToProps = state => ({
  roleData: dataRightModal(state),
});

const mapDispatchToProps = {
  closeRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewAndEditRoleModal);
