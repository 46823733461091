import React from "react";
import {connect} from "react-redux";
import {injectIntl, FormattedMessage} from "react-intl";
import messages from "./messages";
import "./index.scss";
import {login} from "shared/auth/actions";
import {Link} from "react-router-dom";
import {routes} from "shared/appUrls";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
// Const
import {ROLE} from "shared/constants";
// Components
import Input from "shared/components/Input";
import DialogWithBlur from "shared/components/DialogWithBlur";
import Eula from "components/Documents/components/Eula";
import PrivacyPolicy from "components/Documents/components/PrivacyPolicy";
import TermsAndConditions from "components/Documents/components/TermsAndConditions";
import Button from "shared/components/Button";
import LinkButton from "shared/components/Button/LinkButton";
import CodeInput from "shared/components/CodeInput";
import TextButton from "shared/components/TextButton";
import {isAuthLoading, isUserAuth, userProfileRoles} from "../../shared/auth/selectors";
import SaveRedirect from "shared/components/SaveRedirect";
// Utils
import config from "shared/config";
// Images
const onWalletIcon = config.prefixImgUrl + "logo-login.svg";
const onWalletShortIcon = config.prefixImgUrl + "onwallet-icon-short_v1.svg";
const appStoreIcon = config.prefixImgUrl + "app-store-icon.svg";
const googlePlayIcon = config.prefixImgUrl + "google-play-icon.svg";
const emailIcon = config.prefixImgUrl + "email-login-icon.svg";
const passwordIcon = config.prefixImgUrl + "password-icon.svg";

const initialValues = {
  email: "",
  password: "",
  isSendCode: false,
  receivedCode: "",
};

const LoginSchema = Yup.object().shape({
  email: Yup.string(),
  password: Yup.string(),
});

const LoginPage = ({intl, login, isAuthLoading, isUserAuth, userRoles}) => {
  const handleDefinePage = () => {
    if (userRoles.includes(ROLE.MASTER_ACCOUNT)) {
      return <SaveRedirect to={routes.dashboardAdmin} />;
    }

    if (userRoles.includes(ROLE.BANK)) {
      return <SaveRedirect to={routes.metalSupply} />;
    }

    if (userRoles.includes(ROLE.GOLD_PROVIDER)) {
      return <SaveRedirect to={routes.orders} />;
    }

    if (!userRoles.length) {
      return <SaveRedirect to={routes.details} />;
    }

    return <SaveRedirect to={routes.dashboard} />;
  };

  if (!isAuthLoading && isUserAuth && !!userRoles) {
    return handleDefinePage();
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={LoginSchema}
      onSubmit={values => {
        login(values);
      }}
    >
      {({errors, touched, values, setFieldValue}) => (
        <div className="LoginPageWrapper">
          <div className="mainBackground">
            <a href="https://onwallet.com/">
              <img className="onWalletLogo" src={onWalletIcon} alt="logo" />
            </a>
            <div className="titles">
              <h1 className="mainTitle">
                <FormattedMessage {...messages.easyPayEasyReceive} />
              </h1>
              <h2 className="secondTitle">
                <FormattedMessage {...messages.someSubheader} />
              </h2>
            </div>
            <div className="copyright">
              <FormattedMessage {...messages.copyRight} />
            </div>
          </div>
          <div className="rightContent">
            <div className="langSelector" />
            <Form className="loginForm">
              <img className="loginLogo" src={onWalletShortIcon} alt="logo" />
              <h1 className="loginTitle">
                <FormattedMessage {...messages.login} />
              </h1>
              <Field name="email">
                {({field}) => (
                  <div className="inputWrapper">
                    <Input
                      type="email"
                      placeholder="Email"
                      className="input"
                      disabled={values.isSendCode}
                      rightIcon={emailIcon}
                      {...field}
                    />
                  </div>
                )}
              </Field>
              <Field name="password">
                {({field}) => (
                  <div className="inputWrapper">
                    <Input
                      type="password"
                      placeholder="Password"
                      className="input"
                      disabled={values.isSendCode}
                      rightIcon={passwordIcon}
                      {...field}
                    />
                  </div>
                )}
              </Field>
              {!values.isSendCode && (
                <>
                  <Button
                    type="button"
                    className="submitButton"
                    onClick={() => setFieldValue("isSendCode", true)}
                    text={messages.login}
                  />
                  <div className="forgotContainer">
                    <p className="text">
                      <FormattedMessage {...messages.forgotYourPassword} />
                    </p>
                    <Link to={routes.recovery} className="recoveryLink">
                      <FormattedMessage {...messages.recoverPassword} />
                    </Link>
                  </div>
                  <div className="separateLine" />
                  <h1 className="registerSubTitle">
                    <FormattedMessage {...messages.dontHaveAccountYet} />
                  </h1>
                  <LinkButton
                    className="registerButton"
                    isSecondary={true}
                    link={routes.register}
                    text={messages.register}
                  />
                </>
              )}
              {values.isSendCode && (
                <>
                  <p className="smsMessage">
                    <FormattedMessage {...messages.weSentSMS} />
                  </p>
                  <Field name="receivedCode">
                    {({field}) => (
                      <CodeInput {...field} focusStatus={values.isSendCode} />
                    )}
                  </Field>
                  <Button type="submit" className="submitButton" text={messages.login} />
                  <TextButton
                    onClick={() => setFieldValue("isSendCode", false)}
                    type="button"
                    className="cancelButton"
                    text={messages.cancel}
                  />
                </>
              )}
            </Form>
            <div className="footerContainer">
              <h1 className="footerTitle">
                <FormattedMessage {...messages.getOnWalletForPhone} />
              </h1>
              <div className="phoneLinks">
                <a
                  // Unlock when do u will have real links
                  // href={externalLinks.appStoreLink}
                  href={"/#"}
                  className="phoneLink"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={appStoreIcon} alt="appStore" />
                </a>
                <a
                  // Unlock when do u will have real links
                  // href={externalLinks.googlePlayLink}
                  href={"/#"}
                  className="phoneLink"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={googlePlayIcon} alt="googlePlay" />
                </a>
              </div>
              <div className="supportLinks">
                <div className="supportLink">
                  <DialogWithBlur
                    title={<FormattedMessage {...messages.eulaLong} />}
                    textCallIn={<FormattedMessage {...messages.eula} />}
                  >
                    <Eula />
                  </DialogWithBlur>
                </div>

                <div className="supportLink">
                  <DialogWithBlur
                    title={<FormattedMessage {...messages.privacyPolicy} />}
                    textCallIn={<FormattedMessage {...messages.privacyPolicy} />}
                  >
                    <PrivacyPolicy />
                  </DialogWithBlur>
                </div>

                <div className="supportLink">
                  <DialogWithBlur
                    title={<FormattedMessage {...messages.termsAndConditions} />}
                    textCallIn={<FormattedMessage {...messages.termsAndConditions} />}
                  >
                    <TermsAndConditions />
                  </DialogWithBlur>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

const mapStateToProps = store => ({
  isAuthLoading: isAuthLoading(store),
  isUserAuth: isUserAuth(store),
  userRoles: userProfileRoles(store),
});
const mapDispatchToProps = {
  login,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(LoginPage));
