import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {closeFileViewer} from "./actions";
import "./index.scss";
import {useClickOutside} from "../../hooks";

const FileViewer = ({show, file, close}) => {
  const [url, setUrl] = useState("");
  useEffect(() => {
    const reader = new FileReader();
    reader.onload = () => setUrl(reader.result);
    if (file) reader.readAsDataURL(file);
  }, [file]);
  const refContainer = useRef(null);
  useClickOutside(refContainer, () => close());
  return (
    <div className={`FileViewerWrapper ${show ? "show" : "hide"}`}>
      <div ref={refContainer}>
        {file && file.name.includes("pdf") && (
          <PDFView file={file} ref={refContainer} url={url} close={close} />
        )}
        {file && file.name.match(/(gif|png|jpg|jpeg)$/) && (
          <PhotoView ref={refContainer} url={url} />
        )}
      </div>
    </div>
  );
};

const PDFView = ({ref, file, url, close}) => {
  return (
    <div ref={ref} className="fileContainer">
      <div className="headerFile">
        <button className="closeButton" onClick={close} />
        <div className="titles">
          <h1 className="main">{file ? file.name : ""}</h1>
          <h2 className="sub">confirmation document</h2>
        </div>
      </div>
      {url && <iframe title="pdf" className="pdfContainer" src={url} />}
    </div>
  );
};

const PhotoView = ({ref, url}) => {
  return (
    url && (
      <div ref={ref} className="photoContainer">
        <img src={url} className="photo" alt="icon" />
      </div>
    )
  );
};

const mapStateToProps = store => ({
  show: store.fileViewerData.show,
  file: store.fileViewerData.file,
});

const mapDispatchToProps = {
  close: closeFileViewer,
};

export default connect(mapStateToProps, mapDispatchToProps)(FileViewer);
