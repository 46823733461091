import React from "react";
import {connect} from "react-redux";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import {FormattedMessage} from "react-intl";

import "./index.scss";
import messages from "../../messages";
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import Select from "shared/components/Select";
import Button from "shared/components/Button";

import {closeRightModal} from "shared/components/RightModal/actions";
import {transferCardToOnWallet} from "shared/cardService/actions";
import {addCartItem} from "shared/cartService/actions";
import {
  getAllRegularCurrency,
  getBankCards,
  getOnwalletAccount,
  getCardIconBank,
} from "shared/cardService/selectors";
import {
  CardOption,
  ThirdPartyWalletsOption,
  BankAccountOption,
  OnWalletOption,
} from "shared/components/CardWalletOptions";
import Textarea from "shared/components/Input/Textarea";
import config from "shared/config";
import {
  CardAmountWithCurrency,
  DetectAmount,
  DetectDescription,
  DetectOnWalletAccount,
  DetectRecipient,
  DetectRegularCard,
  DetectRegularCurrency,
  FeeRate,
  SendRecipient,
  // SomeoneOnWallet,
  SomeoneOnWalletWithName,
} from "./components";

const initialOwnValues = {
  sourceCard: null,
  recipientOnWallet: null,
  amount: "0",
  currency: "USD",
  description: "",
  sendReceipt: false,
  email: "",
};

const initialSomeoneValues = {
  sourceCard: null,
  recipientAddress: "",
  firstName: "",
  lastName: "",
  amount: "0",
  currency: "USD",
  description: "",
  sendReceipt: false,
  email: "",
};

const walletOne = config.prefixImgUrl + "walletone-logo-big.svg";

const ownOnWalletFromCardSchema = Yup.object().shape({
  sourceCard: Yup.object()
    .nullable()
    .default(null)
    .required("Source card is required"),
  recipientOnWallet: Yup.object()
    .nullable()
    .default(null)
    .required("Recipient account is required"),
  amount: Yup.string()
    .required("Amount is required")
    .matches(
      "([1-9]\\d*(\\.\\d*[1-9])?|0\\.\\d*[1-9]+)|\\d+(\\.\\d*[1-9])?",
      "Amount must be a number"
    ),
  currency: Yup.string().required("Currency is required"),
  email: Yup.string().email("Email must be valid"),
});

const someoneOnWalletFromCardSchema = Yup.object().shape({
  sourceCard: Yup.object()
    .nullable()
    .default(null)
    .required("Source card is required"),
  recipientAddress: Yup.string().required("OnWallet address is required"),
  firstName: Yup.string(),
  lastName: Yup.string(),
  amount: Yup.string()
    .required("Amount is required")
    .matches(
      "([1-9]\\d*(\\.\\d*[1-9])?|0\\.\\d*[1-9]+)|\\d+(\\.\\d*[1-9])?",
      "Amount must be a number"
    ),
  currency: Yup.string().required("Currency is required"),
  email: Yup.string().email("Email must be valid"),
});

const onSubmit = (values, own) => {
  if (own) {
    return {
      amount: Number.parseFloat(values.amount),
      sourceNumber: values.sourceCard ? values.sourceCard.number : "",
      recipientAccount: values.recipientOnWallet ? values.recipientOnWallet.account : "",
      receiptEmail: values.email || null,
    };
  } else {
    return {
      amount: Number.parseFloat(values.amount),
      sourceNumber: values.sourceCard ? values.sourceCard.number : "",
      recipientAccount: values.recipientAddress,
      receiptEmail: values.email || null,
    };
  }
};

const handlerSelect = (
  bankCards,
  bankAccounts,
  thirdPartyWallets,
  fromTab,
  field,
  errors,
  touched
) => {
  switch (fromTab) {
    case "card":
      return (
        <Select
          {...field}
          error={touched.sourceCard && errors.sourceCard ? errors.sourceCard : ""}
          renderValue={value =>
            !!value ? <CardOption className="short" card={value} /> : ""
          }
        >
          {bankCards.map((card, index) => (
            <CardOption key={index} className="currency-m" value={card} card={card} />
          ))}
        </Select>
      );

    case "bank":
      return (
        <Select
          {...bankAccounts}
          error={touched.sourceCard && errors.sourceCard ? errors.sourceCard : ""}
          renderValue={value =>
            !!value ? <CardOption className="short" card={value} /> : ""
          }
        >
          {[bankAccounts.value].map((bank, index) => (
            <BankAccountOption key={index} value={bank} bank={bank} />
          ))}
        </Select>
      );

    case "thirdPartyWallets":
      return (
        <Select
          {...thirdPartyWallets}
          error={touched.sourceCard && errors.sourceCard ? errors.sourceCard : ""}
          renderValue={thirdPartyWallets =>
            !!thirdPartyWallets ? (
              <ThirdPartyWalletsOption
                className="no-padding"
                thirdPartyWallet={thirdPartyWallets}
              />
            ) : (
              ""
            )
          }
        >
          {[thirdPartyWallets.value].map((service, index) => (
            <ThirdPartyWalletsOption key={index} thirdPartyWallet={service} />
          ))}
        </Select>
      );

    default:
      return (
        <Select
          {...field}
          error={touched.sourceCard && errors.sourceCard ? errors.sourceCard : ""}
          renderValue={value =>
            !!value ? <CardOption className="short" card={value} /> : ""
          }
        >
          {bankCards.map((card, index) => (
            <CardOption key={index} value={card} card={card} />
          ))}
        </Select>
      );
  }
};

const OnWalletFromCardForm = ({
  own,
  isSending,
  bankCards,
  allCurrency,
  bankAccounts,
  thirdPartyWallets,
  transferCardToOnWallet,
  addCartItem,
  formTabs,
  onWalletAccount,
  cardNumber,
  recipientAddress,
  currency,
  amount,
  description,
  closeRightModal,
}) => {
  return (
    <Formik
      initialValues={own ? initialOwnValues : initialSomeoneValues}
      validationSchema={own ? ownOnWalletFromCardSchema : someoneOnWalletFromCardSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, {setSubmitting}) => {
        transferCardToOnWallet(onSubmit(values, own));
      }}
    >
      {({errors, touched, values}) => (
        <div className="CardToCardTransferForm">
          <DetectRegularCard
            field="sourceCard"
            allCards={bankCards}
            cardNumber={cardNumber}
          />
          <DetectOnWalletAccount
            field="recipientOnWallet"
            onWalletAccount={onWalletAccount}
          />
          <DetectRecipient field="recipientAddress" recipient={recipientAddress} />
          <DetectRegularCurrency
            allCurrency={allCurrency}
            field="currency"
            currency={currency}
          />
          <DetectAmount field="amount" amount={amount} />
          <DetectDescription field="description" description={description} />
          <Form className="form">
            <FormSectionSeparator sectionName={messages.source} />
            {formTabs && formTabs()}
            <Field name="sourceCard">
              {({field}) => (
                <div className="cardInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.sourceOfTransfer} />
                  </label>

                  {handlerSelect(
                    bankCards,
                    bankAccounts,
                    thirdPartyWallets,
                    formTabs().props.from,
                    field,
                    errors,
                    touched
                  )}
                </div>
              )}
            </Field>
            <FormSectionSeparator sectionName={messages.recipient} />
            {own && (
              <Field name="recipientOnWallet">
                {({field}) => (
                  <div className="cardInputContainer">
                    <label className="label">
                      <FormattedMessage {...messages.myOnWalletAccount} />
                    </label>
                    <OnWalletOption account={field.value} />
                  </div>
                )}
              </Field>
            )}
            {/* integrate ContactList */}
            {!own && (
              <SomeoneOnWalletWithName
                errors={errors}
                touched={touched}
                values={values}
              />
            )}
            <FormSectionSeparator sectionName={messages.details} />
            <CardAmountWithCurrency
              touched={touched}
              errors={errors}
              allCurrency={allCurrency}
            />

            <Field name="description">
              {({field}) => (
                <div className="descriptionInputContainer">
                  <label className="label">
                    <FormattedMessage {...messages.description} />
                  </label>
                  <Textarea
                    type="text"
                    placeholder={messages.transferFromCryptoWallet.defaultMessage}
                    {...field}
                  />
                </div>
              )}
            </Field>
            <SendRecipient touched={touched} errors={errors} values={values} />
            <FeeRate
              card={values.sourceCard}
              amount={values.amount}
              currency={values.sourceCard ? values.sourceCard.currency : null}
            />
            <div className="buttonsContainer">
              {formTabs().props.from !== "thirdPartyWallets" && (
                <Button
                  type="button"
                  text={messages.addToCart}
                  isSecondary="true"
                  onClick={() => {
                    closeRightModal();
                    addCartItem({
                      type: "transfer",
                      data: {
                        description: values.description,
                        sourceCard: values.sourceCard,
                        sourceAmount: values.amount,
                        sourceCurrency: values.sourceCard
                          ? values.sourceCard.currency
                          : "USD",
                      },
                      action: option =>
                        transferCardToOnWallet(onSubmit(values, own), option),
                    });
                  }}
                />
              )}
              <Button type="submit" text={messages.sendNow} isLoading={isSending} />
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

const mapStateToProps = store => ({
  isSending: store.transferCardData.isLoading,
  bankCards: getBankCards(store),
  // bankAccounts: getBankAccounts(store), (when connect api)
  bankAccounts: {
    name: "SourceCard",
    value: {
      name: "Bank Austria",
      isDefault: true,
      operator: "Bank Austria",
      icon: getCardIconBank(),
      last4: "25214",
      number: "4916453194787152",
    },
  },
  thirdPartyWallets: {
    value: {
      walletName: "Wallet One",
      operator: "Wallet One",
      icon: walletOne,
    },
  },
  allCurrency: getAllRegularCurrency(store),
  onWalletAccount: getOnwalletAccount(store),
});

const mapDispatchToProps = {
  transferCardToOnWallet,
  addCartItem,
  closeRightModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(OnWalletFromCardForm);
