import React, {useState} from "react";
import "./index.scss";

//todo get dynamiclly
const сoinFilters = ["DEHO", "BTC", "ETH"];

const CoinDropDown = ({onChange}) => {
  return (
    <select onChange={onChange}>
      {сoinFilters.map(item => {
        return (
          <option value={item} key={item}>
            {item}
          </option>
        );
      })}
    </select>
  );
};

//todo get dynamiclly
const intervals = [
  {label: "Time", value: "TIME", isActive: false},
  {label: "1m", value: "1MIN", isActive: false},
  {label: "1H", value: "1HOUR", isActive: false},
  {label: "1D", value: "1DAY", isActive: false},
  {label: "1W", value: "1WEEK", isActive: true},
  {label: "1M", value: "1MONTH", isActive: false},
];

const TimeFilter = ({onChange}) => {
  const [timeIntervals, updateTimeIntervals] = useState(intervals);

  return (
    <div className="time-filter">
      {timeIntervals.map((interval, index) => (
        <div
          className={`time-filter-button ${interval.isActive ? "active" : null}`}
          key={index}
          onClick={() => {
            updateTimeIntervals(
              timeIntervals.map(item => ({
                ...item,
                ...{isActive: item.value === interval.value},
              }))
            );
            onChange(interval);
          }}
        >
          {interval.label}
        </div>
      ))}
    </div>
  );
};

const ChartFilter = ({onChangeFilter}) => {
  const [interval, setTimeInterval] = useState("1WEEK");
  const [coin, setCoin] = useState("DEHO");

  return (
    <>
      <CoinDropDown
        onChange={e => {
          setCoin(e.target.value);
          onChangeFilter({coin: e.target.value, interval});
        }}
      />

      <TimeFilter
        onChange={interval => {
          setTimeInterval(interval.value);
          onChangeFilter({coin, interval: interval.value});
        }}
      />
    </>
  );
};

export default ChartFilter;
