import React from "react";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import originalMoment from "moment";
import {extendMoment} from "moment-range";
import {FormattedMessage} from "react-intl";
import "./index.scss";

const CustomDateRangePicker = React.memo(
  ({onChange, title}) => {
    const moment = extendMoment(originalMoment);
    const today = moment();
    const [value, setValue] = React.useState(
      moment.range(today.clone().subtract(30, "days"), today.clone())
    );
    const [isOpen, setIsOpen] = React.useState(false);

    const onSelect = (value, states) => {
      value = moment.range(value.start.startOf("day"), value.end.endOf("day"));
      console.log(value);
      setValue(value);
      onChange(value);

      setIsOpen(false);
    };

    const onToggle = () => {
      setIsOpen(!isOpen);
    };

    const onQuickPeriodClicked = (periodsNumber, periodName) => {
      const period = moment.range(
        today
          .clone()
          .subtract(periodsNumber, periodName)
          .startOf("day"),
        today.clone().endOf("day")
      );
      setValue(period);
      onChange(value);
      setIsOpen(false);
    };

    return (
      <div className="CustomDateRangePicker">
        <label className="label">
          <FormattedMessage {...title} />
        </label>
        <div className="dateRangeDropdown" onClick={onToggle}>
          <span>
            {value && value.start.format("DD MMM YYYY")} -{" "}
            {value && value.end.format("DD MMM YYYY")}
          </span>
          <span className="dateRangeDropdownIcon"></span>
        </div>
        {isOpen && (
          <div className="overlayContainer">
            <div className="dateRangeOverlay">
              <div
                aria-hidden="true"
                className="overlayHiddenBackgound"
                onClick={onToggle}
              />
              <div className="quickRangeButtonsContainer">
                <div
                  className="quickRangeButton"
                  onClick={() => onQuickPeriodClicked(0, "days")}
                >
                  Today
                </div>
                <div
                  className="quickRangeButton"
                  onClick={() => onQuickPeriodClicked(1, "weeks")}
                >
                  Last Week
                </div>
                <div
                  className="quickRangeButton"
                  onClick={() => onQuickPeriodClicked(1, "months")}
                >
                  Last Month
                </div>
                <div
                  className="quickRangeButton"
                  onClick={() => onQuickPeriodClicked(1, "years")}
                >
                  Last Year
                </div>
              </div>
              <DateRangePicker
                onSelect={onSelect}
                value={value}
                singleDateRange={true}
                numberOfCalendars={2}
                selectionType="range"
              />
            </div>
          </div>
        )}
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.title === nextProps.title
);

export default CustomDateRangePicker;
