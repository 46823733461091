import {defineMessages} from "react-intl";

const scope = "DashboardPage";

const messages = defineMessages({
  headerDashboardTitle: {
    id: `${scope}.headerDashboardTitle`,
    defaultMessage: "Dashboard",
  },
  headerCart: {
    id: `${scope}.headerCard`,
    defaultMessage: "Cart",
  },
  onwalletAccount: {
    id: `${scope}.onwalletAccount`,
    defaultMessage: "OnWallet Account",
  },
  bitcoinAccount: {
    id: `${scope}.bitcoinAccount`,
    defaultMessage: "Bitcoin Account",
  },
  bitcoinWallet: {
    id: `${scope}.bitcoinWallet`,
    defaultMessage: "Bitcoin Wallet",
  },
  dehoWalletAccount: {
    id: `${scope}.dehoWalletAccount`,
    defaultMessage: "Deho Wallet",
  },
  dehoBuy: {
    id: `${scope}.dehoBuy`,
    defaultMessage: "Buy",
  },
});

export default messages;
