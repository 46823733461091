import React from "react";
import {connect} from "react-redux";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import {FormattedMessage} from "react-intl";

import "./index.scss";
import messages from "../../messages";
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import Select from "shared/components/Select";
import Input from "shared/components/Input";
import Button from "shared/components/Button";

import {CountdownCircleTimer} from "react-countdown-circle-timer";
import {addCartItem} from "shared/cartService/actions";
import {getOnwalletAccount} from "shared/cardService/selectors";
import {
  OnWalletOption,
  WalletOption,
  WalletOptionSelected,
} from "shared/components/CardWalletOptions";
import Textarea from "shared/components/Input/Textarea";
import {
  getCoinAmountInUSD,
  getCryptoWallets,
  getWalletIconByName,
} from "shared/walletService/selectors";
import {getCurrencyRates} from "shared/ratesService/selectors";
import {getCoinAmountInOtherCurrency} from "shared/walletService/selectors";
import {transferCryptoToOnWallet} from "shared/walletService/actions";
import {
  DetectCryptoWallet,
  DetectDescription,
  DetectOnWalletAccount,
  DetectRecipient,
  FeeRate,
  SendRecipient,
  SomeoneOnWalletWithName,
} from "./components";
import config from "shared/config";

const arrowIcon = config.prefixImgUrl + "left-arrow-icon.svg";

const initialOwnValues = {
  sourceWallet: null,
  recipientOnWallet: null,
  amount: "0",
  currency: "USD",
  description: "",
  sendReceipt: false,
  email: "",
};

const initialSomeoneValues = {
  sourceWallet: null,
  recipientAddress: "",
  firstName: "",
  lastName: "",
  amount: "0",
  currency: "USD",
  description: "",
  sendReceipt: false,
  email: "",
};

const ownOnWalletFromWalletSchema = Yup.object().shape({
  sourceWallet: Yup.object()
    .nullable()
    .default(null)
    .required("Source wallet is required"),
  recipientOnWallet: Yup.object()
    .nullable()
    .default(null)
    .required("Recipient account is required"),
  amount: Yup.string()
    .required("Amount is required")
    .matches(
      "([1-9]\\d*(\\.\\d*[1-9])?|0\\.\\d*[1-9]+)|\\d+(\\.\\d*[1-9])?",
      "Amount must be a number"
    ),
  currency: Yup.string().required("Currency is required"),
  email: Yup.string().email("Email must be valid"),
});

const someoneOnWalletFromWalletSchema = Yup.object().shape({
  sourceWallet: Yup.object()
    .nullable()
    .default(null)
    .required("Source wallet is required"),
  recipientAddress: Yup.string().required("OnWallet address is required"),
  firstName: Yup.string(),
  lastName: Yup.string(),
  amount: Yup.string()
    .required("Amount is required")
    .matches(
      "([1-9]\\d*(\\.\\d*[1-9])?|0\\.\\d*[1-9]+)|\\d+(\\.\\d*[1-9])?",
      "Amount must be a number"
    ),
  currency: Yup.string().required("Currency is required"),
  email: Yup.string().email("Email must be valid"),
});

const onSubmit = (values, own) => {
  if (own) {
    return {
      cryptoAmount: Number.parseFloat(values.amount),
      sourceCurrency: values.sourceWallet
        ? values.sourceWallet.currency.toLowerCase()
        : "",
      recipientAccount: values.recipientOnWallet ? values.recipientOnWallet.account : "",
      receiptEmail: values.email || null,
    };
  } else {
    return {
      cryptoAmount: Number.parseFloat(values.amount),
      sourceCurrency: values.sourceWallet
        ? values.sourceWallet.currency.toLowerCase()
        : "",
      recipientAccount: values.recipientAddress,
      receiptEmail: values.email || null,
    };
  }
};

const OnWalletFromWalletForm = ({
  own,
  isSending,
  addCartItem,
  formTabs,
  onWalletAccount,
  allWallets,
  currencyRates,
  transferCryptoToOnWallet,
  walletAddress,
  recipientAddress,
  description,
}) => {
  return (
    <Formik
      initialValues={own ? initialOwnValues : initialSomeoneValues}
      validationSchema={
        own ? ownOnWalletFromWalletSchema : someoneOnWalletFromWalletSchema
      }
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, {setSubmitting}) => {
        console.log("values: ", values);
        // transferCryptoToOnWallet(onSubmit(values, own));
      }}
    >
      {({errors, touched, values}) => {
        return (
          <div className="CardToCardTransferForm">
            <DetectOnWalletAccount
              field="recipientOnWallet"
              onWalletAccount={onWalletAccount}
            />
            <DetectCryptoWallet
              field="sourceWallet"
              allWallets={allWallets}
              walletAddress={walletAddress}
            />
            <DetectRecipient field="recipientAddress" recipient={recipientAddress} />
            <DetectDescription field="description" description={description} />
            <Form className="form">
              <FormSectionSeparator sectionName={messages.source} />
              {formTabs && formTabs()}
              <Field name="sourceWallet">
                {({field}) => (
                  <div className="cardInputContainer">
                    <label className="label">
                      <FormattedMessage {...messages.wallet} />
                    </label>
                    <Select
                      {...field}
                      height={64}
                      renderValue={value =>
                        !!value ? (
                          <WalletOptionSelected className="no-padding" wallet={value} />
                        ) : (
                          ""
                        )
                      }
                    >
                      {allWallets.map((wallet, index) => (
                        <WalletOption key={index} value={wallet} wallet={wallet} />
                      ))}
                    </Select>
                  </div>
                )}
              </Field>
              <FormSectionSeparator sectionName={messages.recipient} />
              {own && (
                <Field name="recipientOnWallet">
                  {({field}) => (
                    <div className="cardInputContainer">
                      <label className="label">
                        <FormattedMessage {...messages.myOnWalletAccount} />
                      </label>
                      <OnWalletOption account={field.value} />
                    </div>
                  )}
                </Field>
              )}
              {!own && (
                <SomeoneOnWalletWithName
                  errors={errors}
                  touched={touched}
                  values={values}
                />
              )}
              <FormSectionSeparator sectionName={messages.details} />
              <div className="AmountWalletCurrency">
                <Field name="amount">
                  {({field}) => (
                    <div className="amountInputContainer">
                      <label className="label">
                        <FormattedMessage {...messages.amountInSourceCurrency} />
                      </label>
                      <div className="amountWrapper">
                        {!!values.sourceWallet && (
                          <img
                            className="walletIcon"
                            src={getWalletIconByName(values.sourceWallet.walletName)}
                            alt="coin"
                          />
                        )}
                        {!!values.sourceWallet && (
                          <Input
                            type={
                              values.sourceWallet.walletName === "Deho"
                                ? "number"
                                : "text"
                            }
                            value={null}
                            error={touched.amount && errors.amount}
                            {...field}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </Field>

                <div className="amountInUSDContainer">
                  <label className="label">
                    <FormattedMessage {...messages.amountInRecipientCurrency} />
                  </label>
                  <div className="amountWrapper">
                    <span className="equal">≈</span>
                    <Input
                      type="text"
                      value={
                        own
                          ? getCoinAmountInOtherCurrency(
                              values.sourceWallet,
                              values.amount,
                              currencyRates,
                              values.recipientOnWallet
                                ? values.recipientOnWallet.currency
                                : ""
                            )
                          : getCoinAmountInUSD(
                              values.sourceWallet,
                              values.amount,
                              currencyRates
                            )
                      }
                      readOnly
                    />
                  </div>
                </div>
              </div>

              {/* Timer */}
              {!!values.sourceWallet && !!values.recipientOnWallet && (
                <div className="timer">
                  <div className="exchangeRate">
                    <div className="rate">
                      <FormattedMessage {...messages.exchangeRate} />:{" "}
                      {`${values.amount} ${values.sourceWallet.currency}`}
                      <div className="arrow-right">
                        <img src={arrowIcon} alt="arrow" />
                      </div>
                      {own
                        ? getCoinAmountInOtherCurrency(
                            values.sourceWallet,
                            values.amount,
                            currencyRates,
                            values.recipientOnWallet
                              ? values.recipientOnWallet.currency
                              : ""
                          )
                        : getCoinAmountInUSD(
                            values.sourceWallet,
                            values.amount,
                            currencyRates
                          )}{" "}
                      {values.currency}
                    </div>
                    <div className="timer">
                      {/* TODO add function to restart timer from https://github.com/vydimitrov/react-countdown-circle-timer#props-for-both-reactreact-native*/}
                      <CountdownCircleTimer
                        isPlaying
                        size={26}
                        strokeWidth={2}
                        duration={60}
                        colors={[["#0080ff", 1]]}
                      >
                        {({remainingTime}) => remainingTime}
                      </CountdownCircleTimer>
                    </div>
                  </div>
                </div>
              )}

              <Field name="description">
                {({field}) => (
                  <div className="descriptionInputContainer">
                    <label className="label">
                      <FormattedMessage {...messages.description} />
                    </label>
                    <Textarea
                      type="text"
                      placeholder={messages.transferFromCryptoWallet.defaultMessage}
                      {...field}
                    />
                  </div>
                )}
              </Field>
              <SendRecipient touched={touched} errors={errors} values={values} />
              <FeeRate
                wallet={values.sourceWallet}
                amount={values.amount}
                currency={values.sourceWallet ? values.sourceWallet.currency : null}
                currencyRates={currencyRates}
              />
              <div className="buttonsContainer">
                <Button
                  type="button"
                  text={messages.addToCart}
                  isSecondary="true"
                  onClick={() => {
                    addCartItem({
                      type: "transfer",
                      data: {
                        description: values.description,
                        sourceWallet: values.sourceWallet,
                        sourceAmount: values.amount,
                        sourceCurrency: values.sourceWallet
                          ? values.sourceWallet.currency
                          : "",
                      },
                      action: option =>
                        transferCryptoToOnWallet(onSubmit(values, own), option),
                    });
                  }}
                />

                <Button type="sumbit" text={messages.sendNow} isLoading={isSending} />
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = store => ({
  isSending: store.transferCardData.isLoading,
  onWalletAccount: getOnwalletAccount(store),
  allWallets: getCryptoWallets(store),
  currencyRates: getCurrencyRates(store),
});

const mapDispatchToProps = {
  addCartItem,
  transferCryptoToOnWallet,
};

export default connect(mapStateToProps, mapDispatchToProps)(OnWalletFromWalletForm);
