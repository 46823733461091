import React from "react";
import {connect} from "react-redux";

import Header from "shared/components/Header";
import MessageViewElement from "shared/components/MessageViewElement";

import messages from "./messages";

import "./index.scss";
// import {routes} from "shared/appUrls";

import {FormattedMessage, useIntl} from "react-intl";

import config from "shared/config";
import moment from "moment";
import MessagesContainer from "./components/MessagesContainer";
import {getMessageList, getUnReadNumber} from "shared/messagesService/selectors";
import {markAsRead, markAllRead, getMessages} from "shared/messagesService/actions";
import SearchInput from "shared/components/SearchInput";
import Button from "shared/components/Button";
import {Link} from "react-router-dom";
// import Orders from "./components/Orders";
const MessagesIcon = config.prefixImgUrl + "bell-icon.svg";
const NoMessage = config.prefixImgUrl + "no_messages.svg";

const Messages = ({messagesList, markAsRead, markAllRead, unReadNumber, getMessages}) => {
  const [selected, setSelected] = React.useState(null);

  const [selectedSearch, setSelectedSearch] = React.useState("");
  const [selectedUnread, setSelectedUnread] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [newMessageList, setNewMessageList] = React.useState([]);

  React.useEffect(() => {
    getMessages();
  }, [getMessages]);

  React.useEffect(() => {
    const arr = [];
    let newMessageList = null;

    if (isLoading) {
      setSelected(messagesList[0]);
    }

    if (messagesList.length) {
      newMessageList = messagesList.map(item => {
        if (
          !arr.find(
            messages => messages.day === moment(item.time).format("DD MMMM, YYYY")
          )
        ) {
          arr.push({
            day: moment(item.time).format("DD MMMM, YYYY"),
            time: item.time,
            messages: [],
          });
        }

        arr.forEach(messages => {
          if (moment(item.time).format("DD MMMM, YYYY") === messages.day) {
            messages.messages.push(item);
          }
        });

        return arr;
      })[messagesList.length - 1];

      setNewMessageList(newMessageList.reverse());
    }

    // Change after demo
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  }, [isLoading, messagesList]);

  const handleClick = message => {
    setSelected(message);
  };
  const handleChangeSearch = value => {
    setSelectedSearch(value);
  };

  const intl = useIntl(); // intl extracted from useIntl hook
  const titleMsg = intl.formatMessage({...messages.input});

  return (
    <>
      <Header
        headerTitle={<FormattedMessage {...messages.header} />}
        navLinks={[]}
        logoImage={MessagesIcon}
      >
        <div className="messages__header__actionBlock">
          {!selectedUnread && (
            <div className="action__button" onClick={() => setSelectedUnread(true)}>
              <FormattedMessage {...messages.showUnReadOnly} />
            </div>
          )}
          {selectedUnread && (
            <div className="action__button" onClick={() => setSelectedUnread(false)}>
              <FormattedMessage {...messages.showAll} />
            </div>
          )}
          <div className="action__button" onClick={() => markAllRead()}>
            <FormattedMessage {...messages.markAllAsRead} />
          </div>
          <SearchInput
            value={selectedSearch}
            onChange={e => handleChangeSearch(e.target.value)}
            onClick={() => setSelectedSearch("")}
            placeholder={titleMsg}
          />
        </div>
      </Header>
      {!isLoading &&
        (messagesList.length ? (
          <div className="main-Messages-page mainContent">
            <div className="messages__block list">
              <div className="message__block_date__now">
                <div className={`date__now ${selectedSearch ? "search_result" : ""}`}>
                  <span className="date_time">{moment().format("DD MMM, YYYY")}</span>
                  <div className="messageListType">
                    <span className={`unred ${selectedUnread ? "true" : ""}`}>
                      : Unread Only
                    </span>
                    {/*<span className={`read ${selectedMarkRead ? "true" : ""}`}>*/}
                    {/*  : Read only*/}
                    {/*</span>*/}
                    <span className="search__title">
                      <FormattedMessage {...messages.searchResult} />{" "}
                      <span className="search__req">: {selectedSearch}</span>
                    </span>

                    <div className="action__button">
                      <span
                        onClick={() => handleChangeSearch("")}
                        className={`reset ${selectedSearch ? "true" : ""}`}
                      >
                        <FormattedMessage {...messages.reset} />
                      </span>
                      <span
                        className={`showAll ${selectedUnread ? "true" : ""}`}
                        onClick={() => setSelectedUnread(false)}
                      >
                        <FormattedMessage {...messages.showAll} />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="message__block_unread">
                  {unReadNumber} <FormattedMessage {...messages.unreadMessages} />
                </div>
              </div>
              <div className="messages__list">
                {newMessageList.map(message => (
                  <MessagesContainer
                    key={message.id}
                    date={message.time}
                    items={message.messages}
                    handleClick={message => handleClick(message)}
                    selected={messagesList[0].id}
                    selectedUnread={selectedUnread}
                    selectedSearch={selectedSearch}
                  />
                ))}
              </div>
            </div>

            <MessageViewElement
              id={selected && selected.id}
              selected={selected}
              time={selected ? moment(selected.time).format("HH:MM") : ""}
              date={selected ? moment(selected.time).format("DD MMMM, YYYY") : ""}
              heading={selected && selected.title}
              messageBodyFull={selected && selected.content}
              messageKey={selected && selected.key}
              onClick={() => markAsRead(selected && selected.id)}
            />
          </div>
        ) : (
          <div className="message-empty">
            <div className="message-empty__img">
              <img src={NoMessage} alt="No message" />
            </div>
            <p>{messages.noMessageForNow.defaultMessage}</p>
            <div className="message-empty__date">
              {moment(new Date()).format("DD MMMM, YYYY")}
            </div>
            <Link to={""}>
              <Button
                className="message-empty__btn"
                type="button"
                text={messages.goBack}
              />
            </Link>
          </div>
        ))}
    </>
  );
};

const mapStateToProps = store => ({
  messagesList: getMessageList(store),
  unReadNumber: getUnReadNumber(store),
});

function mapDispatchToProps(dispatch) {
  return {
    getMessages: data => {
      dispatch(getMessages(data));
    },
    markAsRead: data => {
      dispatch(markAsRead(data));
    },
    markAllRead: data => {
      dispatch(markAllRead(data));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Messages);
