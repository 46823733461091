import {
  SET_DATA_CARD_SHOPPING,
  SET_DATA_VOUCHER_SHOPPING,
  GET_VOUCHER_LIST_SHOPPING_SUCCESS,
  // GET_VOUCHER_LIST_SHOPPING_FAIL,
  CREATE_VOUCHER_LIST_SHOPPING_SUCCESS,
  // CREATE_VOUCHER_LIST_SHOPPING_FAIL,
  SEND_VOUCHER_ONWALLET_ACCOUNT,
  // TRANSFER_VOUCHER_SUCCESS,
  // TRANSFER_VOUCHER_FAIL,
  GET_VOUCHER_QR_SUCCESS,
  DELETE_VOUCHER_USER_SUCCESS,
  // GET_VOUCHER_QR_FAIL,
} from "./actions/types";
import initialState from "./initialState";

export const shoppingReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case SET_DATA_CARD_SHOPPING:
      return {
        ...state,
        shoppingCardData: payload,
      };
    case SET_DATA_VOUCHER_SHOPPING:
      return {
        ...state,
        shoppingVoucherData: payload,
      };
    case CREATE_VOUCHER_LIST_SHOPPING_SUCCESS:
      return {
        ...state,
        voucherList: [...state.voucherList, ...payload],
      };
    case GET_VOUCHER_LIST_SHOPPING_SUCCESS:
      return {
        ...state,
        voucherList: payload,
      };
    case DELETE_VOUCHER_USER_SUCCESS:
      return {
        ...state,
        voucherList: state.voucherList.filter(voucher => voucher.voucherId !== payload),
      };
    case GET_VOUCHER_QR_SUCCESS:
      return {
        ...state,
        voucherQRCode: payload,
      };
    case SEND_VOUCHER_ONWALLET_ACCOUNT:
      return state;
    default:
      return state;
  }
};

const shoppingAddCard = (state = initialState.formAddCard, action) => {
  const {type} = action;

  switch (type) {
    default:
      return state;
  }
};
export {shoppingAddCard};
