import React, {Fragment} from "react";
import {connect} from "react-redux";
import {Formik, Form} from "formik";
import {FormattedMessage} from "react-intl";
// Actions
import {closeRightModal} from "shared/components/RightModal/actions";
// Components
import RadioBlue from "shared/components/RadioBlue";
import Button from "shared/components/Button";
// Utils
import messages from "../../messages";
// Styles
import "./index.scss";

const status = "normal";

const ChangeFinancialAccountStatusModal = ({closeRightModal}) => {
  const initFormik = {status};

  return (
    <Fragment>
      <Formik initialValues={initFormik} onSubmit={() => closeRightModal()}>
        {({values, setFieldValue, handleChange}) => (
          <Form>
            <div className="customer-change-status">
              {/* Radio Group */}
              <div className="customer-change-status__item">
                <RadioBlue
                  checked={values.status === "normal"}
                  onChange={() => setFieldValue("status", "normal")}
                  value="normal"
                  name="status-group"
                  title={<FormattedMessage {...messages.normal} />}
                  subTitle={<FormattedMessage {...messages.noLimits} />}
                />
              </div>
              <div className="customer-change-status__item">
                <RadioBlue
                  checked={values.status === "blocked"}
                  onChange={() => setFieldValue("status", "blocked")}
                  value="blocked"
                  name="status-group"
                  title={<FormattedMessage {...messages.blocked} />}
                  subTitle={<FormattedMessage {...messages.incoming} />}
                />
              </div>
              <div className="customer-change-status__item">
                <RadioBlue
                  checked={values.status === "blockedOutgoing"}
                  onChange={() => setFieldValue("status", "blockedOutgoing")}
                  value="blockedOutgoing"
                  name="status-group"
                  title={<FormattedMessage {...messages.blockedOutgoing} />}
                  subTitle={<FormattedMessage {...messages.outgoing} />}
                />
              </div>
              <div className="customer-change-status__item">
                <RadioBlue
                  checked={values.status === "blockedIncoming"}
                  onChange={() => setFieldValue("status", "blockedIncoming")}
                  value="blockedIncoming"
                  name="status-group"
                  title={<FormattedMessage {...messages.blockedIncoming} />}
                  subTitle={<FormattedMessage {...messages.incomingTransactions} />}
                />
              </div>

              {/* Actions */}
              <div className="customer-change-status__actions">
                <Button
                  type="button"
                  text={messages.cancel}
                  isSecondary="true"
                  onClick={() => closeRightModal()}
                />

                <Button type="submit" text={messages.changeStatus} />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

// Redux
const mapDispatchToProps = {
  closeRightModal,
};

export default connect(null, mapDispatchToProps)(ChangeFinancialAccountStatusModal);
