import React, {Fragment} from "react";
import {
  USE_AGREEMENT,
  LEGAL_PERMISSIONS,
  MERCHANTS_ACCOUNT,
  SECURITY_WEBSITE,
  SERVICES_PROVIDED,
  PROMOTIONAL_ACTIVITIES,
  PRIVACY,
  REFUND,
  REPRESENTATIONS,
  INTELLECTUAL,
  DISPUTE_RESOLUTION,
  TERM_AND_TERMINATION,
  SEVERABILITY_AND_WAIVER,
  MISCELLANEOUS,
} from "./constance";
// Styles
import "../../index.scss";

const Agreement = () => {
  return (
    <Fragment>
      <div className="document-wrapper text__container_article">
        <div>
          This Agreement is executed and entered into between Merchant (“you”) and
          Onwallet (“we”, “us”) in consideration of mutual promises. Onwallet and Merchant
          are two independent parties contracting for services and neither is an agent or
          a partner of the other. We deal only with people who are above 18 years and
          legally able to enter into a binding contract. Please read this agreement
          carefully and upon accepting, you are bound by the terms of this agreement.
          Onwallet is just an intermediary providing services allowing customers to make
          payment using their app for products brought from the Merchant’s shop/store and
          reserves all the rights to modify or amend this Agreement from time to time.
        </div>

        <div className="mt-6 text__container_title">1. Definitions: </div>

        <div>
          <span> Website - </span>the website you are currently using, Onwallet, and any
          sub-domain of this site unless expressly excluded by their own terms and
          conditions.
          <br />
          <span>Effective date - </span> means the date on which this privacy policy has
          come into force and effect.
          <br />
          <span>Merchant (You):</span> a person who sells directly to the public either
          from a store or through internet. Here Merchant refers to any person selling
          goods/products through Onwallet.
          <br />
          <span>Merchant Account:</span> an e wallet account operated by a Merchant for
          accepting payments from customers using Onwallet.
          <br />
          <span>Refund:</span> An amount of money that is returned as repayment to the
          customer either partly or wholly by the Merchant.
          <br />
          <span>Chargeback - </span> This is a charge that is returned to a payment card
          after a customer disputes an item on their account statement or transaction
          report.
          <br />
          <span>Merchant Identification Number - </span> a unique identification number
          provided to the Merchant by Onwallet for identifying the Merchant easily by
          customers.
          <br />
          <span>Merchant Application Form - </span> the form to be filled by the Merchant
          which shall contain all the details about the Merchant including the
          goods/services provided by them to the customers through Onwallet.
          <br />
          <span>Applicable law:</span> means all laws, statutes, by-laws, rules,
          regulations, orders, ordinances, protocols, codes, guidelines, policies,
          notices, directions, judgments, decrees, arbitral awards or other requirements
          or official directive of any Governmental Authority or person acting under the
          authority of any Governmental Authority of that respective Country.
          <br />
          <span>Governing Law:</span> the rules and laws that govern the agreement in case
          of any legal issues/disputes that arise out of this agreement.
          <br />
          The singular shall include the plural and vice versa, and the masculine shall
          include the feminine and vice versa.
          <br />A reference to any legislation shall include any statutory modification or
          re-enactment thereof or any statutory provisions substituted therefore.
        </div>

        <div className="mt-6 text__container_title">
          2. Terms of use of this agreement
        </div>
        <div className="document-wrapper-restrictions__items mt-4">
          {USE_AGREEMENT.map((item, index) => (
            <div className="document-wrapper-restrictions__items__item" key={index}>
              <div>{item.id}</div>
              <div>{item.text}</div>
            </div>
          ))}
        </div>

        <div className="mt-6 text__container_title">3. Legal and general permissions</div>
        <div className="document-wrapper-restrictions__items mt-4">
          {LEGAL_PERMISSIONS.map((item, index) => (
            <div className="document-wrapper-restrictions__items__item" key={index}>
              <div>{item.id}</div>
              <div>{item.text}</div>
            </div>
          ))}
        </div>

        <div className="mt-6 text__container_title">4. Merchants use of the account</div>
        <div className="document-wrapper-restrictions__items mt-4">
          {MERCHANTS_ACCOUNT.map((item, index) => (
            <div className="document-wrapper-restrictions__items__item" key={index}>
              <div>{item.id}</div>
              <div>{item.text}</div>
            </div>
          ))}
        </div>

        <div className="mt-6 text__container_title">5. Security of website</div>
        <div>
          If you violate Our website we shall take legal action against you.
          <br />
          You now agree that you will not, and will not allow any other person to:
        </div>

        <div className="document-wrapper-restrictions__items mt-4">
          {SECURITY_WEBSITE.map((item, index) => (
            <div className="document-wrapper-restrictions__items__item" key={index}>
              <div>{item.id}</div>
              <div>
                <section>{item.text}</section>
                {item.details &&
                  item.details.map((detailsItem, index) => (
                    <div
                      className="document-wrapper-restrictions__items__item"
                      key={index}
                    >
                      <div>{detailsItem.id}</div>
                      <div>{detailsItem.text}</div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>

        <div className="mt-6 text__container_title">6. Services provided by us:</div>
        <div className="document-wrapper-restrictions__items">
          {SERVICES_PROVIDED.map((item, index) => (
            <div className="document-wrapper-restrictions__items__item" key={index}>
              <div>{item.id}</div>
              <div>
                <section>{item.text}</section>
                {item.details &&
                  item.details.map((item, index) => (
                    <div
                      className="document-wrapper-restrictions__items__item"
                      key={index}
                    >
                      <div>{item.id}</div>
                      <div>{item.text}</div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>

        <div className="mt-4">
          <div>
            <span>Note:</span> If it is necessary for us to interrupt the Services, we
            will give you reasonable notice where this is possible and when we judge the
            down time is such as to justify telling you.
          </div>

          <ul>
            <li>
              You acknowledge that the Services may also be interrupted for many reasons
              beyond our control.
            </li>
            <li>
              You agree that we are not liable to you for any loss, foreseeable or not,
              arising from any interruption to the Services.
            </li>
          </ul>
        </div>

        <div className="mt-6 text__container_title">7. Promotional activities</div>
        <div className="document-wrapper-restrictions__items">
          {PROMOTIONAL_ACTIVITIES.map((item, index) => (
            <div className="document-wrapper-restrictions__items__item" key={index}>
              <div>{item.id}</div>
              <div>{item.text}</div>
            </div>
          ))}
        </div>

        <div className="mt-6 text__container_title">8. Privacy</div>
        <div className="document-wrapper-restrictions__items">
          {PRIVACY.map((item, index) => (
            <div className="document-wrapper-restrictions__items__item" key={index}>
              <div>{item.id}</div>
              <div>{item.text}</div>
            </div>
          ))}
        </div>

        <div className="mt-6 text__container_title">
          9. Refund, Delivery, Cancellation and Chargeback
        </div>
        <div className="document-wrapper-restrictions__items">
          {REFUND.map((item, index) => (
            <div className="document-wrapper-restrictions__items__item" key={index}>
              <div>{item.id}</div>
              <div>{item.text}</div>
            </div>
          ))}
        </div>

        <div className="mt-6 text__container_title">
          10. Intellectual property rights:
        </div>
        <div className="document-wrapper-restrictions__items">
          {INTELLECTUAL.map((item, index) => (
            <div className="document-wrapper-restrictions__items__item" key={index}>
              <div>{item.id}</div>
              <div>{item.text}</div>
            </div>
          ))}
        </div>

        <div className="mt-6 text__container_title">
          11. Dispute resolution and governing law:
        </div>
        <div className="document-wrapper-restrictions__items">
          {DISPUTE_RESOLUTION.map((item, index) => (
            <div className="document-wrapper-restrictions__items__item" key={index}>
              <div>{item.id}</div>
              <div>
                <section>{item.text}</section>
                {item.details &&
                  item.details.map((detailsItem, index) => (
                    <div
                      className="document-wrapper-restrictions__items__item"
                      key={index}
                    >
                      <div>{detailsItem.id}</div>
                      <div>{detailsItem.text}</div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>

        <div className="mt-6 text__container_title">12. Representations and warrants</div>
        <div>
          You shall represent and warrant that during the term of this Agreement, you
          shall:
        </div>
        <div className="document-wrapper-restrictions__items">
          {REPRESENTATIONS.map((item, index) => (
            <div className="document-wrapper-restrictions__items__item" key={index}>
              <div>{item.id}</div>
              <div>
                <section>{item.text}</section>
                {item.details &&
                  item.details.map((detailsItem, index) => (
                    <div
                      className="document-wrapper-restrictions__items__item"
                      key={index}
                    >
                      <div>{detailsItem.id}</div>
                      <div>{detailsItem.text}</div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>

        <div className="mt-6 text__container_title">13. Term and termination</div>
        <div className="document-wrapper-restrictions__items">
          {TERM_AND_TERMINATION.map((item, index) => (
            <div className="document-wrapper-restrictions__items__item" key={index}>
              <div>{item.id}</div>
              <div>
                <section>{item.text}</section>
                {item.details &&
                  item.details.map((detailsItem, index) => (
                    <div
                      className="document-wrapper-restrictions__items__item"
                      key={index}
                    >
                      <div>{detailsItem.id}</div>
                      <div>{detailsItem.text}</div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
        <div className="mt-3">
          <span>Note:</span> In the event of termination of your account, Onwallet shall
          issue prior notice 30 days in advance. If you fail to cure the breach in 30 days
          period, Onwallet shall without any notification suspend/terminate your account
          immediately. In case of such termination, you shall not be able to use your
          account for a limited period of time.
        </div>

        <div className="mt-6 text__container_title">14. Indemnity</div>
        <div>
          You acknowledge and agree that you shall indemnify and hold harmless Onwallet,
          its affiliates and agents from and against any claim, allegation, loss,
          proceedings, damage, and penalty arising out of and in connection with:
        </div>

        <ul>
          <li>Access of the application or third-party content.</li>
          <li>Breach of terms and conditions of this agreement.</li>
          <li>Violation of any law of the land.</li>
          <li>Negligence, ignorance or wilful conduct.</li>
          <li>
            Violation of any rights of third party, including infringement of by you of
            any IP or misappropriation of any proprietary information of any person or
            entity.
          </li>
          <div>
            Note: These obligations will survive any termination of the Agreement.
          </div>
        </ul>

        <div className="mt-6 text__container_title">15. Severability and waiver</div>
        <div className="document-wrapper-restrictions__items">
          {SEVERABILITY_AND_WAIVER.map((item, index) => (
            <div className="document-wrapper-restrictions__items__item" key={index}>
              <div>{item.id}</div>
              <div>{item.text}</div>
            </div>
          ))}
        </div>

        <div className="mt-6 text__container_title">16. Amendments</div>
        <div>
          Onwallet may modify or amend the terms of this agreement from time to time with
          or without providing any prior information or notification to you. If you
          continue to use the application even after the modification or amendment, you
          will be deemed to have agreed or accepted such amendments or modifications.
        </div>

        <div className="mt-6 text__container_title">17. Entire agreement</div>
        <div>
          This agreement including all the documents incorporated herein constitute the
          entire agreement with respect to the use of the mobile application hereunder and
          supersedes all the prior or contemporaneous oral or written communications,
          proposals, agreements or any representations regarding such subject matter.
        </div>

        <div className="mt-6 text__container_title">18. Miscellaneous</div>
        <div className="document-wrapper-restrictions__items">
          {MISCELLANEOUS.map((item, index) => (
            <div className="document-wrapper-restrictions__items__item" key={index}>
              <div>{item.id}</div>
              <div>
                <section>{item.text}</section>
                {item.details &&
                  item.details.map((detailsItem, index) => (
                    <div
                      className="document-wrapper-restrictions__items__item"
                      key={index}
                    >
                      <div>{detailsItem.id}</div>
                      <div>{detailsItem.text}</div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default Agreement;
