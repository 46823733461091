import React from "react";
import {connect} from "react-redux";

import PromoItem from "./PromoItem";

import "./index.scss";

const ShoppingPromos = ({formTabs, personalPromos}) => {
  return (
    <>
      {formTabs && formTabs()}
      <div className="promos-layout">
        {personalPromos.map((item, index) => (
          <PromoItem item={item} key={index} />
        ))}
      </div>
    </>
  );
};

const mapStateToProps = store => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingPromos);
