import React, {useEffect} from "react";
import {connect} from "react-redux";
import "./index.scss";
import Chart from "shared/components/Chart";
import messages from "../../messages";
import {FormattedMessage} from "react-intl";
import {
  fetchDehoBalanceHistory,
  fetchOnWalletBalanceHistory,
} from "shared/historyService/actions";
import {getBalanceData, isBalanceLoading} from "shared/historyService/selectors";
import {getOnwalletAccount, isCardsLoading} from "shared/cardService/selectors";
import Loader from "shared/components/Loader";
import {
  getDehoWallet,
  isWalletsLoading,
  getShortAddress,
} from "shared/walletService/selectors";
import {openRightModal} from "shared/components/RightModal/actions";
import moment from "moment";
import {setActiveCryptoWallet} from "shared/walletService/actions";
import {openQRCodeModal} from "shared/components/QRCodeModal/actions";

import config from "shared/config";
import {decimal2digits} from "../../../../shared/tools";
const infoIcon = config.prefixImgUrl + "info-icon-blue-dark.svg";
const dehoIcon = config.prefixImgUrl + "deho_coin_gold.svg";
const onwalletIcon = config.prefixImgUrl + "onwallet-icon-short_v1.svg";

const WalletsContainer = ({
  fetchDehoBalanceHistory,
  fetchOnWalletBalanceHistory,
  onWalletAccount,
  isOnwalletLoading,
  dehoWallet,
  isDehoLoading,
  openRightModal,
  openQRCodeModal,
  balanceData,
  isBalanceLoading,
}) => {
  useEffect(() => {
    fetchDehoBalanceHistory();
  }, [fetchDehoBalanceHistory]);
  useEffect(() => {
    fetchOnWalletBalanceHistory();
  }, [fetchOnWalletBalanceHistory]);
  return (
    <>
      <div className="BuyDehoChart">
        <h1 className="walletsPageContentTitle">
          <FormattedMessage {...messages.onWalletDehoBalance} />
        </h1>
        <div className="ExchangeContainer">
          {isBalanceLoading && <Loader local />}
          {!isBalanceLoading && (
            <>
              <div className="balanceRateChart">
                <Chart
                  id={"balanceRateChart"}
                  data={balanceData.map(item => ({
                    name: moment(item.day).format("DD.MMM"),
                    onwallet: item.onwallet,
                    deho: item.deho,
                  }))}
                  keys={["onwallet", "deho gold"]}
                  colors={["#0080FF", "#FF9500"]}
                  formatValues={(value, key) => {
                    return key === "onwallet" ? `$${value}` : `${value} DHO`;
                  }}
                  withTooltip={true}
                  withDots={false}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <div className="CryptoBalanceChart cards">
        <div
          className="BalanceCard margin"
          onClick={() => {
            openRightModal("modalMenuOnwalletAccount");
          }}
        >
          {isOnwalletLoading && <Loader local />}
          {!isOnwalletLoading && onWalletAccount && (
            <div className="BalanceCardContainer">
              <img className="icon" src={onwalletIcon} alt="icon" />
              <h1 className="name">
                <FormattedMessage {...messages.onwalletAccount} />
              </h1>
              <p className="balance">
                {onWalletAccount.balance ? decimal2digits(onWalletAccount.balance) : 0}{" "}
                USD
              </p>
              <p
                className="number"
                onClick={event => {
                  event.stopPropagation();
                  openQRCodeModal(
                    onWalletAccount.account,
                    <FormattedMessage {...messages.onwalletAccount} />
                  );
                }}
              >
                {onWalletAccount.account}
              </p>
              <span className="dots" />
            </div>
          )}
        </div>
        <div
          className="BalanceCard"
          onClick={() => {
            openRightModal("modalMenuDehoWallet");
          }}
        >
          {isDehoLoading && <Loader local />}
          {!isDehoLoading && dehoWallet && (
            <div className="BalanceCardContainer">
              <img className="icon icon-hint" src={infoIcon} alt="icon" />
              <img className="icon" src={dehoIcon} alt="icon" />
              <h1 className="name">
                <FormattedMessage {...messages.dehoWalletAccount} />
              </h1>
              <p className="balance">
                {dehoWallet.balance} {dehoWallet.currency}
              </p>
              <p
                className="number"
                onClick={event => {
                  event.stopPropagation();
                  openQRCodeModal(
                    dehoWallet.address,
                    <FormattedMessage {...messages.dehoWalletAccount} />
                  );
                }}
              >
                {getShortAddress(dehoWallet.address)}
              </p>
              <span className="dots" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  onWalletAccount: getOnwalletAccount(state),
  isOnwalletLoading: isCardsLoading(state),
  dehoWallet: getDehoWallet(state),
  isDehoLoading: isWalletsLoading(state),
  balanceData: getBalanceData(state),
  isBalanceLoading: isBalanceLoading(state),
});

const mapDispatchToProps = {
  fetchDehoBalanceHistory,
  fetchOnWalletBalanceHistory,
  setActiveCryptoWallet,
  openRightModal,
  openQRCodeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletsContainer);
