import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Formik, Field, Form, FieldArray} from "formik";
import {FormattedMessage} from "react-intl";
import moment from "moment";
// Components
import FormSectionSeparator from "shared/components/FormSectionSeparator";
import CustomDatePicker from "shared/components/DatePicker";
import Button from "shared/components/Button";
import Input from "shared/components/Input";
// Selectors
import {dataRightModal} from "shared/components/RightModal/selectors";
// Utils
import messages from "../../../messages";
import config from "shared/config";
// Styles
import "./index.scss";
import "../OrderDescriptionModal/index.scss";
// Images
const printIcon = config.prefixImgUrl + "print_icon_blue.svg";

const OrderConfirmModal = ({order, close}) => {
  const state = {
    src: null,
    status: {
      1: "In Transit",
      2: "Completed",
      3: "Canceled",
    },
    metal: {
      1: {img: "metal-au.svg", title: "Gold 999.9"},
      2: {img: "metal-ag.svg", title: "Silver"},
      3: {img: "metal-pt.svg", title: "Platinum"},
    },
  };

  // Transfer to selectors
  const outMetal = idx => {
    let _m = state.metal[idx];

    return (
      <span className="ordered-metal">
        <img src={config.prefixImgUrl + _m.img} alt={_m.title} />
        {_m.title}
      </span>
    );
  };

  const initStateFormik = {
    date: "",
    bullion: [{weight: 5, id: "012342325GB", refinerName: "The Royal Mint"}],
  };

  // const OrderConfirmSchema = {
  // TODO: Validation
  // };

  return (
    <Formik
      initialValues={initStateFormik}
      // validateOnChange={true}
      // validationSchema={OrderConfirmSchema}
      onSubmit={values => console.log(values)}
    >
      {({values, setFieldValue, isSubmitting}) => (
        <Form>
          <FormSectionSeparator sectionName={messages.orderDetails} />

          {/* Description order */}
          <div className="order-details">
            <div className="order-details__item">
              <div className="order-details__item-text">Date & Time: </div>
              <div className="order-details__item-value">{order.dateTime}</div>
            </div>
            <div className="order-details__item">
              <div className="order-details__item-text">Ordered Metal: </div>
              <div className="order-details__item-value">
                {outMetal(order.orderedMetal)}
              </div>
            </div>
            <div className="order-details__item">
              <div className="order-details__item-text">Weight: </div>
              <div className="order-details__item-value">{`${order.weight} Kg`}</div>
            </div>
            <div className="order-details__item">
              <div className="order-details__item-text">Price per Kg: </div>
              <div className="order-details__item-value">
                {`${order.price.toFixed(2)} USD`}
              </div>
            </div>
            <div className="order-details__item">
              <div className="order-details__item-text">Total Amount: </div>
              <div className="order-details__item-value">
                {`${order.orderAmount.toFixed(2)} USD`}
              </div>
            </div>
            <div className="order-details__item">
              <div className="order-details__item-text">Status: </div>
              <div className="order-details__item-value">
                {state.status[order.status]}
              </div>
            </div>

            <div className="order-details__date-picker">
              <Field name="date">
                {({field}) => {
                  return (
                    <div>
                      <label className="label">
                        <FormattedMessage {...messages.deliveryDate} />
                      </label>
                      <CustomDatePicker
                        value={field.value ? moment(field.value) : moment()}
                        onChange={date => setFieldValue("date", date)}
                        onBlur={() => {}}
                      />
                    </div>
                  );
                }}
              </Field>
            </div>
          </div>

          {/* Second Section */}
          <FormSectionSeparator sectionName={messages.bullionDetails} />

          <FieldArray name="bullion">
            {fieldArrayProps => {
              const {push, remove} = fieldArrayProps;
              const {bullion} = values;

              return (
                <div className="order-confirm__bullion-details">
                  {!!bullion.length &&
                    bullion.map((item, index) => (
                      <div key={index} className="order-confirm__bullion-details__item">
                        {/* Title */}
                        <div className="order-confirm__bullion-details__title">
                          <div className="order-confirm__bullion-details__title__item">
                            {`Bullion # ${index + 1}`}
                          </div>
                          {!!index && (
                            <div
                              className="order-confirm__bullion-details__title__item"
                              onClick={() => remove(index)}
                            >
                              {`Remove Bullion # ${index + 1}`}
                            </div>
                          )}
                        </div>
                        {/* Forms */}
                        <div className="order-confirm__bullion-details__forms">
                          {/* First Block with Inputs */}
                          <div className="order-confirm__bullion-details__forms__item">
                            <Field name={`bullion[${index}].weight`}>
                              {field => (
                                <div className="">
                                  <div className="label">
                                    <FormattedMessage {...messages.bullionWeight} />
                                  </div>
                                  <Input
                                    type="text"
                                    key={index}
                                    value={item.weight}
                                    onChange={e =>
                                      setFieldValue(
                                        `bullion[${index}].weight`,
                                        e.target.value
                                      )
                                    }
                                    // error={
                                    //   bankAccounts[index] && errors && errors.bankAccounts
                                    // }
                                    {...field}
                                  />
                                </div>
                              )}
                            </Field>

                            {/* Second Input Bullion ID */}
                            <Field name={`bullion[${index}].id`}>
                              {field => (
                                <div>
                                  <div className="label">
                                    <FormattedMessage {...messages.bullionId} />
                                  </div>
                                  <Input
                                    type="text"
                                    key={index}
                                    value={item.id}
                                    onChange={e =>
                                      setFieldValue(
                                        `bullion[${index}].id`,
                                        e.target.value
                                      )
                                    }
                                    // error={
                                    //   bankAccounts[index] && errors && errors.bankAccounts
                                    // }
                                    {...field}
                                  />
                                </div>
                              )}
                            </Field>
                          </div>
                          {/* Input Refiner Name */}
                          <Field name={`bullion[${index}].refinerName`}>
                            {field => (
                              <div className="mt-4">
                                <div className="label">
                                  <FormattedMessage {...messages.bullionId} />
                                </div>
                                <Input
                                  type="text"
                                  key={index}
                                  value={item.refinerName}
                                  onChange={e =>
                                    setFieldValue(
                                      `bullion[${index}].refinerName`,
                                      e.target.value
                                    )
                                  }
                                  // error={
                                  //   bankAccounts[index] && errors && errors.bankAccounts
                                  // }
                                  {...field}
                                />
                              </div>
                            )}
                          </Field>
                        </div>
                      </div>
                    ))}
                  <div
                    className="order-confirm__bullion-details__forms__add blue-text"
                    onClick={() => push({weight: "", id: "", refinerName: ""})}
                  >
                    Add one more Bullion
                  </div>
                </div>
              );
            }}
          </FieldArray>

          {/* Linked Coins Section */}
          <FormSectionSeparator sectionName={messages.linkedCoins} />

          <div className="order-confirm__linked">
            <div className="order-confirm__linked__text">
              356 DHG coins are linked to the gold in this order
            </div>
            <div className="order-confirm__linked__print">
              <img src={printIcon} alt="print icon" />
              <div className="blue-text">Print List of coins</div>
            </div>
          </div>

          {/* Actions Section */}
          <div className="order-confirm__actions">
            <Button
              text={messages.cancel}
              isSecondary="true"
              onClick={close}
              type="button"
            />
            <Button
              text={messages.confirmDelivery}
              disabled={isSubmitting}
              type="submit"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

// Types
OrderConfirmModal.propTypes = {
  order: PropTypes.object.isRequired,
};

// Redux
const mapStateToProps = state => ({
  order: dataRightModal(state),
});

export default connect(mapStateToProps, null)(OrderConfirmModal);
