import React from "react";
import {FormattedMessage} from "react-intl";
import "./index.scss";

const Button = ({
  text,
  type = "submit",
  error,
  isSecondary = false,
  isLoading = false,
  className = "",
  disabled,
  srcIcon,
  renderIcon,
  ...props
}) => {
  return (
    <>
      <button
        type={type}
        className={
          (className.indexOf("btn-ui") > -1
            ? ""
            : `CustomSubmitButton ${isSecondary ? "white" : ""}`) +
          `${disabled ? "disabled" : ""}  
        ${className.length > -1 ? className : ""}`
        }
        {...props}
      >
        {isLoading && (
          <div className="buttonSpinnerContainer">
            <div className="buttonSpinner">
              <div></div>
            </div>
          </div>
        )}
        {className.indexOf("btn-with-icon") > -1 && srcIcon && (
          <img src={srcIcon} className="icon" alt="icon" />
        )}
        {className.indexOf("btn-with-icon") > -1 && renderIcon && renderIcon("icon")}
        <FormattedMessage {...text} />
      </button>
    </>
  );
};

export default Button;
