/**
 * @author: Seleznyov
 */

import React, {Component} from "react";

import {connect} from "react-redux";
import {getDataContacts} from "shared/contacts/actions";
import {contactList} from "shared/contacts/selectors";

import {Scrollbars} from "react-custom-scrollbars";
import {ReactComponent as SearchIcon} from "images/icon-search.svg";
import messages from "./messages";
import {callForFilter, filterVars, getFilterValue} from "./helpers";
import config from "shared/config";

const defaultIcon = config.prefixImgUrl + "userdefault-icon.svg";

class ModalContactList extends Component {
  constructor(props) {
    super(props);

    this.handleSetFilterValue = this.handleSetFilterValue.bind(this);
    this.returnValue = this.returnValue.bind(this);
    this.filterFromProps = this.filterFromProps.bind(this);
    this.input = React.createRef();

    this.state = {
      filterValue: null,
      contactList: [],
    };
  }

  static defaultProps = {
    isShow: false,
    customer: {},
    valfilter: null,
    isShowSearch: true,
  };

  /**
   *
   */
  componentDidMount() {
    this.props.getDataContacts();
    this.buildContactList();
    // this.setState({type: this.props.type});
  }

  /**
   *
   * @param prevProps
   * @param prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (
      filterVars(prevProps) !== this.state.filterValue &&
      this.state.filterValue === prevState.filterValue
    ) {
      this.setState({filterValue: filterVars(prevProps)});
    }

    if (
      this.props.contactList.length !== prevProps.contactList.length ||
      JSON.stringify(this.props.valfilter) !== JSON.stringify(prevProps.valfilter)
    )
      this.buildContactList();
  }

  /**
   *
   * @param data
   */
  buildContactList() {
    let _list = [];
    let _filter = this.props.valfilter ? getFilterValue(this.props.valfilter) : null;

    this.props.contactList.forEach((item, idx) => {
      let _val = item.info[this.props.type];
      let _typeof = typeof _val;

      if (_typeof === "string") {
        _list.push({
          index: idx,
          id: item.id,
          firstName: item.info.firstName,
          lastName: item.info.lastName,
          picture: item.info.picture ? item.info.picture : defaultIcon,
          value: _val,
        });
      } else {
        _val.forEach((iVal, vIdx) => {
          if (
            _filter &&
            _filter.val &&
            iVal[_filter.name].toLowerCase() !== _filter.val.toLowerCase()
          ) {
            return;
          }

          _list.push({
            index: idx + "_" + vIdx,
            id: item.id,
            firstName: item.info.firstName,
            lastName: item.info.lastName,
            picture: item.info.picture ? item.info.picture : defaultIcon,
            value: typeof iVal === "string" ? iVal : iVal[Object.keys(iVal)[0]],
          });
        });
      }
    });
    this.setState({contactList: _list});
  }

  /**
   *
   * @param val
   */
  filterFromProps(val) {
    this.setState({
      filterValue: val,
    });
  }

  /**
   *
   * @param e
   */
  handleSetFilterValue(e) {
    let value = e.target.value;

    this.setState(prevState => {
      return {...prevState, filterValue: value};
    });
  }

  /**
   *
   * @param id
   */
  returnValue(idx) {
    let _item = this.state.contactList
      .filter(item => {
        return item.index === idx;
      })
      .shift();

    let _itemInf = this.props.contactList
      .filter(item => {
        return item.id === _item.id;
      })
      .shift();

    _item["info"] = _itemInf.info;

    this.setState({
      filterValue: null,
    });

    this.props.handleSetSelect(_item);
    this.props.toggleShowContacts();
  }

  /**
   *
   * @returns {*}
   */
  render() {
    return (
      <>
        {this.props.isShowSearch && (
          <div className="list-contacts__input-search">
            <SearchIcon className="icon-search" />
            <input
              type="text"
              ref={this.input}
              value={this.state.filterValue || ""}
              onChange={this.handleSetFilterValue}
              placeholder={messages.search.defaultMessage}
            />
          </div>
        )}

        <Scrollbars
          className="list-contacts__wrapper-items-list"
          renderTrackVertical={props => <div {...props} className="track-vertical " />}
          renderThumbVertical={props => <div {...props} className="thumb-vertical " />}
        >
          <div className="list-contacts__items-list">
            {this.state.contactList
              .filter(item => {
                if (this.state.filterValue) {
                  return callForFilter(item, this.state.filterValue);
                } else return true;
              })
              .map((item, key, array) => {
                return (
                  <div
                    className="items-list__item-wrapper"
                    key={item.index}
                    onClick={() => {
                      this.returnValue(item.index);
                    }}
                  >
                    <div className="item-wrapper__img">
                      <div className="img-wrapper">
                        <img
                          src={item.picture}
                          alt={`${item.firstName} ${item.lastName}`}
                        />
                      </div>
                    </div>

                    <div className="item-wrapper__info">
                      <div className="info-name">
                        {item.firstName} {item.lastName}
                      </div>
                      <div className="info-value">{item.value}</div>
                    </div>
                  </div>
                );
              })}
          </div>
        </Scrollbars>
      </>
    );
  }
}

const mapStateToProps = store => ({
  contactList: contactList(store),
});

function mapDispatchToProps(dispatch) {
  return {
    getDataContacts: () => {
      dispatch(getDataContacts());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContactList);
