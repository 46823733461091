/**
 * @author: Seleznyov
 */

import React from "react";
import {connect} from "react-redux";

import Header from "shared/components/Header";

import messages from "./messages";
import {FormattedMessage} from "react-intl";
import Button from "shared/components/Button";
import {getCartItemsCount} from "shared/cartService/selectors";

import "./index.scss";
import {routes} from "shared/appUrls";

import config from "shared/config";
import ActionHistoryFilters from "./components/Filters";
import ActionHistoryTransactions from "./components/Transactions";

import {ReactComponent as AHPiconSaveFile} from "images/icon-save-file.svg";
import {ReactComponent as AHPiconSendEmail} from "images/icon-send-email.svg";
import {ReactComponent as AHPiconAutoReport} from "images/icon-auto-report.svg";
import {ReactComponent as CartIcon} from "images/cart-icon.svg";

const ActionHistoryIcon = config.prefixImgUrl + "icon-action-history-header.svg";

const ActionsHistory = ({cartItemsCount}) => {
  const navLinks = [
    {
      text: <FormattedMessage {...messages.headerCart} />,
      icon: className => <CartIcon className={className} />,
      leftBorder: true,
      link: routes.cart,
      notifications: cartItemsCount,
    },
  ];
  return (
    <>
      <Header
        headerTitle={<FormattedMessage {...messages.headerTransactionHistory} />}
        navLinks={navLinks}
        logoImage={ActionHistoryIcon}
      >
        <div className="messages__header__actionBlock">
          <Button
            type="button"
            text={messages.saveToFile}
            className="btn-ui btn-bold-black btn-with-icon"
            renderIcon={className => <AHPiconSaveFile className={className} />}
          />
          <Button
            type="button"
            text={messages.sendByEMail}
            className="btn-ui btn-bold-black btn-with-icon"
            renderIcon={className => <AHPiconSendEmail className={className} />}
          />
          <Button
            type="button"
            text={messages.autoReport}
            className="btn-ui btn-bold-black btn-with-icon"
            renderIcon={className => <AHPiconAutoReport className={className} />}
          />
        </div>
      </Header>

      <div className="action-history-page">
        <div className="action-history-page__filters">
          <ActionHistoryFilters />
        </div>
        <div className="action-history-page__transactions">
          <ActionHistoryTransactions />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = store => ({
  cartItemsCount: getCartItemsCount(store),
});
const dispatchToProps = {};

export default connect(mapStateToProps, dispatchToProps)(ActionsHistory);
