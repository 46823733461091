import React from "react";
import "./index.scss";
import config from "shared/config";

const ContactElement = ({
  id,
  onClick,
  status,
  imgUrl,
  UserFName,
  UserLName,
  PhoneNumber,
  date,
  heading,
  messageBody,
  selected,
}) => {
  return (
    <div
      id={id}
      className={`contact__block ${selected ? "selected" : ""}`}
      onClick={onClick}
    >
      <div className="contact__img">
        <img
          src={`${imgUrl ? imgUrl : config.prefixImgUrl + "userdefault-icon.svg"}`}
          alt="contact__img"
        />
      </div>
      <div className="contact__data">
        <div className="contact__data_wrap">
          <div className="contact__name">
            {UserFName} {UserLName}
          </div>
          <div className="contact__phoneNumber">{PhoneNumber}</div>
        </div>
        <div className="contact__id">#{id}</div>
      </div>
    </div>
  );
};
export default ContactElement;
