import React, {useState} from "react";
import {connect} from "react-redux";
import {
  LineChart,
  Line,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  // ReferenceLine,
} from "recharts";
// Selectors
import {getLoading} from "shared/ratesService/selectors";
// Components
import Loader from "shared/components/Loader";
import ChartFilter from "../CustomersChartFilter";
// Styles
import "./index.scss";

// TODO: Delete after integration with back-end
import daily from "components/Dashboard/Components/Chart/mockData/1day.js";
import weekly from "components/Dashboard/Components/Chart/mockData/1week.js";
import month from "components/Dashboard/Components/Chart/mockData/1month.js";
import perMinute from "components/Dashboard/Components/Chart/mockData/1minute.js";
import perHour from "components/Dashboard/Components/Chart/mockData/1hour.js";

const CustomTooltip = ({active, payload, children}) => {
  if (active && !!payload) {
    return (
      <div className="custom-chart-tooltip">
        <span className="custom-chart-tooltip__value">{`Quantity ${payload[0].value}`}</span>
        {children}
      </div>
    );
  }

  return null;
};

const Chart = ({chartData}) => {
  return (
    <ResponsiveContainer width="99%">
      <LineChart data={chartData} margin={{top: 17, right: 0, bottom: 0, left: -20}}>
        <XAxis dataKey="name" tick={{fontSize: 11}} />
        <YAxis axisLine={false} tickLine={false} tick={{fontSize: 11}} />
        <CartesianGrid vertical={false} />
        <Tooltip content={<CustomTooltip />} />
        {/* <ReferenceLine y={selectedValue} stroke="red" strokeDasharray="3 3" /> */}
        {/* 'basis' | 'basisClosed' | 'basisOpen' | 'linear' | 'linearClosed' | 'natural' | 'monotoneX' | 'monotoneY' | 'monotone' | 'step' | 'stepBefore' | 'stepAfter'  */}
        <Line
          isAnimationActive={false}
          type="monotone"
          dataKey="value"
          stroke="#0080FF"
          strokeWidth="2"
          activeDot={{stroke: "#0080FF", strokeWidth: 2, r: 5, fill: "#FFF"}}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const ChartComposition = ({isLoading}) => {
  const [category, setCategory] = useState("Total Customers");
  const [chartData, setChartData] = useState(weekly);

  return (
    <div className="chart-wrapper">
      <div className="nav-bar-chart">
        {/* Section Header Chart */}
        <ChartFilter
          onChangeFilter={filter => {
            setCategory(filter.category);

            switch (filter.interval) {
              case "1MIN":
                setChartData(perMinute);
                break;
              case "1HOUR":
                setChartData(perHour);
                break;
              case "1DAY":
                setChartData(daily);
                break;
              case "1WEEK":
                setChartData(weekly);
                break;
              case "1MONTH":
                setChartData(month);
                break;
              default:
            }
          }}
        />

        <div className="full-screen"></div>
      </div>

      {/* Section Body Chart */}
      <div className="chart">
        {!isLoading ? <Chart chartData={chartData} category={category} /> : <Loader />}
      </div>
    </div>
  );
};

const mapStateToProps = store => ({
  isLoading: getLoading(store),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ChartComposition);
