export const callForFilter = (item, filterVal) => {
  let filterValue = filterVal.toLowerCase().split(" ");
  let nameF = filterValue[0] || "";
  let nameL = filterValue[1] || "";
  let itemNameFirst = item.firstName;
  let itemNameLast = item.lastName;

  return (
    itemNameFirst.toLowerCase().indexOf(nameF) > -1 ||
    itemNameLast.toLowerCase().indexOf(nameF) > -1 ||
    (itemNameLast.toLowerCase().indexOf(nameF) > -1 &&
      nameL &&
      itemNameLast.toLowerCase().indexOf(nameL) > -1)
  );
};

/**
 *
 * @param data
 * @returns {string}
 */
export const filterVars = data => {
  let firstName = data.customer.firstName || "";
  let lastName = data.customer.lastName ? " " + data.customer.lastName : "";
  return firstName + lastName;
};

export const getFilterValue = data => {
  let fv = Object.entries(data)[0];
  return {
    name: fv[0],
    val: fv[1],
  };
};
