/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import messages from "../../messages";
// Actions
import {getOnfidoCountriesWithDoc} from "shared/auth/actions";
import {getCountriesWithDocument} from "shared/auth/selectors";
// Components
import Button from "shared/components/Button";
import {CompanyFileInput} from "../SecondRegisterStep";

const ThirdRegisterStep = ({
  step,
  setFieldValue,
  values,
  setStep,
  validateForm,
  setErrors,
  getOnfidoCountriesWithDoc,
  countriesWithDocument,
}) => {
  useEffect(() => {
    getOnfidoCountriesWithDoc();
  }, []);

  const isDisabled = () => {
    return (
      !values.validFiles ||
      values.validFiles.length === 0 ||
      values.validFiles.length !== countriesWithDocument[`${values.country}`].length
    );
  };
  const isNotValid = errors => {
    return (
      !values.validFiles ||
      values.validFiles.length === 0 ||
      errors.validFiles ||
      values.validFiles.length !== countriesWithDocument[`${values.country}`].length
    );
  };

  return (
    <div className="thirdStep">
      {step > 3 && (
        <div className="readyStep">
          <div className="stepTitle">
            <div className="title">
              <FormattedMessage {...messages.personValidation} />
            </div>
            <div className="separator" />
            <button className="titleEdit" onClick={() => setStep(3)}>
              <FormattedMessage {...messages.edit} />
            </button>
          </div>
          <div className="stepInfo">
            {values.validFiles &&
              !!values.validFiles.length &&
              values.validFiles.map(file => <div>{file.name}</div>)}
          </div>
        </div>
      )}
      {step === 3 && (
        <>
          <div className="thirdStepTitle">
            <FormattedMessage {...messages.personValidation} />
          </div>
          <div className="fileInfo">
            <div className="line">
              <FormattedMessage {...messages.toConfirmYourIdentity} />
            </div>
          </div>

          {/* Upload validation files user */}
          {!!countriesWithDocument &&
            countriesWithDocument[`${values.country}`].map((document, index) => (
              <CompanyFileInput
                file={values.validFiles[index] ? values.validFiles[index].name : ""}
                clear={() => setFieldValue(`validFiles[${index}]`, "")}
                onChange={e => setFieldValue(`validFiles[${index}]`, e.target.files[0])}
              >
                {document.documentType}
              </CompanyFileInput>
            ))}

          <div className="formButtons">
            <Button
              type="button"
              isSecondary={true}
              className="nextButton"
              onClick={() => setStep(4)}
              text={messages.skipThisTime}
            />
            <Button
              disabled={isDisabled()}
              type="button"
              className="nextButton"
              onClick={() => {
                validateForm().then(errors => {
                  if (!isNotValid(errors)) {
                    setErrors({});
                    setStep(4);
                  }
                });
              }}
              text={messages.next}
            />
          </div>
          <div className="promptMessage">
            <FormattedMessage {...messages.youWillBePrompt} />
          </div>
          <div className="copyright">{messages.copyRight.defaultMessage}</div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  countriesWithDocument: getCountriesWithDocument(state),
});

const mapDispatchToProps = {
  getOnfidoCountriesWithDoc,
};

export default connect(mapStateToProps, mapDispatchToProps)(ThirdRegisterStep);
