export const DATA = {
  customersManagement: [
    {
      title: "View Customers",
      description: "View Customers",
      checkBoxStatus: true,
      isShowSwitch: false,
      switchStatus: false,
      main: true,
      type: "customer",
    },
    {
      title: "Register New Customer",
      description: "View Customers",
      checkBoxStatus: false,
      isShowSwitch: true,
      switchStatus: false,
      main: false,
      type: "customer",
    },
    {
      title: "Edit Customer's Personal Data",
      description: "View Customers",
      checkBoxStatus: true,
      isShowSwitch: true,
      switchStatus: true,
      main: false,
      type: "customer",
    },
    {
      title: "Reset Customer's Password",
      description: "View Customers",
      checkBoxStatus: false,
      isShowSwitch: true,
      switchStatus: true,
      main: false,
      type: "customer",
    },
    {
      title: "Edit Customer's Status",
      description: "View Customers",
      checkBoxStatus: false,
      isShowSwitch: true,
      switchStatus: true,
      main: false,
      type: "customer",
    },
    {
      title: "View Customer's Wallets & Cards",
      description: "View Customers",
      checkBoxStatus: true,
      isShowSwitch: false,
      switchStatus: false,
      main: false,
      type: "customer",
    },
    {
      title: "Edit Financial Account Status",
      description: "View Customers",
      checkBoxStatus: false,
      isShowSwitch: true,
      switchStatus: true,
      main: false,
      type: "customer",
    },
    {
      title: "Execute Financial Transactions",
      description: "View Customers",
      checkBoxStatus: false,
      isShowSwitch: true,
      switchStatus: true,
      main: false,
      type: "customer",
    },
    {
      title: "View Transaction History",
      description: "View Customers",
      checkBoxStatus: false,
      isShowSwitch: false,
      switchStatus: false,
      main: false,
      type: "customer",
    },
  ],
  goldAndCoin: [
    {
      title: "View Metal Supply",
      description: "View Customers",
      checkBoxStatus: false,
      isShowSwitch: false,
      switchStatus: false,
      main: false,
      type: "gold",
    },
    {
      title: "View Coin Registry",
      description: "View Customers",
      checkBoxStatus: false,
      isShowSwitch: false,
      switchStatus: false,
      main: false,
      type: "coin",
    },
  ],
  staff: [
    {
      title: "View Staff Members",
      description: "View Customers",
      checkBoxStatus: false,
      isShowSwitch: false,
      switchStatus: false,
      main: true,
      type: "staff",
    },
    {
      title: "Edit Staff Members",
      description: "View Customers",
      checkBoxStatus: false,
      isShowSwitch: false,
      switchStatus: false,
      main: false,
      type: "staff",
    },
  ],
  roles: [
    {
      title: "View Roles",
      description: "View Customers",
      checkBoxStatus: false,
      isShowSwitch: false,
      switchStatus: false,
      main: true,
      type: "roles",
    },
    {
      title: "Edit Roles",
      description: "View Customers",
      checkBoxStatus: false,
      isShowSwitch: false,
      switchStatus: false,
      main: false,
      type: "roles",
    },
  ],
  supervision: [
    {
      title: "Supervise Customer Registration",
      description: "View Customers",
      checkBoxStatus: false,
      isShowSwitch: false,
      switchStatus: false,
      main: false,
      type: "supervision",
    },
    {
      title: "Supervise Customer's Settings and Data",
      description: "View Customers",
      checkBoxStatus: false,
      isShowSwitch: false,
      switchStatus: false,
      main: false,
      type: "supervision",
    },
    {
      title: "Supervise Customer's Financials",
      description: "View Customers",
      checkBoxStatus: false,
      isShowSwitch: false,
      switchStatus: false,
      main: false,
      type: "supervision",
    },
  ],
};
