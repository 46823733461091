import React from "react";
import "../../index.scss";
import {Formik, Field, Form} from "formik";
import * as Yup from "yup";
import {FormattedMessage} from "react-intl";
import messages from "../../messages";
import Input from "shared/components/Input";
import Button from "shared/components/Button";
import LinkButton from "shared/components/Button/LinkButton";
import {routes} from "shared/appUrls";

const initialValues = {
  email: "",
};

const RecoveryWithEmailSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be valid")
    .required("Email is required"),
});

const WithEmailForm = ({click}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={RecoveryWithEmailSchema}
      onSubmit={values => {
        click();
      }}
    >
      {({errors, touched, values}) => (
        <Form className="recoveryForm">
          <p className="text">
            <FormattedMessage {...messages.enterYourEmail} />
          </p>
          <Field
            name="email"
            render={({field}) => (
              <div className="inputContainer">
                <label className="label">
                  <FormattedMessage {...messages.email} />
                </label>
                <Input type="text" error={touched.email && errors.email} {...field} />
              </div>
            )}
          />
          <div className="buttonsContainer">
            <LinkButton text={messages.cancel} isSecondary="true" link={routes.login} />
            <Button
              disabled={!values.email || errors.email}
              type="submit"
              text={messages.recover}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default WithEmailForm;
