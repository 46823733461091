import {defineMessages} from "react-intl";

const scope = "Shopping";

const messages = defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: "Shopping",
  },
  manage: {
    id: `${scope}.manage`,
    defaultMessage: "manage",
  },
  cardsoption: {
    id: `${scope}.cardsoption`,
    defaultMessage: "cards",
  },
  vouchersoption: {
    id: `${scope}.vouchersoption`,
    defaultMessage: "vouchers",
  },
  promooption: {
    id: `${scope}.promooption`,
    defaultMessage: "promo catalogues",
  },
});

export default messages;
