import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    color: "#0080FF",
    boxShadow: "unset",
    borderBottom: "1px solid #0080ff26",
    borderRadius: "unset",
  },
});

const CustomTabs = ({value, handleChange, tabs}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        {!!tabs.length && tabs.map(tap => <Tab label={tap} key={tap} />)}
      </Tabs>
    </Paper>
  );
};

export default CustomTabs;
