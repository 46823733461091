import React, {Component} from "react";
import {connect} from "react-redux";
import {closeRightModal} from "shared/components/RightModal/actions";
import {createVoucher} from "shared/shoppingService/actions";
import {getVoucherList} from "shared/shoppingService/actions";

import FormSectionSeparator from "shared/components/FormSectionSeparator";
import Input from "shared/components/Input";
import Button from "shared/components/Button";
import Select from "shared/components/Select";
import Textarea from "shared/components/Input/Textarea";
import DatePicker from "shared/components/DatePicker";
import {MenuItem} from "@material-ui/core";
import moment from "moment";

import {Formik, Form, Field} from "formik";

import {FormattedMessage} from "react-intl";
import messages from "../../messages";
import "../index.scss";
import {fieldsAddCard} from "shared/shoppingService/selectors";
import {storeUserInfo} from "shared/auth/selectors";

class ShoppingVoucher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      validity_period: [
        {
          key: "date",
          title: <FormattedMessage {...messages.fieldValidityPeriodSpecifiedDate} />,
        },
        {
          key: "years",
          title: <FormattedMessage {...messages.fieldValidityPeriodYears} />,
        },
        {
          key: "months",
          title: <FormattedMessage {...messages.fieldValidityPeriodMonths} />,
        },
        {key: "days", title: <FormattedMessage {...messages.fieldValidityPeriodDays} />},
        {
          key: "unlimit",
          title: <FormattedMessage {...messages.fieldValidityPeriodUnlimited} />,
        },
      ],
    };
  }

  PeriodItems() {
    return this.state.validity_period.map((item, idx) => {
      return (
        <MenuItem value={item.key} key={idx}>
          {item.title}
        </MenuItem>
      );
    });
  }

  handlerCreate = data => {
    const {cardName, description, numberOff, validPeriod} = data;
    let countDate = null;

    // Define duration voucher
    switch (validPeriod) {
      case "days":
        countDate = numberOff;
        break;
      case "months":
        countDate = numberOff * 30;
        break;
      case "years":
        countDate = numberOff * 365;
        break;
      case "date":
        console.log("expiryDate: ", data.expiryDate);
        break;
      default:
        break;
    }

    const payload = {
      type: 2, // define type voucher
      merchantId: this.props.userInfo.sub,
      quantity: 1,
      title: cardName,
      duration: +countDate,
      description,
    };

    this.props.createVoucher(payload);
    this.props.getVoucherList();
    this.props.closeRightModal();
  };

  render() {
    return (
      <Formik initialValues={this.props.fieldsAddCard} onSubmit={this.handlerCreate}>
        {({values, errors, touched, handleSubmit, setFieldValue}) => (
          <Form className="form new-shopping-card" onSubmit={handleSubmit}>
            <FormSectionSeparator sectionName={messages.ShCompDetails} />

            {/* Voucher Name */}
            <Field name="cardName">
              {({field, form, meta}) => (
                <div className="fields-wrapper">
                  <label className="label">
                    <FormattedMessage {...messages.voucherName} />
                  </label>
                  <Input {...field} />
                </div>
              )}
            </Field>

            {/* Define Valid Period */}
            <div className="inputWrapper validity-period">
              <div className="inputWrapper__col">
                <Field name="validPeriod">
                  {({field, form, meta}) => (
                    <div className="fields-wrapper column">
                      <label className="label">
                        <FormattedMessage {...messages.fieldValidityPeriod} />
                      </label>
                      <Select {...field} className="select">
                        {this.PeriodItems()}
                      </Select>
                    </div>
                  )}
                </Field>
              </div>
              <div className="inputWrapper__col">
                {values.validPeriod === "date" ? (
                  <Field name="expiryDate">
                    {({field, form, meta}) => (
                      <div className="fields-wrapper column">
                        <label className="label">
                          <FormattedMessage {...messages.ShCompExpiryDate} />
                        </label>
                        <DatePicker
                          error={touched.valueDate && errors.valueDate}
                          value={field.value ? moment(field.value) : moment()}
                          onChange={value => setFieldValue("expiryDate", value)}
                          onBlur={() => {}}
                        />
                      </div>
                    )}
                  </Field>
                ) : values.validPeriod === "unlimit" ? (
                  ""
                ) : (
                  <Field name="numberOff">
                    {({field, form, meta}) => (
                      <div className="fields-wrapper column">
                        <label className="label">
                          {values.validPeriod === "years" && (
                            <FormattedMessage {...messages.ShCompNumberOfYears} />
                          )}
                          {values.validPeriod === "months" && (
                            <FormattedMessage {...messages.ShCompNumberOfMonths} />
                          )}
                          {values.validPeriod === "days" && (
                            <FormattedMessage {...messages.ShCompNumberOfDays} />
                          )}
                        </label>
                        <Input {...field} />
                      </div>
                    )}
                  </Field>
                )}
              </div>
            </div>

            {/* Description Voucher */}
            <Field name="description">
              {({field, form, meta}) => (
                <div className="fields-wrapper column">
                  <label className="label">
                    <FormattedMessage {...messages.fieldDescription} />
                  </label>
                  <Textarea
                    className="description-voucher"
                    placeholder={messages.ShCompOptionalDescription.defaultMessage}
                    type="text"
                    {...field}
                  />
                </div>
              )}
            </Field>

            <div className="inputWrapper">
              <div className="inputWrapper__col">
                <Button
                  type="button"
                  text={messages.ShCompCancel}
                  isSecondary="true"
                  onClick={() => {
                    this.props.closeRightModal();
                  }}
                />
              </div>
              <div className="inputWrapper__col">
                <Button type="submit" text={messages.createVoucher} />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = store => ({
  fieldsAddCard: fieldsAddCard(store),
  userInfo: storeUserInfo(store),
});
const dispatchToProps = {
  closeRightModal,
  getVoucherList,
  createVoucher: data => createVoucher(data),
};

export default connect(mapStateToProps, dispatchToProps)(ShoppingVoucher);
