export const FETCH_BANK_CARDS_PENDING = "FETCH_BANK_CARDS_PENDING";
export const FETCH_BANK_CARDS_SUCCESS = "FETCH_BANK_CARDS_SUCCESS";
export const FETCH_BANK_CARDS_FAILED = "FETCH_BANK_CARDS_FAILED";

export const SET_ACTIVE_BANK_CARD = "SET_ACTIVE_BANK_CARD";

export const GET_ONWALLET_PENDING = "GET_ONWALLET_PENDING";
export const GET_ONWALLET_SUCCESS = "GET_ONWALLET_SUCCESS";
export const GET_ONWALLET_FAILED = "GET_ONWALLET_FAILED";

export const GET_REGULAR_CURRENCY_PENDING = "GET_REGULAR_CURRENCY_PENDING";
export const GET_REGULAR_CURRENCY_SUCCESS = "GET_REGULAR_CURRENCY_SUCCESS";
export const GET_REGULAR_CURRENCY_FAILED = "GET_REGULAR_CURRENCY_FAILED";

export const ADD_REGULAR_CARD_PENDING = "ADD_REGULAR_CARD_PENDING";
export const ADD_REGULAR_CARD_SUCCESS = "ADD_REGULAR_CARD_SUCCESS";
export const ADD_REGULAR_CARD_FAILED = "ADD_REGULAR_CARD_FAILED";

export const RESET_RECEIVE_CARD_QR = "RESET_RECEIVE_CARD_QR";
export const RECEIVE_CARD_QR_PENDING = "RECEIVE_CARD_QR_PENDING";
export const RECEIVE_CARD_QR_SUCCESS = "RECEIVE_CARD_QR_SUCCESS";
export const RECEIVE_CARD_QR_FAILED = "RECEIVE_CARD_QR_FAILED";

export const TRANSFER_CARD_TO_CARD_PENDING = "TRANSFER_CARD_TO_CARD_PENDING";
export const TRANSFER_CARD_TO_CARD_SUCCESS = "TRANSFER_CARD_TO_CARD_SUCCESS";
export const TRANSFER_CARD_TO_CARD_FAILED = "TRANSFER_CARD_TO_CARD_FAILED";

export const FETCH_BANK_ACCOUNT_PENDING = "FETCH_BANK_ACCOUNT_PENDING";
export const FETCH_BANK_ACCOUNT_SUCCESS = "FETCH_BANK_ACCOUNT_SUCCESS";
export const FETCH_BANK_ACCOUNT_FAILED = "FETCH_BANK_ACCOUNT_FAILED";
export const SET_ACTIVE_BANK_ACCOUNT = "SET_ACTIVE_BANK_ACCOUNT";

export const SET_CARD_DEFAULT_PENDING = "SET_CARD_DEFAULT_PENDING";
export const SET_CARD_DEFAULT_SUCCESS = "SET_CARD_DEFAULT_SUCCESS";
export const SET_CARD_DEFAULT_FAILED = "SET_CARD_DEFAULT_FAILED";

export const UPDATE_CARD_DEFAULT_PENDING = "UPDATE_CARD_DEFAULT_PENDING";
export const UPDATE_CARD_DEFAULT_SUCCESS = "UPDATE_CARD_DEFAULT_SUCCESS";
export const UPDATE_CARD_DEFAULT_FAILED = "UPDATE_CARD_DEFAULT_FAILED";

export const DELETE_CARD_DEFAULT_PENDING = "DELETE_CARD_DEFAULT_PENDING";
export const DELETE_CARD_DEFAULT_SUCCESS = "DELETE_CARD_DEFAULT_SUCCESS";
export const DELETE_CARD_DEFAULT_FAILED = "DELETE_CARD_DEFAULT_FAILED";

export const TRANSFER_ONWALLET_TO_CARD_PENDING = "TRANSFER_ONWALLET_TO_CARD_PENDING";
export const TRANSFER_ONWALLET_TO_CARD_SUCCESS = "TRANSFER_ONWALLET_TO_CARD_SUCCESS";
export const TRANSFER_ONWALLET_TO_CARD_FAILED = "TRANSFER_ONWALLET_TO_CARD_FAILED";

export const TRANSFER_CARD_TO_ONWALLET_PENDING = "TRANSFER_CARD_TO_ONWALLET_PENDING";
export const TRANSFER_CARD_TO_ONWALLET_SUCCESS = "TRANSFER_CARD_TO_ONWALLET_SUCCESS";
export const TRANSFER_CARD_TO_ONWALLET_FAILED = "TRANSFER_CARD_TO_ONWALLET_FAILED";

export const TRANSFER_ONWALLET_TO_ONWALLET_PENDING =
  "TRANSFER_ONWALLET_TO_ONWALLET_PENDING";
export const TRANSFER_ONWALLET_TO_ONWALLET_SUCCESS =
  "TRANSFER_ONWALLET_TO_ONWALLET_SUCCESS";
export const TRANSFER_ONWALLET_TO_ONWALLET_FAILED =
  "TRANSFER_ONWALLET_TO_ONWALLET_FAILED";
