/**
 * @author: Seleznyov
 */

import React, {Component} from "react";
import messages from "./messages";
import {FormattedMessage} from "react-intl";

class SaveNewContacts extends Component {
  constructor(props) {
    super(props);

    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.inputFN = React.createRef();
    this.inputLN = React.createRef();
    this.inputFO = React.createRef();

    this.state = {
      firstName: "",
      lastName: "",
      otherField: {},
    };
  }

  static defaultProps = {
    isShow: false,
    fieldValue: "",
    customer: {
      firstName: "",
      lastName: "",
    },
    otherField: {},
  };

  /**
   *
   * @param id
   */
  handleChangeInput(e) {
    let _input = e.target;
    let value = _input.value;
    let name = _input.id;
    this.setState(prevState => {
      return {...prevState, [name]: value};
    });
  }

  static getDerivedStateFromProps(nextProps, prevState){
    return {
      firstName: nextProps.customer.firstName,
      lastName: nextProps.customer.lastName,
      otherField: nextProps.otherField.value,
    };
  }

  /**
   *
   */
  handleFormSubmit() {
    this.setState({
      firstName: null,
      lastName: null,
      otherField: null,
      localEvent: false,
    });
    this.props.toggleShowContactsNew();
  }

  render() {
    return (
      <>
        <h3>
          <FormattedMessage {...messages.newContact} />
        </h3>

        <div className="wrapper">
          <div className="wrapper__row">
            <div className="input-section">
              <label className="label">
                <FormattedMessage {...messages.firstName} />{" "}
              </label>
              <div className="CustomInputWrapper">
                <input
                  ref={this.inputFN}
                  type="text"
                  className="input"
                  id="firstName"
                  value={this.state.firstName}
                  onChange={this.handleChangeInput}
                />
              </div>
            </div>
          </div>
          <div className="wrapper__row separator">
            <div className="input-section">
              <label className="label">
                <FormattedMessage {...messages.lastName} />{" "}
              </label>
              <div className="CustomInputWrapper">
                <input
                  ref={this.inputLN}
                  type="text"
                  className="input"
                  id="lastName"
                  value={this.state.lastName}
                  onChange={this.handleChangeInput}
                />
              </div>
            </div>
          </div>

          <div className="wrapper__row">
            <div className="input-section">
              <label className="label">{this.props.otherField.label}</label>
              <div className="CustomInputWrapper">
                <input
                  ref={this.inputFO}
                  type="text"
                  className="input"
                  id="otherField"
                  value={this.state.otherField}
                  onChange={this.handleChangeInput}
                />
              </div>
            </div>
          </div>

          <div className="wrapper__row row-btns footer">
            <button
              type="button"
              className="CustomSubmitButton white"
              onClick={this.handleFormSubmit}
            >
              <FormattedMessage {...messages.cancel} />
            </button>
            <button
              type="button"
              className="CustomSubmitButton"
              onClick={this.handleFormSubmit}
            >
              <FormattedMessage {...messages.saveNewContact} />
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default SaveNewContacts;
