import axios from "axios";
import {CardEndpoints} from "./apiEndpoints";

export const cardAPICreateRegular = (data, option) => {
  return axios.post(CardEndpoints.createRegular, data, {
    ...option,
  });
};

export const cardAPIGetRegular = option => {
  return axios.get(CardEndpoints.getRegularCards, option);
};

export const cardAPIGetOnWallet = option => {
  return axios.get(CardEndpoints.cardOnWallet, {
    ...option,
  });
};

export const walletAPIGetAllRegularCurrency = option => {
  return axios.get(CardEndpoints.currencyRegularAll, {
    ...option,
  });
};

export const getCardRegularQRCode = (sourceId, amount, option) => {
  return axios.get(
    `${CardEndpoints.cardRegularQRCode}?sourceId=${sourceId}&amount=${amount}`,
    {
      ...option,
      responseType: "blob",
    }
  );
};

export const getOnWalletQRCode = (amount, option) => {
  return axios.get(`${CardEndpoints.cardOnWalletQRCode}?amount=${amount}`, {
    ...option,
    responseType: "blob",
  });
};

export const cardAPITransferRegularToRegular = (data, option) => {
  return axios.post(CardEndpoints.transferRegularToRegular, data, {
    ...option,
  });
};

export const cardAPISetRegularCardDefault = (id, option) => {
  return axios.put(
    CardEndpoints.cardRegularDefault(id),
    {},
    {
      ...option,
    }
  );
};

export const cardAPICardRegularUpdate = (id, data, option) => {
  return axios.put(CardEndpoints.cardRegularUpdate(id), data, {
    ...option,
  });
};

export const cardAPICardRegularDelete = (id, option) => {
  return axios.delete(CardEndpoints.cardRegularDelete(id), {
    ...option,
  });
};

export const cardAPITransferOnWalletToCard = (data, option) => {
  return axios.post(CardEndpoints.transferOnWalletToCard, data, {
    ...option,
  });
};

export const cardAPITransferCardToOnWallet = (data, option) => {
  return axios.post(CardEndpoints.transferCardToOnWallet, data, {
    ...option,
  });
};

export const cardAPITransferOnWalletToOnWallet = (data, option) => {
  return axios.post(CardEndpoints.transferOnWalletToOnWallet, data, {
    ...option,
  });
};
