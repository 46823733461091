/**
 * @author: Seleznyov
 */

import {
  SET_DATA_BUY,
  ADD_DATA_BUY,
  REMOVE_DATA_BUY,
  UPDATE_DATA_BUY,
  SET_DATA_SEL,
  ADD_DATA_SEL,
  REMOVE_DATA_SEL,
  UPDATE_DATA_SEL,
  SET_TYPEVIEW,
  SET_LIMITVIEW,
  SET_DATA_LASTORDER,
  SET_DATA_TRADEHISTORY,
  ADD_DATA_TRADEHISTORY,
  SelectMarket,
  ORDERS_HISTORY_WIDGET,
  GRAPH_LOAD_DATA,
  GRAPH_HEADER_DATA,
  TRADING_SELECT_PAIR,
  CHART_PENDING_DATA,
  CHART_SUCCESS_DATA,
  CHART_FAIL_DATA,
  CHART_HEADER_SUCCESS_DATA,
  CHART_HEADER_FAIL_DATA,
} from "./actions/types";
import initialState from "./initialState";

/**
 *
 * @param state
 * @param action
 * @returns {{dataSel: *, dataBuy: []}|{dataSel: [], dataBuy: []}|{dataSel: [], dataBuy: *}}
 */
const dataTradingWidgetsOrders = (state = initialState.orders, action) => {
  const {type, payload, limit} = action;

  let resB;
  let resS;

  switch (type) {
    // DATA_BUY --------------------------------------------------------------------------------------------------------
    case SET_DATA_BUY:
      return {
        ...state,
        dataBuy: sortSliceDataSelBuy(payload, limit),
      };
    case ADD_DATA_BUY:
      resB = [...state.dataBuy, payload];
      return {
        ...state,
        dataBuy: sortSliceDataSelBuy(resB, limit),
      };
    case REMOVE_DATA_BUY:
      resB = [...state.dataBuy.filter(data => data.tradeUnitId !== payload)];
      return {
        ...state,
        dataBuy: sortSliceDataSelBuy(resB, limit),
      };
    case UPDATE_DATA_BUY:
      resB = updateSelBuy(state.dataBuy, payload);
      return {
        ...state,
        dataBuy: sortSliceDataSelBuy(resB, limit),
      };

    // DATA_SEL --------------------------------------------------------------------------------------------------------
    case SET_DATA_SEL:
      return {
        ...state,
        dataSel: sortSliceDataSelBuy(payload, limit),
      };
    case ADD_DATA_SEL:
      resS = [...state.dataSel, payload];
      return {
        ...state,
        dataSel: sortSliceDataSelBuy(resS, limit),
      };
    case REMOVE_DATA_SEL:
      resS = [...state.dataSel.filter(data => data.tradeUnitId !== payload)];
      return {
        ...state,
        dataSel: sortSliceDataSelBuy(resS, limit),
      };
    case UPDATE_DATA_SEL:
      resS = updateSelBuy(state.dataSel, payload);
      return {
        ...state,
        dataSel: sortSliceDataSelBuy(resS, limit),
      };

    // DATA_LASTORDER --------------------------------------------------------------------------------------------------
    case SET_DATA_LASTORDER:
      return {
        ...state,
        dataLastOrder: payload,
      };

    // -----------------------------------------------------------------------------------------------------------------
    case SET_TYPEVIEW:
      return {
        ...state,
        typeView: payload,
      };
    case SET_LIMITVIEW:
      return {
        ...state,
        limit: payload,
      };
    default:
      return state;
  }
};

function updateSelBuy(data, payload) {
  return data.map((item, key) => {
    if (item.tradeUnitId === payload.id) {
      item.baseCryptoAmount = payload.baseCryptoAmount;
      item.createDate = payload.createDate;
      item.cryptoAmount = payload.cryptoAmount;
      item.cryptoCurrency = payload.cryptoCurrency;
      item.currency = payload.currency;
      item.price = payload.price;
      item.type = payload.type;
    }
    return item;
  });
}
function sortSliceDataSelBuy(arr, limit) {
  return arr
    .sort(function(a, b) {
      return b.price - a.price;
    })
    .slice(0, limit);
}
export {dataTradingWidgetsOrders};

/**
 *
 * @param state
 * @param action
 * @returns {{dataList: []}}
 */
const dataTradingWidgetsTradeHistory = (state = initialState.tradeHistory, action) => {
  const {type, payload, limit} = action;

  switch (type) {
    case SET_DATA_TRADEHISTORY:
      return {
        ...state,
        dataList: sortSliceTradeHistory(payload, limit),
      };

    case ADD_DATA_TRADEHISTORY:
      let res = [...state.dataList, payload];
      return {
        ...state,
        dataList: sortSliceTradeHistory(res, limit),
      };
    default:
      return state;
  }
};

function sortSliceTradeHistory(arr, limit) {
  return arr
    .sort(function(a, b) {
      let dateA = new Date(a.date);
      let dateB = new Date(b.date);
      return dateB - dateA;
    })
    .slice(0, limit);
}
export {dataTradingWidgetsTradeHistory};

/**
 *
 * @param state
 * @param action
 * @returns {{tradeHistory: {dataList: []}, orders: {dataSel: [], dataBuy: []}, filterVars: {limit: number, currency: string, type: number, cryptoCurrency: string}}}
 */
const dataTradingWidgets = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
export {dataTradingWidgets};

const marketInitialState = {
  market: {
    id: 0,
    code: "DHG",
  },
  pair: {id: "1", code: "USD"},
};

const marketData = (state = marketInitialState, action) => {
  switch (action.type) {
    case SelectMarket:
      return {...state, market: action.payload};
    case TRADING_SELECT_PAIR:
      return {...state, pair: action.payload};
    default:
      return state;
  }
};

export {marketData};

/**
 *
 * @param state
 * @param action
 * @returns {{dataListOrders: []}}
 */
const dataTradingWidgetsOrdersHistory = (state = initialState.ordersHistory, action) => {
  const {type, payload} = action;

  switch (type) {
    case ORDERS_HISTORY_WIDGET:
      return {
        ...state,
        dataListOrders: payload,
      };

    default:
      return state;
  }
};

export {dataTradingWidgetsOrdersHistory};
export const dataTradingGraph = (state = [], action) => {
  const {type, payload} = action;
  switch (type) {
    case GRAPH_LOAD_DATA:
      return {...state, graph_data: payload};
    case GRAPH_HEADER_DATA:
      return {...state, graph_header_data: payload};
    default:
      return state;
  }
};

/*
|--------------------------------------------------------------
| Trade Chart Binance (Graph)
|--------------------------------------------------------------
|
*/

export const tradingChartData = (state = initialState.chartData, action) => {
  const {type, payload} = action;

  switch (type) {
    case CHART_SUCCESS_DATA:
      return {
        ...state,
        coinToPair: payload,
        isLoading: false,
      };
    case CHART_FAIL_DATA:
      return {...state, coinToPair: [], isLoading: true};
    case CHART_PENDING_DATA:
      return {...state, isLoading: true};
    case CHART_HEADER_SUCCESS_DATA:
      return {...state, header: payload};
    case CHART_HEADER_FAIL_DATA:
      return {...state, header: null};
    default:
      return state;
  }
};
