import config from "shared/config";
const dehoCoinGold = config.prefixImgUrl + "deho_coin_gold.svg";
const dehoCoinSilver = config.prefixImgUrl + "deho_coin_silver.svg";
// const dehoCoinPlatinum = config.prefixImgUrl + "deho_coin_platinum.svg";
const bitCoin = config.prefixImgUrl + "bitcoin-icon.svg";
const ethereumCoin = config.prefixImgUrl + "ethereum-coin.svg";
const zcashCoin = config.prefixImgUrl + "zcash-coin.svg";
const moneroCoin = config.prefixImgUrl + "monero-coin.svg";
const liteCoin = config.prefixImgUrl + "litecoin-icon.svg";
const rippleCoin = config.prefixImgUrl + "ripple-coin.svg";
const bitcoinCash = config.prefixImgUrl + "bitcoin-cash-icon.svg";
const binance = config.prefixImgUrl + "binance-icon.svg";
const tether = config.prefixImgUrl + "tether-icon.svg";
const tezos = config.prefixImgUrl + "tezos-icon.svg";
const cardano = config.prefixImgUrl + "cardano-icon.svg";
const cryprocom = config.prefixImgUrl + "cryprocom-icon.svg";
const stellar = config.prefixImgUrl + "stellar-icon.svg";
const chainlink = config.prefixImgUrl + "chainlink-icon.svg";
const bitcoinsv = config.prefixImgUrl + "bitcoin-sv-icon.svg";
const walletOne = config.prefixImgUrl + "walletone-logo.svg";
const payments = config.prefixImgUrl + "payments-logo.svg";
const hydroMineLogo = config.prefixImgUrl + "hydro_mine_logo.jpeg";

export const getCoinNameByCurrency = currency => {
  if (!currency) return null;
  switch (currency.toUpperCase()) {
    case "DHG":
      return "Deho Gold";
    case "DHS":
      return "Deho Silver";
    case "DHP":
      return "Hydro Mine";
    case "BTC":
      return "Bitcoin";
    case "ETH":
      return "Ethereum";
    case "ZEC":
      return "Zcash";
    case "XMR":
      return "Monero";
    case "LTC":
      return "Litecoin";
    case "BCH":
      return "Bitcoin Cash";
    case "XRP":
      return "Ripple";
    default:
      return "Deho Gold";
  }
};

export const getWalletIconByName = name => {
  switch (name) {
    case "Deho Gold":
      return dehoCoinGold;
    case "Deho Silver":
      return dehoCoinSilver;
    case "Hydro Mine":
      return hydroMineLogo;
    case "Bitcoin":
      return bitCoin;
    case "Ethereum":
      return ethereumCoin;
    case "Zcash":
      return zcashCoin;
    case "Monero":
      return moneroCoin;
    case "Litecoin":
      return liteCoin;
    case "Bitcoin Cash":
      return bitcoinCash;
    case "Ripple":
      return rippleCoin;
    default:
      return null;
  }
};

export const getCryptoIconBySymbol = name => {
  switch (name) {
    case "DHG":
      return dehoCoinGold;
    case "DHS":
      return dehoCoinSilver;
    case "DHP":
      return hydroMineLogo;
    case "BTC":
      return bitCoin;
    case "ETH":
      return ethereumCoin;
    case "ZEC":
      return zcashCoin;
    case "XMR":
      return moneroCoin;
    case "LTC":
      return liteCoin;
    case "BCH":
      return bitcoinCash;
    case "XRP":
      return rippleCoin;
    case "BSV":
      return bitcoinsv;
    case "BNB":
      return binance;
    case "USDT":
      return tether;
    case "XTZ":
      return tezos;
    case "ADA":
      return cardano;
    case "CRO":
      return cryprocom;
    case "LINK":
      return chainlink;
    case "XLM":
      return stellar;
    default:
      return dehoCoinGold;
  }
};

export const getShortAddress = address => {
  if (!address) return "";
  const length = address.length;
  return `${address.substring(0, 4)}...${address.substring(length - 4)}`;
};

export const getCoinAmountFromOtherCurrency = (wallet, amount, rates, currency) => {
  if (!wallet || !amount || !rates || !currency) return 0;
  const amountUSD =
    currency.toLowerCase() === "usd"
      ? amount
      : Number.parseFloat(amount) * (1 / rates[currency.toLowerCase()]);
  return amountUSD / (1 / rates[wallet.currency.toLowerCase()]);
};

export const getCoinAmountInOtherCurrency = (wallet, amount, rates, currency) => {
  if (!wallet || !amount || !rates || !currency) return 0;
  const amountInUSD = getCoinAmountInUSD(wallet, amount, rates);
  return amountInUSD * rates[currency.toLowerCase()];
};

export const getCoinAmountInUSD = (wallet, amount, rates) => {
  if (!wallet || !amount || !rates) return 0;
  return Number.parseFloat(amount) * (1 / rates[wallet.currency.toLowerCase()]);
};

export const getPerOtherCoin = (wallet, otherWallet, rates) => {
  return getAmountInOtherCoin(wallet.currency, otherWallet.currency, 1, rates);
};

export const getAmountInOtherCoin = (
  walletCurrency,
  otherWalletCurrency,
  amount,
  rates
) => {
  if (!walletCurrency || !otherWalletCurrency || !amount || !rates) return 0;
  if (otherWalletCurrency.toLowerCase() === "usd") {
    const walletUSD = amount * (1 / 0.1);
    return walletUSD / (1 / rates[otherWalletCurrency.toLowerCase()]);
  } else {
    const walletUSD = amount * (1 / rates[walletCurrency.toLowerCase()]);
    const result = walletUSD / (1 / rates[otherWalletCurrency.toLowerCase()]);

    return result;
  }
};

export const getActiveWallet = store => {
  if (!store.cryptoWalletsData.data || !store.cryptoWalletsData.activeWalletName)
    return null;
  return store.cryptoWalletsData.data.find(
    wallet => wallet.walletName === store.cryptoWalletsData.activeWalletName
  );
};

export const getCryptoWallets = store => {
  return store.cryptoWalletsData.data ? store.cryptoWalletsData.data : [];
};

export const getCryptoWalletWithoutDeho = store => {
  return store.cryptoWalletsData.data
    ? store.cryptoWalletsData.data.filter(wallet => wallet.walletName !== "Deho Gold")
    : [];
};

export const getBitcoinWallet = store => {
  return store.cryptoWalletsData.data
    ? store.cryptoWalletsData.data.filter(wallet => wallet.walletName === "Bitcoin")[0]
    : null;
};

export const getDehoWallet = store => {
  return store.cryptoWalletsData.data
    ? store.cryptoWalletsData.data.filter(wallet => wallet.walletName === "Deho Gold")[0]
    : null;
};

export const getDehoWallets = store => {
  return store.cryptoWalletsData.data
    ? store.cryptoWalletsData.data.filter(wallet => wallet.walletName.includes("Deho"))
    : [];
};

export const isWalletsLoading = store => store.cryptoWalletsData.isLoading;

export const getAllAvailableWallets = store => {
  return store.cryptoWalletsData.availableWallets
    ? store.cryptoWalletsData.availableWallets
    : [];
};

export const isAvailableWalletsFetched = store =>
  store.cryptoWalletsData.isAvailableWalletsFetched;

export const getAllCryptoCurrency = store => {
  return store.cryptoWalletsData.allCryptoCurrency
    ? store.cryptoWalletsData.allCryptoCurrency
    : [];
};

export const isAllCurrencyFetched = store => store.cryptoWalletsData.isAllCurrencyFetched;

export const getThirdWalletLogoByName = name => {
  switch (name) {
    case "WalletOne":
      return walletOne;
    case "Payments":
      return payments;
    default:
      return walletOne;
  }
};

export const getThirdWallets = store => {
  if (!store.thirdWalletsData || !store.thirdWalletsData.thirdWallets) return [];
  return store.thirdWalletsData.thirdWallets;
};
export const isThirdWalletsLoading = store => store.thirdWalletsData.isLoading;
export const getActiveThirdWalletNumber = store =>
  store.thirdWalletsData.activeThirdWalletNumber;
