export const SET_DATA_CARD_SHOPPING = "SET_DATA_CARD_SHOPPING";
export const SET_DATA_VOUCHER_SHOPPING = "SET_DATA_VOUCHER_SHOPPING";
export const SEND_VOUCHER_ONWALLET_ACCOUNT = "SEND_VOUCHER_ONWALLET_ACCOUNT";
export const GET_VOUCHER_LIST_SHOPPING_SUCCESS = "GET_VOUCHER_LIST_SHOPPING_SUCCESS";
export const GET_VOUCHER_LIST_SHOPPING_FAIL = "GET_VOUCHER_LIST_SHOPPING_FAIL";
export const TRANSFER_VOUCHER_SUCCESS = "TRANSFER_VOUCHER_SUCCESS";
export const TRANSFER_VOUCHER_FAIL = "TRANSFER_VOUCHER_FAIL";
export const GET_VOUCHER_QR_SUCCESS = "GET_VOUCHER_QR_SUCCESS";
export const GET_VOUCHER_QR_FAIL = "GET_VOUCHER_QR_FAIL";
export const DELETE_VOUCHER_USER_SUCCESS = "DELETE_VOUCHER_USER_SUCCESS";
export const DELETE_VOUCHER_USER_FAIL = "DELETE_VOUCHER_USER_FAIL";
export const CREATE_VOUCHER_LIST_SHOPPING_FAIL = "CREATE_VOUCHER_LIST_SHOPPING_FAIL";
export const CREATE_VOUCHER_LIST_SHOPPING_SUCCESS =
  "CREATE_VOUCHER_LIST_SHOPPING_SUCCESS";
