import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import RightModal from "shared/components/RightModal";
import {FormattedMessage} from "react-intl";
import messages from "../../messages";
import AddNewCard from "../AddNewCard";
import AddNewWallet from "../AddNewWallet";
import ExchangeModal from "../ExchangeModal";
import OwnCardFromCardForm from "../TransferModal/OwnCardFromCardForm";
import OwnCardFromWalletForm from "../TransferModal/OwnCardFromWalletForm";
import SomeoneCardFromCardForm from "../TransferModal/SomeoneCardFromCardForm";
import SomeoneCardFromWalletForm from "../TransferModal/SomeoneCardFromWalletForm";
import HistoryFilterModal from "../History/HistoryFilterModal";
import ModalRowMenu from "../TableRowMenu/ModalRowMenu";
import ManageCardModal from "../ManageCardModal";
import {
  getActiveCard,
  getCardIconBank,
  getOnwalletAccount,
  getAllCards,
  getCardOperatorName,
} from "shared/cardService/selectors";
import {
  getActiveWallet,
  getDehoWallet,
  getShortAddress,
  getWalletIconByName,
} from "shared/walletService/selectors";
import OwnCardFromOnWalletForm from "../TransferModal/OwnCardFromOnWalletForm";
import SomeoneCardFromOnWalletForm from "../TransferModal/SomeoneCardFromOnWalletForm";
import ToWalletFromCard from "../TransferModal/ToWalletFromCardForm";
import ToWalletFromWalletForm from "../TransferModal/ToWalletFromWalletForm";
import ToWalletFromOnWalletForm from "../TransferModal/ToWalletFromOnWalletForm";
import OnWalletFromCardForm from "../TransferModal/OnWalletFromCardForm";
import OnWalletFromWalletForm from "../TransferModal/OnWalletFromWalletForm";
import OnWalletFromOnWalletForm from "../TransferModal/OnWalletFromOnWallet";
import ManageWalletModal from "../ManageWalletModal";
import BankDetailsFromCardForm from "../TransferModal/BankDetailsFromCardForm";
import BankDetailsFromWalletForm from "../TransferModal/BankDetailsFromWalletForm";
import BankDetailsFromOnWalletForm from "../TransferModal/BankDetailsFromOnWalletForm";
import ReceiveToOwnCard from "../RecieveModal/ReceiveToOwnCard";
import ReceiveToOwnOnWallet from "../RecieveModal/ReceveToOwnOnWallet";
import ReceiveToOwnWallet from "../RecieveModal/ReceiveToOwnWallet";
import ReceiveDetailsForm from "../RecieveModal/ReceiveDetailsForm";
import ReceiveVirtualCard from "../RecieveVirtualCard/RecieveVirtualCard";
import {fetchTransactions} from "shared/historyService/actions";
import SWIFTFromCardForm from "../TransferModal/SWIFTFromCardForm";
import SWIFTFromWalletForm from "../TransferModal/SWIFTFromWalletForm";
import SWIFTFromOnWalletForm from "../TransferModal/SWIFTFromOnWalletForm";
import AddBankAccount from "../AddBankAccount";
import AddThirdWallet from "../AddThirdWallet";
import ReferalLinkModal from "components/App/components/Sidebar/components/SidebarDetailsModals/ReferalLinkModal";

const fromStates = {
  card: "card",
  bank: "bank",
  thirdPartyWallets: "thirdPartyWallets",
  wallet: "wallet",
  onWallet: "OnWallet",
};

const modalVariants = {
  addNewCard: "addNewCard",
  addNewWallet: "addNewWallet",
  addBankAccount: "addBankAccount",
  addThirdWallet: "addThirdWallet",
  exchange: "exchange",
  exchangeDeho: "exchangeDeho",
  receiveCard: "receiveCard",
  receiveVirtualCard: "receiveVirtualCard",
  receiveCardDetails: "receiveCardDetails",
  receiveOnWallet: "receiveOnWallet",
  receiveOnWalletDetails: "receiveOnWalletDetails",
  receiveWallet: "receiveWallet",
  receiveWalletDetails: "receiveWalletDetails",
  transferOwnCard: "transferOwnCard",
  transferSomeoneCard: "transferSomeoneCard",
  transferOwnWallet: "transferOwnWallet",
  transferToOwnOnWallet: "transferToOwnOnWallet",
  transferToSomeoneOnWallet: "transferToSomeoneOnWallet",
  transferToBankDetails: "transferToBankDetails",
  transferSWIFT: "transferSWIFT",
  modalMenuCard: "modalMenuCard",
  modalMenuWallet: "modalMenuWallet",
  modalMenuOnwalletAccount: "modalMenuOnwalletAccount",
  modalMenDehoWallet: "modalMenuDehoWallet",
  manageCard: "manageCard",
  manageWallet: "manageWallet",
  manageDehoWallet: "manageDehoWallet",
  modalHistoryFilter: "modalHistoryFilter",
  referralLink: "referralLink",
};

const getModalDataByVariant = variant => {
  switch (variant) {
    case modalVariants.addNewCard:
      return {
        title: <FormattedMessage {...messages.addNewCard} />,
      };
    case modalVariants.addNewWallet:
      return {
        title: <FormattedMessage {...messages.addNewCryptoWallet} />,
      };
    case modalVariants.exchange:
      return {
        title: <FormattedMessage {...messages.exchange} />,
        subTitle: <FormattedMessage {...messages.cryptoOrFiat} />,
      };
    case modalVariants.exchangeDeho:
      return {
        title: <FormattedMessage {...messages.exchangeBuyDeho} />,
        subTitle: <FormattedMessage {...messages.cryptoOrFiat} />,
      };
    case modalVariants.receiveVirtualCard:
    case modalVariants.receiveCardDetails:
    case modalVariants.receiveCard:
      return {
        title: <FormattedMessage {...messages.newInvoice} />,
        subTitle: <FormattedMessage {...messages.toOwnCard} />,
      };
    case modalVariants.receiveWalletDetails:
    case modalVariants.receiveWallet:
      return {
        title: <FormattedMessage {...messages.newInvoice} />,
        subTitle: <FormattedMessage {...messages.toOwnCryptoWallet} />,
      };
    case modalVariants.receiveOnWalletDetails:
    case modalVariants.receiveOnWallet:
      return {
        title: <FormattedMessage {...messages.newInvoice} />,
        subTitle: <FormattedMessage {...messages.toOwnOnwalletAccount} />,
      };
    case modalVariants.transferOwnCard:
      return {
        title: <FormattedMessage {...messages.newTransfer} />,
        subTitle: <FormattedMessage {...messages.toOwnCard} />,
      };
    case modalVariants.transferSomeoneCard:
      return {
        title: <FormattedMessage {...messages.newTransfer} />,
        subTitle: <FormattedMessage {...messages.transferToSomeoneCard} />,
      };
    case modalVariants.transferOwnWallet:
      return {
        title: <FormattedMessage {...messages.newTransfer} />,
        subTitle: <FormattedMessage {...messages.transferToCryptoWallet} />,
      };
    case modalVariants.transferToOwnOnWallet:
      return {
        title: <FormattedMessage {...messages.newTransfer} />,
        subTitle: <FormattedMessage {...messages.toOwnOnwalletAccount} />,
      };
    case modalVariants.transferToSomeoneOnWallet:
      return {
        title: <FormattedMessage {...messages.newTransfer} />,
        subTitle: <FormattedMessage {...messages.toSomeoneOnWalletAccount} />,
      };
    case modalVariants.transferToBankDetails:
      return {
        title: <FormattedMessage {...messages.newTransfer} />,
        subTitle: <FormattedMessage {...messages.byBankDetails} />,
      };
    case modalVariants.modalHistoryFilter:
      return {
        title: <FormattedMessage {...messages.historyFilters} />,
        button: <FormattedMessage {...messages.reset} />,
      };
    case modalVariants.transferSWIFT:
      return {
        title: <FormattedMessage {...messages.newTransfer} />,
        subTitle: <FormattedMessage {...messages.transferInternationalPayment} />,
      };
    case modalVariants.addBankAccount:
      return {
        title: <FormattedMessage {...messages.linkExistingBankAccount} />,
      };
    case modalVariants.addThirdWallet:
      return {
        title: <FormattedMessage {...messages.linkExistingWallet} />,
      };
    case modalVariants.referralLink:
      return {
        title: "Referal Link",
      };
    default:
      return {};
  }
};

const WalletsModals = ({
  variant,
  meta,
  transactionData,
  activeCard,
  activeWallet,
  bankCards,
  onWalletAccount,
  dehoWallet,
  fetchTransactions,
}) => {
  const [from, setFrom] = useState(fromStates.card);
  useEffect(() => {
    if (meta && meta.from) {
      setFrom(meta.from);
    }
  }, [setFrom, meta]);
  const modalData = getModalDataByVariant(variant);
  if (activeCard && variant === modalVariants.modalMenuCard) {
    modalData.title = getCardOperatorName(activeCard.operator);
    modalData.subTitle = "**** " + activeCard["last4"];
    modalData.icon = getCardIconBank(activeCard.name);
  }
  if (activeWallet && variant === modalVariants.modalMenuWallet) {
    modalData.title = activeWallet.walletName;
    modalData.subTitle = getShortAddress(activeWallet.address);
    modalData.icon = getWalletIconByName(activeWallet.walletName);
  }
  if (onWalletAccount && variant === modalVariants.modalMenuOnwalletAccount) {
    modalData.title = "OnWallet Account";
    modalData.subTitle = onWalletAccount.account;
    modalData.icon = getCardIconBank("OnWallet");
  }
  if (dehoWallet && variant === modalVariants.modalMenDehoWallet) {
    modalData.title = dehoWallet.walletName;
    modalData.subTitle = getShortAddress(dehoWallet.address);
    modalData.icon = getWalletIconByName(dehoWallet.walletName);
  }
  if (activeCard && variant === modalVariants.manageCard) {
    modalData.title = `${activeCard["last4"]} Card`;
    modalData.subTitle = <FormattedMessage {...messages.manage} />;
  }
  if (bankCards && variant === modalVariants.receiveVirtualCard) {
    const virtualCard = bankCards.find(card => card.operator === "OnWallet");

    modalData.title = `${virtualCard["last4"]} ${getCardOperatorName(
      virtualCard.operator
    )} Card`;
    modalData.subTitle = <FormattedMessage {...messages.manage} />;
  }
  if (activeWallet && variant === modalVariants.manageWallet) {
    modalData.title = `${activeWallet.walletName} Wallet`;
    modalData.subTitle = <FormattedMessage {...messages.manage} />;
  }
  if (dehoWallet && variant === modalVariants.manageDehoWallet) {
    modalData.title = `${dehoWallet.walletName} Wallet`;
    modalData.subTitle = <FormattedMessage {...messages.manage} />;
  }
  if (variant === modalVariants.modalHistoryFilter) {
    // Reset filter - fetch transactions without parameters
    modalData.buttonAction = () => fetchTransactions();
  }
  return (
    <RightModal
      title={modalData && modalData.title ? modalData.title : null}
      subTitle={modalData && modalData.subTitle ? modalData.subTitle : null}
      icon={modalData.icon ? modalData.icon : null}
      button={modalData.button ? modalData.button : null}
      buttonAction={modalData.buttonAction ? modalData.buttonAction : null}
    >
      {variant === modalVariants.addNewCard && <AddNewCard />}
      {variant === modalVariants.addNewWallet && <AddNewWallet />}
      {variant === modalVariants.addBankAccount && <AddBankAccount />}
      {variant === modalVariants.addThirdWallet && <AddThirdWallet />}
      {variant === modalVariants.exchange && <ExchangeModal />}
      {variant === modalVariants.exchangeDeho && <ExchangeModal recipient={meta} />}
      {variant === modalVariants.receiveCard && (
        <ReceiveToOwnCard cardNumber={meta && meta.cardNumber} />
      )}
      {variant === modalVariants.receiveCardDetails && (
        <ReceiveDetailsForm
          details={meta && meta.details}
          cardNumber={meta && meta.cardNumber}
        />
      )}
      {variant === modalVariants.receiveWallet && (
        <ReceiveToOwnWallet walletAddress={meta && meta.walletAddress} />
      )}
      {variant === modalVariants.receiveWalletDetails && (
        <ReceiveDetailsForm
          details={meta && meta.details}
          walletAddress={meta && meta.walletAddress}
        />
      )}
      {variant === modalVariants.receiveOnWallet && <ReceiveToOwnOnWallet />}
      {variant === modalVariants.receiveOnWalletDetails && (
        <ReceiveDetailsForm details={meta && meta.details} onWallet />
      )}
      {variant === modalVariants.transferOwnCard && (
        <>
          {from === fromStates.card && (
            <OwnCardFromCardForm
              formTabs={() => <FormTabs from={from} setFrom={setFrom} />}
              cardNumber={meta && meta.cardNumber}
            />
          )}
          {from === fromStates.wallet && (
            <OwnCardFromWalletForm
              formTabs={() => <FormTabs from={from} setFrom={setFrom} />}
              walletAddress={meta && meta.walletAddress}
            />
          )}
          {from === fromStates.onWallet && (
            <OwnCardFromOnWalletForm
              formTabs={() => <FormTabs from={from} setFrom={setFrom} />}
            />
          )}
        </>
      )}
      {variant === modalVariants.transferSomeoneCard && (
        <>
          {from === fromStates.card && (
            <SomeoneCardFromCardForm
              formTabs={() => <FormTabs from={from} setFrom={setFrom} />}
              cardNumber={meta && meta.cardNumber}
              recipientCardNumber={meta && meta.recipientCardNumber}
              amount={meta && meta.amount}
              currency={meta && meta.currency}
              description={meta && meta.description}
            />
          )}
          {from === fromStates.wallet && (
            <SomeoneCardFromWalletForm
              formTabs={() => <FormTabs from={from} setFrom={setFrom} />}
              walletAddress={meta && meta.walletAddress}
              recipientCardNumber={meta && meta.recipientCardNumber}
              amount={meta && meta.amount}
              currency={meta && meta.currency}
              description={meta && meta.description}
            />
          )}
          {from === fromStates.onWallet && (
            <SomeoneCardFromOnWalletForm
              formTabs={() => <FormTabs from={from} setFrom={setFrom} />}
              recipientCardNumber={meta && meta.recipientCardNumber}
              amount={meta && meta.amount}
              currency={meta && meta.currency}
              description={meta && meta.description}
            />
          )}
        </>
      )}
      {variant === modalVariants.transferOwnWallet && (
        <>
          {from === fromStates.card && (
            <ToWalletFromCard
              formTabs={() => <FormTabs from={from} setFrom={setFrom} />}
              cardNumber={
                transactionData ? transactionData.sourceAccount : meta && meta.cardNumber
              }
              recipientAddress={
                transactionData
                  ? transactionData.targetAccount
                  : meta && meta.recipientAddress
              }
              amount={transactionData ? transactionData.amount : meta && meta.amount}
              currency={
                transactionData ? transactionData.sourceCurrency : meta && meta.currency
              }
              description={
                transactionData ? transactionData.description : meta && meta.description
              }
            />
          )}
          {from === fromStates.wallet && (
            <ToWalletFromWalletForm
              formTabs={() => <FormTabs from={from} setFrom={setFrom} />}
              walletAddress={
                transactionData
                  ? transactionData.sourceAccount
                  : meta && meta.walletAddress
              }
              recipientAddress={
                transactionData
                  ? transactionData.targetAccount
                  : meta && meta.recipientAddress
              }
              amount={transactionData ? transactionData.amount : meta && meta.amount}
              currency={
                transactionData ? transactionData.sourceCurrency : meta && meta.currency
              }
              description={
                transactionData ? transactionData.description : meta && meta.description
              }
            />
          )}
          {from === fromStates.onWallet && (
            <ToWalletFromOnWalletForm
              formTabs={() => <FormTabs from={from} setFrom={setFrom} />}
              recipientAddress={
                transactionData
                  ? transactionData.targetAccount
                  : meta && meta.recipientAddress
              }
              amount={transactionData ? transactionData.amount : meta && meta.amount}
              currency={
                transactionData ? transactionData.sourceCurrency : meta && meta.currency
              }
              description={
                transactionData ? transactionData.description : meta && meta.description
              }
            />
          )}
        </>
      )}
      {variant === modalVariants.transferToOwnOnWallet && (
        <>
          {from === fromStates.card && (
            <OnWalletFromCardForm
              own
              formTabs={() => (
                <FormTabs
                  from={from}
                  setFrom={setFrom}
                  page="depositOnwalletAccount"
                  ownOnWallet
                />
              )}
              cardNumber={meta && meta.cardNumber}
            />
          )}
          {from === fromStates.wallet && (
            <OnWalletFromWalletForm
              own
              formTabs={() => (
                <FormTabs
                  from={from}
                  setFrom={setFrom}
                  page="depositOnwalletAccount"
                  ownOnWallet
                />
              )}
              walletAddress={meta && meta.walletAddress}
            />
          )}
          {from === fromStates.onWallet && (
            <OnWalletFromCardForm
              own
              formTabs={() => (
                <FormTabs
                  from={from}
                  setFrom={setFrom}
                  page="depositOnwalletAccount"
                  ownOnWallet
                />
              )}
            />
          )}
          {from === fromStates.bank && (
            <OnWalletFromCardForm
              own
              bankAccounts={meta && meta.bank}
              formTabs={() => (
                <FormTabs
                  from={from}
                  setFrom={setFrom}
                  page="depositOnwalletAccount"
                  ownOnWallet
                />
              )}
            />
          )}
          {from === fromStates.thirdPartyWallets && (
            <OnWalletFromCardForm
              own
              formTabs={() => (
                <FormTabs
                  from={from}
                  setFrom={setFrom}
                  page="depositOnwalletAccount"
                  ownOnWallet
                />
              )}
              cardNumber={meta && meta.thirdPartyWallets}
            />
          )}
        </>
      )}
      {variant === modalVariants.transferToSomeoneOnWallet && (
        <>
          {from === fromStates.card && (
            <OnWalletFromCardForm
              formTabs={() => <FormTabs from={from} setFrom={setFrom} />}
              cardNumber={meta && meta.cardNumber}
              recipientAddress={meta && meta.recipientAddress}
              amount={meta && meta.amount}
              currency={meta && meta.currency}
              description={meta && meta.description}
            />
          )}
          {from === fromStates.wallet && (
            <OnWalletFromWalletForm
              formTabs={() => <FormTabs from={from} setFrom={setFrom} />}
              walletAddress={meta && meta.walletAddress}
              recipientAddress={meta && meta.recipientAddress}
              amount={meta && meta.amount}
              currency={meta && meta.currency}
              description={meta && meta.description}
            />
          )}
          {from === fromStates.onWallet && (
            <OnWalletFromOnWalletForm
              formTabs={() => <FormTabs from={from} setFrom={setFrom} />}
              recipientAddress={meta && meta.recipientAddress}
              amount={meta && meta.amount}
              currency={meta && meta.currency}
              description={meta && meta.description}
            />
          )}
        </>
      )}
      {variant === modalVariants.transferToBankDetails && (
        <>
          {from === fromStates.card && (
            <BankDetailsFromCardForm
              formTabs={() => <FormTabs from={from} setFrom={setFrom} />}
              cardNumber={meta && meta.cardNumber}
            />
          )}
          {from === fromStates.wallet && (
            <BankDetailsFromWalletForm
              formTabs={() => <FormTabs from={from} setFrom={setFrom} />}
              walletAddress={meta && meta.walletAddress}
            />
          )}
          {from === fromStates.onWallet && (
            <BankDetailsFromOnWalletForm
              formTabs={() => <FormTabs from={from} setFrom={setFrom} />}
            />
          )}
        </>
      )}
      {variant === modalVariants.transferSWIFT && (
        <>
          {from === fromStates.card && (
            <SWIFTFromCardForm
              formTabs={() => <FormTabs from={from} setFrom={setFrom} />}
              cardNumber={meta && meta.cardNumber}
            />
          )}
          {from === fromStates.wallet && (
            <SWIFTFromWalletForm
              formTabs={() => <FormTabs from={from} setFrom={setFrom} />}
              walletAddress={meta && meta.walletAddress}
            />
          )}
          {from === fromStates.onWallet && (
            <SWIFTFromOnWalletForm
              formTabs={() => <FormTabs from={from} setFrom={setFrom} />}
            />
          )}
        </>
      )}
      {variant === modalVariants.modalMenuCard && <ModalRowMenu card={activeCard} />}
      {variant === modalVariants.modalMenuWallet && (
        <ModalRowMenu wallet={activeWallet} />
      )}
      {variant === modalVariants.modalMenuOnwalletAccount && <ModalRowMenu onwallet />}
      {variant === modalVariants.modalMenDehoWallet && (
        <ModalRowMenu wallet={dehoWallet} />
      )}
      {variant === modalVariants.manageCard && <ManageCardModal card={activeCard} />}
      {variant === modalVariants.receiveVirtualCard && <ReceiveVirtualCard />}
      {variant === modalVariants.manageWallet && (
        <ManageWalletModal wallet={activeWallet || dehoWallet} />
      )}
      {variant === modalVariants.manageDehoWallet && (
        <ManageWalletModal wallet={dehoWallet} />
      )}
      {variant === modalVariants.modalHistoryFilter && <HistoryFilterModal />}
      {variant === modalVariants.referralLink && <ReferalLinkModal />}
    </RightModal>
  );
};

const FormTabs = ({from, setFrom, ownOnWallet, page = ""}) => {
  // TODO (When unlock tabs)
  // const path = ["depositOnwalletAccount"];
  // const isShowTabs = path.includes(page);
  const isShowTabs = false;

  return (
    <div className="SourceTabsContainer">
      <div
        className={`SourceTab ${from === fromStates.wallet ? "ActiveTab" : ""}`}
        onClick={() => setFrom(fromStates.wallet)}
      >
        <FormattedMessage {...messages.crypto} />
      </div>

      <div
        className={`SourceTab ${from === fromStates.card ? "ActiveTab" : ""}`}
        onClick={() => setFrom(fromStates.card)}
      >
        <FormattedMessage {...messages.card} />
      </div>

      {!ownOnWallet && (
        <div
          className={`SourceTab ${from === fromStates.onWallet ? "ActiveTab" : ""}`}
          onClick={() => setFrom(fromStates.onWallet)}
        >
          <FormattedMessage {...messages.onWallet} />
        </div>
      )}

      {isShowTabs && (
        <div
          className={`SourceTab ${from === fromStates.bank ? "ActiveTab" : ""}`}
          onClick={() => setFrom(fromStates.bank)}
        >
          <FormattedMessage {...messages.bank} />
        </div>
      )}

      {isShowTabs && (
        <div
          className={`SourceTab ${
            from === fromStates.thirdPartyWallets ? "ActiveTab" : ""
          }`}
          onClick={() => setFrom(fromStates.thirdPartyWallets)}
        >
          <FormattedMessage {...messages.thirdPartyWallets} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = store => ({
  variant: store.rightModalData.variant,
  meta: store.rightModalData.meta,
  transactionData: store.rightModalData.savedData,
  activeCard: getActiveCard(store),
  activeWallet: getActiveWallet(store),
  onWalletAccount: getOnwalletAccount(store),
  dehoWallet: getDehoWallet(store),
  bankCards: getAllCards(store),
});

const mapDispatchToProps = {
  fetchTransactions,
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletsModals);
