import React, {useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import "./index.scss";
import {FormattedMessage} from "react-intl";
import messages from "../../messages";

import {openRightModal} from "shared/components/RightModal/actions";
import CustomCheckbox from "shared/components/Checkbox";
import {setDefaultCard} from "shared/cardService/actions";
import {isDefaultCardLoading} from "shared/cardService/selectors";
import Loader from "shared/components/Loader";
import {mapItems} from "./index.jsx";

import config from "shared/config";
const ownCardIcon = config.prefixImgUrl + "own-card-icon.svg";
const cardIcon = config.prefixImgUrl + "card-icon.svg";
const bankIcon = config.prefixImgUrl + "bank-icon.svg";
const globeIcon = config.prefixImgUrl + "globe-icon.svg";
const walletIcon = config.prefixImgUrl + "wallet-icon.svg";
const exchangeIcon = config.prefixImgUrl + "exhange-icon.svg";
const clockIcon = config.prefixImgUrl + "clock-icon.svg";
const walletIconSecond = config.prefixImgUrl + "wallet-icon-second.svg";
const manageCardIcon = config.prefixImgUrl + "manage-card-icon.svg";
const manageWalletIcon = config.prefixImgUrl + "manage-wallet-icon.svg";
const depositOnwalletIcon = config.prefixImgUrl + "deposit-onwallet-icon.svg";
const someoneDepositOnwalletIcon =
  config.prefixImgUrl + "someone-deposit-onwallet-icon.svg";
const depositIcon = config.prefixImgUrl + "deposit-icon.svg";
const createInvoice = config.prefixImgUrl + "create-invoice-icon.svg";

const ModalRowMenu = ({
  openRightModal,
  card,
  wallet,
  onwallet,
  setDefaultCard,
  isDefaultCardLoading,
  ...props
}) => {
  const [localCard, setLocalCard] = useState(card);

  const DepositOnwalletAccountItems = [
    {
      title: <FormattedMessage {...messages.depositOnwalletAccount} />,
      subTitle: <FormattedMessage {...messages.easyTransferToYourAccount} />,
      icon: depositOnwalletIcon,
      onClick: () => {
        if (wallet) {
          openRightModal("transferToOwnOnWallet", {
            from: "wallet",
            walletAddress: wallet.address,
          });
        } else if (card) {
          openRightModal("transferToOwnOnWallet", {
            from: "card",
            cardNumber: card.number,
          });
        } else {
          openRightModal("transferToOwnOnWallet");
        }
      },
    },
  ];

  const TransferItems = [
    {
      title: <FormattedMessage {...messages.toSomeoneOnWalletAccount} />,
      subTitle: <FormattedMessage {...messages.transferToAnyOnWalletAccount} />,
      icon: someoneDepositOnwalletIcon,
      onClick: () => {
        if (wallet) {
          openRightModal("transferToSomeoneOnWallet", {
            from: "wallet",
            walletAddress: wallet.address,
          });
        } else if (card) {
          openRightModal("transferToSomeoneOnWallet", {
            from: "card",
            cardNumber: card.number,
          });
        } else {
          openRightModal("transferToSomeoneOnWallet", {
            from: "OnWallet",
          });
        }
      },
    },
    {
      title: <FormattedMessage {...messages.toOwnCard} />,
      subTitle: <FormattedMessage {...messages.transferEasyTransfer} />,
      icon: ownCardIcon,
      onClick: () => {
        if (wallet) {
          openRightModal("transferOwnCard", {
            from: "wallet",
            walletAddress: wallet.address,
          });
        } else if (card) {
          openRightModal("transferOwnCard", {
            from: "card",
            cardNumber: card.number,
          });
        } else {
          openRightModal("transferOwnCard", {
            from: "OnWallet",
          });
        }
      },
    },
    {
      title: <FormattedMessage {...messages.transferToSomeoneCard} />,
      subTitle: <FormattedMessage {...messages.transferPayFast} />,
      icon: cardIcon,
      onClick: () => {
        if (wallet) {
          openRightModal("transferSomeoneCard", {
            from: "wallet",
            walletAddress: wallet.address,
          });
        } else if (card) {
          openRightModal("transferSomeoneCard", {
            from: "card",
            cardNumber: card.number,
          });
        } else {
          openRightModal("transferSomeoneCard", {
            from: "OnWallet",
          });
        }
      },
    },
    {
      title: <FormattedMessage {...messages.transferBankDetails} />,
      subTitle: <FormattedMessage {...messages.transferToBankAccount} />,
      icon: bankIcon,
      onClick: () => {
        if (wallet) {
          openRightModal("transferToBankDetails", {
            from: "wallet",
            walletAddress: wallet.address,
          });
        } else if (card) {
          openRightModal("transferToBankDetails", {
            from: "card",
            cardNumber: card.number,
          });
        } else {
          openRightModal("transferToBankDetails", {
            from: "OnWallet",
          });
        }
      },
    },
    {
      title: <FormattedMessage {...messages.transferInternationalPayment} />,
      subTitle: <FormattedMessage {...messages.transferPayAbroad} />,
      icon: globeIcon,
      onClick: () => {
        if (wallet) {
          openRightModal("transferSWIFT", {
            from: "wallet",
            walletAddress: wallet.address,
          });
        } else if (card) {
          openRightModal("transferSWIFT", {
            from: "card",
            cardNumber: card.number,
          });
        } else {
          openRightModal("transferSWIFT", {
            from: "OnWallet",
          });
        }
      },
    },
    {
      title: <FormattedMessage {...messages.transferToCryptoWallet} />,
      subTitle: <FormattedMessage {...messages.transferFundsToPopular} />,
      icon: walletIcon,
      onClick: () => {
        if (wallet) {
          openRightModal("transferOwnWallet", {
            from: "wallet",
            walletAddress: wallet.address,
          });
        } else if (card) {
          openRightModal("transferOwnWallet", {
            from: "card",
            cardNumber: card.number,
          });
        } else {
          openRightModal("transferOwnWallet", {
            from: "OnWallet",
          });
        }
      },
    },
  ];

  const CardReceiveItems = [
    {
      title: <FormattedMessage {...messages.depositCard} />,
      subTitle: <FormattedMessage {...messages.fromCardOrOtherWallet} />,
      icon: depositIcon,
      onClick: () => {
        openRightModal("transferSomeoneCard", {
          from: "card",
          cardNumber: card.number,
        });
      },
    },
    {
      title: <FormattedMessage {...messages.createInvoice} />,
      subTitle: <FormattedMessage {...messages.generateInvoiceLink} />,
      icon: createInvoice,
      onClick: () => {
        openRightModal("receiveCard", {cardNumber: card.number});
      },
    },
  ];

  const AccountReceiveItems = [
    {
      title: <FormattedMessage {...messages.depositOnwalletAccount} />,
      subTitle: <FormattedMessage {...messages.easyTransferToYourAccount} />,
      icon: depositIcon,
      onClick: () =>
        openRightModal("transferToOwnOnWallet", {
          from: "OnWallet",
        }),
    },
    {
      title: <FormattedMessage {...messages.createInvoice} />,
      subTitle: <FormattedMessage {...messages.generateInvoiceLink} />,
      icon: walletIconSecond,
      onClick: () => openRightModal("receiveOnWallet"),
    },
  ];

  const WalletReceiveItems = [
    {
      title: <FormattedMessage {...messages.depositWallet} />,
      subTitle: <FormattedMessage {...messages.fromCardOrOtherWallet} />,
      icon: depositIcon,
      onClick: () =>
        openRightModal("transferOwnWallet", {
          from: "wallet",
          walletAddress: wallet.address,
        }),
    },
    {
      title: <FormattedMessage {...messages.transferToCryptoWallet} />,
      subTitle: <FormattedMessage {...messages.transferCreateInvoiceWithCrypto} />,
      icon: walletIconSecond,
      onClick: () => openRightModal("receiveWallet"),
    },
  ];

  const ExchangeItems = [
    {
      title: <FormattedMessage {...messages.headerExchange} />,
      subTitle: <FormattedMessage {...messages.exchangeCryptoToFiat} />,
      icon: exchangeIcon,
      onClick: () => openRightModal("exchange"),
    },
  ];

  const HistoryOtherItem = [
    {
      title: <FormattedMessage {...messages.transactionHistory} />,
      icon: clockIcon,
      onClick: () => props.history.push("/action_history"),
    },
  ];

  const CardOtherItems = [
    {
      title: <FormattedMessage {...messages.manageCard} />,
      icon: manageCardIcon,
      onClick: () => openRightModal("manageCard"),
    },
  ];

  const WalletOtherItems = [
    {
      title: <FormattedMessage {...messages.manageWallet} />,
      icon: manageWalletIcon,
      onClick: () => {
        openRightModal(
          wallet.walletName === "Deho" ? "manageDehoWallet" : "manageWallet"
        );
      },
    },
  ];

  return (
    <div className="ModalMenu">
      <h1 className="RowMenuHeader">
        <FormattedMessage {...messages.headerTransfer} />
      </h1>
      {(card || wallet) && mapItems(DepositOnwalletAccountItems)}
      {mapItems(TransferItems)}
      <h1 className="RowMenuHeader">
        <FormattedMessage {...messages.headerReceive} />
      </h1>
      {card && mapItems(CardReceiveItems)}
      {wallet && mapItems(WalletReceiveItems)}
      {onwallet && mapItems(AccountReceiveItems)}
      <h1 className="RowMenuHeader">
        <FormattedMessage {...messages.headerExchange} />
      </h1>
      {mapItems(ExchangeItems)}
      {localCard && (
        <>
          <h1 className="RowMenuHeader">
            <FormattedMessage {...messages.headerOther} />
          </h1>
          {mapItems(HistoryOtherItem, {other: true})}
          {mapItems(CardOtherItems, {other: true})}
          <div className="currentByDefault">
            {isDefaultCardLoading && <Loader simple />}
            {!isDefaultCardLoading && (
              <CustomCheckbox
                value={localCard.isDefault}
                onChange={() => {
                  if (!localCard.isDefault) {
                    setDefaultCard(card.id);
                    setLocalCard({...localCard, isDefault: true});
                  }
                }}
              />
            )}
            <p className="text">
              <FormattedMessage {...messages.useThisCardByDefault} />
            </p>
          </div>
        </>
      )}
      {wallet && (
        <>
          <h1 className="RowMenuHeader">
            <FormattedMessage {...messages.headerOther} />
          </h1>
          {mapItems(HistoryOtherItem, {other: true})}
          {mapItems(WalletOtherItems, {other: true})}
        </>
      )}
      {onwallet && (
        <>
          <h1 className="RowMenuHeader">
            <FormattedMessage {...messages.headerOther} />
          </h1>
          {mapItems(HistoryOtherItem, {other: true})}
        </>
      )}
    </div>
  );
};

const mapStateToProps = store => ({
  isDefaultCardLoading: isDefaultCardLoading(store),
});

const mapDispatchToProps = {
  openRightModal,
  setDefaultCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalRowMenu));
