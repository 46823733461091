import {defineMessages} from "react-intl";

const scope = "ContactsPage";

const messages = defineMessages({
  sendMyContact: {
    id: `${scope}.sendMyContact`,
    defaultMessage: "Send my contact",
  },
  send: {
    id: `${scope}.send`,
    defaultMessage: "Send",
  },
  addContact: {
    id: `${scope}.addContact`,
    defaultMessage: "Add contact",
  },
  contactDetails: {
    id: `${scope}.contactDetails`,
    defaultMessage: "Contact Details",
  },
  sendTo: {
    id: `${scope}.sendTo`,
    defaultMessage: "Send to",
  },
  header: {
    id: `${scope}.header`,
    defaultMessage: "Contacts",
  },
  noContactsNow: {
    id: `${scope}.noContactsNow`,
    defaultMessage: "No Contacts Now",
  },
  noContactsMatchingSearch: {
    id: `${scope}.noContactsMatchingSearch`,
    defaultMessage: "No Contacts Matching Search",
  },
  input: {
    id: `${scope}.input`,
    defaultMessage: "Search",
  },
  companyName: {
    id: `${scope}.companyName`,
    defaultMessage: "Company Name",
  },
  onWalletAccount: {
    id: `${scope}.onWalletAccount`,
    defaultMessage: "OnWallet Account",
  },
  walletAddress: {
    id: `${scope}.walletAddress`,
    defaultMessage: "Wallet Address",
  },
  phone: {
    id: `${scope}.phone`,
    defaultMessage: "Phone",
  },
  phoneNumber: {
    id: `${scope}.phoneNumber`,
    defaultMessage: "Phone Number",
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: "E-mail",
  },
  address: {
    id: `${scope}.address`,
    defaultMessage: "Address",
  },
  dehoWallet: {
    id: `${scope}.dehoWallet`,
    defaultMessage: "Deho Wallet",
  },
  bankAccount: {
    id: `${scope}.bankAccount`,
    defaultMessage: "Bank Account",
  },
  bankAccountIBAN: {
    id: `${scope}.bankAccountIBAN`,
    defaultMessage: "Bank Account (IBAN)",
  },
  bankCardNumber: {
    id: `${scope}.bankCardNumber`,
    defaultMessage: "Bank Card Number",
  },
  cardNumber: {
    id: `${scope}.cardNumber`,
    defaultMessage: "Card Number",
  },
  shareContact: {
    id: `${scope}.shareContact`,
    defaultMessage: "Share contact",
  },
  recipient: {
    id: `${scope}.recipient`,
    defaultMessage: "Recipient",
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: "Edit",
  },
  editContact: {
    id: `${scope}.editContact`,
    defaultMessage: "Edit Contact",
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: "Save",
  },
  saveChange: {
    id: `${scope}.saveChange`,
    defaultMessage: "Save Change",
  },
  share: {
    id: `${scope}.share`,
    defaultMessage: "Share",
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: "Cancel",
  },
  general: {
    id: `${scope}.general`,
    defaultMessage: "General",
  },
  firstName: {
    id: `${scope}.firstName`,
    defaultMessage: "First Name",
  },
  lastName: {
    id: `${scope}.lastName`,
    defaultMessage: "Last Name",
  },
  deleteContact: {
    id: `${scope}.deleteContact`,
    defaultMessage: "Delete Contact",
  },
  contactDeletion: {
    id: `${scope}.contactDeletion`,
    defaultMessage: "Contact Deletion",
  },
  willBeDeleted: {
    id: `${scope}.willBeDeleted`,
    defaultMessage: "This contact will be deleted from OnWallet Service permanently.",
  },
  finance: {
    id: `${scope}.finance`,
    defaultMessage: "Finance",
  },
  currency: {
    id: `${scope}.currency`,
    defaultMessage: "Currency",
  },
});

export default messages;
